import React, { useEffect, useState } from 'react'
import { MdFlag } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import StatusFilterIcon from '/src/ui/core/icons/status_filter_icon'
import useFlagFilterServices from '/src/ui/domain/requests/flag_filter_services'

const GROUP = 'flag'

export default function RequestFlagFilterIcon({ filter, filterGroup, updateFilters }) {
  const [popupOpened, setPopupOpened] = useState(false)
  const { flags, setFlags, changeFilter, clearFilters } = useFlagFilterServices({
    setPopupOpened,
    filterGroup,
    GROUP,
    updateFilters
  })

  useEffect(() => {
    if (filterGroup !== GROUP) {
      setFlags((prev) =>
        prev.map((flag) => {
          return { ...flag, filtered: false }
        })
      )
    }
  }, [filterGroup])

  const description = (flag) => {
    const flagTitle = I18n.t(`requests.flags.${flag.i18n_id}`)
    return (
      <span className="flag-description">
        <span className="flag-icon">{flag.icon}</span>
        {flagTitle}
      </span>
    )
  }

  return (
    <StatusFilterIcon
      statuses={flags}
      statusDescription={description}
      isStatusSelected={(flag) => flag.filtered}
      isFilterSelected={!(filter.length === 0) && filterGroup === GROUP}
      onChangeFilter={changeFilter}
      onClearFilters={clearFilters}
      popupOpened={popupOpened}
      setPopupOpened={setPopupOpened}
      icon={<MdFlag />}
      filterType={GROUP}
    />
  )
}

RequestFlagFilterIcon.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterGroup: PropTypes.string,
  updateFilters: PropTypes.func.isRequired
}

RequestFlagFilterIcon.defaultProps = {
  filterGroup: null
}
