import React, { useRef } from 'react'
import { useStore } from 'react-context-hook'
import { MdLanguage, MdCheck } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import '/src/static/css/core/menus/language_switch.css'
import '/src/static/css/core/icons/popup_icon.css'

export const languages = {
  en: 'English',
  nb: 'Norsk',
  'pt-BR': 'Português (BR)',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  'zh-CN': '简体中文',
  th: 'ไทย'
}

export default function LanguageSwitch() {
  const [locale, setLocale] = useStore('language')
  const popupButtonRef = useRef(null)

  const items = Object.keys(languages).map((key) => ({
    text: languages[key],
    icon: key === locale ? <MdCheck /> : undefined,
    onClick: () => setLocale(key)
  }))

  return (
    <div className="popup-icon">
      <PopupMenu items={items} popupButtonRef={popupButtonRef}>
        <button ref={popupButtonRef} className="language">
          <MdLanguage className="icon" />
          <span className="text">{languages[locale]}</span>
        </button>
      </PopupMenu>
    </div>
  )
}
