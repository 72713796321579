import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function AreaNoiseColumn({ value }) {
  const translationKey = value && value.i18n_id
  if (!translationKey) return ''
  return I18n.t(`scaffoldings.area_noises.${translationKey}`)
}

AreaNoiseColumn.propTypes = {
  value: PropTypes.shape({
    i18n_id: PropTypes.string
  })
}

AreaNoiseColumn.defaultProps = {
  value: {}
}
