import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircle, MdTraffic } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import StatusFilterIcon from '/src/ui/core/icons/status_filter_icon'

const STATUS_BLACKLIST = ['confirmed', 'in_planning', 'deleting', 'on_hold']
const GROUP = 'status'

export default function RequestStatusFilterIcon({ filter, filterGroup, updateFilters, filterLabelNames }) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [subRequestFilter, setSubRequestFilter] = useState(false)

  const [requestStatuses] = useStore('request_statuses')

  useEffect(() => {
    if (statuses?.length > 0) return

    const filteredStatuses = filter?.find(({ column }) => column === 'request_status_id')?.value || []

    setStatuses(
      Object.values(requestStatuses)?.map((status) => ({
        ...status,
        filtered: filteredStatuses.includes(status.id)
      })) || []
    )
  }, [requestStatuses, filter, statuses?.length])

  const requestIdFiltered = () => {
    return filter && filter.length > 0 && filter.find((x) => x.column === 'id' && x.operator === IsEqualOperator)
  }

  const findFilter = (selectedStatus, status) => {
    if (selectedStatus.id === status.id) return true
    if (selectedStatus.i18n_id === 'confirmed' && status.i18n_id === 'in_scoping') return true
    if (selectedStatus.i18n_id === 'in_scoping' && status.i18n_id === 'confirmed') return true
    if (selectedStatus.i18n_id === 'in_planning' && status.i18n_id === 'in_performance') return true
    if (selectedStatus.i18n_id === 'in_performance' && status.i18n_id === 'in_planning') return true
    return false
  }

  const removeFilter = ({ column, operator }) => {
    return filter.filter((x) => x.column !== column || x.operator !== operator)
  }

  const addFilter = (filterObject, source) => {
    const newFilter = source.map((x) => x)
    newFilter.push(filterObject)
    return newFilter
  }

  const changeFilter = (status) => {
    if (!popupOpened) return

    setPopupOpened(false)

    const newStatuses = statuses.map((x) => x)

    const { filtered } = status
    newStatuses
      .filter((eachStatus) => findFilter(status, eachStatus))
      .forEach((filteredStatus) => (filteredStatus.filtered = !filtered))

    const filters = statuses.filter((eachStatus) => eachStatus.filtered).map((filteredStatus) => filteredStatus.id)

    const newLabelNames = statuses.filter((x) => x.filtered).map((eachStatus) => `statuses.${eachStatus.i18n_id}`)

    if (!filters) return

    const filterObject = {
      type: 'where_or',
      column: 'request_status_id',
      operator: IsEqualOperator,
      value: filters
    }

    let newFilter = []
    newFilter = removeFilter(filterObject)
    if (filters.length > 0) {
      newFilter = addFilter(filterObject, filterGroup === GROUP ? newFilter : [])
    }

    let newLabels = []
    if (subRequestFilter) newLabels = [...(newLabelNames || []), 'sub_request']
    else newLabels = newLabelNames || []

    updateFilters(newFilter, GROUP, newLabels)
    setStatuses(newStatuses)
  }

  const changeSubRequestFilter = () => {
    setPopupOpened(false)

    if (!filter) return

    const filterObject = {
      type: 'where_or',
      column: 'parent_id',
      operator: IsEqualOperator,
      value: 'NOT_NULL'
    }

    let newFilter = []
    let newLabels = []
    if (subRequestFilter) {
      newFilter = removeFilter(filterObject)
      newLabels = filterLabelNames.slice(0, -1)
    } else if (filterGroup === GROUP) {
      newFilter = addFilter(filterObject, filter)
      newLabels = [...filterLabelNames, 'sub_request']
    } else {
      newFilter = addFilter(filterObject, [])
      newLabels = ['sub_request']
    }

    updateFilters(newFilter, GROUP, newLabels)
    setSubRequestFilter(!subRequestFilter)
  }

  const clearFilters = () => {
    setPopupOpened(false)
    setStatuses(
      statuses.map((s) => {
        return { ...s, filtered: false }
      })
    )
    setSubRequestFilter(false)
    if (filterGroup === GROUP) updateFilters([], null, [])
  }

  const allowedStatuses = statuses.filter((s) => !STATUS_BLACKLIST.includes(s.i18n_id))

  const description = (status) => {
    const statusTitle = I18n.t(`requests.statuses.${status.i18n_id}`)
    return statusTitle
  }

  useEffect(() => {
    if (filterGroup !== GROUP) {
      setStatuses((st) =>
        st.map((s) => {
          return { ...s, filtered: false }
        })
      )
      setSubRequestFilter(false)
    }
  }, [filterGroup])

  return (
    !requestIdFiltered() && (
      <StatusFilterIcon
        statuses={allowedStatuses}
        statusDescription={description}
        isStatusSelected={(status) => status.filtered}
        isFilterSelected={!(filter.length === 0) && filterGroup === GROUP}
        onChangeFilter={changeFilter}
        onClearFilters={clearFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
        icon={<MdTraffic />}
        filterType={GROUP}
      >
        <span
          id="request-status-label-sub-request"
          className="label workflow-status sub-request"
          onClick={() => changeSubRequestFilter()}
        >
          {I18n.t(`requests.sub_request`)}
          {subRequestFilter && <MdCheckCircle />}
        </span>
      </StatusFilterIcon>
    )
  )
}

RequestStatusFilterIcon.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterGroup: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
  filterLabelNames: PropTypes.arrayOf(PropTypes.string).isRequired
}

RequestStatusFilterIcon.defaultProps = {
  filterGroup: null
}
