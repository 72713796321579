import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import RadioButton from '/src/ui/core/inputs/radio_button'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import { isBlank } from '/src/utils/boolean_refinements'
import { asString } from '/src/utils/string'
import { maxRadioDropBySizeAndLength, defaultDropFormWidth } from '/src/utils/constants/fields'
import '/src/static/css/dropdown.css'

const formatOptions = (options, fieldFormatter, textField = 'description') =>
  options.map((dropItem) => {
    if (typeof dropItem !== 'object' || Array.isArray(dropItem)) return dropItem

    const fieldFormatted = fieldFormatter && fieldFormatter(dropItem)

    if (isBlank(fieldFormatted)) return dropItem
    return { ...dropItem, [textField]: fieldFormatted }
  })

const allSmallOptions = (options, webFormWidth) => {
  const width = webFormWidth || defaultDropFormWidth
  const maxAllowedLength = maxRadioDropBySizeAndLength[width][options?.length]
  if (!maxAllowedLength) return true
  return !options.some((item) => item.length > maxAllowedLength)
}
/**
 *  It will create a combobox using an array of
 *  either objects or strings as options
 *
 *  If you do use objects, make sure you to have both properties id (for value)
 *  and description (for text reference on the combobox)
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
export default function InputDrop({ inputProps }) {
  const inputRef = useRef()

  const {
    options,
    opened,
    forceCombobox,
    forceRadioButton,
    ref,
    textField,
    popupClassName,
    iconClassName,
    appendTo,
    virtual,
    clearButton,
    onPageChange,
    isDynamicDrop,
    dependency,
    dataItem,
    fieldFormatter,
    webFormWidth
  } = inputProps

  const dependencyAttr = `${dependency}_id`
  const dependencyValue = dataItem ? dataItem[dependencyAttr] : undefined

  const dropOptions = formatOptions(
    dependency ? options.filter((option) => option[dependencyAttr] === dependencyValue) : options,
    fieldFormatter,
    textField
  )

  const {
    data,
    error,
    value,
    wrapperClass,
    inputClass,
    onFocus,
    onBlur,
    readOnly,
    onFilterChange,
    onComboChange,
    isOptionsString,
    textToSearch
  } = useInputDrop({
    ...inputProps,
    options: dropOptions
  })
  

  const shouldShowRadioButton = () => {
    if (forceRadioButton) return true
    if (forceCombobox || isDynamicDrop) return false
    if (options.length <= 4 && isOptionsString() && allSmallOptions(dropOptions, webFormWidth)) return true
    return false
  }

  if (shouldShowRadioButton()) return <RadioButton inputProps={inputProps} />

  const comboBoxPopupSettings = () => {
    const settings = {
      appendTo: document.querySelector(appendTo || 'body'),
      className: `combobox-list ${popupClassName || ''}`
    }

    if (inputProps.popupSettingsWidth) settings.width = inputProps.popupSettingsWidth

    return settings
  }

  return (
    <div className={wrapperClass}>
      <div
        onClick={() => {
          const inputDropRef = ref || inputRef
          inputDropRef.current.toggleBtnClick()
        }}
      >
        <ComboBox
          id={inputProps.id}
          className={inputClass()}
          iconClassName={iconClassName}
          data={data}
          value={value || null}
          onChange={onComboChange}
          onFocus={onFocus}
          onBlur={onBlur}
          popupSettings={comboBoxPopupSettings()}
          filterable
          filter={asString(textToSearch)}
          textField={isOptionsString() ? null : inputProps.textField || 'description'}
          valueField={isOptionsString() ? null : 'id'}
          onFilterChange={onFilterChange}
          disabled={readOnly || (dependency && isBlank(dependencyValue))}
          opened={opened}
          ref={ref || inputRef}
          virtual={virtual}
          onPageChange={onPageChange}
          clearButton={clearButton !== false}
        />
      </div>
      <div className="error-label">{error}</div>
    </div>
  )
}

InputDrop.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array]),
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    dependency: PropTypes.string,
    appendTo: PropTypes.string,
    opened: PropTypes.bool,
    forceCombobox: PropTypes.bool,
    forceRadioButton: PropTypes.bool,
    popupClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    ref: PropTypes.oneOfType([PropTypes.object]),
    textField: PropTypes.string,
    clearButton: PropTypes.bool,
    popupSettingsWidth: PropTypes.string,
    fieldFormatter: PropTypes.func,
    webFormWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
}
