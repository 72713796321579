import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdAttachFile } from 'react-icons/md'
import AttachmentsModal from '/src/ui/domain/requests/attachments_modal'
import '/src/static/css/domain/requests/track_block.css'

export default function RequestAttachmentIcon({ request }) {
  const { id, subproject_id: subprojectId } = request
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <div className="icon-attachment-container">
        <MdAttachFile data-testid="icon-attachment" className="icon-attachment" onClick={() => setModalOpen(true)} />
      </div>
      {modalOpen && <AttachmentsModal subprojectId={subprojectId} requestId={id} onClose={() => setModalOpen(false)} />}
    </>
  )
}

RequestAttachmentIcon.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.number,
    subproject_id: PropTypes.number
  }).isRequired
}
