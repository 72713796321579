import React from 'react'
import PropTypes from 'prop-types'
import Image from '/src/ui/core/fields/image'
import '/src/static/css/core/inputs/image_thumbnail.css'
import '/src/static/css/tooltip.css'

export default function ImageThumbnail({ children, image, onDelete, onClick, columnId }) {
  return (
    <div className="image-thumbnail">
      <Image image={image} key={image.id} onDelete={onDelete} columnId={columnId} onClick={onClick} />
      {children ?? []}
    </div>
  )
}

ImageThumbnail.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  image: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    comment: PropTypes.string
  }).isRequired,
  onDelete: PropTypes.func,
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ImageThumbnail.defaultProps = {
  onDelete: () => {},
  columnId: null
}
