import React from 'react'
import PropTypes from 'prop-types'
import { filterFlags } from '/src/utils/constants/request'

export default function DisciplineCheckIcon({ disciplineCheckStatus, keyProp, title }) {
  const hashFlag = filterFlags.find((flag) => flag.value === disciplineCheckStatus)

  if (!hashFlag) return null

  return (
    <span
      key={keyProp}
      className="recycling-flag-icon"
      title={title}
      display="contents"
      data-testid="tooltip-discipline-message"
    >
      {React.cloneElement(hashFlag.icon, { size: 17 })}
    </span>
  )
}

DisciplineCheckIcon.propTypes = {
  disciplineCheckStatus: PropTypes.string,
  keyProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  size: PropTypes.number
}
