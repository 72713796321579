import { useMemo } from 'react'
import I18n from '/src/utils/translations'

export default function useScaffoldingPartActionTitle(actionFormMode, type) {
  const title = useMemo(() => {
    const actionTitle = `${I18n.t(`scaffolding_part_actions.${type}_title`)}`
    if (type === 'edit' || type === 'duplicate') return actionTitle
    if (actionFormMode === 'dimension') {
      return I18n.t('scaffolding_parts.menu_items.dimensions')
    }
    return I18n.t('scaffolding_parts.menu_items.modifications')
  }, [actionFormMode, type])

  return title
}
