import React from 'react'
import PropTypes from 'prop-types'
import { MdInfoOutline } from 'react-icons/md'
import { Tooltip } from '@progress/kendo-react-tooltip'

export default function InformationTooltip({ title }) {
  return (
    <Tooltip anchorElement="target" parentTitle position="right">
      <span title={title}>
        <MdInfoOutline />
      </span>
    </Tooltip>
  )
}

InformationTooltip.propTypes = {
  title: PropTypes.string
}

InformationTooltip.defaultProps = {
  title: ''
}
