import React from 'react'
import PropTypes from 'prop-types'

export default function RequiredMark({ required }) {
  return <React.Fragment>{required && <span className="required"> * </span>}</React.Fragment>
}

RequiredMark.propTypes = {
  required: PropTypes.bool
}

RequiredMark.defaultProps = {
  required: false
}
