import { useCallback } from 'react'
import { dispatch } from '/src/hooks/bus/bus'
import useFetch from '/src/hooks/api/fetch'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useOpenFile(onSuccess) {
  const { fetch } = useFetch()

  const openFile = useCallback(
    (url, opts, filename) => {
      dispatch(BusEvents.SHOW_LOADING_DIALOG)

      fetch(
        url,
        {
          requestAction: 'READ',
          httpAction: 'get',
          responseType: 'blob',
          ...opts
        },
        {
          onSuccess: (file) => {
            dispatch(BusEvents.HIDE_DIALOG)
            if (onSuccess) onSuccess()

            const fileURL = URL.createObjectURL(file.data)
            const linkElement = document.createElement('a')
            linkElement.target = '_blank'
            linkElement.rel = 'noopener noreferrer'
            linkElement.href = fileURL
            if (filename) linkElement.download = filename()
            linkElement.click()
          },
          onError: () => {
            dispatch(BusEvents.HIDE_DIALOG)
          }
        }
      )
    },
    [fetch]
  )

  return { openFile }
}
