// eslint-disable-next-line
import I18n from '/src/utils/translations'
// eslint-disable-next-line
import { isJson } from '/src/utils/string'

function clarify(messages) {
  let finalMessages = messages.replace(/\\n/g, '<br>') // Backend to Frontend newliners conversion
  finalMessages = finalMessages.replace(/(^\[")|("\]$)/g, '') // Remove [" and "]
  return finalMessages
}

export default function handleError(e, shouldClarify = false) {
  let err = e
  if (e.xhr) err = e.xhr
  else if (e.XMLHttpRequest) err = e.XMLHttpRequest
  let response = isJson(err.response)
  if (!response) {
    response = err.response
  }
  let messages = ''
  if (shouldClarify && Array.isArray(response)) return clarify(messages)
  if (response) {
    if (response.message) return response.message
    if (Array.isArray(response)) {
      return response
    }
    if (Array.isArray(response.errors)) {
      return response.errors
    }
    if (response.error) {
      return response.error
    }
    if (response.errors) {
      if (response.errors.the_role)
        return {
          title: I18n.t('notification.access_denied'),
          body: I18n.t('notification.access_denied_message')
        }
      messages = Object.keys(response.errors)
        .map((key) => response.errors[key].join(', '))
        .join('<br>')
      return messages
    }
    if (typeof response === 'string') {
      return response
    }
    if (Object.keys(response).length > 0) {
      messages = Object.keys(response)
        .map((key) => `${key}: ${response[key]}`)
        .join('<br>')
      return messages
    }
  }
  return err.statusText
}
