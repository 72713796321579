import React, { Fragment } from 'react'
import { MdAccessTime, MdCheck, MdClose, MdOutlineCalendarMonth } from 'react-icons/md'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import { formattedLocaleDate } from '/src/utils/project_formatter'

export default function PrintListPDFCell({ column, item, project }) {
  const { description, column_type: columnType } = column

  if (isBlank(item[description])) return I18n.t('fields.na')

  const value = item[description]

  if (columnType.description === 'boolean') {
    if (value) return (
      <Fragment>
        <MdCheck fontSize={15} style={{ color: '#455a64' }} />
        <span>{I18n.t('actions.yes')}</span>
      </Fragment>
    )

    return (
      <Fragment>
        <MdClose fontSize={15} style={{ color: '#455a64' }} />
        <span>{I18n.t('actions.no')}</span>
      </Fragment>
    )
  }

  if (columnType.description === 'date_time') {
    const config = {
      value,
      project,
      time: true,
      parser: new IntlService()
    }

    return (
      <Fragment>
        <MdOutlineCalendarMonth style={{ color: '#455a64' }} />
        <span>
          {formattedLocaleDate(config)}
        </span>
      </Fragment>
    )
  }

  if (columnType.description === 'date') {
    const config = {
      value,
      project,
      parser: new IntlService()
    }

    return (
      <Fragment>
        <MdOutlineCalendarMonth style={{ color: '#455a64' }} />
        <span>
          {formattedLocaleDate(config)}
        </span>
      </Fragment>
    )
  }

  if (columnType.description === 'time') {
    return (
      <Fragment>
        <MdAccessTime />
        <span>{value}</span>
      </Fragment>
    )
  }

  return String(value)
}

PrintListPDFCell.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  column: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    column_type: PropTypes.shape({
      description: PropTypes.string
    })
  }).isRequired,
  project: PropTypes.oneOfType([PropTypes.object]).isRequired
}
