import React, { useState, useEffect, useRef } from 'react'
import { Switch } from '@progress/kendo-react-inputs'
import PropTypes from 'prop-types'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/inputs.css'

export default function InputBoolean({ inputProps }) {
  const { id, className, value, onChange, readOnly, title, required, dataItem } = inputProps
  const parsedValue = String(value) === 'true'
  const [inputValue, setValue] = useState(parsedValue)
  const error = useInputError({ inputValue, title, required })

  const inBulkEdit = dataItem && dataItem.inEdit === id
  const bulkEditFirstRender = useRef(true)

  const changeValue = (value) => {
    onChange(id, value)
    setValue(value)
  }

  useEffect(() => {
    if (inBulkEdit && bulkEditFirstRender.current) {
      bulkEditFirstRender.current = false
      return
    }

    const isChecked = parsedValue || false
    if (inputValue === isChecked) {
      onChange(id, inputValue)
    } else {
      changeValue(isChecked)
    }
  }, [id])

  const onChangeInput = (e) => changeValue(e.target.value)

  const inputClass = () => {
    const classes = ['switch']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return <Switch id={id} checked={inputValue} onChange={onChangeInput} className={inputClass()} disabled={readOnly} />
}

InputBoolean.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
  }).isRequired
}
