import { useStore, store } from 'react-context-hook'
import { setToOneMillissecond } from '/src/utils/calendar'

export default function useUpdateLastSync() {
  const [lastSync, setLastSync] = useStore('last_sync')

  const maxUpdatedAt = (sortedData) => {
    if (!sortedData || sortedData.length === 0) return

    let updatedAt = sortedData[sortedData.length - 1].updated_at

    if (!updatedAt) return
    if (updatedAt instanceof Date) updatedAt = updatedAt.toISOString()

    return setToOneMillissecond(updatedAt)
  }

  const maxLastSync = (lastSync1, lastSync2) => {
    if (lastSync1 && lastSync2) return lastSync1 > lastSync2 ? lastSync1 : lastSync2
    if (lastSync1) return lastSync1
    if (lastSync2) return lastSync2
  }

  const setEntityLastSync = (entityName, sortedData, sortedInactives = []) => {
    const dataLastSync = maxUpdatedAt(sortedData)
    const inactiveLastSync = maxUpdatedAt(sortedInactives)
    const entityLastSync = maxLastSync(dataLastSync, inactiveLastSync)
    if (entityLastSync) setLastSync({ ...store.get('last_sync'), [entityName]: entityLastSync })
  }

  return [lastSync, setEntityLastSync]
}
