import { isBlank } from '/src/utils/boolean_refinements'

export function setSidePanelHidden(state) {
  if (isBlank(state.side_panel_hidden)) return
  localStorage.setItem('side_panel_hidden', JSON.stringify(state.side_panel_hidden))
}

export function getSidePanelHidden() {
  let sidePanelHidden = localStorage.getItem('side_panel_hidden')
  return {
    side_panel_hidden: isBlank(sidePanelHidden) ? false : JSON.parse(sidePanelHidden)
  }
}
