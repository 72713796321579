import { useState } from 'react'
import { filterFlags } from '/src/utils/constants/request'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'

export default function useFlagFilterServices({ setPopupOpened, filterGroup, GROUP, updateFilters }) {
  const [flags, setFlags] = useState(filterFlags)

  const findFilter = (selectedFlag, flag) => {
    return selectedFlag.id === flag.id
  }

  const changeFilter = (flag) => {
    setPopupOpened(false)

    const newFlags = flags.map((eachFlag) => ({ ...eachFlag }))

    const { filtered } = flag
    newFlags.filter((newFlag) => findFilter(flag, newFlag)).forEach((newFlag) => (newFlag.filtered = !filtered))

    const filteredFlags = newFlags.filter((eachFlag) => eachFlag.filtered)
    const groupedFilters = {}

    filteredFlags.forEach((eachFilter) => {
      if (!groupedFilters[eachFilter.column]) {
        groupedFilters[eachFilter.column] = []
      }
      groupedFilters[eachFilter.column].push(eachFilter.value)
    })

    const newFilters = Object.entries(groupedFilters).map(([column, values]) => {
      return {
        type: 'where_or',
        column: column,
        operator: IsEqualOperator,
        value: values
      }
    })

    const newLabelNames = filteredFlags.map((eachFilter) => `flags.${eachFilter.i18n_id}`)
    updateFilters(newFilters, GROUP, newLabelNames)
    setFlags(newFlags)
  }

  const clearFilters = () => {
    setPopupOpened(false)
    setFlags(
      flags.map((f) => {
        return { ...f, filtered: false }
      })
    )
    if (filterGroup === GROUP) updateFilters([], null, [])
  }

  return {
    flags,
    setFlags,
    changeFilter,
    clearFilters
  }
}
