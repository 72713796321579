import React from 'react'
import { MdAddCircleOutline, MdCloudDownload } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations.js'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import EmptyState from '/src/ui/core/layouts/empty_state'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/empty_state_grid.css'

export default function GridEmptyState({
  onCreate,
  modelName,
  buttons,
  modelRoute,
  templateId,
  onPopupImportClose,
  message,
  imageSrc,
  className,
  children
}) {
  const modelNameLower = modelName.toLowerCase()

  const printButtons = () => (
    <React.Fragment>
      {buttons && (
        <div className="empty-buttons">
          {templateId !== undefined && (
            <PopupImportFile
              button={
                <button type="button" className="import-from-xls empty-button">
                  <MdCloudDownload />
                  <span>{I18n.t('actions.import')}</span>
                </button>
              }
              modelRoute={modelRoute}
              templateId={templateId}
              onClose={onPopupImportClose}
            />
          )}
          <button type="button" className="create-entity empty-button" onClick={onCreate}>
            <MdAddCircleOutline />
            <span>{`${I18n.t('actions.create')} ${modelName}`}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  )

  return (
    <div className={className}>
      <EmptyState imageSrc={imageSrc} modelNameLower={modelNameLower} message={message} modelRoute={modelRoute}>
        {printButtons()}
        {children}
      </EmptyState>
    </div>
  )
}

GridEmptyState.propTypes = {
  modelName: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  buttons: PropTypes.bool,
  modelRoute: PropTypes.string,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPopupImportClose: PropTypes.func,
  message: PropTypes.string,
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

GridEmptyState.defaultProps = {
  onCreate: undefined,
  buttons: true,
  modelRoute: '',
  templateId: undefined,
  onPopupImportClose: undefined,
  message: undefined,
  imageSrc: 'workflow',
  className: 'empty-state-wrapper',
  children: undefined
}
