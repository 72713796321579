import React from 'react'
import PropTypes from 'prop-types'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import DateColumn from '/src/ui/core/grid/flexible_column/date'
import '/src/static/css/core/blocks/inspection_track_block.css'

export default function InpectionTrackBlock({ children, detail, actions }) {
  const { number, responsible_full_name: responsibleFullName, inspect_date: inspectDate } = detail

  const responsibleFullNameText = responsibleFullName || I18n.t('inspections.inspection_block.not_selected_yet')
  const inspectDateText = inspectDate || I18n.t('inspections.inspection_block.missing_date')

  return (
    <div className="detail-summary">
      <div className="information">
        <div className="title-side-panel-inspection">
          <span className="summary-request-id">{`#${number}`}</span>
          {children && <div className="children">{children}</div>}
        </div>
        <div className="reference-side-panel">
          <div className="summary-label">{I18n.t('inspections.inspection_block.inspection_date')}</div>
          <div className="summary-value">
            {inspectDateText !== I18n.t('inspections.inspection_block.missing_date') ? (
              <DateColumn value={inspectDateText} />
            ) : (
              inspectDateText
            )}
          </div>
          <div className="summary-label">{I18n.t('inspections.inspection_block.responsible')}</div>
          <div
            className={`summary-value responsibility ${
              responsibleFullNameText !== I18n.t('inspections.inspection_block.not_selected_yet')
                ? 'responsible'
                : 'no-responsible'
            }`}
          >
            {responsibleFullNameText}
          </div>
        </div>
      </div>
      <div className="summary-buttons">{actions}</div>
    </div>
  )
}

InpectionTrackBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]),
  detail: PropTypes.shape({
    reason: PropTypes.string,
    comments: PropTypes.string,
    number: PropTypes.number,
    responsible_full_name: PropTypes.string,
    inspect_date: PropTypes.string
  }).isRequired,
  actions: PropTypes.element
}

InpectionTrackBlock.defaultProps = {
  children: undefined,
  actions: null
}
