import React from 'react'
import PropTypes from 'prop-types'

export default function ShadowColumn({ value }) {
  return <div className="unit-wrapper">{value && <div className="unit">{value}</div>}</div>
}

ShadowColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string])
}

ShadowColumn.defaultProps = {
  value: undefined
}
