import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { titleize } from 'inflected'
import { StringParam, useQueryParam } from 'use-query-params'
import I18n from '/src/utils/translations'
import LoadingBatchEntities from '/src/ui/core/popups/loading_batch_entities'
import Page from '/src/ui/core/layouts/page'
import '/src/static/css/layout.css'

const translate = (title, param) => {
  if (title.includes('[missing')) return titleize(param)
  return title
}

export default function Layout({ children, showProjectSwitcher }) {
  const url = useParams()
  const [page] = useQueryParam('page', StringParam)

  const title = []

  if (page) {
    title.push(translate(I18n.t(`shortcuts.${page}`), url.collection))
  } else if (url.collection) {
    title.push(translate(I18n.t(`collections.${url.collection}`), url.collection))
  }

  url.resource && title.push(translate(I18n.t(`top_menu.${url.resource}`), url.resource))
  url.id && title.push(`${url.id}`)

  title.push('DPMS')

  document.title = title.join(' - ')

  return (
    <div className="body-wrapper">
      <LoadingBatchEntities />
      <Page showProjectSwitcher={showProjectSwitcher}>{children}</Page>
    </div>
  )
}

Layout.propTypes = {
  showProjectSwitcher: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  showNewButton: PropTypes.bool
}

Layout.defaultProps = {
  showProjectSwitcher: true,
  showNewButton: false
}
