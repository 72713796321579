/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFetchDescription from '/src/ui/domain/eav_columns/fetch_description'
import LabelColumn from '/src/ui/core/grid/label_column'
import ScopeCard from './scope_card'
import '/src/static/css/domain/sub_requests/sub_request.css'

export default function ScopesAllocation({ scopes, scopeCluster, label, templateId }) {
  const [globalProject] = useStore('project')
  const clusterQuantity = formatNumberWithPrecision(scopeCluster.quantity, globalProject)
  const [scopesFullInfo, setScopesFullInfo] = useState([])
  const getScopesFullInfo = useFetchAPI('scopes')
  const descriptionColumn = useFetchDescription(templateId)

  useEffect(() => {
    if (!scopes.length) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: scopes.map((a) => a.id).join() } }
    }

    getScopesFullInfo.fetchAPI(params)
  }, [scopes])

  useEffect(() => {
    if (getScopesFullInfo.status !== 'SUCCESS') return

    setScopesFullInfo(getScopesFullInfo.responseData.data)
  }, [getScopesFullInfo.status, getScopesFullInfo.responseData])

  const progressBarValue = () => {
    const value = scopeCluster.quantity - scopeCluster.remaining_quantity
    return Math.min(value, scopeCluster.quantity)
  }

  const getDescription = (id) => {
    if (!scopesFullInfo.length) return I18n.t('search.loading')
    return scopesFullInfo.find((scope) => scope.id === id)[descriptionColumn]
  }

  return (
    <div className="scopes-allocation" data-testid="scopes-allocation">
      <div className="allocation-title" data-testid="allocation-title">
        <span>{I18n.t('sub_requests.left_panel.total_allocation')}</span>
      </div>
      <div className="progress-bar-values" data-testid="progress-bar-values">
        <span>{formatNumberWithPrecision(0, globalProject)}</span>
        <span>{clusterQuantity}</span>
      </div>
      <ProgressBar
        max={scopeCluster.quantity}
        value={progressBarValue()}
        labelVisible={false}
        emptyClassName="progress-empty-bar"
        progressClassName="progress-filled-bar"
      />
      <div className="scope-cards-header">
        <span className="title">{I18n.t('sub_requests.left_panel.cards_header_title')}</span>
        <LabelColumn value={label} />
      </div>
      {scopes.map((scope) => (
        <ScopeCard key={scope.id} scope={scope} label={label} description={getDescription(scope.id)} />
      ))}
    </div>
  )
}

ScopesAllocation.propTypes = {
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantity: PropTypes.number,
      remaining_quantity: PropTypes.number,
      team_target_hours: PropTypes.number
    })
  ).isRequired,
  scopeCluster: PropTypes.shape({
    quantity: PropTypes.number,
    remaining_quantity: PropTypes.number
  }).isRequired,
  label: PropTypes.string,
  templateId: PropTypes.number
}

ScopesAllocation.defaultProps = {
  label: '',
  templateId: null
}
