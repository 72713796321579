import React from 'react'
import PropTypes from 'prop-types'
import { indexify } from '/src/utils/array'
import BatchEditionDeleteButton from '/src/ui/core/popups/columns_batch_assigner/batch_edition_delete_button'
import BatchEditionUpdateButton from '/src/ui/core/popups/columns_batch_assigner/batch_edition_update_button'
import { isDatasheetFilter } from '/src/models/concerns/eav_column'
import ColumnsBatchAssignerAction from '/src/ui/core/popups/columns_batch_assigner/columns_batch_assigner_action'
import '/src/static/css/core/popups/column_assigner.css'

export default function ColumnsBatchAssignerActions({ columns, columnValuePairs, onSelectAction, onDeleteAction }) {
  const columnsIndexified = indexify(columns, 'description')

  return (
    <div className="columns-batch-assigner__actions">
      {Object.keys(columnValuePairs).map((columnDescription) => {
        const column = columnsIndexified[columnDescription]
        const columnValue = columnValuePairs[columnDescription]

        return (
          <div key={columnDescription} className="columns-batch-assigner__action">
            {isDatasheetFilter(column) ? (
              <div className="columns-batch-assigner__datasheet-filter-actions">
                {Object.keys(columnValue).map((columnDesc) => (
                  <ColumnsBatchAssignerAction
                    key={columnsIndexified[columnDesc].title}
                    columnTitle={columnsIndexified[columnDesc].title}
                    columnValue={columnValue[columnDesc]}
                  />
                ))}
              </div>
            ) : (
              <ColumnsBatchAssignerAction columnTitle={column.title} columnValue={columnValue} />
            )}
            <div className="columns-batch-assigner__action-buttons">
              <BatchEditionUpdateButton onEditAction={() => onSelectAction(column, columnValue)} />
              <BatchEditionDeleteButton column={column} onDeleteAction={onDeleteAction} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

ColumnsBatchAssignerActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnValuePairs: PropTypes.object,
  onSelectAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func
}

ColumnsBatchAssignerActions.defaultProps = {
  columnValuePairs: {},
  onDeleteAction: undefined
}
