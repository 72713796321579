import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import '/src/static/css/notifications.css'
import Notification from './notification'
import generateHexString from '/src/utils/generator'
import I18n from '/src/utils/translations'

export default function NotificationGroup() {
  const [show, setShow] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [globalNotification] = useStore('notification')
  const [groupClass, setGroupClass] = useState('notification-group')

  const removeFromNotifications = (key) => {
    setNotifications((notes) => notes.filter((note) => note.hashKey !== key))
  }

  const notificationWithHashKey = (notificationWithoutKey) => {
    const notification = { ...notificationWithoutKey }
    let key = ''
    do {
      key = generateHexString(4)
    } while (notifications.some((e) => e.hashKey === key))
    notification.hashKey = key
    return notification
  }

  const addToNotifications = (notificationsArray) => {
    if (Array.isArray(globalNotification)) {
      const notificationsGroup = globalNotification.map((notification) => notificationWithHashKey(notification))
      return [...notificationsGroup, ...notificationsArray]
    }
    return [notificationWithHashKey(globalNotification), ...notificationsArray]
  }

  useEffect(() => {
    if (globalNotification) {
      setNotifications((notificationsArray) => addToNotifications(notificationsArray))
      setShow(true)
    }
  }, [globalNotification])

  useEffect(() => {
    if (notifications.length === 0) setShow(false)
  }, [notifications])

  useEffect(() => {
    let newClass = 'notification-group'
    if (show) newClass += ' show'
    setGroupClass(newClass)
  }, [show])

  const dismissAll = () => {
    setShow(false)
    setTimeout(() => {
      setNotifications([])
    }, 300)
  }

  return (
    <React.Fragment>
      {notifications.length > 0 && (
        <div className={groupClass}>
          {notifications.map((notification) => {
            return (
              <Notification
                body={notification.body}
                hashKey={notification.hashKey}
                key={notification.hashKey}
                title={notification.title}
                status={notification.status}
                closeSelf={removeFromNotifications}
                closable={notification.closable}
                closeTimeout={notification.closeTimeout}
              />
            )
          })}

          {notifications.length > 1 && (
            <button data-testid="dismiss-all" type="button" className="dismiss-all" onClick={dismissAll}>
              {I18n.t('notification.dismiss_all')}
            </button>
          )}
        </div>
      )}
    </React.Fragment>
  )
}
