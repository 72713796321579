import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import RequestModel from '/src/models/request'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import RequestDetailSummary from '/src/ui/domain/requests/request_detail_summary'
import SubRequestListHeader from '/src/ui/domain/sub_requests/sub_request_list_header'
import ClusterBlock from '/src/ui/core/blocks/cluster_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import GeneralBlock from '/src/ui/core/blocks/general_block'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getInfoTab, getInspectionsTab } from '/src/ui/core/tabs/get_tabs'
import ScheduleSidePanelBlock from '/src/ui/domain/requests/schedule_side_panel_block'
import TimelineSidePanelBlock from '/src/ui/domain/requests/timeline_side_panel_block'
import '/src/static/css/core/grid/grid.css'

export default function RequestsPageSidePanel({ dataItem, sections, onClose, onBlocksBuild, hideSummaryButtons }) {
  const requestModel = new RequestModel()
  const [blocks, setBlocks] = useState()
  const [subRequests, setSubRequests] = useState([])
  const subRequestsFetch = useFetchAPI('requests')

  const buildScheduleBlock = () => {
    return {
      component: <ScheduleSidePanelBlock dataItem={dataItem} />,
      title: I18n.t('requests.schedule_form.schedule'),
      type: 'schedule'
    }
  }

  const buildTimelineBlock = () => {
    return {
      component: <TimelineSidePanelBlock request={dataItem} />,
      title: I18n.t('timeline.title'),
      type: 'general',
      collapsible: true
    }
  }

  const buildSummary = () => {
    return {
      component: (
        <RequestDetailSummary dataItem={dataItem} requestModel={requestModel} hideButtons={hideSummaryButtons} />
      ),
      title: '',
      type: 'track'
    }
  }

  const buildSubRequests = () => {
    return {
      component: (
        <ClusterBlock
          dataItems={subRequests}
          dataKey="id"
          link={{
            url: '/requests',
            params: [{ fromDataItem: true, column: 'request_id', value: 'id' }]
          }}
          onSubrequestClick={onClose}
        />
      ),
      maxHeight: '420px',
      title: <SubRequestListHeader subRequests={subRequests} />,
      type: 'cluster'
    }
  }

  const buildGeneral = () => {
    return {
      component: <GeneralBlock model={requestModel} dataItem={dataItem} />,
      title: I18n.t('form.general_information'),
      type: 'general'
    }
  }

  const sectionBlocks = useSectionBlock({ sections, dataItem, openModal: !hideSummaryButtons })

  const buildBlocks = () => {
    if (subRequests.length > 0)
      return [
        buildSummary(),
        buildTimelineBlock(),
        buildSubRequests(),
        buildGeneral(),
        buildScheduleBlock(),
        ...sectionBlocks
      ]

    return [buildSummary(), buildTimelineBlock(), buildGeneral(), buildScheduleBlock(), ...sectionBlocks]
  }

  useEffect(() => {
    if (!dataItem) return

    const subRequestsParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { parent_id: dataItem.id } }
    }

    subRequestsFetch.fetchAPI(subRequestsParams)
  }, [dataItem])

  useEffect(() => {
    if (subRequestsFetch.loading) return
    if (subRequestsFetch.errors) return

    setSubRequests(subRequestsFetch.responseData.data)
  }, [subRequestsFetch.loading, subRequestsFetch.errors, subRequestsFetch.responseData])

  useEffect(() => {
    if (!subRequests || !dataItem) return

    setBlocks(buildBlocks())
    onBlocksBuild()
  }, [subRequests])

  const infoBlocks = buildBlocks(sectionBlocks)

  const infoTab = getInfoTab(infoBlocks, !hideSummaryButtons ? 'info' : 'preview_details')
  const inspectionsTab = getInspectionsTab({ ...dataItem, request_id: dataItem.id }, 'Request', !hideSummaryButtons)

  return (
    dataItem && (
      <BlocksSidePanel
        title={I18n.t('requests.request_detail.side_panel_title')}
        closable={!hideSummaryButtons}
        onClose={onClose}
      >
        <SidePanelTabs tabs={[infoTab, inspectionsTab]} />
      </BlocksSidePanel>
    )
  )
}

RequestsPageSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  onBlocksBuild: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  hideSummaryButtons: PropTypes.bool
}

RequestsPageSidePanel.defaultProps = {
  dataItem: null,
  onClose: () => {},
  onBlocksBuild: () => {},
  sections: [],
  hideSummaryButtons: false
}
