import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/buttons/cancel_button.css'

export default function CancelButton({ title, onClick }) {
  return (
    <button type="button" className="button-cancel" onClick={onClick}>
      {title}
    </button>
  )
}

CancelButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

CancelButton.defaultProps = {
  onClick: () => {}
}
