import React from 'react'
import SectionBlock from '/src/ui/core/blocks/section_block'

export default function useSectionBlock({ sections, dataItem, openModal }) {
  const buildSections = () => {
    return sections
      .sort((sec1, sec2) => sec1.position - sec2.position)
      .flatMap((section) => {
        const filteredColumns = section.eav_columns.filter((field) => field.visible_on_web)

        if (filteredColumns.length === 0) return []

        return [
          {
            component: <SectionBlock eavColumns={filteredColumns} dataItem={dataItem} openModal={openModal ?? true} />,
            title: section.title,
            type: 'section',
            key: section.id
          }
        ]
      })
  }

  return dataItem && sections ? buildSections() : []
}
