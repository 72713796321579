import { useCallback } from 'react'
import useDatasheetFilters from '/src/hooks/datasheet_filters'

export default function useSearchDatasheetFilters(templateId, columns) {
  const {
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns,
    isLoading
  } = useDatasheetFilters(templateId, {}, true)

  const isDatasheetFilterSingle = useCallback(
    (column) => {
      if (!datasheetFilters || !multipleDatasheetFilters) return false
      if (!datasheetFilters[column.id] && !multipleDatasheetFilters[column.id]) return false

      const datasheetFilter = datasheetFilters[column.id] || multipleDatasheetFilters[column.id]

      const sameTagColumns = columns
        .filter(({ id, metadata }) => id !== column.id && metadata === column.metadata)
        .filter(({ id }) => {
          const currentFilter = datasheetFilters[id] || multipleDatasheetFilters[id]
          return currentFilter && datasheetFilter.datasheet_template_id === currentFilter.datasheet_template_id
        })

      return sameTagColumns.length === 0
    },
    [datasheetFilters, multipleDatasheetFilters, columns]
  )

  return [
    isLoading || !datasheetColumns,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns,
    isDatasheetFilterSingle
  ]
}
