import React from 'react'
import PropTypes from 'prop-types'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import FieldFactory from '/src/ui/core/fields/field_factory'
import { byString } from '/src/utils/object'
import ScaffoldingTypeColumn from '/src/ui/domain/scaffoldings/scaffolding_type_column'
import ScaffoldingPartTotalColumn from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'
import ErectMethodColumn from '/src/ui/domain/scaffoldings/erect_method_column'

export default function ScaffoldingPartCellFactory({ field, dataItem, value }) {
  const { columns } = new ScaffoldingPartModel()
  const column = columns.find((c) => c.description == field)

  switch (field) {
    case 'scaffolding_type_id':
      return <ScaffoldingTypeColumn column={column} value={dataItem.scaffolding_type} />
    case 'erect_method':
      return <ErectMethodColumn value={byString(dataItem, field)} />
    case 'total':
      return <ScaffoldingPartTotalColumn dataItem={dataItem} />
    default:
      return <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
  }
}

ScaffoldingPartCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()])
}

ScaffoldingPartCellFactory.defaultProps = {
  field: '',
  dataItem: undefined,
  value: undefined
}
