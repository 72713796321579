import { useState, useEffect } from 'react'

const initialSkipValue = (page, pageSize) => (page ? (page - 1) * pageSize : 0)

export default function usePaging(pageSize, page, onChange) {
  const [paging, setPaging] = useState({ skip: initialSkipValue(page, pageSize), pageSize })

  useEffect(() => {
    setPaging({ skip: initialSkipValue(page, pageSize), pageSize })
  }, [pageSize, page])

  const onPageChange = (event, offset) => {
    if (onChange) onChange()
    const newSkip = offset < 1 ? 0 : (offset - 1) * pageSize
    setPaging((prevState) => {
      return { ...prevState, skip: newSkip }
    })
  }

  return { paging, onPageChange }
}
