import React from 'react'
import { MdAttachMoney, MdFlag, MdEdit, MdDescription } from 'react-icons/md'
import { GiTargeted } from 'react-icons/gi'
import { IoMdSpeedometer } from 'react-icons/io'
import { FaCheckDouble } from 'react-icons/fa'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label.jsx'
import { withRouter, useHistory } from 'react-router-dom'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import QRCode from 'qrcode.react'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'
import useRequestsPinned from '/src/hooks/requests_pinned'
import EstimateModel from '/src/models/estimate'
import useConfigureWorkPackageFile from '/src/ui/domain/requests/popups/configure_work_package_file'
import '/src/static/css/core/blocks/request_detail_summary.css'
import '/src/static/css/core/blocks/track_block.css'

export default withRouter(function RequestDetailSummary({ dataItem, requestModel, hideButtons = false }) {
  const estimateModel = new EstimateModel()
  const history = useHistory()
  const { setPinnedRequests } = useRequestsPinned()
  const requestStatus = dataItem.request_status.i18n_id
  const nonEditableStatuses = ['canceled', 'deleting', 'done']
  const isEditable = !nonEditableStatuses.includes(requestStatus)

  const [openWorkPackagePopup, printWorkPackagePopup] = useConfigureWorkPackageFile()

  const getOptionVisibility = (option) => {
    const visibilities = {
      canceled: [],
      deleting: [],
      clustered: ['scopings'],
      registered: ['inspections'],
      confirmed: ['scopings', 'estimates', 'inspections'],
      in_scoping: ['scopings', 'estimates', 'inspections'],
      authorization_pending: ['scopings', 'estimates', 'inspections'],
      on_hold: ['scopings', 'estimates', 'inspections'],
      in_planning: ['scopings', 'estimates', 'planning', 'performance', 'inspections'],
      in_performance: ['scopings', 'estimates', 'planning', 'performance', 'inspections'],
      done: ['scopings', 'estimates', 'planning', 'performance', 'inspections']
    }
    const statusArray = visibilities[requestStatus]

    return statusArray && statusArray.includes(option)
  }

  const onClickEstimates = () =>
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      payload: [dataItem],
      modelName: estimateModel.paramName
    })

  const contextMenuIcons = [
    {
      text: I18n.t('requests.request_detail.scopes'),
      icon: <GiTargeted />,
      onClick: (e, data) => {
        setPinnedRequests([dataItem])
        history.push(`/scopes`)
      },
      visible: () => getOptionVisibility('scopings')
    },
    {
      text: I18n.t('requests.request_detail.estimates'),
      icon: <MdAttachMoney />,
      onClick: (e, data) => {
        onClickEstimates()
      },
      visible: () => getOptionVisibility('estimates')
    },
    {
      text: I18n.t('requests.request_detail.planning'),
      icon: <MdFlag />,
      onClick: (e, data) => {
        setPinnedRequests([dataItem])
        history.push(`/plannings`)
      },
      visible: () => getOptionVisibility('planning')
    },
    {
      text: I18n.t('requests.request_detail.performance'),
      icon: <IoMdSpeedometer />,
      onClick: (e, data) => {
        setPinnedRequests([dataItem])
        history.push(`/performances`)
      },
      visible: () => getOptionVisibility('performance')
    },
    {
      text: I18n.t('requests.request_detail.inspections'),
      icon: <FaCheckDouble />,
      onClick: (e, data) => {
        setPinnedRequests([dataItem])
        history.push(`/inspections`)
      },
      visible: () => getOptionVisibility('inspections')
    },
    {
      text: I18n.t('actions.generate_work_package'),
      icon: <MdDescription />,
      onClick: () => openWorkPackagePopup(dataItem),
      visible: () => true
    }
  ]

  return (
    <div className="detail-summary">
      {printWorkPackagePopup}
      <div className="request-main-info">
        <div>
          <div className="title-side-panel">
            DPMSID
            <span className="summary-request-id">#{dataItem.id}</span>
          </div>
          <div className="detail-row">
            <RequestStatusLabel dataItem={dataItem} />
          </div>
        </div>
        <QRCode value={dataItem.id.toString()} size={76} />
      </div>
      <div className="detail-field" data-testid="detail-reference-title">
        <span>{I18n.t('requests.reason')}</span>
        <p>{dataItem.reason}</p>
        <span>{I18n.t('requests.comments')}</span>
        <p>{dataItem.comments}</p>
      </div>
      {history && !hideButtons && (
        <div className="actions-row">
          {contextMenuIcons.filter((x) => x.visible()).length > 0 && (
            <div className={`track-block-buttons${!isEditable ? ' show-button-expanded' : ''}`}>
              {isEditable && (
                <Button
                  text={I18n.t('requests.request_detail.edit')}
                  icon={<MdEdit />}
                  className="edit-button-track-block"
                  onClick={() => {
                    dispatch({
                      type: BusEvents.OPEN_GRID_FORM_MODAL,
                      modelName: requestModel.paramName,
                      payload: { dataItem, formType: 'edit' }
                    })
                  }}
                  role="edit-button-panel"
                />
              )}
              <PopupButton items={contextMenuIcons} text={I18n.t('requests.request_detail.show')} role="show-button" />
            </div>
          )}
        </div>
      )}
    </div>
  )
})
