import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md'
import I18n from '/src/utils/translations'

const EmptyStateButton = ({ path, label }) => (
  <Link to={path}>
    <button type="button" className="custom-back-button">
      <MdArrowBack />
      {` ${I18n.t('actions.back_to', { page: label })}`}
    </button>
  </Link>
)

EmptyStateButton.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default EmptyStateButton
