import React from 'react'
import PropTypes from 'prop-types'
import { MdBorderAll, MdGesture, MdWaves } from 'react-icons/md'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/cells.css'

const labelBackgroundColor = (erectMethod) => {
  if (erectMethod === 'tt') return 'rounded-label-oversea-rope-access'
  if (erectMethod === 'os') return 'rounded-label-oversea'
  return 'rounded-label-standard'
}

const getIcon = (erectMethod) => {
  if (erectMethod === 'tt') return <MdGesture data-testid="icon-gesture" />
  if (erectMethod === 'os') return <MdWaves data-testid="icon-waves" />
  return <MdBorderAll data-testid="icon-border-all" />
}

export default function ErectMethodColumn({ value }) {
  const translationKey = value
  if (!translationKey || translationKey === '') return null

  return (
    <div data-test-id="rounded-label" className="align-cells-center">
      <div className={`rounded-label ${labelBackgroundColor(value)}`}>
        <span className="rounded-label-indicator">{getIcon(value)}</span>
        <span>{I18n.t(`scaffolding_parts.erect_methods.${translationKey}`)}</span>
      </div>
    </div>
  )
}

ErectMethodColumn.propTypes = {
  value: PropTypes.string
}

ErectMethodColumn.defaultProps = {
  value: ''
}
