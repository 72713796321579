import { useReducer } from 'react'
import useFetch from '/src/hooks/api/fetch'
import useFirstFlexibleColumn from './first_flexible_column'

const initialState = {
  templateId: null,
  isLoadingTemplates: false,
  parentTemplateId: null,
  inspectedColumn: null,
  isLoadingColumns: false,
  firstInspectedServiceColumn: null
}

const reducer = (state, action) => {
  const { type, payload } = action

  // eslint-disable-next-line default-case
  switch (type) {
    case 'fetchingColumns':
      return { ...state, ...payload, isLoadingColumns: true }

    case 'columnsFetched':
      return { ...state, ...payload, isLoadingColumns: false }

    case 'fetchingTemplates':
      return { ...state, ...payload, isLoadingTemplates: true }

    case 'templatesFetched':
      return { ...state, ...payload, isLoadingTemplates: false }
  }
}

export default function useInspectionTemplateFetches() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const isLoadingTemplatesOrColumns = state.isLoadingColumns || state.isLoadingTemplates

  const { fetch } = useFetch()
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()

  /**
   * Fetches the eav_columns for the selected value.
   * Does not return the value, but sets the state `inspectedColumn`,
   * that is returned by the hook.
   * @param {number} localSelectedValue - The id of the eav_template
   */
  const fetchColumns = (localSelectedValue) => {
    if (localSelectedValue === state.templateId) return

    dispatch({
      type: 'fetchingColumns',
      payload: { templateId: localSelectedValue }
    })

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { eav_template_id: localSelectedValue } }
    }

    fetch('eav_columns', params, {
      onSuccess: ({ data }) => {
        const columns = data.data
        const newInspectedColumn = filterFirstFlexibleColumn(columns) || {}

        dispatch({
          type: 'columnsFetched',
          payload: { inspectedColumn: newInspectedColumn }
        })
      }
    })
  }

  /**
   * Fetches the eav_templates for the selected value.
   * Does not return the value, but sets the state `firstInspectedServiceColumn`,
   * that is returned by the hook.
   * @param {number} localSelectedValue - The id of the parent_template_id
   */
  const fetchTemplates = (localSelectedValue, serviceType) => {
    if (localSelectedValue === state.parentTemplateId) return

    dispatch({
      type: 'fetchingTemplates',
      payload: { parentTemplateId: localSelectedValue }
    })

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          parent_template_id: localSelectedValue,
          template_type: serviceType
        }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: async ({ data }) => {
        const { data: eavColumns } = await fetch('eav_columns', {
          requestAction: 'READ',
          httpAction: 'get',
          query: { where: { eav_template_id: data.data[0]?.id } }
        })

        const newInspectedServiceColumn = filterFirstFlexibleColumn(eavColumns.data) || {}

        dispatch({
          type: 'templatesFetched',
          payload: { firstInspectedServiceColumn: newInspectedServiceColumn }
        })
      }
    })
  }

  /**
   * Returns an array of [textDisplayFields, searchFields], calculated from the
   * inspectedType and other filter values.
   * @param {object} filter - The current filter object
   * @param {string} inspectedType - The type of inspected module
   * (e.g., 'Scaffolding', 'estimate_services', 'progress_services')
   * @returns An array [textDisplayFields, searchFields] with calculated values
   */
  const getTextDisplayAndSearchFields = (filter, inspectedType, isServiceStep) => {
    const { inspectedColumn, firstInspectedServiceColumn } = state

    if (inspectedType === 'Scaffolding') {
      return [
        ['tag_number', 'description'],
        ['tag_number', 'description']
      ]
    }

    if (isServiceStep) {
      return [
        [...filter?.textDisplayFields, firstInspectedServiceColumn?.description],
        [firstInspectedServiceColumn?.description]
      ]
    }

    if (inspectedColumn) {
      return [[...filter.textDisplayFields, inspectedColumn.description], [inspectedColumn.description]]
    }

    return [filter.textDisplayFields, filter.searchFields]
  }

  return {
    inspectedColumn: state.inspectedColumn,
    isLoadingTemplatesOrColumns,
    fetchColumns,
    fetchTemplates,
    getTextDisplayAndSearchFields
  }
}
