import React, { useState } from 'react'
import { MdChevronLeft, MdClose } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

// eslint-disable-next-line max-lines-per-function
export default function PopupNewLabel({ show, close, back, applyLabel }) {
  const [value, setValue] = useState()

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSubmit = (event) => {
    applyLabel(value)
    event.preventDefault()
  }

  return (
    <div className="new-label" style={{ display: show ? 'block' : 'none' }}>
      <div className="new-label-title">
        <MdChevronLeft onClick={back} data-testid="back" />
        <h4>{I18n.t('scopes.popup_label.new_label')}</h4>
        <MdClose onClick={close} data-testid="close" />
      </div>
      <form className="new-label-form" onSubmit={handleSubmit}>
        <label htmlFor="label">
          {I18n.t('scopes.popup_label.name_label')}
          <input id="label" type="text" defaultValue={value} onChange={handleChange} />
        </label>
        <button type="submit" disabled={!value} className="k-button" data-testid="submit">
          {I18n.t('scopes.popup_label.create_label')}
        </button>
      </form>
    </div>
  )
}

PopupNewLabel.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  applyLabel: PropTypes.func.isRequired
}
