import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { estimatesPrice, estimateIsReadOnly } from '/src/models/estimate'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateFormDetailsFooter({ estimates, statuses }) {
  const [project] = useStore('project')

  const estimateList = useMemo(() => {
    if (!estimates) return []
    return Object.values(estimates).reduce((acc, val) => acc.concat(val), [])
  }, [estimates])

  const approvedEstimates = useMemo(() => {
    return estimateList.filter((estimate) => estimateIsReadOnly(estimate, statuses))
  }, [estimateList, statuses])

  const pedingEstimates = useMemo(() => {
    return estimateList.filter((estimate) => !estimateIsReadOnly(estimate, statuses))
  }, [estimateList, statuses])

  return (
    <div className="footer">
      <div className="price-content">
        <div className="description">{I18n.t('estimate.total_approved')}</div>
        <div className="total-price">{estimatesPrice(approvedEstimates, project)}</div>
      </div>
      <div className="price-content">
        <div className="description">{I18n.t('estimate.total_pending')}</div>
        <div className="total-price">{estimatesPrice(pedingEstimates, project)}</div>
      </div>
      <div className="price-content">
        <div className="description">{I18n.t('estimate.total_request')}</div>
        <div className="total-price">{estimatesPrice(estimateList, project)}</div>
      </div>
    </div>
  )
}

EstimateFormDetailsFooter.propTypes = {
  estimates: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          estimate_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      )
    ])
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

EstimateFormDetailsFooter.defaultProps = {
  estimates: undefined,
  statuses: {}
}
