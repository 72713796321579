import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import useCookie from '/src/hooks/cookie'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import Home from '/src/pages/home'
import Login from '/src/pages/login'
import ForgotPassword from '/src/ui/domain/login/forgot_password'
import ScopesPage from '/src/pages/scopes_page'
import SettingsPage from '/src/pages/settings_page'
import SettingsEmbedPage from '/src/pages/settings_embed_page'
import EstimateServicesPage from '/src/pages/estimate_services_page'
import PlanningPage from '/src/pages/planning_page'
import Inspections from '/src/pages/inspections'
import InspectionsCalendarPage from '/src/pages/inspections_calendar'
import DatasheetsPage from '/src/pages/datasheets_page'
import ProgressesPage from '/src/pages/progresses_page'
import ProgressHistoryPage from '/src/pages/progress_history_page'
import Report from '/src/pages/report'
import SubRequest from '/src/pages/sub_request'
import RequestsPage from '/src/pages/requests_page'
import EavTemplatePage from '/src/pages/eav_template_page'
import UserFormPage from '/src/pages/user_form_page'
import ScaffoldingsPage from '/src/pages/scaffoldings_page'
import Layout from '/src/ui/core/layouts/layout'
import { notifyWarning } from '/src/ui/core/dialogs/notifications'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'

// eslint-disable-next-line max-lines-per-function
export default function AuthRequired({ match, onLoadingUser }) {
  const [floatingUser, setFloatingUser] = useState()
  const [globalUser] = useStore('user')
  const [getToken, setToken] = useCookie('remember_token')
  const getUser = useFetchAPI('users')
  const [floatingSubproject, setFloatingSubproject] = useState()
  const [globalSubproject, setGlobalSubproject] = useStore('subproject')
  const getSubprojects = useFetchAPI('users')
  const [globalProject, setGlobalProject] = useStore('project')
  const getProjects = useFetchAPI('users')
  const history = useHistory()
  const loadingComponent = (
    <Layout showProjectSwitcher={false}>
      <ThreeDotsLoader />
    </Layout>
  )
  const [page, setPage] = useState(loadingComponent)

  const clearBatchEntities = useClearBatchEntities()

  const toRender = {
    home: () => <Home />,
    requests: () => <RequestsPage match={match} />,
    'requests/:id/sub_requests': () => <SubRequest match={match} />,
    scopes: () => <ScopesPage match={match} />,
    'scopes/clusters': () => <ScopesPage match={match} />,
    settings: () => <SettingsPage user={globalUser} />,
    'settings/edit': () => <SettingsEmbedPage user={globalUser} />,
    estimate_services: () => <EstimateServicesPage />,
    plannings: () => <PlanningPage />,
    performances: () => <ProgressesPage />,
    'performances/:id/history': () => <ProgressHistoryPage match={match} />,
    'progress_services/:id/history': () => <ProgressHistoryPage match={match} />,
    inspections: () => <Inspections />,
    'inspections/calendar': () => <InspectionsCalendarPage />,
    datasheets: () => <DatasheetsPage />,
    scaffoldings: () => <ScaffoldingsPage match={match} />,
    reports: () => <Report />,
    eav_templates: () => <EavTemplatePage />,
    'users/:id/edit': () => <UserFormPage match={match} user={globalUser} />,
    'not-found': () => <h1>Not found</h1> // TODO: a component for this
  }

  const render = () => {
    if (match.isExact && match.url === '/') {
      return toRender.home()
    }

    let routePath = match.params.resource
    if (match.params.id) routePath = `${routePath}/:id`
    if (match.params.collection) routePath = `${routePath}/${match.params.collection}`

    if (routePath.match(/login/) || routePath.match(/forgot_password/)) {
      routePath = 'home'
    } else if (!Object.keys(toRender).includes(routePath)) {
      routePath = 'not-found'
    }
    return toRender[routePath]()
  }

  const fetchUser = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      resourceId: globalUser.id
    }
    getUser.fetchAPI(params)
  }

  const fetchUserSubproject = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      resourceId: globalUser.id,
      additionalResource: { path: 'subprojects' }
    }
    getSubprojects.fetchAPI(params)
  }

  const fetchUserProject = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      resourceId: globalUser.id,
      additionalResource: { path: 'projects' }
    }
    getProjects.fetchAPI(params)
  }

  const validateToken = () => {
    fetchUser()
  }

  const clearSubproject = () => {
    if (globalSubproject) setGlobalSubproject({})
    if (globalProject) setGlobalProject({})
  }

  const forceHomePage = () => {
    setPage(<Home />)
    const msg = I18n.t('notification.select_subproject')
    notifyWarning({ body: msg, closeTimeout: 10 })
    history.push(``)
  }

  const cleanLogin = () => {
    clearSubproject()
    clearBatchEntities()
    forceHomePage()
  }

  useEffect(() => {
    const {
      responseData: { data },
      status
    } = getUser
    if (status === 'FETCHING') setPage(loadingComponent)
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      const user = data[0]
      setFloatingUser(user)
      Sentry.setUser({
        email: user.email,
        name: user.name,
        id: user.id,
        subproject: user.subproject_id
      })
    }
  }, [getUser.responseData, getUser.status])

  useEffect(() => {
    if (floatingUser) {
      if (floatingUser.remember_token && floatingUser.remember_token !== getToken()) {
        setToken(floatingUser.remember_token)
        dispatch(BusEvents.REMEMBER_TOKEN_UPDATED)
      }
      onLoadingUser(floatingUser)
      fetchUserSubproject()
    }
  }, [floatingUser])

  useEffect(() => {
    const {
      responseData: { data },
      status
    } = getSubprojects
    if (status === 'FETCHING' || status === 'NOT_STARTED') return
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      if (!floatingUser.subproject_id) {
        cleanLogin()
      } else {
        const userSubproject = data.find((subproject) => {
          return subproject.id === floatingUser.subproject_id
        })

        if (!userSubproject) {
          forceHomePage()
          return
        }

        setFloatingSubproject(userSubproject)
        fetchUserProject()
      }
    }
  }, [floatingUser, getSubprojects.responseData, getSubprojects.status])

  useEffect(() => {
    const {
      responseData: { data },
      status
    } = getProjects
    if (status === 'FETCHING' || status === 'NOT_STARTED') return
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      if (!floatingSubproject) {
        cleanLogin()
        return
      }
      const userProject = data.find((project) => {
        return project.id === floatingSubproject.project_id
      })

      if (!userProject) {
        forceHomePage()
        return
      }

      // setGlobalUser(floatingUser)
      setGlobalSubproject(floatingSubproject)
      setGlobalProject(userProject)
      setPage(render())
    }
  }, [floatingSubproject, getProjects.responseData, getProjects.status])

  useEffect(() => {
    if (globalUser) validateToken()
    else if (match.url.match(/forgot_password/)) {
      clearSubproject()
      setPage(<ForgotPassword />)
    } else {
      clearSubproject()
      setPage(<Login />)
    }
  }, [match.url, globalUser])

  useEffect(() => {
    if (globalProject && globalSubproject && globalUser) dispatch(BusEvents.REMEMBER_TOKEN_UPDATED)
  }, [globalProject, globalSubproject, globalUser, history.location.search])

  return <React.Fragment key={globalSubproject.id}>{page}</React.Fragment>
}

AuthRequired.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      resource: PropTypes.string,
      collection: PropTypes.string
    })
  }).isRequired,
  onLoadingUser: PropTypes.func.isRequired
}
