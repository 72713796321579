import { useEffect } from 'react'

export default function useBeforeUnload(preventUnload) {
  useEffect(() => {
    if (!preventUnload) return

    const unload = (event) => (event.returnValue = null)

    window.addEventListener('beforeunload', unload)
    return () => window.removeEventListener('beforeunload', unload)
  }, [preventUnload])
}
