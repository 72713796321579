import React from 'react'
import PropTypes from 'prop-types'
import InspectedColumn from '/src/ui/domain/inspections/inspected'
import RequestColumn from '/src/ui/domain/inspections/request'
import InspectionStatusLabelWrapper from '/src/ui/domain/inspections/inspection_status_label_wrapper'
import ResponsibleBadge from '/src/ui/domain/inspections/responsible_badge'
import InspectionModel from '/src/models/inspection'
import FieldFactory from '/src/ui/core/fields/field_factory'
import RequestIdCell from '/src/ui/core/grid/request_id_cell'
import { cellColumnValue } from '/src/models/concerns/grid'

export default function InspectionCellFactory({ field, dataItem, value }) {
  const { columns } = new InspectionModel()

  const column = columns.find((c) => c.description === field || c.field === field)

  switch (field) {
    case 'request_id':
      return <RequestIdCell id={dataItem[field]} request={dataItem.request} showStatus />
    case 'request.reason':
    case 'request.comments':
      return <RequestColumn request={dataItem.request} field={field} />
    case 'inspected_type':
      return (
        <InspectedColumn
          inspected={dataItem.inspected}
          type={dataItem.inspected_type}
          services={dataItem.inspected_services}
        />
      )
    case 'inspect_status':
      return <InspectionStatusLabelWrapper dataItem={dataItem} />
    case 'responsible.full_name':
      return <ResponsibleBadge fullName={cellColumnValue(column, dataItem, field, 'responsible.full_name')} />
    default:
      return <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
  }
}

InspectionCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.shape({
    inspected: PropTypes.shape({}),
    inspected_type: PropTypes.string,
    request: PropTypes.shape({}),
    inspected_services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        service_number: PropTypes.number
      })
    )
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()])
}

InspectionCellFactory.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined
}
