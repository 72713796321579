/* eslint-disable max-lines-per-function */
import React, { useState, useRef } from 'react'
import { useSetStoreValue } from 'react-context-hook'
import { MdLabelOutline, MdAddCircle } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import { dispatch } from '/src/hooks/bus/bus'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useRequestsPinned from '/src/hooks/requests_pinned'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PopupMenuItem from '/src/ui/core/popups/popup_menu_item'
import PopupNewLabel from '/src/ui/core/popups/popup_new_label'
import PopupSearch from '/src/ui/core/popups/popup_search'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations.js'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { contains } from '/src/utils/string'
import '/src/static/css/popup_anchored.css'

export default function PopupScopeLabel({ selectedItems }) {
  const { fetch } = useFetch()
  const { requestId } = useRequestsPinned()
  const [tabbing] = useQueryParam('eav_template_id', NumberParam)

  const [popupOpened, setPopupOpened] = useState(false)
  const [newLabelOpened, setNewLabelOpened] = useState(false)

  const [labels, setLabels] = useState([])
  const [filterSearch, setFilterSearch] = useState('')

  const popupButtonRef = useRef(null)
  const setNotification = useSetStoreValue('notification')


  const newLabelButton = {
    text: I18n.t('scopes.popup_label.new_label'),
    icon: <MdAddCircle />,
    onClick: () => setNewLabelOpened(!newLabelOpened)
  }

  const filteredLabels = filterSearch
    ? labels.filter((label) => contains(label.description.toLowerCase(), filterSearch.toLowerCase()))
    : labels

  const closePopup = () => {
    setNewLabelOpened(false)
    setPopupOpened(false)
    setFilterSearch('')
  }

  const promptSuccessfulLabelAssign = () => {
    const success = {
      title: I18n.t('notification.success'),
      body: I18n.t('scopes.label_assigner.success'),
      status: 'success',
      closable: true,
      closeTimeout: 10
    }
    setNotification(success)
  }

  const updateInBatch = (label) => {
    const scopes = {}
    selectedItems.forEach((selected) => {
      scopes[selected.id] = { label }
    })

    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    const data = { fields: scopes }
    const params = { httpAction: 'put', data }

    fetch('scopes/update_in_batch', params)
      .then(() => {
        promptSuccessfulLabelAssign()
        dispatch(BusEvents.RELOAD_GRID)
      })
      .finally(() => dispatch(BusEvents.HIDE_DIALOG))

    closePopup()
  }

  const handleOpenPopup = () => {
    if (popupOpened) {
      closePopup()
      return
    }

    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    const params = {
      query: { where: { request_id: requestId, eav_template_id: tabbing } }
    }

    fetch('scope_labels', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        setLabels(data?.label?.map((label) => ({ description: label })) ?? [])
        dispatch(BusEvents.HIDE_DIALOG)
        setPopupOpened(true)
      },
      onError: (error) => {
        dispatch(BusEvents.HIDE_DIALOG)
        notifyError(error)
      }
    })
  }

  const popupBody = (
    <div className="popup-label">
      <PopupNewLabel
        show={newLabelOpened}
        back={() => setNewLabelOpened(false)}
        close={() => closePopup()}
        applyLabel={updateInBatch}
      />
      <div style={{ display: !newLabelOpened ? 'block' : 'none' }}>
        <div>
          <PopupSearch onChange={(e) => setFilterSearch(e.target.value)} />
        </div>
        <div className="body">
          <p className="labels-header">{I18n.t('scopes.popup_label.labels')}</p>
          <div className="popup-scroll-box" data-testid="popup-scroll-box">
            {filteredLabels.length ? filteredLabels.map(({ description }) => {
              const popupItem = {
                text: description,
                onClick: () => updateInBatch(description)
              }
              return <PopupMenuItem item={popupItem} key={description} />
            }) : (
              <span className="list-info" data-testid="label-result-text">
                {I18n.t('search.no_result_found')}
              </span>
            )}
          </div>
        </div>
        <div className="line" />
        <div className="new-label-button">
          <PopupMenuItem item={newLabelButton} key={newLabelButton.text} />
        </div>
      </div>
    </div>
  )

  return (
    <CustomTooltip position="bottom" parentTitle>
      <PopupAnchored
        body={popupBody}
        popupButtonRef={popupButtonRef}
        forceOpen={popupOpened}
        setForceOpen={() => closePopup()}
      >
        <button
          type="button"
          className={`btn-icon ${popupOpened ? 'active' : ''} grid-header-icon`}
          title={I18n.t('scopes.labels_info')}
          ref={popupButtonRef}
          data-testid="open-popup-button"
          onClick={handleOpenPopup}
        >
          <MdLabelOutline />
        </button>
      </PopupAnchored>
    </CustomTooltip>
  )
}

PopupScopeLabel.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object)
}

PopupScopeLabel.defaultProps = {
  selectedItems: []
}
