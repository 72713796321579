import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { fullTitleDisplay, titleLimiter } from '/src/utils/string'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function ReportItem({ report, lenghtLimit }) {
  const { id, description, server_url: serverUrl, report_type: type } = report
  const iframeTypes = {
    IFRAME: 'powerbi_iframe',
    TELERIK: 'telerik_reports'
  }

  const encodeURL = (endpoint) => {
    const baseURL = `${SERVER_URL}/${endpoint}?hide_menus=true`
    const reportParamsURL = `${baseURL}&report_id=${id}&report_name=${description}&`
    const encodedQueryParamURL = encodeURIComponent(reportParamsURL)

    return `/reports?baseUrl=${encodedQueryParamURL}&id=${id}&report_name=${description}`
  }

  const isLink = () => type === 'LINK'

  const renderAnchor = () => {
    if (isLink())
      return (
        <a href={serverUrl} target="_blank" rel="noreferrer" title={fullTitleDisplay(description, 20)}>
          {lenghtLimit ? titleLimiter(description, lenghtLimit) : description}
        </a>
      )

    return (
      <Link to={encodeURL(iframeTypes[type])} title={fullTitleDisplay(description, 20)}>
        {lenghtLimit ? titleLimiter(description, lenghtLimit) : description}
      </Link>
    )
  }

  return type !== 'POWER-BI' && renderAnchor()
}

ReportItem.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    server_url: PropTypes.string,
    report_type: PropTypes.string
  }).isRequired
}

ReportItem.defaultProps = {
  lenghtLimit: undefined
}
