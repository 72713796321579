import React from 'react'
import PropTypes from 'prop-types'
import { MdPageview, MdAutoAwesomeMotion } from 'react-icons/md'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'

export default function InspectionTemplateButton({ title, onClick, isCategory }) {
  return (
    <div onClick={onClick} className="inspection-template-button">
      <div className="icon">{isCategory ? <MdAutoAwesomeMotion /> : <MdPageview />}</div>
      <GeneralTooltip anchorElement="target">
        <div className="text">
          <p title={title}>{title}</p>
        </div>
      </GeneralTooltip>
    </div>
  )
}

InspectionTemplateButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isCategory: PropTypes.bool
}

InspectionTemplateButton.defaultProps = {
  onClick: null,
  isCategory: false
}
