import { useState, useCallback } from 'react'
/**
 *  Hook designed to manage model instance attributes
 */
export default function useModel(model, fields) {
  const [modelState, setModelState] = useState(model)

  const onChange = useCallback(
    (attribute, value) => {
      if (fields && fields.includes(attribute)) {
        setModelState((prevModel) => {
          if (attribute === 'statuses') {
            prevModel.setStatuses(value)
            return prevModel
          }
          const newModel = { ...prevModel }
          newModel[attribute] = value
          return newModel
        })
      }
    },
    [fields]
  )

  return [modelState, onChange]
}
