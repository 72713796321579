import { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'

/**
 * This pull request is intended to manipulate page change
 *
 * @param page
 * @param total
 * @param onPageChange
 * @return {{pageClick: (function(*=, *): (undefined)), jumpToPage: jumpToPage, currentPage: *}}
 */
export default function useChangePage(page, total, onPageChange, modelName, loading) {
  const [currentPage, setPage] = useState(page)
  const [_, setGridPage] = useStore(`${modelName}_grid_page`)

  useEffect(() => {
    setPage(page)
  }, [page, total])

  useEffect(() => {
    setGridPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (!loading && currentPage > total) jumpToPage({ target: { value: 1 } }, 1)
  }, [total])

  const jumpToPage = (event, newPage) => {
    setPage(newPage)
    onPageChange(event, newPage)
  }

  const pageClick = (event, dir) => {
    const nextPage = currentPage + dir
    if (nextPage < 1 || nextPage > total) return
    jumpToPage(event, nextPage)
  }

  return { currentPage, jumpToPage, pageClick }
}
