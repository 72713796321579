import { useEffect, useCallback } from 'react'
import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { TIMEOUT_CLOSE_FINISHED_JOB_POPUP } from '/src/utils/constants/timeouts'

const dispatchPollingError = (error) => {
  notifyError(I18n.t('estimate.some_estimates_not_approved'))
  dispatch({
    type: BusEvents.POLLING_DATA_SUBMITTED,
    payload: error
  })
  dispatch(BusEvents.RELOAD_GRID)
}

const dispatchSuccess = () => {
  notifySuccess(I18n.t('estimate.all_estimates_approved'))
  dispatch(BusEvents.FORM_FINISHED)
}

const handleJobResponse = (polling) => {
  let hasError = false

  Object.entries(polling.error_log || {}).forEach(([key, value]) => {
    if (value.status === 'error') hasError = true
  })

  if (hasError) {
    dispatchPollingError(polling.error_log)
  } else {
    dispatchSuccess()
  }
}

const pollingCallback = (response) => {
  if (!response.id) return null

  return dispatch({
    type: BusEvents.SHOW_SERVICE_IN_PROGRESS_DIALOG,
    payload: {
      jobId: response.id,
      onFinishedJob: (polling) => {
        setTimeout(() => dispatch(BusEvents.HIDE_DIALOG), TIMEOUT_CLOSE_FINISHED_JOB_POPUP)
        handleJobResponse(polling)
      },
      onJobError: () => {
        notifyError(I18n.t('notification.error_job'))
        dispatch(BusEvents.HIDE_DIALOG)
      }
    }
  })
}

export default function useSendEstimates() {
  const { status, loading, responseData, fetchAPI } = useFetchAPI('estimates')

  useEffect(() => {
    if (loading) dispatch(BusEvents.SHOW_LOADING_DIALOG)
    dispatch(BusEvents.HIDE_DIALOG)

    if (status !== 'SUCCESS') return
    pollingCallback(responseData)
  }, [status, loading, responseData])

  const approveEstimates = useCallback(
    (pendingEstimates, values) => {
      const dataValues = values || {}
      const params = {
        requestAction: 'UPDATE',
        httpAction: 'put',
        additionalResource: { path: 'approve_in_batch' },
        data: {
          estimate_list: pendingEstimates.map((estimate) => estimate.id),
          estimate_date: dataValues.estimate_date,
          responsible_id: dataValues.responsible_id
        }
      }

      fetchAPI(params)
    },
    [fetchAPI]
  )

  return [approveEstimates]
}
