import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { isBlank } from '/src/utils/boolean_refinements'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/delta_quantity_column.css'

export default function DeltaQuantityColumn({ data }) {
  const [globalProject] = useStore('project')

  if (isBlank(data) || isBlank(data.quantity) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_quantity)) return '---'

  const actualQuantity = data.progress_summary.actual_quantity
  const plannedQuantity = data.quantity

  const actualFormatted = formatNumberWithPrecision(actualQuantity, globalProject)
  const plannedFormatted = formatNumberWithPrecision(plannedQuantity, globalProject)

  const isPositiveDelta = actualQuantity <= plannedQuantity

  const currentQuantityTooltip = I18n.t('progresses.current_qty')
  const plannedQuantityTooltip = I18n.t('progresses.planned_qty')

  return (
    <div className="delta-quantity-column">
      <CustomTooltip position="left" parentTitle>
        <span
          className={`delta-quantity-column__actual${isPositiveDelta ? '--positive' : '--negative'}`}
          title={currentQuantityTooltip}
        >
          {actualFormatted}
        </span>
      </CustomTooltip>
      <span>/</span>
      <CustomTooltip position="right" parentTitle>
        <span className="delta-quantity-column__planned" title={plannedQuantityTooltip}>
          {plannedFormatted}
        </span>
      </CustomTooltip>
    </div>
  )
}

DeltaQuantityColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

DeltaQuantityColumn.defaultProps = {
  data: {}
}

export const deltaQuantity = (data, project) => {
  if (isBlank(data) || isBlank(data.quantity) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_quantity)) return '---'

  return formatNumberWithPrecision(data.progress_summary.actual_quantity - data.quantity, project)
}
