import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import xss from 'xss'
import I18n from '/src/utils/translations'
import { getLinkNodeAttributes } from '/src/utils/grid'
import '/src/static/css/core/fields/link_field.css'

export default function LinkColumn({ value }) {
  const link = useMemo(() => {
    if (!value) return I18n.t('fields.na')

    if (value.includes('</a>')) {
      const xssOptions = { whiteList: { a: ['href', 'download'] } }
      const sanitizedValue = xss(value, xssOptions)
      const el = document.createElement('div')
      el.innerHTML = sanitizedValue
      const anchor = el.firstElementChild
      let attributes = getLinkNodeAttributes(anchor.attributes)
      attributes = {
        ...attributes,
        className: `${attributes.className ? `${attributes.className} ` : ''}link-field`,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
      return React.createElement('a', attributes, el.firstElementChild.innerText)
    }

    return (
      <a className="link-field" target="_blank" rel="noopener noreferrer" href={value}>
        {value}
      </a>
    )
  }, [value])

  return link
}

LinkColumn.propTypes = {
  value: PropTypes.string
}

LinkColumn.defaultProps = {
  value: null
}
