import React from 'react'
import { MdOutlinePendingActions, MdAssignment, MdThumbUp, MdThumbDown } from 'react-icons/md'

export const RECYCLING_STATUS = 'recycling'
export const DISCIPLINE_CHECK_PENDING_STATUS = 'pending'
export const DISCIPLINE_CHECK_DONE_STATUS = 'done'
export const DISCIPLINE_CHECK_REJECTED_STATUS = 'rejected'

export const status = {
  1: 'registered',
  2: 'canceled',
  3: 'confirmed',
  4: 'in_scoping',
  5: 'authorization_pending',
  6: 'in_planning',
  7: 'in_performance',
  8: 'done',
  9: 'on_hold',
  10: 'clustered'
}

export const filterFlags = [
  {
    id: 1,
    description: 'Discipline check pending',
    i18n_id: 'discipline_check_pending',
    column: 'discipline_check_status',
    value: DISCIPLINE_CHECK_PENDING_STATUS,
    icon: <MdAssignment />
  },
  {
    id: 2,
    description: 'Discipline check done',
    i18n_id: 'discipline_check_done',
    column: 'discipline_check_status',
    value: DISCIPLINE_CHECK_DONE_STATUS,
    icon: <MdThumbUp />
  },
  {
    id: 3,
    description: 'Discipline check reject',
    i18n_id: 'discipline_check_rejected',
    column: 'discipline_check_status',
    value: DISCIPLINE_CHECK_REJECTED_STATUS,
    icon: <MdThumbDown />
  },
  {
    id: 4,
    description: 'Recycle',
    i18n_id: 'recycle',
    column: 'recycling_status',
    value: RECYCLING_STATUS,
    icon: <MdOutlinePendingActions />
  }
]

const requestStatusToCreateScopes = ['in_scoping', 'confirmed']
const requestStatusToEditScopes = ['registered', 'confirmed', 'in_scoping', 'authorization_pending']

const updateRequestFilter = (oldFilter, requestFilter) => {
  const cleanedFilter = oldFilter.filter((f) => !/request_id$/.test(f.column))
  if (requestFilter.length === 0) return cleanedFilter

  return Object.values(cleanedFilter).concat(requestFilter)
}

const requestStatusToCreateProgresses = ['in_planning', 'in_performance']

const canCreateScope = (statusString) => requestStatusToCreateScopes.includes(statusString)
const canEditScope = (statusString) => requestStatusToEditScopes.includes(statusString)

const canCreateProgress = (statusString) => requestStatusToCreateProgresses.includes(statusString)

export { canCreateScope, canEditScope, canCreateProgress, updateRequestFilter }

export function requestTitle(request, prefix = '') {
  const { reason, discipline } = request

  if (!reason) return ''

  return `${prefix}${reason}${discipline ? ` - ${discipline}` : ''}`
}
