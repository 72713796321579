import React from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import I18n from '/src/utils/translations'

const HeaderMain = ({ projectDescription, subproject, projectType, reason, comments }) => (
  <div className="estimates-pdf-header__header-main">
    <h1 className="estimates-pdf-header__header-main__title">{I18n.t('estimate.pdf_header.title')}</h1>
    <p className="estimates-pdf-header__header-main__ao-number">
      AO: {reason} {comments ? ` - ${comments}` : ''}
    </p>
    <p>
      <strong>{I18n.t('estimate.pdf_header.project')}: </strong>
      {projectDescription}
    </p>
    <p>
      <strong>{I18n.t('estimate.pdf_header.subproject')}: </strong>
      {subproject}
    </p>
    <p>
      <strong>{I18n.t('estimate.pdf_header.project_type')}: </strong>
      {projectType}
    </p>
  </div>
)

HeaderMain.propTypes = {
  projectDescription: PropTypes.string,
  subproject: PropTypes.string,
  projectType: PropTypes.string,
  reason: PropTypes.string,
  comments: PropTypes.string
}

export default HeaderMain
