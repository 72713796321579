import React from 'react'
import { MdSearch } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function PopupSearch({ onChange }) {
  return (
    <div className="popup-search">
      <span className="search-box-icon">
        <MdSearch />
      </span>
      <input
        className="search-box-popup"
        type="text"
        onChange={onChange}
        placeholder={I18n.t('search.placeholder')}
        data-testid="search-box-popup"
      />
    </div>
  )
}

PopupSearch.propTypes = {
  onChange: PropTypes.func.isRequired
}
