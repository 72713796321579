import React from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import I18n from '/src/utils/translations'

export default function RequestFilterLabel({ filterLabels, filterGroup, clearFilters }) {
  const labelNames = filterLabels.map((label) => I18n.t(`requests.${label}`))
  const uniqueLabelNames = [...new Set(labelNames)]

  return (
    <div className="filter-label">
      {`${I18n.t(`grid.${filterGroup}_filter`)}: ${uniqueLabelNames.join(', ')}`}
      <MdClose onClick={clearFilters} data-testid="clear-filters-button" />
    </div>
  )
}

RequestFilterLabel.propTypes = {
  filterLabels: PropTypes.arrayOf(PropTypes.string),
  clearFilters: PropTypes.func.isRequired,
  filterGroup: PropTypes.string
}

RequestFilterLabel.defaultProps = {
  filterLabels: null,
  filterGroup: ''
}
