import React from 'react'
import PropTypes from 'prop-types'
import { MdBackup } from 'react-icons/md'
import I18n from '/src/utils/translations'
import IconButton from '/src/ui/core/buttons/icon_button'
import '/src/static/css/core/inputs/input_attachment.css'

export default function IconButtonContainer({ children, id, error, readOnly, title, onClickIcon }) {
  return (
    <div className="input-attachment" id={id}>
      <div className="d-flex">
        <IconButton
          btnClassName={error ? 'input-error' : ''}
          disabled={readOnly}
          onClick={onClickIcon}
          icon={<MdBackup />}
          title={title}
        />
      </div>
      {children}
    </div>
  )
}

IconButtonContainer.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  onClickIcon: PropTypes.func
}

IconButtonContainer.defaultProps = {
  id: undefined,
  error: false,
  readOnly: false,
  title: I18n.t('actions.upload_picture'),
  onClickIcon: () => {}
}
