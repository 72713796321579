import { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'

export default function useFetchSections(templateId, useLoading = false) {
  const [sections, setSections] = useState(null)
  const [fetchLoading, setFetchLoading] = useState(true)
  const { fetchAPI, status, responseData } = useFetchAPI('eav_sections')

  useEffect(() => {
    setFetchLoading(true)
    if (!templateId) return
    const params = { requestAction: 'READ', httpAction: 'get' }
    const query = { where: { eav_template_id: templateId } }
    const additionalResource = { path: 'eav_columns' }
    fetchAPI({ ...params, query, additionalResource })
  }, [templateId])

  useEffect(() => {
    if (status === 'SUCCESS') {
      setSections(responseData.data[0].filter((s) => s.eav_columns.length > 0))
      setFetchLoading(false)
    }
  }, [status, responseData])

  if (useLoading) return [sections, fetchLoading]

  return sections
}
