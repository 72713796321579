import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'


export default function InspectionStatusLabelWrapper({ dataItem }) {
  const [statuses] = useStore('inspect_statuses')

  return <InspectionStatusLabel dataItem={dataItem} statuses={statuses} />
}

InspectionStatusLabelWrapper.propTypes = {
  dataItem: PropTypes.shape({
    inspect_status_id: PropTypes.number,
    inspect_date: PropTypes.string,
    recurrency_uuid: PropTypes.string
  })
}

InspectionStatusLabelWrapper.defaultProps = {
  dataItem: null
}
