import React from 'react'
import { MdModeEdit } from 'react-icons/md'
import I18n from '/src/utils/translations.js'

export default function EditModeIcon() {
  return (
    <span className="edit-mode-icon">
      <MdModeEdit />
      {I18n.t('grid.editable.title')}
    </span>
  )
}
