import React from 'react'
import PropTypes from 'prop-types'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ScaffoldingForm from '/src/ui/domain/scaffoldings/scaffolding_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'

export default function ScaffoldingFormModal({ scaffoldingModel }) {
  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    ({ payload }) => {
      const { subGrid } = payload
      if (!subGrid) {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scaffoldingModel.paramName,
          payload: { formType: 'new' }
        })
      }
    },
    []
  )

  return (
    <GridFormModal modelName={scaffoldingModel.paramName}>
      <ScaffoldingForm model={scaffoldingModel} />
    </GridFormModal>
  )
}

ScaffoldingFormModal.propTypes = {
  scaffoldingModel: PropTypes.oneOfType([PropTypes.object]).isRequired
}
