import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, GridNoRecords } from '@progress/kendo-react-grid'
import { PopupPropsContext } from '@progress/kendo-react-popup'
import { Checkbox } from '@progress/kendo-react-inputs'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'
import GridLoader from '/src/ui/core/loaders/grid_loader'
import EmptyRowLoader from '/src/ui/core/loaders/empty_row_loader'
import Status from '/src/ui/core/grid/status'
import { allFilterOperators } from '/src/models/concerns/filter_operators'
import { forceBooleanDefault } from '/src/utils/object'
import { HeaderFilterCell } from '/src/ui/core/grid/filtering/header_filter_cell'
import '/src/static/css/core/grid/grid.css'

const SELECTED_FIELD = 'row_selected'

export default function SimpleGridContent({
  status,
  model,
  animatePopup,
  gridWrapper,
  selectedItem,
  selectedId,
  selectFiltering,
  dataSource,
  formattedGridColumns,
  gridColumns,
  rowRender,
  kendoGridFilters,
  onFilterChange,
  sortable,
  sorting,
  onColumnReorder,
  onSortChange,
  onColumnResize,
  onGridRowClick,
  onSelectionChange
}) {
  const { name, route } = model

  const DisableSelectionCheckbox = useCallback((td, { field, dataItem }) => {
    if (field === SELECTED_FIELD && dataItem.checkboxDisabled) {
      return (
        <td>
          <Checkbox disabled />
        </td>
      )
    }
    return td
  }, [])

  const handleDataSourceSelectedItems = (data) =>
    data.map((item) => ({
      ...item,
      row_selected: item.row_selected ? forceBooleanDefault(item, SELECTED_FIELD) : false
    }))

  const gridData =
    selectedItem && selectFiltering
      ? [forceBooleanDefault(selectedItem, SELECTED_FIELD)]
      : handleDataSourceSelectedItems(dataSource.data)

  const gridFilter = useMemo(() => ({ filters: kendoGridFilters }), [kendoGridFilters])

  return (
    <Status
      status={status}
      loading={<GridLoader />}
      empty={<GridEmptyState onCreate={null} modelName={name} modelRoute={route} />}
      noTemplate={<GridEmptyState modelName={`${name} template`} />}
    >
      <PopupPropsContext.Provider
        value={(props) => ({
          ...props,
          animate: animatePopup,
          appendTo: gridWrapper.current
        })}
      >
        <Grid
          data={gridData}
          className={dataSource.data.length === 0 ? 'no-records' : ''}
          cellRender={DisableSelectionCheckbox}
          rowRender={rowRender}
          total={dataSource.total}
          filterable
          filter={gridFilter}
          filterOperators={allFilterOperators}
          onFilterChange={onFilterChange}
          sortable={sortable}
          sort={sorting}
          reorderable
          resizable
          onColumnReorder={onColumnReorder}
          onSortChange={onSortChange}
          onColumnResize={onColumnResize}
          onRowClick={onGridRowClick}
          selectedField={SELECTED_FIELD}
          onSelectionChange={onSelectionChange}
          filterCellRender={(_, props) => <HeaderFilterCell {...props} columns={gridColumns} />}
        >
          <GridNoRecords>
            <EmptyRowLoader status={status} />
          </GridNoRecords>
          {formattedGridColumns}
        </Grid>
      </PopupPropsContext.Provider>
    </Status>
  )
}

SimpleGridContent.propTypes = {
  status: PropTypes.string,
  model: PropTypes.object.isRequired,
  animatePopup: PropTypes.bool,
  gridWrapper: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  selectedId: PropTypes.number,
  selectFiltering: PropTypes.bool,
  dataSource: PropTypes.oneOfType([PropTypes.object]),
  formattedGridColumns: PropTypes.array,
  rowRender: PropTypes.func,
  kendoGridFilters: PropTypes.arrayOf(
    PropTypes.shape({
      logic: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
            PropTypes.string,
            PropTypes.shape({ start: PropTypes.string, end: PropTypes.string })
          ])
        })
      )
    })
  ),
  onFilterChange: PropTypes.func,
  sortable: PropTypes.bool,
  sorting: PropTypes.arrayOf(PropTypes.object),
  onColumnReorder: PropTypes.func,
  onSortChange: PropTypes.func,
  onColumnResize: PropTypes.func,
  onGridRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func
}

SimpleGridContent.defaultProps = {
  status: 'loading',
  animatePopup: true,
  selectedItem: undefined,
  selectedId: undefined,
  selectFiltering: true,
  dataSource: { data: [], total: 0 },
  formattedGridColumns: [],
  rowRender: () => {},
  kendoGridFilters: [],
  onFilterChange: () => {},
  sortable: true,
  sorting: [],
  onColumnReorder: () => {},
  onSortChange: () => {},
  onColumnResize: () => {},
  onGridRowClick: () => {},
  onSelectionChange: () => {}
}
