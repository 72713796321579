import { buildEquation, buildVariables } from '/src/models/concerns/formula'
import { isEmpty, objectEquals } from '/src/utils/object'
import buildFormulaItemObj from '/src/utils/build_formula_item_obj'

const formatEquations = ({ formulas, dataItem, parents, parentModels, includeOnFormula, variableTypes }) => {
  const equations = {}

  formulas.forEach((formula) => {
    const fieldsVariables = buildVariables(
      { ...formula, parents, parentModels },
      { ...dataItem, ...includeOnFormula },
      variableTypes
    )

    equations[formula.id] = [buildEquation(formula, fieldsVariables), buildFormulaItemObj(dataItem)]
  })

  return equations
}

const fetchFormulasParams = ({
  formulasByRow,
  dataItems,
  parents,
  parentModels,
  includeOnFormula,
  variableTypes,
  getDataItemId
}) => {
  const equations = dataItems.reduce((equationsObj, dataItem) => {
    const itemId = getDataItemId(dataItem)
    const itemEquations = formatEquations({
      formulas: formulasByRow[itemId],
      dataItem,
      parents,
      parentModels,
      includeOnFormula,
      variableTypes
    })

    return { ...equationsObj, ...itemEquations }
  }, {})

  const fetchParams = {
    requestAction: 'CREATE',
    httpAction: 'post',
    data: { formulas: equations }
  }

  return fetchParams
}

const filterEquationsResults = (results) => {
  const filteredResults = {}

  Object.entries(results).forEach(([key, result]) => {
    const [itemId, formulaId] = key.split('_')

    if (filteredResults[itemId] === undefined) filteredResults[itemId] = {}

    filteredResults[itemId][formulaId] = result
  })

  return filteredResults
}

const updateDataItems = ({ updatedDataItems, results, columns, getDataItemId, treatFormulaFields }) => {
  const filteredResults = filterEquationsResults(results)

  updatedDataItems.forEach((updatedDataItem) => {
    const newDataItem = updatedDataItem
    const itemId = getDataItemId(newDataItem)
    treatFormulaFields(columns, filteredResults[itemId], (field, value) => {
      newDataItem[field] = value
    })
  })
}

const calculateFormulas = ({
  fetch,
  formulasByRow,
  dataItems,
  parents,
  parentModels,
  columns,
  includeOnFormula,
  variableTypes,
  treatFormulaFields,
  getDataItemId
}) => {
  return new Promise((resolve, reject) => {
    if (isEmpty(formulasByRow) || dataItems.length === 0) resolve([dataItems, {}])

    const updatedDataItems = dataItems.map((item) => ({ ...item }))

    const fetchFormulas = () => {
      const fetchParams = fetchFormulasParams({
        formulasByRow,
        dataItems: updatedDataItems,
        parents,
        parentModels,
        includeOnFormula,
        variableTypes,
        getDataItemId
      })

      fetch('formulas/eval_equations', fetchParams, {
        onSuccess: ({ data }) => {
          const prevDataItems = updatedDataItems.map((item) => ({ ...item }))
          updateDataItems({
            updatedDataItems,
            results: data,
            columns,
            getDataItemId,
            treatFormulaFields
          })

          if (!objectEquals(updatedDataItems, prevDataItems)) fetchFormulas()
          else resolve([updatedDataItems, data])
        },
        onError: () => reject()
      })
    }

    fetchFormulas()
  })
}

export default calculateFormulas
