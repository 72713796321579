import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'

export default function ColumnFooterCell({ column, data }) {
  const printCell = () => {
    const selectedData = data.filter((item) => item.row_selected)
    let amount = selectedData.length ? selectedData : data
    amount = amount.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue[column])
    }, 0)

    return <DecimalColumn value={amount} />
  }

  return (
    <td data-testid="footer-cell">
      <span className="align-fixed-cells-right">{data.length > 0 ? printCell() : I18n.t('actions.loading')}</span>
    </td>
  )
}

ColumnFooterCell.propTypes = {
  column: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object)
}

ColumnFooterCell.defaultProps = {
  data: []
}
