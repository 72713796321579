import React, { useEffect, useState } from 'react'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/notifications.css'
import Notification from '/src/ui/core/dialogs/notification'
import generateHexString from '/src/utils/generator'
import I18n from '/src/utils/translations'

const defaultNotification = { closable: true, closeTimeout: 5 }

const notify = (status, options) => {
  if (typeof options === 'object') {
    dispatch({
      type: BusEvents.NOTIFICATION,
      payload: {
        ...defaultNotification,
        status,
        ...options,
        title: options.title || I18n.t(`notification.${status}`),
        body: options.body || I18n.t(`notification.${status}_msg`)
      }
    })
  } else {
    dispatch({
      type: BusEvents.NOTIFICATION,
      payload: {
        ...defaultNotification,
        title: I18n.t(`notification.${status}`),
        body: options || I18n.t(`notification.${status}_msg`),
        status
      }
    })
  }
}

export const notifyError = (options) => {
  notify('error', options)
}

export const notifySuccess = (options) => {
  notify('success', options)
}

export const notifyWarning = (options) => {
  notify('warning', options)
}

export const notifyInfo = (options) => {
  notify('info', options)
}

export default function Notifications() {
  const DISMISS_TIMEOUT = 300

  const [show, setShow] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [groupClass, setGroupClass] = useState('notification-group')
  const display = (baseNotification, payload) => {
    const notification = { ...baseNotification, ...payload }
    setNotifications((notifications) => {
      if (notifications.some((e) => e.body === notification.body)) return notifications
      return [notificationWithHashKey(notification), ...notifications]
    })
    setShow(true)
  }

  useBus(
    BusEvents.NOTIFICATION,
    ({ payload }) => {
      display(payload)
    },
    []
  )

  const removeFromNotifications = (key) => {
    setNotifications((notes) => notes.filter((note) => note.hashKey !== key))
  }

  const notificationWithHashKey = (notificationWithoutKey) => {
    const notification = { ...notificationWithoutKey }
    let key = ''
    do {
      key = generateHexString(4)
    } while (notifications.some((e) => e.hashKey === key))
    notification.hashKey = key
    return notification
  }

  useEffect(() => {
    if (notifications.length === 0) setShow(false)
  }, [notifications])

  useEffect(() => {
    let newClass = 'notification-group'
    if (show) newClass += ' show'
    setGroupClass(newClass)
  }, [show])

  const dismissAll = () => {
    setShow(false)
    setTimeout(() => setNotifications([]), DISMISS_TIMEOUT)
  }

  return (
    <React.Fragment>
      {notifications.length > 0 && (
        <div className={groupClass}>
          {notifications.map((notification) => {
            return (
              <Notification
                body={notification.body}
                hashKey={notification.hashKey}
                key={notification.hashKey}
                title={notification.title}
                status={notification.status}
                closeSelf={removeFromNotifications}
                closable={notification.closable}
                closeTimeout={notification.closeTimeout}
              />
            )
          })}

          {notifications.length > 1 && (
            <button data-testid="dismiss-all" type="button" className="dismiss-all" onClick={dismissAll}>
              {I18n.t('notification.dismiss_all')}
            </button>
          )}
        </div>
      )}
    </React.Fragment>
  )
}
