import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { MdBorderAll } from 'react-icons/md'
import { formatCurrency } from '/src/utils/project_formatter'

/**
 * This custom hook will receive a array of templates
 * and will generate building blocks to be used with <Card /> and <ExpandableCard />
 *
 * @param templates - array of eav templates
 * @return {[{ tag, title, subtitle, details, id }]} - array of card building blocks
 */
export default function useTemplatesCardsBlocks(templates) {
  const [cardParts, setCardParts] = useState([])
  const [project] = useStore('project')

  useEffect(() => {
    if (templates.length) setCardParts(templates.map(buildCard))
  }, [templates])

  const getTotalPrice = (items) => {
    let totalPrice = 0
    if (items) totalPrice = items.reduce((total, es) => total + es.price, 0)
    return formatCurrency(totalPrice, project)
  }

  const buildCard = (template) => {
    const { items } = template
    const tag = (
      <div className="template-item-tag">
        <MdBorderAll />
      </div>
    )
    const title = (
      <div className="template-item-title">
        <div className="title-text">{template.title}</div>
        <div className="title-count">{items ? items.length : 0}</div>
      </div>
    )

    const subtitle = undefined

    const details = <div className="template-total-price">{getTotalPrice(items)}</div>

    return { tag, title, subtitle, details, key: template.id, items }
  }

  return cardParts
}
