// this is just for the editable grid (bulk grid)
const NON_EDITABLE_SERVICES_STATUSES = ['in_performance', 'canceled', 'done', 'on_hold']

const NON_EDITABLE_FORMULA_FIELD_STATUSES = ['in_performance', 'on_hold']

const EDITABLE_SERVICES_STATUSES = ['planned', 'in_planning', 'in_performance', 'on_hold']

// eslint-disable-next-line import/prefer-default-export
export {
  NON_EDITABLE_SERVICES_STATUSES,
  NON_EDITABLE_FORMULA_FIELD_STATUSES,
  EDITABLE_SERVICES_STATUSES
}
