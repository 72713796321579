import useUpdateEffect from '/src/hooks/update_effect'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

// eslint-disable-next-line max-lines-per-function
export default function useRejectRequest(showConfirmation) {
  const getOrder = useFetchAPI('orders')

  const rejectAction = (dataItem) => {
    const orderQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { request_id: dataItem.id, 'order_statuses][description': 'pending' } }
    }

    return getOrder.fetchAPI(orderQueryParams)
  }

  const showRejectConfirmation = (order) => {
    const rejectSuccess = () => {
      notifySuccess(
        I18n.t('notification.action_success', {
          action: I18n.t(`actions.past.reject`),
          model: I18n.t('requests.title'),
          id: order.request_id
        })
      )
      return dispatch(BusEvents.RELOAD_GRID)
    }

    const rejectParams = {
      title: I18n.t('requests.confirmation_modal.reject_title'),
      description: I18n.t('requests.confirmation_modal.reject_description'),
      actionButtonText: I18n.t('actions.reject'),
      cancelButtonText: I18n.t('actions.cancel'),
      buttonColor: '#B33939',
      onSuccess: () => rejectSuccess,
      requestParams: {
        resourcePath: 'orders',
        requestAction: 'UPDATE',
        httpAction: 'put',
        resourceId: order.id,
        additionalResource: { path: 'reject' }
      }
    }

    return showConfirmation(rejectParams)
  }

  useUpdateEffect(() => {
    if (getOrder.status === 'FETCHING') return dispatch(BusEvents.SHOW_LOADING_DIALOG)

    if (getOrder.status === 'SUCCESS') {
      dispatch(BusEvents.HIDE_DIALOG)

      if (getOrder.responseData.data.length === 0) {
        notifyError(I18n.t('requests.notification.missing_order_error'))
        return true
      }

      showRejectConfirmation(getOrder.responseData.data[0])
    }

    return true
  }, [getOrder.status, getOrder.responseData])

  return rejectAction
}
