import React from 'react'
import PropTypes from 'prop-types'
import Modal from '/src/ui/core/popups/modal'
import ChooseButton from '/src/ui/core/buttons/choose_button'
import '/src/static/css/core/popups/choose_modal.css'

export default function ChooseFormModal({ onClose, opts }) {
  const { buttons, title } = opts

  const chooseButtons = () =>
    buttons.map(({ title, subtitle, icon, onClick }) => (
      <ChooseButton key={title} onClick={onClick} title={title} subtitle={subtitle} icon={icon()} />
    ))

  return (
    <div className="confirmation-modal choose-modal">
      <Modal height={45} width={28} title={title} onClose={onClose} closable>
        <div className="body">{chooseButtons()}</div>
      </Modal>
    </div>
  )
}

ChooseFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opts: PropTypes.shape({
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        icon: PropTypes.func.isRequired
      })
    ).isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}
