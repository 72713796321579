import lodash from 'lodash'
import treatObjectFilter from '/src/ui/core/grid/filtering/object_filtering'
import { normalizeDateToString } from '/src/utils/project_formatter'

export const deletedColumnsOnFilters = (currentColumns, kendoGridFilters) => {
  const currentColumnNames = currentColumns.map(({ description, field }) => field || description)

  return kendoGridFilters.reduce((currentDeletedColumns, { field, column }) => {
    const fieldColumn = field || column
    if (currentColumnNames.includes(fieldColumn)) return currentDeletedColumns
    return [...currentDeletedColumns, fieldColumn]
  }, [])
}

export const templateFilters = (templateId, storedFilters, type = 'kendoGridFilters') => {
  const filtersByTemplate = storedFilters || {}
  const filtersOnTemplate = filtersByTemplate[templateId] || {}
  const filtersOnTemplateByType = filtersOnTemplate[type] || []
  return Array.isArray(filtersOnTemplateByType) ? filtersOnTemplateByType : []
}

const isFilterToRemove = ({ field, column }, columnNames) => {
  const filterColumn = field || column
  return columnNames.includes(filterColumn)
}

export const removeKendoFiltersByColumn = (columnNames, kendoGridFilters) => {
  return kendoGridFilters.filter((kendoFilter) => !isFilterToRemove(kendoFilter, columnNames))
}

export const removeColumnFiltersByColumn = (columnNames, columnFilters) => {
  return columnFilters.filter((columnFilter) => !isFilterToRemove(columnFilter, columnNames))
}

const convertFilters = (filters) => {
  const operatorConversion = { eq: 'where', neq: 'not' }
  const containsOrFilters = filters.filter((filter) => filter.type === 'contains_or')
  let convertedFilters = filters.filter((filter) => filter.type !== 'contains_or')
  let convertedContainsOrFilters = []

  if (convertedFilters.length > 0) {
    convertedFilters = convertedFilters.map((f) => {
      const type = operatorConversion[f.operator] || f.operator
      const value = f.value instanceof Date ? normalizeDateToString(f.value) : f.value

      return { type, column: f.field, value, field: f.originalField }
    })
  }
  if (containsOrFilters.length > 0) {
    containsOrFilters.forEach((f) => {
      const value = f.value instanceof Date ? normalizeDateToString(f.value) : f.value
      f.field.forEach((field) => {
        convertedContainsOrFilters.push({ type: f.type, column: field, value, field: f.originalField })
      })
    })
  }

  return convertedFilters.concat(convertedContainsOrFilters)
}

export const convertKendoToColumnFilters = ({ kendoGridFilters, gridColumns, batchedEntities, locale }) => {
  return convertFilters(
    kendoGridFilters.map((filter) =>
      treatObjectFilter({
        gridFilter: { ...filter, originalField: filter.field },
        gridColumns,
        batchedEntities,
        locale
      })
    )
  )
}

export const convertQueryFilters = (queryFilters) => {
  const { camelCase } = lodash

  return queryFilters.reduce((currentQuery, { type, column, value }) => {
    const camelCaseType = camelCase(type)
    const existingQuery = currentQuery[camelCaseType] || {}
    const isValueArray = Array.isArray(existingQuery[column])

    return {
      ...currentQuery,
      [camelCaseType]: {
        ...existingQuery,
        [column]: isValueArray ? [...existingQuery[column], ...value] : value
      }
    }
  }, {})
}
