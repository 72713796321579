import { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import { pluralize } from 'inflected'
import useFetch from '/src/hooks/api/fetch'
import { isEmpty } from 'lodash'

const inspectedServiceType = {
  progress_services: 'progress',
  estimate_services: 'scope'
}

export default function useInspectedData(dataItem) {
  const [moduleName, setModuleName] = useState()
  const [moduleId, setModuleId] = useState()
  const [inspectedData, setInspectedData] = useState()
  const [inspectedSections, setInspectedSections] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [requestStatuses] = useStore('request_statuses')

  const { fetch } = useFetch()

  useEffect(() => {
    if (!dataItem || isEmpty(dataItem)) return

    const inspectedType = dataItem?.inspected_type?.toLowerCase()
    const newModuleName = inspectedServiceType[inspectedType] ?? inspectedType
    const newModuleId = newModuleName === 'request' ? dataItem.request_id : dataItem.inspected_id

    if (newModuleName !== moduleName || newModuleId !== moduleId) {
      setIsLoading(true)
      setInspectedData(null)
    }

    const requestParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: newModuleId } }
    }

    fetch(pluralize(newModuleName), requestParams, {
      onSuccess: ({ data }) => {
        setModuleName(newModuleName)
        setModuleId(newModuleId)

        if (!data.data || isEmpty(data.data)) {
          setInspectedData(null)
          setInspectedSections([])
          setIsLoading(false)
        } else {
          const newData = data.data[0]

          if (newModuleName === 'request' && !newData.request_status) {
            newData.request_status = requestStatuses[newData.request_status_id]
          }

          if (newModuleName === 'progress') {
            newData.request.id = newData.request_id
          }

          setInspectedData(newData)
        }
      }
    })
  }, [dataItem, fetch, moduleId, moduleName])

  useEffect(() => {
    if (!inspectedData) return

    const requestParams = {
      requestAction: 'READ',
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: inspectedData.eav_template_id } }
    }

    fetch('eav_sections', requestParams, {
      onSuccess: ({ data }) => {
        setInspectedSections(data)
        setIsLoading(false)
      }
    })
  }, [inspectedData, fetch])

  useEffect(() => {
    if (!inspectedData?.request_id || moduleName !== 'scope' || inspectedData.request) return

    const requestParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: inspectedData.request_id } }
    }

    fetch('requests', requestParams, {
      onSuccess: ({ data }) => {
        setInspectedData((prev) => ({ ...prev, request: data.data[0] }))
        setIsLoading(false)
      }
    })
  }, [inspectedData, moduleName, fetch])

  return [moduleName, inspectedData, inspectedSections, isLoading]
}
