import React from 'react'
import PropTypes from 'prop-types'
import { MdDownload } from 'react-icons/md'
import useBuildUrl from '/src/hooks/build_url_attachment'

export default function DownloadAttachment({ file, fileCategory }) {
  const url = useBuildUrl(file)

  return (
    <a href={url} target="_blank" rel="noreferrer" className="download-button">
      <MdDownload />
      <p>{fileCategory}</p>
    </a>
  )
}

DownloadAttachment.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    uuid: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number
  }).isRequired,
  fileCategory: PropTypes.string
}

DownloadAttachment.defaultProps = {
  fileCategory: null
}
