import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { MdFlag, MdOutlineHourglassTop } from 'react-icons/md'
import { formatCurrency, formatNumberWithPrecision } from '/src/utils/project_formatter'
import EstimateServiceValuesTooltip from '/src/ui/domain/estimates/estimate_service_values_tooltip'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
import { translateFormulaService } from '/src/ui/domain/formulas_services/formulas_services_utils'
import { sortColumnsByPosition } from '/src/models/concerns/eav_column'

/**
 * This custom hook will receive a array of estimate services
 * and will generate building blocks to be used with <Card /> and <ExpandableCard />
 *
 * @param  estimateServices - array of estimate_services
 * @return {[{ tag, title, subtitle, details, id }]} - array of card building blocks
 */
export default function useEstimateServicesCardsBlocks(estimateServices, abbreviatedCard) {
  const [cardParts, setCardParts] = useState([])
  const [project] = useStore('project')

  useEffect(() => {
    if (!estimateServices || !estimateServices.length) return

    setCardParts(estimateServices.map(buildCard))
  }, [estimateServices])

  const buildTag = (tag) => <div className="estimate-item-tag">{tag}</div>

  const buildTitle = (description, firstColumn) => {
    const title = `${description}${firstColumn ? ` - ${firstColumn}` : ''}`
    return <div className="estimate-item-title">{title}</div>
  }

  const translate = (i18nDescription, column, eavTemplateId) => {
    return translateFormulaService(I18n.t(i18nDescription), column, eavTemplateId)
  }

  const buildValueLabel = (title, icon, value) => {
    return (
      <CustomTooltip position="bottom" parentTitle>
        <span title={title}>
          <div className="info">
            {abbreviatedCard ? <div className="icon">{icon}</div> : `${title}: `}
            {value}
          </div>
        </span>
      </CustomTooltip>
    )
  }

  const buildSubtitle = (serviceDescription, qtt, hours, eavTemplateId) => (
    <div className="estimate-item-subtitle">
      {serviceDescription && <div className="item-service">{serviceDescription}</div>}
      {buildValueLabel(translate('estimate_services.quantity', 'quantity', eavTemplateId), <MdFlag />, qtt)}
      {buildValueLabel(
        translate('estimate_services.team_target_hours', 'team_target_hours', eavTemplateId),
        <MdOutlineHourglassTop />,
        hours
      )}
    </div>
  )

  const getEstimatesValues = (estimateService, unit) => {
    const {
      quantity,
      application_price: applicationPrice,
      materials_price: materialsPrice,
      equipment_price: equipmentPrice,
      other_price: otherPrice,
      team_target_hours: teamTargetHours
    } = estimateService

    const qtt = `${formatNumberWithPrecision(quantity, project)} ${unit.description}`
    const hours = `${formatNumberWithPrecision(teamTargetHours, project)}h`
    const price = applicationPrice + materialsPrice + equipmentPrice + otherPrice
    const formattedPrice = formatCurrency(price, project)
    const sortedColumns = sortColumnsByPosition(estimateService.columns)
    const firstColumn = getFirstStringOrFormula(sortedColumns) || {}
    const firstColumnDescription = firstColumn.description
    const firstColumnValue = estimateService[firstColumnDescription]

    return { qtt, hours, price, formattedPrice, firstColumnValue }
  }

  const buildCard = (estimateService, index) => {
    const {
      id,
      description,
      service_description: serviceDescription,
      unit,
      eav_template_id: templateId
    } = estimateService

    if (!unit) return

    const { qtt, hours, price, formattedPrice, firstColumnValue } = getEstimatesValues(estimateService, unit)

    const tag = buildTag(index + 1)
    const title = buildTitle(description, firstColumnValue)
    const subtitle = buildSubtitle(serviceDescription, qtt, hours, templateId)
    const details = <EstimateServiceValuesTooltip estimateService={estimateService} totalPrice={formattedPrice} />

    return {
      tag,
      title,
      subtitle,
      details,
      price,
      key: id,
      templateId
    }
  }

  return cardParts
}
