import React from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircle, MdOutlineError } from 'react-icons/md'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'
import '/src/static/css/core/inputs/image_thumbnail.css'

export default function StatusIcon({ loadingStatus }) {
  if (loadingStatus === 'success') {
    return (
      <div className={`status-icon ${loadingStatus.toLowerCase()}`}>
        <MdCheckCircle />
      </div>
    )
  }

  if (loadingStatus === 'error') {
    return (
      <div className={`status-icon ${loadingStatus.toLowerCase()}`}>
        <MdOutlineError />
      </div>
    )
  }

  return <SpinnerIcon className={`status-icon ${loadingStatus.toLowerCase()}`} />
}

StatusIcon.propTypes = {
  loadingStatus: PropTypes.string
}

StatusIcon.defaultProps = {
  loadingStatus: 'not_started'
}
