import React from 'react'
import PropTypes from 'prop-types'

export default function RadioButtonOption({ inputProps, option }) {
  const { id, className, inputValue, onChange, readOnly: componentReadOnly, preventNull } = inputProps
  let label = option
  let value = option
  let optionReadOnly = false

  if (typeof option === 'object') {
    ;({ label, value, readOnly: optionReadOnly = false } = option)
  }

  const isReadOnly = componentReadOnly || optionReadOnly

  const onClick = (e) => {
    if (inputValue === value && !preventNull) e.target.value = null
    onChange(e)
  }

  return (
    <div className="option">
      <input
        type="radio"
        id={id}
        className={className}
        name={id}
        value={value}
        checked={inputValue === value}
        onClick={onClick}
        onChange={() => {}}
        disabled={isReadOnly}
        style={{
          opacity: isReadOnly ? 0.5 : 1,
          cursor: isReadOnly ? 'not-allowed' : 'pointer'
        }}
      />
      <label
        title={label}
        htmlFor={id}
        className={className}
        style={{
          color: isReadOnly && '#8097a2',
          cursor: isReadOnly ? 'not-allowed' : 'pointer'
        }}
      >
        {label}
      </label>
    </div>
  )
}

RadioButtonOption.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    preventNull: PropTypes.bool
  }).isRequired,
  option: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      disabled: PropTypes.bool
    })
  ]).isRequired
}
