import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { BiError } from 'react-icons/bi'
import ItemsCarousel from 'react-items-carousel'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import '@brainhubeu/react-carousel/lib/style.css'
import '/src/static/css/core/popups/image_modal.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const handleKeyUp = (e) => {
  switch (e.key) {
    case 'ArrowLeft':
      const leftChevron = document.querySelector('.left-chevron')
      if (leftChevron) leftChevron.click()
      break
    case 'ArrowRight':
      const rightChevron = document.querySelector('.right-chevron')
      if (rightChevron) rightChevron.click()
      break
    case 'Escape':
      const closeModal = document.querySelector('.k-i-close')
      if (closeModal) closeModal.click()
      break
  }
}

export default function ImageModal({ height, width, images, extraClass, openImage, onClose }) {
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const [imageFound, setImageFound] = useState(true)
  const [index, setIndex] = useState(openImage)
  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`

  const getSrc = (image) => {
    const { file_path: filePath } = image

    return `${SERVER_URL + filePath}?${subprojectQuery}`
  }

  const onError = () => {
    notifyError(I18n.t('fields.image_not_found'))

    setImageFound(false)
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <Modal
      title={`${index + 1}/${images.length}`}
      onClose={onClose}
      width={width}
      height={height}
      closable
      className={`image-modal ${extraClass}`}
    >
      {imageFound ? (
        <ItemsCarousel
          requestToChangeActive={setIndex}
          activeItemIndex={index}
          numberOfCards={1}
          leftChevron={
            <div className="chevron">
              <MdKeyboardArrowLeft />
            </div>
          }
          rightChevron={
            <div className="chevron">
              <MdKeyboardArrowRight />
            </div>
          }
          outsideChevron
          chevronWidth={40}
          classes={{
            wrapper: 'carousel',
            rightChevronWrapper: 'right-chevron',
            leftChevronWrapper: 'left-chevron'
          }}
        >
          {images.map((image) => (
            <div key={index} className="selected-image-wrapper">
              <img
                id="modal-image"
                className="selected-image"
                src={getSrc(image)}
                onError={onError}
                alt={image.uuid || image.file_path}
              />
              <span className="comment">{image.comment} </span>
            </div>
          ))}
        </ItemsCarousel>
      ) : (
        <div className="not-found-placeholder-wrapper">
          <div className="not-found-placeholder-icon">
            <BiError />
          </div>
          <div className="not-found-message">{I18n.t('fields.image_not_found')}</div>
        </div>
      )}
    </Modal>
  )
}

ImageModal.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  openImage: PropTypes.number,
  onClose: PropTypes.func
}

ImageModal.defaultProps = {
  height: 90,
  width: 90,
  images: [],
  openImage: 0,
  extraClass: '',
  onClose: () => {}
}
