const changePlaystoreLink = (currentUrl) => {
  let appUrl = 'https://play.google.com/store/apps/details?id=com.kaefer'
  switch (true) {
    case currentUrl.includes('dpmstest5.oxeanbits.com'):
      appUrl += '.pms.norway'
      break
    case currentUrl.includes('dpmsdemov2.kaefer.com'):
      appUrl += '.pms.demo'
      break
    case currentUrl.includes('dpms.ea1.kaefer.com'):
      appUrl += '.dpms.stable.ea1'
      break
    default:
      appUrl += '.dpms'
      break
  }
  return appUrl
}

export { changePlaystoreLink }
