import { useEffect, useMemo, useState } from 'react'
import useFetch from '/src/hooks/api/fetch'

export default function useDatasheetFilterColumns({ datasheetFilters, multipleDatasheetFilters, hasDefaultColumns }) {
  const { fetch } = useFetch()
  const [datasheetColumns, setDatasheetColumns] = useState()
  const [multipleDatasheetColumns, setMultipleDatasheetColumns] = useState()

  const filterColumns = useMemo(() => {
    let columnsOnFilter

    if (datasheetColumns) columnsOnFilter = { ...datasheetColumns }
    if (multipleDatasheetColumns) columnsOnFilter = { ...columnsOnFilter, ...multipleDatasheetColumns }

    return columnsOnFilter || (hasDefaultColumns ? {} : undefined)
  }, [datasheetColumns, multipleDatasheetColumns, hasDefaultColumns])

  useEffect(() => {
    if (!datasheetFilters || Object.values(datasheetFilters).length === 0) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: Object.values(datasheetFilters).map((filter) => filter.datasheet_column_id) }
      }
    }

    fetch('eav_columns', queryParams, {
      onSuccess: ({ data }) => {
        const { data: eavColumns } = data
        const fetchedDatasheetColumns = eavColumns.reduce(
          (currentObj, column) => ({
            ...currentObj,
            [column.id]: column
          }),
          {}
        )

        setDatasheetColumns((prevDatasheetColumns) => ({
          ...prevDatasheetColumns,
          ...fetchedDatasheetColumns
        }))
      }
    })
  }, [datasheetFilters, fetch])

  useEffect(() => {
    if (!multipleDatasheetFilters || Object.values(multipleDatasheetFilters).length === 0) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          id: Object.values(multipleDatasheetFilters).map((filter) => filter.datasheet_column_id)
        }
      }
    }

    fetch('eav_columns', queryParams, {
      onSuccess: ({ data }) => {
        const { data: eavColumns } = data
        const fetchedDatasheetColumns = eavColumns.reduce(
          (currentObj, column) => ({
            ...currentObj,
            [column.id]: column
          }),
          {}
        )

        setMultipleDatasheetColumns((prevDatasheetColumns) => ({
          ...prevDatasheetColumns,
          ...fetchedDatasheetColumns
        }))
      }
    })
  }, [multipleDatasheetFilters, fetch])

  return filterColumns
}
