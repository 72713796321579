import I18n from '/src/utils/translations'
import { getCurrentDate, getPrevCurrentDate } from '/src/utils/calendar'
import { BetweenOperator, IsEqualOperator } from '/src/models/concerns/filter_operators'

export const disabledStatuses = ['progress', 'completed']
export const scheduled = { id: 8, description: 'Scheduled', filtered: false, i18n_id: 'scheduled' }
export const overdue = { id: 9, description: 'Overdue', filtered: false, i18n_id: 'overdue' }

export const scheduledFilterObject = {
  type: 'not',
  column: 'recurrency_uuid',
  operator: IsEqualOperator,
  value: null
}
export const overdueFilterObject = () => ({
  type: 'lessThanOrEqualTo',
  column: 'inspect_date',
  operator: IsEqualOperator,
  value: getPrevCurrentDate()
})
export const notOverdueFilterObject = () => ({
  type: 'moreThanOrEqualTo',
  column: 'inspect_date',
  operator: IsEqualOperator,
  value: getCurrentDate()
})
export const pendingFilter = (id) => ({
  type: 'where',
  column: 'inspect_status_id',
  operator: IsEqualOperator,
  value: id
})
export const filterObject = (ids) => ({
  type: 'where',
  column: 'inspect_status_id',
  operator: IsEqualOperator,
  value: ids
})
export const getFilteredStatusesNames = (newStatuses) => {
  const statusesNames = newStatuses.filter((x) => x.filtered).map((x) => I18n.t(`inspections.statuses.${x.i18n_id}`))
  return statusesNames
}

export const getStatus = (item, statuses) => {
  if (!item || !item.inspect_status_id) return
  if (!statuses || Object.keys(statuses).length === 0) return

  const status = statuses[item.inspect_status_id].i18n_id
  if (isScheduled(status, item.inspect_date, item.recurrency_uuid)) return scheduled.i18n_id

  return statuses[item.inspect_status_id].i18n_id
}

export const checkInspectionFinished = (inspection, statuses) => {
  const inspectionStatus = getStatus(inspection, statuses)
  return inspectionStatus === 'approved' || inspectionStatus === 'rejected'
}

export const isOverdue = (date, status) => status === 'pending' && getCurrentDate() > date
export const isScheduled = (status, date, recurrence) => status === 'pending' && !isOverdue(date, status) && recurrence
