import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import { MdChevronLeft } from 'react-icons/md'
import LanguageSwitch from '/src/ui/core/menus/language_switch'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'
import I18n from '/src/utils/translations'
import '/src/static/css/forgot_password.css'

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [isNotEmpty, setIsNotEmpty] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [pictureIndex] = useState(Math.ceil(Math.random() * 10))

  const picturePath = `/static/images/kaefer-worker-${pictureIndex}.jpg`

  useStore('language')

  const handleOnChange = (event) => {
    const newIsNotEmpty = event.target.value !== ''
    setIsNotEmpty(newIsNotEmpty)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    /* INCLUDE HERE LOGIC TO SEND EMAIL
    For now, it only has a setTimeout to simulate the delay of the API response.
    It's also setting the loading as true, in order to disable the submit button.
    When the actual logic is implemented, it should:
    - setLoading(true),
    - perform all the logic to send the email (API call)
    - after receiving the API response, setIsEmailSent(true) and setLoading(false),
    possibly inside a useEffect */

    setLoading(true)

    setTimeout(() => {
      setIsEmailSent(true)
      setLoading(false)
    }, 1000)
  }

  return (
    <div className="login-wrapper">
      <div className="login-info-wrapper">
        <div className="logo-login-wrapper">
          <div className="logo-login">
            <img className="dpms-logo-full" src="/static/svg/logo-red.svg" alt="" />
          </div>
          <LanguageSwitch />
        </div>

        <a className="back-to-login-group" href="/">
          <div className="back-to-login-chevron">
            <MdChevronLeft />
          </div>
          <div className="back-to-login-text">{I18n.t('forgot_password.back_to_login')}</div>
        </a>

        <div className="forgot-password-title-wrapper">
          <div className="forgot-password-title">{I18n.t('forgot_password.title')}</div>
          <div className="forgot-password-subtitle">
            {!isEmailSent ? I18n.t('forgot_password.subtitle') : I18n.t('forgot_password.feedback')}
          </div>
        </div>

        {!isEmailSent && (
          <form className="forgot-password-form-wrapper" onSubmit={handleSubmit}>
            <div className="forgot-password-form">
              <div className="forgot-password-input-wrapper">
                <div className="forgot-password-input">
                  <input
                    className="form-input-text"
                    data-testid="email-input"
                    type="email"
                    onChange={handleOnChange}
                    required
                  />
                  <span className={`floating-label ${isNotEmpty ? 'not-empty' : ''}`}>
                    {I18n.t('forgot_password.placeholder')}
                  </span>
                </div>
              </div>
              <div className="submit-input">
                <button
                  data-testid="submit-button"
                  className="login-button"
                  type="submit"
                  disabled={loading}
                  aria-label={I18n.t('forgot_password.button')}
                >
                  {loading ? (
                    <SpinnerIcon key="forgot-password-spinner" className="icon-container" />
                  ) : (
                    I18n.t('forgot_password.button')
                  )}
                </button>
              </div>
            </div>
          </form>
        )}

        <div className="rights-reserved-kaefer">{I18n.t('login.copyright', { year: new Date().getFullYear() })}</div>
      </div>
      <div className="login-image-wrapper">
        <img src={picturePath} alt="Cover" />
      </div>
    </div>
  )
}
