/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { MdFilterList } from 'react-icons/md'
import PropTypes from 'prop-types'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import '/src/static/css/core/icons/popup_icon.css'
import useSyncBatch from '/src/hooks/api/sync_batch'
import I18n from '/src/utils/translations'
import { convert } from '/src/hooks/api/query_builder'

export default function GridFilterIcon({ template, model, onFilterSelection }) {
  const [active, setActive] = useState(false)
  const popupButtonRef = useRef(null)
  const [getFilters, setFilters] = useState([])
  const [getItems, setItems] = useState([])

  const batchedEntities = {}

  if (template) {
    batchedEntities.grid_filters = {
      get: getFilters,
      set: setFilters,
      query: { where: { eav_template_id: template.id, template_type: template.type, model } }
    }
  }

  const { loading } = useSyncBatch(batchedEntities)

  useEffect(() => {
    if (loading) return
    const items = Object.keys(getFilters).map((key) => {
      return { text: getFilters[key].name, onClick: () => {} }
    })
    items.unshift({ title: true, text: I18n.t('grid.grid_filters.menu_title'), onClick: () => {} })
    setItems(items)
  }, [loading])

  const onSelection = useCallback(
    (selected) => {
      if (selected === null) return onFilterSelection([])

      const selectedFilterId = Object.keys(getFilters).find((key) => getFilters[key].name === selected)

      const filter = getFilters[selectedFilterId] ? convert(getFilters[selectedFilterId].query) : []

      onFilterSelection(filter)
    },
    [onFilterSelection, getFilters]
  )

  return (
    <React.Fragment>
      {getItems && getItems.length > 1 && (
        <div className="popup-icon" id="filter-icon-id">
          <PopupMenu
            items={getItems}
            setActive={setActive}
            popupButtonRef={popupButtonRef}
            selectable
            onSelection={onSelection}
          >
            <button
              ref={popupButtonRef}
              id="filter-button-id"
              data-testid="grid-filter-button"
              type="button"
              className={`open-icon-popup${active ? ' active-button' : ''} grid-header-icon`}
            >
              <MdFilterList />
            </button>
          </PopupMenu>
        </div>
      )}
    </React.Fragment>
  )
}

GridFilterIcon.propTypes = {
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  model: PropTypes.string.isRequired
}

GridFilterIcon.defaultProps = {
  template: undefined
}
