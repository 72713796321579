import React, { useMemo, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdFilterAlt, MdFilterAltOff } from 'react-icons/md'
import { HeaderFilterInput } from '/src/ui/core/grid/filtering/header_filter_input'
import { isBlank } from '/src/utils/boolean_refinements'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import { getFilterOperators } from '/src/models/concerns/eav_column'
import { BetweenOperator } from '/src/models/concerns/filter_operators'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import '/src/static/css/core/grid/filtering.css'

export const HeaderFilterCell = ({ columns, field, value, operator, operators, booleanValues, onChange }) => {
  const column = useMemo(() => {
    const col =
      columns.find(({ field: columnField, description }) => columnField === field || description === field) || {}
    if (DROP_COLUMN_TYPES.includes(col.type)) col.type = 'drop'
    return col
  }, [columns, field])

  const filterOperators = getFilterOperators(operators, column)
  const defaultOperator = operator || (filterOperators.length > 0 && filterOperators[0].operator)

  const [currentOperator, setCurrentOperator] = useState(defaultOperator)
  const operatorsButtonRef = useRef()

  const onClear = (event) => {
    onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
      nativeEvent: { type: 'reset' }
    })
  }

  useEffect(() => {
    setCurrentOperator(defaultOperator)
  }, [column])

  const operatorItems = filterOperators.map(({ text, operator: itemOperator }) => ({
    text,
    onClick: () => {
      setCurrentOperator(itemOperator)
      if (!isBlank(value) && !(itemOperator === BetweenOperator || currentOperator === BetweenOperator))
        onChange({ value, operator: itemOperator })
    },
    selected: currentOperator === itemOperator
  }))

  return (
    <div className="k-filtercell">
      <HeaderFilterInput
        column={column}
        value={value}
        operator={currentOperator}
        onChange={onChange}
        booleanValues={booleanValues}
      />
      <PopupMenu items={operatorItems} popupButtonRef={operatorsButtonRef}>
        <button type="button" className="btn-icon" data-testid="btn-change-operator" ref={operatorsButtonRef}>
          <MdFilterAlt />
        </button>
      </PopupMenu>
      {!isBlank(value) && (
        <button type="button" className="btn-icon" onClick={onClear} data-testid="btn-clear-filter">
          <MdFilterAltOff />
        </button>
      )}
    </div>
  )
}

HeaderFilterCell.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      column_type: PropTypes.shape({
        description: PropTypes.string
      })
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({ start: PropTypes.string, end: PropTypes.string })
  ]),
  operator: PropTypes.string,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      operator: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func,
  booleanValues: PropTypes.array.isRequired
}

HeaderFilterCell.defaultProps = {
  columns: [],
  value: undefined,
  operator: '',
  onChange: () => {}
}
