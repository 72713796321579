import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDraggable } from '@progress/kendo-react-common'

export default function DraggableListItem({ children, id, onDragStart, ...props }) {
  const draggableElement = useRef(null)
  const [dragCoordinates, setDragCoordinates] = useState()

  const handleDragStart = useCallback(
    (event) => {
      setDragCoordinates({
        x: event.clientX,
        y: event.clientY
      })

      if (onDragStart) onDragStart(id)
    },
    [id, onDragStart]
  )

  const handleDrag = useCallback(
    (event) => {
      if (!draggableElement.current || !dragCoordinates) return

      const transform = `translate(${event.clientX - dragCoordinates.x}px, ${event.clientY - dragCoordinates.y}px)`

      draggableElement.current.style.transform = transform
    },
    [dragCoordinates]
  )

  const handleDragEnd = useCallback(() => {
    if (!draggableElement.current) return

    draggableElement.current.style.transform = null

    setDragCoordinates()
  }, [])

  useDraggable(draggableElement, {
    onDragStart: handleDragStart,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd
  })

  return (
    <div
      className="draggable-list-item"
      id={id}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      ref={draggableElement}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  )
}

DraggableListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  onDragStart: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
