import React from 'react'
import PropTypes from 'prop-types'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import InspectionForm from '/src/ui/domain/inspections/inspection_form'
import InspectedItem from '/src/ui/domain/inspections/inspected_item'

export default function InspectionFormModal({ modelParamName, template }) {
  return (
    <GridFormModal modelName={modelParamName} formSidePanel={<InspectedItem />}>
      <InspectionForm template={template} />
    </GridFormModal>
  )
}

InspectionFormModal.propTypes = {
  modelParamName: PropTypes.string,
  template: PropTypes.oneOfType([PropTypes.object])
}

InspectionFormModal.defaultProps = {
  modelParamName: '',
  template: {}
}
