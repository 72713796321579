import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdCloudDownload } from 'react-icons/md'
import { Window } from '@progress/kendo-react-dialogs'
import I18n from '/src/utils/translations'
import ProgressUploader from '/src/ui/core/inputs/progress_uploader'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { pollingStatus } from '/src/hooks/api/polling_api'
import { byString } from '/src/utils/object'
import '/src/static/css/form.css'

const DOWNLOAD_TEMPLATE_ACTION = '/template_xlsx'
const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

// eslint-disable-next-line max-lines-per-function
export default function PopupImportFile({ modelRoute, templateId, onClose, setFile, button, useLegacyPath }) {
  const [visible, setVisible] = useState(!button)
  const [importedFile, setImportedFile] = useState()
  const [importButtonVisible, setImportButtonVisible] = useState(true)
  const [excelDownloadPath, setExcelDownloadPath] = useState('')
  const [importationStatus, setImportationStatus] = useState()
  const [jobError, setJobError] = useState()

  useEffect(() => {
    const apiPath = useLegacyPath ? '' : 'api/v1/'
    let path = `${SERVER_URL}/${apiPath}${modelRoute}${DOWNLOAD_TEMPLATE_ACTION}`
    path += `?eav_template_id=${templateId}`
    setExcelDownloadPath(path)
  }, [modelRoute, templateId])

  useEffect(() => {
    if (importationStatus === undefined) return
    let body
    switch (importationStatus) {
      case pollingStatus.SUCCESS:
        body = I18n.t('importation_popup.importation_success')
        if (setFile) setFile(importedFile)
        notifySuccess({ title: I18n.t('notification.success'), body })
        setVisible(false)
        onClose()
        break
      case pollingStatus.ERROR:
        body = I18n.t('importation_popup.importation_error')
        if (byString(jobError, 'exception')) {
          body = `${jobError.exception.join('; ')} ${body}`
        }
        notifyError({ title: I18n.t('notification.error'), body })
        setImportButtonVisible(true)
        break
      case pollingStatus.CONTINUE:
      case pollingStatus.TIMEOUT:
      default:
    }
  }, [importationStatus])

  useEffect(() => {
    if (visible) return
    setImportButtonVisible(true)
  }, [visible])

  const importClick = () => {
    document.getElementsByClassName('k-button k-primary k-upload-selected')[0].click()
    setImportButtonVisible(false)
  }

  const handleClose = () => {
    setVisible(false)
    onClose()
  }

  return (
    <React.Fragment>
      {button && (
        <div className="d-flex" onClick={() => templateId && setVisible(true)} role="button" aria-hidden="true">
          {button}
        </div>
      )}
      {visible && (
        <>
          <Window
            title={I18n.t('actions.import')}
            onClose={handleClose}
            modal
            style={{
              margin: '0 auto',
              left: '50%',
              top: '50%',
              border: '1px solid',
              transform: 'translate(-50%, -50%)'
            }}
            initialHeight={628}
            initialWidth={610}
            minimizeButton={() => null}
            maximizeButton={() => null}
          >
            <h3>{I18n.t('importation_popup.submit_spreadsheet')}</h3>
            <div className="form-info">
              <h4>{`${I18n.t('importation_popup.requirements')}:`}</h4>
              <ul>
                {I18n.t('importation_popup.instructions')
                  .split('<br>')
                  .map((s, i) => {
                    return <li key={i}> {s} </li>
                  })}
              </ul>
              <a target="_blank" rel="noopener noreferrer" href={excelDownloadPath} className="button-download">
                <MdCloudDownload />
                {I18n.t('actions.export_template')}
              </a>
            </div>
            <div className="form-upload">
              <p>
                <br />
                {I18n.t('actions.upload_file')}
              </p>
              <ProgressUploader
                modelRoute={modelRoute}
                templateId={templateId}
                onImportationStatus={setImportationStatus}
                onError={setJobError}
                setImportedFile={setImportedFile}
              />
            </div>
            {importButtonVisible && (
              <div className="import-button">
                <button type="button" className="import-from-xls empty-button" onClick={() => importClick()}>
                  {I18n.t('actions.import_file')}
                </button>
              </div>
            )}
          </Window>
        </>
      )}
    </React.Fragment>
  )
}

PopupImportFile.propTypes = {
  button: PropTypes.element,
  modelRoute: PropTypes.string.isRequired,
  templateId: PropTypes.number,
  onClose: PropTypes.func,
  setFile: PropTypes.func
}

PopupImportFile.defaultProps = {
  button: null,
  templateId: null,
  setFile: undefined,
  onClose: () => {}
}
