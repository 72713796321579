import React, { useCallback, useEffect, useMemo, useState } from 'react'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFormModal from '/src/ui/core/popups/form_modal'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { PartBadges, ItemBadges } from '/src/ui/domain/scaffoldings/badges.jsx'
import useDismantlePopupFields from '/src/ui/domain/scaffoldings/dismantle_popup_fields'
import '/src/static/css/domain/scaffoldings/dismantle_popup.css'

export default function useDismantlePopup(scaffoldingItem, model) {
  const { route } = model
  const { fetch } = useFetch()

  const isScaffoldingItemModel = route === 'scaffoldings'
  const [scaffoldingDataItem, setScaffoldingDataItem] = useState(scaffoldingItem)
  const [dataItem, errorMessages, resetForm, sections] = useDismantlePopupFields(scaffoldingDataItem, route)
  const [dismantleElement, setDismantleElement] = useState({})

  const modalTitle = I18n.t(`${route}.dismantle_modal.title`)
  const formTitle = I18n.t(`${route}.dismantle_modal.confirmation_message`)
  const confirmText = I18n.t(`${route}.dismantle_modal.confirm`)
  const cancelText = I18n.t(`${route}.dismantle_modal.cancel`)
  const dismantleMessage = I18n.t(`${route}.dismantle_modal.success_message`)

  const [width, height] = isScaffoldingItemModel ? [27, 60] : [24, 55]

  const badges = isScaffoldingItemModel ? (
    <ItemBadges scaffoldingItem={dismantleElement} />
  ) : (
    <PartBadges scaffoldingPartItem={dismantleElement} />
  )

  useEffect(() => {
    setScaffoldingDataItem(scaffoldingItem)
  }, [scaffoldingItem])

  const dismantlePartRequest = useMemo(
    () => ({
      requestAction: 'CREATE',
      httpAction: 'post',
      data: {
        action_type: 'modify',
        modification_type: 'dismantle',
        width: 1,
        height: 1,
        length: dismantleElement.total,
        scaffolding_part_id: dismantleElement.id,
        comment: 'GENERATED BY PART DISMANTLE',
        ...dataItem
      }
    }),
    [dataItem, dismantleElement]
  )

  const dismantleItemRequest = useMemo(
    () => ({
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: dismantleElement.id,
      additionalResource: { path: 'dismantle' },
      data: dataItem
    }),
    [dataItem, dismantleElement]
  )

  const dismantle = useCallback(() => {
    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    const [path, params] = isScaffoldingItemModel
      ? ['scaffoldings', dismantleItemRequest]
      : ['scaffolding_part_actions', dismantlePartRequest]

    fetch(path, params, {
      onSuccess: () => {
        dispatch(BusEvents.HIDE_DIALOG)
        notifySuccess(dismantleMessage)
        dispatch(BusEvents.FORM_SUCCESS)
        resetForm()
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }, [resetForm, fetch, dismantleMessage, dismantleItemRequest, dismantlePartRequest, isScaffoldingItemModel])

  const onClick = useCallback(() => {
    if (errorMessages.length > 0) {
      notifyError(errorMessages.join(', '))
    } else {
      dismantle()
    }
  }, [errorMessages, dismantle])

  const buttons = [
    {
      id: 1,
      text: cancelText,
      type: 'cancel',
      cancel: true,
      halfWidth: true,
      disabled: false,
      class: 'cancel-button'
    },
    {
      id: 2,
      text: confirmText,
      type: 'submit',
      submit: true,
      onClick,
      halfWidth: true,
      disabled: false,
      class: 'dismantle-button'
    }
  ]

  const modalProps = {
    className: 'dismantle-popup',
    modalTitle,
    formTitle,
    sections,
    badges,
    buttons,
    dataItem,
    model,
    height,
    width,
    sectionable: false,
    closable: true,
    onClose: () => resetForm()
  }

  const [openModal, renderModal] = useFormModal(modalProps)

  const openPopup = useCallback(
    (element) => {
      if (isScaffoldingItemModel) setScaffoldingDataItem(element)
      setDismantleElement(element)
      openModal()
    },
    [openModal, isScaffoldingItemModel]
  )

  return [openPopup, renderModal]
}
