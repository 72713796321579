import { useState } from "react"

const jumpMapping = {
  // request_id
  1: {
    Request: 4
  },
  // inspected_id
  3: {
    Scope: 2,
    Progress: 2,
    Scaffolding: 2
  }
}

export default function useJumpFilterSteps(filtersLength) {
  const [jumpStack, setJumpStack] = useState([])

  const findJump = (index, currentSelectedValue, inspectedType) => {
    let jump = 1

    if (jumpMapping[index]?.[inspectedType] !== undefined) {
      jump = jumpMapping[index][inspectedType]
    }

    if (currentSelectedValue === 'Scaffolding') {
      jump = 3
    }
    
    const newIndex = Math.max(Math.min(index + jump, filtersLength), 0)
    setJumpStack([...jumpStack, Math.abs(index - newIndex)])

    return newIndex
  }

  const findLastJump = (index) => {
    const currentjumpStack = [...jumpStack]
    
    const lastJump = currentjumpStack.pop() * -1
    setJumpStack(currentjumpStack)
    
    const newIndex = Math.max(Math.min(index + lastJump, filtersLength), 0)
    return newIndex
  }

  return { findJump, findLastJump }
}
