import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { fullTitleDisplay, titleLimiter } from '/src/utils/string'

export default function CategoryItem({ category, lenghtLimit }) {
  const { id, description } = category

  return (
    <Link to={`/datasheets?filter=datasheet_category_id:${id}`} title={fullTitleDisplay(description, lenghtLimit)}>
      {lenghtLimit ? titleLimiter(description, lenghtLimit) : description}
    </Link>
  )
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string
  }).isRequired,
  lenghtLimit: PropTypes.number
}

CategoryItem.defaultProps = {
  lenghtLimit: undefined
}
