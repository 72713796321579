import React from 'react'
import PropTypes from 'prop-types'

export default function LookupColumn({ value }) {
  return value
}

LookupColumn.propTypes = {
  value: PropTypes.string
}

LookupColumn.defaultProps = {
  value: null
}
