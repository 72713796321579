import React from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircleOutline, MdErrorOutline, MdPrint } from 'react-icons/md'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { estimateIsReadOnly } from '/src/models/estimate'
import ActionButton from '/src/ui/core/buttons/action_button'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateFormState({ estimates, statuses, children }) {
  const isEstimateEmpty = estimates.length === 0

  const allEstimatesAreReadOnly = estimates.every((estimate) => {
    return estimateIsReadOnly(estimate, statuses)
  })

  const renderPrintButton = (className) => (
    <ActionButton
      icon={<MdPrint />}
      title={I18n.t('estimate_services.print')}
      onClick={() => dispatch({ type: BusEvents.PRINT_PDF })}
      className={`print-button button-action ${className}`}
    />
  )

  if (!isEstimateEmpty && !allEstimatesAreReadOnly)
    return (
      <React.Fragment>
        {children}
        {renderPrintButton()}
      </React.Fragment>
    )

  const icon = isEstimateEmpty ? <MdErrorOutline /> : <MdCheckCircleOutline />
  const messageKey = isEstimateEmpty ? 'estimate.no_estimate_message' : 'estimate.info_message'
  const className = isEstimateEmpty ? 'empty-estimate-state' : 'approved-estimate-state'

  return (
    <div className="estimate-state">
      <div className={`${className}`}>{icon}</div>
      {`${I18n.t(messageKey)}`}
      {renderPrintButton('right')}
    </div>
  )
}

EstimateFormState.propTypes = {
  estimates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      estimate_status_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string])
}

EstimateFormState.defaultProps = {
  estimates: [],
  statuses: {},
  children: null
}
