import React, { useCallback, useState } from 'react'
import ConfigureWorkPackageFile from '/src/ui/domain/requests/popups/configure_work_package_file_modal'

export default function useConfigureWorkPackageFile() {
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const [request, setRequest] = useState()

  const openWorkPackageCofiguration = useCallback((selectedRequest) => {
    setIsDialogOpened(true)
    setRequest(selectedRequest)
  }, [])

  const onClose = useCallback(() => {
    setIsDialogOpened(false)
    setRequest()
  }, [])

  const popupComponent = isDialogOpened && <ConfigureWorkPackageFile onClose={onClose} request={request} />

  return [openWorkPackageCofiguration, popupComponent]
}
