import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function LoadClassColumn({ value }) {
  const translationKey = value && value.i18n_id
  if (!translationKey) return ''
  return I18n.t(`scaffoldings.load_classes.${translationKey}`)
}

LoadClassColumn.propTypes = {
  value: PropTypes.shape({
    i18n_id: PropTypes.string
  })
}

LoadClassColumn.defaultProps = {
  value: {}
}
