import React from 'react'
import { useStore } from 'react-context-hook'
import { titleize } from 'inflected'
import '@progress/kendo-theme-material/dist/all.css'
import '/src/static/css/pages/home.css'
import Layout from '/src/ui/core/layouts/layout'
import useBatchEntities from '/src/hooks/api/batch_entities'
import I18n from '/src/utils/translations'
import { changePlaystoreLink } from '../ui/domain/home/home_controller'

export default function Home() {
  const [user] = useStore('user')
  const loadingBatch = useBatchEntities()

  const cards = ['create_request', 'scoping', 'performance', 'inspection']

  const handleClick = () => {
    const playstoreLink = changePlaystoreLink(window.location.origin)
    window.open(playstoreLink, '_blank')
  }

  return (
    <Layout>
      <div className="home__welcome-user">{I18n.t('home.welcome_user', { user: user ? titleize(user.name) : '' })}</div>
      <div className="home__content">
        <div className="home__banner">
          <div className="home__banner-content">
            <h1>{I18n.t('home.dpms_mobile')}</h1>
            <h3>{I18n.t('home.banner_description')}</h3>

            <button type="button" id="google-play-button" className="home__google-play-button" onClick={handleClick}>
              <img src="/static/svg/google-play-icon.svg" alt="" />
              <div className="home__google-play">
                <div className="home__google-play-available-on">{I18n.t('home.available_on')}</div>
                <div className="home__google-play-desc">Google Play</div>
              </div>
            </button>
          </div>
        </div>
        <div className="home__getting-started">
          <div className="home__getting-started-text">{I18n.t('home.getting_started')}</div>

          <div className="home__card-list">
            {cards.map((card, index) => (
              <div key={card} className="home__card">
                <div className="home__card-number">{index + 1}</div>
                <div className="home__card-text">
                  <span className="home__card-title">{I18n.t(`home.${card}.title`)}</span>
                  <p>{I18n.t(`home.${card}.text`)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
