import { columnType } from '/src/models/concerns/eav_column'
import { fieldWidthByType } from '/src/utils/constants/fields'
import { isBlank } from '/src/utils/boolean_refinements'
import { byString } from '/src/utils/object'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'

export const getColumnWidth = (column, columnSettings) => {
  const { web_grid_width: webGridWidth, width } = column
  const { width: userWidth } = columnSettings

  return userWidth || webGridWidth || width || fieldWidthByType(columnType(column))
}

export const isColumnPrintable = (column) => {
  if (column.hideOnGrid) return false
  if (isBlank(column.title)) return false
  return true
}

export const cellColumnValue = (column, dataItem, field, trueField) => {
  let newValue = column ? dataItem[column.description] : dataItem[field]
  if (newValue && typeof newValue === 'object') {
    const searchField = trueField || field
    newValue = byString(dataItem, searchField)
  }
  return newValue
}

export const foreignColumns = (columns) =>
  columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])
