import { useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { ADMIN_ONLY_PAGES, ADMIN_LIKE_ROLES } from '/src/utils/constants/admin'

export default function SettingsEmbedPageAuthorized({ children, page }) {
  const [userRole] = useStore('user_role')
  const history = useHistory()

  const isNonAuthorized =
    userRole && !ADMIN_LIKE_ROLES.includes(userRole[0].name) && ADMIN_ONLY_PAGES.includes(I18n.t(`shortcuts.${page}`))

  useEffect(() => {
    if (isNonAuthorized) {
      history.push('/home')
    }
  }, [history, isNonAuthorized])

  if (children) return children

  return null
}

SettingsEmbedPageAuthorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  page: PropTypes.string
}

SettingsEmbedPageAuthorized.defaultProps = {
  children: undefined,
  page: undefined
}
