import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'

export default function DropFormulaResultColumn({ value }) {
  const [globalProject] = useStore('project')

  if (typeof value === 'number') {
    value = formatNumberWithPrecision(value, globalProject)
  }

  return value
}

DropFormulaResultColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DropFormulaResultColumn.defaultProps = {
  value: null
}
