import { useCallback } from 'react'
import usePreferences from '/src/ui/core/grid/preferences'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import I18n from '/src/utils/translations'
import { ERROR } from '/src/utils/constants/chart_colors'

export default function useClearTemplatePreferences(templateId, onBeforeReload) {
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const { clearTemplatePreferences } = usePreferences()

  const onConfirmation = useCallback(() => {
    clearTemplatePreferences(templateId, onBeforeReload)
  }, [clearTemplatePreferences, templateId, onBeforeReload])

  const cleanPreferencesClick = useCallback(() => {
    const confirmationParams = {
      title: I18n.t('clear_template_preferences.title'),
      description: I18n.t('clear_template_preferences.description'),
      actionButtonText: I18n.t('clear_template_preferences.ok'),
      cancelButtonText: I18n.t('clear_template_preferences.cancel'),
      buttonColor: ERROR,
      onSuccess: () => onConfirmation
    }
    showConfirmation(confirmationParams)
  }, [onConfirmation, showConfirmation])

  return [cleanPreferencesClick, renderConfirmation]
}
