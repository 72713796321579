import React from 'react'
import { useStore } from 'react-context-hook'
import { MdPendingActions } from 'react-icons/md'
import { isRecyclingRequest } from '/src/models/request'
import { status } from '/src/utils/constants/request'
import { normalizeDateToString } from '/src/utils/project_formatter'
import I18n from '/src/utils/translations'

export default function useRemoveRecycleMenuItem(showActionConfirmation, confirmationButtonColor) {
  const [user] = useStore('user')

  const removeRecycleRequest = (request) => ({
    resourcePath: 'recycles',
    requestAction: 'CREATE',
    httpAction: 'post',
    additionalResource: { path: 'done' },
    data: {
      date: normalizeDateToString(new Date()),
      responsible_id: user && user.employee_id,
      request_id: request && request.id
    }
  })

  const modalParams = ({ reason, discipline } = {}) => ({
    actionName: 'remove_recycle',
    actionText: I18n.t('requests.remove_recycle_modal.confirm_description'),
    cancelText: I18n.t('requests.remove_recycle_modal.cancel_description'),
    buttonColor: confirmationButtonColor,
    customSuccessMessage: I18n.t('requests.remove_recycle_modal.success_notification', {
      reason,
      discipline
    })
  })

  return {
    text: I18n.t('actions.remove_recycle'),
    icon: <MdPendingActions />,
    onClick: (e, dataItem) => showActionConfirmation(dataItem, removeRecycleRequest(dataItem), modalParams(dataItem)),
    visible: (dataItem) => isRecyclingRequest(dataItem, status)
  }
}
