import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ValueSelector from '/src/ui/core/inputs/value_selector'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useUpdateEffect from '/src/hooks/update_effect'
import usePollingAPI, { pollingStatus } from '/src/hooks/api/polling_api'
import { notifyError } from '/src/ui/core/dialogs/notifications'

// eslint-disable-next-line max-lines-per-function
export default function ApplyPackage({ request, template }) {
  const [inputStatus, setInputStatus] = useState('')
  const { responseData, fetchAPI, status } = useFetchAPI('estimate_services')
  const polling = usePollingAPI()

  const startPolling = () => {
    const backgroundJobId = responseData.id
    polling.startPolling(backgroundJobId)
  }

  const apply = (pkg) => {
    const params = {
      requestAction: 'CREATE',
      httpAction: 'post',
      additionalResource: { path: 'batch_unpacker' },
      data: {
        request_id: request.id,
        template_id: template.value.id,
        package_id: pkg
      }
    }
    fetchAPI(params)
    setInputStatus('waiting')
  }

  useEffect(() => {
    if (polling.fetchStatus !== 'SUCCESS') return

    if (polling.status === pollingStatus.ERROR) {
      setInputStatus('')
      notifyError(I18n.t('notification.error_job'))
    }
    if (polling.status === pollingStatus.SUCCESS) setInputStatus('done')
  }, [polling.fetchStatus, polling.status])

  useUpdateEffect(() => {
    if (status === 'SUCCESS') startPolling()
  }, [status])

  return (
    <ValueSelector
      key={template.value.id}
      id={`${template.value.id}`}
      labelLeft={I18n.t('requests.eav_template')}
      labelRight={I18n.t('estimate_services.service_package')}
      placeholder={I18n.t('form.inputs.value_selector.search')}
      categoryName={template.value.title}
      route="service_packages"
      query={{ where: { discipline_id: template.value.discipline_id } }}
      searchFields={['description']}
      textDisplayFields={['description']}
      onApply={(pkg) => apply(pkg, template.value.id)}
      state={inputStatus}
    />
  )
}

ApplyPackage.propTypes = {
  request: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  template: PropTypes.oneOfType([PropTypes.object])
}

ApplyPackage.defaultProps = {
  request: {},
  template: {}
}
