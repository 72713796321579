import React from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Layout from '/src/ui/core/layouts/layout'
import LanguageSwitch from '/src/ui/core/menus/language_switch'
import ShortcutBlocksList from '/src/ui/core/blocks/shortcut_blocks_list'
import shortcuts from '/src/ui/domain/settings/shortcuts'
import { ADMIN_ONLY_PAGES, ADMIN_LIKE_ROLES } from '/src/utils/constants/admin'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/icons/popup_icon.css'

export default function SettingsPage({ user }) {
  const [userRole] = useStore('user_role')

  const filteredShortcuts = () => {
    if (!userRole) return []

    const unfilteredShortcuts = shortcuts(user.id)
    if (ADMIN_LIKE_ROLES.includes(userRole[0].name)) return unfilteredShortcuts

    return unfilteredShortcuts.filter((shortcut) => {
      return !ADMIN_ONLY_PAGES.includes(shortcut.title)
    })
  }

  return (
    <Layout key="settings" showProjectSwitcher={false}>
      <div className="center-container-header">
        <div className="center-container-title">{I18n.t('settings_shortcuts')}</div>
        <LanguageSwitch />
      </div>
      <ShortcutBlocksList links={filteredShortcuts()} />
    </Layout>
  )
}

SettingsPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
}
