import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ProgressServicePackageableModel from '/src/models/progress_service_packageable'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import useFetchSections from '/src/hooks/api/fetch_sections'
import I18n from '/src/utils/translations'

export default function ProgressServicePackageableForm({ dataItem }) {
  const { eav_template_id: templateId, discipline_id: disciplineId, progress_id: progressId } = dataItem

  const query = { query: { where: { discipline_id: disciplineId } } }
  const sections = useFetchSections(templateId)
  const progressServicePackageableModel = new ProgressServicePackageableModel(query)

  const type = 'new'

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])

  return (
    <FormWrapper
      model={progressServicePackageableModel}
      type={type}
      backText={I18n.t('progress_services.back')}
      sidePanelSections={sectionsWithGeneral}
    >
      <React.Fragment>
        <Form
          model={progressServicePackageableModel}
          sections={sections}
          includeOnForm={{
            progress_id: progressId,
            eav_template_id: templateId,
            discipline_id: disciplineId
          }}
          templateId={templateId}
          type={type}
        />
        <FormButtons model={progressServicePackageableModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressServicePackageableForm.propTypes = {
  dataItem: PropTypes.shape({
    progress_id: PropTypes.number.isRequired,
    eav_template_id: PropTypes.number.isRequired,
    discipline_id: PropTypes.number.isRequired
  })
}

ProgressServicePackageableForm.defaultProps = {
  dataItem: undefined
}
