import { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'
import useFetch from '/src/hooks/api/fetch'

export default function usePaginatedFetch(path, onFetchData) {
  const { fetch } = useFetch()
  const [dataFetched, setDataFetched] = useState([])

  const routeParams = useRef()
  const pagesLeft = useRef()
  const skip = useRef(0)

  const [isPaginating, setIsPaginating] = useState(false)

  const calculateSkip = useCallback((onPaginate) => {
    if (pagesLeft.current > 0) {
      skip.current += MAX_PAGE_SIZE
      if (skip.current > 0) onPaginate()
    } else {
      skip.current = 0
      setIsPaginating(false)
    }
  }, [])

  const calculatePagesLeft = useCallback(
    (totalItems, onPaginate) => {
      if (skip.current > 0) {
        pagesLeft.current -= 1
        calculateSkip(onPaginate)
        return
      }

      const totalPages = Math.ceil(totalItems / MAX_PAGE_SIZE)
      const newPagesLeft = totalPages - 1
      pagesLeft.current = newPagesLeft
      calculateSkip(onPaginate)
    },
    [calculateSkip]
  )

  const fetchPaginated = useCallback(
    (routeParamsToSend, onPaginate) => {
      const paging =
        routeParamsToSend && routeParamsToSend.paging
          ? {
              ...routeParamsToSend.paging
            }
          : {}
      paging.skip = skip.current
      paging.pageSize = MAX_PAGE_SIZE

      const requestParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { ...routeParamsToSend, paging }
      }

      fetch(path, requestParams, {
        onSuccess: (responseData) => {
          const { data, total } = responseData.data

          const hasData = data.length > 0

          setDataFetched((prevData) => {
            const nextData = [...prevData, ...data]
            if (onFetchData) return onFetchData(nextData)
            return nextData
          })

          if (hasData) calculatePagesLeft(total, onPaginate)
          else setIsPaginating(false)
        }
      })
    },
    [fetch, path, onFetchData, calculatePagesLeft]
  )

  const callAPI = useCallback(
    (forceNewFetch, queryParams) => {
      if (!path) return

      if (forceNewFetch) {
        routeParams.current = queryParams
        skip.current = 0
        setDataFetched([])
      }

      setIsPaginating(true)

      fetchPaginated(queryParams || routeParams.current, callAPI)
    },
    [path, fetchPaginated]
  )

  return [dataFetched, callAPI, isPaginating]
}

usePaginatedFetch.propTypes = {
  path: PropTypes.string,
  onFetchData: PropTypes.func
}

usePaginatedFetch.defaultProps = {
  path: undefined,
  onFetchData: (data) => data
}
