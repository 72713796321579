import React from 'react'
import PropTypes from 'prop-types'
import { TimePicker } from '@progress/kendo-react-dateinputs'
import { getFormatLocaleTimeToTime } from '/src/utils/project_formatter'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/core/inputs/input_time.css'

const formatValueToKendo = (value) => {
  const dateTime = new Date()
  dateTime.setHours(...value.split(':'))
  return dateTime
}

export default function InputTime({ inputProps }) {
  const { id, className, title, required, value, readOnly, onChange } = inputProps

  const valueFormatter = (newValue) => (newValue ? newValue.toLocaleTimeString('en-GB').toString() : '')
  const inputChangeProps = { id, value, onChange, valueFormatter }
  const [inputValue, onInputChange] = useInputChange(inputChangeProps)
  const error = useInputError({ inputValue, title, required })

  const inputClass = () => {
    const classes = ['form-input-time']
    if (!inputValue) classes.push('invisible-placeholder')
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <React.Fragment>
      <TimePicker
        id={id}
        className={inputClass()}
        popupSettings={{ popupClass: 'form-input-time-popup' }}
        onChange={onInputChange}
        value={inputValue ? formatValueToKendo(inputValue) : null}
        formatPlaceholder="formatPattern"
        format={getFormatLocaleTimeToTime()}
        disabled={readOnly}
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputTime.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool
  }).isRequired
}
