import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdFunctions } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { formulaCategory } from '/src/models/concerns/formula_categories'
import InputSearch from '/src/ui/core/inputs/input_search'
import FieldFactory from '/src/ui/core/fields/field_factory'
import useFormulaModal from '/src/ui/core/popups/formula_modal'
import I18n from '/src/utils/translations'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/core/inputs/input_drop_formula.css'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'

/**
 *  It will create a drop_formula input responsible to select
 *  a drop_formula_id and open the formula modal with that formula
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
// eslint-disable-next-line max-lines-per-function
export default function InputControlledDropFormula({ inputProps }) {
  const {
    id,
    onChange,
    dataItem,
    column,
    formData,
    modalSimpleTitle,
    title,
    type,
    readOnly,
    closeModalButtonText,
    appendModalToBody,
    applyOverlayToModal,
    required
  } = inputProps

  const idKey = `${column.description}_id`
  const variablesKey = `${column.description}_variables`
  const resultKey = column.description
  const resultTitle = I18n.t('form.inputs.errors.drop_formula_result', { field: column.title })

  const get = (object, key, defaultValue) => {
    return object && !isBlankOrFalse(object[key]) ? object[key] : defaultValue
  }

  const [formula, setFormula] = useState(dataItem ? column.formulas[dataItem[idKey]] : {})
  const [variables, setVariables] = useState(get(dataItem, variablesKey, {}))
  const [result, setResult] = useState(get(dataItem, resultKey, ''))
  const error = useInputError({ inputValue: get(formula, 'id', 0), title, required, type })
  const resultError = useInputError({ inputValue: result, title: resultTitle, required, type: 'drop_formula_result' })

  const location = useLocation()

  const resultClass = () => {
    if (!error && resultError) return 'drop-formula-result input-error'
    return 'drop-formula-result'
  }

  const useFormulaModalProps = {
    closeButtonText: closeModalButtonText || I18n.t('history_popup.back'),
    appendToBody: appendModalToBody !== false,
    applyOverlay: applyOverlayToModal || false,
    simpleTitle: modalSimpleTitle,
    columnDescription: column.description
  }

  const [showFormulaModal, renderFormulaModal] = useFormulaModal(useFormulaModalProps)

  const searchQuery = () => {
    const query = {
      where: {
        eav_template_id: column.eav_template_id,
        category: formulaCategory.drop
      }
    }

    if (column.metadata) query.where.id = JSON.parse(column.metadata)

    return query
  }

  const updateVariables = (formulaParam) => {
    const normalFields = formulaParam.fields.map((f) => f.description).filter((d) => !d.includes('.'))

    const newVariables = Object.fromEntries(Object.entries(variables).filter(([key]) => normalFields.includes(key)))

    setVariables(newVariables)
  }

  const onFormulaChange = (_, idParam) => {
    const { formulas } = column
    let newResult = result

    if (formulas[idParam].id !== (dataItem && dataItem[idKey])) newResult = ''
    else newResult = get(dataItem, resultKey, '')
    updateVariables(formulas[idParam])
    setFormula(formulas[idParam])
    setResult(newResult)
    onChange(idKey, formulas[idParam].id)
    onChange(resultKey, newResult)
  }

  const searchProps = {
    id,
    onChange: onFormulaChange,
    searchRoute: 'formulas',
    searchExtraQuery: searchQuery(),
    searchFields: ['description'],
    textDisplayFields: ['description'],
    placeholder: (() => {
      return I18n.t('form.inputs.drop_formula.formula_select')
    })(),
    value: get(formula, 'id', undefined),
    title,
    type,
    required,
    readOnly
  }

  const onSave = ({ id: idParam, variables: variablesParam, result: resultParam }) => {
    setFormula(column.formulas[idParam])
    setVariables(variablesParam)
    setResult(resultParam)

    const historyFound = location.pathname.match(/\/history/)
    let dropFormulaVariables

    if (historyFound) {
      dropFormulaVariables = variablesParam
    } else {
      dropFormulaVariables = Object.fromEntries(
        Object.entries(variablesParam).filter(([variableName]) => !variableName.includes('.'))
      )
    }

    onChange(idKey, idParam)
    onChange(variablesKey, dropFormulaVariables)
    onChange(resultKey, resultParam)
  }

  const showFormulaOnClick = () => {
    const formulaParams = {
      item: { ...dataItem, ...formData },
      formula,
      variables,
      onSave: () => onSave
    }

    showFormulaModal(formulaParams)
  }

  return (
    <React.Fragment>
      <div className="drop-formula-field">
        {renderFormulaModal()}
        <InputSearch inputProps={searchProps} />
        <button type="button" className="epsilon-button" onClick={() => showFormulaOnClick()} disabled={readOnly}>
          <div className="epsilon">
            <MdFunctions />
          </div>
        </button>
        <div id="drop-formula-result" className={resultClass()}>
          <FieldFactory value={result} type="decimal" />
        </div>
      </div>
      <div className="error-label">{error || resultError}</div>
    </React.Fragment>
  )
}

InputControlledDropFormula.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
