import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Header from '/src/ui/core/menus/header'
import CenterContainer from '/src/ui/core/layouts/center_container'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import '/src/static/css/layout.css'

export default function Page({ children, showProjectSwitcher }) {
  const [isLoading, setIsLoading] = useState(false)

  useBus(
    BusEvents.RELOAD_PAGE,
    ({ payload }) => {
      setIsLoading(payload)
    },
    []
  )

  return (
    <div className={classNames({ 'vertical-wrapper': true })}>
      <Header showProjectSwitcher={showProjectSwitcher} />
      <CenterContainer>
        <React.Fragment>{!isLoading ? children : <ThreeDotsLoader />}</React.Fragment>
      </CenterContainer>
    </div>
  )
}

Page.propTypes = {
  showProjectSwitcher: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.element)]).isRequired
}

Page.defaultProps = {
  showProjectSwitcher: true
}
