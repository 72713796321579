import React from 'react'
import { MdRequestQuote } from 'react-icons/md'
import I18n from '/src/utils/translations'
import { filterFlags, RECYCLING_STATUS } from '/src/utils/constants/request'
import { estimatePrice } from '/src/models/estimate'
import { getDaysCounter } from '/src/utils/project_formatter'

export const formatRecycling = (fetchedItem) => {
  const filterFlag = filterFlags.find((flag) => flag.value === RECYCLING_STATUS)
  return {
    responsibleId: fetchedItem.responsible_id,
    statusDate: fetchedItem.created_at,
    daysAgo: getDaysCounter(fetchedItem.created_at),
    comments: fetchedItem.comments,
    title: I18n.t(`timeline.recycle_${fetchedItem.status}`),
    statusIcon: filterFlag.icon
  }
}

export const formatDisciplineCheck = (fetchedItem) => {
  const filterFlag = filterFlags.find((flag) => flag.value === fetchedItem.status)
  return {
    responsibleId: fetchedItem.responsible_id,
    statusDate: fetchedItem.created_at,
    daysAgo: getDaysCounter(fetchedItem.created_at),
    comments: fetchedItem.comments,
    title: I18n.t(`timeline.${filterFlag.i18n_id}`),
    statusIcon: filterFlag.icon
  }
}

export const formatOrders = (fetchedItem) => {
  if (!fetchedItem) return null
  return {
    responsibleId: fetchedItem.responsible_id,
    comments: fetchedItem.comments,
    statusDate: fetchedItem.order_date
  }
}

export const formatEstimates = (fetchedItem, requestOrder, project, estimateStatuses) => {
  const estimateStatus = estimateStatuses[fetchedItem.estimate_status_id].i18n_id
  if (requestOrder) {
    return {
      responsibleId: requestOrder.responsibleId,
      statusDate: fetchedItem.updated_at,
      daysAgo: getDaysCounter(fetchedItem.updated_at),
      title: I18n.t(`timeline.estimate_${estimateStatus}`),
      comments: requestOrder.comments,
      statusIcon: <MdRequestQuote />,
      estimatedCost: estimatePrice(fetchedItem, project)
    }
  } else {
    return {
      responsibleId: fetchedItem.responsible_id,
      title: I18n.t(`timeline.estimate_${estimateStatus}`),
      comments: fetchedItem.comments,
      statusIcon: <MdRequestQuote />
    }
  }
}
