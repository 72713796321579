import PropTypes from 'prop-types'
import inspectedFormatter from '/src/ui/domain/inspections/inspected_formatter'

export default function InspectedColumn({ inspected, type, services }) {
  return inspectedFormatter(inspected, type, services)
}

InspectedColumn.propTypes = {
  inspected: PropTypes.shape({
    number: PropTypes.number,
    eav_template: PropTypes.shape({
      title: PropTypes.string
    })
  }),
  type: PropTypes.string.isRequired
}

InspectedColumn.defaultProps = { inspected: {} }
