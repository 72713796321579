export function getDownloadSpeed() {
  try {
    return navigator.connection.downlink
  } catch (error) {
    return 10
  }
}

export function averageDownloadSpeed() {
  const avgTime = Math.ceil((1 / getDownloadSpeed()) * 2000)
  return Math.min(Math.max(avgTime, 900), 3000)
}
