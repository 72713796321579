/* eslint-disable max-lines-per-function */
import { useMemo } from 'react'
import I18n from '/src/utils/translations'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useRequestItemDataProvider from '/src/ui/domain/requests/request_item_data_provider'

/**
 * This custom hook implements a way of creating sections for recycle form
 * @return [dataItem, setDataItem, sections]
 */
export default function useRecycleRequestPopupFields() {
  const [dataItem, setDataItem, defaultResponsible, onChange] = useRequestItemDataProvider()

  const sections = useMemo(() => {
    return [
      {
        id: 1,
        eav_columns: [
          {
            id: 1,
            description: 'date',
            title: I18n.t('orders.date'),
            onChange,
            type: 'date',
            default: 'current_date',
            required: true
          },
          {
            id: 2,
            description: 'responsible_id',
            title: I18n.t('orders.responsible'),
            foreignKey: 'employees',
            foreignAttribute: 'responsible_id',
            searchFields: ['name', 'last_name'],
            searchRoute: 'employees',
            onChange,
            textDisplayFields: ['full_name'],
            textField: 'full_name',
            type: 'search',
            default: defaultResponsible(),
            required: true
          },
          // {
          //   id: 3,
          //   description: 'recycle_recipient',
          //   title: I18n.t('orders.recipient'),
          //   onChange,
          //   type: 'string',
          // },
          {
            id: 4,
            description: 'comments',
            title: I18n.t('orders.comments'),
            onChange,
            type: 'multiline_text',
            maxLength: 255,
            required: true
          }
        ]
      }
    ]
  }, [defaultResponsible, onChange])

  return [dataItem, setDataItem, sections]
}
