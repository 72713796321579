import { useLocation } from 'react-router-dom'
import useRequestsPinned from '/src/hooks/requests_pinned'
import EstimateServiceModel from '/src/models/estimate_service'
import ScopeModel from '/src/models/scope'
import ProgressModel from '/src/models/progress'
import { isEmpty } from '/src/utils/object'

const { url: estimateServiceRoute } = new EstimateServiceModel()
const { url: scopeRoute } = new ScopeModel()
const { url: progressRoute } = new ProgressModel()

export default function useRequestFilterByDiscipline() {
  const { selectedRequests } = useRequestsPinned()
  const { pathname } = useLocation()
  const filterableRoutes = [estimateServiceRoute, scopeRoute, progressRoute].map((route) => `/${route}`)

  return filterableRoutes.includes(pathname) && selectedRequests && !isEmpty(selectedRequests)
}
