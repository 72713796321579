import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import '/src/static/css/layout.css'

export default function TopMenuItem({ item }) {
  return (
    <div className="header-menu-item">
      <NavLink to={`/${item.name}`}>{item.title}</NavLink>
    </div>
  )
}

TopMenuItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.string).isRequired
}
