import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MaskedTextBox } from '@progress/kendo-react-inputs'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/inputs.css'

export default function InputMaskedTextBox({ inputProps }) {
  const {
    id,
    className,
    name,
    placeholder,
    value,
    title,
    mask,
    required,
    onChange,
    onBlur,
    onFocus,
    readOnly,
    valueFormatter,
    defaultValueFormatter,
    appendButton
  } = inputProps

  const inputChangeProps = {
    id,
    value: parseFloat(value),
    onChange,
    valueFormatter
  }
  const [inputValue, onInputChange] = useInputChange(inputChangeProps)
  const error = useInputError({ inputValue, title, required })

  const inputClass = () => {
    const classes = ['form-input-maskedtextbox']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <React.Fragment>
      <div className="masked-text-box-wrapper">
        <MaskedTextBox
          mask={mask}
          id={id}
          className={inputClass()}
          name={name}
          defaultValue={defaultValueFormatter ? defaultValueFormatter(value) : mask}
          placeholder={placeholder}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={readOnly}
        />
        {appendButton}
        <div className="error-label">{error}</div>
      </div>
    </React.Fragment>
  )
}

InputMaskedTextBox.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numberOfDigits: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool
  }).isRequired
}
