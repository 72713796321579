import { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'

/**
 *  This custom hook will just fetch a entity by its id.
 *
 * @param entityName - entity name
 * @param id - id to search with
 * @return {*}
 */
export default function useFetchById(entityName, id) {
  const { status, responseData, fetchAPI } = useFetchAPI(entityName)
  const [entity, setEntity] = useState()

  useEffect(() => {
    if (!id || !entityName) {
      setEntity(undefined)
      return
    }
    const requestQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: parseInt(id, 10) } }
    }

    fetchAPI(requestQueryParams)
  }, [id, entityName])

  useEffect(() => {
    if (status !== 'SUCCESS') return
    setEntity(responseData.data['0'])
  }, [status, responseData])

  return entity
}
