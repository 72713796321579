import React from 'react'
import PropTypes from 'prop-types'
import ProductivityFactorColumn from '/src/ui/core/grid/productivity_factor_column'
import { calculateProgressPF } from '/src/utils/productivity_factor'

export default function ProductivityColumn({ data }) {
  return <ProductivityFactorColumn value={calculateProgressPF(data)} />
}

ProductivityColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

ProductivityColumn.defaultProps = {
  data: {}
}
