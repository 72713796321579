import I18n from '/src/utils/translations'
import { ALLOWED_SAVE_NEW_MODELS, ALLOWED_FORM_TYPES_SAVE_NEW } from '/src/models/concerns/form'

export const FIXED_FORMS = ['scaffolding_part', 'scaffolding_part_action']

export const isFixedForm = (modelName) => FIXED_FORMS.includes(modelName)

// eslint-disable-next-line import/prefer-default-export
export const shouldShowForm = (formType, dataItem) => {
  switch (formType) {
    case 'edit':
    case 'duplicate':
      return dataItem !== null && dataItem !== undefined
    default:
      return true
  }
}

export const shouldShowSaveAndNewButton = (modelName, type) =>
  ALLOWED_SAVE_NEW_MODELS.includes(modelName) && ALLOWED_FORM_TYPES_SAVE_NEW.includes(type)

export const duplicationCustomActionBasic = {
  requestAction: 'CREATE',
  httpAction: 'post'
}

export const duplicationCustomAction = (dataItem) => ({
  ...duplicationCustomActionBasic,
  resourceId: dataItem ? dataItem.id : {},
  additionalResource: { path: 'duplicate_hierarchy' }
})

export const duplicationCustomError = (errors, partialSuccess) => {
  if (!Array.isArray(errors)) return undefined
  const messages = errors.join().match(`${partialSuccess}\n(.*)`)
  const requestError = messages ? messages[1] : null
  if (requestError === '') {
    const causingError = errors.join().match(`(.*\n.*)\n{2}${partialSuccess}`)[1]
    return {
      title: I18n.t(`notification.error`),
      body: causingError,
      status: 'error',
      closable: true,
      closeTimeout: 10
    }
  }
  return undefined
}

export const storeInitialVisibleOnWeb = (column) => {
  return {
    ...column,
    initialVisibleOnWeb: column.initialVisibleOnWeb === undefined ? column.visible_on_web : column.initialVisibleOnWeb
  }
}
