import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdAddComment, MdComment } from 'react-icons/md'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import ColumnCommentEditModal from '/src/ui/core/popups/column_comment_edit_modal'
import '/src/static/css/core/icons/column_comment_icon.css'

export default function ColumnCommentIcon({ title, description, flexibleColumnsControls }) {
  const { setValue, getValues } = flexibleColumnsControls

  const [comment, setComment] = useState(getValues('flexible_comments')?.[description])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const handleChangeComment = (newComment) => {
    const currentComments = getValues('flexible_comments')
    setComment(newComment)
    setValue('flexible_comments', { ...currentComments, [description]: newComment })
  }

  return (
    <div className='column-comment'>
      {isEditModalOpen && (
        <ColumnCommentEditModal
          title={title}
          comment={comment}
          description={description}
          handleChangeComment={handleChangeComment}
          closeModal={() => setIsEditModalOpen(false)}
        />
      )}
      <CustomTooltip position="top">
        {comment ? (
          <div
            title={comment}
            data-testid='show-comment-icon'
            className='column-comment__icon-wrapper'
            onClick={() => setIsEditModalOpen(true)}
          >
            <MdComment />
          </div>
        ) : (
          <div
            data-testid='add-comment-icon'
            className='column-comment__icon-wrapper'
            title={I18n.t('dialogs.column_comment.add_comment')}
            onClick={() => setIsEditModalOpen(true)}
          >
            <MdAddComment />
          </div>
        )}
      </CustomTooltip>
    </div>
  )
}

ColumnCommentIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  flexibleColumnsControls: PropTypes.shape({
    setValue: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
}
