import React, { Fragment, useState } from 'react'
import { PropTypes } from 'prop-types'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import '/src/static/css/core/popups/column_comment_edit_modal.css'

export default function ColumnCommentEditModal({ title, comment, closeModal, handleChangeComment }) {
  const [inputComment, setInputComment] = useState(comment)

  const saveComment = () => {
    handleChangeComment(inputComment)
    closeModal()
  }

  const footer = (
    <Fragment>
      <button className="column-comment-modal__cancel-button" type="button" onClick={closeModal}>
        {I18n.t('dialogs.column_comment.cancel')}
      </button>
      <button className="column-comment-modal__comment-button" type="button" onClick={saveComment}>
        {I18n.t('dialogs.column_comment.comment')}
      </button>
    </Fragment>
  )

  return (
    <div>
      <Modal
        closable
        width={28}
        height={45}
        appendToBody
        title={title}
        footer={footer}
        onClose={closeModal}
        className='column-comment-modal'
      >
        <div className='column-comment-modal__body'>
          {I18n.t('dialogs.column_comment.subtitle')}
          <div className="column-comment-modal__comment-area">
            <textarea
              value={inputComment}
              onChange={(e) => setInputComment(e.target.value)}
              placeholder={I18n.t('form.inputs.picture.comment_placeholder')}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

ColumnCommentEditModal.propTypes = {
  title: PropTypes.string.isRequired,
  comment: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  handleChangeComment: PropTypes.func.isRequired
}

ColumnCommentEditModal.defaultProps = {
  comment: undefined
}
