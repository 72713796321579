import getPictureColumnImages from '/src/models/concerns/picture'
import { getAttachmentFiles } from '/src/models/concerns/attachment'

export default function useEavColumnsFields(eavColumns, dataItem) {
  const getFlexibleFields = () => {
    const fieldsObj = {}
    eavColumns.forEach((column) => {
      if (!dataItem) return
      const newColumn = column
      newColumn.value = dataItem[column.description]

      // TODO The column.type refers to fixed columns used on Scaffolding model.
      // On a US leading with scaffolding side panel, please refactor its fields
      // to not use this hook
      newColumn.type = column.type || column.column_type.description
      if (column.column_type && column.column_type.description === 'picture') {
        const pictureObjects = column.value || []
        newColumn.value = getPictureColumnImages(dataItem, pictureObjects)
      }
      if (column.column_type && column.column_type.description === 'attachment') {
        const attachmentObjects = column.value ? column.value : []
        newColumn.value = getAttachmentFiles(dataItem, attachmentObjects)
      }

      fieldsObj[column.description] = newColumn
    })

    return fieldsObj
  }

  return getFlexibleFields()
}
