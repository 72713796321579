import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import useFetch from '/src/hooks/api/fetch'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import useSyncBatch from '/src/hooks/api/sync_batch'
import { estimateIsReadOnly, getEstimateByRequest } from '/src/models/estimate'
import { getEstimateServicesByEstimate } from '/src/models/estimate_service'
import EstimateFormCheckout from '/src/ui/domain/estimates/estimate_form_checkout'
import EstimateFormState from '/src/ui/domain/estimates/estimate_form_state'
import EstimateFormDetails from '/src/ui/domain/estimates/estimate_form_details'
import { isEmpty } from '/src/utils/object'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateForm({ dataItem }) {
  const { fetch } = useFetch()
  const [estimates, setEstimates] = useState()
  const [estimatesLog, setEstimatesLog] = useState({})
  const [estimateServices, setEstimateServices] = useState()
  const [estimateStatuses, setEstimateStatuses] = useState({})
  const [employees, setEmployees] = useState({})

  useBus(BusEvents.POLLING_DATA_SUBMITTED, ({ payload }) => setEstimatesLog(payload), [])

  const requestIds = useMemo(() => Object.values(dataItem).map((item) => item.id), [dataItem])

  const allEstimatesAreReadyOnly = () => {
    return estimates.every(
      (estimate) => estimate && !isEmpty(estimate) && estimateIsReadOnly(estimate, estimateStatuses)
    )
  }

  useBus(
    BusEvents.FORM_DISCARD_CHANGES_CONFIRM,
    () => {
      if (!allEstimatesAreReadyOnly()) return

      dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
    },
    [estimates, estimateStatuses]
  )

  useEffect(() => {
    const estimateQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          request_id: requestIds
        }
      }
    }

    fetch('estimates', estimateQueryParams, {
      onSuccess: ({ data: { data } }) => {
        setEstimates(data || [])
      }
    })
  }, [dataItem, fetch, requestIds, estimatesLog])

  useEffect(() => {
    if (!estimates) return
    const employeeQueryParams = { requestAction: 'READ', httpAction: 'get' }

    fetch('employees', employeeQueryParams, {
      onSuccess: ({ data: { data } }) => {
        const hashData = data.reduce((acc, employee) => {
          acc[employee.id] = employee
          return acc
        }, {})
        setEmployees(hashData)
      }
    })
  }, [estimates, fetch])

  const requestIdWhere = { request_id: requestIds }
  const queryService = { where: { estimates: requestIdWhere } }
  const batchedEntities = {
    estimate_services: {
      get: estimateServices,
      set: setEstimateServices,
      query: queryService
    },
    estimate_statuses: { get: estimateStatuses, set: setEstimateStatuses }
  }

  useSyncBatch(batchedEntities)

  const estimateByRequestId = useMemo(() => {
    if (!estimates) return {}
    return getEstimateByRequest(estimates)
  }, [estimates])

  const estimateServicesByEstimate = useMemo(() => {
    if (!estimateServices) return {}
    return getEstimateServicesByEstimate(Object.values(estimateServices))
  }, [estimateServices])

  return estimates && estimateServices ? (
    <div className="side-panel-wrapper">
      <div style={{ width: '60%' }}>
        <EstimateFormDetails
          requests={dataItem}
          estimates={estimateByRequestId}
          estimateServices={estimateServicesByEstimate}
          statuses={estimateStatuses}
          employees={employees}
        />
      </div>
      <div className="estimate-form-state" style={{ width: '40%' }}>
        <div className="estimate-label">{I18n.t('collections.estimate')}</div>
        <EstimateFormState estimates={estimates} statuses={estimateStatuses}>
          <EstimateFormCheckout estimates={estimates} statuses={estimateStatuses} employees={employees} />
        </EstimateFormState>
      </div>
    </div>
  ) : (
    <ThreeDotsLoader />
  )
}

EstimateForm.propTypes = {
  dataItem: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  ])
}

EstimateForm.defaultProps = {
  dataItem: []
}
