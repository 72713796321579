import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/simple_grid_pdf_export.css'

export default function GridPDFPage({ model, numItems, pageNum, totalPages }) {
  const title = I18n.t('dialogs.configure_print_grid_file.pdf_file.title', {
    modelName: model.name,
    numItems,
    pageNum,
    totalPages
  })

  return (
    <div className="grid-pdf-file">
      <img className="dpms-logo-full" src="/static/svg/logo-red.svg" alt="" />
      <div className="grid-pdf-title">{title}</div>
    </div>
  )
}

GridPDFPage.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  numItems: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}
