import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'
import { ReactComponent as EditNote } from '/public/static/svg/edit-note'

export default function BulkEditingIcon({ onClick }) {
  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="bulk-editing"
        type="button"
        title={I18n.t('actions.bulk_editing')}
        data-testid="bulk-editing"
        className="btn-icon grid-header-icon"
        onClick={onClick}
      >
        <EditNote />
      </button>
    </CustomTooltip>
  )
}

BulkEditingIcon.propTypes = {
  onClick: PropTypes.func
}

BulkEditingIcon.defaultProps = {
  onClick: () => {}
}
