/* eslint-disable import/prefer-default-export */
import {
  filterVisibleOnWeb as filterColumn,
  bypassVisibleOnWeb,
  datasheetFilterVisibleOnWeb,
  sortColumnsByPosition
} from '/src/models/concerns/eav_column'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'

export const filterVisibleOnWeb = (sections = []) => {
  return sections.map((section) => {
    const newSection = section
    newSection.eav_columns = section.eav_columns ? filterColumn(section.eav_columns) : []
    return newSection
  })
}

export const isSectionVisible = (columns) => {
  const visibleColumns = columns.filter((column) => {
    if (column.type === 'datasheet_filter') return datasheetFilterVisibleOnWeb(column.filters)
    if (bypassVisibleOnWeb(column)) return false
    return column.required || column.visible_on_web
  })

  return visibleColumns.length > 0
}

const columnsNotExpandable = (columns) =>
  columns.map((column) => ({
    ...column,
    dontExpand: true
  }))

export const sectionsColumnsNotExpandable = (sections) => {
  return sections.map((section) => {
    if (section.eav_columns) return { ...section, eav_columns: columnsNotExpandable(section.eav_columns) }
    if (section.columns) return { ...section, columns: columnsNotExpandable(section.columns) }
  })
}

export const getSectionTitle = (sections, sectionKey) => {
  if (!sections || !sectionKey) return undefined
  const section = sections.find((item) => item.id == sectionKey)
  if (!section) return undefined
  else return section.title
}

/**
 * This will get all columns of a template, grouped in sections, then will regroup them all together,
 * disregarding their sections, and then will finally return the name of the first flexible column.
 * This is usually used for comments, descriptions or any similar information.
 * @param {Array} sections - This is usually the return of the API's route "/eav_sections/eav_columns".
 * @returns {string} The name of the first flexible column, to be used as description of comments.
 */
export const getDescriptionField = (sections) => {
  if (!sections || !sections.length) return null

  let unsectionedEavColumns = []
  sections.forEach((section) => {
    unsectionedEavColumns = unsectionedEavColumns.concat(section?.eav_columns ?? [])
  })
  const firstColumn = getFirstStringOrFormula(sortColumnsByPosition(unsectionedEavColumns))
  return firstColumn?.description
}
