const kendoGridReducer = (currentData, action) => {
  switch (action.type) {
    case 'READ':
      return action.responseData
    case 'UPDATE': {
      const updatedItem = action.responseData
      const prop = action.property ? action.property : 'id'
      const itemIndex = currentData.data.findIndex((el) => el[prop] === updatedItem[prop])
      currentData.data.splice(itemIndex, 1, updatedItem)
      return { data: currentData.data, total: currentData.total }
    }
    case 'DESTROY': {
      const prop = action.property ? action.property : 'id'
      const response = action.responseData[prop]
      const itemIndex = currentData.data.findIndex((el) => el[prop] === response)
      currentData.data.splice(itemIndex, 1)
      return { data: currentData.data, total: currentData.total - 1 }
    }
    case 'CREATE':
      return {
        data: [action.responseData, ...currentData.data],
        total: currentData.total + 1
      }
    default:
      return currentData
  }
}

export default kendoGridReducer
