import React from 'react'
import PropTypes from 'prop-types'
import formatBytes from '/src/utils/converter'

export default function Attachments({ fileName, fileSize }) {
  return (
    <React.Fragment>
      <div className="form-input-combobox-wrapper">
        <input
          disabled
          className="form-input-text"
          value={`${fileName} ${fileSize ? formatBytes(fileSize) : ''}`}
          type="text"
        />
      </div>
    </React.Fragment>
  )
}

Attachments.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number
}

Attachments.defaultProps = {
  fileSize: null
}
