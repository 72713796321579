import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import CircleLabel from '/src/ui/core/fields/circle_label'
import { MdError } from 'react-icons/md'

export default function WorkOrderColumn({ type, value }) {
  if (!value) return ''

  const textDisplayFields = ['reason', 'comments']
  const workOrderDisplayValue = textDisplayFields.map((field) => value[field]).filter((value) => value)
  const response = workOrderDisplayValue.join(' - ')

  if (!response && type === 'erect')
    return (
      <CircleLabel className={'rounded-label-gray'} value={I18n.t('scaffoldings.missing_request')} icon={<MdError />} />
    )

  return response
}

WorkOrderColumn.propTypes = {
  type: PropTypes.string,
  value: PropTypes.shape({})
}

WorkOrderColumn.defaultProps = {
  type: 'erect',
  value: {}
}
