/* eslint-disable max-lines-per-function */
import { useMemo } from 'react'
import usePreferences from '/src/ui/core/grid/preferences'
import { indexify } from '/src/utils/array'
import { byString } from '/src/utils/object'
import { getColumnWidth } from '/src/models/concerns/grid'
import { applyForeignMetadata } from '/src/models/concerns/eav_column'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import useDatasheetFilters from '/src/hooks/datasheet_filters'
import { applyDatasheetFilterInfo } from '/src/models/concerns/eav_column'

export default function useGridSettings({ templateId, foreignEntities }) {
  const { preferences: gridSettings, savingPreferencesStatus, savePreferences } = usePreferences()
  const batchedEntities = useEntitiesCache(foreignEntities)
  const { datasheetFilters, datasheetColumns, isLoading } = useDatasheetFilters(templateId)

  const templateSettings = useMemo(() => {
    if (Array.isArray(gridSettings[templateId]))
      return { columnsSettings: indexify(gridSettings[templateId], 'description') }
    return gridSettings[templateId] || { columnsSettings: {} }
  }, [gridSettings, templateId])

  const datasheetFilterSettings = useMemo(() => {
    if (isLoading || datasheetColumns === undefined) return { filterColumns: {}, filterInfos: {} }
    return { filterColumns: datasheetColumns, filterInfos: datasheetFilters }
  }, [datasheetFilters, datasheetColumns, isLoading])

  const updateColumnOrder = (column, columnsOrder) => {
    const colWithIndex = { ...column }
    const orderIndex = columnsOrder ? columnsOrder.indexOf(column.description) : -1
    const defaultValue = columnsOrder && columnsOrder.length + 1
    colWithIndex.orderIndex = orderIndex === -1 ? defaultValue : orderIndex + 1
    return colWithIndex
  }

  const updateColumnWidth = (column, columnsSettings) => {
    const columnKey = column.description
    const columnSettings = columnsSettings[columnKey]
    return {
      ...column,
      width: getColumnWidth(column, columnSettings || {})
    }
  }

  const getColumnsWithUpdatedSettings = (columns) => {
    let { columnsOrder } = templateSettings
    const { columnsSettings } = templateSettings
    const { filterColumns, filterInfos } = datasheetFilterSettings

    let updatedColumns = [...columns]

    if (columnsSettings && Object.keys(columnsSettings).length > 0) {
      updatedColumns = updatedColumns.map((column) => {
        const columnKey = column.description
        const columnSettings = templateSettings.columnsSettings[columnKey]

        if (columnSettings) {
          if (columnSettings.hide === true && columnsOrder) {
            columnsOrder = columnsOrder.filter((c) => c !== columnKey)
          }
          column.hide = columnSettings.hide || column.hide
        }

        return column
      })
    }

    updatedColumns = applyForeignMetadata(updatedColumns, batchedEntities)
    updatedColumns = applyDatasheetFilterInfo(updatedColumns, filterColumns, filterInfos)

    updatedColumns = updatedColumns
      .map((column) => updateColumnOrder(column, columnsOrder))
      .map((column) => updateColumnWidth(column, columnsSettings))

    return updatedColumns
  }

  const getSorting = () => {
    return byString(gridSettings, `${templateId}.sorting`)
  }

  const setSorting = (sorting) => {
    savePreferences({ ...gridSettings, [templateId]: { ...templateSettings, sorting } })
  }

  const setHiddenColumns = (columns) => {
    const newGridSettings = { ...gridSettings }
    newGridSettings[templateId] = getFormattedHiddenColumns(columns)
    savePreferences(newGridSettings)
  }

  const setColumnWidth = (columnName, width) => {
    const newGridSettings = { ...gridSettings }

    const columnsSettings = templateSettings.columnsSettings || {}

    columnsSettings[columnName] = { ...columnsSettings[columnName], width }
    newGridSettings[templateId] = { ...templateSettings, columnsSettings }
    savePreferences(newGridSettings)
  }

  const setColumnsOrder = (columns) => {
    const order = []
    columns.forEach((col) => (order[col.orderIndex] = col.description))
    const newGridSettings = { ...gridSettings }

    templateSettings.columnsOrder = order
    newGridSettings[templateId] = templateSettings
    savePreferences(newGridSettings)
  }

  const getFormattedHiddenColumns = (columns) => {
    const { columnsSettings } = templateSettings
    if (!columnsSettings) return templateSettings
    columns.forEach((column) => {
      if (column.hide === true)
        columnsSettings[column.description] = { ...columnsSettings[column.description], hide: column.hide }
      else if (columnsSettings[column.description]) delete columnsSettings[column.description].hide
    })
    return templateSettings
  }

  return {
    savingPreferencesStatus,
    getColumnsWithUpdatedSettings,
    setHiddenColumns,
    setColumnWidth,
    setColumnsOrder,
    getSorting,
    setSorting
  }
}
