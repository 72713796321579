import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useInputChange from '/src/ui/core/inputs/input_change'
import RadioButtonOption from './option'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/radio_button.css'

export default function RadioButton({ inputProps }) {
  const { id, options, value, title, required, onChange, valueFormatter } = inputProps
  const [inputValue, onInputChange] = useInputChange({ id, value, onChange, valueFormatter })
  const error = useInputError({ inputValue, title, required })
  const props = { ...inputProps, onChange: onInputChange, inputValue }

  if (Object.keys(options).length === 1) props.className = 'single-child'

  const optionContainer = options.map((option) => {
    let key = typeof option === 'object' ? option.label : option
    key = `${id}_${key.replace(/ /g, '_')}`
    return <RadioButtonOption inputProps={{ ...props, id: key }} option={option} key={key} />
  })

  return (
    <React.Fragment>
      <div className={classNames({ 'radio-button': true, 'input-error': error })}>{optionContainer}</div>
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

RadioButton.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    valueFormatter: PropTypes.func,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    title: PropTypes.string
  }).isRequired
}
