import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/grid/grid.css'

export default function GridPageSize({ onChangePageSize, quantity }) {
  const [style, setStyle] = useState({ width: `${quantity.toString().length}em` })
  const [editingQuantity, setEditingQuantity] = useState(quantity)

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) e.target.blur()
  }

  const onChange = (e) => {
    let { value } = e.target
    value = value.toString() === '' ? value.toString() : parseInt(value, 10)
    setEditingQuantity(value)
    const width = value.toString().length || 1
    setStyle({ width: `${width}em` })
  }

  const onBlur = () => {
    if (editingQuantity > 100) {
      setEditingQuantity(100)
      onChangePageSize(100)
    } else if (editingQuantity < 1 || !editingQuantity) {
      setEditingQuantity(1)
      onChangePageSize(1)
    } else {
      onChangePageSize(editingQuantity)
    }
  }

  return (
    <div id="page-size">
      <input
        type="number"
        className="page-size"
        data-testid="page-size"
        style={style}
        value={editingQuantity}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyPress}
      />
      {quantity > 1 ? ' items' : ' item'}
    </div>
  )
}

GridPageSize.propTypes = {
  onChangePageSize: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired
}
