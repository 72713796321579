/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react'
import { useStore } from 'react-context-hook'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import deNumbers from 'cldr-numbers-full/main/de/numbers.json'
import { IntlProvider, load } from '@progress/kendo-react-intl'
import PropTypes from 'prop-types'
import '/src/static/css/inputs.css'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { rangeNumericMessage } from '/src/models/concerns/eav_column'
import { isPresent } from '/src/utils/boolean_refinements'

load(deNumbers)

export default function InputNumeric({ inputProps, step, format }) {
  const {
    id,
    className,
    name,
    placeholder,
    value,
    min,
    max,
    title,
    required,
    onChange,
    onBlur,
    onFocus,
    readOnly,
    onPrintValue
  } = inputProps

  const [wrapperClass, setWrapperClass] = useState('form-input-numeric-wrapper')
  const [project] = useStore('project')
  const inputChangeProps = { id, value: parseFloat(value), onChange }
  const [inputValue, onInputChange] = useInputChange(inputChangeProps)
  const error = useInputError({ inputValue, title, required })
  const locale = project.number_format_type === 'COMMA' ? 'de' : 'en'
  const formattedMin = formatNumberWithPrecision(min)
  const formattedMax = formatNumberWithPrecision(max)
  const inputRef = useRef(null)
  const inputValueRef = useRef(null)
  const onFocusInput = (e) => {
    if (onFocus) onFocus(e)
    e.target.element.select()
    setWrapperClass('form-input-numeric-wrapper active')
  }

  const onBlurInput = (e) => {
    if (onBlur) onBlur(e)
    if (isPresent(min) && e.target.value < min) onInputChange({ target: { value: formattedMin } })
    if (isPresent(max) && e.target.value > max) onInputChange({ target: { value: formattedMax } })
    setWrapperClass('form-input-numeric-wrapper')
  }

  const inputClass = () => {
    const classes = ['form-input-numeric']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  const handleWheel = (e) => e.stopPropagation()

  useEffect(() => {
    // Capture inputValue on ref to use updated value on unmount useEffect bellow
    inputValueRef.current = inputValue
  }, [inputValue])

  useEffect(() => {
    inputRef.current.element.addEventListener('wheel', handleWheel)
    return () => {
      // Use inputValueRef to avoid inputValue dependency on unmount useEffect
      onBlurInput({ target: { value: inputValueRef.current } })
      inputRef.current.element.removeEventListener('wheel', handleWheel)
    }
  }, [])

  const formattedMinValue = onPrintValue ? onPrintValue(formattedMin) : formattedMin

  return (
    <div className={wrapperClass}>
      <IntlProvider locale={locale}>
        <NumericTextBox
          id={id}
          className={inputClass()}
          name={name}
          value={inputValue}
          min={isPresent(min) ? formattedMin : undefined}
          max={isPresent(max) ? formattedMax : undefined}
          placeholder={placeholder}
          step={step}
          format={format}
          onChange={onInputChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          disabled={readOnly}
          ref={inputRef}
        />
        <div className="info-label">{rangeNumericMessage(formattedMinValue, max)}</div>
        <div className="error-label">{error}</div>
      </IntlProvider>
    </div>
  )
}

InputNumeric.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numberOfDigits: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    onPrintValue: PropTypes.func
  }).isRequired,
  format: PropTypes.string.isRequired,
  step: PropTypes.number
}

InputNumeric.defaultProps = {
  step: 1
}
