/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import xss from 'xss'
import { BiLinkExternal } from 'react-icons/bi'
import { isBlankOrZero } from '/src/utils/boolean_refinements'
import '/src/static/css/inputs.css'

export default function InputFormulaLink({ inputProps }) {
  const { id, name, placeholder, formula_result: value, readOnly } = inputProps
  const options = {
    whiteList: {
      a: ['href', 'download']
    }
  }

  const getAttributes = (nodeMap) => {
    const attrs = {}
    Array.prototype.slice.call(nodeMap).forEach((item) => {
      const key = item.name === 'class' ? 'className' : item.name
      attrs[key] = item.value
    })

    return attrs
  }

  const createLinkElement = (val) => {
    let attributes = {
      target: '_blank',
      rel: 'noopener noreferrer'
    }
    if (!readOnly) attributes.href = val || ''

    if (val && val.includes('</a>')) {
      const sanitizedValue = xss(val, options)
      const el = document.createElement('div')
      el.innerHTML = sanitizedValue
      const anchor = el.firstElementChild
      const newAttributes = getAttributes(anchor.attributes)
      attributes = {
        ...attributes,
        ...newAttributes
      }
    }

    return React.createElement('a', attributes, <BiLinkExternal />)
  }

  const formatValue = (val) => {
    let actualValue = val
    if (val.includes('</a>')) {
      const el = document.createElement('div')
      el.innerHTML = xss(actualValue)
      actualValue = el.textContent
    }

    return actualValue
  }

  return (
    <React.Fragment>
      <div className="formula-wrapper input-type-link">
        <input
          id={id}
          className="form-input-link"
          name={name}
          value={!isBlankOrZero(value) ? formatValue(value) : ''}
          disabled
          placeholder={placeholder}
          type="text"
        />
        {createLinkElement(value)}
      </div>
    </React.Fragment>
  )
}

InputFormulaLink.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    formula_result: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    readOnly: PropTypes.bool
  }).isRequired
}
