import React from 'react'
import ContentLoader from 'react-content-loader'

export default function CellContentLoader() {
  return (
    <td>
      <div className="cell-loading" />
    </td>
  )
}
