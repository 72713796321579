import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import AppRouter from './routes/app_router_react'
import I18n from '/src/utils/translations'
import '/src/static/css/app.css'

if (import.meta.env.SNOWPACK_PUBLIC_SENTRY_URL) {
  Sentry.init({
    dsn: import.meta.env.SNOWPACK_PUBLIC_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
)

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept()
}
