import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import { scaffoldingTotalUnit } from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'

export default function useScaffoldingConfirmationPopup(showConfirmation) {
  const showActionConfirmation = (dataItem, requestParams, opts) => {
    let actionButtonText = I18n.t(`${requestParams.resourcePath}.confirmation_modal.${opts.actionName}_confirmation`)
    let cancelButtonText = I18n.t(`${requestParams.resourcePath}.confirmation_modal.delete_cancel`)
    if (opts.actionText) actionButtonText = opts.actionText
    if (opts.cancelText) cancelButtonText = opts.cancelText

    const actionSuccess = () => {
      const defaultSuccessMessage = I18n.t('notification.action_success', {
        action: I18n.t(`actions.past.${opts.actionName}`),
        model: I18n.t(`${requestParams.resourcePath}.title`),
        id: dataItem.tag_number || dataItem.id
      })
      notifySuccess(opts.customSuccessMessage || defaultSuccessMessage)

      return dispatch(BusEvents.RELOAD_GRID)
    }

    const actionParams = {
      title: I18n.t(`${requestParams.resourcePath}.confirmation_modal.${opts.actionName}_title`, {
        type: I18n.t(`${requestParams.resourcePath}.scaffolding_types.${dataItem?.scaffolding_type?.i18n_id}`)
      }),
      description: I18n.t(`${requestParams.resourcePath}.confirmation_modal.${opts.actionName}_description`, {
        type: I18n.t(`${requestParams.resourcePath}.scaffolding_types.${dataItem?.scaffolding_type?.i18n_id}`),
        total: dataItem?.total,
        dimension: dataItem?.scaffolding_type?.dimension_type ? scaffoldingTotalUnit(dataItem) : '',
        tag: dataItem?.tag_number
      }),
      actionButtonText,
      cancelButtonText,
      buttonColor: opts.buttonColor ? opts.buttonColor : '#B33939',
      onSuccess: () => actionSuccess,
      requestParams
    }

    return showConfirmation(actionParams)
  }

  return showActionConfirmation
}
