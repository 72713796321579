import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { useStore } from 'react-context-hook'
import { guid } from '@progress/kendo-react-common'
import { HiPlusCircle, HiTrash } from 'react-icons/hi'
import I18n from '/src/utils/translations'
import { isBlank, isBlankOrZero } from '/src/utils/boolean_refinements'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { notifyError, notifyInfo } from '/src/ui/core/dialogs/notifications'
import { measurementDimension } from '/src/models/scaffolding_part'
import ActionButton from '/src/ui/core/buttons/action_button'
import InputTitle from '/src/ui/core/inputs/input_title'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import InputControlledFormula from '/src/ui/core/inputs/input_controlled_formula'
import EntryItem from '/src/ui/core/inputs/multiple_entries/entry_item'
import '/src/static/css/core/inputs/input_multiple_entries.css'

export default function InputMultipleEntries({ inputProps }) {
  const { id, columns, dependency, dataItem, control, onChange, hideColumns, deletedEntryMessage } = inputProps
  const defaultValue = dataItem ? dataItem[dependency] : undefined
  const dependencyValue = useWatch({ control, name: dependency, defaultValue })
  const filteredColumns = useMemo(() => (hideColumns ? hideColumns(dependencyValue, columns) : columns), [
    hideColumns,
    dependencyValue,
    columns
  ])
  const [project] = useStore('project')
  const [values, setValues] = useState({})
  const [list, setList] = useState([])

  const calculateTotal = useCallback((inputValues) => {
    if (isBlank(inputValues) || Object.keys(inputValues).length === 0) return undefined

    const total = Object.values(inputValues).reduce((acc, value) => {
      const numericValue = isBlank(value) ? 0 : Number(value)
      return acc * numericValue
    }, 1)

    return total
  }, [])

  const handleOnChange = (idProp, value) => {
    setValues((prevValues) => {
      const currentValue = {
        ...prevValues,
        [idProp]: value
      }

      setList((prevList) => {
        const updatedList = [...prevList]
        const updatedValue = {
          ...currentValue,
          flag: true,
          uuid: guid(),
          total: calculateTotal(currentValue)
        }

        if (updatedList.length === 0) {
          updatedList.push({
            ...updatedValue
          })
        } else {
          updatedList[0] = {
            ...updatedValue
          }
        }
        return updatedList
      })

      return currentValue
    })
  }

  const inputs = useMemo(
    () =>
      filteredColumns.map((column) => {
        const { id: idColumn, description, title } = column
        const inputsProps = {
          ...column,
          id: description,
          dataItem,
          value: values[description],
          onChange: handleOnChange,
          control
        }
        const inputKey = list?.length === 0 ? list?.length : list?.length - 1

        if (column.type === 'decimal')
          return (
            <div
              className={`section-field width-${column.web_form_width} column-height`}
              key={`${dependencyValue}.${inputKey}.${idColumn}`}
            >
              <InputTitle title={title} />
              <InputDecimal inputProps={{ ...inputsProps, min: 0 }} />
            </div>
          )
        if (column.type === 'aggregate')
          return (
            <div
              className={`section-field width-${column.web_form_width} column-height`}
              key={`${dependencyValue}.${inputKey}.${idColumn}`}
            >
              <InputTitle title={title} />
              <InputControlledFormula inputProps={{ ...inputsProps, value: calculateTotal(values) }} />
            </div>
          )
        return <React.Fragment key={`fragment-${idColumn}`} />
      }),
    [filteredColumns, control, dataItem, values, dependencyValue, list?.length, calculateTotal]
  )

  const validateInputs = () => {
    const isValidInput = Object.entries(values).every(([key, value]) => {
      if (value === undefined || value <= 0) {
        const columnError = filteredColumns.find((column) => column.description === key)
        notifyError(
          I18n.t('form.inputs.multiple_entries.required', {
            field: columnError.title
          })
        )
        return false
      }
      return true
    })
    return isValidInput
  }

  const clearInputs = () => {
    setValues((prevValues) => {
      const newValues = { ...prevValues }
      Object.keys(prevValues).forEach((key) => {
        newValues[key] = undefined
      })
      return newValues
    })
    setList((prevValues) => {
      const updatedValues = prevValues.map((value, index) => {
        if (index === 0) return {}
        const newValue = { ...value }
        delete newValue.flag
        return newValue
      })
      return updatedValues
    })
  }

  const registerItemOnList = () => {
    const item = {
      ...values
    }
    setList([item, ...list])
    clearInputs()
  }

  const handleClick = () => {
    if (validateInputs()) registerItemOnList()
  }

  const handleDelete = (itemToDelete) => {
    notifyInfo(deletedEntryMessage(itemToDelete))
    setList((prevItem) => prevItem.filter((item) => item !== itemToDelete))
  }

  useEffect(() => {
    setValues(
      filteredColumns.reduce((acc, column) => {
        if (column.type !== 'aggregate') acc[column.description] = undefined
        return acc
      }, {})
    )
    setList([])
  }, [filteredColumns, dependencyValue])

  // The list is cleaned here
  useEffect(() => {
    const updatedList = list.filter((item, index) => {
      if (index !== 0) return true

      if (Object.keys(item).length === 0) return false

      const hasValidProperty = Object.entries(item).some(([key, value]) => {
        return key !== 'uuid' && key !== 'flag' && !isBlankOrZero(value)
      })

      if (hasValidProperty) return true

      return false
    })

    onChange(id, updatedList)
  }, [id, list, onChange])

  const savedItems = list.slice(1).map((value) => {
    const formattedTotal = formatNumberWithPrecision(value.total, project)
    const totalLabel = ` / ${formattedTotal}${measurementDimension(value)}`

    const formattedValues = Object.keys(value)
      .filter((key) => key !== 'uuid' && key !== 'total')
      .map((key) => {
        const initialOfInput = key[0].toUpperCase()
        const formattedNumber = formatNumberWithPrecision(value[key], project)
        return `${formattedNumber} ${initialOfInput}`
      })

    const itemLabel = formattedValues.join(' / ') + totalLabel

    return <EntryItem key={value.uuid} label={itemLabel} handleDelete={() => handleDelete(value)} />
  })

  return (
    <React.Fragment>
      {dependencyValue ? (
        <div className="input-multiple-entries" data-testid="input-multiple-entries">
          <div className="input-multiple-entries__fields-group">
            {inputs}
            <div className="input-multiple-entries__fields-group__clear-container">
              <ActionButton
                title=""
                className="button-action-discard"
                icon={<HiTrash />}
                onClick={() => clearInputs()}
              />
            </div>
          </div>
          <div className="input-multiple-entries__section-button-add">
            <ActionButton
              title={I18n.t('form.inputs.multiple_entries.add')}
              className="button-action-add"
              icon={<HiPlusCircle />}
              onClick={() => handleClick()}
            />
          </div>
          {savedItems}
        </div>
      ) : (
        <div className="input-multiple-entries__empty-items" data-testid="empty-items">
          {I18n.t(`form.inputs.multiple_entries.empty_entry.${dependency}`)}
        </div>
      )}
    </React.Fragment>
  )
}

InputMultipleEntries.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
