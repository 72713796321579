import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import xss from 'xss'
import { MdClear, MdInfoOutline, MdErrorOutline } from 'react-icons/md'
import { FiAlertTriangle, FiCheckCircle } from 'react-icons/fi'
import '/src/static/css/notifications.css'

export default function Notification({ hashKey, title, body, closeSelf, status, closeTimeout, closable }) {
  const [notificationClass, setClass] = useState('notification')

  const closeNotification = () => {
    setClass('notification hide')
    setTimeout(() => {
      closeSelf(hashKey)
    }, 290)
  }

  useEffect(() => {
    let mounted = true

    if (closeTimeout) {
      setTimeout(() => {
        if (mounted) closeNotification()
      }, closeTimeout * 1000)
    }

    return () => (mounted = false)
  }, [])

  const statusIcon = () => {
    switch (status) {
      case 'warning':
        return (
          <div data-testid="notification-status" className="status warning">
            <FiAlertTriangle />
          </div>
        )
      case 'error':
        return (
          <div data-testid="notification-status" className="status error">
            <MdErrorOutline />
          </div>
        )
      case 'success':
        return (
          <div data-testid="notification-status" className="status success">
            <FiCheckCircle />
          </div>
        )
      default:
        return (
          <div data-testid="notification-status" className="status info">
            <MdInfoOutline />
          </div>
        )
    }
  }

  return (
    <div className={notificationClass}>
      {statusIcon()}
      <div className="message">
        <div className="header">
          <div data-testid="notification-title" className="title">
            {title}
          </div>
          {closable && (
            <button type="button" data-testid="notification-close" className="close" onClick={closeNotification}>
              <MdClear />
            </button>
          )}
        </div>
        <div data-testid="notification-body" className="body" dangerouslySetInnerHTML={{ __html: xss(body) }} />
      </div>
    </div>
  )
}

Notification.propTypes = {
  hashKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  closeSelf: PropTypes.func,
  status: PropTypes.string,
  closeTimeout: PropTypes.number,
  closable: PropTypes.bool
}

Notification.defaultProps = {
  title: '',
  body: '',
  closeSelf: undefined,
  status: 'info',
  closeTimeout: undefined,
  closable: true
}
