import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore, store } from 'react-context-hook'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_EXPANDED } from '/src/utils/constants/grid'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

const sidePanelStyle = (isHidden, isExpanded, defaultStyle, index) => {
  if (isHidden) return SIDE_PANEL_CLOSED[index]
  if (isExpanded) return SIDE_PANEL_EXPANDED[index]
  return defaultStyle[index]
}

export default function LayoutPanels({ wrapperClass, columnStyles, children }) {
  const [isSidePanelHidden] = useStore('side_panel_hidden')
  const [isSidePanelExpanded, setIsSidePanelExpanded] = useState(false)

  useBus(
    BusEvents.TOGGLE_SIDE_PANEL,
    () => {
      store.set('side_panel_hidden', !isSidePanelHidden)
    },
    [isSidePanelHidden]
  )

  useBus(
    BusEvents.EXPAND_SIDE_PANEL_WIDTH,
    ({ payload }) => {
      setIsSidePanelExpanded(() => payload)
    },
    []
  )

  return (
    <div className={`layout-panels-wrapper ${wrapperClass}`} data-testid="layout-panels-wrapper">
      {React.Children.map(children, (child, index) => {
        return (
          <div
            className="layout-panel"
            style={sidePanelStyle(isSidePanelHidden, isSidePanelExpanded, columnStyles, index)}
          >
            {React.cloneElement(child, child.props)}
          </div>
        )
      })}
    </div>
  )
}

LayoutPanels.propTypes = {
  wrapperClass: PropTypes.string,
  columnStyles: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

LayoutPanels.defaultProps = {
  wrapperClass: '',
  columnStyles: [],
  children: []
}
