import { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
/**
 *  This custom hook will request to api eav_columns at an template and return the first by position
 *
 * @param templateId - id of eav_template to get columns
 * @returns {string} first column of type string or formula
 */
export default function useFetchDescription(templateId) {
  const { status, responseData, fetchAPI } = useFetchAPI('eav_columns')
  const [firstColumn, setFirstColum] = useState()

  useEffect(() => {
    if (!templateId) return
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { eav_template_id: templateId },
        order: { position: 'asc' }
      }
    }

    fetchAPI(params)
  }, [templateId])

  useEffect(() => {
    if (status !== 'SUCCESS') return
    if (!responseData.data.length) return

    const columns = responseData.data
    const firstColumn = getFirstStringOrFormula(columns)

    if (!firstColumn) return

    setFirstColum(firstColumn.description)
  }, [status, responseData])

  return firstColumn
}
