// eslint-disable-next-line
import I18n from '/src/utils/translations'

export default class EstimateServicePackageableModel {
  constructor(props) {
    this.paramName = EstimateServicePackageableModel.paramName

    if (!props) return
    this.columns.find((x) => x.description === 'service_package').searchExtraQuery = props.query
  }

  static paramName = 'estimate_service'

  name = I18n.t('estimate_services.title_packageable')

  singularName = I18n.t('estimate_services.description')

  route = 'estimate_services/create_by_package'

  flexible = true

  columns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('estimate_services.id'),
      editable: false,
      width: 200,
      type: 'integer',
      hideOnForm: true
    },
    {
      id: 2,
      description: 'service_package',
      title: I18n.t('estimate_services.service_package'),
      foreignKey: 'service_package',
      foreignAttribute: 'service_package_id',
      required: true,
      filterable: false,
      editable: false,
      searchFields: ['description'],
      searchRoute: 'service_packages',
      textDisplayFields: ['description'],
      type: 'search',
      width: 200
    }
  ]
}
