import React, { useState } from 'react'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PropTypes from 'prop-types'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ScaffoldingPartsSidePanel from '/src/ui/domain/scaffoldings/scaffolding_parts_side_panel'
import ScaffoldingPartActionForm from '/src/ui/domain/scaffoldings/scaffolding_part_action_form'
import useScaffoldingPartActionTitle from '/src/hooks/scaffolding_part_action_title'

export default function ScaffoldingPartWrapper({
  selectedScaffolding,
  selectedScaffoldingPart,
  handleCloseSidePanel,
  handleCloseActionForm,
  actionFormMode,
  partAction,
  type
}) {
  const formTitle = useScaffoldingPartActionTitle(actionFormMode, type)
  const [itemCount, setItemCount] = useState(1)
  const actionForm = actionFormMode === 'dimension' || actionFormMode === 'modification'

  useBus(
    BusEvents.FORM_FINISHED,
    ({ payload }) => {
      dispatch(BusEvents.RELOAD_GRID)

      if (payload && payload.createNew) {
        setItemCount((count) => count + 1)
      } else {
        dispatch(BusEvents.FORM_SIDE_PANEL_CLOSE)
      }
    },
    []
  )

  return (
    <React.Fragment>
      {actionForm ? (
        <div data-testid="model-side-panel" className="model-side-panel">
          <BlocksSidePanel title={formTitle} closable onClose={handleCloseActionForm}>
            <div className="side-panel-content">
              <ScaffoldingPartActionForm
                addOrModify={actionFormMode}
                scaffolding={selectedScaffolding}
                scaffoldingPartItem={selectedScaffoldingPart}
                partAction={partAction}
                type={type}
                key={itemCount}
              />
            </div>
          </BlocksSidePanel>
        </div>
      ) : (
        <ScaffoldingPartsSidePanel
          dataItem={selectedScaffoldingPart}
          parentDataItem={selectedScaffolding}
          onClose={handleCloseSidePanel}
        />
      )}
    </React.Fragment>
  )
}

ScaffoldingPartWrapper.propTypes = {
  selectedScaffolding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedScaffoldingPart: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleCloseSidePanel: PropTypes.func.isRequired,
  handleCloseActionForm: PropTypes.func.isRequired,
  actionFormMode: PropTypes.string,
  partAction: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string
}

ScaffoldingPartWrapper.defaultProps = {
  actionFormMode: null,
  partAction: {},
  type: undefined
}
