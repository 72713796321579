import React, { useState } from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import ScrollContainer from 'react-indiana-drag-scroll'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Image from '/src/ui/core/fields/image'
import ImageModal from '/src/ui/core/popups/image_modal'
import useCollapsiblePictures from '/src/hooks/collapsible_pictures'
import '/src/static/css/utilities/utilities.css'

export default function Picture({ images, imageSize, columnId, openModal, allowDelete, collapse, showComment }) {
  const [selected, setSelected] = useState(undefined)

  const [toggleButton, collapsibleStyles, carouselClassName] = useCollapsiblePictures(collapse, images.length)

  // available sizes: xs, sm, md, lg, xl
  const imageSizeClass = imageSize ? `picture-${imageSize}` : ''

  const printImages = () => {
    if (!images || images.length === 0) return I18n.t('fields.na')
    return images.map((image, index) => (
      <div key={image.id} role="button" className={`picture-side-panel ${imageSizeClass}`}>
        <Image
          image={image}
          key={image.id}
          index={index}
          onClick={() => setSelected(index)}
          showComment={showComment}
          columnId={columnId}
          allowDelete={allowDelete}
        />
      </div>
    ))
  }

  return (
    <div className="detail-field-picture">
      {selected !== undefined && openModal && (
        <ImageModal
          images={images}
          width={100}
          height={100}
          extraClass={'full-screen'}
          openImage={selected}
          onClose={() => setSelected(undefined)}
        />
      )}
      <ScrollContainer className="picture-scroll-group" hideScrollbars={false}>
        <div className={carouselClassName} style={collapsibleStyles}>
          {printImages()}
        </div>
        {toggleButton}
      </ScrollContainer>
    </div>
  )
}

Picture.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      file_path: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      comment: PropTypes.string
    })
  ),
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openModal: PropTypes.bool,
  allowDelete: PropTypes.bool,
  collapse: PropTypes.shape({
    isCollapsible: PropTypes.bool,
    picturesPerRow: PropTypes.number,
    picturesSize: PropTypes.number,
    sizeUnit: PropTypes.string
  }),
  imageSize: PropTypes.string,
  showComment: PropTypes.bool
}

Picture.defaultProps = {
  images: [],
  columnId: null,
  openModal: true,
  allowDelete: true,
  collapse: { isCollapsible: false },
  imageSize: null,
  showComment: true
}
