import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations.js'
import useFetch from '/src/hooks/api/fetch'
import Modal from '/src/ui/core/popups/modal'
import ContentLoader from '/src/ui/core/loaders/content_loader'
import EmptyState from '/src/ui/core/layouts/empty_state'
import DownloadAttachment from '/src/ui/core/inputs/download_attachment'
import Attachment from '/src/ui/core/inputs/attachments'
import { indexify } from '/src/utils/array'

const getCategory = (attachment, fileCategories) => {
  if (attachment?.file_category_id) {
    return fileCategories?.[attachment.file_category_id]?.description
  }
  return undefined
}

export default function AttachmentsModal({ subprojectId, requestId, onClose }) {
  const [fileCategories] = useStore('file_categories')
  const [request, setRequest] = useState()
  const [attachmentColumns, setAttachmentColumns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { fetch } = useFetch()

  const attachmentFiles = indexify(request?.attachments || [], 'uuid')
  const attachments = attachmentColumns.reduce((currentAttachments, column) => {
    const columnUUids = request[column.description] || []
    return [...currentAttachments, ...columnUUids.map((uuid) => attachmentFiles[uuid])]
  }, [])

  const fetchRequestColumns = useCallback(
    (requestTemplateId) => {
      if (!requestTemplateId) return

      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { eav_template_id: requestTemplateId } }
      }

      fetch('eav_columns', params, {
        onSuccess: ({ data: { data } }) => {
          setIsLoading(false)

          if (!data?.length) return
          setAttachmentColumns(
            data.filter((column) => {
              const type = column.column_type.description
              return type === 'attachment'
            })
          )
        },
        onError: () => setIsLoading(false)
      })
    },
    [fetch]
  )

  const fetchRequestData = useCallback(() => {
    if (!subprojectId || !requestId || isLoading) return

    setIsLoading(true)

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { subproject_id: subprojectId, id: requestId } }
    }

    fetch('requests', params, {
      onSuccess: ({ data: { data } }) => {
        if (!data?.length) {
          setIsLoading(false)
          return
        }

        setRequest(data[0])
        fetchRequestColumns(data[0].eav_template_id)
      },
      onError: () => setIsLoading(false)
    })
  }, [subprojectId, requestId, fetch, isLoading, fetchRequestColumns])

  useEffect(() => {
    if (request?.id === requestId) return
    fetchRequestData()
  }, [fetchRequestData, request?.id, requestId])

  return (
    <Modal
      title={I18n.t('work_order_attachments.title')}
      header={I18n.t('work_order_attachments.subtitle')}
      closable
      onClose={onClose}
      className="attachments-modal"
    >
      {isLoading && <ContentLoader width="100%" height={500} />}
      {!isLoading && attachments.length === 0 && (
        <EmptyState
          imageSrc="attachment"
          modelNameLower={I18n.t('orders.attachment')}
          message={I18n.t('empty_state.no_attachments')}
        />
      )}
      {!isLoading &&
        attachments.length > 0 &&
        attachments.map((attachment) => (
          <div className="d-flex" key={attachment.uuid}>
            <div className="left">
              <DownloadAttachment file={attachment} fileCategory={getCategory(attachment, fileCategories)} />
            </div>
            <Attachment fileName={attachment.description} />
          </div>
        ))}
    </Modal>
  )
}

AttachmentsModal.propTypes = {
  subprojectId: PropTypes.number,
  requestId: PropTypes.number,
  onClose: PropTypes.func.isRequired
}
