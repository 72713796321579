import { useEffect, useState, useCallback, useMemo } from 'react'
import I18n from '/src/utils/translations'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useUpdateScaffoldingFormModel from '/src/hooks/update_scaffolding_form_model'

export default function useDismantlePopupFields(scaffoldingItem, modelRoute) {
  const isScaffoldingItemModel = modelRoute === 'scaffoldings'
  const dateTitle = I18n.t(`${modelRoute}.dismantle_modal.date`)
  const workOrderTitle = I18n.t(`${modelRoute}.dismantle_modal.work_order`)
  const [workOrderField, dateField] = isScaffoldingItemModel
    ? ['main_work_order_dismantle_id', 'dismantle_date']
    : ['work_order_id', 'date']

  const {
    dismantle_date: dismantleDate,
    erect_date: erectDate,
    main_work_order_dismantle_id: dismantleWorkOrderId,
    main_work_order_erect_id: erectWorkOrderId
  } = scaffoldingItem || {}

  const today = normalizeDateToString(new Date())

  const [dataItem, setDataItem] = useState({})
  const [section, setSection] = useState({ columns: [] })
  const sections = useUpdateScaffoldingFormModel(section)
  const [errorMessages, setErrorMessages] = useState([])

  const defaultValues = useMemo(
    () => ({
      [dateField]: dismantleDate || today,
      [workOrderField]: dismantleWorkOrderId || erectWorkOrderId
    }),
    [dateField, dismantleDate, workOrderField, dismantleWorkOrderId, erectWorkOrderId]
  )

  const resetForm = useCallback(() => {
    setDataItem(defaultValues)
  }, [defaultValues])

  const onChange = useCallback((description, value) => {
    setDataItem((prevData) => {
      const newData = { ...prevData }
      newData[description] = value
      return newData
    })
  }, [])

  const updateSection = useCallback(() => {
    const updatedSection = {
      id: 1,
      columns: [
        {
          id: 1,
          description: dateField,
          title: dateTitle,
          type: 'date',
          required: true,
          min: erectDate,
          max: today,
          onChange,
          columnSize: 'width-100'
        },
        {
          id: 2,
          description: workOrderField,
          title: workOrderTitle,
          type: 'search',
          required: isScaffoldingItemModel,
          onChange,
          foreignKey: 'requests',
          foreignAttribute: workOrderField,
          searchFields: ['id', 'reason', 'comments'],
          searchExtraQuery: {
            where: {
              '[request_statuses][i18n_id]': ['in_planning', 'in_performance']
            },
            order: {
              id: 'desc'
            }
          },
          searchRoute: 'requests',
          textDisplayFields: ['id', 'reason', 'comments'],
          textField: 'reason',
          columnSize: 'width-100',
          clearButton: !isScaffoldingItemModel
        }
      ]
    }

    setSection(updatedSection)
  }, [dateField, dateTitle, erectDate, today, isScaffoldingItemModel, onChange, workOrderField, workOrderTitle])

  useEffect(() => {
    const messages = []
    if (!dataItem) return
    if (dataItem[dateField] > today) {
      messages.push(I18n.t(`${modelRoute}.dismantle_modal.errors.date_greater_max`))
    }
    setErrorMessages(messages)
  }, [dataItem, dateField, today, modelRoute])

  useEffect(() => {
    updateSection()
  }, [updateSection])

  useEffect(() => {
    if (!scaffoldingItem) return
    resetForm()
  }, [scaffoldingItem, resetForm])

  return [dataItem, errorMessages, resetForm, sections]
}
