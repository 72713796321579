/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useMemo, useReducer } from 'react'
import { useStore } from 'react-context-hook'
import '/src/static/css/core/grid/grid.css'
import PropTypes from 'prop-types'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { useQueryParam, NumberParam } from 'use-query-params'
import { eavColumnToKendoType } from '/src/models/concerns/eav_column'
import { setExpandRows } from '/src/utils/grid'
import usePaging from '/src/ui/core/grid/paging_hook'
import useGridFilters from '/src/ui/core/grid/filtering/grid_filters_hook'
import useSorting from '/src/ui/core/grid/sorting_hook'
import CellContentLoader from '/src/ui/core/grid/cell_content_loader'
import CustomizeCell from '/src/ui/core/grid/customize_cell'
import GridWrapper from '/src/ui/core/grid/grid_wrapper'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'
import GridLoader from '/src/ui/core/loaders/grid_loader'
import { ColumnMenu } from '/src/ui/core/grid/column_menu'
import useSelectableRows from '/src/ui/core/grid/selectable_rows'
import kendoGridReducer from '/src/ui/core/grid/kendo_grid_reducer'
import GridRowContextMenu from '/src/ui/core/grid/grid_row_context_menu'
import ColumnFooterCell from '/src/ui/core/grid/column_footer_cell'
import ColumnFooterTitle from '/src/ui/core/grid/column_footer_title'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchGrid from '/src/ui/core/grid/fetch_grid'
import Status from '/src/ui/core/grid/status'
import I18n from '/src/utils/translations'
import { allFilterOperators } from '/src/models/concerns/filter_operators'
import { forceBooleanDefault } from '/src/utils/object'
import { HeaderFilterCell } from '/src/ui/core/grid/filtering/header_filter_cell'

const SELECTED_FIELD = 'selected'

// eslint-disable-next-line max-lines-per-function
export default function KendoGrid({
  model,
  extraRows,
  pageSize,
  filter,
  sortable,
  sort,
  expandedComponent,
  loadFlexColumns,
  tabs,
  contextMenuItems,
  columnCellFactory,
  customColumns,
  templateId,
  rowClick,
  overlayHeaderColumns,
  opts
}) {
  const [tabbing, setTabbing] = useQueryParam('eav_template_id', NumberParam)
  const [gridColumns, setGridColumns] = useState(model.columns)
  const [scrollPosition, setScrollPosition] = useState()
  const [formattedGridColumns, setFormattedGridColumns] = useState()
  const [optionsEnabled, setOptionsEnabled] = useState(true)
  const [gridContext, setGridContext] = useStore('grid_context')
  const [dataSource, dispatch] = useReducer(kendoGridReducer, { data: [], total: 0 })
  const { paging, onPageChange } = usePaging(pageSize)
  const { kendoGridFilters, queryFilters, onFilterChange } = useGridFilters({ defaultFilters: filter, gridColumns })
  const gridFilter = useMemo(() => ({ filters: kendoGridFilters }), [kendoGridFilters])
  const { sorting, onSortChange } = useSorting(sort)
  const [status, setStatus] = useState('loading')
  const { loading, responseData, flexibleColumnsData, errors, read } = useFetchGrid({
    model,
    paging,
    queryFilters,
    sorting,
    tabbing: tabs.length ? tabbing || tabs[0].id : null,
    templateId: templateId || tabbing,
    flexible: loadFlexColumns
  })

  const { onSelectionChange, selectionColumn, formattedData, SelectedRows } = useSelectableRows(
    responseData,
    opts.usingSelectableRow
  )

  const { selectedItem, onSelectedItem } = opts

  useBus(BusEvents.RELOAD_GRID, () => read(), [read])

  useEffect(() => {
    setGridContext({
      formattedData,
      request: opts.request,
      tabs: [...tabs]
    })
  }, [formattedData])

  useEffect(() => {
    extraRows = setExpandRows(extraRows, false)
  }, [filter])

  useEffect(() => {
    setFixedAndFlexibleGridColumns()

    if (!loading && !errors) {
      const responseDataAux = { ...responseData }
      responseDataAux.data = responseData.data.concat(extraRows)
      if (expandedComponent && opts.forceExpandRows) {
        responseDataAux.data = setExpandRows(responseDataAux.data, true)
      }
      dispatch({ type: 'READ', responseData: responseDataAux })
      responseDataAux.data.length > 0 ? setStatus('loaded') : setStatus('empty')
    }
  }, [loading, responseData, extraRows, flexibleColumnsData])

  const expandChange = (e) => {
    e.dataItem.expanded = !e.dataItem.expanded
    dispatch({ type: 'UPDATE', responseData: e.dataItem, property: opts.uniqueKey })
  }

  const customizedCell = (column) => {
    const cellContendLoader = () => <CellContentLoader />
    const customizeCell = (props) => {
      return <CustomizeCell cell={props} column={column} columnCellFactory={columnCellFactory} />
    }

    return loading ? cellContendLoader : customizeCell
  }

  useEffect(() => {
    setFixedAndFlexibleGridColumns()
  }, [model.columns])

  // mount grid columns
  useEffect(() => {
    if (!gridColumns) return

    const optionalColumns = []

    if (contextMenuItems) {
      optionalColumns.unshift(<Column key="action" field="" cell={embedGridRowMenu} width={60} sortable={false} />)
    }

    const customGridColumns = customColumns.map((col) => [col.columnComponent])

    const entityColumns = []
    gridColumns.forEach((column) => {
      if (column.hideOnGrid || column.hide) return
      const columnComponent = (
        <Column
          key={column.description}
          field={column.description}
          title={column.title}
          width={column.width || 150}
          cell={customizedCell(column)}
          sortable={column.sortable}
          filterable={column.filterable}
          columnMenu={
            column.filterable === false
              ? undefined
              : (props) => (
                  <ColumnMenu
                    {...props}
                    columns={gridColumns}
                    onColumnsSubmit={(columnsState) => setGridColumns(columnsState)}
                  />
                )
          }
          footerCell={
            column.footer
              ? () => (
                  <ColumnFooterCell
                    column={column.description}
                    filtered={opts.usingSelectableRow}
                    data={opts.usingSelectableRow ? formattedData : dataSource.data}
                  />
                )
              : null
          }
        />
      )
      entityColumns.push(columnComponent)
    })

    if (opts.usingSelectableRow) {
      entityColumns.unshift(selectionColumn)
    }

    if (opts.enableRadioCheckbox) {
      entityColumns.unshift(
        <Column
          key="select"
          field=""
          width={70}
          cell={(e) =>
            e.dataItem.label ? (
              <td>
                <input
                  type="radio"
                  name="selected"
                  value={e.dataItem}
                  onClick={() => {
                    saveScrollPosition()
                    e.dataItem.selected ? onSelectedItem(undefined) : onSelectedItem(e.dataItem)
                  }}
                  defaultChecked={e.dataItem.selected}
                />
              </td>
            ) : (
              <td />
            )
          }
          sortable={false}
          filterable={false}
        />
      )
    }

    if (model.footer) {
      optionalColumns.unshift(
        <Column
          key="selectedCount"
          field=""
          width={1}
          sortable={false}
          footerCell={() => <ColumnFooterTitle selected={SelectedRows.length} />}
        />
      )
    }

    setFormattedGridColumns([...optionalColumns, ...customGridColumns, entityColumns])
  }, [gridColumns, formattedData, dataSource, loading])

  useEffect(() => {
    const contentDiv = document.getElementsByClassName('k-grid-content')[0]

    if (contentDiv && scrollPosition) contentDiv.scrollTo(0, scrollPosition)
  })

  const setFixedAndFlexibleGridColumns = () => {
    if (loadFlexColumns && flexibleColumnsData.length > 0) {
      setGridColumns([...model.columns, ...flexibleColumnsData])
    } else {
      setGridColumns(model.columns)
    }
  }

  const saveScrollPosition = () => {
    const position = document.getElementsByClassName('k-grid-content')[0].scrollTop
    setScrollPosition(position)
  }

  const onRowClick = (e) => {
    saveScrollPosition()
    if (rowClick) rowClick(e)
    if (!onSelectedItem) return
    const alreadySelected = selectedItem && e.dataItem[opts.uniqueKey] === selectedItem[opts.uniqueKey]
    if (!opts.enabledButtons) setOptionsEnabled(!!alreadySelected)
    onSelectedItem(alreadySelected || !e.dataItem[opts.uniqueKey] ? undefined : e.dataItem)
  }

  const icons = () => {
    const defaultIcons = ['label', 'clustered', 'split']
    const buttons = opts.icons && opts.icons.length > 0 ? opts.icons : defaultIcons
    return buttons
  }

  const embedGridRowMenu = (dataItem) => {
    return (
      <GridRowContextMenu
        key={dataItem.dataItem.id}
        dataItem={dataItem.dataItem}
        data={dataSource.data}
        items={contextMenuItems}
      />
    )
  }

  const data = () => {
    if (selectedItem && opts.filterBySingleSelection) return [forceBooleanDefault(selectedItem, SELECTED_FIELD)]

    let rows = opts.usingSelectableRow ? formattedData : dataSource.data
    if (selectedItem) {
      rows = rows.map((item) => ({ ...item, selected: item[opts.uniqueKey] === selectedItem[opts.uniqueKey] }))
    }
    if (expandedComponent && opts.forceExpandRows) rows = setExpandRows(rows, true)
    return rows ? rows.map((item) => forceBooleanDefault(item, SELECTED_FIELD)) : []
  }

  const total = () => {
    const total = Math.ceil(dataSource.total / paging.pageSize)

    return isNaN(total) ? 0 : total
  }

  const content = useMemo(() => {
    const { name, route } = model

    const grid = (
      <Grid
        data={data()}
        total={dataSource.total}
        filterable
        filter={gridFilter}
        filterOperators={allFilterOperators}
        filterCellRender={(_, props) => <HeaderFilterCell {...props} columns={gridColumns} />}
        onFilterChange={onFilterChange}
        sortable={sortable}
        sort={sorting}
        reorderable
        resizable
        onSortChange={onSortChange}
        expandField={expandedComponent ? 'expanded' : ''}
        onExpandChange={expandChange}
        detail={expandedComponent}
        selectedField={SELECTED_FIELD}
        onSelectionChange={(e) => {
          saveScrollPosition()
          opts.usingSelectableRow ? onSelectionChange(e) : undefined
        }}
        onRowClick={onRowClick}
      >
        {formattedGridColumns}
      </Grid>
    )

    return (
      <Status
        status={status}
        loading={<GridLoader />}
        empty={
          <GridEmptyState
            onCreate={null}
            modelName={name}
            modelRoute={route}
            templateId={tabbing}
            onPopupImportClose={read}
          />
        }
      >
        {grid}
      </Status>
    )
  }, [status, formattedGridColumns, formattedData, selectedItem])

  return gridContext ? (
    <GridWrapper
      page={Math.ceil((paging.skip + paging.pageSize) / paging.pageSize)}
      take={paging.pageSize}
      total={total()}
      onPageChange={onPageChange}
      gridTitle={opts.gridTitle || I18n.t('grid.all')}
      selectedItem={opts.selectedItem}
      optionsEnabled={optionsEnabled}
      icons={icons()}
      overlayHeaderColumns={overlayHeaderColumns}
    >
      {content}
    </GridWrapper>
  ) : null
}

KendoGrid.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  extraRows: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.arrayOf(PropTypes.object),
  sortable: PropTypes.bool,
  sort: PropTypes.arrayOf(PropTypes.object),
  expandedComponent: PropTypes.func,
  loadFlexColumns: PropTypes.bool,
  rowClick: PropTypes.func,
  templateId: PropTypes.number,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ),
  columnCellFactory: PropTypes.element,
  customColumns: PropTypes.arrayOf(
    PropTypes.shape({
      columnComponent: PropTypes.element,
      rowComponent: PropTypes.func
    })
  ),
  overlayHeaderColumns: PropTypes.element,
  opts: PropTypes.shape({
    api: PropTypes.bool,
    usingSelectableRow: PropTypes.string,
    request: PropTypes.oneOfType([PropTypes.object]),
    enableRadioCheckbox: PropTypes.bool,
    enabledButtons: PropTypes.bool,
    filterBySingleSelection: PropTypes.bool,
    forceExpandRows: PropTypes.bool,
    gridTitle: PropTypes.string,
    uniqueKey: PropTypes.string,
    selectedItem: PropTypes.oneOfType([PropTypes.object]),
    onSelectedItem: PropTypes.func,
    icons: PropTypes.arrayOf(PropTypes.string)
  })
}

KendoGrid.defaultProps = {
  extraRows: [],
  pageSize: 30,
  tabs: [],
  filter: [],
  sortable: true,
  loadFlexColumns: false,
  sort: [],
  expandedComponent: null,
  rowClick: null,
  templateId: null,
  contextMenuItems: null,
  columnCellFactory: null,
  customColumns: [],
  overlayHeaderColumns: <React.Fragment />,
  opts: {
    api: true,
    uniqueKey: 'id',
    usingSelectableRow: false,
    request: null,
    enableRadioCheckbox: false,
    enabledButtons: false,
    filterBySingleSelection: false,
    forceExpandRows: false,
    selectedItem: null,
    onSelectedItem: null,
    icons: [],
    gridTitle: I18n.t('grid.all')
  }
}
