import React, { useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import GalleryAccordion from '/src/ui/core/layouts/gallery_accordion'
import GalleryPicture from '/src/ui/core/fields/gallery_picture'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'

export default function ImageGalleryBlock({ columns, showButtons, defaultExpand }) {
  const [expandedAccordion, setExpandedAccordion] = useState(defaultExpand)

  if (!columns || columns.length === 0)
    return (
      <GridEmptyState
        modelName={I18n.t('empty_state.columns')}
        message={I18n.t('empty_state.no_columns')}
        imageSrc="columns"
        className="empty-state-wrapper-gallery"
      />
    )

  return columns.map((column, index) => {
    const picLength = `(${column.pictures.length.toString().padStart(2, '0')})`
    const label = `${column.module} - ${column.name} `

    return (
      <GalleryAccordion
        key={label}
        label={label}
        index={index}
        picLength={picLength}
        expandedAccordion={expandedAccordion}
        setExpandedAccordion={setExpandedAccordion}
        dataItem={column.dataItem}
        column={column}
        model={column.model}
        showButtons={showButtons}
      >
        <GalleryPicture
          images={column.pictures}
          columnId={column?.fixedPicture ? null : column.id}
          openModal={showButtons}
        />
      </GalleryAccordion>
    )
  })
}

ImageGalleryBlock.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          file_path: PropTypes.string.isRequired,
          uuid: PropTypes.string.isRequired,
          comment: PropTypes.string
        })
      ),
      name: PropTypes.string,
      module: PropTypes.string
    })
  ),
  showButtons: PropTypes.bool,
  defaultExpand: PropTypes.number
}

ImageGalleryBlock.defaultProps = {
  columns: [],
  showButtons: true,
  defaultExpand: null
}
