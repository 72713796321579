import { useEffect, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFetch from '/src/hooks/api/fetch'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useEavTemplate({ onFetch, shouldFetch, eavTemplateId }) {
  const { fetch } = useFetch()

  const [urlTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const [eavTemplate, setEavTemplate] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isLoading || isPresent(eavTemplate) || (shouldFetch && !shouldFetch())) return

    const templateId = eavTemplateId || urlTemplateId

    if (!templateId) return

    setIsLoading(true)

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: templateId } }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data: { data } }) => {
        if (!data || data.length === 0 || !data[0] || !data[0].id) return

        setEavTemplate(data[0])
        if (onFetch) onFetch(data[0])

        setIsLoading(false)
      },
      onError: () => setIsLoading(false)
    })
  }, [isLoading, eavTemplate, onFetch, shouldFetch, eavTemplateId, urlTemplateId, fetch])

  return eavTemplate
}
