import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdEdit } from 'react-icons/md'
import I18n from '/src/utils/translations'
import InspectedModule from '/src/models/concerns/inspected_module'
import InspectedModuleFilterStepsModal from '/src/ui/domain/inspections/popups/inspected_module_filter_steps_modal'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/inputs/input_filter.css'

const typeMap = {
  Progress: 'progress_services',
  Scope: 'estimate_services'
}

export default function InputModuleFilter({ inputProps, extraParams }) {
  const { onChange } = inputProps
  const { columns } = new InspectedModule()
  const { eavTemplate } = extraParams

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState(columns)
  const [currentValues, setCurrentValues] = useState({})
  const [displayParentValues, setDisplayParentValues] = useState({})

  const updateCurrentValues = (filters) => {
    const newCurrentValues = filters.reduce((acc, { value, description }) => {
      onChange(description, value)
      acc[description] = value
      return acc
    }, {})

    setCurrentValues(newCurrentValues)
  }

  const adjustInspectedType = (values) => {
    if (values.service_ids && typeMap[values.inspected_type]) {
      return { ...values, inspected_type: typeMap[values.inspected_type] }
    }

    return values
  }

  const onDone = (values, displayValues) => {
    const newStepFilters = stepFilters.map((f) => ({
      ...f,
      value: values[f.description],
      displayValue: displayValues[f.description]
    }))

    const inspectedType = newStepFilters[0].value
    const valueMapping = {
      progress_services: 'Progress',
      estimate_services: 'Scope'
    }

    if (valueMapping[inspectedType]) {
      newStepFilters[0].value = valueMapping[inspectedType]
    }

    dispatch({
      type: BusEvents.UPDATE_INSPECTED_ITEM,
      payload: values
    })

    setDisplayParentValues(displayValues)
    setStepFilters(newStepFilters)
    updateCurrentValues(newStepFilters)
    setOpen(false)
  }

  const renderValues = (value, description) => {
    if (Array.isArray(value)) {
      return value.map((valueItem) => {
        const computedText = valueItem?.computed_text_field || valueItem
        return (
          <span
            key={computedText}
            className="input-module-filter__inspected-services"
          >
            {computedText}
          </span>
        )
      })
    }

    return description === 'inspected_type'
      ? I18n.t(`inspections.inspected_modules.${value.toLowerCase()}`)
      : value
  }

  const renderFilters = () => {
    const allFiltersEmpty = stepFilters.every((filter) => !filter.displayValue)

    if (allFiltersEmpty) {
      return stepFilters.map(({ id, title }) => <div key={id}>{`${title}: ${I18n.t('fields.na')}`}</div>)
    }

    return stepFilters
      .filter((filter) => filter.displayValue)
      .map(({ id, title, displayValue, description }) => (
        <div key={id}>
          {`${title}: `}
          {renderValues(displayValue, description)}
        </div>
      ))
  }

  return (
    <React.Fragment>
      <div className="filter">
        <div className="filter-header">
          <div className="filter-group-title">
            <div className="filter-title">{I18n.t('inspections.module')}</div>
          </div>
          <div
            data-testid="filter-edit"
            className="filter-edit"
            onClick={() => setOpen(true)}
          >
            <MdEdit />
          </div>
        </div>
        <div className="filter-body">
          <div className="filters">{renderFilters()}</div>
        </div>
      </div>
      <div className="error-label" />
      {open && (
        <InspectedModuleFilterStepsModal
          filters={stepFilters}
          onClose={() => setOpen(false)}
          currentValues={adjustInspectedType(currentValues)}
          displayParentValues={displayParentValues}
          onDone={onDone}
          inspectionDetail={eavTemplate.inspection_detail}
        />
      )}
    </React.Fragment>
  )
}

InputModuleFilter.propTypes = {
  inputProps: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  extraParams: PropTypes.oneOfType([PropTypes.object])
}

InputModuleFilter.defaultProps = {
  extraParams: {}
}
