import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@progress/kendo-react-dialogs'
import I18n from '/src/utils/translations'
import { MEDIUM_POPUP_HEIGHT, MEDIUM_POPUP_WIDTH } from '/src/utils/constants/popups'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import ActionButton from '/src/ui/core/buttons/action_button'
import useOpenFile from '/src/hooks/api/open_file'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import ColumnInput from '/src/ui/core/inputs/column_input'
import { isPresent } from '/src/utils/boolean_refinements'
import { workPackageFilename } from '/src/models/request'
import { useStore } from 'react-context-hook'
import '/src/static/css/dialogs.css'
import '/src/static/css/domain/requests/configure_work_package_file.css'

const workPackageConfigurationColumns = [
  { description: 'work_description', columnSize: 'width-100' },
  { description: 'estimates', columnSize: 'width-50' },
  { description: 'estimates_price', columnSize: 'width-50' },
  { description: 'hse_r', columnSize: 'width-100' },
  { description: 'inspections', columnSize: 'width-100' },
  { description: 'inspection_page_breaker', columnSize: 'width-50' },
  { description: 'inspection_pictures', columnSize: 'width-50' },
  { description: 'attachments', columnSize: 'width-50' },
  { description: 'attached_documents', columnSize: 'width-50' }
]

export default function ConfigureWorkPackageFile({ onClose, request }) {
  const { openFile } = useOpenFile(onClose)
  const [disciplines] = useStore('disciplines')
  const [sectionsVisibility, setSectionsVisibility] = useState(
    workPackageConfigurationColumns.reduce(
      (currentDataItem, { description }) => ({
        ...currentDataItem,
        [description]: true
      }),
      {}
    )
  )

  const onGenerateFile = () => {
    const excludedSections = workPackageConfigurationColumns
      .filter(({ description }) => sectionsVisibility[description] === false)
      .map(({ description }) => description)
      .join(',')

    openFile(
      `requests/${request.id}/work_package`,
      isPresent(excludedSections)
        ? {
            query: { exclude: excludedSections }
          }
        : {},
      () => workPackageFilename(request, disciplines)
    )
  }

  const renderColumn = (column) => (
    <ColumnInput
      column={column}
      onChange={(_, value) =>
        setSectionsVisibility((prevDataItem) => ({
          ...prevDataItem,
          [column.description]: value
        }))
      }
      dataItem={sectionsVisibility}
    />
  )

  const formSections = useMemo(
    () => [
      {
        id: 1,
        eav_columns: workPackageConfigurationColumns.map(({ description, columnSize }, index) => ({
          id: index,
          description,
          title: I18n.t(`dialogs.configure_work_package_file.${description}`),
          columnSize,
          type: 'boolean'
        }))
      }
    ],
    []
  )

  return (
    <React.Fragment>
      <Dialog
        className="configure-work-package-file"
        title={I18n.t('dialogs.configure_work_package_file.header')}
        closeIcon
        onClose={onClose}
        width={MEDIUM_POPUP_WIDTH}
        height={MEDIUM_POPUP_HEIGHT}
      >
        <div className="title">
          <span>{I18n.t('dialogs.configure_work_package_file.title')}</span>
        </div>
        <div className="subtitle">
          <span>{I18n.t('dialogs.configure_work_package_file.subtitle')}</span>
        </div>
        <SectionedPanel
          sections={formSections}
          enumerable={false}
          hideable={false}
          sectionable={false}
          showBorder={false}
          display
          renderColumn={renderColumn}
        />
        <div className="action-buttons">
          <CancelButton title={I18n.t('actions.cancel')} onClick={onClose} />
          <ActionButton title={I18n.t('dialogs.configure_work_package_file.generate')} onClick={onGenerateFile} />
        </div>
      </Dialog>
    </React.Fragment>
  )
}

ConfigureWorkPackageFile.propTypes = {
  onClose: PropTypes.func,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reason: PropTypes.string,
    comments: PropTypes.string,
    discipline_id: PropTypes.number
  })
}

ConfigureWorkPackageFile.defaultProps = {
  onClose: () => {},
  request: undefined
}
