import React from 'react'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ModalActionButtons({
  index,
  isLoading,
  isLastStep,
  isNextButtonDisabled,
  handleNextStep,
  handleDoneClick,
  handlePreviousStep
}) {

  if (isLoading) return (
    <div className="filter-steps-action-buttons">
      <div id="filter-steps-prev-button" className="filter-steps-button temp-prev">
        {index > 0 && (
          <button className="form-cancel-button" onClick={handlePreviousStep} type="button">
            <MdArrowBack />
            {` ${I18n.t('actions.prev')}`}
          </button>
        )}
      </div>
    </div>
  )

  return (
    <div className="filter-steps-action-buttons">
      <div id="filter-steps-prev-button" className="filter-steps-button">
        {index > 0 && (
          <button
            className="form-cancel-button"
            onClick={handlePreviousStep}
            type="button"
          >
            <MdArrowBack />
            {` ${I18n.t('actions.prev')}`}
          </button>
        )}
      </div>

      <div id="filter-steps-next-button" className="filter-steps-button">
        {isLastStep ? (
          <button className="next-button" onClick={handleDoneClick} type="button">
            {`${I18n.t('actions.done')}`}
          </button>
        ) : (
          <button
            className="next-button"
            disabled={isNextButtonDisabled}
            onClick={handleNextStep}
            type="button"
          >
            {`${I18n.t('actions.next')} `}
            <MdArrowForward />
          </button>
        )}
      </div>
    </div>
  )
}

ModalActionButtons.propTypes = {
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  isNextButtonDisabled: PropTypes.bool.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired
}
