import React from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import I18n from '/src/utils/translations.js'

export default function StatusFilterLabel({ options, onClearFilters }) {
  return (
    <div className="filter-label">
      {`${I18n.t('grid.flag_filter')}: ${options.join(', ')}`}
      <MdClose onClick={onClearFilters} data-testid="clear-status-filter" />
    </div>
  )
}

StatusFilterLabel.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]).isRequired
}
