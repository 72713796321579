import { useEffect, useState } from 'react'
import useFetch from '/src/hooks/api/fetch'

export default function useTranslations() {
  const [loadingTranslations, setLoadingTranslations] = useState(true)
  const { fetch } = useFetch()

  const buildLocaleKeys = (data) => {
    return data.reduce((map, obj) => {
      const result = { ...map }
      if (obj.eav_template_id) result[`${obj.eav_template_id}_${obj.key}`] = obj.value
      else result[obj.key] = obj.value
      return result
    }, {})
  }

  useEffect(() => {
    fetch('translations', {}, {
      useParse: true,
      onSuccess: ({ data }) => {
        const result = buildLocaleKeys(data)
        localStorage.setItem('translations', JSON.stringify(result))
        setLoadingTranslations(false)
      }
    })
  }, [fetch])

  return loadingTranslations
}
