import { useState, useEffect, useCallback } from 'react'
import useGeneralDiscipline from '/src/hooks/general_discipline'
import useEavTemplate from '/src/hooks/eav_template'
import { addRequestByDisciplineQuery } from '/src/models/concerns/form'

export default function useUpdateFormModel({ requestIds, type, formModel, dataItemRequestId, shouldFetch }) {
  const [model, setModel] = useState(formModel)

  const generalDiscipline = useGeneralDiscipline()

  const onFetchEavTemplate = useCallback(
    (eavTemplate) =>
      setModel((prevScopeModel) => addRequestByDisciplineQuery(prevScopeModel, eavTemplate, generalDiscipline)),
    [generalDiscipline]
  )

  const eavTemplate = useEavTemplate({ onFetch: onFetchEavTemplate, shouldFetch })

  useEffect(() => {
    if (!requestIds && type !== 'edit') return
    const { columns } = model
    const requestColumn = columns.find((a) => a.description === 'request')

    if (dataItemRequestId || requestIds.length === 1) {
      requestColumn.readOnly = true
      const reqItem = dataItemRequestId || requestIds[0]
      requestColumn.default = reqItem
      requestColumn.searchExtraQuery.where = { id: reqItem }
    } else {
      const oldWhere = requestColumn.searchExtraQuery.where
      requestColumn.searchExtraQuery.where = { ...oldWhere, id: requestIds }
    }
    setModel(model)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestIds, dataItemRequestId, type])

  return [model, eavTemplate]
}
