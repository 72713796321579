// eslint-disable-next-line
import I18n from '/src/utils/translations.js'

const items = () => {
  return [
    {
      name: 'requests',
      title: I18n.t('top_menu.requests')
    },
    {
      name: 'scopes',
      title: I18n.t('top_menu.scopes')
    },
    {
      name: 'plannings',
      title: I18n.t('top_menu.plannings')
    },
    {
      name: 'performances',
      title: I18n.t('top_menu.performances')
    },
    {
      name: 'inspections',
      title: I18n.t('top_menu.inspections')
    },
    {
      name: 'scaffoldings',
      title: I18n.t('top_menu.scaffoldings')
    }
  ]
}

export default items
