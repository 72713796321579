/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { IoMdExit } from 'react-icons/io'
import { HiPlusCircle } from 'react-icons/hi'
import PropTypes from 'prop-types'
import { formulaCategory } from '/src/models/concerns/formula_categories'
import I18n from '/src/utils/translations'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useHistoryPopupFields from '/src/ui/domain/progresses/history_popup_fields'
import SidePanelFormWrapper from '/src/ui/core/grid/side_panel/side_panel_form_wrapper'
import { isPresent } from '/src/utils/boolean_refinements'
import useFetch from '/src/hooks/api/fetch'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import ColumnInput from '/src/ui/core/inputs/column_input'

export default function HistoryForm({ parentDataItem, historyDataItem, onClose, level }) {
  const isEdition = isPresent(historyDataItem?.id)

  const { fetch } = useFetch()
  const [formulas, setFormulas] = useState()

  const historyPopupFieldsProps = { parentDataItem, historyDataItem, level, formulas }
  const [dataItem, sections, resetDataItem] = useHistoryPopupFields(historyPopupFieldsProps)

  const model = {
    name: I18n.t('history_popup.singular'),
    paramName: `${level}_history`,
    route: `${level}_histories`
  }

  const percentageText = I18n.t('history_popup.percentage')

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          eav_template_id: parentDataItem.eav_template_id,
          category: formulaCategory.drop
        }
      }
    }

    fetch('formulas', params, {
      onSuccess: ({ data }) => {
        const formulasObj = {}
        data.data.forEach((formula) => {
          const fields = formula.fields.map((field) => ({ ...field, editable: true }))
          formulasObj[formula.id] = { ...formula, entity: level, fields }
        })
        setFormulas(formulasObj)
      }
    })
  }, [parentDataItem])

  const progressType = (progressBy) => {
    return progressBy === percentageText ? 'accumulated_progress' : 'daily_quantity'
  }

  const validateForm = (item) => {
    const fields = [progressType(item.progress_by), 'crew_size', 'crews', 'date', `${level}_id`, 'working_hours']

    const validated = fields.every((field) => item[field])

    if (!validated)
      notifyError({
        title: I18n.t('history_popup.form_error_title'),
        body: I18n.t('history_popup.form_error_body'),
        closeTimeout: 5
      })

    return validated
  }

  // eslint-disable-next-line max-params
  const saveForm = (e, button, sendForm, dataItemToSave) => {
    const progressBy = progressType(dataItemToSave.progress_by)

    const item = {
      [progressBy]: dataItemToSave[progressBy],
      progress_by: dataItemToSave.progress_by,
      crew_size: dataItemToSave.crew_size,
      crews: dataItemToSave.crews,
      date: dataItemToSave.date,
      [`${level}_id`]: dataItemToSave[`${level}_id`],
      working_hours: dataItemToSave.working_hours,
      comment: dataItemToSave.comment,
      drop_formula_id: dataItemToSave.drop_formula_id,
      drop_formula_variables: dataItemToSave.drop_formula_variables
    }

    if (validateForm(item)) sendForm(item)
  }

  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.cancel'),
      cancel: true,
      class: 'button-cancel'
    },
    !isEdition && {
      id: 2,
      text: I18n.t('actions.save_new'),
      onClick: saveForm,
      class: 'button-save',
      createNew: true,
      icon: <HiPlusCircle />
    },
    {
      id: 3,
      text: I18n.t('actions.save_exit'),
      onClick: saveForm,
      class: 'button-action',
      icon: <IoMdExit />
    }
  ]

  const renderColumn = (column) => <ColumnInput column={column} onChange={column.onChange} dataItem={dataItem} />

  return (
    <SidePanelFormWrapper
      title={I18n.t(`history_form.${isEdition ? 'edit' : 'create'}_title`)}
      subtitle={I18n.t(`history_form.subtitle`)}
      sections={sections}
      buttons={buttons}
      dataItem={dataItem}
      model={model}
      validateForm={validateForm}
      onClose={onClose}
      onCreateNew={resetDataItem}
    >
      <SectionedPanel
        sections={sections}
        enumerable={false}
        hideable={false}
        sectionable={false}
        showBorder={false}
        display
        renderColumn={renderColumn}
      />
    </SidePanelFormWrapper>
  )
}

HistoryForm.propTypes = {
  parentDataItem: PropTypes.oneOfType([PropTypes.object]),
  historyDataItem: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  level: PropTypes.string.isRequired
}

HistoryForm.defaultProps = {
  parentDataItem: {},
  historyDataItem: {}
}
