import React from 'react'
import PropTypes from 'prop-types'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import '/src/static/css/multidropdown.css'

export default function InputMultipleDrop({ inputProps }) {
  const { readOnly, textField, popupClassName, valueFormatter } = inputProps
  const { data, value, wrapperClass, inputClass, error, onChange, onFocus, onBlur, onFilterChange } = useInputDrop(
    inputProps
  )

  return (
    <div className={`multiple-drop ${wrapperClass}`}>
      <MultiSelect
        id={inputProps.id}
        className={inputClass()}
        data={data}
        value={valueFormatter ? value || [] : undefined}
        defaultValue={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={{
          className: `combobox-list multiple-drop ${popupClassName || ''}`
        }}
        filterable
        onFilterChange={onFilterChange}
        disabled={readOnly}
        textField={textField}
      />
      <div className="error-label">{error}</div>
    </div>
  )
}

InputMultipleDrop.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    readOnly: PropTypes.bool,
    textField: PropTypes.string,
    popupClassName: PropTypes.string,
    valueFormatter: PropTypes.func
  }).isRequired
}
