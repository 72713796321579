import React, { useState } from 'react'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import I18n from '/src/utils/translations'
import BusEvents from '/src/hooks/bus/bus_events'
import FormHeader from '/src/ui/core/forms/form_header'
import Modal from '/src/ui/core/popups/modal'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import ColumnInput from '/src/ui/core/inputs/column_input'
import useSendForm from '/src/ui/core/forms/send_form_hook'
import Button from '/src/ui/core/buttons/button'
import '/src/static/css/core/popups/modal.css'

/**
 * This is a wrapper for the modal component so it can be easily used as a form modal
 * @param modalTitle - string - optional
 * @param formTitle - string - optional
 * @param formSubtitle - string - optional
 * @param label - string - optional
 * @param sections - array of objects - mandatory
 * @param badges - array of badges - optional
 * @param buttons - array of objects - optional
 * @param dataItem - object representing the data to be sent
 * @param model - model containing data to configure form submit request
 * @param width - modal width (0 to 100 since it will be used as percentage of the screen)
 * @param height - modal height (0 to 100 since it will be used as percentage of the screen)
 * @param sectionable - if sections should have header and collapse functionality
 * @param closable - boolean to toggle X close button on the right top corner
 * @param onSuccess - callback function to be called on success - optional
 * @param onClose - callback function to be called on modal close - optional
 * @param validateForm - callback function that returns true if dataItem is valid and false otherwise
 * @param filterDataAttributes - callback function to filter data before send to API - optional
 * @return {[function(): *, function(): (null|*)]} [function to open modal,
 * function to render modal]
 */
// eslint-disable-next-line max-lines-per-function
export default function useFormModal({
  modalTitle,
  formTitle,
  formSubtitle,
  label,
  sections,
  badges,
  buttons,
  dataItem,
  model,
  width,
  height,
  sectionable,
  closable,
  onSuccess,
  validateForm,
  onClose,
  filterDataAttributes,
  className
}) {
  const [opened, setOpened] = useState(false)
  const action = dataItem && dataItem.id ? 'update' : 'create'
  const sendFormProps = { model, action, dataItem, hideLoadingDialog: true, onSuccess, filterDataAttributes }
  const [sendForm, loading] = useSendForm(sendFormProps)

  const openModal = () => setOpened(true)
  const closeModal = () => {
    setOpened(false)
    if (onClose) onClose()
  }

  useBus(
    BusEvents.FORM_SUCCESS,
    ({ customDispatchEvent }) => {
      closeModal()
      dispatch(customDispatchEvent ?? BusEvents.RELOAD_GRID)
    },
    []
  )

  useBus(
    BusEvents.FORM_SUBMIT,
    () => {
      dispatch(BusEvents.INPUT_VALIDATE)
    },
    []
  )

  const onClick = (e, button) => {
    if (button.cancel) closeModal()
    if (button.submit && (!validateForm || (validateForm && validateForm(dataItem)))) sendForm(dataItem)

    if (button.onClick) button.onClick(e, button, sendForm, dataItem)
  }

  const modalHeader = () => {
    if (!formTitle && !formSubtitle) return null
    return <FormHeader label={label} title={formTitle} subtitle={formSubtitle} badge={badges} />
  }

  const renderColumn = (column) => <ColumnInput column={column} onChange={column.onChange} dataItem={dataItem} />

  const modalButtons = () => {
    if (!buttons) return null
    return buttons.map((button) => (
      <Button
        type={button.submit ? 'submit' : 'button'}
        text={button.text}
        key={button.id}
        className={button.class}
        halfWidth={button.halfWidth}
        icon={button.icon}
        disabled={button.disabled}
        onClick={(e) => onClick(e, button)}
      />
    ))
  }

  const renderModal = () => {
    if (!opened) return null
    return (
      <Modal
        className={className}
        width={width}
        height={height}
        onClose={closeModal}
        title={modalTitle}
        closable={closable}
        header={modalHeader()}
        footer={modalButtons()}
        loading={{ status: loading, text: I18n.t('actions.saving') }}
      >
        <SectionedPanel
          sections={sections}
          enumerable
          hideable
          sectionable={sectionable}
          display
          renderColumn={renderColumn}
        />
      </Modal>
    )
  }

  return [openModal, renderModal]
}
