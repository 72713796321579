/* eslint-disable max-lines-per-function */
import { useEffect, useRef, useState, useCallback } from 'react'
import { useStore } from 'react-context-hook'
import { buildEquation, buildVariables } from '/src/models/concerns/formula'
import { objectEquals, isEmpty } from '/src/utils/object'
import useFetchAPI from '/src/hooks/api/fetch_api'
import buildFormulaItemObj from '/src/utils/build_formula_item_obj'

/**
 *  Hook designed watch form change and calculate formulas, formulas services and to update form fields visibilities
 */
export default function useFormulasCalculation({
  watch,
  formulas,
  eavColumns,
  fixedFields,
  includeOnForm,
  parentItems,
  dataItem
}) {
  const postEquation = useFetchAPI('formulas/eval_equations', true)
  const [variableTypes] = useStore('variable_types')
  const prevWatchFields = useRef()
  const prevParentItems = useRef()
  const [result, setResult] = useState({})

  const fieldsToWatch = [
    ...Object.keys(eavColumns).map((key) => eavColumns[key].description),
    ...(fixedFields ? fixedFields.map((dataItem) => dataItem.description) : [])
  ]

  const watchFields = watch(fieldsToWatch)

  const calculate = useCallback(
    (formulasArray) => {
      if (!formulasArray || isEmpty(formulasArray)) return
      const equations = {}

      const watchFormObject = watchFields.reduce((map, value, index) => {
        // eslint-disable-next-line no-param-reassign
        map[fieldsToWatch[index]] = value
        return map
      }, {})

      formulasArray.forEach((formula) => {
        const fieldsVariables = buildVariables(
          { ...formula, parents: parentItems },
          { ...watchFormObject, ...includeOnForm },
          variableTypes
        )
        const batchItem = dataItem ? buildFormulaItemObj(dataItem) : {}
        equations[formula.id] = [buildEquation(formula, fieldsVariables), batchItem]
      })

      const params = { requestAction: 'CREATE', httpAction: 'post', data: { formulas: equations } }
      postEquation.fetchAPI(params)
    },
    [fieldsToWatch, includeOnForm, parentItems, postEquation, variableTypes, watchFields]
  )

  useEffect(() => {
    if (postEquation.status !== 'SUCCESS') return
    setResult(postEquation.responseData)
  }, [postEquation.status, postEquation.responseData])

  useEffect(() => {
    if (!Object.keys(watchFields).length) return
    if (objectEquals(watchFields, prevWatchFields.current) && objectEquals(parentItems, prevParentItems.current)) return

    prevWatchFields.current = watchFields
    prevParentItems.current = parentItems
    calculate(formulas)
  }, [calculate, formulas, parentItems, watchFields])

  return result
}
