import { isBlank } from '/src/utils/boolean_refinements'
import useDatasheetFilters from '/src/hooks/datasheet_filters'
import { groupFilterColumnsByTag } from '/src/models/concerns/datasheet_filter'

/**
 * Custom Hook to treat datasheet filter fields
 * @return {[*, *[], function(*): *]} (formatted Columns)
 */
export default function useSetupDatasheetFilter(templateId, dataItem, onChange) {
  const {
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetColumns,
    datasheetTemplates,
    isLoading
  } = useDatasheetFilters(templateId)

  const hasDatasheetFiltersLoading = () => {
    if (isBlank(datasheetFilters)) return false
    if (Array.isArray(datasheetFilters) && datasheetFilters.length === 0) return false
    return !datasheetColumns
  }

  const createSectionsHashWithAllEavColumns = (sections) => {
    const sectionsHash = {}

    sectionsHash.allEavColumns = sections.reduce((memo, section) => {
      sectionsHash[section.id] = { ...section, eav_columns: [] }
      return [...memo, ...section.eav_columns]
    }, [])

    return sectionsHash
  }

  const setDatasheetFilterFields = (sectionsHash, formulasControlFields) => {
    const datasheetFilterColumns = []
    const sectionsHashCopy = { ...sectionsHash }
    const { allEavColumns } = sectionsHashCopy

    const mappedControlFields = {}
    formulasControlFields.forEach((formula) => {
      mappedControlFields[formula.eav_column_id] = {
        ...mappedControlFields[formula.eav_column_id],
        [formula.eav_column_field]: true
      }
    })

    allEavColumns.forEach((field) => {
      const { type, column_type: columnType } = field
      const typeDescription = columnType ? columnType.description : type

      const datasheetFilterTypes = ['datasheet_filter', 'multiple_datasheet_filter']
      if (datasheetFilterTypes.includes(typeDescription)) {
        const datasheet = datasheetFilters[field.id] || multipleDatasheetFilters[field.id]
        const controlField = mappedControlFields[field.id]
        const visibilityByFormula = controlField && controlField.visible_on_web
        datasheetFilterColumns.push({
          ...field,
          datasheet,
          visibility_by_formula: visibilityByFormula
        })
      } else {
        sectionsHashCopy[field.eav_section_id].eav_columns.push(field)
      }
    })

    sectionsHashCopy.datasheetFilterFields = groupFilterColumnsByTag({
      formattedColumns: datasheetFilterColumns,
      onChangeDatasheetFilter: onChange,
      datasheetTemplates,
      dataItem
    })
    return sectionsHashCopy
  }

  const isGroupedFilterSingle = (filter) => {
    const { filters } = filter
    return filters.length === 1 && filters[0].column_type.description === 'datasheet_filter'
  }

  const pushDatasheetFilterColumn = (filter, sectionsHash) => {
    const { filters } = filter

    const datasheetColumn = datasheetColumns[filters[0].datasheet.datasheet_column_id]
    const datasheetFilterColumn = filters[0]

    const singleDatasheetFilter = {
      ...datasheetFilterColumn,
      datasheetTemplateId: datasheetColumn.eav_template_id,
      datasheetColumn: datasheetColumn.description,
      column_type: { ...datasheetFilterColumn.column_type, single: true }
    }

    const sectionId = datasheetFilterColumn.eav_section_id
    if (sectionsHash[sectionId]) sectionsHash[sectionId].eav_columns.push(singleDatasheetFilter)
  }

  const setEavColumnsOnNewSections = (sectionsHash) => {
    const sectionsHashCopy = { ...sectionsHash }
    const { datasheetFilterFields } = sectionsHashCopy

    Object.values(datasheetFilterFields).forEach((filter) => {
      if (isGroupedFilterSingle(filter)) {
        pushDatasheetFilterColumn(filter, sectionsHashCopy)
        return
      }

      const datasheetFilterGrouped = {
        ...filter,
        position: filter.filters.sort((a, b) => a.position - b.position)[0].position
      }
      const { eav_section_id: sectionId } = datasheetFilterGrouped.filters[0]
      sectionsHashCopy[sectionId].eav_columns.push(datasheetFilterGrouped)
    })

    Object.values(sectionsHashCopy).forEach((section) => {
      if (sectionsHashCopy[section.id]) sectionsHashCopy[section.id].eav_columns.sort((a, b) => a.position - b.position)
    })
    return sectionsHashCopy
  }

  const treatDatasheetFilterColumns = (sections, formulasControlFields = []) => {
    let sectionsHash = createSectionsHashWithAllEavColumns(sections)
    sectionsHash = setDatasheetFilterFields(sectionsHash, formulasControlFields)
    sectionsHash = setEavColumnsOnNewSections(sectionsHash)

    delete sectionsHash.allEavColumns
    delete sectionsHash.datasheetFilterFields

    return Object.values(sectionsHash).reverse()
  }

  return [
    datasheetFilters,
    multipleDatasheetFilters,
    isLoading || hasDatasheetFiltersLoading(),
    treatDatasheetFilterColumns
  ]
}
