export function setLanguage(state) {
  if (state.language) localStorage.setItem('language', JSON.stringify(state.language))
}

export function getLanguage() {
  const initialStore = {}
  const item = localStorage.getItem('language')

  if (item) initialStore.language = JSON.parse(item)

  return initialStore
}
