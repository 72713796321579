import React from 'react'
import ProgressServiceForm from '/src/ui/domain/progress_services/progress_service_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import ProgressServiceModel from '/src/models/progress_service'

const progressServiceModel = new ProgressServiceModel()

export default function ProgressServiceFormModal() {
  return (
    <GridFormModal modelName={progressServiceModel.paramName}>
      <ProgressServiceForm />
    </GridFormModal>
  )
}
