import PropTypes from 'prop-types'

export default function GPSColumn({ value }) {
  return value
}

GPSColumn.propTypes = {
  value: PropTypes.string
}

GPSColumn.defaultProps = {
  value: null
}
