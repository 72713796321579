// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class OrderModel {
  constructor() {
    this.paramName = OrderModel.paramName
  }

  static paramName = 'order'

  name = I18n.t('orders.title')

  singularName = 'order'

  templateType = 'order'

  route = 'orders'
}
