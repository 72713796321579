import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import ProgressesTrackBlock from './progresses_track_block'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getGalleryTab, getInfoTab, getInspectionsTab } from '/src/ui/core/tabs/get_tabs'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFetch from '/src/hooks/api/fetch'
import InspectedServicesBlock from '/src/ui/domain/inspections/inspected_services_block'
import { hasInspectedServices } from '/src/models/inspection'
import '/src/static/css/model_side_panel.css'

export default function ProgressesSidePanel({
  dataItem,
  onClose,
  sections,
  contextMenuItems,
  sidePanelFields,
  template,
  inspection
}) {
  const { fetch } = useFetch()
  const [requestSections, loading] = useFetchSections(dataItem?.request?.eav_template_id, true)

  const [scopes, setScopes] = useState()
  const [scopeSection, setScopeSection] = useState()
  const showButtons = Boolean(contextMenuItems)

  useEffect(() => {
    const fetchData = async () => {
      if (!dataItem?.scope_id) return null

      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: dataItem.scope_id } }
      }

      const scopeResponse = await fetch('scopes', params)
      const scopeData = scopeResponse.data?.data[0]

      if (scopeData) {
        setScopes(scopeData)

        const eavTemplateId = scopeData.eav_template_id
        if (eavTemplateId) {
          const templateParams = {
            requestAction: 'READ',
            httpAction: 'get',
            additionalResource: { path: 'eav_columns' },
            query: { where: { eav_template_id: eavTemplateId } }
          }

          const sectionResponse = await fetch('eav_sections', templateParams)
          setScopeSection(sectionResponse.data)
        }
      }
    }

    fetchData()
  }, [dataItem?.scope_id, fetch])

  const buildSummary = () => ({
    component: (
      <ProgressesTrackBlock
        dataItem={dataItem}
        contextMenuItems={contextMenuItems}
        template={template}
        fields={sidePanelFields}
        request={dataItem?.request}
        showAttachments={showButtons}
      />
    ),
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  const buildInspectedServices = () => {
    return {
      component: <InspectedServicesBlock inspection={inspection} />,
      title: I18n.t('inspections.inspected_services'),
      type: 'track',
      key: 'inspected-services-block',
      visible: hasInspectedServices(inspection)
    }
  }

  const getBlocks = (blocks) => {
    const defaultBlocks = [...blocks]
    if (inspection) defaultBlocks.push(buildInspectedServices())

    if (!dataItem) return null
    if (!dataItem.request_id) return defaultBlocks
    return [buildSummary(), ...defaultBlocks]
  }

  const initialSectionBlocks = useSectionBlock({ sections, dataItem, openModal: showButtons })
  const infoBlocks = getBlocks(initialSectionBlocks)

  const infoTab = getInfoTab(infoBlocks, showButtons ? 'info' : 'preview_details')

  const galleryModules = [
    { moduleName: 'request', sections: requestSections, dataItem: dataItem?.request },
    { moduleName: 'scope', sections: scopeSection, dataItem: scopes },
    { moduleName: 'progress', sections, dataItem }
  ]

  const galleryTab = getGalleryTab({ modules: galleryModules, loading, showButtons })

  const inspectionsTab = getInspectionsTab(dataItem, 'progress', showButtons)

  const progressTabs = [infoTab, galleryTab, inspectionsTab]

  return (
    <div className="model-side-panel">
      {dataItem && (
        <BlocksSidePanel
          title={I18n.t('progresses.progresses_detail.side_panel_title')}
          closable={showButtons}
          onClose={onClose}
        >
          <SidePanelTabs tabs={progressTabs} />
        </BlocksSidePanel>
      )}
    </div>
  )
}

ProgressesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    cancel: PropTypes.oneOfType([PropTypes.object]).isRequired
  }),
  sidePanelFields: PropTypes.oneOfType([PropTypes.object]),
  template: PropTypes.oneOfType([PropTypes.object]),
  inspection: PropTypes.shape({
    inspected: PropTypes.shape({
      eav_template_id: PropTypes.number
    }),
    inspected_services: PropTypes.arrayOf(
      PropTypes.shape({
        service_number: PropTypes.number
      })
    ),
    inspected_type: PropTypes.string
  })
}

ProgressesSidePanel.defaultProps = {
  dataItem: undefined,
  sidePanelFields: undefined,
  contextMenuItems: undefined,
  template: undefined,
  inspection: undefined
}
