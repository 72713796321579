import { useState } from 'react'
import useDatasheetFilterColumns from '/src/hooks/datasheet_filter_columns'
import useSyncBatch from '/src/hooks/api/sync_batch'
import { templateType } from '/src/models/concerns/template_types'

export default function useDatasheetFilters(templateId, defaultObject, hasDefaultColumns) {
  const [datasheetFilters, setDatasheetFilters] = useState(defaultObject)
  const [multipleDatasheetFilters, setMultipleDatasheetFilters] = useState(defaultObject)
  const [templates, setTemplates] = useState(defaultObject)

  const queryFilters = { where: { eav_columns: { eav_template_id: templateId } } }
  const queryTemplates = { where: { template_type: templateType.datasheet } }

  const batchedEntities = {
    eav_datasheet_filters: {
      get: datasheetFilters,
      set: setDatasheetFilters,
      key: 'eav_column_id',
      query: queryFilters
    },
    multiple_eav_datasheet_filters: {
      get: multipleDatasheetFilters,
      set: setMultipleDatasheetFilters,
      key: 'eav_column_id',
      query: queryFilters
    },
    eav_templates: { get: templates, set: setTemplates, queryTemplates }
  }

  const { loading } = useSyncBatch(batchedEntities)

  const datasheetColumns = useDatasheetFilterColumns({
    datasheetFilters,
    multipleDatasheetFilters,
    hasDefaultColumns
  })

  return {
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates: templates,
    datasheetColumns,
    isLoading: loading
  }
}
