/**
 * convert size in bytes to 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB' or 'YB'
 *
 * @param {number} bytes The size in bytes.
 * @param {number} decimals How many decimal cases, default: 2
 *
 * formatBytes(5988);       // 5.85 KB
 * formatBytes('5988');     // 5.85 KB
 * formatBytes(59881931);   // 57.11 MB
 * formatBytes(1234, 3);    // 1.205 KB
 *
 * @return string, value concat unit measure
 */
export default function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
