import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputTitle from '/src/ui/core/inputs/input_title'
import { Switch } from '@progress/kendo-react-inputs'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/inputs.css'

export default function InputMiddleToggle({ inputProps, leftTitle, rightTitle, falseValue, trueValue }) {
  const { id, className, value, title, onChange, required, readOnly } = inputProps
  const [stringValue, setStringValue] = useState(value || falseValue)
  const [boolValue, setBoolValue] = useState(value === trueValue)
  const error = useInputError({ stringValue, title, required })

  const switchChange = (e) => {
    setBoolValue(e.target.value)
    setStringValue(e.target.value ? trueValue : falseValue)
    if (onChange) onChange(id, e.target.value ? trueValue : falseValue)
  }

  const inputClass = () => {
    const classes = ['switch']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <div className="middle-toggle-input">
      <InputTitle title={leftTitle} className="middle-toggle-title" />
      <div data-testid="middle-toggle-switch" className="middle-toggle-switch">
        <Switch id={id} checked={boolValue} onChange={switchChange} className={inputClass()} disabled={readOnly} />
      </div>
      <InputTitle title={rightTitle} className="middle-toggle-title" />
    </div>
  )
}

InputMiddleToggle.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  falseValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  trueValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

InputMiddleToggle.defaultProps = {
  leftTitle: '',
  rightTitle: '',
  falseValue: false,
  trueValue: true
}
