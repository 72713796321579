import lodash from 'lodash'
import { useStore } from 'react-context-hook'

export default function useFieldSettings(templateId, desiredSettings) {
  const { pick } = lodash

  const [fieldSettings] = useStore('field_settings')

  const filteredByTemplate = Object.values(fieldSettings).filter((settings) => {
    return settings.eav_template_id === templateId
  })

  const desiredSettingsByField = {}
  filteredByTemplate.forEach((settings) => {
    const selectedSettings = pick(settings, desiredSettings)
    if (Object.values(selectedSettings).some((setting) => setting))
      desiredSettingsByField[settings.field] = selectedSettings
  })

  return desiredSettingsByField
}
