import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import {
  disabledStatuses,
  scheduled,
  overdue,
  filterObject,
  notOverdueFilterObject,
  scheduledFilterObject,
  overdueFilterObject,
  pendingFilter,
  getFilteredStatusesNames
} from '/src/models/concerns/inspection_filter'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'

let parsedStatuses = []
let pendingId

const statusDescription = (status) => {
  const statusTitle = I18n.t(`inspections.statuses.${status.i18n_id}`)
  if (statusTitle.length <= 15) return statusTitle
  return `${statusTitle.substring(0, 15)}...`
}

const removeSelectedFilter = (filter, { column, operator }) => {
  return filter.filter((x) => x.column !== column || x.operator !== operator)
}

const addFilter = (filter, source) => {
  const newFilter = source.map((x) => x)
  newFilter.push(filter)
  return newFilter
}

const addFilters = (selectedStatus, currentFilter, pendingId) => {
  let newFilter = currentFilter.filterObjects
  if (selectedStatus.i18n_id === 'scheduled' && selectedStatus.filtered) {
    newFilter = addFilter(pendingFilter(pendingId), newFilter)
    newFilter = addFilter(scheduledFilterObject, newFilter)
    newFilter = addFilter(notOverdueFilterObject(), newFilter)
  }
  if (selectedStatus.i18n_id === 'overdue' && selectedStatus.filtered) {
    newFilter = addFilter(pendingFilter(pendingId), newFilter)
    newFilter = addFilter(overdueFilterObject(), newFilter)
  }
  if (currentFilter.newFilterIds.length > 0) newFilter = addFilter(filterObject(currentFilter.newFilterIds), newFilter)
  return newFilter
}

const removeFilters = (selectedStatus, filterIds, statuses, filter) => {
  let filterObjects = []
  let newFilterIds = filterIds.filter((item) => ![scheduled.id, overdue.id].includes(item))
  let newStatuses = statuses
  const selectedStatusId = selectedStatus.i18n_id
  if (!['scheduled', 'overdue'].includes(selectedStatusId))
    filterObjects = removeSelectedFilter(filter, filterObject(newFilterIds))
  if (filterIds.includes(scheduled.id) || filterIds.includes(overdue.id)) {
    newStatuses = statuses.map((s) => {
      if (selectedStatus.id !== s.id) return { ...s, filtered: false }
      return { ...s }
    })
    filterObjects = filter.filter(
      (x) => x.column !== 'inspect_status_id' && x.column !== 'recurrency_uuid' && x.column !== 'inspect_date'
    )
    if (['scheduled', 'overdue'].includes(selectedStatusId)) newFilterIds = []
  }
  return { filterObjects, newFilterIds, newStatuses }
}

export default function useInspectionStatusFilter(filter, onFilter, onSelectedOptionChange) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [inspectStatuses] = useStore('inspect_statuses')

  useEffect(() => {
    if (!Object.keys(inspectStatuses).length) return
    if (!onFilter || !onSelectedOptionChange) return
    const filtering = statuses.filter((x) => x.filtered)
    if (filtering.length) return

    const initialStatuses = localStorage.getItem('inspection_status_filter') || []

    parsedStatuses = Object.values(inspectStatuses)
    pendingId = parsedStatuses.find((item) => item.i18n_id === 'pending').id
    const statusList = parsedStatuses.map((s) => {
      return { ...s, filtered: initialStatuses.includes(s.i18n_id) }
    })
    statusList.filter((item) => disabledStatuses.includes(item.i18n_id))

    let newStatuses = [
      ...statusList,
      { ...scheduled, filtered: initialStatuses.includes('scheduled') },
      { ...overdue, filtered: initialStatuses.includes('overdue') }
    ]

    const initialFilters = newStatuses.filter((x) => x.filtered).map((s) => ({ i18n_id: s.i18n_id, id: s.id }))
    if (initialFilters && initialFilters.length) {
      onFilter(applyInitialFilters(initialFilters, filter))
      onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
    }

    setStatuses(newStatuses)
  }, [inspectStatuses])

  const applyInitialFilters = (initialFilters, filter) => {
    let newFilter

    if (initialFilters[0].i18n_id === 'scheduled') {
      newFilter = addFilter(pendingFilter(pendingId), filter)
      newFilter = addFilter(scheduledFilterObject, newFilter)
      newFilter = addFilter(notOverdueFilterObject(), newFilter)
    } else if (initialFilters[0].i18n_id === 'overdue') {
      newFilter = addFilter(pendingFilter(pendingId), filter)
      newFilter = addFilter(overdueFilterObject(), newFilter)
    } else {
      newFilter = addFilter(filterObject(initialFilters.map((s) => s.id)), filter)
    }

    return newFilter
  }

  const clearFilters = useCallback(() => {
    setPopupOpened(false)
    const newStatuses = statuses.map((s) => {
      return { ...s, filtered: false }
    })
    setStatuses(newStatuses)

    const newFilters = filter.filter(
      (x) => x.column !== 'inspect_status_id' && x.column !== 'recurrency_uuid' && x.column !== 'inspect_date'
    )
    onFilter(newFilters)
    localStorage.setItem('inspection_status_filter', [])
    onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
  }, [statuses, filter, onSelectedOptionChange])

  const changeFilter = useCallback(
    (selectedStatus) => {
      if (!popupOpened) return

      setPopupOpened(false)

      let newStatuses = statuses.map((x) => x)

      let { filtered } = selectedStatus
      filtered = !filtered
      newStatuses.filter((s) => selectedStatus.id === s.id).forEach((s) => (s.filtered = filtered))

      let filterIds = newStatuses.filter((x) => x.filtered).map((s) => s.id)

      if (!filterIds) return
      // Remove selected filters from grid filters
      let newFilter = removeFilters(selectedStatus, filterIds, statuses, filter)
      // Add selected filters to grid filters
      newStatuses = newFilter.newStatuses
      newFilter = addFilters(selectedStatus, newFilter, pendingId)
      const filterTranslations = newStatuses.filter((x) => x.filtered).map((x) => x.i18n_id)

      onFilter(newFilter)
      setStatuses(newStatuses)
      onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
      localStorage.setItem('inspection_status_filter', filterTranslations)
    },
    [popupOpened, statuses, onFilter, onSelectedOptionChange]
  )

  const filterIcon = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={statuses.filter((s) => s.id && !disabledStatuses.includes(s.i18n_id))}
        statusDescription={statusDescription}
        isStatusSelected={(status) => status.filtered}
        onChangeFilter={changeFilter}
        onClearFilters={clearFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [statuses, popupOpened, changeFilter, clearFilters]
  )

  return {
    filterIcon,
    clearFilters
  }
}
