import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/core/layouts/page_form.css'

export default function FormHeader({ title, subtitle, label, badge, inline, separator }) {
  const renderLabel = () => {
    if (!label) {
      return null
    }

    if (label.length > 50) {
      return (
        <CustomTooltip position="bottom" parentTitle>
          <span title={label}>
            <div className={inline ? 'label-filter' : 'form-wrapper-label overflow'}>{label}</div>
          </span>
        </CustomTooltip>
      )
    }

    return <div className={inline ? 'label-filter' : 'form-wrapper-label'}>{label}</div>
  }

  return (
    <div className="form-wrapper-header">
      <div className={inline ? 'form-title-badge-wrapper-inline' : 'form-title-badge-wrapper'}>
        {title && (
          <div className="form-wrapper-title" data-testid="form-title">
            {title}
          </div>
        )}
        {renderLabel()}
      </div>
      {subtitle && <div className="form-wrapper-subtitle">{subtitle}</div>}
      <div>
        {badge && (
          <span id="badge" className="badge">
            {badge}
          </span>
        )}
      </div>
      {separator && <div data-testid="line-separator" className="line-separator" />}
    </div>
  )
}

FormHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badge: PropTypes.element,
  inline: PropTypes.bool,
  separator: PropTypes.bool
}

FormHeader.defaultProps = {
  title: '',
  subtitle: '',
  label: undefined,
  badge: undefined,
  inline: false,
  separator: false
}
