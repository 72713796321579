import React from 'react'
import PropTypes from 'prop-types'
import { MdCheck, MdClose } from 'react-icons/md'
import I18n from '/src/utils/translations'

const classNameByStatus = (status) => (status.filtered ? 'status-selected' : 'status-not-selected')

export default function CircleStatusFilterPopup({
  statuses,
  statusDescription,
  isStatusSelected,
  setPopupOpened,
  onClearFilters,
  onChangeFilter,
  children
}) {
  const clearFilters = () => {
    setPopupOpened(false)
    onClearFilters()
  }

  const changeFilter = (status) => {
    setPopupOpened(false)
    onChangeFilter(status)
  }

  return (
    <React.Fragment>
      <div id="popup-status-filter" data-testid="popup-status-filter" className="popup-service-filter">
        {statuses &&
          statuses.map((status) => {
            return (
              <div key={status.id} className={classNameByStatus(status)}>
                <div className={`circle ${status.i18n_id.replaceAll('_', '-')}-circle`} />
                <span role="button" className="workflow-status status-label" onClick={() => changeFilter(status)}>
                  {statusDescription(status)}
                  {isStatusSelected(status) && <MdCheck />}
                </span>
              </div>
            )
          })}

        {children}
      </div>
      <button id="clear-filters" className="clear-filters clear-service-statuses" type="button" onClick={clearFilters}>
        <MdClose />
        {I18n.t(`grid.clear_filters`)}
      </button>
    </React.Fragment>
  )
}

CircleStatusFilterPopup.propTypes = {
  statuses: PropTypes.array,
  statusDescription: PropTypes.func,
  isStatusSelected: PropTypes.func,
  onClearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  setPopupOpened: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

CircleStatusFilterPopup.defaultProps = {
  statuses: [],
  statusDescription: (_) => '',
  isStatusSelected: (_) => false,
  onClearFilters: () => {},
  onChangeFilter: () => {},
  setPopupOpened: (_) => {},
  children: <div />
}
