import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { FaRegCalendar } from 'react-icons/fa'
import { MdComment, MdDelete } from 'react-icons/md'
import I18n from '/src/utils/translations'
import { formatDate } from '/src/utils/project_formatter'
import { isScaffoldingDismantled } from '/src/utils/constants/scaffolding'
import ScaffoldingPartTotalColumn from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { duplicateModalMenuItem, editModalMenuItem } from '/src/ui/core/grid/context_menu_entries'
import GridRowContextMenu from '/src/ui/core/grid/grid_row_context_menu'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import useScaffoldingConfirmationPopup from '/src/ui/domain/scaffoldings/scaffolding_confirmation_popup'
import ActionTimestampBadges from '/src/ui/domain/scaffoldings/action_timestamp_badges'
import ScaffoldingPartDimensionsColumn from '/src/ui/domain/scaffoldings/scaffolding_dimensions_column'
import '/src/static/css/domain/scaffoldings/scaffolding_part_action_card.css'

export default function ScaffoldingPartActionCard({ action }) {

  const [project] = useStore('project')
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const showActionConfirmation = useScaffoldingConfirmationPopup(showConfirmation)
  const [scaffolding] = useStore('selected_scaffolding')
  const [statuses] = useStore('scaffolding_statuses')

  const showDimensions = action.modification_type != 'total_dismantle'

  const dismantled = useMemo(() => isScaffoldingDismantled(statuses, scaffolding), [statuses, scaffolding])

  const actionParams = (opts) => {
    return {
      resourcePath: 'scaffolding_part_actions',
      scaffoldingAction: opts.scaffoldingAction || 'UPDATE',
      httpAction: opts.httpAction || 'put',
      resourceId: opts.id,
      data: {
        scaffolding_status_id: opts.scaffolding_status_id,
        ...opts.extraData
      }
    }
  }

  const rowActions = [
    editModalMenuItem(
      () => true,
      () => {
        dispatch({
          type: BusEvents.OPEN_SIDE_PANEL_FORM,
          payload: action,
          formType: 'edit'
        })
      }
    ),
    duplicateModalMenuItem(
      () => true,
      () => {
        dispatch({
          type: BusEvents.OPEN_SIDE_PANEL_FORM,
          payload: action,
          formType: 'duplicate'
        })
      }
    ),
    {
      text: I18n.t('actions.delete'),
      icon: <MdDelete />,
      onClick: (_, dataItem) => {
        showActionConfirmation(dataItem, actionParams({ ...dataItem, httpAction: 'delete' }), {
          actionName: 'delete'
        })
      }
    }
  ]

  return (
    <React.Fragment>
      <div className="action-card">
        <div className="marker" />
        <div className="action-card-info">
          <div className="item-line">
            <FaRegCalendar />
            {formatDate(action.date, project)}
            <ActionTimestampBadges action={action} />
            {renderConfirmation()}
            <div className="grid-row-context" data-testid="row-context-action">
              {!dismantled && <GridRowContextMenu dataItem={action} data={[]} items={rowActions} isTableData={false} />}
            </div>
          </div>
          <div className="item-line">
            <MdComment />
            <CustomTooltip position="bottom" parentTitle>
              <span title={action.comment}>
                <div className="action-comment">{action.comment ? action.comment : I18n.t('fields.na')}</div>
              </span>
            </CustomTooltip>
          </div>
          {showDimensions && (
            <div className="info-blocks">
              <ScaffoldingPartDimensionsColumn action={action} />
            </div>
          )}
          <div className="info-blocks">
            <div className="column-block">
              <div className="column-title">{I18n.t('scaffolding_part_actions.card.total')}</div>
              <div className="column-value">
                <ScaffoldingPartTotalColumn dataItem={action} />
              </div>
            </div>
            <div className="column-block">
              <div className="column-title">{I18n.t(`scaffolding_part_actions.card.${action.action_type}`)}</div>
              <div className="column-value">{I18n.t(`scaffolding_part_actions.card.${action.modification_type}`)}</div>
            </div>
            {action.creator_name && (
              <div className="column-block">
                <div className="column-title">{I18n.t('scaffolding_part_actions.card.created_by')}</div>
                <div className="column-value">{action.creator_name}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ScaffoldingPartActionCard.propTypes = {
  action: PropTypes.oneOfType([PropTypes.object]).isRequired
}
