// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { isBlank } from '/src/utils/boolean_refinements'

export function getEstimateServicesByEstimate(estimateServices) {
  return estimateServices.reduce((acc, service) => {
    const estimateId = service.estimate_id
    if (!acc[estimateId]) acc[estimateId] = []
    acc[estimateId].push(service)
    return acc
  }, {})
}

export default class EstimateServiceModel {
  constructor() {
    this.paramName = EstimateServiceModel.paramName
    this.singularName = EstimateServiceModel.singularName
    this.route = EstimateServiceModel.route
  }

  static paramName = 'estimate_service'

  static singularName = I18n.t('estimate_services.description')

  static route = 'estimate_services'

  name = I18n.t('estimate_services.title')

  url = 'estimate_services'

  flexible = true

  parentModels = ['scope', 'contract_service', 'service_price', 'estimate', 'estimate.request']

  initialColumns = [
    {
      id: 1,
      description: 'request_id',
      field: 'request_id',
      title: 'DPMSID',
      editable: true,
      hideOnForm: true,
      hideOnSubGrid: true,
      filterable: false,
      sortable: false,
      width: 200,
      type: 'estimate_service_scope_id',
      filterSteps: [
        {
          title: I18n.t('requests.description'),
          filterAttribute: 'request_id',
          required: true,
          multiple: false,
          distinct: false,
          keyField: 'id',
          queryType: 'where',
          searchExtraQuery: {
            where: {
              '[request_statuses][i18n_id]': ['confirmed', 'in_scoping', 'clustered']
            }
          },
          searchRoute: 'requests',
          searchOperator: 'containsOr',
          searchFields: ['id', 'reason', 'discipline', 'comments'],
          textDisplayFields: ['id', 'reason', 'discipline', 'comments']
        },
        {
          title: I18n.t('scopes.description'),
          keyField: 'id',
          filterAttribute: 'scope_id',
          required: true,
          multiple: false,
          distinct: false,
          searchRoute: 'scopes',
          searchFields: ['number'],
          searchOperator: 'containsOr'
        }
      ]
    },
    {
      id: 25,
      description: 'request.reason',
      title: I18n.t('requests.reason'),
      forceUseDescription: true,
      hideOnForm: true,
      hideOnSubGrid: true,
      editable: false,
      filterable: false,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem.request.reason),
      width: 200,
      type: 'string'
    },
    {
      id: 26,
      description: 'request.comments',
      title: I18n.t('requests.comments'),
      forceUseDescription: true,
      hideOnForm: true,
      hideOnSubGrid: true,
      editable: false,
      filterable: false,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem.request.reason),
      width: 200,
      type: 'string'
    },
    {
      id: 2,
      title: I18n.t('estimate_services.item_no'),
      description: 'scope',
      field: 'scope.number',
      type: 'estimate_service_scope_id',
      editable: true,
      width: 200,
      hideOnSubGrid: true,
      filterable: false,
      sortable: false,
      required: true,
      hideOnForm: true,
      filterSteps: [
        {
          title: I18n.t('requests.description'),
          filterAttribute: 'request_id',
          required: true,
          multiple: false,
          distinct: false,
          keyField: 'id',
          queryType: 'where',
          searchExtraQuery: {
            where: {
              '[request_statuses][i18n_id]': ['confirmed', 'in_scoping', 'clustered']
            }
          },
          searchRoute: 'requests',
          searchOperator: 'containsOr',
          searchFields: ['id', 'reason', 'comments'],
          textDisplayFields: ['id', 'reason', 'comments']
        },
        {
          title: I18n.t('scopes.description'),
          keyField: 'id',
          filterAttribute: 'scope_id',
          required: true,
          multiple: false,
          distinct: false,
          searchRoute: 'scopes',
          searchFields: ['number'],
          searchOperator: 'containsOr'
        }
      ]
    },
    {
      id: 23,
      description: 'number',
      title: I18n.t('estimate_services.service_no'),
      editable: false,
      hideOnForm: true,
      filterable: false,
      sortable: false,
      width: 200,
      type: 'integer'
    },
    {
      id: 3,
      description: 'description',
      title: I18n.t('estimate_services.description'),
      editable: false,
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'text'
    },
    {
      id: 4,
      description: 'estimate',
      title: I18n.t('estimate_services.estimate'),
      foreignKey: 'estimates',
      foreignAttribute: 'estimate_id',
      hideOnGrid: true,
      hideOnForm: true,
      type: 'integer',
      width: 200
    },
    {
      id: 5,
      description: 'contract_service',
      field: 'contract_service.description',
      title: I18n.t('estimate_services.contract_service'),
      foreignKey: 'contract_services',
      foreignAttribute: 'contract_service_id',
      editable: false,
      required: true,
      type: 'contract_service',
      width: 200
    },
    {
      id: 6,
      description: 'service_package',
      field: 'service_package.description',
      title: I18n.t('estimate_services.service_package'),
      foreignKey: 'service_package',
      foreignAttribute: 'service_package_id',
      filterable: true,
      sortable: false,
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 24,
      description: 'flag',
      field: 'flag',
      hideOnForm: true,
      title: I18n.t('estimate_services.flag'),
      readOnly: true,
      width: 200,
      filterable: false,
      type: 'string'
    },
    {
      id: 7,
      description: 'discipline',
      title: I18n.t('estimate_services.discipline'),
      foreignKey: 'disciplines',
      foreignAttribute: 'discipline_id',
      field: 'discipline.description',
      filterable: true,
      sortable: false,
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 22,
      description: 'service_description',
      field: 'service_description',
      title: I18n.t('service_description'),
      readOnly: true,
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 8,
      description: 'factor',
      title: I18n.t('estimate_services.factor'),
      readOnly: true,
      hideOnForm: true,
      width: 200,
      type: 'numeric'
    },
    {
      id: 9,
      description: 'quantity',
      title: I18n.t('estimate_services.quantity'),
      validation: {
        required: true
      },
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 10,
      description: 'weight',
      title: I18n.t('estimate_services.weight'),
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'numeric'
    },
    {
      id: 11,
      description: 'application_price',
      title: I18n.t('estimate_services.application_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 12,
      description: 'materials_price',
      title: I18n.t('estimate_services.materials_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 13,
      description: 'equipment_price',
      title: I18n.t('estimate_services.equipment_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 14,
      description: 'other_price',
      title: I18n.t('estimate_services.other_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 15,
      description: 'norm_hours',
      title: I18n.t('estimate_services.norm_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 16,
      description: 'team_target_hours',
      title: I18n.t('estimate_services.team_target_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 17,
      description: 'budget_target_hours',
      title: I18n.t('estimate_services.budget_target_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 18,
      description: 'working_hours',
      title: I18n.t('estimate_services.working_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 19,
      description: 'crews',
      title: I18n.t('estimate_services.crews'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 20,
      description: 'crew_size',
      title: I18n.t('estimate_services.crew_size'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 21,
      description: 'unit',
      hideOnForm: true,
      title: I18n.t('estimate_services.unit'),
      filterable: false,
      sortable: false,
      width: 200,
      type: 'text'
    }
  ]

  columns = includeDateColumns(this.initialColumns)
}
