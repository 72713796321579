import React from 'react'
import PropTypes from 'prop-types'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import ColumnCommentIcon from '/src/ui/core/icons/column_comment_icon'
import InformationTooltip from '/src/ui/core/tooltips/information_tooltip'

export default function InputTitle({
  title,
  htmlFor,
  required,
  children,
  className,
  information,
  commentAllowed,
  flexibleColumnsControls
}) {
  if (!title) return null

  return (
    <label className={`column-label ${className}`} htmlFor={htmlFor}>
      <div className="column-label-first-row">
        <CustomTooltip position="auto">
          <div className="column-label__title--size">
            <div title={title} className={`column-label-title ${className}`}>
              <RequiredMark required={required} />
              {title}
            </div>
          </div>
        </CustomTooltip>
        {information && <InformationTooltip title={information} />}
        {commentAllowed && (
          <ColumnCommentIcon
            title={title}
            description={htmlFor}
            flexibleColumnsControls={flexibleColumnsControls}
          />
        )}
      </div>
      {children}
    </label>
  )
}

InputTitle.propTypes = {
  title: PropTypes.string,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  information: PropTypes.string,
  commentAllowed: PropTypes.bool,
  flexibleColumnsControls: PropTypes.shape({
    setValue: PropTypes.func,
    getValues: PropTypes.func
  })
}

InputTitle.defaultProps = {
  title: '',
  htmlFor: '',
  required: false,
  children: undefined,
  className: '',
  information: '',
  commentAllowed: false,
  flexibleColumnsControls: undefined
}
