import React from 'react'
import PropTypes from 'prop-types'
/* eslint-disable import/no-unresolved */
import I18n from '/src/utils/translations'
import DateColumn from '/src/ui/core/grid/flexible_column/date'

const InfoColumn = ({
  reason,
  discipline,
  requestDate,
  requestEndDate,
  kaeferEnergyPriority,
  prioritySequence,
  maintenancePlant,
  area,
  subarea
}) => {
  const infoRows = [
    {
      label: I18n.t('estimate.pdf_header.work_order'),
      value: reason,
      type: 'text'
    },
    {
      label: I18n.t('estimate.pdf_header.order_start'),
      value: <DateColumn value={requestDate} />,
      type: 'date'
    },
    {
      label: I18n.t('estimate.pdf_header.order_finish'),
      value: <DateColumn value={requestEndDate} />,
      type: 'date'
    },
    {
      label: I18n.t('estimate.pdf_header.ke_priority'),
      value: kaeferEnergyPriority,
      type: 'text'
    }
  ]

  const additionalInfoRows = [
    {
      label: I18n.t('estimate.pdf_header.discipline'),
      value: discipline,
      type: 'text'
    },
    {
      label: I18n.t('estimate.pdf_header.priority_sequence'),
      value: prioritySequence,
      type: 'text'
    },
    {
      label: I18n.t('estimate.pdf_header.maintenance_plant'),
      value: maintenancePlant,
      type: 'text'
    },
    {
      label: I18n.t('estimate.pdf_header.main_area_location'),
      value: `${area} ${subarea ? `- ${subarea}` : ''}`,
      type: 'text'
    }
  ]

  return (
    <>
      <div className="estimates-pdf-header__header-info__info-column">
        {infoRows.map((row, index) => (
          <div key={index} className="estimates-pdf-header__header-info__info-column__row">
            <strong className="estimates-pdf-header__header-info__info-column__row__label">{row.label}</strong>
            <span className="estimates-pdf-header__header-info__info-column__row__value">{row.value}</span>
          </div>
        ))}
      </div>

      <div className="estimates-pdf-header__header-info__info-column">
        {additionalInfoRows.map((row, index) => (
          <div key={index} className="estimates-pdf-header__header-info__info-column__row">
            <strong className="estimates-pdf-header__header-info__info-column__row__label">{row.label}</strong>
            <span className="estimates-pdf-header__header-info__info-column__row__value">{row.value}</span>
          </div>
        ))}
      </div>
    </>
  )
}

InfoColumn.propTypes = {
  reason: PropTypes.string,
  discipline: PropTypes.string,
  requestDate: PropTypes.string,
  requestEndDate: PropTypes.string,
  kaeferEnergyPriority: PropTypes.string,
  prioritySequence: PropTypes.number,
  maintenancePlant: PropTypes.string,
  area: PropTypes.string,
  subarea: PropTypes.string
}

export default InfoColumn
