import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { isJson } from '/src/utils/string'
import InputDrop from '/src/ui/core/inputs/input_drop'
import InputSearch from '/src/ui/core/inputs/input_search'
import { filterModules } from '/src/utils/inspection_template_filtering'
import { ContainsOperator } from '/src/models/concerns/filter_operators'

export default function ModalSearchInput({ index, values, inputProps, inspectionDetail }) {
  if (!inputProps || !inputProps.type || index !== inputProps.index) return null
  
  const { type, index: newIndex, metadata } = inputProps

  if (type === 'search') {
    const isScaffolding = values?.inspected_type === 'Scaffolding'
    const prefix = isScaffolding ? 'TAG #' : ''
  
    const searchProps = (newIndex === 4 && !isScaffolding)
      ? { ...inputProps, searchOperator: ContainsOperator }
      : inputProps

    return <InputSearch key={newIndex} inputProps={searchProps} prefix={prefix} />
  }

  if (type === 'drop') {
    const options = isJson(metadata) ? JSON.parse(metadata) : null
    const filteredOptions = newIndex === 0 && inspectionDetail.inspection_modules?.length > 0
        ? filterModules(options, inspectionDetail)
        : options

    return <InputDrop key={newIndex} inputProps={{ ...inputProps, options: filteredOptions }} />
  }

  return <b>{I18n.t('notifications.warning')}</b>
}

ModalSearchInput.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  inputProps: PropTypes.oneOfType([PropTypes.object]),
  inspectionDetail: PropTypes.oneOfType([PropTypes.object]).isRequired
}

ModalSearchInput.defaultProps = {
  inputProps: undefined
}
