import lodash from 'lodash'
import { camelize, pluralize, singularize } from 'inflected'
import { formatNumberToFloatByProject, normalizeDateToString } from '/src/utils/project_formatter'
import forbiddenTypesOnGrid from '/src/ui/core/grid/forbidden_types'
import { DATASHEET_FILTER_TYPES, DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import { byString, isEmpty } from '/src/utils/object'
import {
  BetweenOperator,
  IsEqualOperator,
  ContainsOperator,
  IsNotEqualOperator
} from '/src/models/concerns/filter_operators'
import { isJson } from '/src/utils/string'
import { isPresent } from '/src/utils/boolean_refinements'
import I18n from '/src/utils/translations'

export const eavColumnToKendoType = (column) => {
  const kendoTypes = ['text', 'numeric', 'boolean', 'date']
  const numericTypes = ['integer', 'decimal']
  const dateTypes = ['date', 'date_time']
  const textTypes = ['string']

  const { type } = column
  const columnType = { ...column.column_type }

  if (type) {
    if (kendoTypes.includes(type)) return type
    if (dateTypes.includes(type)) return 'date'
    if (numericTypes.includes(type)) return 'numeric'
    if (textTypes.includes(type)) return 'text'
  }

  if (columnType) {
    if (kendoTypes.includes(columnType.description)) return columnType.description
    if (dateTypes.includes(columnType.description)) return 'date'
    if (numericTypes.includes(columnType.description)) return 'numeric'
    if (textTypes.includes(columnType.description)) return 'text'
  }

  return 'text'
}

export const columnType = (column) => {
  return column.column_type ? column.column_type.description : column.type
}

const FORMULA_TYPES = ['formula', 'drop_formula']

export const filterFormulaColumns = (columns = []) =>
  columns
    .filter((column) => FORMULA_TYPES.includes(columnType(column)) || column.formula_id)
    .map((column) => column.description)

export const filterVisibleOnWeb = (columns = []) => {
  return columns.filter((column) => column.visible_on_web)
}

export const datasheetFilterVisibleOnWeb = (filters) => {
  return filters[0] && filters[0].visible_on_web
}

export const bypassVisibleOnWeb = (column) => {
  if (
    column.column_type &&
    column.column_type.description &&
    (column.column_type.description === 'drop_formula_id' ||
      column.column_type.description === 'drop_formula_variables' ||
      column.column_type.description === 'matrix_dimension')
  )
    return true
  return false
}

export const parseDefaultValue = (column, project) => {
  const defaultValue = column.default

  if (typeof defaultValue === 'function') return defaultValue()

  if (columnType(column) === 'decimal') return formatNumberToFloatByProject(defaultValue, project)
  else if (columnType(column) === 'date') return normalizeDateToString(new Date())
  else if (columnType(column) === 'date_time') return new Date().toISOString()

  return defaultValue
}

export const filterAllowedEavColumns = (flexibleColumns, templateId) => {
  const { uniqBy } = lodash
  const flexibleColumnsFiltered = uniqBy(
    flexibleColumns.filter((item) => item.eav_template_id === templateId),
    'id'
  )

  if (!flexibleColumnsFiltered || flexibleColumnsFiltered.length === 0) return []

  return flexibleColumnsFiltered
    .filter((fc) => {
      if (!fc.column_type) return false
      const type = fc.column_type.description
      return !forbiddenTypesOnGrid.includes(type)
    })
    .map((col) => ({ ...col, flexible: true }))
}

export const applyForeignMetadata = (columns, batchedEntities) => {
  if (batchedEntities === undefined) return columns
  const newColumns = [...columns]

  return newColumns.map((column) => {
    const foreignKeyParsed = column.foreignKey && camelize(pluralize(column.foreignKey), false)

    if (column.foreignKey && DROP_COLUMN_TYPES.includes(column.type) && batchedEntities[foreignKeyParsed]) {
      column.metadata = JSON.stringify(
        Object.values(batchedEntities[foreignKeyParsed]).map((value) => value.description)
      )
    }

    return column
  })
}

export const applyDatasheetFilterInfo = (columns, filterColumns, filterInfos) => {
  if (!filterColumns || !filterInfos || isEmpty(filterColumns) || isEmpty(filterInfos)) return columns
  return (
    columns &&
    columns.map((column) => {
      if (filterInfos[column.id]) {
        const filterInfo = filterInfos[column.id]
        delete filterInfo.id
        filterInfo['datasheetColumn'] = filterColumns[filterInfo.datasheet_column_id].description
        return { ...column, ...filterInfo }
      } else {
        return column
      }
    })
  )
}

/**
 * This function is used to get the real sort field for a given fixed column that
 * defines its own `onSort` function at a model.
 */

export const getSortDescription = (column, columns) => {
  const gridColumn = columns.find((c) => c.description === column.field || c.field === column.field)
  return gridColumn.onSort && gridColumn.onSort(columns) && gridColumn.onSort(columns).description
}

export const isDatasheetFilter = (column) => {
  return DATASHEET_FILTER_TYPES.includes(columnType(column))
}

export const hasInputOnBulkEdit = (column) => {
  const cellColumnType = columnType(column)

  return (
    cellColumnType !== 'multiple_datasheet_filter' &&
    cellColumnType !== 'estimate_service_scope_id' &&
    !(cellColumnType === 'datasheet_filter' && column.column_type && !column.column_type.single)
  )
}

// TODO: This is necessary just to disable the "Is not equal" filter operator
// in a date column with datetime values. This feature will be fully implemented
// in a future user story
export const getFilterOperators = (defaultOperators, column) => {
  if (!defaultOperators) return []

  if (column.filterOperators)
    return defaultOperators.filter((filter) => column.filterOperators.includes(filter.operator))

  const type = columnType(column)

  let filterOperators = defaultOperators.filter(({ text }) => text)

  switch (type) {
    case 'boolean':
      filterOperators = filterOperators.filter(({ operator }) => operator === IsEqualOperator)
      break
    case 'date':
    case 'datetime':
      filterOperators = filterOperators
        .filter(({ operator }) => operator !== ContainsOperator)
        .map(({ text, operator }) =>
          operator === IsEqualOperator ? { text, operator: ContainsOperator } : { text, operator }
        )
      break
    default:
      filterOperators = filterOperators.filter(({ operator }) => operator !== BetweenOperator)

      if (DROP_COLUMN_TYPES.includes(type) || type === 'datasheet_filter') {
        const eqOperator = filterOperators.find(({ operator }) => operator === 'eq')
        filterOperators = [eqOperator, ...filterOperators.filter(({ operator }) => operator !== 'eq')]
      }
  }

  const neqUnableColumns = [
    'norm_hours',
    'team_target_hours',
    'budget_target_hours',
    'request.reason',
    'request.comments'
  ]

  if (column.flexible || type === 'datetime' || neqUnableColumns.includes(column.field)) {
    return filterOperators.filter((op) => op.operator !== IsNotEqualOperator)
  }

  return filterOperators
}

export const columnValue = (column, dataItem) => {
  if (column.fieldFormatter) return column.fieldFormatter(dataItem)
  if (column.forceUseDescription) return byString(dataItem, column.description)
  const columnKey =
    column.field || (column.foreignKey ? `${singularize(column.foreignKey)}.${column.textField}` : column.description)
  return byString(dataItem, columnKey)
}

export const rangeNumericMessage = (min, max) => {
  if (isPresent(min) && isPresent(max)) {
    return I18n.t('form.inputs.numeric.between', {
      min,
      max
    })
  } else if (isPresent(min)) {
    return I18n.t('form.inputs.numeric.greater', {
      min
    })
  } else if (isPresent(max)) {
    return I18n.t('form.inputs.numeric.lower', {
      max
    })
  }
  return ''
}

export const isNumericInputWithMinMax = (column) => {
  const range = isJson(column.metadata) ? JSON.parse(column.metadata) : {}

  return (
    column &&
    column.column_type &&
    column.column_type.description &&
    ['integer', 'decimal'].includes(column.column_type.description) &&
    rangeNumericMessage(range.min_value, range.max_value)
  )
}

export const getColumnsByTemplate = (columns, callback) => {
  return columns.reduce((acc, column) => {
    let updatedColumn = (callback && callback(column)) || { ...column }

    if (typeof updatedColumn !== 'object') updatedColumn = { ...column }

    const templateId = updatedColumn.eav_template_id
    if (!acc[templateId]) acc[templateId] = []
    acc[templateId].push(updatedColumn)
    return acc
  }, {})
}

export const sortColumnsByPosition = (columns) => {
  return columns.sort((a, b) => a.position - b.position)
}
