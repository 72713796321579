import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatPercentageWithPrecision } from '/src/utils/project_formatter'

export default function PercentageColumn({ value, columnData }) {
  const [project] = useStore('project')
  const result = formatPercentageWithPrecision(value, columnData.number_of_digits, project)

  return result
}

PercentageColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnData: PropTypes.shape({
    number_of_digits: PropTypes.number.isRequired
  }).isRequired
}

PercentageColumn.defaultProps = {
  value: null
}
