import { useMemo } from 'react'
import { useStore } from 'react-context-hook'

export default function useAccessSolutionDisciplines() {
  const [disciplines] = useStore('disciplines')

  const disciplinesFiltered = useMemo(
    () =>
      disciplines &&
      Object.values(disciplines).filter(
        (discipline) => discipline.category && discipline.category.toLowerCase() === 'access_solution'
      ),
    [disciplines]
  )

  return disciplinesFiltered
}
