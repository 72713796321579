import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { IoCubeOutline } from 'react-icons/io5'
import I18n from '/src/utils/translations'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import ScaffoldingTypeFilterPopup from '/src/ui/core/popups/scaffolding_type_filter_popup'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function ScaffoldingTypeFilterIcon({
  labelClassName,
  types,
  typeDescription,
  isTypeSelected,
  onClearFilters,
  onChangeFilter,
  popupOpened,
  setPopupOpened,
  children
}) {
  const popupButtonRef = useRef(null)

  return (
    <CustomTooltip position="bottom" parentTitle>
      <PopupAnchored
        body={
          <ScaffoldingTypeFilterPopup
            labelClassName={labelClassName}
            types={types}
            typeDescription={typeDescription}
            isTypeSelected={isTypeSelected}
            setPopupOpened={setPopupOpened}
            onClearFilters={onClearFilters}
            onChangeFilter={onChangeFilter}
          >
            {children}
          </ScaffoldingTypeFilterPopup>
        }
        popupButtonRef={popupButtonRef}
        forceOpen={popupOpened}
        setForceOpen={setPopupOpened}
        onClick={() => setPopupOpened(!popupOpened)}
      >
        <button
          id="open-filter-popup-button"
          data-testid="type-filter-button"
          type="button"
          title={I18n.t('scaffolding_parts.type_filter')}
          className={`${popupOpened ? 'btn-icon active' : 'btn-icon'} grid-header-icon`}
          ref={popupButtonRef}
        >
          <IoCubeOutline />
        </button>
      </PopupAnchored>
    </CustomTooltip>
  )
}

ScaffoldingTypeFilterIcon.propTypes = {
  labelClassName: PropTypes.string,
  types: PropTypes.oneOfType([PropTypes.array]),
  typeDescription: PropTypes.func,
  isTypeSelected: PropTypes.func,
  onClearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  popupOpened: PropTypes.bool,
  setPopupOpened: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

ScaffoldingTypeFilterIcon.defaultProps = {
  labelClassName: '',
  types: [],
  typeDescription: () => '',
  isTypeSelected: () => false,
  onClearFilters: () => {},
  onChangeFilter: () => {},
  popupOpened: false,
  setPopupOpened: () => {},
  children: null
}
