import { useState } from 'react'
import { getSortDescription } from '/src/models/concerns/eav_column'

export default function useSorting(sort, onChange) {
  const [sorting, setSorting] = useState(sort)

  const onSortChange = (event, gridColumns) => {
    const newSort = []
    event.sort.forEach((sortColumn) => {
      const realSort = getSortDescription(sortColumn, gridColumns)
      newSort.push({ ...sortColumn, realSort })
    })
    if (onChange) onChange(newSort)
    setSorting(() => newSort)
  }
  return { sorting, onSortChange }
}
