export function filterInspectionTemplatesByModule(templates, module) {
  const filteredTemplates = templates.filter((template) => {
    const inspectionDetail = template.inspection_detail
    if (!inspectionDetail?.inspection_modules?.length) return true
    if (inspectionDetail.inspection_modules.includes(module)) return true
    return false
  })

  return filteredTemplates
}

function getDisciplineIds(items, module, scaffoldingDisciplines) {
  if (module === 'Request') return items.map((item) => item.discipline_id)
  if (module === 'Scaffolding') return scaffoldingDisciplines.map((item) => item.id)
  return items.map((item) => item.request.discipline_id )
}

// eslint-disable-next-line max-params
export function filterInspectionTemplatesByDiscipline(
  templates,
  { dataItem },
  inspectedType,
  generalDiscipline,
  scaffoldingDisciplines
) {
  const items = 'items' in dataItem ? dataItem.items : [dataItem]
  const disciplines = getDisciplineIds(items, inspectedType, scaffoldingDisciplines)
  const uniqueDisciplines = [...new Set(disciplines)]
  
  const filteredTemplates = templates.filter((template) => {
    const detail = template.inspection_detail
    if (!detail?.inspection_disciplines?.length) return true

    const setDisciplines = detail.inspection_disciplines
    if (generalDiscipline && setDisciplines.includes(generalDiscipline.id)) return true

    if (inspectedType === 'Scaffolding') {
      const isAnyDisciplineIncluded = uniqueDisciplines.some((discipline) => setDisciplines.includes(discipline))
      if (isAnyDisciplineIncluded) return true
    }

    const areAllDisciplinesIncluded = uniqueDisciplines.every((discipline) => setDisciplines.includes(discipline))
    if (areAllDisciplinesIncluded) return true
    return false
  })

  return filteredTemplates
}

export function filterModules(modules, inspectionDetail) {
  return modules.filter((module) => inspectionDetail.inspection_modules.includes(module.id))
}
