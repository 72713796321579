import I18n from '/src/utils/translations'
import { notifyWarning, notifySuccess } from '/src/ui/core/dialogs/notifications'
import { isFormulaServiceHidden } from '/src/models/concerns/formula'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'

export const ALLOWED_COLUMN_TYPES = [
  'boolean',
  'date',
  'decimal',
  'integer',
  'string',
  'drop',
  'percentage',
  'time',
  'lookup',
  'link',
  'datasheet_filter',
  'multiple_datasheet_filter',
  'search',
  'cascade_drop',
  'multiline_text',
  'date_time',
  'estimate_service_scope_id'
]

export const onSave = (status, quantity) => {
  if (status === 'error') {
    return notifyWarning({
      title: I18n.t('notification.error'),
      body: I18n.t('grid.editable.update.error', { quantity })
    })
  }

  if (status === 'success') {
    return notifySuccess({
      title: I18n.t('notification.success'),
      body: I18n.t('grid.editable.update.success', { quantity })
    })
  }

  return null
}

export const attributesToRemove = (columns) => {
  const propertiesToRemove = ['dirty', 'inEdit', 'inError', 'inputProps', 'virtualKey', 'requiredAndEmpty']

  const foreignAttributeToRemove = columns
    .filter((column) => column.foreignAttribute && column.foreignAttribute !== column.description)
    .map((column) => column.description)

  return [...propertiesToRemove, ...foreignAttributeToRemove]
}

export const isColumnVisible = (column, fieldSettings, formulasServices) => {
  const setting = fieldSettings[column.foreignAttribute] || fieldSettings[column.description] || {}
  const hideOnForm = !(typeof column.hideOnForm === 'function') && column.hideOnForm
  const columnVisible = formulasServices ? formulasServices[`${column.description}_visible`] : true
  const hideFormulaColumn = columnVisible !== undefined ? !columnVisible : false
  const isHidden =
    hideFormulaColumn ||
    column.hideOnGrid ||
    setting.hide_on_grid ||
    hideOnForm ||
    column.hide ||
    isFormulaServiceHidden(column.description, formulasServices)

  return column.required || !isHidden
}

const isUndefinedValues = (value1, value2) => {
  if (value1 === null && value2 === undefined) return true
  if (value1 === undefined && value2 === null) return true
  return false
}

export const isItemEdited = (originalItem, newItem, fieldInEdit) => {
  if (!originalItem && !newItem) return false
  if (!originalItem || !newItem) return true

  const differentProperties = Object.keys(originalItem).filter((key) => {
    if (typeof originalItem[key] === 'object' && originalItem[key] !== null) return false
    if (isUndefinedValues(originalItem[key], newItem[key])) return false

    return originalItem[key] !== newItem[key]
  })

  const hasDifferences = differentProperties.length > 0
  const isFieldInEditDifferent =
    fieldInEdit &&
    !isUndefinedValues(originalItem[fieldInEdit], newItem[fieldInEdit]) &&
    originalItem[fieldInEdit] !== newItem[fieldInEdit]

  return hasDifferences || isFieldInEditDifferent
}

const isDataItemEquals = (item1, item2) => {
  if (isBlank(item1.id) && isBlank(item2.id)) return item1.virtualKey === item2.virtualKey
  return item1.id === item2.id
}

export const onCellChange = (cell, setNewDataSource) => {
  const { dataItem, field, foreignAttributeValue } = cell

  switch (field) {
    case 'area.description':
      setNewDataSource((prevDataSource) => {
        return prevDataSource.map((item) =>
          item.id === dataItem.id
            ? {
                ...item,
                subarea: null,
                subarea_id: null
              }
            : item
        )
      })
      break
    case 'main_work_order_erect_id':
      setNewDataSource((prevDataSource) => {
        return prevDataSource.map((item) =>
          isDataItemEquals(item, dataItem) ? { ...item, main_work_order_erect: foreignAttributeValue } : item
        )
      })
      break
    case 'main_work_order_dismantle_id':
      setNewDataSource((prevDataSource) => {
        return prevDataSource.map((item) =>
          isDataItemEquals(item, dataItem) ? { ...item, main_work_order_dismantle: foreignAttributeValue } : item
        )
      })
      break
    case 'actual_progress':
      setNewDataSource((prevDataSource) =>
        prevDataSource.map((item) => {
          if (!isDataItemEquals(item, dataItem)) return item

          const summary = item.progress_summary || {}
          const actualProgress = item.actual_progress

          return {
            ...item,
            progress_summary: { ...summary, actual_progress: actualProgress }
          }
        })
      )
      break
    case 'progress_service_summary.actual_progress':
      setNewDataSource((prevDataSource) =>
        prevDataSource.map((item) => {
          if (!isDataItemEquals(item, dataItem)) return item

          const summary = item.progress_service_summary || {}
          const actualProgress = item.actual_progress

          return {
            ...item,
            progress_service_summary: { ...summary, actual_progress: actualProgress }
          }
        })
      )
      break
  }
}

export const isItemColumnRequired = (columnDescription, columns, dataItem) =>
  columns
    .filter(({ required }) => (typeof required === 'function' ? required(dataItem) : required))
    .map(({ description }) => description)
    .includes(columnDescription)

const getItemMissingColumnsDescriptions = (item, columns, isRequiredColumnEmpty) =>
  columns.filter((column) => isRequiredColumnEmpty(column, item, true)).map(({ description }) => description)

export const getDataSourceMissingRequiredFields = ({ dataSource, columns, getDataItemId, isRequiredColumnEmpty }) =>
  dataSource.reduce(
    (currentObject, item) => ({
      ...currentObject,
      [getDataItemId(item)]: getItemMissingColumnsDescriptions(item, columns, isRequiredColumnEmpty)
    }),
    {}
  )

export const isColumnRequiredByFormula = (column, formulasControlFields) =>
  isPresent(
    formulasControlFields?.find(
      ({ eav_column_field: field, eav_column_id: columnId }) => field === 'required' && columnId === column?.id
    )
  )
