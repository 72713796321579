import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ScaffoldingTypeColumn({ column, value }) {
  const translationKey = value && value.i18n_id
  if (!translationKey) return ''
  return (
    <span className="align-cells-left icon-cell">
      <img className="column-icon" src={`/static/svg/${column.icon(translationKey)}.svg`} />
      <span className="icon-cell-description">{I18n.t(`scaffolding_parts.scaffolding_types.${translationKey}`)}</span>
    </span>
  )
}

ScaffoldingTypeColumn.propTypes = {
  value: PropTypes.shape({
    i18n_id: PropTypes.string
  })
}

ScaffoldingTypeColumn.defaultProps = {
  value: {}
}
