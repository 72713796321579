import React from 'react'
import PropTypes from 'prop-types'
import Card from '/src/ui/core/cards/card'

export default function CardList({ blocksCollection }) {
  const inputClass = (className) => {
    const classes = ['border-top']
    if (className) classes.push(className)
    return classes.join(' ')
  }

  const buildCard = (blocks) => {
    const { key, tag, title, subtitle, details, height, className, labels } = blocks
    return (
      <Card
        tag={tag}
        title={title}
        subtitle={subtitle}
        details={details}
        height={height}
        className={inputClass(className)}
        key={key}
        labels={labels}
      />
    )
  }

  return blocksCollection.map(buildCard)
}

CardList.propTypes = {
  blocksCollection: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.element,
      title: PropTypes.element,
      body: PropTypes.element,
      subtitle: PropTypes.element,
      details: PropTypes.element,
      height: PropTypes.number,
      className: PropTypes.string
    })
  ).isRequired
}
