import React from 'react'
import PropTypes from 'prop-types'
import InputSearch from '/src/ui/core/inputs/input_search'
import { ContainsOperator } from '/src/models/concerns/filter_operators'

export default function InputSingleDatasheetFilter({ inputProps }) {
  const { datasheetTemplateId, datasheetColumn } = inputProps

  return (
    <InputSearch
      inputProps={{
        ...inputProps,
        searchRoute: 'datasheets',
        searchFields: [datasheetColumn],
        textDisplayFields: [datasheetColumn],
        keyField: datasheetColumn,
        searchExtraQuery: {
          where: { eav_template_id: datasheetTemplateId },
          distinct: [datasheetColumn]
        },
        searchOperator: ContainsOperator,
        distinct: true,
        popupSettingsWidth: 'none'
      }}
    />
  )
}

InputSingleDatasheetFilter.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    dependency: PropTypes.string,
    columnId: PropTypes.number
  }).isRequired
}
