import React, { useState, useEffect } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import PropTypes from 'prop-types'
import usePollingAPI, { pollingStatus } from '/src/hooks/api/polling_api'
import '/src/static/css/dialogs.css'

export default function ServiceDialog({ title, message, serviceDialogProps }) {
  const { jobId, onFinishedJob, onJobError } = serviceDialogProps

  const polling = usePollingAPI()

  const [serviceProgress, setServiceProgress] = useState(0)

  useEffect(() => {
    polling.startPolling(jobId)
  }, [])

  useEffect(() => {
    if (polling.response === undefined) return
    if (polling.response.data && polling.response.data.length === 0) return
    setServiceProgress(polling.response.data[0].job_progress)
  }, [polling.response])

  useEffect(() => {
    if (polling.fetchStatus === 'ERROR') onJobError()
    if (polling.fetchStatus !== 'SUCCESS') return

    if (polling.status === pollingStatus.ERROR) onJobError()
    if (polling.status === pollingStatus.SUCCESS) onFinishedJob(polling.response.data[0])
  }, [polling.fetchStatus, polling.status])

  return (
    <Dialog
      title={title}
      width={420}
      height={220}
      style={{
        zIndex: '20000'
      }}
      closeIcon={false}
    >
      <div className="importation-progress-area">
        <div className="importation-progress-header"></div>
        <div data-testid="progress-bar-component" className="colored-progress-bar-wrapper">
          <ProgressBar max={100} value={serviceProgress} labelVisible={false} />
          <span className="progress_value">{serviceProgress}%</span>
        </div>
      </div>
      <p className="dialog-message" data-testid="service-dialog-rendered">
        {message}
      </p>
    </Dialog>
  )
}

ServiceDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  serviceDialogProps: PropTypes.shape({
    jobId: PropTypes.number,
    onFinishedJob: PropTypes.func,
    onJobError: PropTypes.func
  })
}

ServiceDialog.defaultProps = {
  serviceDialogProps: {
    jobId: null,
    onFinishedJob: () => {},
    onJobError: () => {}
  }
}
