// return if object has parent_id property
// E.g.: has_parent({ parent_id: 10 })
//       true
export default function hasParent(object) {
  if ('parent_id' in object && object.parent_id) {
    return true
  }

  return false
}
