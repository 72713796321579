import React from 'react'
import PropTypes from 'prop-types'
import { store } from 'react-context-hook'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import ScaffoldingTypeColumn from '/src/ui/domain/scaffoldings/scaffolding_type_column'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { scaffoldingTotalUnit as scaffoldingPartTotalUnit } from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'
import '/src/static/css/domain/scaffoldings/badges.css'

const { columns } = new ScaffoldingPartModel()
const typeColumn = columns.find((c) => c.description === 'scaffolding_type_id')

const scaffoldingItemTotalUnity = (item) => {
  if (item?.total_m3 > 0) return [item?.total_m3, 'm³']
  if (item?.total_m2 > 0) return [item?.total_m2, 'm²']
  return [item?.total_m, 'm']
}

export function ScaffoldingTypeBadge({ scaffoldingType }) {
  return (
    <div className="scaffolding-type-badge" data-testid="scaffolding-type-badge">
      <ScaffoldingTypeColumn column={typeColumn} value={scaffoldingType} />
    </div>
  )
}

export function TotalDimensionBadge({ total, unit }) {
  const project = store.get('project') || {}

  return (
    <div className="total-dimension-badge" data-testid="total-dimension-badge">
      <img src="/static/svg/scaffolding-measuring-tape.svg" alt="scaffolding-measuring-tape" />
      {`${formatNumberWithPrecision(total, project)} ${unit}`}
    </div>
  )
}

export function ItemBadges({ scaffoldingItem }) {
  const [total, unit] = scaffoldingItemTotalUnity(scaffoldingItem)

  return (
    <div className="scaffolding-item-badges" data-testid="scaffolding-item-badges">
      <TotalDimensionBadge total={total} unit={unit} />
    </div>
  )
}

export function PartBadges({ scaffoldingPartItem }) {
  return (
    <div className="scaffolding-part-badges" data-testid="scaffolding-part-badges">
      <ScaffoldingTypeBadge scaffoldingType={scaffoldingPartItem.scaffolding_type} />
      <TotalDimensionBadge total={scaffoldingPartItem.total} unit={scaffoldingPartTotalUnit(scaffoldingPartItem)} />
    </div>
  )
}

ScaffoldingTypeBadge.propTypes = {
  scaffoldingType: PropTypes.oneOfType([PropTypes.object]).isRequired
}

TotalDimensionBadge.propTypes = {
  total: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired
}

ItemBadges.propTypes = {
  scaffoldingItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}

PartBadges.propTypes = {
  scaffoldingPartItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
