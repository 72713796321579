import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdOutlineAddCircle } from 'react-icons/md'
import { Dialog } from '@progress/kendo-react-dialogs'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import { MEDIUM_POPUP_HEIGHT, MEDIUM_POPUP_WIDTH } from '/src/utils/constants/popups'
import { isBlank } from '/src/utils/boolean_refinements'
import ColumnsBatchAssignerForm from '/src/ui/core/popups/columns_batch_assigner/columns_batch_assigner_form'
import ColumnsBatchAssignerActions from '/src/ui/core/popups/columns_batch_assigner/columns_batch_assigner_actions'
import useSearchDatasheetFilters from '/src/ui/core/grid/search_datasheet_filters'
import useDatasheetFiltersByTag from '/src/ui/core/grid/editable/datasheet_filters_by_tag'
import { isDatasheetFilter } from '/src/models/concerns/eav_column'
import useFetch from '/src/hooks/api/fetch'
import Colors from '/src/utils/constants/colors'
import { flattenObject } from '/src/utils/object'
import '/src/static/css/dialogs.css'
import '/src/static/css/core/popups/columns_batch_assigner.css'

const ALLOWED_COLUMN_TYPES = [
  'boolean',
  'date',
  'decimal',
  'integer',
  'string',
  'drop',
  'multiple_drop',
  'percentage',
  'multiline_text',
  'datasheet_filter',
  'multiple_datasheet_filter'
]

export default function ColumnsBatchAssigner({
  isOpen,
  onClose,
  eavTemplateId,
  selectedRecords,
  modelRoute,
  onSuccessfulUpdate,
  modelName
}) {
  const [eavColumns, setEavColumns] = useState()

  // Stores the assigned value for each column as hash, where the
  // key is the columns's description and the value is the assigned value.
  const [columnValuePairs, setColumnValuePairs] = useState({})
  const [isAddingAction, setIsAddingAction] = useState(true)
  const [selectedColumn, setSelectedColumn] = useState()
  const [selectedColumnValue, setSelectedColumnValue] = useState()
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const { fetch } = useFetch()

  const [
    isLoadingDatasheetFilters,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns
  ] = useSearchDatasheetFilters(eavTemplateId)

  const filtersByTag = useDatasheetFiltersByTag({
    columns: eavColumns,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns
  })

  const hasNoAction = Object.keys(columnValuePairs).length === 0
  const isFormOpened = isAddingAction || selectedColumn || hasNoAction

  const displaySuccessMessage = () => {
    const singleSuccessKey = 'dialogs.columns_batch_assigner.single_success'
    const multipleSuccessKey = 'dialogs.columns_batch_assigner.multiple_success'

    const body =
      selectedRecords.length > 1
        ? I18n.t(multipleSuccessKey, { numberOfItems: selectedRecords.length })
        : I18n.t(singleSuccessKey)

    notifySuccess(body)
    dispatch({
      type: BusEvents.SELECTED_ROWS,
      triggeredModelName: 'ALL_MODELS',
      payload: []
    })
  }

  const addOrEditColumnValuePair = (eavColumn, valueToAssign) => {
    setSelectedColumn()
    setSelectedColumnValue()
    setIsAddingAction(false)

    setColumnValuePairs((currentColumnValuePairs) => {
      const updatedColumnValuePairs = { ...currentColumnValuePairs }

      if (isDatasheetFilter(eavColumn) && eavColumn.metadata) {
        // always get the first column of the datasheetfilter group of columns
        const datasheetDescription = filtersByTag[eavColumn.metadata].filters[0].description

        updatedColumnValuePairs[datasheetDescription] = valueToAssign ?? null
        return updatedColumnValuePairs
      }

      updatedColumnValuePairs[eavColumn.description] = valueToAssign ?? null
      return updatedColumnValuePairs
    })
  }

  const sendBatchUpdate = async () => {
    const flattenedValuePairs = flattenObject(columnValuePairs)
    const formattedData = selectedRecords.map((record) => ({
      id: record.id,
      subproject_id: record.subproject_id,
      eav_template_id: record.eav_template_id,
      ...flattenedValuePairs
    }))

    const updateInBatchArgs = {
      httpAction: 'post',
      data: { items: formattedData }
    }

    setIsLoadingSave(true)
    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    await fetch(`${modelRoute}/save_in_batch`, updateInBatchArgs, { useReject: true })
      .then(() => {
        setIsLoadingSave(false)

        displaySuccessMessage()
        onSuccessfulUpdate()

        dispatch(BusEvents.HIDE_DIALOG)
      })
      .catch(() => {
        setIsLoadingSave(false)
        dispatch(BusEvents.HIDE_DIALOG)
      })
  }

  const onSelectAction = (column, columnValue) => {
    setSelectedColumn(column)
    setSelectedColumnValue(columnValue)
  }

  const onDeleteAction = (column) => {
    setSelectedColumn()
    setSelectedColumnValue()
    setIsAddingAction(false)

    if (!column) return

    setColumnValuePairs((prevPairs) => {
      const newPairs = { ...prevPairs }
      delete newPairs[column.description]
      return newPairs
    })
  }

  useEffect(() => {
    const fetchEavColumns = async () => {
      const params = { requestAction: 'READ', httpAction: 'get' }
      const query = { where: { eav_template_id: eavTemplateId } }
  
      const { data: allEavColumns } = await fetch('eav_columns', { ...params, query })
  
      const filteredEavColumns = allEavColumns.data.filter((eavColumn) => 
        ALLOWED_COLUMN_TYPES.includes(eavColumn.column_type.description) &&
        (modelName !== 'Planning' || eavColumn.visible_on_planning)
      )

      setEavColumns(filteredEavColumns)
    }

    fetchEavColumns()
  }, [fetch, eavTemplateId, modelName])

  return (
    <React.Fragment>
      {isOpen && (
        <Dialog
          className="columns-batch-assigner"
          title={I18n.t('dialogs.columns_batch_assigner.header')}
          closeIcon
          onClose={onClose}
          width={MEDIUM_POPUP_WIDTH}
          height={MEDIUM_POPUP_HEIGHT}
        >
          <div className="title">
            <span>{I18n.t('dialogs.columns_batch_assigner.title')}</span>
          </div>
          <div className="subtitle">
            <span>
              {I18n.t('dialogs.columns_batch_assigner.subtitle', {
                numberOfColumns: Object.keys(columnValuePairs).length
              })}
            </span>
          </div>
          {isFormOpened ? (
            <ColumnsBatchAssignerForm
              columns={isLoadingDatasheetFilters ? undefined : eavColumns}
              selectedColumn={selectedColumn}
              selectedColumnValue={selectedColumnValue}
              onSelectColumn={setSelectedColumn}
              onValueEdition={setSelectedColumnValue}
              onSaveAction={addOrEditColumnValuePair}
              onDeleteAction={hasNoAction ? undefined : onDeleteAction}
              datasheetFiltersByTag={filtersByTag}
            />
          ) : (
            <React.Fragment>
              <div className="actions-header">
                <span>{I18n.t('dialogs.columns_batch_assigner.actions')}</span>
              </div>
              <ColumnsBatchAssignerActions
                columns={eavColumns}
                columnValuePairs={columnValuePairs}
                onSelectAction={onSelectAction}
                onDeleteAction={onDeleteAction}
              />
              <div className="columns-batch-assigner__buttons">
                {!isFormOpened && (
                  <div className="columns-batch-assigner__add-action" onClick={() => setIsAddingAction(true)}>
                    <MdOutlineAddCircle color={Colors.PRIMARY_BLUE} size={20} />
                    <span>{I18n.t('dialogs.columns_batch_assigner.add_action')}</span>
                  </div>
                )}
                <button
                  className="update-button"
                  disabled={isLoadingSave || (selectedColumn && isBlank(columnValuePairs[selectedColumn]))}
                  onClick={sendBatchUpdate}
                  type="button"
                >
                  {I18n.t('dialogs.columns_batch_assigner.update')}
                </button>
              </div>
            </React.Fragment>
          )}
        </Dialog>
      )}
    </React.Fragment>
  )
}

ColumnsBatchAssigner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccessfulUpdate: PropTypes.func,
  eavTemplateId: PropTypes.number,
  selectedRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  modelRoute: PropTypes.string.isRequired,
  modelName: PropTypes.string
}

ColumnsBatchAssigner.defaultProps = {
  onClose: () => {},
  onSuccessfulUpdate: () => {},
  eavTemplateId: null,
  modelName: ''
}
