import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import ScheduleSidePanelBlockDateGroup from './schedule_side_panel_block_date_group'
import '/src/static/css/domain/requests/schedule_side_panel_block.css'

export default function ScheduleSidePanelBlock({ dataItem }) {
  return (
    <React.Fragment>
      <ScheduleSidePanelBlockDateGroup
        dataItem={dataItem}
        entity="request"
        title={I18n.t('requests.schedule_form.request')}
      />
      <ScheduleSidePanelBlockDateGroup
        dataItem={dataItem}
        entity="scope"
        title={I18n.t('requests.schedule_form.scoping')}
      />
      <ScheduleSidePanelBlockDateGroup
        dataItem={dataItem}
        entity="progress"
        title={I18n.t('requests.schedule_form.performance')}
      />
    </React.Fragment>
  )
}

ScheduleSidePanelBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
