import React from 'react'
import PropTypes from 'prop-types'

export default function PlanningChartBlockBullet({ name, value, iconClassName, nameAppend }) {
  return (
    <div className="bullet-wrapper">
      <span className={iconClassName} />
      <div className="bullet-data">
        <span className="bullet-value">
          {value}
          <span className="bullet-unit">{nameAppend}</span>
        </span>
        <span className="bullet-name">{name}</span>
      </div>
    </div>
  )
}

PlanningChartBlockBullet.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconClassName: PropTypes.string,
  nameAppend: PropTypes.string
}

PlanningChartBlockBullet.defaultProps = {
  iconClassName: '',
  nameAppend: ''
}
