import React from 'react'
import PropTypes from 'prop-types'
import ScopeCellFactory from '/src/ui/domain/scopes/scope_cell_factory'
import RequestCellFactory from '/src/ui/domain/requests/request_cell_factory'
import InspectionCellFactory from '/src/ui/domain/inspections/inspection_cell_factory'
import ScopingServiceCellFactory from '/src/ui/domain/estimate_services/scoping_service_cell_factory'
import ProgressServiceCellFactory from '/src/ui/domain/progress_services/progress_service_cell_factory'
import ProgressCellFactory from '/src/ui/domain/progresses/progress_cell_factory'
import DatasheetCellFactory from '/src/ui/domain/datasheets/datasheet_cell_factory'
import ScaffoldingCellFactory from '/src/ui/domain/scaffoldings/scaffolding_cell_factory'
import ScaffoldingPartCellFactory from '/src/ui/domain/scaffoldings/scaffolding_part_cell_factory'
import RetrievingCell from '/src/ui/core/grid/retrieving_cell'

export default function CellFactory({ type, field, dataItem, value, columns, opts }) {
  const factoryTypes = {
    request: () => <RequestCellFactory field={field} dataItem={dataItem} value={value} />,
    scope: () => <ScopeCellFactory field={field} dataItem={dataItem} />,
    inspection: () => <InspectionCellFactory field={field} dataItem={dataItem} value={value} />,
    progress: () => <ProgressCellFactory field={field} dataItem={dataItem} columns={columns} />,
    datasheet: () => <DatasheetCellFactory field={field} dataItem={dataItem} />,
    progress_service: () => (
      <ProgressServiceCellFactory field={field} dataItem={dataItem} columns={columns} opts={opts} />
    ),
    estimate_service: () => <ScopingServiceCellFactory field={field} dataItem={dataItem} value={value} />,
    scaffolding: () => <ScaffoldingCellFactory field={field} dataItem={dataItem} value={value} />,
    scaffolding_part: () => <ScaffoldingPartCellFactory field={field} dataItem={dataItem} value={value} />,
    default: () => null
  }
  const column = columns.find((c) => c.description === field)

  if (column && column.isRetrieving && column.isRetrieving(dataItem)) return <RetrievingCell />
  if (Object.keys(factoryTypes).includes(type)) return factoryTypes[type]()
  return factoryTypes.default()
}

CellFactory.propTypes = {
  type: PropTypes.string.isRequired,
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
    PropTypes.shape()
  ]),
  columns: PropTypes.arrayOf(PropTypes.object),
  opts: PropTypes.oneOfType([PropTypes.object])
}

CellFactory.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined,
  columns: [],
  opts: {}
}
