import { useMemo, useCallback, useRef, useEffect } from 'react'
import { pluralize } from 'inflected'
import useSyncBatch from '/src/hooks/api/sync_batch'
import { isEmpty, objectEquals } from '/src/utils/object'
import { NON_CONCATENABLE_ENTITIES } from '/src/models/concerns/formula'

const getBatchEntityIds = (entity, parentsIds, parentsWatched) => {
  return [...new Set(parentsIds[entity])].filter((id) => !parentsWatched[entity] || !parentsWatched[entity][id])
}

const getBatchEntity = ({ entity, entityIds, parentsWatchedRef }) => {
  const query = { where: { id: entityIds } }
  const get = () => parentsWatchedRef.current[entity]
  const set = (newValue) => {
    const parentsRefCopy = parentsWatchedRef
    if (NON_CONCATENABLE_ENTITIES.includes(entity)) {
      parentsRefCopy.current = {
        ...parentsWatchedRef.current,
        [entity]: newValue
      }
    } else {
      parentsRefCopy.current = {
        ...parentsWatchedRef.current,
        [entity]: { ...(parentsWatchedRef.current[entity] || {}), ...newValue }
      }
    }
  }

  return { get, set, query }
}

const emptyBatchEntities = {}

/**
 * Fetches all the parent items for the desired items
 *
 * @param items - Array of items (The items used to fetch it's parents)
 * @param parentModels - Array of Strings (The parents that will be fetched)
 * @return parentItemss - Object - The parent of items with their fields
 *
 * Ex:
 * > useWatchParentsBatch([{ id: 1, scope_id: 1, ... }], ['scope', 'estimate.request'])
 * {
 *   'scope': [{ id: 1, description: 'My Scope' }],
 *   'request': [{ id: 2, reason: 'My Reason', test_integer: 666 }]
 * }
 */

export default function useWatchParentsBatch(items = [], parentModels = []) {
  const parentModelsRef = useRef(parentModels)

  const parentsWatchedRef = useRef({})
  const parentIdsRef = useRef({})

  const [parents, grandparents] = useMemo(
    () => [
      parentModelsRef.current.filter((parent) => !parent.includes('.')),
      parentModelsRef.current.filter((parent) => parent.includes('.'))
    ],
    []
  )

  const getBatchParents = useCallback(
    (parentIds) => {
      if (!parents || !parents.length) return {}

      return parents.reduce((prevBatchEntities, entity) => {
        const entityIds = getBatchEntityIds(entity, parentIds, parentsWatchedRef.current)

        if (!entityIds.length) return prevBatchEntities

        return {
          ...prevBatchEntities,
          [pluralize(entity)]: getBatchEntity({
            entity,
            entityIds,
            parentsWatchedRef
          })
        }
      }, {})
    },
    [parents]
  )

  const getBatchGrandparents = useCallback(
    (grandparentIds) => {
      if (!grandparents || !grandparents.length) return {}

      return grandparents.reduce((prevBatchEntities, grandparent) => {
        const [entityKey, parentKey] = grandparent.split('.')
        if (!parentsWatchedRef.current[entityKey]) return prevBatchEntities

        const entityIds = getBatchEntityIds(
          parentKey,
          { [parentKey]: grandparentIds[grandparent] },
          parentsWatchedRef.current
        )

        if (!entityIds.length) return prevBatchEntities

        return {
          ...prevBatchEntities,
          [pluralize(parentKey)]: getBatchEntity({
            entity: parentKey,
            entityIds,
            parentsWatchedRef
          })
        }
      }, {})
    },
    [grandparents]
  )

  const { loading: grandparentsLoading, syncBatch: syncBatchGrandparents } = useSyncBatch(emptyBatchEntities)

  const fetchGrandparents = useCallback(() => {
    if (!grandparents || !grandparents.length) return

    const grandparentIds = grandparents.reduce((currentGrandparentIds, grandparent) => {
      const [entityKey, parentKey] = grandparent.split('.')
      if (!parentsWatchedRef.current[entityKey]) return currentGrandparentIds

      return {
        ...currentGrandparentIds,
        [grandparent]: Object.values(parentsWatchedRef.current[entityKey])
          .map((item) => item[`${parentKey}_id`])
          .filter(Number)
      }
    }, {})

    if (Object.keys(grandparentIds).length === 0) return

    syncBatchGrandparents(getBatchGrandparents(grandparentIds))
  }, [syncBatchGrandparents, getBatchGrandparents, grandparents])

  const { loading: parentsLoading, syncBatch: syncBatchParents } = useSyncBatch(emptyBatchEntities, fetchGrandparents)

  useEffect(() => {
    const parentIds = parents.reduce(
      (currentEntitiesIds, entity) => ({
        ...currentEntitiesIds,
        [entity]: items.map((item) => item[`${entity}_id`]).filter(Number)
      }),
      {}
    )

    if (objectEquals(parentIds, parentIdsRef.current)) return

    parentIdsRef.current = parentIds

    if (Object.keys(parentIds).length === 0) return

    syncBatchParents(getBatchParents(parentIds))
  }, [syncBatchParents, getBatchParents, items, parents])

  const isLoading = () => {
    if (parents.length === 0 && grandparents.length === 0) return false
    if (isEmpty(parentsWatchedRef.current)) return parentsLoading || grandparentsLoading
    return false
  }

  return isLoading() ? undefined : parentsWatchedRef.current
}
