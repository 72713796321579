import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { DragAndDrop } from '@progress/kendo-react-common'
import DraggableListItem from '/src/ui/core/layouts/draggable_list_item'
import DroppableListItem from '/src/ui/core/layouts/droppable_list_item'

export default function ReorderableList({ data, onItemRender, idField, onSetList }) {
  const [draggedId, setDraggedId] = useState()

  const onReorder = useCallback(
    (droppedId) => {
      const newData = [...data]

      const draggedIndex = newData.findIndex((item) => item[idField] === draggedId)
      const droppedIndex = newData.findIndex((item) => item[idField] === droppedId)

      const droppedItem = newData[droppedIndex]
      const draggedItem = newData[draggedIndex]

      newData[droppedIndex] = draggedItem
      newData[draggedIndex] = droppedItem

      onSetList(newData)
      setDraggedId()
    },
    [data, draggedId, idField, onSetList]
  )

  return (
    <DragAndDrop>
      {data?.map((item, index) => (
        <DroppableListItem key={item[idField] || index} id={item[idField] || index} onDrop={onReorder}>
          <DraggableListItem key={item[idField] || index} id={item[idField] || index} onDragStart={setDraggedId}>
            {onItemRender(item, index)}
          </DraggableListItem>
        </DroppableListItem>
      ))}
    </DragAndDrop>
  )
}

ReorderableList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onItemRender: PropTypes.func.isRequired,
  idField: PropTypes.string.isRequired,
  onSetList: PropTypes.func.isRequired
}
