import { batchEntities } from '../constants/batch_entities'

export function setBatchEntities(state) {
  batchEntities.forEach((key) => {
    const stateEntityData = state[key]

    if (stateEntityData) localStorage.setItem(key, JSON.stringify(stateEntityData))
  })
}

export function getBatchEntities() {
  const initialStore = {}

  batchEntities.forEach((entity) => {
    const item = localStorage.getItem(entity)

    if (item) initialStore[entity] = JSON.parse(item)
  })

  return initialStore
}
