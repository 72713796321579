/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useStore } from 'react-context-hook'
import { MdFunctions } from 'react-icons/md'
import { useWatch } from 'react-hook-form'
import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/boolean_refinements'
import { parseFormulaResult } from '/src/models/concerns/formula_result'
import '/src/static/css/inputs.css'

export default function InputFixedFormula({ inputProps }) {
  const { id, name, placeholder, className, label, isInteger, dataItem, dependency, formula, control } = inputProps
  const dependencyValue = useWatch({ control, name: dependency, defaultValue: [] })
  const [globalProject] = useStore('project')
  const calculatedValue = formula(dependencyValue || [], dataItem)

  return (
    <React.Fragment>
      <div className="formula-wrapper fixed-formula-input" title={I18n.t('form.inputs.formula.description')}>
        {label}
        <input
          id={id}
          className={classNames('form-input-text', { [className]: className })}
          name={name}
          value={isPresent(calculatedValue) ? parseFormulaResult(calculatedValue, isInteger, globalProject) : ''}
          disabled
          placeholder={placeholder}
          type="text"
        />
        <button type="button" id="result-button" disabled>
          <MdFunctions />
        </button>
      </div>
    </React.Fragment>
  )
}

InputFixedFormula.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    dependency: PropTypes.arrayOf(PropTypes.string),
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.element,
    isInteger: PropTypes.bool,
    readOnly: PropTypes.bool,
    formula: PropTypes.func,
    control: PropTypes.oneOfType([PropTypes.object])
  })
}

InputFixedFormula.defaultProps = {
  inputProps: {
    className: '',
    name: '',
    placeholder: '',
    dependency: null,
    value: null,
    label: null,
    isInteger: false,
    formula: () => {},
    control: {}
  }
}
