/* eslint-disable max-lines-per-function */
// eslint-disable-next-line
import I18n from '/src/utils/translations'

const defaultColumns = () => {
  return [
    {
      description: 'label',
      title: I18n.t('scopes.label'),
      editable: false,
      width: 150,
      type: 'text'
    },
    {
      description: 'scopes_count',
      title: '⠀',
      editable: false,
      width: 200,
      type: 'numeric'
    },
    {
      description: 'team_target_hours',
      title: I18n.t('scopes.hours'),
      editable: false,
      width: 150,
      type: 'numeric',
      footer: true
    },
    {
      description: 'remaining_team_target_hours',
      title: I18n.t('scopes.remaining_hours'),
      editable: false,
      width: 150,
      type: 'numeric',
      footer: true
    },
    {
      description: 'quantity',
      title: I18n.t('scopes.quantity'),
      editable: false,
      width: 150,
      type: 'numeric',
      footer: true
    },
    {
      description: 'remaining_quantity',
      title: I18n.t('scopes.remaining_quantity'),
      editable: false,
      width: 150,
      type: 'numeric',
      footer: true
    }
  ]
}

export class ScopeClusteredModel {
  constructor(columns) {
    this.columns = columns || defaultColumns()
    this.paramName = ScopeClusteredModel.paramName
  }

  static paramName = 'scope_cluster'

  name = 'Scoping Clustered'

  templateType = 'scoping'

  route = 'scope_cluster_summaries'

  footer = true
}

export class ScopeClusteredFilteredModel extends ScopeClusteredModel {
  constructor() {
    const interactiveModeBlackList = ['remaining_quantity', 'remaining_team_target_hours']
    const filteredColumns = defaultColumns().filter((item) => {
      return !interactiveModeBlackList.includes(item.description)
    })

    super(filteredColumns)
  }
}
