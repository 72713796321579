/* eslint-disable max-lines-per-function */
import { useMemo } from 'react'
import I18n from '/src/utils/translations'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useRequestItemDataProvider from '/src/ui/domain/requests/request_item_data_provider'

/**
 * This custom hook implements a way of creating sections for discipline check form
 * @return [dataItem, setDataItem, sections]
 */
export default function useDisciplineCheckPopupFields({ markedToDiscipline = false, requestTemplate }) {
  const [dataItem, setDataItem, defaultResponsible, onChange] = useRequestItemDataProvider()

  const sections = useMemo(() => {
    const eavColumns = [
      {
        id: 1,
        description: 'date',
        title: I18n.t('orders.date'),
        onChange,
        type: 'date',
        default: 'current_date',
        required: true
      },
      {
        id: 2,
        description: 'responsible_id',
        title: I18n.t('orders.responsible'),
        foreignKey: 'employees',
        foreignAttribute: 'responsible_id',
        searchFields: ['name', 'last_name'],
        searchRoute: 'employees',
        searchExtraQuery: { where: { is_scope_responsible: 1 } },
        onChange,
        textDisplayFields: ['full_name'],
        textField: 'full_name',
        type: 'search',
        default: defaultResponsible(),
        required: true
      },
      {
        id: 3,
        description: 'comments',
        title: I18n.t('orders.comments'),
        className: 'perform-discipline-comments',
        onChange,
        type: 'multiline_text',
        maxLength: 255,
        required: markedToDiscipline
      }
    ]

    if (markedToDiscipline && requestTemplate?.metadata) {
      eavColumns.push({
        id: 4,
        description: 'check-terms',
        title: I18n.t('requests.discipline_check.check_terms'),
        className: 'discipline-check-terms',
        type: 'multiline_text',
        required: false,
        readOnly: true,
        default: requestTemplate?.metadata
      })
    }

    return [{ id: 1, eav_columns: eavColumns }]
  }, [defaultResponsible, onChange, markedToDiscipline, requestTemplate])

  return [dataItem, setDataItem, sections]
}
