import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/cards/card.css'

export default function Card({ tag, title, subtitle, details, height, className, labels }) {
  const inputClass = () => {
    const classes = ['card-wrapper']
    if (className) classes.push(className)
    return classes.join(' ')
  }

  const cardStyle = labels ? { minHeight: `${height}px` } : { height: `${height}px` }

  return (
    <div className={inputClass()} style={cardStyle}>
      {tag && <div className="card-tag">{tag}</div>}
      <div className="card-main">
        <div className="card-upper-group">
          <div className="card-body">
            <div className="card-title">{title}</div>
            {subtitle && <div className="card-subtitle">{subtitle}</div>}
          </div>
          {details && <div className="card-details">{details}</div>}
        </div>
        {labels && (
          <div className="card-labels-group" data-testid="card-labels-group">
            {labels.map((label) => (
              <div className="card-label" key={label.columnTitle}>
                {`${label.columnTitle}: ${label.columnValue.toString()}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Card.propTypes = {
  tag: PropTypes.element,
  title: PropTypes.element.isRequired,
  subtitle: PropTypes.element,
  details: PropTypes.element,
  height: PropTypes.number,
  className: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      columnTitle: PropTypes.string,
      columnValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    })
  )
}

Card.defaultProps = {
  tag: undefined,
  subtitle: undefined,
  details: undefined,
  height: 89,
  className: undefined,
  labels: undefined
}
