import React from 'react'
import { PropTypes } from 'prop-types'
import I18n from '/src/utils/translations'
import FlagIconsBadge from '/src/ui/core/badges/flag_icons_badge'
import { getStatusFlag, getAdditionalFlag, getInspectionFlag, } from '/src/utils/flag_builder'

export default function PDFTitleServices({ item, modelName, storeValues, firstFlexibleColumnDescription }) {
  const { progressServiceStatuses, units } = storeValues

  const {
    number,
    request,
    quantity,
    inspected_flag: inspectedFlag,
    estimate_service: estimateService,
    contract_service: contractService,
    team_target_hours: teamTargetHours,
    progress_service_status_id: statusId,
  } = item

  const isEstimateService = modelName === 'Estimate Details'

  const parent = isEstimateService ? 'scope' : 'progress'

  const notSkipScoping = request && !request.skip_scoping
  const isExtra = !isEstimateService && (!estimateService || estimateService.quantity === null) && notSkipScoping
  const isRevised = estimateService && estimateService.quantity !== quantity
  const hasInspectionPending = inspectedFlag && inspectedFlag === 'pending'

  const flags = [
    getStatusFlag(statusId, progressServiceStatuses),
    getAdditionalFlag(isExtra, isRevised),
    getInspectionFlag(hasInspectionPending)
  ].filter(Boolean)

  const unitId = item.contract_service.unit_id
  const unit = units[unitId]

  const firstFlexibleColumnValue = firstFlexibleColumnDescription && item[firstFlexibleColumnDescription]

  return (
    <div className='print-list-pdf-title'>
      <div className='print-list-pdf-title__id'>
        <span className='print-list-pdf-title__id-title'>DPMSID</span>
        <span className='print-list-pdf-title__dpmsid'>{`#${request.id}`}</span>
        <div className='print-list-pdf-title__number'>
          {`${I18n.t('progresses.item')} ${item[parent].number}`}
        </div>
        <span className='print-list-pdf-title__right-info'>
          {`${quantity}${unit.description} ${teamTargetHours}h`}
        </span>
      </div>

      <div className='print-list-pdf-title__column-groups'>
        <div className='print-list-pdf-title__column-value-pair'>
          <div className='print-list-pdf-title__column'>
            {I18n.t('requests.reason')}
          </div>
          <div className='print-list-pdf-title__value'>
            {request.reason}
          </div>
        </div>
        <div className='print-list-pdf-title__column-value-pair'>
          <div className='print-list-pdf-title__column'>
            {I18n.t('requests.comments')}
          </div>
          <div className='print-list-pdf-title__value'>
            {request.comments ?? I18n.t('fields.na')}
          </div>
        </div>
      </div>

      <div>
        <div className='print-list-pdf-title__column'>
          {I18n.t('dialogs.configure_print_grid_file.pdf_file.information')}
        </div>
        <div className='print-list-pdf-title__badges'>
          {firstFlexibleColumnValue && (
            <div className='print-list-pdf-title__value'>
              {firstFlexibleColumnValue}
            </div>
          )}
          <div className='print-list-pdf-title__number'>
            {`${I18n.t('progress_services.service')} ${number}`}
          </div>
          <div>
            <FlagIconsBadge flags={flags} />
          </div>
        </div>
      </div>

      <div className='print-list-pdf-title__column-value-pair'>
        <div className='print-list-pdf-title__column'>
          {I18n.t('progress_services.contract_service')}
        </div>
        <div className='print-list-pdf-title__value'>
          {contractService.description}
        </div>
      </div>
    </div>
  )
}

PDFTitleServices.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modelName: PropTypes.string.isRequired,
  firstFlexibleColumnDescription: PropTypes.string,
  storeValues: PropTypes.shape({
    project: PropTypes.oneOfType([PropTypes.object]),
    subproject: PropTypes.oneOfType([PropTypes.object]),
    units: PropTypes.oneOfType([PropTypes.object]),
    progressServiceStatuses: PropTypes.oneOfType([PropTypes.object])
  }).isRequired,
}

PDFTitleServices.defaultProps = {
  firstFlexibleColumnDescription: undefined
}
