import React, { useEffect } from 'react'
import { useStore } from 'react-context-hook'
import useFetchAPI from '/src/hooks/api/fetch_api'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import Layout from '/src/ui/core/layouts/layout'
import PlanningGrid from '/src/ui/domain/planning/planning_grid'

export default function PlanningPage() {
  const [globalSubproject] = useStore('subproject')
  const { fetchAPI, responseData, loading } = useFetchAPI('eav_templates')

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { template_type: 'progress_service' } }
    }

    fetchAPI(params)
  }, [globalSubproject])

  return (
    <Layout key={globalSubproject.id}>
      {loading ? <ThreeDotsLoader /> : <PlanningGrid templates={responseData.data} />}
    </Layout>
  )
}
