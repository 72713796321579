import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'

export default function LoginForm({ onSubmit, loading }) {
  const { register, handleSubmit } = useForm()

  return (
    <form className="login-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form">
        <div className="login-input-wrapper">
          <div className="login-input">
            <input
              className="form-input-text"
              data-testid="email-input"
              type="text"
              required
              {...register('session[email]', { required: true })}
            />
            <span className="floating-label">{I18n.t('login.username')}</span>
          </div>
        </div>
        <div className="login-input-wrapper">
          <div className="login-input">
            <input
              className="form-input-text"
              data-testid="password-input"
              type="password"
              required
              {...register('session[password]', { required: true })}
            />
            <span className="floating-label">{I18n.t('login.password')}</span>
          </div>
        </div>
        <div className="submit-input">
          <button
            data-testid="submit-button"
            className="login-button"
            type="submit"
            disabled={loading}
            aria-label={I18n.t('login.log_in')}
          >
            {loading ? <SpinnerIcon key="login-spinner" className="icon-container" /> : I18n.t('login.log_in')}
          </button>
        </div>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

LoginForm.defaultProps = {
  loading: false
}
