/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdAddCircle } from 'react-icons/md'
import { duplicationCustomAction, duplicationCustomError } from '/src/ui/core/forms/form_utils'
import useFetchSections from '/src/hooks/api/fetch_sections'
import { isSectionVisible } from '/src/models/concerns/eav_section'
import useFetch from '/src/hooks/api/fetch'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Steps from '/src/ui/core/layouts/steps'
import ImportDropdown from '/src/ui/core/inputs/import_dropdown'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import LoadingCover from '/src/ui/core/layouts/loading_cover'
import useModel from '/src/ui/core/forms/model_hook'
import Form from '/src/ui/core/forms/form'
import ThirdStep from '/src/ui/domain/requests/third_step'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label.jsx'
import { includeDefaultValuesOnModel } from '/src/utils/columns_formatter'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/buttons/buttons.css'
import '/src/static/css/inputs.css'

const isApplyPackageButtonEnabled = (templates) => {
  return Object.keys(templates).length > 0
}

const confirmRequest = (dataItem, showConfirmation) => {
  const completeSuccess = () => {
    notifySuccess(
      I18n.t('notification.action_success', {
        action: I18n.t(`actions.past.confirm`),
        model: I18n.t('requests.title'),
        id: dataItem.id
      })
    )
  }

  const cancelClicked = () => {
    dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
  }

  const confirmationParams = {
    title: I18n.t('requests.confirmation_modal.confirm_title'),
    description: I18n.t('requests.confirmation_modal.confirm_description', { id: dataItem.id }),
    actionButtonText: I18n.t('requests.confirmation_modal.confirm_confirmation'),
    cancelButtonText: I18n.t('requests.confirmation_modal.confirm_cancel'),
    buttonColor: '#607d8b',
    onSuccess: () => completeSuccess,
    onCancel: () => cancelClicked,
    requestParams: {
      resourcePath: 'requests',
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: dataItem.id,
      additionalResource: { path: 'realize' }
    }
  }

  showConfirmation(confirmationParams)
}

/**
 * It will include default values on skip_scoping
 * and estimates_authorization on the request model
 */

const getRequestSteps = (requestModel) => {
  const requestSteps = [I18n.t('requests.creation_page.first_step')]
  if (requestModel.skip_scoping !== undefined && !requestModel.skip_scoping) {
    requestSteps.push(I18n.t('requests.creation_page.second_step'))
    requestSteps.push(I18n.t('requests.creation_page.third_step'))
  }
  return requestSteps
}

export default function RequestForm({ dataItem, type, model }) {
  const { fetch } = useFetch()
  const [statuses] = useStore('request_statuses')
  const [requestModel, onChangeColumn] = useModel(model, ['skip_scoping', 'statuses'])
  const [includeOnForm, setIncludeOnForm] = useState({})
  const [isContractedLoaded, setIsContractedLoaded] = useState(false)
  const [templatesWithScopes, setTemplatesWithScopes] = useState({})
  const [activeStep, setActiveStep] = useState(0)

  const isDuplicate = type === 'duplicate'

  const importDropdown = useCallback(
    (key = 0) => (
      <ImportDropdown
        key={key}
        model={{
          route: 'eav_templates',
          importRoute: 'scopes',
          name: I18n.t('requests.eav_template')
        }}
        filter={{ where: { template_type: 'scoping' } }}
        onFileSelected={setTemplatesWithScopes}
      />
    ),
    [setTemplatesWithScopes]
  )

  const [allTemplates, setAllTemplates] = useState([importDropdown()])
  const [createdRequest, setCreatedRequest] = useState()
  const [subproject] = useStore('subproject')
  const sections = useFetchSections(subproject.request_eav_template_id)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  useEffect(() => {
    onChangeColumn('statuses', statuses)
  }, [statuses])

  useBus(
    BusEvents.FORM_SUCCESS,
    (response) => {
      setCreatedRequest(response.payload)
    },
    [setCreatedRequest]
  )

  useEffect(() => {
    if (createdRequest) confirmRequest(createdRequest, showConfirmation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdRequest])

  useEffect(() => {
    if (subproject.id === null) return
    const contractParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: subproject.project_contract_id }
      }
    }
    fetch('project_contracts', contractParams, {
      onSuccess: ({ data: { data } }) => {
        if (!data || data.length === 0 || !data[0] || !data[0].id) {
          return
        }
        includeDefaultValuesOnModel(data[0], requestModel, onChangeColumn)
        setIsContractedLoaded(true)
      }
    })
    setIncludeOnForm({
      eav_template_id: subproject.request_eav_template_id,
      subproject_id: subproject.id,
      elevation: 0
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subproject])

  const requestModelSection = useMemo(
    () => (
      <React.Fragment>
        <Form
          model={requestModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          onChangeColumn={onChangeColumn}
          templateId={subproject.request_eav_template_id}
          type={type}
          isDuplicate={isDuplicate}
          disableColumnEdition={(column) => isDuplicate && column.type === 'picture'}
          submitParams={isDuplicate ? duplicationCustomAction(dataItem) : undefined}
          errorHandler={
            isDuplicate ? duplicationCustomError(I18n.t('api_internal_messages.only_request_copy')) : undefined
          }
        />
        <FormButtons model={requestModel} awaitFormSuccess />
      </React.Fragment>
    ),
    [requestModel, sections, dataItem, includeOnForm, subproject, type, onChangeColumn]
  )

  const addNewTemplate = useCallback(() => setAllTemplates([...allTemplates, importDropdown(allTemplates.length)]), [
    allTemplates,
    importDropdown
  ])

  const getCreationSections = useMemo(() => {
    if (!sections) return []
    const newSections = sections.filter((section) => {
      const columns = section.eav_columns || section.columns
      return isSectionVisible(columns)
    })
    return [{ id: 'general', title: I18n.t('form.general_information') }, ...newSections]
  }, [sections])

  const createSections = useMemo(() => {
    const createRequestSteps = []
    createRequestSteps.push({
      id: 'first-step',
      awaitFormSuccess: true,
      title: I18n.t('requests.creation_page.first_step'),
      subSections: getCreationSections,
      body: requestModelSection
    })

    if (requestModel.skip_scoping !== undefined && !requestModel.skip_scoping) {
      createRequestSteps.push({
        id: 'second-step',
        title: I18n.t('requests.creation_page.second_step'),
        body: (
          <React.Fragment>
            {renderConfirmation()}
            <div className="request-form-scope-importation">
              {allTemplates}
              <button className="button-cleaned add-template-button" type="button" onClick={() => addNewTemplate()}>
                <MdAddCircle className="add-template-icon" />
                {I18n.t('requests.creation_page.add_template')}
              </button>
              <FormButtons model={requestModel} />
            </div>
          </React.Fragment>
        ),
        isNextButtonEnabled: isApplyPackageButtonEnabled(templatesWithScopes)
      })

      createRequestSteps.push({
        id: 'third-step',
        title: I18n.t('requests.creation_page.third_step'),
        body: (
          <div>
            <ThirdStep dataItem={createdRequest} templates={templatesWithScopes} />
            <FormButtons model={requestModel} />
          </div>
        )
      })
    }

    return (
      <Steps steps={createRequestSteps} modelName="requests" activeStep={activeStep} setActiveStep={setActiveStep} />
    )
  }, [
    requestModel,
    allTemplates,
    templatesWithScopes,
    renderConfirmation,
    getCreationSections,
    createdRequest,
    addNewTemplate,
    requestModelSection,
    activeStep
  ])

  const willEdit = () => dataItem && type === 'edit'

  if (!isContractedLoaded) {
    return <LoadingCover show text={I18n.t('form.loading_contract')} />
  }

  return (
    <FormWrapper
      model={requestModel}
      dataItem={dataItem}
      label={willEdit() ? dataItem.id : createdRequest && createdRequest.id}
      badge={willEdit() ? <RequestStatusLabel dataItem={dataItem} /> : undefined}
      type={type}
      classes={type === 'new' ? 'full' : ''}
      showSidePanel={activeStep !== 0 ? ['edit', 'duplicate'] : undefined}
      sidePanelSections={getCreationSections}
      steps={getRequestSteps(requestModel)}
      activeStep={activeStep}
    >
      {dataItem ? requestModelSection : createSections}
    </FormWrapper>
  )
}

RequestForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired
}

RequestForm.defaultProps = {
  dataItem: null,
  type: ''
}
