import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { IntlService } from '@progress/kendo-react-intl'
import { MdCheck } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import { formattedLocaleDate, dateDiffDays } from '/src/utils/project_formatter'
import '/src/static/css/domain/requests/schedule_side_panel_block.css'

export default function ScheduleSidePanelBlockDateGroup({ dataItem, entity, title }) {
  const [globalProject] = useStore('project')
  const [employees] = useStore('employees')
  const opts = { project: globalProject, parser: new IntlService() }
  const responsible = employees[dataItem[`${entity}_responsible_id`]]
  const startDate = formattedLocaleDate({ ...opts, value: dataItem[`${entity}_start_date`] })
  const endDate = formattedLocaleDate({ ...opts, value: dataItem[`${entity}_end_date`] })
  const dateDiff = dateDiffDays(dataItem[`${entity}_start_date`], dataItem[`${entity}_end_date`])

  return (
    <div className="schedule-wrapper">
      {startDate && endDate && responsible ? (
        <div className="schedule-check active">
          <MdCheck />
        </div>
      ) : (
        <div className="schedule-check" />
      )}
      <div className="schedule-group">
        <div className="schedule-info">
          <div className="schedule-description">{title}</div>
          <div className="schedule-dates">
            <span>{startDate || ''}</span>
            {endDate ? (
              <React.Fragment>
                <span className="arrow">&#8594;</span>
                {endDate}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          {responsible && <div className="responsible">{responsible.full_name}</div>}
        </div>
        {startDate && endDate && (
          <div className="schedule-days">{I18n.t('requests.schedule_form.days', { number: dateDiff })}</div>
        )}
      </div>
    </div>
  )
}

ScheduleSidePanelBlockDateGroup.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  entity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
