import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import InputNumeric from '/src/ui/core/inputs/input_numeric'
import InputDate from '/src/ui/core/inputs/input_date'
import InputDateRange from '/src/ui/core/inputs/input_date_range'
import InputText from '/src/ui/core/inputs/input_text'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import InputDrop from '/src/ui/core/inputs/input_drop'
import InputSingleDatasheetFilter from '/src/ui/core/inputs/input_single_datasheet_filter'
import { columnType } from '/src/models/concerns/eav_column'
import { isDateRange } from '/src/utils/calendar'
import { isBlank } from '/src/utils/boolean_refinements'
import { BetweenOperator, IsEqualOperator } from '/src/models/concerns/filter_operators'

export const HeaderFilterInput = ({ column, value, operator, onChange, booleanValues }) => {
  const timer = useRef()

  const handleChange = (id, newValue) => {
    if (operator === BetweenOperator && !isDateRange(newValue)) return

    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      if (isBlank(newValue)) onChange({ value: '', operator: '', nativeEvent: { type: 'reset' } })
      else onChange({ value: newValue, operator })
    }, 500)
  }

  const formatBoolean = (booleanValue) => {
    return booleanValues.filter((value) => value.operator === booleanValue)[0]
  }

  const formatDate = (dateValue) => {
    if (typeof dateValue === 'object') return ''
    return dateValue
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  switch (columnType(column)) {
    case 'integer':
      return (
        <InputNumeric
          format="#"
          inputProps={{
            value,
            onChange: handleChange
          }}
        />
      )
    case 'numeric':
    case 'decimal':
      return (
        <InputDecimal
          inputProps={{
            value,
            onChange: handleChange
          }}
        />
      )
    case 'boolean':
      return (
        <DropDownList
          popupSettings={{
            className: 'combobox-list boolean-dropdown-filter'
          }}
          data={booleanValues.filter(({ operator }) => typeof operator === 'boolean')}
          textField="text"
          value={formatBoolean(value !== undefined ? value : '')}
          onChange={(event) => handleChange(0, event.target.value.operator)}
        />
      )
    case 'date':
    case 'date_time':
      return operator === BetweenOperator ? (
        <InputDateRange
          inputProps={{
            className: 'filter-input-date',
            value,
            onChange: handleChange,
            simplified: true
          }}
        />
      ) : (
        <InputDate
          inputProps={{
            className: 'filter-input-date',
            defaultValue: formatDate(value),
            value: formatDate(value),
            onChange: handleChange
          }}
        />
      )
    case 'drop':
      if (operator === IsEqualOperator && column.metadata) {
        return (
          <InputDrop
            inputProps={{
              options: JSON.parse(column.metadata),
              value,
              onChange: handleChange,
              forceCombobox: true,
              clearButton: false,
              popupClassName: 'header-filter-cell-dropdown',
              popupSettingsWidth: 'none'
            }}
          />
        )
      }
    case 'datasheet_filter':
      if (operator === IsEqualOperator && column.datasheet_template_id && column.datasheetColumn) {
        return (
          <InputSingleDatasheetFilter
            inputProps={{
              value,
              datasheetTemplateId: column.datasheet_template_id,
              datasheetColumn: column.datasheetColumn,
              onChange: handleChange,
              multiple: false,
              autoReset: false
            }}
          />
        )
      }
    default:
      return (
        <InputText
          inputProps={{
            className: 'k-textbox',
            value,
            onChange: handleChange
          }}
        />
      )
  }
}

HeaderFilterInput.propTypes = {
  column: PropTypes.shape({
    type: PropTypes.string,
    column_type: PropTypes.shape({
      description: PropTypes.string
    })
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({ start: PropTypes.string, end: PropTypes.string })
  ]),
  operator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  booleanValues: PropTypes.array.isRequired
}

HeaderFilterInput.defaultProps = {
  value: undefined,
  operator: ''
}
