const KendoFileStatus = {
  uploadFailed: 0,
  initial: 1,
  selected: 2,
  uploading: 3,
  uploaded: 4,
  removeFailed: 5,
  removing: 6
}

export default KendoFileStatus
