import { invert } from '/src/utils/object'

export const variableType = {
  1: 'string',
  2: 'integer',
  3: 'decimal',
  4: 'date',
  5: 'drop',
  6: 'boolean',
  7: 'date_time',
  8: 'datasheet_filter',
  9: 'datasheet_aggregation',
  10: 'time'
}

export const variableTypeNames = invert(variableType)
