import React from 'react'
import PropTypes from 'prop-types'
import { MdArrowDownward } from 'react-icons/md'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import '/src/static/css/core/layouts/steps.css'

// eslint-disable-next-line max-lines-per-function
export default function Steps({ steps, modelName, activeStep, setActiveStep }) {
  const forward = (payload) => {
    const nextStep = activeStep + 1
    const { autoSkipCondition } = steps[nextStep]

    if (autoSkipCondition) setActiveStep(nextStep + autoSkipCondition(payload))
    else setActiveStep(nextStep)
  }

  useBus(
    BusEvents.FORM_SUBMITTED,
    ({ payload }) => {
      if (activeStep >= steps.length - 1) return
      forward(payload)
    },
    [activeStep, steps]
  )

  const handleClick = () => {
    if (steps[activeStep].awaitFormSuccess) {
      dispatch(BusEvents.STEPS_NEXT_BUTTON_CLICKED)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const nextStepButton = (i, isEnabled = true) => {
    let buttonName = I18n.t('actions.next')
    if (modelName === 'requests' && steps[activeStep + 1]) buttonName = steps[activeStep + 1].title

    return (
      activeStep === i &&
      activeStep < steps.length - 1 && (
        <button className="next-button" onClick={() => handleClick()} type="button" disabled={!isEnabled}>
          {`${buttonName}`}
          <MdArrowDownward />
        </button>
      )
    )
  }

  return (
    <div className="steps">
      {steps[activeStep].body}
      <div className="buttons-col">{nextStepButton(activeStep, steps[activeStep].isNextButtonEnabled)}</div>
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      awaitFormSuccess: PropTypes.bool,
      title: PropTypes.string,
      autoSkipCondition: PropTypes.func,
      body: PropTypes.element,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subSections: PropTypes.arrayOf(PropTypes.object),
      isNextButtonEnabled: PropTypes.bool
    })
  ).isRequired,
  modelName: PropTypes.string,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func
}

Steps.defaultProps = {
  modelName: 'requests',
  activeStep: 0,
  setActiveStep: () => {}
}
