import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'

export default function MatrixResult({ value }) {
  const [globalProject] = useStore('project')
  const formattedFloat = formatNumberWithPrecision(value, globalProject)

  return formattedFloat
}

MatrixResult.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

MatrixResult.defaultProps = {
  value: null
}
