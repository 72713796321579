import React, { useCallback, useEffect, useMemo, useState } from 'react'
import HistoriesFeed from '/src/ui/domain/progresses/histories_feed'
import HistoryForm from '/src/ui/domain/progresses/history_form'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useProgressHistoryTab(level, dataItem) {
  const [isFormOpened, setIsFormOpened] = useState(dataItem?.dispatchProgressFormTab)
  const [historyItem, setHistoryItem] = useState()

  const isServiceCompleted = dataItem.progress_service_status_id === 5

  const onOpenForm = useCallback(() => {
    if (dataItem.quantity === 0 || isServiceCompleted) return
    setIsFormOpened(true)
  }, [dataItem.quantity, isServiceCompleted])

  useEffect(() => {
    setHistoryItem(null)
    if (!dataItem.dispatchProgressFormTab) setIsFormOpened(false)
    else setIsFormOpened(true)
  }, [dataItem?.id, dataItem?.dispatchProgressFormTab])

  const tabBody = useMemo(() => {
    return (
      <div className="progress-history-tab" key={dataItem?.id}>
        {isFormOpened && !isServiceCompleted && (
          <HistoryForm
            parentDataItem={dataItem}
            historyDataItem={historyItem}
            onClose={() => {
              setIsFormOpened(false)
              setHistoryItem()
            }}
            level={level}
          />
        )}

        <HistoriesFeed
          level={level}
          itemId={dataItem?.id}
          onOpenForm={onOpenForm}
          onChangeHistoryItem={(item) => {
            if (!item && historyItem?.id) setIsFormOpened(false)
            setHistoryItem(item)
          }}
          formItem={historyItem}
          isFormOpen={isFormOpened && !isServiceCompleted}
        />
      </div>
    )
  }, [isFormOpened, historyItem, level, dataItem, isServiceCompleted, onOpenForm])

  useBus(
    BusEvents.OPEN_SIDE_PANEL_TAB,
    ({ payload }) => {
      const { name: tabName, openForm } = payload
      if (tabName === 'progress' && openForm && !isServiceCompleted) onOpenForm()
    },
    []
  )

  useEffect(() => {
    const { dispatchProgressFormTab } = dataItem
    if (!dispatchProgressFormTab || isServiceCompleted) return
    onOpenForm()
  }, [dataItem.dispatchProgressFormTab, isServiceCompleted, onOpenForm, dataItem])

  return {
    name: 'progress',
    expandSidePanelWidth: true,
    body: tabBody
  }
}
