import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { useLocation } from 'react-router-dom'
import I18n from '/src/utils/translations'
import CategoryItem from '/src/ui/domain/datasheets/category_item'
import TopMenuPopupItem from '/src/ui/core/header/top_menu_popup_item'
import { isEmpty } from '/src/utils/object'

// eslint-disable-next-line max-lines-per-function
export default function TopMenuDatasheetButton() {
  const { pathname } = useLocation()
  const [datasheetCategories] = useStore('datasheet_categories')

  const datasheetCategoriesList = useMemo(() => {
    if (!datasheetCategories || isEmpty(datasheetCategories)) return I18n.t('top_menu.no_datasheets')

    return Object.values(datasheetCategories).map((category) => <CategoryItem category={category} key={category.id} />)
  }, [datasheetCategories])

  return (
    <TopMenuPopupItem label={I18n.t('top_menu.datasheets')} isActive={pathname === '/datasheets'}>
      <React.Fragment>{datasheetCategoriesList}</React.Fragment>
    </TopMenuPopupItem>
  )
}
