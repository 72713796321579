import React, { useMemo } from 'react'
import lodash from 'lodash'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import RowActionsColumn from '/src/ui/core/grid/column_cell_factory/row_actions_column'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'

export default function useEditableGridRowsActions(
  newDataSource,
  setNewDataSource,
  isRowEditable,
  updateEditedItems,
  onItemUpdate,
  allowDelete,
  getNewVirtualKey,
  getDataItemKey,
  cellMissingRequiredFields,
  allowDuplicate
) {
  const { omit } = lodash

  const isHeaderChecked = useMemo(
    () =>
      newDataSource.length > 0 &&
      newDataSource.filter((item) => isRowEditable(item) && !item.to_be_deleted).length === 0,
    [newDataSource, isRowEditable]
  )

  const onSelectionChange = (cellItem) => {
    const { dataIndex, dataItem } = cellItem

    if (!isRowEditable(dataItem)) return

    setNewDataSource((prevDataSource) => {
      const nextDataSource = prevDataSource.map((item, index) => {
        if (dataIndex === index) {
          if (item.to_be_deleted) return omit(item, ['to_be_deleted', 'inError'])
          return { ...item, to_be_deleted: true }
        }
        return item
      })

      updateEditedItems(nextDataSource)

      return nextDataSource
    })
  }

  const onDuplicate = (dataIndex) => {
    setNewDataSource((prevDataSource) => {
      const itemToDuplicate = prevDataSource[dataIndex]

      const fieldsToOmit = ['id', 'uuid', 'updated_at', 'created_at', 'number', 'attachments', 'pictures', 'signatures']

      const duplicatedItem = {
        ...omit(itemToDuplicate, fieldsToOmit),
        virtualKey: getNewVirtualKey(),
        isDuplicated: true
      }

      const nextDataSource = [
        ...prevDataSource.slice(0, dataIndex + 1),
        duplicatedItem,
        ...prevDataSource.slice(dataIndex + 1)
      ]

      updateEditedItems(nextDataSource)

      dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
      onItemUpdate(duplicatedItem)

      return nextDataSource
    })
  }

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.target
    const { checked } = checkboxElement
    setNewDataSource((prevDataSource) => {
      let nextDataSource = []

      if (!checked) {
        nextDataSource = prevDataSource.map((item) => omit(item, ['to_be_deleted', 'inError']))
      } else {
        nextDataSource = prevDataSource.map((item) => (isRowEditable(item) ? { ...item, to_be_deleted: true } : item))
      }

      updateEditedItems(nextDataSource)
      return nextDataSource
    })
  }

  const renderActionsColumn = () => {
    return (
      <Column
        key="actions"
        width={120}
        sortable={false}
        filterable={false}
        resizable={false}
        headerCell={() => (
          <RowActionsColumn
            toBeDeleted={isHeaderChecked}
            onChange={(event) => onHeaderSelectionChange(event)}
            allowDelete={allowDelete}
            allowDuplicate={allowDuplicate}
            header
          />
        )}
        cell={(cellItem) => {
          const { dataItem } = cellItem
          const itemId = dataItem[getDataItemKey(dataItem)]
          return (
            <RowActionsColumn
              toBeDeleted={dataItem.to_be_deleted}
              onChange={() => onSelectionChange(cellItem)}
              onDuplicate={() => onDuplicate(cellItem.dataIndex)}
              allowDelete={allowDelete}
              allowDuplicate={allowDuplicate}
              missingRequiredFields={cellMissingRequiredFields[itemId]?.length}
            />
          )
        }}
      />
    )
  }

  return renderActionsColumn
}
