import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import Layout from '/src/ui/core/layouts/layout'
import EmbedIframe from '/src/ui/core/layouts/embed_iframe'
import I18n from '/src/utils/translations'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function UserFormPage({ match, user }) {
  const history = useHistory()

  const url = () => {
    return `${SERVER_URL}/users/${match.params.id}/edit`
  }

  if (match.params.id != user.id) {
    notifyError(I18n.t('notification.page_denied'))
    history.push('/')
  }

  return (
    <Layout key={match.params.id}>
      <EmbedIframe
        url={url()}
        allowedUrls={[`/users/${match.params.id}/edit`]}
        forbiddenUrls={[`/users/${match.params.id}`]}
      />
    </Layout>
  )
}

UserFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
}

UserFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
