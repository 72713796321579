/* eslint-disable import/prefer-default-export */

export const setExpandRows = (rows, expanded) => {
  if (typeof expanded !== 'boolean') return rows
  return rows.map((item) => ({ ...item, expanded }))
}

export const getLinkNodeAttributes = (nodeMap) => {
  const attrs = {}
  Array.prototype.slice.call(nodeMap).forEach((item) => {
    const key = item.name === 'class' ? 'className' : item.name
    attrs[key] = item.value
  })

  return attrs
}
