import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { RiThumbUpFill } from 'react-icons/ri'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'
import useSendEstimates from '/src/ui/domain/estimates/send_estimates_hook'

// eslint-disable-next-line max-lines-per-function
export default function EstimateFormButtons({ pendingEstimates }) {
  const [showConfirmation, renderConfirmation] = useConfirmationModal(true)
  const [sendEstimates] = useSendEstimates()

  useBus(BusEvents.FORM_SUBMITTED, ({ payload }) => sendEstimates(pendingEstimates, payload), [pendingEstimates])

  const handleApproveClick = () => dispatch(BusEvents.FORM_SUBMIT)

  const showConfirmationModal = () => {
    const confirmationParams = {
      title: I18n.t('estimate.confirmation.title'),
      description: I18n.t('estimate.confirmation.description'),
      actionButtonText: I18n.t('estimate.confirmation.confirm'),
      cancelButtonText: I18n.t('estimate.confirmation.cancel'),
      onSuccess: () => handleApproveClick
    }

    showConfirmation(confirmationParams)
  }

  return (
    <div>
      {renderConfirmation()}
      <ActionToolbar
        className="form-buttons-estimate"
        cancelText={I18n.t('actions.cancel')}
        onCancel={() => dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)}
        actionIcon={<RiThumbUpFill />}
        actionText={I18n.t('actions.approve')}
        onAction={() => showConfirmationModal()}
      />
    </div>
  )
}

EstimateFormButtons.propTypes = {
  pendingEstimates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}

EstimateFormButtons.defaultProps = {
  pendingEstimates: []
}
