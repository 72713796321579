import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import PropTypes from 'prop-types'
import Layout from '/src/ui/core/layouts/layout'
import EmbedIframe from '/src/ui/core/layouts/embed_iframe'
import shortcuts from '/src/ui/domain/settings/shortcuts'
import SettingsEmbedPageAuthorized from '/src/pages/settings_embed_page_authorized'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function SettingsEmbedPage({ user }) {
  const [page] = useQueryParam('page', StringParam)

  const allowedPaths = shortcuts(user.id)
    .map((a) => a.shortcuts.map((b) => b.path))
    .flat()

  const extraAllowedPaths = ['signup']

  const url = () => `${SERVER_URL}/${page}`

  return (
    <Layout key={page}>
      <SettingsEmbedPageAuthorized page={page}>
        {page && <EmbedIframe url={url()} allowedUrls={[...allowedPaths, ...extraAllowedPaths]} />}
      </SettingsEmbedPageAuthorized>
    </Layout>
  )
}

SettingsEmbedPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
}
