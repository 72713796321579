import React from 'react'
import PropTypes from 'prop-types'
import { MdCheck } from 'react-icons/md'
import I18n from '/src/utils/translations'

export default function BooleanColumn({ value }) {
  const isTrue = typeof value === 'string' ? value === 'true' : value

  const renderNoLabel = () => (
    <div className="no-label">
      <span className="labeled">{I18n.t('actions.no')}</span>
    </div>
  )

  const renderYesLabel = () => (
    <div className="yes-label">
      <span className="labeled green-labeled">
        <div className="check-icon">
          <MdCheck fontSize={15} />
        </div>
        {I18n.t('actions.yes')}
      </span>
    </div>
  )

  return isTrue ? renderYesLabel() : renderNoLabel()
}

BooleanColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

BooleanColumn.defaultProps = {
  value: false
}
