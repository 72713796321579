/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import RequestModel from '/src/models/request'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import FieldFactory from '/src/ui/core/fields/field_factory'
import { getAttachmentFiles } from '/src/models/concerns/attachment'
import '/src/static/css/domain/sub_requests/sub_request.css'

export default function SubRequestSummary({ request }) {
  const requestModel = new RequestModel()
  const fixedSection = {
    id: 0,
    title: I18n.t('form.general_information'),
    columns: requestModel.columns.filter((column) => !column.hideOnSummary)
  }

  const [sections, setSections] = useState([fixedSection])
  const { fetch } = useFetch()

  useEffect(() => {
    const params = {
      dataOptions: {
        filtering: {
          filter: {
            logic: 'and',
            filters: [{ type: 'where', column: 'eav_template_id', value: request.eav_template_id }]
          }
        }
      },
      additionalResource: { path: 'eav_columns' }
    }

    fetch('eav_sections', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          setSections((prevState) => {
            return [...prevState, ...data[0]]
          })
        }
      }
    })
  }, [fetch, request.eav_template_id])

  const renderColumn = (column) => {
    let columnValue = request[column.description]
    if (column.type === 'attachment' && request[column.description])
      columnValue = getAttachmentFiles(request, request[column.description])

    return <FieldFactory value={columnValue} type={column.type} opts={column} />
  }

  return (
    <div className="sub-request-summary" data-testid="sub-request-summary">
      <SectionedPanel sections={sections} enumerable={false} hideable={false} renderColumn={renderColumn} />
    </div>
  )
}

SubRequestSummary.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    eav_template_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
}
