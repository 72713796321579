import React from 'react'
import { MdAdd } from 'react-icons/md'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/menus/sidebar.css'

export default function NewButton({ modelName, opts }) {
  const onClick = () =>
    dispatch({
      type: BusEvents.NEW_BUTTON_CLICKED,
      payload: { modelName, ...opts }
    })

  return (
    <CustomTooltip position="bottom" parentTitle>
      <div className="new-button-container">
        <button data-testid="new-btn-grid" title={I18n.t('actions.new')} className="new-btn" onClick={onClick}>
          <MdAdd />
          <span>{I18n.t('actions.new')}</span>
        </button>
      </div>
    </CustomTooltip>
  )
}

NewButton.propTypes = {
  modelName: PropTypes.string,
  opts: PropTypes.oneOfType([PropTypes.object])
}

NewButton.defaultProps = {
  modelName: '',
  opts: {}
}
