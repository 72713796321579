/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { store } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import buildUrl from '/src/hooks/api/query_builder'
import { dispatch } from '/src/hooks/bus/bus'
import handleError from '/src/hooks/api/errors'

export const MAX_PAGE_SIZE = 100

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const defaultParams = { requestAction: 'READ', httpAction: 'get' }

const notifyErrors = (errors) => {
  if (Array.isArray(errors)) {
    errors.forEach((body) => {
      notifyError({ body, closeTimeout: 10 })
    })
  } else {
    notifyError({ body: errors, closeTimeout: 10 })
  }
}

const onFetchFailed = (response, dialogMessages) => {
  const errors = handleError(response.request)
  dialogMessages ? dispatch(BusEvents.SHOW_FAILURE_DIALOG) : notifyErrors(errors)
}

const useFetch = (hideErrors = false) => {
  const componentMounted = useRef(true)
  const [getToken] = useCookie('remember_token')
  const source = axios.CancelToken.source()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return () => {
      source.cancel()
      componentMounted.current = false
    }
  }, [])

  const getUrlParams = (path, params) => {
    return {
      ...params,
      resourcePath: path,
      serverUrl: SERVER_URL,
      globalSubproject: store.get('subproject'),
      globalProject: store.get('project')
    }
  }

  const parseResponse = (response, params) => {
    const { httpAction, resourceId, additionalResource } = params
    let responseData

    try {
      if (response && typeof response.data === 'string') responseData = JSON.parse(response.data)
      else responseData = response.data
    } catch (err) {
      responseData = response.data
    }

    if (httpAction === 'get') {
      responseData = responseData.data ? responseData.data : responseData
    } else if (httpAction === 'delete') {
      const responseId = additionalResource ? additionalResource.resourceId : resourceId
      responseData = { id: responseId }
    }

    return responseData
  }

  // eslint-disable-next-line max-params
  const fetch = useCallback((path, fetchParams, { onSuccess, onError, useParse, useReject, useLoading } = {}) => {
    const params = { ...defaultParams, ...fetchParams }
    if (useLoading) setLoading(true)

    return new Promise((resolve, reject) => {
      const authToken = getToken()
      const headers = { 'Content-Type': 'application/json; charset=utf-8' }
      if (authToken) headers.Authorization = `Token ${authToken}`

      axios({
        cancelToken: source.token,
        method: params.httpAction,
        url: buildUrl(getUrlParams(path, params)),
        data: params.data,
        responseType: params.responseType || 'text',
        withCredentials: true,
        headers
      })
        .then((response) => {
          if (componentMounted.current) {
            let responseData

            try {
              responseData = JSON.parse(response.data)
            } catch (e) {
              responseData = response.data
            }

            if (useParse) responseData = parseResponse(response, params)

            resolve({ data: responseData })
            onSuccess?.({ data: responseData, status: response.status }, params)
            if (useLoading) setLoading(false)
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (!hideErrors) onFetchFailed(error, params.dialogMessages)
            onError?.(error, params.dialogMessages)

            if (useReject) reject(error)
            else resolve(error)
            if (useLoading) setLoading(false)
          }
        })
    })
  }, [])

  return { fetch, loading }
}

export default useFetch
