/* eslint-disable max-lines-per-function */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { objectEquals } from '/src/utils/object'

/**
 * Merge two array of objects creating a new one
 * @param {objArray1} 1st array of objects
 * @param {objArray2} 2nd array of objects
 * @returns {array} The merged array of objects
 */
export function merge(objArray1, objArray2) {
  const merged = [...objArray1]

  objArray2.forEach((object2) => {
    if (
      merged.some((object1) => {
        return objectEquals(object1, object2)
      })
    )
      return

    merged.push(object2)
  })

  return merged
}

/**
 * Remove the second array of objects from the first array of objects
 * @param {objects} The main array of objects you want to remove the objects
 * @param {removeObjects} The objects you want to remove from the array of objects
 * @returns {array} A new array with objects removed
 */
export function remove(objects, removeObjects) {
  return objects.filter((obj1) => {
    return removeObjects.every((obj2) => {
      return !objectEquals(obj2, obj1)
    })
  })
}

/**
 * Generates a range from a value to a value using the desired step
 * https://stackoverflow.com/a/3895618/7644846
 * @param from { integer, string } - range starting number
 * @param to { integer, string } - range stopping number
 * @param step { integer } - range step
 * @returns { array } - an array representing the range
 */
export const range = (from, to, step) => {
  if (typeof from !== 'number' && typeof from !== 'string') {
    throw new TypeError('The first parameter should be a number or a character')
  }

  if (typeof to !== 'number' && typeof to !== 'string') {
    throw new TypeError('The second parameter should be a number or a character')
  }

  let A = []
  if (typeof from === 'number') {
    A[0] = from
    step = step || 1
    while (from + step <= to) {
      from += step
      A[A.length] = from
    }
  } else {
    let s = 'abcdefghijklmnopqrstuvwxyz'
    if (from === from.toUpperCase()) {
      to = to.toUpperCase()
      s = s.toUpperCase()
    }
    s = s.substring(s.indexOf(from), s.indexOf(to) + 1)
    A = s.split('')
  }
  return A
}

/**
 * Converts an array of objects to an object using the provided key
 * @param array { array } - The array of objects to be converted to an object
                            Example: [{ id: 1, title: 'a' }, { id: 2, title: 'b' }]
 * @param key  { string } - The key to be used for the conversion
                            Example: 'id'
 * @returns { object } - the generated object
 */
export const indexify = (array, key) => {
  if (!array || array.length === 0) return {}

  if (array.some((item) => !Object.keys(item).includes(key)))
    throw new TypeError(`The ${key} key should be available on all objects`)

  return array.reduce((obj, item) => Object.assign(obj, { [item[key]]: item }), {})
}
