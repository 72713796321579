import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import StatusIcon from '/src/ui/core/inputs/image_thumbnail/status_icon'
import useFetch from '/src/hooks/api/fetch'
import '/src/static/css/core/inputs/image_thumbnail.css'

const DEBOUNCE_TIME_CALL_ONCHANGE = 500
const MAX_TIME_SHOWING_SUCCESS_ICON = 2000

export default function CommentSection({ image }) {
  const initialComment = image.comment ? `${image.comment}` : ''
  const updateCommentPath = `pictures/${image.uuid}/update_comment`
  const { fetch } = useFetch()
  const [loadingStatus, setLoadingStatus] = useState('not_started')
  const [newComment, setNewComment] = useState(initialComment || '')
  const timer = useRef(null)

  useEffect(() => {
    let mounted = true

    timer.current = setTimeout(() => {
      const params = {
        requestAction: 'UPDATE',
        httpAction: 'put',
        data: {
          comment: newComment
        }
      }

      setLoadingStatus('loading')

      fetch(updateCommentPath, params, {
        onSuccess: () => {
          setLoadingStatus('success')
          timer.current = setTimeout(() => {
            if (mounted) setLoadingStatus('not_started')
          }, MAX_TIME_SHOWING_SUCCESS_ICON)
        },
        onError: () => setLoadingStatus('error')
      })
    }, DEBOUNCE_TIME_CALL_ONCHANGE)

    return () => {
      mounted = false
      clearTimeout(timer.current)
    }
  }, [fetch, updateCommentPath, newComment])

  return (
    <div className="comment">
      <textarea
        id={image.uuid}
        name={image.description}
        placeholder={I18n.t('form.inputs.picture.comment_placeholder')}
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <StatusIcon loadingStatus={loadingStatus} />
    </div>
  )
}

CommentSection.propTypes = {
  image: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
    comment: PropTypes.string
  }).isRequired
}
