/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import LoginForm from '/src/ui/domain/login/login_form'
import LoginWrapper from '/src/ui/domain/login/login_wrapper'
import NotificationGroup from '/src/ui/core/dialogs/notification_group'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useLogout from '/src/ui/domain/login/logout_hook'
import usePreferences from '/src/ui/core/grid/preferences'
import '/src/static/css/login.css'

export default function LoginController() {
  const [data, setData] = useState()
  const [session, setSession] = useState()
  const [loading, setLoading] = useState(false)
  const [, setUser] = useStore('user')
  const [, setUserRole] = useStore('user_role')
  const [getToken, setToken] = useCookie('remember_token')
  const getSession = useFetchAPI('sessions')
  const getRole = useFetchAPI('users')
  const [, setGlobalProject] = useStore('project')
  const [, setGlobalSubproject] = useStore('subproject')
  const logout = useLogout()
  const { clearRequests } = useRequestsPinned()
  const clearBatchEntities = useClearBatchEntities()
  const { saveLocalPreferences } = usePreferences()

  const onSubmit = (formData) => {
    setGlobalProject({})
    setGlobalSubproject({})
    clearRequests()
    clearBatchEntities()
    setData(formData)
  }

  useEffect(() => {
    logout()
  }, [])
  // this useEffect is the reason why user can't login at both applications from dpms.com/management,
  // since the react web app won't have the user state set, it will redirect to login page and clean the cookies

  useEffect(() => {
    if (data) {
      const sessionParams = {
        requestAction: 'CREATE',
        httpAction: 'post',
        data
      }
      getSession.fetchAPI(sessionParams)
    }
  }, [data])

  useEffect(() => {
    if (getSession.status === 'NOT_STARTED') return
    if (data && getSession.responseData.id) {
      setToken(getSession.responseData.remember_token)
      setSession(getSession.responseData)
    }
  }, [data, getSession.status, getSession.responseData])

  useEffect(() => {
    setLoading(getSession.status === 'FETCHING' || getRole.loading || false)
  }, [getSession.status, getRole.loading])

  useEffect(() => {
    if (session) {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        resourceId: session.id,
        additionalResource: { path: 'role' }
      }
      getRole.fetchAPI(params, getToken())
    }
  }, [session])

  useEffect(() => {
    if (!getRole.loading && !getRole.errors) {
      if (getRole.responseData.data.length > 0) {
        setUser(session)
        setUserRole(getRole.responseData.data)
        saveLocalPreferences(JSON.parse(session.preferences))
      }
    }
  }, [getRole.loading, getRole.errors, getRole.responseData])

  return (
    <LoginWrapper>
      <React.Fragment>
        <div className="login-notification">
          <NotificationGroup />
        </div>
        <LoginForm onSubmit={onSubmit} loading={loading} />
      </React.Fragment>
    </LoginWrapper>
  )
}
