import { useEffect } from 'react'
import useFetchPricedService from '/src/hooks/fetch_priced_service'
import useWatchParents from '/src/hooks/watch_parents'

/**
 * Register the parentItems (via '_parentItems') on the form.
 * The parentItems are used later to calculate equations with parent columns inside
 */
export default function useParentItemsRegister(dataItem, model, control, register, setValue) {
  const [parentItems, setParentItems] = useWatchParents(dataItem, model.parentModels || [], control)

  register('_parentItems')

  const contractServiceId = parentItems && parentItems.contract_service && parentItems.contract_service.id
  const pricedService = useFetchPricedService(contractServiceId)

  useEffect(() => {
    if (contractServiceId && pricedService && contractServiceId === pricedService.id) {
      if (parentItems.service_price === undefined) {
        parentItems.service_price = pricedService.latest_price
        setParentItems(parentItems)
      }
    }

    setValue('_parentItems', parentItems)
  }, [parentItems, pricedService])

  return parentItems
}
