import React, { useState, useEffect } from 'react'
import I18n from '/src/utils/translations'
import useSyncBatch from '/src/hooks/api/sync_batch'
import ContractItemBlock from '/src/ui/domain/blocks/contract_item_block'

export default function useContractItemBlock(dataItem) {
  const [contractItemBlock, setContractItemBlock] = useState({
    component: <React.Fragment key="contract_service_block" />,
    title: I18n.t('estimate_services.contract_service'),
    type: 'general'
  })
  const [contractServices, setContractServices] = useState()
  const [units, setUnits] = useState()

  const query = { where: { id: dataItem.contract_service_id } }

  const batchedEntities = {
    contract_services: { get: contractServices, set: setContractServices, query },
    units: { get: units, set: setUnits }
  }

  const { loading, syncBatch } = useSyncBatch(batchedEntities)

  useEffect(() => {
    if (loading || !contractServices || !units || !dataItem) return
    const contractService = contractServices[dataItem.contract_service_id]

    if (!contractService) {
      syncBatch()
      return
    }

    const newDataItem = {
      ...dataItem,
      contract_service: contractService,
      unit: units[contractService.unit_id]
    }

    setContractItemBlock({
      component: <ContractItemBlock dataItem={newDataItem} templateType="estimate_services" />,
      title: I18n.t('estimate_services.contract_service'),
      type: 'general'
    })
  }, [loading, contractServices, units, dataItem, syncBatch])

  return contractItemBlock
}
