/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'

export default function useManageFixedForm({ registerAndStoreFixedFields, fixedForm, control, columns }) {
  const dependencies = columns?.filter((c) => c.watchUpdate).map((c) => c.description)
  const dependencyValues = useWatch({ control, name: dependencies, defaultValue: [] })

  // registerAndStoreFixedFields is not included on dependency array since it is not memoized on form hook
  useEffect(() => {
    if (fixedForm && dependencyValues.length) registerAndStoreFixedFields({})
  }, [JSON.stringify(dependencyValues), fixedForm])
}
