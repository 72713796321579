import React, { useRef, useReducer } from 'react'
import { useStore, store } from 'react-context-hook'
import { MdKeyboardArrowDown } from 'react-icons/md'
import classNames from 'classnames'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import ProjectSwitch from '/src/ui/core/menus/project_switch'
import SubprojectSwitch from '/src/ui/core/menus/subproject_switch'
import I18n from '/src/utils/translations'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import { isEmpty } from '/src/utils/object'
import '/src/static/css/environment_switch.css'

function reducer(state, action) {
  const newState = (update) => {
    return {
      ...state,
      ...update
    }
  }

  switch (action.type) {
    case 'changeProject':
      return newState({
        isProjectPopupOpen: false,
        isSubprojectPopupOpen: true,
        newProject: action.newProject,
        clickedToBack: false
      })

    case 'changeSubproject':
      return newState({ isSubprojectPopupOpen: false })

    case 'openPopup':
      if (state.isSubprojectPopupOpen || state.isProjectPopupOpen) return state
      return newState({ isProjectPopupOpen: true })

    case 'clickOutside':
      if (state.clickedToBack) return newState({ clickedToBack: false })
      return newState({ isProjectPopupOpen: false, isSubprojectPopupOpen: false })

    case 'onBack':
      return newState({
        isProjectPopupOpen: true,
        isSubprojectPopupOpen: false,
        clickedToBack: true
      })

    default:
      throw Error()
  }
}

export default function EnvironmentSwitch() {
  const [globalProject] = useStore('project')
  const [globalSubProject] = useStore('subproject')
  const popupButtonRef = useRef(null)
  const [state, dispatch] = useReducer(reducer, {
    isProjectPopupOpen: false,
    isSubprojectPopupOpen: false,
    clickedToBack: false,
    newProject: undefined
  })
  const clearBatchEntities = useClearBatchEntities()

  const handleProjectSelection = (item) => {
    dispatch({ type: 'changeProject', newProject: item })
  }

  const handleSubprojectSelection = (item) => {
    dispatch({ type: 'changeSubproject', newSubproject: item })
    clearBatchEntities()
    store.set('project', state.newProject)
    store.set('subproject', item)
  }

  const getSelectedProject = () => {
    return state.newProject || globalProject
  }

  const isOpen = state.isProjectPopupOpen || state.isSubprojectPopupOpen

  const projectTitle = (globalProject && globalProject.description) || I18n.t('environment.project.select_project')

  const subprojectTitle =
    (globalSubProject && globalSubProject.description) || I18n.t('environment.subproject.select_subproject')

  return (
    <PopupAnchored
      body={
        <React.Fragment>
          <ProjectSwitch project={globalProject} onChange={handleProjectSelection} open={state.isProjectPopupOpen} />
          <SubprojectSwitch
            project={getSelectedProject()}
            onChange={handleSubprojectSelection}
            onBack={() => dispatch({ type: 'onBack' })}
            open={state.isSubprojectPopupOpen}
          />
        </React.Fragment>
      }
      anchorAlign={{ horizontal: 'right', vertical: 'top' }}
      popupAlign={{ horizontal: 'right', vertical: 'top' }}
      forceOpen={isOpen}
      setForceOpen={() => isOpen}
      onClickOutside={() => dispatch({ type: 'clickOutside' })}
      popupButtonRef={popupButtonRef}
    >
      <button
        type="button"
        className={classNames({ 'project-switch switch-button-text': true, active: isOpen })}
        ref={popupButtonRef}
        onClick={() => dispatch({ type: 'openPopup' })}
        data-testid="project-switch-button"
      >
        <div className="project-switch-container">
          <CustomTooltip position="auto" maxLength={35}>
            {projectTitle}
          </CustomTooltip>
          <div className="project-switch-divisor">/</div>
          <CustomTooltip position="auto" maxLength={35}>
            {subprojectTitle}
          </CustomTooltip>
          <span className="subproject-switch-collapser">
            <MdKeyboardArrowDown />
          </span>
        </div>
      </button>
    </PopupAnchored>
  )
}
