import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError, notifyInfo, notifyWarning } from '/src/ui/core/dialogs/notifications'
import { removeUrlParams, hasParams } from '/src/utils/url'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import useLogout from '/src/ui/domain/login/logout_hook'
import '/src/static/css/core/layouts/embed_iframe.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function EmbedIframe({ url, allowedUrls, forbiddenUrls, onPageChange }) {
  const [locale] = useStore('language')
  const [loading, setLoading] = useState(true)
  const [signedIn, setSignedIn] = useState(false)
  const [user] = useStore('user')
  const token = user && user.remember_token
  const [iframeURL, setIframeURL] = useState(url)
  const history = useHistory()
  const logout = useLogout()

  const onReady = (event, allowedLinks) => {
    if (event.data.url.match(new RegExp(allowedLinks.join('|')))) {
      setLoading(false)
      setSignedIn(true)
      if (removeUrlParams(event.data.url) !== removeUrlParams(iframeURL)) setIframeURL(event.data.url)
      return
    }

    notifyError(I18n.t('notification.page_denied'))
    if (forbiddenUrls.length > 0 && event.data.url.match(new RegExp(forbiddenUrls.join('|')))) {
      notifyInfo(I18n.t('login.again'))
      logout()
    }
  }

  const handleIframeOpenPage = (link) => {
    if (removeUrlParams(link) === removeUrlParams(iframeURL)) return

    setIframeURL(link)
    setLoading(true)
  }

  const handleLoading = (event) => {
    if (event.status) {
      dispatch({
        type: BusEvents.SHOW_LOADING_DIALOG,
        payload: { title: event.title, message: event.message }
      })
      return
    }

    dispatch(BusEvents.HIDE_DIALOG)
  }

  const handleNotification = (notification, isLoading) => {
    if (isLoading) return

    switch (notification.type) {
      case 'warning':
      case 'alert':
        notifyWarning({ body: notification.message, title: notification.title })
        break
      case 'error':
      case 'danger':
        notifyError({ body: notification.message, title: notification.title })
        break
      case 'notice':
        break
      default:
        notifyInfo({ body: notification.message, title: notification.title })
        break
    }
  }

  useEffect(() => {
    document.getElementById('embed-iframe').onload = () => {
      const iframe = document.querySelector('.iframe iframe')
      iframe.contentWindow.postMessage({ action: 'sign_in', token: token, url }, SERVER_URL || '*')
      console.info(I18n.t('login.iframe_info', { link: url }))
    }

    return () => {
      document.getElementById('embed-iframe').onload = null
    }
  }, [])

  useEffect(() => {
    const iframeChannel = (event) => {
      switch (event.data.action) {
        case 'ready':
          onReady(event, allowedUrls)
          break
        case 'error':
          const path = history.location.pathname
          const search = history.location.search
          if (path.match(/.*\/users\/[0-9]*\/edit/) || (path.includes('edit') && search === '?page=users')) {
            history.push('/home')
            break
          }
          notifyError({ body: I18n.t('notification.error_cookies'), closeTimeout: 10 })
          break
        case 'notification':
          handleNotification(event.data, loading)
          break
        case 'page_changing':
          setIframeURL(event.data.url)
          setLoading(true)
          if (onPageChange) onPageChange(event.data)
          break
        case 'loading':
          handleLoading(event.data)
          break
        case 'opening':
          handleIframeOpenPage(event.data.url)
          break
        default:
          break
      }
    }

    window.addEventListener('message', iframeChannel, false)
    return () => {
      window.removeEventListener('message', iframeChannel)
    }
  }, [allowedUrls, loading])

  const showLoading = () => loading || !signedIn

  const defaultParams = `hide_menus=true&locale=${locale}`

  const checkDefaultParams = (actualUrl) => {
    if (actualUrl && actualUrl.includes(defaultParams)) return actualUrl

    if (hasParams(actualUrl)) return `${actualUrl}&${defaultParams}`
    return `${actualUrl}?${defaultParams}`
  }

  return (
    <div className="iframe">
      <Iframe
        styles={{ display: showLoading() ? 'none' : 'block' }}
        id="embed-iframe"
        frameborder="0"
        allowFullScreen="true"
        src={checkDefaultParams(iframeURL)}
      />
      {showLoading() && (
        <div className="loading">
          <ThreeDotsLoader />
        </div>
      )}
    </div>
  )
}

EmbedIframe.propTypes = {
  url: PropTypes.string.isRequired,
  allowedUrls: PropTypes.oneOfType([PropTypes.array]).isRequired,
  forbiddenUrls: PropTypes.oneOfType([PropTypes.array]),
  onPageChange: PropTypes.func
}

EmbedIframe.defaultProps = {
  forbiddenUrls: [],
  onPageChange: null
}
