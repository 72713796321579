import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import HandlerDialog from '/src/ui/core/dialogs/handler_dialog'
import AboutVersionModal from '/src/ui/core/menus/about_version_modal'

export default function CenterContainer({ children }) {
  const [globalSubproject] = useStore('subproject')

  return (
    <div className="center-container" key={globalSubproject.id}>
      <AboutVersionModal />
      <HandlerDialog />
      {children}
    </div>
  )
}

CenterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
