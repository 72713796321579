import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/core/popups/column_assigner.css'

export default function ColumnsBatchAssignerAction({ columnTitle, columnValue }) {
  return (
    <div className="columns-batch-assigner__action-description">
      <div className="columns-batch-assigner__column-description">
        <span className="columns-batch-assigner__column-label">
          {I18n.t('dialogs.columns_batch_assigner.add_to_column')}
        </span>
        <CustomTooltip position="bottom" parentTitle>
          <span className="columns-batch-assigner__column-text" title={columnTitle}>
            {`: ${columnTitle}`}
          </span>
        </CustomTooltip>
      </div>
      <div className="columns-batch-assigner__value-description">
        <span className="columns-batch-assigner__value-label">
          {I18n.t('dialogs.columns_batch_assigner.new_value')}
        </span>
        <CustomTooltip position="bottom" parentTitle>
          <span className="columns-batch-assigner__value-text" title={`${columnValue ?? I18n.t('fields.na')}`}>
            {`: ${columnValue ?? I18n.t('fields.na')}`}
          </span>
        </CustomTooltip>
      </div>
    </div>
  )
}

ColumnsBatchAssignerAction.propTypes = {
  columnTitle: PropTypes.string,
  columnValue: PropTypes.oneOfType([PropTypes.any])
}

ColumnsBatchAssignerAction.defaultProps = {
  columnTitle: '',
  columnValue: undefined
}
