import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import { Editor, EditorTools, ProseMirror } from '@progress/kendo-react-editor'
import { InsertImage } from '/src/ui/core/rich_text/insert_image_tool'
import { insertImagePlugin } from '/src/ui/core/rich_text/insert_image_plugin'
import { insertImageFiles } from '/src/ui/core/rich_text/utils'
import '/src/static/css/inputs.css'

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell
} = EditorTools

const DEFAULT_CONTENT =
  '<p><em><span style="font-family: Georgia, serif;">Please</span></em>' +
  '<span style="font-family: Georgia, serif;">, fill your </span>' +
  '<em><u><span style="font-family: Georgia, serif;">work description</span></u></em>' +
  '<span style="font-family: Georgia, serif;"> here on this </span>' +
  '<strong><span style="font-family: Georgia, serif; font-size: x-large;">amazing</span></strong>' +
  '<span style="font-family: Georgia, serif;"> field!</span></p>'

export default function InputRichText({ inputProps }) {
  const {
    id,
    className,
    name,
    placeholder,
    required,
    readOnly,
    height,
    title,
    value,
    onChange,
    onBlur,
    onFocus,
    maxLength
  } = inputProps
  const [inputValue, onInputChange] = useInputChange({ id, value, onChange })
  const [actualValue, setActualValue] = useState(inputValue || '')
  const timer = useRef(null)
  const error = useInputError({ inputValue, title, required })
  const textMaxLength = 32000

  const onImageInsert = (args) => {
    const { files, view, event } = args
    const nodeType = view.state.schema.nodes.image
    const position =
      event.type === 'drop'
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY
          })
        : null
    insertImageFiles({
      view,
      files,
      nodeType,
      position
    })
    return files.length > 0
  }

  const onMount = (event) => {
    const state = event.viewProps.state
    const plugins = [...state.plugins, insertImagePlugin(onImageInsert)]
    const view = new ProseMirror.EditorView(
      {
        mount: event.dom
      },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({
          doc: state.doc,
          plugins
        })
      }
    )

    view.dom.addEventListener('DOMNodeInserted', (e) => {
      const node = e.target
      if (node.nodeName === 'IMG') {
        if (!node.width && node.src) e.target.style.maxWidth = '100%'
      }
    })

    return view
  }

  useEffect(() => {
    timer.current = setTimeout(() => {
      onInputChange({ target: { value: actualValue } })
    }, 500)
    return () => {
      clearTimeout(timer.current)
    }
  }, [actualValue, onInputChange])

  return (
    <React.Fragment>
      <Editor
        id={id}
        className={'form-input-rich-text'}
        name={name}
        placeholder={placeholder}
        value={actualValue}
        onChange={(e) => setActualValue(e.html)}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={textMaxLength}
        disabled={readOnly}
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [Subscript, Superscript],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize,
          FontName,
          FormatBlock,
          InsertImage
        ]}
        contentStyle={{
          margin: 0,
          height: height || 250,
          resize: 'vertical'
        }}
        defaultContent={DEFAULT_CONTENT}
        onMount={onMount}
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputRichText.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    height: PropTypes.number
  }).isRequired
}
