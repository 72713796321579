import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line max-lines-per-function
export default function usePopupAnchoredClick({
  popupRef,
  popupButtonRef,
  setOpenActive,
  setForceOpen,
  onClickOutside
}) {
  const handleClick = (e) => {
    if (popupButtonRef.current && !popupButtonRef.current.contains(e.target)) {
      setOpenActive(false)
    }
  }

  const handleClickOutside = (e) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(e.target) &&
      popupButtonRef.current &&
      !popupButtonRef.current.contains(e.target)
    ) {
      onClickOutside()
      setForceOpen(false)
      setOpenActive(false)
    }
  }

  useEffect(() => {
    if (!setForceOpen) {
      document.addEventListener('click', handleClick)
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }
  }, [popupButtonRef])

  useEffect(() => {
    if (setForceOpen) {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [popupRef])
}

usePopupAnchoredClick.propTypes = {
  popupButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  popupButtonRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setOpenActive: PropTypes.func.isRequired,
  setForceOpen: PropTypes.func,
  onClickOutside: PropTypes.func
}

usePopupAnchoredClick.propTypes = {
  setForceOpen: undefined,
  onClickOutside: () => {}
}
