// eslint-disable-next-line
/* eslint-disable max-lines-per-function */
import I18n from '/src/utils/translations.js'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const route = '/eav_templates?template_type='

const buildLink = (path) => route + path

export const templateSideMenuLinks = () => {
  return [
    {
      link: buildLink('contract_service'),
      title: I18n.t('side_menu.template.contract_service')
    },
    {
      link: buildLink('request'),
      title: I18n.t('side_menu.template.request')
    },
    {
      link: buildLink('scoping'),
      title: I18n.t('side_menu.template.scoping')
    },
    {
      link: buildLink('estimate_service'),
      title: I18n.t('side_menu.template.estimate_service')
    },
    {
      link: buildLink('progress'),
      title: I18n.t('side_menu.template.performance')
    },
    {
      link: buildLink('progress_service'),
      title: I18n.t('side_menu.template.valuation')
    },
    {
      link: buildLink('inspection'),
      title: I18n.t('side_menu.template.inspection')
    },
    {
      link: buildLink('asset'),
      title: I18n.t('side_menu.template.asset')
    },
    {
      link: buildLink('datasheet'),
      title: I18n.t('side_menu.template.datasheet')
    },
    {
      link: buildLink('report'),
      title: I18n.t('side_menu.template.report')
    }
  ]
}

export const filterReportTemplateFromRole = (role, roles, templates) => {
  let newReportTemplates = []

  roles.forEach((rt) => {
    if (rt.role_id === role.id) {
      const report = templates[rt.report_template_id]
      if (report) newReportTemplates.push(report)
    }
  })

  return newReportTemplates
}

export const orderReportTemplates = (templates) => {
  return templates.sort((a, b) => {
    return a.position - b.position
  })
}
