import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { parseISO } from 'date-fns'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/scaffoldings/action_timestamp_badges.css'

export default function ActionTimestampBadges({ action }) {
  const badge = useMemo(() => {
    const createdDate = parseISO(action.created_at)
    const updatedDate = parseISO(action.updated_at)

    const createdAtHours = (new Date() - createdDate) / (1000 * 60 * 60)
    const updatedAtHours = (new Date() - updatedDate) / (1000 * 60 * 60)

    if (createdAtHours < 24 || updatedAtHours < 24) {
      if (createdAtHours < 24) return 'new'
      return 'updated'
    }

    return null
  }, [action])

  return (
    <>
      {badge && (
        <div className={`timestamp-badge ${badge}`} data-testid="timestamp-badge">
          <span className={`timestamp-badge-label ${badge}`}>
            {I18n.t(`scaffolding_part_actions.timestamp_badges.${badge}`)}
          </span>
        </div>
      )}
    </>
  )
}

ActionTimestampBadges.propTypes = {
  action: PropTypes.shape({
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    updated_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
  }).isRequired
}
