import React from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { IntlProvider } from '@progress/kendo-react-intl'
import weekData from 'cldr-core/supplemental/weekData.json'

const countries = [ 'US', 'BR', 'ES', 'DE', 'FR', 'NB', 'TH', 'CN' ]

export default function InputDateStartDay({ children }) {
  const [project] = useStore('project')
  const [locale] = useStore('language')
  const firstWeekDay = !project?.start_day_of_week || project.start_day_of_week === 'mon' ? 'mon' : 'sun'

  const firstDay = weekData.supplemental.weekData.firstDay
  countries.forEach((country) =>
    firstDay[country] = firstWeekDay
  )

  return <IntlProvider locale={locale || 'en'}>{children}</IntlProvider>
}

InputDateStartDay.propTypes = {
  children: PropTypes.element.isRequired
}
