import React, { useCallback, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'
import { isEmpty } from '/src/utils/object'

export default function useEstimateStatusFilter() {
  const [statusFilterIds, setStatusFilterIds] = useStore('estimate_status_filter')
  const [popupOpened, setPopupOpened] = useState(false)

  const progressServicesStatuses = useMemo(
    () => ({
      9: {
        id: 9,
        active: true,
        description: 'Pending Inspection',
        filtered: false,
        i18n_id: 'inspected_flag'
      }
    }),
    []
  )

  const statusFilterLabels = useMemo(
    () =>
      !isEmpty(progressServicesStatuses) &&
      statusFilterIds &&
      statusFilterIds.map(
        (id) =>
          progressServicesStatuses[id] && I18n.t(`estimate_services.statuses.${progressServicesStatuses[id].i18n_id}`)
      ),
    [statusFilterIds, progressServicesStatuses]
  )

  const statusFilter = useMemo(
    () =>
      statusFilterIds.length > 0 && {
        type: 'where',
        column: 'inspected_flag',
        operator: 'eq',
        value: 'inspected_flag'
      },
    [statusFilterIds]
  )

  const clearFilters = useCallback(() => {
    setStatusFilterIds([])
  }, [setStatusFilterIds])

  const changeFilter = useCallback(
    (status) => {
      if (statusFilterIds.includes(status.id)) {
        setStatusFilterIds(statusFilterIds.filter((id) => id !== status.id))
      } else {
        setStatusFilterIds([...statusFilterIds, status.id])
      }
    },
    [statusFilterIds, setStatusFilterIds]
  )

  const statusFilterButton = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={Object.values(progressServicesStatuses)}
        statusDescription={(status) => I18n.t(`estimate_services.statuses.${status.i18n_id}`)}
        isStatusSelected={(status) => statusFilterIds.includes(status.id)}
        onChangeFilter={changeFilter}
        onClearFilters={clearFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [progressServicesStatuses, statusFilterIds, changeFilter, clearFilters, popupOpened]
  )

  return {
    statusFilterButton,
    selectedStatuses: statusFilterLabels,
    statusFilter,
    clearFilters
  }
}
