/* eslint-disable camelcase */

import React from 'react'
import I18n from '/src/utils/translations'
import PropTypes from 'prop-types'

const AdditionalInfo = ({
  system,
  mainWorkCentreMwc,
  functionalLocation,
  employeeName,
  employeeLastName,
  getJobcardStatus,
  requestStatus
}) => (
  <div className="estimates-pdf-header__additional-info">
    <div>
      <strong>{I18n.t('estimate.pdf_header.system')}: </strong>
      {system}
    </div>
    <div>
      <strong>{I18n.t('estimate.pdf_header.main_work_center')}: </strong>
      {mainWorkCentreMwc}
    </div>
    <div>
      <strong>{I18n.t('estimate.pdf_header.functional_location')}: </strong>
      {functionalLocation}
    </div>
    <div>
      <strong>{I18n.t('estimate.pdf_header.responsible_engineer')}: </strong>
      {employeeName || I18n.t('estimate.pdf_header.not_defined')} {employeeLastName || ''}
    </div>
    <div>
      <strong>{I18n.t('estimate.pdf_header.jobcard_status')}: </strong>
      {getJobcardStatus(requestStatus?.i18n_id)}
    </div>
  </div>
)

AdditionalInfo.propTypes = {
  system: PropTypes.string,
  mainWorkCentreMwc: PropTypes.string,
  functionalLocation: PropTypes.string,
  employeeName: PropTypes.string,
  employeeLastName: PropTypes.string,
  getJobcardStatus: PropTypes.func,
  requestStatus: PropTypes.shape({
    i18n_id: PropTypes.string
  })
}

export default AdditionalInfo
