import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import StatusFilterPopup from '/src/ui/core/popups/status_filter_popup'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function StatusFilterIcon({
  labelClassName,
  statuses,
  statusDescription,
  isStatusSelected,
  onClearFilters,
  onChangeFilter,
  popupOpened,
  setPopupOpened,
  isFilterSelected,
  icon,
  filterType,
  children
}) {
  const popupButtonRef = useRef(null)

  return (
    <CustomTooltip position="bottom" parentTitle>
      <PopupAnchored
        body={
          <StatusFilterPopup
            labelClassName={labelClassName}
            statuses={statuses}
            statusDescription={statusDescription}
            isStatusSelected={isStatusSelected}
            setPopupOpened={setPopupOpened}
            onClearFilters={onClearFilters}
            onChangeFilter={onChangeFilter}
            filterType={filterType}
          >
            {children}
          </StatusFilterPopup>
        }
        popupButtonRef={popupButtonRef}
        forceOpen={popupOpened}
        setForceOpen={setPopupOpened}
        onClick={() => setPopupOpened(!popupOpened)}
      >
        <button
          id={`open-${filterType}-filter-popup-button`}
          data-testid={`${filterType}-filter-button`}
          type="button"
          title={I18n.t(`actions.${filterType}_filter`)}
          className={`btn-icon \
            ${popupOpened ? 'active' : ''} \
            ${isFilterSelected ? 'selected-filter' : ''} \
            grid-header-icon`}
          ref={popupButtonRef}
        >
          {icon}
        </button>
      </PopupAnchored>
    </CustomTooltip>
  )
}

StatusFilterIcon.propTypes = {
  labelClassName: PropTypes.string,
  statuses: PropTypes.array,
  statusDescription: PropTypes.func.isRequired,
  isStatusSelected: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  popupOpened: PropTypes.bool,
  setPopupOpened: PropTypes.func.isRequired,
  isFilterSelected: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  filterType: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

StatusFilterIcon.defaultProps = {
  labelClassName: '',
  statuses: [],
  popupOpened: false,
  children: null
}
