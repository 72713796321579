import { useEffect, useState } from 'react'
import useFetch from '/src/hooks/api/fetch'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'

export default function useFirstStringFormulaFromTemplate(parentTemplateId) {
  const [eavTemplate, setEavTemplate] = useState()
  const [firstStringFormulaColumn, setFirstStringFormulaColumn] = useState()

  const { fetch } = useFetch()
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()

  useEffect(() => {
    if (!parentTemplateId || (eavTemplate && eavTemplate.parent_template_id === parentTemplateId)) return

    const fetchEavColumns = async () => {
      const eavTemplateParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { parent_template_id: parentTemplateId } }
      }

      const {
        data: { data: eavTemplates }
      } = await fetch('eav_templates', eavTemplateParams)

      const eavTemplate =
        eavTemplates.length > 1
          ? eavTemplates.filter(({ template_type: type }) => type === 'estimate_service')[0]
          : eavTemplates[0]

      setEavTemplate(eavTemplate)

      const eavColumnsParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: {
            eav_template_id: eavTemplate.id
          }
        }
      }

      const { data: eavColumns } = await fetch('eav_columns', eavColumnsParams)

      const newInspectedServiceColumn = filterFirstFlexibleColumn(eavColumns.data)

      setFirstStringFormulaColumn(newInspectedServiceColumn)
    }

    fetchEavColumns()
  }, [fetch, parentTemplateId, filterFirstFlexibleColumn, eavTemplate])

  return firstStringFormulaColumn
}
