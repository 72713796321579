import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { MdKeyboardArrowDown } from 'react-icons/md'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'

export default function TopMenuPopupItem({ label, children, isActive }) {
  const popupItemRef = useRef()

  return (
    <div className={`header-menu-item ${isActive && 'active'}`}>
      <PopupAnchored body={children} popupButtonRef={popupItemRef} disabled={!children} popupClass={'header-popup'}>
        <button ref={popupItemRef} className="header-menu-popup-item" type="button">
          {label}
          <span className="arrow-down-collapser">
            <MdKeyboardArrowDown />
          </span>
        </button>
      </PopupAnchored>
    </div>
  )
}

TopMenuPopupItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]),
  isActive: PropTypes.bool
}

TopMenuPopupItem.defaultProps = {
  items: [],
  children: undefined,
  isActive: false
}
