import lodash from 'lodash'

export const REQUEST_ALIAS = ['request', 'main_work_order_erect', 'main_work_order_dismantle', 'work_order']
export const ALLOWED_SAVE_NEW_MODELS = ['progress', 'progress_service']
export const ALLOWED_FORM_TYPES_SAVE_NEW = ['new', 'duplicate']
export const GRID_FORM_MODAL_WIDTH = 90

const requestByDisciplineQuery = (currentQuery, disciplineItem, baseDisciplines) => {
  const { discipline_id: disciplineId } = disciplineItem || {}
  const baseDisciplinesIds = baseDisciplines.filter((discipline) => discipline).map(({ id }) => id)

  const disciplineQuery = []

  if (baseDisciplinesIds && !baseDisciplinesIds.includes(disciplineId)) disciplineQuery.push(baseDisciplinesIds)

  if (disciplineId && !baseDisciplinesIds.includes(disciplineId)) disciplineQuery.push(disciplineId)

  if (disciplineQuery.length > 0) {
    return {
      ...currentQuery,
      where: { ...currentQuery?.where, discipline_id: disciplineQuery.join(',') }
    }
  }

  return currentQuery
}

export const addRequestByDisciplineQueryToColumns = (columns, eavTemplate, baseDisciplines) => {
  return columns.map((column) => {
    const description = column.description.split('_id')

    return description.length === 2 && REQUEST_ALIAS.includes(description[0])
      ? {
          ...column,
          searchExtraQuery: requestByDisciplineQuery(column.searchExtraQuery, eavTemplate, baseDisciplines)
        }
      : column
  })
}

export const addRequestByDisciplineQuery = (model, eavTemplate, generalDiscipline) => {
  const { cloneDeep } = lodash
  const nextScopeModel = cloneDeep(model)
  const { columns } = nextScopeModel
  const requestColumn = columns.find(({ description }) => REQUEST_ALIAS.includes(description))
  requestColumn.searchExtraQuery = requestByDisciplineQuery(requestColumn.searchExtraQuery, eavTemplate, [
    generalDiscipline
  ])
  return nextScopeModel
}
