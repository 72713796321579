/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

/**
 * This custom hook implements a way of creating sections for schedule forms
 * @return [dataItem, setDataItem, sections]
 */
export default function useAuthorizeRequestPopupFields() {
  const [dataItem, setDataItem] = useState({})
  const [employees] = useStore('employees')
  const [sections, setSections] = useState([])
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')

  const defaultDate = () => dataItem.order_date

  const defaultResponsible = () => {
    if (!dataItem) return null
    const employeesArray = Object.values(employees)
    const responsible = employeesArray.find((employee) => {
      return employee.id === dataItem.responsible_id
    })
    return responsible ? responsible.id : null
  }

  const defaultComments = () => dataItem.comments

  const onChange = (description, value) => {
    if (dataItem[description] === value) return
    const item = { ...dataItem }
    item[description] = value
    setDataItem(item)
  }

  const updateSections = () => {
    const newSections = [
      {
        id: 5,
        eav_columns: [
          {
            id: 1,
            description: 'order_date',
            title: I18n.t('orders.date'),
            onChange,
            type: 'date',
            default: defaultDate()
          },
          {
            id: 2,
            description: 'responsible_id',
            title: I18n.t('orders.responsible'),
            foreignKey: 'employees',
            foreignAttribute: 'responsible_id',
            searchFields: ['name', 'last_name'],
            searchExtraQuery: { where: { '[work_positions][operational]': 1 } },
            searchRoute: 'employees',
            onChange,
            textDisplayFields: ['full_name'],
            textField: 'full_name',
            type: 'search',
            default: defaultResponsible()
          },
          {
            id: 3,
            description: 'comments',
            title: I18n.t('orders.comments'),
            onChange,
            type: 'multiline_text',
            maxLength: 255,
            default: defaultComments()
          },
          {
            id: 4,
            description: 'file',
            title: I18n.t('orders.add_evidence'),
            subtitle: I18n.t('orders.drag_and_drop'),
            onChange,
            multiple: false,
            autoUpload: true,
            // eslint-disable-next-line max-len
            saveUrl: `${SERVER_URL}/api/v1/orders/${dataItem.id}/attach_file.json?project_id=${globalProject.id}&subproject_id=${globalSubproject.id}`,
            removeUrl: `${SERVER_URL}/api/v1/orders/${dataItem.id}/delete_file.json?project_id=${globalProject.id}&subproject_id=${globalSubproject.id}`,
            allowedExtensions: ['pdf'],
            type: 'upload'
          }
        ]
      }
    ]
    setSections(newSections)
  }

  useEffect(() => {
    if (Object.entries(employees).length > 0 && Object.entries(dataItem).length > 0) {
      updateSections()
    }
  }, [employees, dataItem])

  return [dataItem, setDataItem, sections]
}
