import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import AuthRequired from '/src/routes/auth_required'
import I18n from '/src/utils/translations'
import usePreferences from '/src/ui/core/grid/preferences'

export default function Routers({ child, mock }) {
  const [, setIsUserLoaded] = useState(false)
  const [locale] = useStore('language')

  const { saveLocalPreferences } = usePreferences()

  I18n.locale = locale || 'en'

  useEffect(() => {
    I18n.locale = locale || 'en'
  }, [locale])

  const onLoadingUser = useCallback(
    (user) => {
      setIsUserLoaded((prevIsUserLoaded) => {
        if (prevIsUserLoaded) return prevIsUserLoaded
        if (user.preferences) saveLocalPreferences(JSON.parse(user.preferences))
        return true
      })
    },
    [saveLocalPreferences]
  )

  const cloneElement = (match) => {
    const newChildrenProps = { ...child.props }
    Object.keys(child.props).forEach((key) => {
      newChildrenProps[key] = match.params[child.props[key]] || newChildrenProps[key]
    })
    return React.cloneElement(child, newChildrenProps)
  }

  const renderPage = (match) => {
    if (mock) {
      return cloneElement(match)
    }
    return <AuthRequired match={match} onLoadingUser={onLoadingUser} />
  }

  return (
    <Switch>
      <Route exact path="/" render={({ match }) => renderPage(match)} />
      <Route exact path="/:resource/:collection?" render={({ match }) => renderPage(match)} />
      <Route exact path="/:resource/:id/:collection?" render={({ match }) => renderPage(match)} />
    </Switch>
  )
}

Routers.propTypes = {
  child: PropTypes.element,
  mock: PropTypes.bool
}

Routers.defaultProps = {
  child: null,
  mock: false
}
