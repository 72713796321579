export const SAVE_PREFERENCES_TIMER = 1000
export const SAVED_LABEL_TIMER = 2000

export const STORE_PREFERENCES_STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SAVED: 'saved',
  FAILED: 'failed'
}

export default function storePreferencesRouteParams(user, preferences) {
  return {
    requestAction: 'UPDATE',
    httpAction: 'put',
    resourceId: user.id,
    additionalResource: { path: 'store_preferences' },
    data: { user: { preferences } }
  }
}
