import React from 'react'
import PropTypes from 'prop-types'
import { MdChevronLeft, MdChevronRight, MdClose } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import Block from '/src/ui/core/grid/side_panel/block'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'

export default function BlocksSidePanel({ blocks, closable, onClose, title, children }) {
  const [isSidePanelHidden] = useStore('side_panel_hidden')

  const blocksToRender =
    blocks
      ?.filter(({ visible }) => visible !== false)
      .map((block) => <Block block={block} key={block.key || block.title} />) || []

  const closePanel = () => {
    onClose()
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
  }

  return (
    <React.Fragment>
      <aside className="side-panel" role="side-panel">
        {isSidePanelHidden && (
          <CustomTooltip position="left" parentTitle>
            <button
              className="side-panel-expand"
              onClick={() => dispatch(BusEvents.TOGGLE_SIDE_PANEL)}
              role="expand-side-panel"
              title={I18n.t('side_panel.expand')}
            >
              <MdChevronLeft />
            </button>
          </CustomTooltip>
        )}
        <div className="side-panel-header">
          {(closable || title) && (
            <div className="side-panel-header-default">
              {title && (
                <div className="side-panel-name" role="side-panel-title">
                  {title}
                </div>
              )}
              {closable && (
                <div className="side-panel-header-buttons">
                  {!isSidePanelHidden && (
                    <CustomTooltip position="top" parentTitle>
                      <button
                        className="side-panel-hide"
                        onClick={() => dispatch(BusEvents.TOGGLE_SIDE_PANEL)}
                        role="hide-side-panel"
                        title={I18n.t('side_panel.collapse')}
                      >
                        <MdChevronRight />
                      </button>
                    </CustomTooltip>
                  )}
                  <CustomTooltip position="top" parentTitle>
                    <button
                      className="side-panel-close"
                      onClick={closePanel}
                      role="side-panel-close"
                      title={I18n.t('side_panel.close')}
                    >
                      <MdClose />
                    </button>
                  </CustomTooltip>
                </div>
              )}
            </div>
          )}
        </div>
        {children || <div className="side-panel-content">{blocksToRender}</div>}
      </aside>
    </React.Fragment>
  )
}

BlocksSidePanel.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      border: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.object]).isRequired,
      radius: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  ),
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.element
}

BlocksSidePanel.defaultProps = {
  closable: true,
  onClose: () => {},
  title: null,
  blocks: null,
  useTabs: false,
  tabs: null,
  children: null
}
