/* eslint-disable max-lines-per-function */
import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useStore } from 'react-context-hook'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import {
  convertKendoToColumnFilters,
  convertQueryFilters,
  deletedColumnsOnFilters,
  removeColumnFiltersByColumn,
  removeKendoFiltersByColumn,
  templateFilters
} from '/src/ui/core/grid/filtering/grid_filters_operations'
import { objectEquals } from '/src/utils/object'

const UNSTORABLE_FILTERS = [
  'progress][request_id',
  'eav_template_id',
  'request_status_id',
  'parent_id',
  'request_id',
  'id',
  'scope_id',
  'progress_id',
  'progress_service_status_id'
]

export default function useGridFilters({ templateId, defaultFilters, onChange, dataSourceTotal }) {
  const batchedEntities = useEntitiesCache()
  const [locale] = useStore('language')

  const prevFiltersByTemplate = useRef()
  const [filtersByTemplate, setFiltersByTemplate] = useStore('template_filters')

  const [kendoGridFilters, setKendoGridFilters] = useState(templateFilters(templateId, filtersByTemplate))
  const [columnFilters, setColumnFilters] = useState(templateFilters(templateId, filtersByTemplate, 'columnFilters'))

  const queryFilters = useMemo(() => convertQueryFilters([...defaultFilters, ...columnFilters]), [
    defaultFilters,
    columnFilters
  ])

  useEffect(() => {
    if (!onChange) return
    onChange({
      total: dataSourceTotal,
      filters: [...defaultFilters, ...columnFilters]
    })
  }, [defaultFilters, columnFilters, onChange, dataSourceTotal])

  useEffect(() => {
    if (!templateId) return

    const storeableKendoFilters = kendoGridFilters.filter(({ field, column }) => {
      const fieldColumn = field || column
      return !UNSTORABLE_FILTERS.includes(fieldColumn)
    })

    const storeableColumnFilters = columnFilters.filter(({ field, column }) => {
      const fieldColumn = field || column
      return !UNSTORABLE_FILTERS.includes(fieldColumn)
    })

    const hasFilters = storeableKendoFilters.length > 0

    const nextFiltersByTemplate = {
      ...filtersByTemplate,
      [templateId]: hasFilters
        ? {
            kendoGridFilters: storeableKendoFilters,
            columnFilters: storeableColumnFilters
          }
        : undefined
    }

    if (objectEquals(prevFiltersByTemplate.current, nextFiltersByTemplate)) return

    prevFiltersByTemplate.current = nextFiltersByTemplate

    setFiltersByTemplate(nextFiltersByTemplate)
  }, [templateId, kendoGridFilters, columnFilters, filtersByTemplate])

  const clearColumnFilters = useCallback((columnNames) => {
    setKendoGridFilters((prevKendoGridFilters) => removeKendoFiltersByColumn(columnNames, prevKendoGridFilters))
    setColumnFilters((prevColumnFilters) => removeColumnFiltersByColumn(columnNames, prevColumnFilters))
  }, [])

  const clearDeletedColumnFilters = useCallback(
    (currentColumns) => {
      if (!kendoGridFilters || kendoGridFilters.length === 0) return

      const deletedColumnNames = deletedColumnsOnFilters(currentColumns, kendoGridFilters)

      if (deletedColumnNames.length === 0) return
      clearColumnFilters(deletedColumnNames)
    },
    [kendoGridFilters, clearColumnFilters]
  )

  const onFilterChange = useCallback(
    (event, gridColumns) => {
      if (event.filter === null) {
        setKendoGridFilters([])
        setColumnFilters([])
        return
      }

      if (event.nativeEvent && event.nativeEvent.type === 'reset') {
        clearColumnFilters([event.syntheticEvent.column])
        return
      }

      setKendoGridFilters(event.filter.filters)

      const nextColumnFilters = convertKendoToColumnFilters({
        kendoGridFilters: event.filter.filters,
        gridColumns,
        batchedEntities,
        locale
      })
      setColumnFilters(nextColumnFilters)
    },
    [clearColumnFilters, batchedEntities, locale]
  )

  const hasColumnFilters = useCallback(() => {
    if (!kendoGridFilters) return false
    return kendoGridFilters.length > 0
  }, [kendoGridFilters])

  const clearAllColumnFilters = useCallback(() => {
    const allFilters = [...kendoGridFilters, ...columnFilters]
    const allFiltersColumns = allFilters.map(({ field, column }) => field || column)
    clearColumnFilters(allFiltersColumns)
  }, [clearColumnFilters, columnFilters, kendoGridFilters])

  return {
    kendoGridFilters,
    queryFilters,
    onFilterChange,
    hasColumnFilters,
    clearColumnFilters,
    clearAllColumnFilters,
    clearDeletedColumnFilters
  }
}
