import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import I18n from '/src/utils/translations'
import TopMenuPopupItem from '/src/ui/core/header/top_menu_popup_item'
import items from '/src/ui/core/header/top_menu_items'

export default function TopMenuWorkflowButton() {
  const { pathname } = useLocation()
  const workflowItems = items().slice(1)

  const isActive = workflowItems.map(({ name }) => name).includes(pathname.substr(1))

  return (
    <TopMenuPopupItem label={I18n.t('top_menu.workflow')} isActive={isActive}>
      {workflowItems.map((item) => (
        <Link key={item.name} to={`/${item.name}`}>
          {item.title}
        </Link>
      ))}
    </TopMenuPopupItem>
  )
}
