import React from 'react'
import PropTypes from 'prop-types'

export default function PlanningChartLegend({ series, onClick }) {
  return (
    <div className="legend-wrapper">
      {series.map((item) => (
        <div
          className="legend"
          role="button"
          aria-hidden="true"
          tabIndex={0}
          key={item.name}
          style={{ opacity: item.visible ? 1 : 0.5 }}
          onClick={() => onClick(item)}
        >
          <span
            className="legend-bullet"
            style={{ backgroundColor: item.line.color, opacity: item.visible ? 1 : 0.5 }}
          />
          <span className="legend-name">{item.name}</span>
        </div>
      ))}
    </div>
  )
}

PlanningChartLegend.propTypes = {
  series: PropTypes.oneOfType([PropTypes.array]),
  onClick: PropTypes.func.isRequired
}

PlanningChartLegend.defaultProps = {
  series: []
}
