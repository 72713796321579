import { useState, useEffect, useRef } from 'react'
import useInputChange from '/src/ui/core/inputs/input_change'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'
import I18n from '/src/utils/translations'
import { isObject } from '/src/utils/object'
import { removeLineFeed } from '/src/utils/string'

/**
 * It will make a dumb radio button or select list work as a real form input
 * @param inputProps
 *   id (String) - HTML id attribute. E.g. 'my-id'
 *   value (Any) - Default value to be used on the combobox. E.g. 1
 *   onChange (Function) - Callback related to the 'react-hook-form'. Called when the user changes the item.
 *   searchRoute (String) - The API route used on the search. E.g. 'employees'
 *   searchExtraQuery (Object) - Query to be used on the search, it can filter the results. E.g. { where: { '[work_positions][operational]': 1 } }
 *   searchFields (Array) - Array of fields used to search when the user writes. E.g. ['name', 'last_name']
 *   searchOperator (String) - String of the operator of queryable to be used when typing. E.g. 'contains'
 *   textDisplayFields (Array) - The fields that display on the combobox result. E.g. ['full_name']
 *   onFetch (Function) - Callback to know when the component has fetched
 *   keyField (String) - The main field to be used for the search. E.g. 'id'
 *   distinct (Boolean) - Show distinct values on the search. E.g. true
 *   multiple (Boolean) - Defines if selectedValue should be an array
 * @param onInputValueChange (Function)
 *   Callback called when the user changes the item.
 * @return
 *   searchData (Array) - The response data. E.g. ['felipe', 'mark', 'sandro']
 *   selectedValue (Any) - The value the user selected. E.g. { id: 1, name: 'felipe' }
 *   setComboValue (Function) - Callback that set the value for the combobox. Should be called when a new value is selected.
 *   setSearchFilter (Function) - Callback that refreshes the combobox API query. Should be called when the combobox filter is changed.
 */
// eslint-disable-next-line max-lines-per-function
export default function useInputSearch(inputProps, onInputValueChange) {
  const {
    id,
    value,
    onChange,
    searchRoute,
    searchExtraQuery,
    searchFields,
    textDisplayFields,
    onFetch,
    multiple,
    searchOperator = 'containsOr',
    keyField = 'id',
    distinct = false,
    autoReset = true
  } = inputProps

  const [inputValue, setInputValue] = useInputChange({ id, value, onChange })

  const [searchData, setSearchData] = useState([])
  const [selectedValue, setSelectedValue] = useState(inputValue)
  const [invalidValueError, setInvalidValueError] = useState()
  const [filter, setFilter] = useState({
    filter: { value: isPresent(value) && typeof value === 'string' ? value : '' }
  })
  const [textToSearch, setTextToSearch] = useState(inputValue && inputValue['computed_text_field'])

  const getResource = useFetchAPI(searchRoute)
  const getItem = useFetchAPI(searchRoute)

  const timer = useRef()
  const displayFields = useRef([])

  const fetchRoute = (e) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { ...searchExtraQuery }
    }

    if (e.filter.value) {
      const filters = searchFields.reduce((filter, f) => {
        return { ...filter, [f]: e.filter.value }
      }, {})
      params.query = { ...params.query, [searchOperator]: filters }
    }
    getResource.fetchAPI(params)
  }

  const distinctData = (newData) => {
    const newDataFiltered = newData.filter((item) => !isBlank(item.computed_text_field))
    if (!distinct) return newDataFiltered

    const distinctKeys = {}
    const distinctItems = []
    newDataFiltered.forEach((x) => {
      if (distinctKeys[x[keyField]]) return
      distinctKeys[x[keyField]] = true
      distinctItems.push(x)
    })

    return distinctItems
  }

  const setValueFromData = (dataArray) => {
    if (dataArray.length === 0) return
    if (isBlank(inputValue) && isBlank(selectedValue)) return

    if (inputValue === null) {
      setSelectedValue([])
      return
    }

    let newValue
    const inputValueArr = Array.isArray(inputValue) ? inputValue : [inputValue]
    if (multiple) {
      const indexedValues = {}
      inputValueArr.forEach((v) => {
        if (!v) return
        if (v[keyField]) {
          indexedValues[v[keyField].toString()] = v
          return
        }

        indexedValues[v.toString()] = v
      })
      newValue = dataArray.filter((item) => indexedValues[item[keyField].toString()])
    } else {
      newValue = dataArray.find(
        (item) =>
          item &&
          (item[keyField].toString() === inputValue.toString() ||
            (isObject(inputValue) && `${item[keyField]}` === `${inputValue[keyField]}`))
      )
    }

    if (isBlank(newValue)) return

    if (multiple && selectedValue) {
      selectedValue.forEach((select) => {
        if (['string', 'number'].includes(typeof select)) {
          return
        }
        const found = newValue.find((item) => item && item[keyField].toString() === select[keyField].toString())
        const isSelectedOnInput = inputValue.find(
          (item) =>
            item === select[keyField].toString() ||
            (item[keyField] && item[keyField].toString() === select[keyField].toString())
        )
        if (!found && isSelectedOnInput) {
          newValue.push(select)
        }
      })
    }
    if (multiple) {
      newValue = newValue.sort((a, b) => {
        return inputValueArr.indexOf(a[keyField]) - inputValueArr.indexOf(b[keyField])
      })
    }
    setSelectedValue(newValue)
  }

  const setComboValue = (e) => {
    if (e.target.value === null) return

    if (multiple) {
      const event = { target: { value: e.target.value.map((val) => val[keyField]), all_values: e.target.value } }
      setInputValue(event)
      setInvalidValueError()

      if (onInputValueChange) onInputValueChange(event)
      return
    }

    const event = {
      target: {
        value: e.target.value[keyField],
        all_values: e.target.value
      }
    }
    setInputValue(event)
    setInvalidValueError()

    if (onInputValueChange) onInputValueChange(event)
  }

  const setSearchFilter = (e) => {
    if (multiple && e.nativeEvent.type !== 'input') return
    setTextToSearch(e.filter.value)

    clearTimeout(timer.current)
    if (!multiple && autoReset) setInputValue({ target: { value: '' } })
    timer.current = setTimeout(() => setFilter(e), 500)
  }

  const getMissingFilter = (missingItems) => {
    return missingItems.map((item) => {
      return typeof item === 'object' ? item[keyField] : item.toString()
    })
  }

  const checkValueInData = (newData) => {
    let initialValue
    let missingItems = []
    const isArray = Array.isArray(inputValue)

    if (isArray) {
      const arr = newData.map((item) => item[keyField].toString())
      missingItems = inputValue.filter((v) => v && !arr.includes(v[keyField]) && !arr.includes(v))
      initialValue = missingItems.length == 0 ? 1 : null
    } else {
      initialValue = newData.find((item) => item && item[keyField].toString() === inputValue.toString())
    }

    if (!isBlank(initialValue)) return

    const filter = isArray ? getMissingFilter(missingItems) : inputValue.toString()

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { ...searchExtraQuery, where: { ...searchExtraQuery.where, [keyField]: filter } }
    }
    getItem.fetchAPI(params)
  }

  useEffect(() => {
    fetchRoute(filter)
  }, [filter])

  useEffect(() => {
    if (textDisplayFields && textDisplayFields.length > 0 && textDisplayFields[0])
      displayFields.current = textDisplayFields
  }, [textDisplayFields])

  useEffect(() => {
    setValueFromData(searchData)
    if (inputValue !== undefined && !multiple)
      setTextToSearch(inputValue ? inputValue && inputValue.computed_text_field : textToSearch)
    if (filter.value && !multiple) setFilter({ filter: { value: '' } })
  }, [inputValue])

  useEffect(() => {
    if (getResource.status !== 'SUCCESS') return

    const newData = getResource.responseData.data.map((dataItem) => {
      const processedField = displayFields.current
        .map((field) => dataItem[field])
        .filter((field) => isPresent(field))
        .join(' - ')

      const computedTextField = removeLineFeed(processedField)
      return { ...dataItem, computed_text_field: computedTextField }
    })

    const newDistinctData = distinctData(newData)

    setSearchData(newDistinctData)
    setValueFromData(newDistinctData)

    if (multiple && inputValue) checkValueInData(newDistinctData)

    if (onFetch) onFetch(newDistinctData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResource.status, getResource.responseData])

  useEffect(() => {
    if (getItem.status !== 'SUCCESS') return
    const newData = getItem.responseData.data.map((x) => {
      return {
        ...x,
        computed_text_field: removeLineFeed(
          displayFields.current
            .map((f) => x[f])
            .filter((y) => isPresent(y))
            .join(' - ')
        )
      }
    })

    const newItem = newData[0]
    if (!newItem) {
      setInvalidValueError(I18n.t('form.inputs.errors.not_found', { value: inputValue }))
      return
    }
    setInvalidValueError(undefined)

    const isArray = Array.isArray(newItem)
    const newItems = isArray ? [...searchData, ...newItem] : [...searchData, newItem]

    setSearchData(distinctData(newItems))
    setValueFromData(distinctData(newItems))
  }, [getItem.status, getItem.responseData])

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  return {
    textToSearch,
    searchData,
    selectedValue,
    setComboValue,
    setSearchFilter,
    invalidValueError
  }
}
