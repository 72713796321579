import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import '/src/static/css/core/forms/form_side_panel.css'

export default function FormSectionLabel({ id, index, title, isFocused, onClick }) {
  const labelClass = classNames({
    'form-section-label': true,
    'form-section-focused': isFocused
  })

  return (
    <a href={`#${id}`}>
      <button className={labelClass} onClick={() => onClick(id)}>
        <div className="form-section-index">{index + 1}</div>
        <span>{title}</span>
      </button>
    </a>
  )
}

FormSectionLabel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  onClick: PropTypes.func
}

FormSectionLabel.defaultProps = {
  isFocused: false,
  onClick: () => {}
}
