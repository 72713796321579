import React, { useMemo, useState } from 'react'
import { MdAssignment, MdChat, MdDateRange, MdModeEdit, MdThumbsUpDown } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { FaCalendarTimes, FaUserEdit } from 'react-icons/fa'
import { isEmpty } from 'lodash'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import { isBlankOrZero } from '/src/utils/boolean_refinements'
import InspectionModel from '/src/models/inspection'
import { getDaysCounter, formatDate } from '/src/utils/project_formatter'
import { getDescriptionField } from '/src/models/concerns/eav_section'
import { checkInspectionFinished, getStatus, isOverdue } from '/src/models/concerns/inspection_filter'
import Picture from '/src/ui/core/fields/picture'
import TooltipIconButton from '/src/ui/core/buttons/tooltip_icon_button'
import TimelineBlockGroup from '/src/ui/core/blocks/timeline_block_group'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useRelatedInspectionsUtils from '/src/ui/domain/inspections/related_inspections_utils'
import InspectionTimelineFilters from '/src/ui/domain/inspections/inspection_timeline_filters'

const collapseParams = {
  isCollapsible: true,
  picturesPerRow: 3,
  picturesSize: 3.5
}

export default function RelatedInspectionsBlock({ dataItem, isLoading, sections, isInspectionsTab, showButtons }) {
  const [employees] = useStore('employees')
  const [statuses] = useStore('inspect_statuses')
  const [project] = useStore('project')

  const [showLoading, setShowLoading] = useState(false)
  const [relatedInspections, setRelatedInspections] = useState([])

  const [filter, setFilter] = useState([])
  const [filterGroup, setFilterGroup] = useState(null)
  const [indexifiedTemplates, setIndexifiedTemplates] = useState()
  const [inspectionCategories, setInspectionCategories] = useState()

  const [indexifiedSections, setIndexifiedSections] = useState()

  useRelatedInspectionsUtils({
    dataItem,
    setShowLoading,
    setRelatedInspections,
    setIndexifiedTemplates,
    setIndexifiedSections,
    setInspectionCategories,
    isInspectionsTab
  })

  const getComments = (item) => {
    const itemSection = sections ?? [indexifiedSections[item.eav_template_id]]
    const descriptionField = getDescriptionField(itemSection)
    return item[descriptionField]
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'yellow'
      case 'rejected':
        return 'red'
      case 'approved':
        return 'green'
      default:
        return ''
    }
  }

  const loadingOrNoData = useMemo(() => {
    if (showLoading || isLoading) return <ThreeDotsLoader />
    if (relatedInspections.length === 0)
      return (
        <div className="detail-summary">
          <span className="summary-label empty-state">{I18n.t('inspections.related_inspections.no_related')}</span>
        </div>
      )
    return undefined
  }, [showLoading, isLoading, relatedInspections])

  return (
    <div className={isInspectionsTab ? '' : 'timeline-block'}>
      {isInspectionsTab && !showLoading && (
        <InspectionTimelineFilters
          filter={filter}
          setFilter={setFilter}
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
          indexifiedTemplates={indexifiedTemplates}
          inspectionCategories={inspectionCategories}
        />
      )}
      {loadingOrNoData ||
        relatedInspections.map((timelineItem) => {
          const responsible = employees[timelineItem.responsible_id]
          const status = getStatus(timelineItem, statuses)
          const overdue = isOverdue(timelineItem.inspect_date, status)
          const comments = getComments(timelineItem)
          const template = indexifiedTemplates?.[timelineItem.eav_template_id]

          // this will check if it's necessary to filter itself,
          // because in the inspections side panel, the clicked inspection itself should not appear here
          const shouldShowSelf = isInspectionsTab ? true : dataItem.id !== timelineItem.id

          if (!isEmpty(filter)) {
            if (filterGroup === 'category' && !filter.includes(template.inspection_category_id)) return null
            if (filterGroup === 'template' && !filter.includes(template.title)) return null
          }

          return (
            status &&
            shouldShowSelf && (
              <TimelineBlockGroup
                key={`${timelineItem.number}-${timelineItem.id}`}
                responsibleId={timelineItem.responsible_id}
                title={
                  <React.Fragment>
                    <span className="id-label">{`#${timelineItem.number}`}</span>
                    {` - ${overdue ? I18n.t('inspections.statuses.overdue') : I18n.t(`inspections.statuses.${status}`)
                      }`}
                  </React.Fragment>
                }
                statusColor={getStatusColor(status)}
                statusIcon={overdue ? <FaCalendarTimes /> : null}
                iconColor={overdue ? 'red' : ''}
                leftLabel={
                  timelineItem.inspect_date
                    ? getDaysCounter(timelineItem.inspect_date)
                    : I18n.t('inspections.related_inspections.missing_date')
                }
              >
                <React.Fragment>
                  {isInspectionsTab && template?.title && (
                    <div className="timeline-dates">
                      <MdAssignment />
                      <span>{template.title}</span>
                    </div>
                  )}
                  <div className="timeline-dates">
                    <MdDateRange />
                    <span>
                      {timelineItem.inspect_date
                        ? formatDate(timelineItem.inspect_date, project)
                        : I18n.t('inspections.related_inspections.missing_date')}
                    </span>
                  </div>
                  {!isBlankOrZero(comments) && (
                    <div className="timeline-dates" title={comments}>
                      <MdChat />
                      <CustomTooltip position="bottom" parentTitle>
                        <div className="timeline-comments">{comments}</div>
                      </CustomTooltip>
                    </div>
                  )}
                  <div className="timeline-dates">
                    <FaUserEdit />
                    <span>
                      {responsible ? responsible.full_name : I18n.t('inspections.related_inspections.no_responsible')}
                    </span>
                  </div>
                  {isInspectionsTab && !isEmpty(timelineItem.pictures) && (
                    <div className="timeline-dates">
                      <Picture
                        images={timelineItem.pictures}
                        imageSize="sm"
                        allowDelete={false}
                        showComment={false}
                        collapse={collapseParams}
                      />
                    </div>
                  )}
                  {!checkInspectionFinished(timelineItem, statuses) && showButtons && (
                    <div className="timeline-block__button-group">
                      <TooltipIconButton
                        title={I18n.t('inspections.acceptance')}
                        className="timeline-block__button"
                        onClick={() => dispatch({
                          type: BusEvents.OPEN_MODAL,
                          payload: { type: 'acceptance', data: [timelineItem] }
                        })}
                      >
                        <MdThumbsUpDown />
                      </TooltipIconButton>
                      <TooltipIconButton
                        title={I18n.t('actions.edit')}
                        className="timeline-block__button"
                        onClick={() => dispatch({
                          type: BusEvents.OPEN_GRID_FORM_MODAL,
                          modelName: InspectionModel.paramName,
                          payload: {
                            dataItem: timelineItem,
                            formType: 'edit',
                            opts: {
                              templateId: timelineItem.eav_template_id,
                              ...(isInspectionsTab && { customDispatchEvent: BusEvents.RELOAD_SIDE_PANEL })
                            }
                          }
                        })}
                      >
                        <MdModeEdit />
                      </TooltipIconButton>
                    </div>
                  )}
                </React.Fragment>
              </TimelineBlockGroup>
            )
          )
        })}
    </div>
  )
}

RelatedInspectionsBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isInspectionsTab: PropTypes.bool,
  showButtons: PropTypes.bool
}

RelatedInspectionsBlock.defaultProps = {
  dataItem: undefined,
  sections: undefined,
  isLoading: undefined,
  isInspectionsTab: false,
  showButtons: true
}
