import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import processColumn, { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import PropTypes from 'prop-types'
import { guid } from '@progress/kendo-react-common'
import { duplicationCustomAction, duplicationCustomError } from '/src/ui/core/forms/form_utils'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import ScopeModel from '/src/models/scope'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import useUpdateFormModel from '/src/hooks/update_form_model'

// eslint-disable-next-line max-lines-per-function
export default function ScopeForm({ dataItem, templateId, requestIds, type }) {
  const [subproject] = useStore('subproject')
  const [includeOnForm, setIncludeOnForm] = useState({ subproject_id: subproject.id, uuid: guid() })
  const sections = useFetchSections(templateId)
  const dataItemRequestId = dataItem && dataItem.request_id

  const [scopeModel, eavTemplate] = useUpdateFormModel({
    requestIds,
    type,
    formModel: new ScopeModel(),
    dataItemRequestId,
    shouldFetch: () => true
  })

  const isDuplicate = type === 'duplicate'

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])

  const isEditMode = () => type === 'edit'

  const formulasServices = useFormulasServices(templateId, true)

  const [formulasStore] = useStore('formulas')

  const includeTemplateAndRequestOnForm = () => {
    setIncludeOnForm({
      ...includeOnForm,
      eav_template_id: templateId,
      request_id: dataItemRequestId || (requestIds && requestIds.length === 1 ? requestIds[0] : requestIds)
    })
  }

  useEffect(() => {
    if (!isEditMode()) includeTemplateAndRequestOnForm()
  }, [templateId, requestIds])

  if (!templateId || !eavTemplate) return <ThreeDotsLoader />

  if (templateId) translateFormulaServiceColumns(scopeModel, templateId)

  return (
    <FormWrapper
      model={scopeModel}
      dataItem={dataItem}
      label={isEditMode() && dataItem && dataItem.request_id}
      type={type}
      sidePanelSections={sectionsWithGeneral}
    >
      <React.Fragment>
        <Form
          model={scopeModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          templateId={templateId}
          type={type}
          submitParams={isDuplicate ? duplicationCustomAction(dataItem) : undefined}
          disableColumnEdition={isDuplicate ? (column) => column.title === 'DPMSID' : undefined}
          isDuplicate={isDuplicate}
          errorHandler={
            isDuplicate ? duplicationCustomError(I18n.t('api_internal_messages.only_scope_copy')) : undefined
          }
          hasFormulasServices
          formulasServices={formulasServices}
          processColumn={(column) => {
            return processColumn(column, formulasServices, formulasStore, ['scope', 'scoping'])
          }}
          eavTemplate={eavTemplate}
        />
        <FormButtons model={scopeModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ScopeForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number,
  requestIds: PropTypes.array,
  type: PropTypes.string
}

ScopeForm.defaultProps = {
  dataItem: null,
  requestIds: undefined,
  templateId: undefined,
  type: undefined
}
