import React from 'react'
import PropTypes from 'prop-types'
import GridLoader from '/src/ui/core/loaders/grid_loader'
import AddRow from '/src/ui/core/grid/editable/add_row'
import EditModeIcon from '/src/ui/core/icons/edit_mode_icon'
import BasicGridHeader from '/src/ui/core/grid/basic_grid_header'

export default function EditableGridLoader({ shouldRenderHeader, isLoading, children, opts }) {
  const { gridTitle, itemsQuantity, isAllowedCreate } = opts

  if (isLoading)
    return shouldRenderHeader ? (
      <div id="editable-grid" className="entity-grid-wrapper">
        <BasicGridHeader
          gridTitle={gridTitle}
          itemsQuantity={itemsQuantity}
          labels={[<EditModeIcon key="edit" />, isAllowedCreate && <AddRow key="add" loading />]}
        />
        <GridLoader />
      </div>
    ) : (
      <GridLoader />
    )

  return children
}

EditableGridLoader.propTypes = {
  shouldRenderHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  opts: PropTypes.shape({
    gridTitle: PropTypes.string,
    itemsQuantity: PropTypes.number,
    isAllowedCreate: PropTypes.bool
  })
}

EditableGridLoader.defaultProps = {
  shouldRenderHeader: true,
  isLoading: false,
  children: <React.Fragment />,
  opts: {
    gridTitle: '',
    itemsQuantity: 0,
    isAllowedCreate: false
  }
}
