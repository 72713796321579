import React from 'react'
import PropTypes from 'prop-types'
import { MdOutlinePendingActions } from 'react-icons/md'
import { Tooltip } from '@progress/kendo-react-tooltip'
import I18n from '/src/utils/translations'

const RecycleCheckIcon = ({ recyclingStatus }) => {
  if (!recyclingStatus) return null

  return (
    <Tooltip position="top" parentTitle anchorElement="target">
      <span
        className="recycling-flag-icon"
        title={I18n.t('requests.recycling_item')}
        display="contents"
        data-testid="tooltip-recycle-message"
      >
        <MdOutlinePendingActions size={17} />
      </span>
    </Tooltip>
  )
}

RecycleCheckIcon.propTypes = {
  recyclingStatus: PropTypes.bool.isRequired
}

export default RecycleCheckIcon
