import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import useFetch from '/src/hooks/api/fetch'
import useUrlFilterInspections from '/src/hooks/url_filter_inspections'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function useScaffoldingGridFilter() {
  const [selectedScaffoldings, setSelectedScaffoldings] = useState([])
  const { fetch } = useFetch()
  const [inspectedIdOnUrl, setInspectedUrl] = useQueryParam('inspected_id', NumberParam)
  const [inspectedTypeOnUrl, setInspectedType] = useQueryParam('inspected_type', StringParam)
  const { urlFilterArray, updateUrlFilterArray } = useUrlFilterInspections()
  const filter = urlFilterArray

  const fetchScaffoldings = useCallback(
    (onSuccess) => {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: inspectedIdOnUrl } }
      }

      fetch(`${inspectedTypeOnUrl}s`, params, {
        onSuccess: ({ data }) => {
          onSuccess(data.data)
        }
      })
    },
    [inspectedIdOnUrl, fetch]
  )

  useEffect(() => {
    if (inspectedIdOnUrl) {
      fetchScaffoldings((data) => {
        setSelectedScaffoldings(data)
      })
    }
  }, [inspectedIdOnUrl, fetchScaffoldings])

  const unpinScaffolding = useCallback(() => {
    setSelectedScaffoldings([])
    setInspectedUrl()
    setInspectedType()

    updateUrlFilterArray()
  }, [setInspectedUrl, setInspectedType, updateUrlFilterArray])

  const labels = useMemo(() => {
    return Object.values(selectedScaffoldings).map((scaffolding) => (
      <CustomTooltip position="bottom" parentTitle key={scaffolding.id} maxLength={20}>
        <span title={scaffolding.comment}>
          <LabelFilter
            text={`Scaffolding: ${scaffolding.tag_number} - ${scaffolding.description}`}
            classes="badge-scaffolding"
            icon={scaffolding.parent_id}
            onClick={() => unpinScaffolding([scaffolding.id])}
            suppressLink
          />
        </span>
      </CustomTooltip>
    ))
  }, [selectedScaffoldings, unpinScaffolding])

  return {
    unpinScaffolding,
    labels,
    filter
  }
}
