/* eslint-disable import/prefer-default-export */
/**
 * Filters the responseData array based on the values mapped from existingValues.
 *
 * @param {Array} existingValues - Array of objects to map values from.
 * @param {Array} responseData - Array of data to filter.
 * @param {string} keyField - The key field in responseData to match agains mapped values.
 * @returns {Array} - Filtered array of responseData
 */
export const filterDataByMappedValues = (existingValues, responseData, keyField) => {
  const mappedValues = existingValues.map((value) => value?.computed_text_field || value)
  const filteredValues = responseData.filter(
    (dataItem) =>
      dataItem && (mappedValues.includes(dataItem?.computed_text_field) || mappedValues.includes(dataItem?.[keyField]))
  )

  return filteredValues
}
