import React from 'react'
import PropTypes from 'prop-types'
import { MdChat } from 'react-icons/md'
import { isEmpty } from '/src/utils/object'

export default function PrintListPDFComments({ orderedComments }) {
  if (!orderedComments || isEmpty(orderedComments)) return null

  return (
    <div className='print-list-pdf__comments'>
      {Object.entries(orderedComments).map(([columnDescription, { value, index }]) => {
        return (
          <div key={columnDescription} className='print-list-pdf__comment-item'>
            <MdChat size={15} style={{ color: '#607d8b' }} />
            <div>{index}</div>
            <div className='print-list-pdf__comment-item--text'>{value}</div>
          </div>
        )
      })}
    </div>
  )
}

PrintListPDFComments.propTypes = {
  orderedComments: PropTypes.shape({
    value: PropTypes.string,
    index: PropTypes.number
  })
}

PrintListPDFComments.defaultProps = {
  orderedComments: undefined
}
