import React from 'react'
import PropTypes from 'prop-types'
import DecimalUnitColumn from '/src/ui/core/grid/column_cell_factory/decimal_unit_column'

export const scaffoldingTotalUnit = (dataItem) => {
  const dimension = dataItem?.scaffolding_type?.dimension_type
  if (dimension == 'm3') return 'm³'
  if (dimension == 'm2') return 'm²'
  return 'm'
}

export default function ScaffoldingPartTotalColumn({ dataItem }) {
  const total = dataItem?.total
  const unit = scaffoldingTotalUnit(dataItem)

  return <DecimalUnitColumn value={total} unit={unit} />
}

ScaffoldingPartTotalColumn.propTypes = {
  dataItem: PropTypes.shape({
    total: PropTypes.number,
    scaffolding_type: PropTypes.shape({
      dimension_type: PropTypes.string
    })
  })
}

ScaffoldingPartTotalColumn.defaultProps = {
  dataItem: {}
}
