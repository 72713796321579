import { useEffect, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFetch from '/src/hooks/api/fetch'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useTemplateId(model) {
  const { fetch } = useFetch()
  const [templateId, setTemplateId] = useState()
  const [urlTemplateId] = useQueryParam('eav_template_id', NumberParam)

  const isPlanningGrid = model.path === 'plannings'
  const isModelSubGrid = model && model.paramName.includes('_service')

  useEffect(() => {
    if (isPlanningGrid) {
      setTemplateId(urlTemplateId)
      return
    }

    if (!model || model.templateId || !isModelSubGrid || isPresent(templateId)) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { parent_template_id: urlTemplateId, template_type: model.paramName }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data: { data } }) => {
        if (!data || data.length === 0 || !data[0] || !data[0].id) {
          setTemplateId(undefined)
          return
        }
        setTemplateId(data[0].id)
      }
    })
  }, [isModelSubGrid, fetch, urlTemplateId, model, model.paramName, templateId, setTemplateId])

  return model.templateId || (isModelSubGrid ? templateId : urlTemplateId)
}
