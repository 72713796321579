/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdCheck } from 'react-icons/md'
import AnimateHeight from 'react-animate-height'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

const isFirstSection = (index) => index === 1

export default function Section({
  index,
  title,
  body,
  enumerable,
  hideable,
  sectionable,
  finished,
  display,
  id,
  showBorder
}) {
  const [show, setShow] = useState(true)
  const [height, setHeight] = useState('auto')
  const [isFocused, setIsFocused] = useState(isFirstSection(index))

  const headerClass = classNames('section-header-label', {
    'form-section-focused': isFocused
  })

  const onClick = () => {
    setHeight(!show ? 'auto' : 0)
    setShow(!show)
  }

  const indexStatus = () => {
    if (!finished)
      return (
        <div className="form-section-index" data-testid={`index-${title}`}>
          {index}
        </div>
      )

    return (
      <div className="form-section-index checked" data-testid={`index-${title}`}>
        <MdCheck />
      </div>
    )
  }

  const onSectionClick = () => {
    dispatch({ type: BusEvents.TOGGLE_SECTION_FOCUS, payload: id })
  }

  useBus(
    BusEvents.TOGGLE_SECTION_FOCUS,
    ({ payload }) => {
      setIsFocused(`${payload}` === `${id}`)
    },
    [id]
  )

  return (
    <div
      id={id}
      data-testid="section"
      className={classNames('section', {
        closed: !show,
        'no-display': !display,
        'no-border': !showBorder
      })}
      onClick={onSectionClick}
    >
      {sectionable && (
        <div className="section-header">
          <div className={headerClass}>
            {enumerable && indexStatus()}
            <div className="section-title" title={title}>
              {title}
            </div>
          </div>
          {hideable && (
            <button type="button" className="visibility-button" data-testid={`visibility-${title}`} onClick={onClick}>
              <div className="show-hide-text">{show ? I18n.t('form.hide') : I18n.t('form.show')}</div>
              <div className="show-hide-icon">{show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
            </button>
          )}
        </div>
      )}
      <div className={sectionable ? 'section-body' : 'section-body no-margin'}>
        <AnimateHeight duration={500} height={height} data-testid={`body-${title}`}>
          {body}
        </AnimateHeight>
      </div>
    </div>
  )
}

Section.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  finished: PropTypes.bool,
  enumerable: PropTypes.bool,
  hideable: PropTypes.bool,
  display: PropTypes.bool,
  sectionable: PropTypes.bool,
  showBorder: PropTypes.bool
}

Section.defaultProps = {
  id: 1,
  index: 1,
  title: '',
  finished: false,
  enumerable: true,
  hideable: true,
  display: true,
  sectionable: true,
  showBorder: true
}
