import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import { formatNumberWithPrecision, formatPercentageWithPrecision } from '/src/utils/project_formatter'
import '/src/static/css/domain/sub_requests/sub_request.css'

export default function ScopeCard({ index, scope, label, description, parentScope, onScopeFactorUpdated, editable }) {
  const [globalProject] = useStore('project')
  const [factor, setFactor] = useState(scope.factor)
  const [quantity, setQuantity] = useState(scope.quantity)
  const [remainingPercentage, setRemainingPercentage] = useState()
  const [formattedQuantity, setFormattedQuantity] = useState()
  const [formattedPercentage, setFormattedPercentage] = useState()
  const formattedHours = formatNumberWithPrecision(scope.team_target_hours, globalProject)
  const labelText = editable ? label || '' : ''

  const changeByFactor = (e) => {
    if (e.target.value != factor) {
      const value = e.target.value ? e.target.value : 0
      const newQuantity = parentScope.quantity * value
      setFactor(value)
      setQuantity(newQuantity)
      onScopeFactorUpdated(scope.id, value)
    }
  }

  const changeByQuantity = (e) => {
    if (e.target.value != quantity) {
      const value = e.target.value ? e.target.value : 0
      const newFactor = parentScope.quantity == 0 ? 0 : value / parentScope.quantity
      setFactor(newFactor)
      setQuantity(parentScope.quantity == 0 ? 0 : value)
      onScopeFactorUpdated(scope.id, newFactor)
    }
  }

  useEffect(() => {
    setFormattedPercentage(formatPercentageWithPrecision(remainingPercentage, 0, globalProject))
  }, [remainingPercentage])

  useEffect(() => {
    setRemainingPercentage(quantity === 0 ? 0 : scope.remaining_quantity / quantity)
    setFormattedQuantity(formatNumberWithPrecision(quantity, globalProject))
  }, [quantity, globalProject, scope.remaining_quantity])

  return (
    <div className="scope-card" data-testid="scope-card">
      {editable && (
        <div className="scope-number">
          <span>{index + 1}</span>
        </div>
      )}
      <div className="card-title" data-testid="card-title">
        <span>
          {`${I18n.t('sub_requests.scope_card.scope')} ${labelText} #${scope.number} `}
          <span>{description}</span>
        </span>
        <span>{`${formattedQuantity} · ${formattedHours} h`}</span>
      </div>
      {editable ? (
        <div className="numeric-inputs" data-testid="numeric-inputs">
          <div className="factor">
            <NumericTextBox
              data-testid="scope-factor"
              format="p"
              value={factor}
              min={0}
              step={0.01}
              onChange={changeByFactor}
            />
          </div>
          <div className="quantity">
            <NumericTextBox data-testid="scope-quantity" value={quantity} min={0} onChange={changeByQuantity} />
          </div>
        </div>
      ) : (
        <span className="scope-percentage">{formattedPercentage}</span>
      )}
    </div>
  )
}

ScopeCard.propTypes = {
  index: PropTypes.number,
  scope: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    quantity: PropTypes.number,
    remaining_quantity: PropTypes.number,
    team_target_hours: PropTypes.number
  }).isRequired,
  label: PropTypes.string.isRequired,
  parentScope: PropTypes.object,
  onScopeFactorUpdated: PropTypes.func,
  editable: PropTypes.bool
}

ScopeCard.defaultProps = {
  editable: false,
  label: '',
  onScopeFactorUpdated: () => {}
}
