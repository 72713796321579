import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import TopMenuPopupItem from '/src/ui/core/header/top_menu_popup_item'
import { filterReportTemplateFromRole, orderReportTemplates } from '/src/ui/core/menus/side_menu_templates'
import ReportItem from '/src/ui/domain/reports/report_item'
import { isEmpty } from '/src/utils/object'

// eslint-disable-next-line max-lines-per-function
export default function TopMenuReportButton() {
  const { pathname } = useLocation()
  const [reportTemplates] = useStore('report_templates')
  const [reportTemplatesRoles] = useStore('report_templates_roles')
  const [userRole] = useStore('user_role')

  const reportItems = useMemo(() => {
    if (!reportTemplates || isEmpty(reportTemplates)) return I18n.t('top_menu.no_reports')

    let newReportTemplates = Object.values(reportTemplates)

    if (userRole === undefined) return

    const role = Object.values(userRole)[0]

    if (reportTemplatesRoles && role.name !== 'admin') {
      newReportTemplates = filterReportTemplateFromRole(role, Object.values(reportTemplatesRoles), reportTemplates)
    }

    return orderReportTemplates(newReportTemplates).map((report) => <ReportItem report={report} key={report.id} />)
  }, [reportTemplates, userRole, reportTemplatesRoles])

  return (
    <TopMenuPopupItem label={I18n.t('top_menu.reports')} isActive={pathname === '/reports'}>
      <React.Fragment>{reportItems}</React.Fragment>
    </TopMenuPopupItem>
  )
}
