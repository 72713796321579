import { useRef } from 'react'

const useVirtualKey = () => {
  const virtualKeyCounter = useRef(1)

  const getNewVirtualKey = () => {
    const key = virtualKeyCounter.current
    virtualKeyCounter.current += 1
    return key
  }

  return getNewVirtualKey
}

export default useVirtualKey
