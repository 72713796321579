export const columnsWidth50 = [
  'boolean', 'string', 'text', 'integer', 'decimal', 'numeric', 'percentage',
  'date', 'date_time', 'gps', 'lookup', 'link', 'drop', 'multiple_drop', 'time',
  // Special Types
  'date_range', 'cascade_drop', 'conditional_cascade_drop', 'search'
]

export const columnsWidth100 = [
  'picture', 'fixed_picture', 'multiline_text', 'formula', 
  'attachment', 'datasheet_filter', 'drop_formula',
  // Special Types
  'module_filter', 'formula_service', 'upload', 'contract_service', 
  'controlled_formula', 'controlled_drop_formula', 'multiple_entries', 'rich_text'
]

export const defaultDropFormWidth = 50

export function getSizeByType(columnType, isDatasheetFilterSingle) {

  if (columnType === 'datasheet_filter' && isDatasheetFilterSingle) return 'width-50'

  if (columnType === 'drop') return `width-${defaultDropFormWidth}`

  if (columnsWidth50.includes(columnType)) return 'width-50'
  
  if (columnsWidth100.includes(columnType)) return 'width-100'

  return null
}

export const fieldSizeByType = (column, dataItem) => {
  const { column_type: columnType, web_form_width: formWidth, filters, initialVisibleOnWeb, required } = column

  const isDatasheetRequired = filters && filters[0] && filters[0].required
  const isRequired = isDatasheetRequired || (typeof required === 'function' ? required(dataItem) : required)

  if (initialVisibleOnWeb === false && !isRequired) return 'width-0'

  const isDatasheetFilterSingle = columnType && columnType.single

  const datasheetFilterWidth = filters && filters[0] && filters[0].web_form_width
  const columnFormWidth = datasheetFilterWidth || formWidth

  const widthByType = getSizeByType(column.type, isDatasheetFilterSingle)
  return columnFormWidth ? `width-${columnFormWidth}` : widthByType
}

export const fieldWidthByType = (type) => {
  switch (type) {
    case 'date_time':
      return 250
    default:
      return 150
  }
}

export const maxRadioDropBySizeAndLength = {
  25: {
    4: 2,
    3: 3,
    2: 6,
    1: 14
  },
  33: {
    4: 3,
    3: 5,
    2: 10,
    1: 20
  },
  50: {
    4: 5,
    3: 9,
    2: 15,
    1: 31
  },
  66: {
    4: 10,
    3: 20,
    2: 30,
    1: 42
  },
  75: {
    4: 13,
    3: 25,
    2: 36,
    1: 48
  },
  100: {
    4: 26,
    3: 34,
    2: 48,
    1: 65
  }
}
