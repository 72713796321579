import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-content-loader'

export default function ContentLoader({ width, height, backgroundColor, foregroundColor, speed }) {
  return (
    <Loader
      speed={speed}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height={height} />
    </Loader>
  )
}

ContentLoader.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  speed: PropTypes.number
}

ContentLoader.defaultProps = {
  speed: 1,
  backgroundColor: '#f8f9fa',
  foregroundColor: '#d2d9e1'
}
