const environmentKeys = ['project', 'subproject', 'language']

export function setEnvironment(state) {
  const keys = Object.keys(state)

  if (state.subproject.id) {
    keys.forEach((key) => {
      if (environmentKeys.includes(key)) {
        localStorage.setItem(key, JSON.stringify(state[key]))
      }
    })
  }
}

export function getEnvironment() {
  const initialStore = {}

  environmentKeys.forEach((key) => {
    const item = localStorage.getItem(key)

    if (item) initialStore[key] = JSON.parse(item)
  })

  return initialStore
}
