import React from 'react'
import PropTypes from 'prop-types'
import Field from '/src/ui/core/layouts/field'
import { fieldSizeByType } from '/src/utils/constants/fields'

// eslint-disable-next-line max-lines-per-function
export default function FieldsGroup({ columns, renderColumn, dataItem }) {
  const columnsElement = columns.reduce((result, c) => {
    const column = { ...c }
    if (!column.type && column.column_type) column.type = column.column_type.description
    if (!column.columnSize) column.columnSize = fieldSizeByType(column, dataItem)

    const columnElement = (
      <Field
        key={column.id}
        column={column}
        dataItem={dataItem}
        renderColumn={renderColumn}
      />
    )

    if (!column.columnSize) return result
    return [...result, ...[columnElement]]
  }, [])

  return <div className="fields-group">{columnsElement}</div>
}

FieldsGroup.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired,
  renderColumn: PropTypes.func.isRequired,
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

FieldsGroup.defaultProps = {
  dataItem: undefined
}
