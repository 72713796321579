import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import EstimateDetailsContent from '/src/ui/domain/estimates/estimate_details_content'
import useEstimatesPDFExport from '/src/ui/domain/estimates/pdf_export/estimates_pdf_export'
import EstimateFormDetailsFooter from '/src/ui/domain/estimates/estimate_form_details_footer'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateFormDetails({ requests, estimates, estimateServices, statuses, employees }) {
  const estimatesPDFExport = useEstimatesPDFExport({
    requests,
    estimates,
    estimateServices,
    statuses,
    employees
  })

  return estimates && estimateServices ? (
    <div className="estimate-side-panel">
      {estimatesPDFExport}
      <div className="header">
        <div className="title">{I18n.t('estimate.estimate_details')}</div>
      </div>
      <div className="estimate-details-content">
        <EstimateDetailsContent
          requests={requests}
          estimates={estimates}
          estimateServices={estimateServices}
          statuses={statuses}
          employees={employees}
        />
      </div>
      <EstimateFormDetailsFooter estimates={estimates} statuses={statuses} />
    </div>
  ) : (
    <ThreeDotsLoader />
  )
}

EstimateFormDetails.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      reason: PropTypes.string,
      comments: PropTypes.string
    })
  ),
  estimates: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          estimate_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      )
    ])
  ),
  estimateServices: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    ])
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  employees: PropTypes.oneOfType([PropTypes.object])
}

EstimateFormDetails.defaultProps = {
  requests: undefined,
  estimates: undefined,
  estimateServices: undefined,
  statuses: {},
  employees: {}
}
