/* eslint-disable max-lines-per-function */
import { useEffect, useRef } from 'react'
import { useStore } from 'react-context-hook'
import lodash from 'lodash'
import { buildEquation, buildVariables } from '/src/models/concerns/formula'
import { isEmpty } from '/src/utils/object'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { indexify } from '/src/utils/array'

const applyFormulaResult = (columns, formulas, result) => {
  const columnsUpdated = Array.isArray(columns) ? indexify(columns, 'id') : { ...columns }

  Object.keys(result).forEach((key) => {
    const formula = formulas[key]
    if (formula && columnsUpdated[formula.eav_column_id] && result[key].status === 'success') {
      columnsUpdated[formula.eav_column_id][formula.eav_column_field] = result[key].response
    }
  })

  return columnsUpdated
}

/**
 *  Hook designed watch form change and calculate formulas to update form fields visibilities
 */
export default function useFieldsControl(
  watch,
  formulasControlFields,
  eavColumns,
  onColumnsUpdated,
  fixedFields,
  setFixedFields,
  parentItems
) {
  const { isEqual } = lodash

  const postEquation = useFetchAPI('formulas/eval_equations', true)
  const [variableTypes] = useStore('variable_types')
  const prevWatchFields = useRef()
  const prevParentItems = useRef()
  const timer = useRef(null)

  const fieldsToWatch = [
    ...Object.keys(eavColumns).map((key) => eavColumns[key].description),
    ...(fixedFields ? fixedFields.map((item) => item.description) : [])
  ]
  const watchFields = watch(fieldsToWatch).reduce((map, value, index) => {
    map[fieldsToWatch[index]] = value
    return map
  }, {})

  const calculate = (formulas) => {
    if (!formulas || isEmpty(formulas)) return
    const equations = {}

    formulas.forEach((formula) => {
      const fieldsVariables = buildVariables({ ...formula, parents: parentItems }, watchFields, variableTypes)
      equations[formula.id] = [buildEquation(formula, fieldsVariables), {}]
    })

    const params = { requestAction: 'CREATE', httpAction: 'post', data: { formulas: equations } }
    postEquation.fetchAPI(params)
  }

  useEffect(() => {
    if (postEquation.status !== 'SUCCESS') return

    const result = postEquation.responseData
    const formulas =
      formulasControlFields && formulasControlFields.reduce((obj, item) => ((obj[item.id] = item), obj), {})

    const fixedFieldsUpdated = applyFormulaResult(fixedFields, formulas, result)
    setFixedFields(Object.values(fixedFieldsUpdated))
    onColumnsUpdated(applyFormulaResult(eavColumns, formulas, result))
  }, [postEquation.status, postEquation.responseData])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  if (!Object.keys(watchFields).length) return
  if (isEqual(watchFields, prevWatchFields.current) && isEqual(parentItems, prevParentItems.current)) return

  prevWatchFields.current = watchFields
  prevParentItems.current = parentItems

  clearTimeout(timer.current)
  timer.current = setTimeout(() => calculate(formulasControlFields), 500)
}
