import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/blocks/timeline_block_group.css'

export default function TimelineBlockGroup({ title, leftLabel, statusColor, statusIcon, iconColor, children }) {
  return (
    <div className="timeline-wrapper" data-testid="timeline-item">
      <span className="timeline-days">{leftLabel}</span>
      <div className="timeline-symbols">
        {statusIcon ? (
          <div className={`timeline-check active ${iconColor}`}>{statusIcon}</div>
        ) : (
          <div className="timeline-check">
            <span className={`timeline-status ${statusColor}`} />
          </div>
        )}
        <div className="timeline-line" />
      </div>
      <div className="timeline-group">
        <div className="timeline-info">
          <div className="timeline-description" data-testid="timeline-description">
            {title}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

TimelineBlockGroup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  leftLabel: PropTypes.string,
  statusColor: PropTypes.string,
  statusIcon: PropTypes.element,
  iconColor: PropTypes.string,
  children: PropTypes.element
}

TimelineBlockGroup.defaultProps = {
  title: undefined,
  leftLabel: undefined,
  statusColor: undefined,
  statusIcon: undefined,
  iconColor: undefined,
  children: undefined
}
