import React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import '/src/static/css/core/grid/grid.css'

import PropTypes from 'prop-types'

export default function GridHeaderPaging({ onPageChange }) {
  return (
    <div className="left-right-icons">
      <button type="button" className="btn-icon" onClick={(event) => onPageChange(event, -1)} data-testid="left-arrow">
        <MdChevronLeft />
      </button>
      <button type="button" className="btn-icon" onClick={(event) => onPageChange(event, 1)} data-testid="right-arrow">
        <MdChevronRight />
      </button>
    </div>
  )
}

GridHeaderPaging.propTypes = {
  onPageChange: PropTypes.func.isRequired
}
