import React from 'react'
import PropTypes from 'prop-types'

export default function Label({ title }) {
  return <div className="general-label">{title}</div>
}

Label.propTypes = {
  title: PropTypes.string
}
