import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ExpandableCard from './expandable_card'
import '/src/static/css/core/cards/card.css'

export default function ExpandableCardList({ blocksCollection, expanded }) {
  const buildExpandableCard = (blocks) => {
    const { key, tag, title, subtitle, details, body, height, className } = blocks
    return (
      <ExpandableCard
        tag={tag}
        title={title}
        subtitle={subtitle}
        details={details}
        key={key}
        body={body}
        className={className}
        height={height}
        expanded={expanded}
      />
    )
  }

  return blocksCollection.map(buildExpandableCard)
}

ExpandableCardList.propTypes = {
  blocksCollection: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      tag: PropTypes.element,
      title: PropTypes.element,
      body: PropTypes.element,
      subtitle: PropTypes.element,
      details: PropTypes.element,
      height: PropTypes.number,
      className: PropTypes.string
    })
  ).isRequired,
  expanded: PropTypes.bool
}

ExpandableCardList.defaultProps = {
  expanded: false
}
