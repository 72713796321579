/* eslint-disable max-lines-per-function */
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { MdDelete } from 'react-icons/md'
import PartModel from '/src/models/scaffolding_part'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import { formattedToEditableGrid, foreignDropToEditableGrid } from '/src/utils/columns_formatter'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import useConfigurePrintPDF from '/src/ui/core/popups/configure_print_pdf'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import {
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  editModalMenuItem,
  duplicateModalMenuItem,
  printListMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useModel from '/src/ui/core/forms/model_hook'
import NewButton from '/src/ui/core/icons/new_button'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import { isScaffoldingDismantled } from '/src/utils/constants/scaffolding'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useDismantlePopup from '/src/ui/domain/scaffoldings/dismantle_popup'
import {
  addDimensionMenuItem,
  addModificationMenuItem,
  dismantleMenuItem
} from '/src/ui/domain/scaffoldings/scaffolding_parts_menu_entries'
import useScaffoldingConfirmationPopup from '/src/ui/domain/scaffoldings/scaffolding_confirmation_popup'

export default function ScaffoldingPartsGrid({
  onRowClick,
  selectedScaffoldingItem,
  selectedItem,
  onDataSource,
  onGridColumns,
  onBulkEditing,
  onAddDimensionMenuClick,
  onAddModificationMenuClick
}) {
  const [gridDataSource, setGridDataSource] = useState()
  const defaultFilter = selectedScaffoldingItem
    ? [
        {
          type: 'where',
          column: 'scaffolding_id',
          value: selectedScaffoldingItem.id
        }
      ]
    : []
  const [filter, setFilter] = useState(defaultFilter)

  const [partModel] = useModel(new PartModel())

  const foreignEntities = partModel.columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])

  const batchedEntities = useEntitiesCache(foreignEntities)

  const [openConfigurationPopup, printGridPopup, isPrintable, printList] = useConfigurePrintPDF(
    partModel.columns,
    gridDataSource,
    partModel
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(
    partModel.templateId
  )

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    // printListMenuItem(printList, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick)
  ]

  const [openDismantlePopup, renderDismantlePopup] = useDismantlePopup(selectedScaffoldingItem, partModel)

  const [statuses] = useStore('scaffolding_statuses')

  const dismantled = useMemo(() => isScaffoldingDismantled(statuses, selectedScaffoldingItem), [
    statuses,
    selectedScaffoldingItem
  ])

  const icons = [
    !dismantled && <BulkEditingIcon key="bulk-edit" onClick={() => onBulkEditing('bottom')} />,
    <MoreActionsIcon key="more-actions" items={moreActionsMenuItems} />,
    !dismantled && <NewButton key="new-button" modelName={partModel.paramName} opts={{ subGrid: true }} />
  ]

  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const showActionConfirmation = useScaffoldingConfirmationPopup(showConfirmation)

  const actionParams = (opts) => {
    return {
      resourcePath: 'scaffolding_parts',
      scaffoldingPartAction: opts.scaffoldingPartAction || 'UPDATE',
      httpAction: opts.httpAction || 'put',
      resourceId: opts.id
    }
  }

  const contextMenuItems = useMemo(
    () => [
      editModalMenuItem(
        () => !dismantled,
        (_, dataItem) =>
          dispatch({
            type: BusEvents.OPEN_GRID_FORM_MODAL,
            modelName: partModel.paramName,
            payload: { dataItem }
          })
      ),
      duplicateModalMenuItem(
        () => !dismantled,
        () => {}
      ),
      addDimensionMenuItem(() => !dismantled, onAddDimensionMenuClick),
      addModificationMenuItem((dataItem) => !dismantled && dataItem.total > 0, onAddModificationMenuClick),
      dismantleMenuItem((dataItem) => !dismantled && dataItem.total > 0, openDismantlePopup),
      {
        text: I18n.t('actions.delete'),
        icon: <MdDelete />,
        visible: () => !dismantled,
        onClick: (_, dataItem) => {
          showActionConfirmation(dataItem, actionParams({ ...dataItem, httpAction: 'delete' }), {
            actionName: 'delete'
          })
        }
      }
    ],
    [onAddDimensionMenuClick, onAddModificationMenuClick, partModel.paramName, showActionConfirmation, showConfirmation]
  )

  useEffect(() => {
    if (!selectedScaffoldingItem) {
      setFilter(defaultFilter)
      return
    }

    setFilter(
      defaultFilter.concat({
        type: 'where',
        column: 'scaffolding_id',
        value: selectedScaffoldingItem.id
      })
    )
  }, [selectedScaffoldingItem])

  const onSetGridDataSource = useCallback(
    (dataSource) => {
      if (onDataSource) onDataSource(dataSource)
      setGridDataSource(dataSource)
    },
    [onDataSource]
  )

  const onSetGridColumns = useCallback(
    (columns) => {
      const formattedColumns = ['scaffolding_type_id']
      let newColumns = formattedToEditableGrid(columns, formattedColumns, batchedEntities)
      newColumns = foreignDropToEditableGrid(newColumns, formattedColumns, batchedEntities)
      if (onGridColumns) onGridColumns(newColumns)
    },
    [onGridColumns, batchedEntities]
  )

  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    ({ payload }) => {
      const { modelName, subGrid } = payload

      if (modelName && modelName === partModel.paramName && subGrid) {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: partModel.paramName
        })
      }
    },
    [partModel]
  )

  return (
    <React.Fragment>
      {renderConfirmation()}
      {renderDismantlePopup()}
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <SimpleGrid
        key="scaffolding_parts"
        templateId={partModel.templateId}
        model={partModel}
        gridTitle={partModel.name}
        sort={[{ field: 'id', dir: 'desc' }]}
        icons={icons}
        filter={filter}
        columnCellFactory={<CellFactory type={partModel.paramName} />}
        contextMenuItems={contextMenuItems}
        selectFiltering={false}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onDataSource={onSetGridDataSource}
        onGridColumns={onSetGridColumns}
      />
    </React.Fragment>
  )
}

ScaffoldingPartsGrid.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  selectedScaffoldingItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onDataSource: PropTypes.func,
  onGridColumns: PropTypes.func,
  onBulkEditing: PropTypes.func,
  onAddDimensionMenuClick: PropTypes.func,
  onAddModificationMenuClick: PropTypes.func
}

ScaffoldingPartsGrid.defaultProps = {
  selectedItem: undefined,
  selectedScaffoldingItem: undefined,
  onRowClick: () => {},
  onDataSource: () => {},
  onGridColumns: () => {},
  onBulkEditing: () => {},
  onAddDimensionMenuClick: () => {},
  onAddModificationMenuClick: () => {}
}
