import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RiErrorWarningLine } from 'react-icons/ri'
import classNames from 'classnames'
import FieldFactory from '/src/ui/core/fields/field_factory'
import { RIGHT_ALIGNED_TYPES, LEFT_ALIGNED_TYPES, CENTER_ALIGNED_FIELDS } from '/src/utils/constants/columns'
import ColumnInput from '/src/ui/core/inputs/column_input'
import useCellClick from '/src/ui/core/grid/cell_click'
import { byString } from '/src/utils/object'
import UnformattedCell from '/src/ui/core/grid/unformatted_cell'
import ConditionalFormatIcon from '/src/ui/core/icons/conditional_format_icon'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'
import { columnType, hasInputOnBulkEdit } from '/src/models/concerns/eav_column'
import '/src/static/css/core/grid/editable_grid.css'

export default function CustomizeCell({
  cell,
  column,
  columnCellFactory,
  columns,
  onClick,
  inBulkEdit,
  cellVisibility,
  cellConditionalFormat,
  cellRequiredMissing,
  onChange,
  model,
  isLoading
}) {
  const [updatedValue, setUpdatedValue] = useState()
  const isScaffoldingTypeNotEditable =
    model &&
    model.paramName === ScaffoldingPartModel.paramName &&
    column.description === 'scaffolding_type_id' &&
    cell.dataItem.uuid !== undefined
  const columnReadOnly = column.read_only || isScaffoldingTypeNotEditable
  const hideOnForm = typeof column.hideOnForm === 'function' ? column.hideOnForm(cell.dataItem) : column.hideOnForm

  const onCellClick = useCellClick()
  const onCustomizeCellClick = (e) => onCellClick(e, cell.dataItem, onClick)

  const cellValue = (changeValue) => {
    let changeValueCopy = changeValue

    if (cell.dataItem.inEdit !== column.description) changeValueCopy = undefined

    const fieldToDisplay = column.dataItemFieldToDisplay || column.description
    let newValue = changeValueCopy || cell.dataItem[fieldToDisplay] || byString(cell.dataItem, fieldToDisplay)
    if (newValue && typeof newValue === 'object') newValue = byString(cell.dataItem, cell.field)

    if (inBulkEdit && column.fieldFormatter) newValue = column.fieldFormatter(cell.dataItem)

    return newValue
  }

  const checkCellLeftAligned = () =>
    LEFT_ALIGNED_TYPES.includes(columnType(column)) && !CENTER_ALIGNED_FIELDS.includes(cell.field)

  const checkCellRightAligned = () =>
    (RIGHT_ALIGNED_TYPES.includes(columnType(column)) ||
      (cellValue(updatedValue) && !Number.isNaN(Number(cellValue(updatedValue))))) &&
    !CENTER_ALIGNED_FIELDS.includes(cell.field)

  const cellFactory = columnCellFactory || <UnformattedCell />

  const change = (id, keyValue, foreignAttributeValue) => {
    setUpdatedValue(keyValue)

    const setForeignAttributeValue = (opts) => {
      if (!column.foreignAttribute) return opts

      cell.onChange({
        dataIndex: 0,
        dataItem: cell.dataItem,
        field: column.dataItemFieldToDisplay || column.description,
        value: foreignAttributeValue
      })

      return { ...opts, field: column.foreignAttribute }
    }

    if (cell.onChange) {
      const opts = { dataIndex: 0, dataItem: cell.dataItem, field: cell.field, value: keyValue }
      cell.onChange(setForeignAttributeValue(opts))
    }

    if (onChange) onChange({ ...cell, foreignAttributeValue })
  }

  const renderConditionalFormat = () => {
    const { type, message } = cellConditionalFormat() || {}
    return <ConditionalFormatIcon type={type} message={message} />
  }

  if (cellVisibility() === false) {
    return <td>------</td>
  }

  if (
    cell.dataItem &&
    cell.dataItem.inEdit &&
    cell.dataItem.inEdit === column.description &&
    hasInputOnBulkEdit(column) &&
    !columnReadOnly
  ) {
    return (
      <td className="input-cell">
        <ColumnInput column={column} onChange={change} dataItem={cell.dataItem} />
      </td>
    )
  }

  if ((!inBulkEdit || hideOnForm || column.readOnly || column.showCellFromFactory) && !column.eav_template_id) {
    const newElementProps = {
      field: cell.field,
      dataItem: cell.dataItem,
      value: cellValue(),
      columns
    }

    return (
      <td
        data-testid={cell.field}
        onClick={onCustomizeCellClick}
        className={classNames({
          'left-aligned-cell': checkCellLeftAligned(),
          'right-aligned-cell': checkCellRightAligned(),
          'cell-read-only': inBulkEdit && columnReadOnly,
          'grid-pdf-cell': cell.pdfCell
        })}
      >
        {React.cloneElement(cellFactory, newElementProps)}
      </td>
    )
  }

  const hasAnyError = cellRequiredMissing && isBlankOrFalse(updatedValue)

  const useStringField = column.column_type && column.column_type.useStringField
  const isColumnEditable = column.editable && columnReadOnly !== true

  const notEditableClick = inBulkEdit ? null : onCustomizeCellClick

  const flexibleComments = cell.dataItem.flexible_comments ?? {}
  const comment = cell.pdfCell ? null : flexibleComments[column.description]
  const newColumn = { ...column, comment }

  return (
    <td
      onClick={isColumnEditable && !isLoading ? onClick : notEditableClick}
      className={classNames({
        'left-aligned-cell': checkCellLeftAligned(),
        'right-aligned-cell': checkCellRightAligned(),
        'cell-editable': column.editable && hasInputOnBulkEdit(column) && !isScaffoldingTypeNotEditable,
        'cell-read-only': columnReadOnly,
        'grid-pdf-cell': cell.pdfCell
      })}
    >
      <FieldFactory
        value={cellValue(updatedValue)}
        type={useStringField ? 'string' : columnType(column)}
        opts={newColumn}
      />
      {renderConditionalFormat()}
      {hasAnyError && <RiErrorWarningLine className="mandatory-highlight" />}
    </td>
  )
}

CustomizeCell.propTypes = {
  cell: PropTypes.shape({
    field: PropTypes.string.isRequired,
    dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
    foreignAttribute: PropTypes.string,
    onChange: PropTypes.func,
    pdfCell: PropTypes.bool
  }).isRequired,
  column: PropTypes.shape({
    description: PropTypes.string,
    foreignAttribute: PropTypes.string,
    field: PropTypes.string,
    editable: PropTypes.bool,
    column_type: PropTypes.shape({
      description: PropTypes.string
    }),
    eav_template_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideOnForm: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool,
    read_only: PropTypes.bool,
    showCellFromFactory: PropTypes.bool
  }).isRequired,
  columnCellFactory: PropTypes.element,
  columns: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  inBulkEdit: PropTypes.bool,
  cellVisibility: PropTypes.func,
  cellConditionalFormat: PropTypes.func,
  cellRequiredMissing: PropTypes.bool,
  model: PropTypes.shape({ paramName: PropTypes.string }),
  isLoading: PropTypes.bool
}

CustomizeCell.defaultProps = {
  columnCellFactory: <UnformattedCell />,
  columns: [],
  onClick: () => {},
  onChange: () => {},
  inBulkEdit: false,
  cellVisibility: () => true,
  cellConditionalFormat: () => {},
  cellRequiredMissing: false,
  model: undefined,
  isLoading: false
}
