import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore, store } from 'react-context-hook'
import lodash from 'lodash'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import InspectionFormModal from '/src/ui/domain/inspections/inspection_form_modal'
import useInspectionAcceptancePopup from '/src/ui/domain/inspections/inspection_acceptance_popup'
import ChooseInspectionTemplateModal from '/src/ui/domain/requests/choose_inspection_template_modal'
import Layout from '/src/ui/core/layouts/layout'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import ScaffoldingModel from '/src/models/scaffolding'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import ScaffoldingsGrid from '/src/ui/domain/scaffoldings/scaffoldings_grid'
import ScaffoldingPartsGrid from '/src/ui/domain/scaffoldings/scaffolding_parts_grid'
import ScaffoldingsSidePanel from '/src/ui/domain/scaffoldings/scaffoldings_side_panel'
import ScaffoldingPartWrapper from '/src/ui/domain/scaffoldings/scaffolding_part_wrapper'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import { byString } from '/src/utils/object'
import useModel from '/src/ui/core/forms/model_hook'
import { DIMENSION, MODIFICATION } from '/src/utils/constants/scaffolding'
import InspectionModel from '/src/models/inspection'
import ScaffoldingPartForm from '/src/ui/domain/scaffoldings/scaffolding_part_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'

const isScaffoldingRowEditable = (dataItem) => byString(dataItem, 'scaffolding_status.i18n_id') !== 'dismantled'

export default function ScaffoldingsPage({ match }) {
  const [subproject] = useStore('subproject')
  const [selectedScaffolding, setSelectedScaffolding] = useState()
  const [selectedScaffoldingPart, setSelectedScaffoldingPart] = useState()
  const [actionFormMode, setActionFormMode] = useState(null)
  const [inspectionTemplate, setInspectionTemplate] = useState({})
  const [type, setType] = useState('new')
  const [partAction, setPartAction] = useState()

  useBus(
    BusEvents.FORM_CANCEL_BUTTON_CLICKED,
    () => {
      setActionFormMode(null)
    },
    []
  )

  useBus(
    BusEvents.FORM_SIDE_PANEL_CLOSE,
    () => {
      setActionFormMode(null)
    },
    []
  )

  const inspectionModelParamName = InspectionModel.paramName
  const columnStyles = selectedScaffolding ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED

  const [scaffoldingModel] = useModel(new ScaffoldingModel())
  const [partModel] = useModel(new ScaffoldingPartModel())

  const editableGridProps = {
    allowCreate: true,
    recoverSettings: () => ({
      selectedItem: selectedScaffolding,
      selectedPart: selectedScaffoldingPart
    }),
    topEntity: {
      model: scaffoldingModel,
      onCreateNewItem: (newDataItem) => ({
        ...newDataItem,
        eav_template_id: undefined
      }),
      shouldAllowCell: (column, dataItem) => {
        if (!dataItem || !dataItem.scaffolding_status) return true

        const { description } = column
        if (description === 'comment') return true
        return isScaffoldingRowEditable(dataItem)
      },
      isRowEditable: (row) => isScaffoldingRowEditable(row)
    },
    bottomEntity: {
      hideCreate: true,
      model: partModel,
      onCreateNewItem: (newDataItem) => ({
        ...newDataItem,
        scaffolding_id: selectedScaffolding.id,
        erect_method: 'standard',
        eav_template_id: undefined
      }),
      shouldAllowCell: (column, dataItem) => {
        const { description } = column
        if (description === 'comment') return true
        return isScaffoldingRowEditable(selectedScaffolding)
      },
      isRowEditable: (_) => isScaffoldingRowEditable(selectedScaffolding)
    }
  }

  const renderAcceptanceModal = useInspectionAcceptancePopup()

  const [
    editableGrid,
    setInEditMode,
    editableTopGridColumns,
    editableTopGridDataSource,
    editableBottomGridColumns,
    editableBottomGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onScaffoldingRowClick = useCallback(
    (e) => {
      setSelectedScaffolding((prevSelectedItem) => {
        const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
        const newItem = isSidePanelOpened ? undefined : e.dataItem

        if (!newItem) setSelectedScaffoldingPart()

        store.set('selected_scaffolding', newItem)

        return newItem
      })
    },
    [setSelectedScaffolding]
  )

  const onScaffoldingPartRowClick = useCallback(
    (e) => {
      setActionFormMode(null)
      setSelectedScaffoldingPart((prevSelectedItem) => {
        const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
        const newItem = isSidePanelOpened ? undefined : e.dataItem

        return newItem
      })
    },
    [setSelectedScaffoldingPart, setActionFormMode]
  )

  const onAddDimensionMenuClick = useCallback(
    (_, dataItem, dataItemAction) => {
      setActionFormMode(DIMENSION)
      setPartAction(dataItemAction)
      if (!dataItemAction) setType('new')
      setSelectedScaffoldingPart(dataItem)
    },
    [setSelectedScaffoldingPart, setActionFormMode, setPartAction, setType]
  )

  const onAddModificationMenuClick = useCallback(
    (_, dataItem, dataItemAction) => {
      setActionFormMode(MODIFICATION)
      setPartAction(dataItemAction)
      if (!dataItemAction) setType('new')
      setSelectedScaffoldingPart(dataItem)
    },
    [setSelectedScaffoldingPart, setActionFormMode, setPartAction, setType]
  )

  const handleCloseSidePanel = useCallback(() => {
    setActionFormMode(null)
    setSelectedScaffolding()
    setSelectedScaffoldingPart()
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
  }, [setSelectedScaffolding, setSelectedScaffoldingPart, setActionFormMode])

  const onScaffoldingDataSource = useCallback(
    (dataSource) => {
      const { isEqual } = lodash

      editableTopGridDataSource.set(dataSource)

      const newSelectedItem =
        selectedScaffolding && dataSource.data.filter((item) => item.id === selectedScaffolding.id)[0]

      if (!isEqual(selectedScaffolding, newSelectedItem)) {
        if (!newSelectedItem) handleCloseSidePanel()
        else {
          setSelectedScaffolding(newSelectedItem)
        }
      }
    },
    [editableTopGridDataSource.set, selectedScaffolding, setSelectedScaffolding, handleCloseSidePanel]
  )

  const onScaffoldingPartDataSource = useCallback(
    (dataSource) => {
      const { isEqual } = lodash

      editableBottomGridDataSource.set(dataSource)

      const selectedPart = dataSource.data.filter(
        (item) => selectedScaffoldingPart && item.id === selectedScaffoldingPart.id
      )[0]

      if (!isEqual(selectedScaffoldingPart, selectedPart)) {
        setSelectedScaffoldingPart(selectedPart)
      }
    },
    [
      editableTopGridDataSource.set,
      selectedScaffoldingPart,
      setSelectedScaffoldingPart,
      editableTopGridDataSource,
      handleCloseSidePanel
    ]
  )

  useEffect(() => {
    if (!subproject) return
    handleCloseSidePanel()
  }, [subproject, handleCloseSidePanel])

  useBus(
    BusEvents.OPEN_SIDE_PANEL_FORM,
    ({ payload, formType, actionType }) => {
      if (!payload) {
        if (actionType === 'dimension') onAddDimensionMenuClick(undefined, selectedScaffoldingPart, undefined)

        if (actionType === 'modification') onAddModificationMenuClick(undefined, selectedScaffoldingPart, undefined)
      } else {
        const updatedPartAction = { ...payload }
        if (payload.work_order_id) {
          updatedPartAction.work_dismantle = true
        }

        setType(formType)

        if (payload.action_type === 'build')
          onAddDimensionMenuClick(undefined, selectedScaffoldingPart, updatedPartAction)

        if (payload.action_type === 'modify')
          onAddModificationMenuClick(undefined, selectedScaffoldingPart, updatedPartAction)
      }
    },
    [setType, selectedScaffoldingPart, onAddDimensionMenuClick, onAddModificationMenuClick]
  )

  useBus(
    BusEvents.EXITING_EDITABLE_MODE,
    ({ payload }) => {
      const item = payload()

      const { selectedItem, selectedPart } = item

      setSelectedScaffolding(selectedItem && { ...selectedItem })
      setSelectedScaffoldingPart(selectedPart && { ...selectedPart })
    },
    []
  )

  const handleCloseActionForm = useCallback(() => {
    dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)
  }, [setActionFormMode])

  return (
    <Layout key={match.params.id} showNewButton>
      {renderAcceptanceModal()}
      {editableGrid(
        <LayoutPanels wrapperClass="side-panel-wrapper no-template no-tabs-above" columnStyles={columnStyles}>
          <div className={selectedScaffolding ? 'shadowed-page' : 'unshadowed-page'}>
            <div className={selectedScaffolding ? 'scope-item-selected' : 'scope-item-not-selected'}>
              <ScaffoldingsGrid
                onRowClick={onScaffoldingRowClick}
                selectedItem={selectedScaffolding}
                onDataSource={onScaffoldingDataSource}
                onGridColumns={editableTopGridColumns.set}
                onBulkEditing={setInEditMode}
              />
            </div>
            {selectedScaffolding && (
              <div className="subgrid" data-testid="scaffolding-part-grid">
                <ScaffoldingPartsGrid
                  onRowClick={onScaffoldingPartRowClick}
                  selectedItem={selectedScaffoldingPart}
                  selectedScaffoldingItem={selectedScaffolding}
                  onDataSource={onScaffoldingPartDataSource}
                  onGridColumns={editableBottomGridColumns.set}
                  onBulkEditing={setInEditMode}
                  onAddDimensionMenuClick={onAddDimensionMenuClick}
                  onAddModificationMenuClick={onAddModificationMenuClick}
                />
              </div>
            )}
          </div>
          {!selectedScaffolding && !selectedScaffoldingPart ? (
            <React.Fragment />
          ) : selectedScaffoldingPart ? (
            <ScaffoldingPartWrapper
              selectedScaffoldingPart={selectedScaffoldingPart}
              selectedScaffolding={selectedScaffolding}
              handleCloseSidePanel={handleCloseSidePanel}
              actionFormMode={actionFormMode}
              handleCloseActionForm={handleCloseActionForm}
              partAction={partAction}
              type={type}
            />
          ) : (
            <ScaffoldingsSidePanel dataItem={selectedScaffolding} onClose={handleCloseSidePanel} />
          )}
          <ChooseInspectionTemplateModal
            modelName={inspectionModelParamName}
            setInspectionTemplate={setInspectionTemplate}
            inspectedType="Scaffolding"
          />
          <InspectionFormModal modelParamName={inspectionModelParamName} template={inspectionTemplate} />
          <GridFormModal modelName={partModel.paramName}>
            <ScaffoldingPartForm scaffolding={selectedScaffolding} />
          </GridFormModal>
        </LayoutPanels>
      )}
    </Layout>
  )
}

ScaffoldingsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      collection: PropTypes.string
    })
  })
}

ScaffoldingsPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined,
      collection: undefined
    })
  })
}
