import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision, formatHours } from '/src/utils/project_formatter'
import { isBlank, isBlankOrZero } from '/src/utils/boolean_refinements'
import { byString } from '/src/utils/object'

export const progress = (dataItem, actual, scoped) => {
  if (isBlank(byString(dataItem, actual))) return '---'
  if (isBlankOrZero(byString(dataItem, scoped))) return '---'

  return byString(dataItem, actual) / byString(dataItem, scoped)
}

export const percentage = (dataItem, actual, scoped) => {
  const progressValue = progress(dataItem, actual, scoped)
  if (progressValue === '---') return '---'

  return `${(progressValue * 100).toFixed(2)}%`
}

export const formatQty = (dataItem, path) => {
  const [globalProject] = useStore('project')

  if (isBlank(byString(dataItem, path))) return '---'
  if (isBlank(byString(dataItem, 'contract_service.unit.description'))) return '---'

  const quantity = byString(dataItem, path)
  const formatted = formatNumberWithPrecision(quantity, globalProject)

  return `${formatted}${byString(dataItem, 'contract_service.unit.description')}`
}

export const formatTime = (dataItem, path) => {
  if (isBlank(byString(dataItem, path))) return '---'
  if (isBlank(byString(dataItem, 'contract_service.unit.description'))) return '---'

  const time = formatHours(byString(dataItem, path))

  return time
}
