import { useState, useCallback } from 'react'
import { useStore } from 'react-context-hook'

export default function useRequestItemDataProvider() {
  const [dataItem, setDataItem] = useState({})
  const [employees] = useStore('employees')
  const [user] = useStore('user')

  const defaultResponsible = useCallback(() => {
    if (dataItem.responsible_id) return dataItem.responsible_id
    const employeesArray = Object.values(employees)
    const responsible = employeesArray.find((employee) => {
      return user && user.employee_id && employee.id === user.employee_id
    })

    if (!responsible) return null

    if (!dataItem.responsible_id && dataItem.request_id) {
      setDataItem({ ...dataItem, responsible_id: responsible.id })
    }
    return responsible.id
  }, [dataItem, employees, user])

  const onChange = (description, value) => {
    if (dataItem[description] === value) return

    const item = { ...dataItem }
    item[description] = value
    setDataItem(item)
  }

  return [dataItem, setDataItem, defaultResponsible, onChange]
}
