import React, { useCallback, useMemo, useState } from 'react'
import { useStore, store } from 'react-context-hook'
import I18n from '/src/utils/translations'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'
import { isEmpty } from '/src/utils/object'

export default function useScaffoldingStatusFilter() {
  const [scaffoldingStatuses] = useStore('scaffolding_statuses')
  const [statusFilterIds] = useStore('scaffolding_status_filter')

  const [popupOpened, setPopupOpened] = useState(false)

  const statusFilterLabels = useMemo(
    () =>
      scaffoldingStatuses &&
      !isEmpty(scaffoldingStatuses) &&
      statusFilterIds &&
      statusFilterIds.map(
        (id) => scaffoldingStatuses[id] && I18n.t(`scaffoldings.statuses.${scaffoldingStatuses[id].i18n_id}`)
      ),
    [statusFilterIds, scaffoldingStatuses]
  )

  const statusFilter = useMemo(
    () =>
      statusFilterIds &&
      statusFilterIds.length > 0 && {
        type: 'where',
        column: 'scaffolding_status_id',
        operator: 'eq',
        value: statusFilterIds
      },
    [statusFilterIds]
  )

  const clearStatusFilters = useCallback(() => {
    store.set('scaffolding_status_filter', [])
  }, [])

  const changeFilter = useCallback(
    (status) => {
      if (statusFilterIds.includes(status.id)) {
        store.set(
          'scaffolding_status_filter',
          statusFilterIds.filter((id) => id != status.id)
        )
      } else {
        store.set('scaffolding_status_filter', [...statusFilterIds, status.id])
      }
    },
    [statusFilterIds]
  )

  const statusFilterButton = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={scaffoldingStatuses && Object.values(scaffoldingStatuses)}
        statusDescription={(status) => I18n.t(`scaffoldings.statuses.${status.i18n_id}`)}
        isStatusSelected={(status) => statusFilterIds && statusFilterIds.includes(status.id)}
        onChangeFilter={changeFilter}
        onClearFilters={clearStatusFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [scaffoldingStatuses, statusFilterIds, changeFilter, clearStatusFilters, popupOpened]
  )

  return {
    statusFilterButton,
    selectedStatuses: statusFilterLabels,
    statusFilter,
    clearStatusFilters
  }
}
