import { isBlank } from '/src/utils/boolean_refinements'

export function setScaffoldingTypeFilter(state) {
  if (isBlank(state.scaffolding_type_filter)) return
  localStorage.setItem('scaffolding_type_filter', JSON.stringify(state.scaffolding_type_filter))
}

export function getScaffoldingTypeFilter() {
  const scaffoldingTypeFilter = localStorage.getItem('scaffolding_type_filter')
  return {
    scaffolding_type_filter: isBlank(scaffoldingTypeFilter) ? [] : JSON.parse(scaffoldingTypeFilter)
  }
}
