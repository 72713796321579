import React from 'react'
import { Loader } from '/src/ui/core/loaders/loader'
import I18n from '/src/utils/translations'

export default function EmptyRowLoader({ status }) {
  return (
    <React.Fragment>
      {status === 'empty-loading' ? (
        <div data-testid="empty-loading-row" className="loading-line">
          <Loader className={'k-master-row'} />
        </div>
      ) : (
        <div className="k-master-row empty-row">{I18n.t('grid.empty_after_filtering')}</div>
      )}
    </React.Fragment>
  )
}
