import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { isBlankOrZero } from '/src/utils/boolean_refinements'
import I18n from '/src/utils/translations'

export default function ScaffoldingPartDimensionsColumn({ action }) {
  const [project] = useStore('project')

  const initialOfDimension = (dimension) => {
    return I18n.t(`scaffolding_part_actions.${dimension}`)[0]
  }

  const formatDimension = (dimension, label) =>
    isBlankOrZero(dimension) ? null : `${label}: ${formatNumberWithPrecision(dimension, project)}`

  const labelParts = [
    formatDimension(action.length, initialOfDimension('length')),
    formatDimension(action.width, initialOfDimension('width')),
    formatDimension(action.height, initialOfDimension('height'))
  ].filter(Boolean)

  const label = labelParts.join(' / ')

  return (
    <div className="info-dimensions" data-testid="info-dimensions">
      {label}
    </div>
  )
}

ScaffoldingPartDimensionsColumn.propTypes = {
  action: PropTypes.shape({
    length: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
}
