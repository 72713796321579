export default function useCellClick() {
  const targetNamesToRemoveClick = ['BUTTON', 'A']

  const onCellClick = (event, dataItem, onClick) => {
    const targetName = event.target.nodeName
    const childNode = event.currentTarget.firstChild

    const isTargetNameClickable = targetName !== 'TD' && !targetNamesToRemoveClick.includes(targetName)

    if (isTargetNameClickable && childNode && childNode.nodeName !== '#text') onClick({ dataItem })
  }

  return onCellClick
}
