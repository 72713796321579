import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { first } from 'lodash'
import { isEmpty } from '/src/utils/object'
import EstimateModel, { estimateIsReadOnly } from '/src/models/estimate'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import EstimateFormButtons from '/src/ui/domain/estimates/estimate_form_buttons'
import I18n from '/src/utils/translations'

export default function EstimateFormCheckout({ estimates, statuses, employees }) {
  const [user] = useStore('user')
  const estimateModel = new EstimateModel()

  const defaultEmployee = useMemo(() => {
    const userId = (user || {}).employee_id
    const userEmployee = employees[userId]
    const firstEmployee = first(Object.values(employees))
    return userEmployee || firstEmployee || {}
  }, [user, employees])

  const processColumn = (column) => {
    let value = column.default
    if (column.description === 'responsible_id' && !isEmpty(employees)) {
      value = defaultEmployee.id
    }
    return { ...column, default: value }
  }

  const pendingEstimates = useMemo(() => estimates.filter((estimate) => !estimateIsReadOnly(estimate, statuses)), [
    estimates,
    statuses
  ])

  return (
    <div className="checkout-service">
      <div className="estimate-form-wrapper">
        <FormWrapper title={I18n.t('estimate.title')} model={estimateModel} classes="estimate-form" isModal>
          <div className="form-list-wrapper">
            <Form
              model={estimateModel}
              sections={[]}
              sectionable={false}
              type="new"
              processColumn={processColumn}
              onSubmitCallback={(values) => {
                if (!values.estimate_date || !values.responsible_id) return

                dispatch({
                  type: BusEvents.FORM_SUBMITTED,
                  payload: {
                    estimate_date: values.estimate_date,
                    responsible_id: values.responsible_id
                  }
                })
              }}
            />
          </div>
        </FormWrapper>
      </div>
      <EstimateFormButtons pendingEstimates={pendingEstimates} />
    </div>
  )
}

EstimateFormCheckout.propTypes = {
  estimates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      estimate_status_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  employees: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
}

EstimateFormCheckout.defaultProps = {
  estimates: [],
  statuses: {},
  employees: {}
}
