import React, { useCallback, useMemo, useRef } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import EstimateFormDetailsFooter from '/src/ui/domain/estimates/estimate_form_details_footer'
import EstimateDetailsContent from '/src/ui/domain/estimates/estimate_details_content'
import { estimatePDFName } from '/src/ui/domain/estimates/pdf_export/estimate_pdf_name'
import EstimatesPDFHeader from '/src/ui/domain/estimates/pdf_export/estimates_pdf_header'
import EstimatesPDFSummary from './estimates_pdf_summary'
// eslint-disable-next-line import/no-unresolved
import '/src/static/css/domain/estimates/estimates_pdf_export.css'

export default function useEstimatesPDFExport({ requests, estimates, estimateServices, statuses, employees }) {
  const pdfExportRef = useRef()

  const pdfName = estimatePDFName(requests)

  const printEstimatesPDF = useCallback(() => {
    if (!pdfExportRef.current) return
    pdfExportRef.current.save()
  }, [])

  useBus(
    BusEvents.PRINT_PDF,
    () => {
      printEstimatesPDF()
    },
    [printEstimatesPDF]
  )

  const pdfExport = useMemo(
    () => (
      <div className="estimates-pdf-container">
        <PDFExport
          ref={pdfExportRef}
          paperSize="A4"
          margin={{ left: '.5cm', right: '.5cm', bottom: '.5cm' }}
          landscape={false}
          scale={0.6}
          fileName={pdfName}
          repeatHeaders
          avoidLinks
          forcePageBreak=".page-break"
        >
          {requests.length > 1 && <EstimatesPDFSummary requests={requests} estimates={estimates} statuses={statuses} />}
          {requests.map((request, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <EstimatesPDFHeader requests={[request]} estimates={estimates} employees={employees} />
              <div className="pdf-export-logos">
                <img className="pdf-export-logos__logo" src="/static/svg/kaefer-logo.svg" alt="Kaefer Logo" />
                <img className="pdf-export-logos__logo" src="/static/svg/logo-red.svg" alt="Red Logo" />
              </div>
              <EstimateFormDetailsFooter estimates={estimates} statuses={statuses} />
              <EstimateDetailsContent
                requests={[request]}
                estimates={estimates}
                estimateServices={estimateServices}
                statuses={statuses}
                expanded
                abbreviatedCard={false}
              />
              {index !== requests.length - 1 && <span className="page-break" key={`page-break-${request.id}`} />}
            </div>
          ))}
        </PDFExport>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [requests, estimates, estimateServices, statuses, employees]
  )

  return pdfExport
}
