import React from 'react'
import PropTypes from 'prop-types'

export default function MultilineTextColumn({ value }) {
  return <span className="break-line">{value}</span>
}

MultilineTextColumn.propTypes = {
  value: PropTypes.string
}

MultilineTextColumn.defaultProps = {
  value: null
}
