import React from 'react'
import PropTypes from 'prop-types'

export default function DropFormulaColumn({ value }) {
  return value
}

DropFormulaColumn.propTypes = {
  value: PropTypes.number
}

DropFormulaColumn.defaultProps = {
  value: null
}
