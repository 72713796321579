import React from 'react'
import { useStore } from 'react-context-hook'
import { render, unmountComponentAtNode } from 'react-dom'
import PropTypes from 'prop-types'
import { savePDF } from '@progress/kendo-react-pdf'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PrintListPDFBody from '/src/ui/core/grid/pdf_print/print_list_pdf_body'
import GridPDFPage from '/src/ui/core/grid/pdf_print/grid_pdf_page'
import { usePDFSectionsAndTemplate } from '/src/utils/pdf_utils'
import '/src/static/css/core/popups/print_list_pdf.css'

export default function PrintListPDFExport({ modelName, gridPage }) {
  const storeValues = {
    units: useStore('units')[0],
    project: useStore('project')[0],
    subproject: useStore('subproject')[0],
    inspectStatuses: useStore('inspect_statuses')[0],
    progressServiceStatuses: useStore('progress_service_statuses')[0]
  }

  const { fetchSectionsAndTemplate } = usePDFSectionsAndTemplate()

  // eslint-disable-next-line max-params
  const exportPDF = (gridData, sectionsData, pdfConfig, templateTitle) => {
    const listWrapper = document.getElementById('print-pdf-content')

    const { forcePageBreak, scale, isPictureBig } = pdfConfig

    // these methods will need to change when we upgrade React to 18
    render(
      <PrintListPDFBody
      gridData={gridData}
      gridSections={sectionsData}
      storeValues={storeValues}
      modelName={modelName}
      templateTitle={templateTitle}
        isPictureBig={isPictureBig}
      />,
      listWrapper
    )

    const fileName = templateTitle
      ? `${modelName} - ${templateTitle} - Grid Page ${gridPage}`
      : `${modelName} - Grid Page ${gridPage}`

    const config = {
      paperSize: 'A4',
      margin: { top: '2.4cm', left: '1.2cm', right: '1.2cm', bottom: '1.2cm' },
      fileName,
      scale,
      forcePageBreak: forcePageBreak && '.page-break',
      // eslint-disable-next-line react/jsx-props-no-spreading
      pageTemplate: (props) => <GridPDFPage {...props} model={{ name: modelName }} numItems={gridData.length || 0} />
    }

    savePDF(listWrapper, config, () => unmountComponentAtNode(listWrapper))
  }

  useBus(
    BusEvents.PRINT_LIST,
    ({ payload }) => {
      const { model, gridData, pdfConfig } = payload
      if (model.name !== modelName) return

      if (modelName.includes('Scaffolding')) return

      const eavTemplateId = gridData?.[0]?.eav_template_id

      if (!eavTemplateId) return

      fetchSectionsAndTemplate(eavTemplateId, modelName).then(([sectionsData, templateTitle]) => {
          exportPDF(gridData, sectionsData, pdfConfig, templateTitle)
        })
    },
    [modelName, gridPage]
  )

  return (
    <div className="print-list-pdf">
      <div id="print-pdf-content" data-testid="print-pdf-content" />
    </div>
  )
}

PrintListPDFExport.propTypes = {
  modelName: PropTypes.string,
  gridPage: PropTypes.number.isRequired
}

PrintListPDFExport.defaultProps = {
  modelName: undefined
}
