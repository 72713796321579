import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import { ScopeClusteredModel, ScopeClusteredFilteredModel } from '/src/models/scope_clustered'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTabs from '/src/ui/core/layouts/template_tabs_hook'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TemplateTabs from '/src/ui/core/layouts/template_tabs'
import KendoGrid from '/src/ui/core/grid/kendo_grid'
import GridInterativeModeHeader from '/src/ui/core/grid/grid_interactive_mode_header'
import ClusterDetail from '/src/ui/domain/scopes/cluster_detail'
import ScopeCellFactory from '/src/ui/domain/scopes/scope_cell_factory'
import I18n from '/src/utils/translations'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/scope_clustered_grid.css'
import '/src/static/css/core/buttons/buttons.css'

// eslint-disable-next-line max-lines-per-function
export default function ScopesClusteredGrid({ request }) {
  const defaultModel = new ScopeClusteredModel()
  const filteredModel = new ScopeClusteredFilteredModel()

  const [grid, setGrid] = useStore('grid')
  const [tabbing] = useQueryParam('eav_template_id', NumberParam)
  const [interactiveMode] = useStore('interactive_mode')
  const [headerTitle] = useState()
  const getTabs = useTabs(defaultModel.templateType)
  const [tabs, setTabs] = useState([])
  const [filter, setFilter] = useState()
  const getRequest = useFetchAPI(`requests/${request}`)
  const getScopesLabelNull = useFetchAPI(defaultModel.route)
  const [scopesLabelNull, setScopesLabelNull] = useState()
  const [selectedItem, setSelectedItem] = useState()

  const history = useHistory()

  const defaultFilters = [
    { type: 'where', column: 'request_id', operator: IsEqualOperator, value: parseInt(request, 10) },
    { type: 'not', column: 'label', value: null }
  ]

  useEffect(() => {
    setFilter(defaultFilters)
    const requestQueryParams = { requestAction: 'READ', httpAction: 'get' }
    getRequest.fetchAPI(requestQueryParams)
  }, [])

  useEffect(() => {
    if (interactiveMode && interactiveMode.status) {
      setFilter([...filter, { type: 'where', column: 'label', value: interactiveMode.filter }])
    } else {
      setFilter(defaultFilters)
    }
  }, [interactiveMode])

  useEffect(() => {
    if (!tabbing) return
    const OthersLabelRowParams = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: {
        filtering: {
          filter: {
            logic: 'and',
            filters: [
              { type: 'where', column: 'request_id', value: parseInt(request, 10) },
              { type: 'where', column: 'eav_template_id', value: tabbing },
              { type: 'where', column: 'label', value: null }
            ]
          }
        }
      }
    }
    getScopesLabelNull.fetchAPI(OthersLabelRowParams)
  }, [tabbing, interactiveMode.status])

  useEffect(() => {
    if (!getScopesLabelNull.loading && !getScopesLabelNull.errors) {
      // the conditional below is used for when there are no scopes without a label in the project
      // the label others will receive a content and be displayed in the grid
      let labelNull = getScopesLabelNull.responseData.data
      if (getScopesLabelNull.responseData.data.length === 0)
        labelNull = [
          {
            label: null,
            scopes_count: 0,
            team_target_hours: 0,
            budget_target_hours: 0,
            norm_hours: 0,
            remaining_team_target_hours: 0,
            quantity: 0,
            remaining_quantity: 0
          }
        ]
      setScopesLabelNull(labelNull)
    }
    if (!getScopesLabelNull.loading && getScopesLabelNull.errors) notifyError(I18n.t('scopes.label_error'))
  }, [getScopesLabelNull.loading, getScopesLabelNull.errors])

  useEffect(() => {
    if (!getTabs.loading && !getTabs.errors && getTabs.responseData.data.length > 0) {
      setTabs([...getTabs.responseData.data])
    }
    if (!getTabs.loading && getTabs.errors) notifyError(I18n.t('tabs.error'))
  }, [getTabs.loading, getTabs.errors, getTabs.requestAction, getTabs.responseData])

  useEffect(() => {
    if (!getRequest.loading && !getRequest.errors && getRequest.responseData.data.length > 0) {
      setGrid({
        ...grid,
        filtered: {
          title: { reason: getRequest.responseData.data['0'].reason, id: request, link: 'scopes' }
        }
      })
    }
    if (!getRequest.loading && getRequest.errors) {
      history.push('/home')
      notifyError(I18n.t('requests.errors', { id: request }))
    }
  }, [getRequest.loading, getRequest.errors, getRequest.requestAction, getRequest.responseData])

  return (
    <div className="scope_clustered">
      {tabs.length > 0 && request ? (
        <div>
          <TemplateTabs tabs={tabs} />
          <KendoGrid
            headerTitle={headerTitle}
            model={interactiveMode.status ? filteredModel : defaultModel}
            extraRows={scopesLabelNull}
            columnCellFactory={<ScopeCellFactory />}
            tabs={tabs}
            expandedComponent={(e) => (
              <ClusterDetail
                request={request}
                tabbing={tabbing}
                label={e.dataItem.label}
                selected={e.dataItem.selected}
              />
            )}
            overlayHeaderColumns={<GridInterativeModeHeader tabbing={tabbing} />}
            filter={filter}
            sort={[{ field: 'id', dir: 'desc' }]}
            opts={{
              enabledButtons: true,
              enableRadioCheckbox: true,
              selectedItem,
              onSelectedItem: setSelectedItem,
              api: true,
              uniqueKey: 'label',
              forceExpandRows: interactiveMode && interactiveMode.status
            }}
          />
        </div>
      ) : (
        <ThreeDotsLoader />
      )}
    </div>
  )
}

ScopesClusteredGrid.propTypes = {
  request: PropTypes.string
}

ScopesClusteredGrid.defaultProps = {
  request: undefined
}
