/* eslint-disable max-lines-per-function */
import { useState } from 'react'
import useUpdateEffect from '/src/hooks/update_effect'
import useFetchAPI from '/src/hooks/api/fetch_api'

export const pollingStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  TIMEOUT: 'TIMEOUT',
  CONTINUE: 'CONTINUE',
  NONE: 'NONE'
}

/**
 * A hook function used to polling the API until a condition is satisfied
 *
 * @return {
 *  startPolling, // function (backgroundJobId, timeOut, interval = 2)
 *  response,     // pollingState.response,
 *  status,       // pollingState.status,
 *  fetchStatus
 *}
 */
const usePollingAPI = () => {
  const { responseData, fetchAPI, status } = useFetchAPI('background_jobs')
  const initialState = { response: { data: [], total: 0 }, status: pollingStatus.NONE }
  const [pollingState, setPollingState] = useState(initialState)
  const [fetchApiParams, setFetchApiParams] = useState()
  const [endDate, setEndDate] = useState()
  const [waitingInterval, setWaitingInterval] = useState()

  const responseToStatus = (response) => {
    if (response.data === undefined) return pollingStatus.NONE
    if (response.data[0].job_status === 'complete') return pollingStatus.SUCCESS
    if (response.data[0].job_status === 'failed') return pollingStatus.ERROR
    return pollingStatus.CONTINUE
  }

  const checkTimeoutAndUpdateState = (jobStatus) => {
    if (!endDate || Number(new Date()) < endDate) {
      setPollingState({ response: responseData, status: jobStatus })
      setTimeout(() => fetchAPI(fetchApiParams), waitingInterval)
    } else {
      setPollingState({ response: responseData, status: pollingStatus.TIMEOUT })
    }
  }

  useUpdateEffect(() => {
    if (responseData === undefined) return
    const jobStatus = responseToStatus(responseData)
    switch (jobStatus) {
      case pollingStatus.SUCCESS:
      case pollingStatus.ERROR:
        setPollingState({ response: responseData, status: jobStatus })
        break
      default:
        checkTimeoutAndUpdateState(jobStatus)
    }
  }, [responseData])

  const startPolling = (backgroundJobId, timeOut, interval = 2) => {
    const apiParams = {
      requestAction: 'READ',
      httpAction: 'get',
      resourceId: backgroundJobId
    }

    setFetchApiParams(apiParams)
    setWaitingInterval(interval * 1000)
    if (timeOut) setEndDate(Number(new Date()) + timeOut * 1000)

    fetchAPI(apiParams)
  }

  return {
    startPolling,
    response: pollingState.response,
    status: pollingState.status,
    fetchStatus: status
  }
}

export default usePollingAPI
