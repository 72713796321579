import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@progress/kendo-react-popup'
import '/src/static/css/popup_anchored.css'

// eslint-disable-next-line max-lines-per-function
export default function usePopupAnchoredHover({ open, popupRef, popupButtonRef, setOpenActive }) {
  const handleMouseOver = () => setOpenActive(true)
  const handleMouseOut = () => setOpenActive(false)

  useEffect(() => {
    const buttonNode = popupButtonRef.current
    if (buttonNode) {
      buttonNode.addEventListener('mouseover', handleMouseOver)
      buttonNode.addEventListener('mouseout', handleMouseOut)

      return () => {
        buttonNode.removeEventListener('mouseover', handleMouseOver)
        buttonNode.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [popupButtonRef.current])

  useEffect(() => {
    const popupNode = popupRef.current
    if (popupNode) {
      popupNode.addEventListener('mouseover', handleMouseOver)
      popupNode.addEventListener('mouseout', handleMouseOut)

      return () => {
        popupNode.removeEventListener('mouseover', handleMouseOver)
        popupNode.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [open, popupRef.current])
}

usePopupAnchoredHover.propTypes = {
  open: PropTypes.bool.isRequired,
  popupButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  popupButtonRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setOpenActive: PropTypes.func.isRequired
}
