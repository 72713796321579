/* eslint-disable no-case-declarations */
import { useReducer } from 'react'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'

export default function InspectionReducer() {
  const initialReducerState = {
    selectedItem: null,
    sidePanelDataItem: null,
    columnStyles: SIDE_PANEL_CLOSED
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'closeSidePanel':
        return { ...state, selectedItem: null, sidePanelDataItem: null, columnStyles: SIDE_PANEL_CLOSED }
      case 'toggleSelectedItem':
        const isSidePanelOpened = state.selectedItem && state.selectedItem.id === action.payload.id
        const newItem = isSidePanelOpened ? null : action.payload
        return {
          ...state,
          selectedItem: newItem,
          sidePanelDataItem: newItem,
          columnStyles: newItem ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED
        }
      case 'updateSelectedItem':
        return {
          ...state,
          selectedItem: action.payload,
          sidePanelDataItem: action.payload,
          columnStyles: SIDE_PANEL_OPENED
        }
      default:
        return null
    }
  }
  return useReducer(reducer, initialReducerState)
}
