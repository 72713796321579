import React from 'react'
import { MdOutlineErrorOutline } from 'react-icons/md'
import I18n from '/src/utils/translations'

export default function EmptyTemplatesBody() {
  return (
    <div className="no-templates-wrapper">
      <div className="no-templates-icon">
        <MdOutlineErrorOutline />
      </div>
      <p className="no-templates-text">{I18n.t('requests.create_inspection_modal.no_templates')}</p>
    </div>
  )
}
