/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError } from '/src/ui/core/dialogs/notifications'

export default function useModelController({ modelType, dataItemId, onBack, onFinish, onFinishAndAnother }) {
  const [dataItem, setDataItem] = useState(null)
  const { fetch, loading } = useFetch()

  useBus(BusEvents.FORM_CANCEL_BUTTON_CLICKED, () => onBack(), [onBack])

  useBus(BusEvents.FORM_BACK_BUTTON_CLICKED, () => onBack(), [onBack])

  useBus(
    BusEvents.FORM_FINISHED,
    ({ payload }) => {
      if (payload && payload.newClicked && onFinishAndAnother) onFinishAndAnother()
      else onFinish()
    },
    [onFinish]
  )

  useEffect(() => {
    if (!dataItemId) return
    const params = { query: { where: { id: parseInt(dataItemId, 10) } } }

    fetch(modelType, params, {
      useParse: true,
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          setDataItem(data[0])
          return
        }

        notifyError({
          title: I18n.t('notification.access_denied'),
          body: I18n.t('notification.correct_subproject')
        })
      },
      onError: () => {
        notifyError({
          title: I18n.t('notification.error'),
          body: I18n.t(`${modelType}.errors.not_found`, { id: dataItemId })
        })
      }
    })
  }, [dataItemId, fetch, modelType])

  return { dataItem, loading }
}
