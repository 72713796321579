import I18n from '/src/utils/translations'

export const includeDateColumns = (columns) => {
  const maxId = Math.max(...columns.map((item) => item.id))

  return [
    ...columns,
    {
      id: maxId + 1,
      description: 'created_at',
      title: I18n.t('fields.created_at'),
      default: new Date().toJSON(),
      hideOnGrid: true,
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnForm: true,
      editable: false,
      visible: false,
      type: 'date_time'
    },
    {
      id: maxId + 2,
      description: 'updated_at',
      title: I18n.t('fields.updated_at'),
      default: new Date().toJSON(),
      hideOnGrid: true,
      hideOnSidePanel: true,
      hideOnSummary: true,
      hideOnForm: true,
      editable: false,
      visible: false,
      type: 'date_time'
    }
  ]
}
