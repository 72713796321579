import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import Upload from '/src/ui/core/inputs/upload'
import '/src/static/css/core/inputs/input_attachment.css'

export default function UploadAttachmnetModal({
  innerFiles,
  allowedExtensions,
  maxSize,
  settings,
  closeModal,
  className
}) {
  const importClick = () => {
    document.getElementsByClassName('k-button k-primary k-upload-selected')[0].click()
  }

  const extensions = () => {
    let index = -1
    return (
      <ul>
        {allowedExtensions && (
          <li key={index++}>
            <span>{I18n.t('form.inputs.attachment.allowed_extensions')}</span>
            {': '}
            <span>{allowedExtensions.join(', ')}</span>
          </li>
        )}
        <li key={index++}>{I18n.t('form.inputs.attachment.max_size', { max_size: maxSize })}</li>
        <li key={index++}>{I18n.t('form.inputs.attachment.one_file')}</li>
      </ul>
    )
  }

  const modalFooter = (
    <div className="import-button">
      <button type="button" disabled={innerFiles.length < 1} onClick={() => importClick()}>
        {I18n.t('actions.import_file')}
      </button>
    </div>
  )

  const modalClassName = () => {
    const defaultClassName = 'input-dropdown input-attachment input-attachment-modal modal-wrapper'
    if (className) return `${defaultClassName} ${className}`
    return defaultClassName
  }

  return (
    <span className="input-attachment-modal">
      <Modal
        title={I18n.t('form.inputs.attachment.submit')}
        footer={modalFooter}
        onClose={() => closeModal()}
        closable
        className={modalClassName()}
        appendToBody
      >
        <div className="center">
          <div className="form-info">
            <h4>{`${I18n.t('importation_popup.requirements')}:`}</h4>
            {extensions()}
          </div>
          <Upload settings={settings} />
        </div>
      </Modal>
    </span>
  )
}

UploadAttachmnetModal.propTypes = {
  innerFiles: PropTypes.array,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.string,
  settings: PropTypes.oneOfType([PropTypes.object]),
  closeModal: PropTypes.func,
  className: PropTypes.string
}

UploadAttachmnetModal.defaultProps = {
  innerFiles: [],
  allowedExtensions: [],
  maxSize: '',
  settings: {},
  className: '',
  closeModal: () => {}
}
