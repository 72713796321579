import { formatNumberWithDelimiter, formatNumberWithPrecision } from '/src/utils/project_formatter'

// eslint-disable-next-line import/prefer-default-export
export const conditionalFormatMessage = (formulaResult) => {
  const defaultResponse = { type: undefined, message: formulaResult }

  if (typeof formulaResult !== 'string') return defaultResponse
  if (!formulaResult.includes(':')) return defaultResponse

  const [type, ...message] = formulaResult.split(':')

  return { type: type.trim(), message: message.join(':').trim() }
}

export const convertFormulaResult = (formulaResult) => {
  if (typeof formulaResult === 'string') {
    if (formulaResult.toLowerCase() === 'true') return true
    if (formulaResult.toLowerCase() === 'false') return false
  }
  return formulaResult
}

export const parseFormulaResult = (formulaResult, isInteger, globalProject) => {
  const parsedValue = convertFormulaResult(formulaResult)

  if (typeof parsedValue === 'number') {
    return isInteger
      ? formatNumberWithDelimiter(Math.round(parsedValue), globalProject)
      : formatNumberWithPrecision(parsedValue, globalProject)
  }

  return parsedValue
}
