import React from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdThumbsUpDown } from 'react-icons/md'
import I18n from '/src/utils/translations'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { checkInspectionFinished } from '/src/models/concerns/inspection_filter'
import '/src/static/css/core/blocks/track_block.css'

export default function InspectionActionButtons({ dataItem, actions }) {
  const [inspectStatuses] = useStore('inspect_statuses')
  const items = actions.map((action) => ({ ...action, dataItem }))

  return (
    <React.Fragment>
      <div
        className={`track-block-buttons ${
          checkInspectionFinished(dataItem, inspectStatuses) && 'show-button-expanded'
        }`}
      >
        {!checkInspectionFinished(dataItem, inspectStatuses) && (
          <Button
            text={I18n.t('inspections.acceptance')}
            icon={<MdThumbsUpDown />}
            className="edit-button-track-block"
            onClick={() =>
              dispatch({
                type: BusEvents.OPEN_MODAL,
                payload: { type: 'acceptance', data: [dataItem] }
              })
            }
          />
        )}
        <PopupButton items={items} text={I18n.t('actions.more')} />
      </div>
    </React.Fragment>
  )
}

InspectionActionButtons.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  actions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
}

InspectionActionButtons.defaultProps = {
  dataItem: undefined,
  actions: []
}
