export function setLastSync(state) {
  if (state.last_sync) localStorage.setItem('last_sync', JSON.stringify(state.last_sync))
}

export function getLastSync() {
  const initialStore = {}
  const item = localStorage.getItem('last_sync')

  if (item) initialStore.last_sync = JSON.parse(item)

  return initialStore
}
