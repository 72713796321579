import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDroppable } from '@progress/kendo-react-common'

export default function DroppableListItem({ children, onDrop, id }) {
  const droppableElement = useRef(null)

  const handleDrop = useCallback(() => {
    if (onDrop) onDrop(id)
  }, [onDrop, id])

  useDroppable(droppableElement, {
    onDrop: handleDrop
  })

  return (
    <div id={id} className="droppable-list-item" ref={droppableElement} onDrop={handleDrop}>
      {children}
    </div>
  )
}

DroppableListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  onDrop: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
