import React from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'

const redColor = '#B33939'

const revertMenuItem = (history, { route, singularName }, showConfirmation, onSuccess, visible) => {
  const onSuccessGridAction = () => {
    notifySuccess({
      title: I18n.t('revert_modal.success_title'),
      body: I18n.t('revert_modal.success_description')
    })
    dispatch(BusEvents.RELOAD_GRID)

    if (onSuccess) onSuccess()
  }

  return {
    text: I18n.t('actions.revert'),
    icon: <RiArrowGoBackLine />,
    visible,
    onClick: (e, dataItem) => {
      const confirmationParams = {
        title: I18n.t('revert_modal.title'),
        description: I18n.t('revert_modal.description', { model: singularName, id: dataItem.id }),
        actionButtonText: I18n.t('revert_modal.ok'),
        cancelButtonText: I18n.t('revert_modal.cancel'),
        buttonColor: redColor,
        onSuccess: () => onSuccessGridAction,
        requestParams: {
          resourcePath: route,
          requestAction: 'REVERT',
          httpAction: 'put',
          resourceId: dataItem.id,
          additionalResource: { path: 'revert' }
        }
      }
      showConfirmation(confirmationParams)
    }
  }
}

export { revertMenuItem }
