import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MdPendingActions } from 'react-icons/md'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import useFormModal from '/src/ui/core/popups/form_modal'
import useRecycleRequestPopupFields from '/src/ui/domain/requests/recycle_request_popup_fields'
import { requestTitle } from '/src/utils/constants/request'

export default function RecycleRequestPopup({ dataItem }) {
  const [data, setData, sections] = useRecycleRequestPopupFields()
  const formTitle = I18n.t('requests.recycle_form.title')
  const formSubtitle = I18n.t('requests.recycle_form.subtitle')
  const modalTitle = I18n.t('requests.recycle_form.modal_title')

  const { fetch } = useFetch()

  const onRecycleRequest = useCallback(() => {
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
    const params = {
      requestAction: 'CREATE',
      httpAction: 'post',
      data: {
        status: 'recycling',
        ...data
      }
    }

    fetch('recycles/recycling', params, {
      onSuccess: () => {
        dispatch(BusEvents.HIDE_DIALOG)
        notifySuccess(I18n.t('requests.notification.recycle_success'))
        dispatch(BusEvents.FORM_SUCCESS)
      }
    })
  }, [data, fetch])

  const buttons = useMemo(
    () => [
      {
        id: 1,
        text: I18n.t('actions.cancel'),
        cancel: true,
        class: 'cancel-button'
      },
      {
        id: 2,
        text: I18n.t('actions.recycle'),
        class: 'action-button',
        disabled: !data.responsible_id || !data.date || !data.comments,
        onClick: onRecycleRequest,
        icon: <MdPendingActions />
      }
    ],
    [data, onRecycleRequest]
  )

  const modalProps = useMemo(() => {
    return {
      modalTitle,
      formTitle,
      formSubtitle,
      label: requestTitle(dataItem.request || {}),
      sections,
      buttons,
      dataItem: data.responsible_id && data.date ? data : {},
      model: {},
      width: 48.5,
      height: 80.5,
      sectionable: false,
      closable: true,
      onSuccess: onRecycleRequest
    }
  }, [buttons, data, formTitle, formSubtitle, modalTitle, onRecycleRequest, sections])

  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => {
    if (Object.values(dataItem).length) {
      setData(dataItem)
      openModal()
    }
  }, [dataItem])

  return renderModal()
}

RecycleRequestPopup.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

RecycleRequestPopup.defaultProps = {
  dataItem: {}
}
