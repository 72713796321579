import React from 'react'
import PropTypes from 'prop-types'
import { getDescriptionField } from '/src/models/concerns/eav_section'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'
import { extractPictureColumns, getOrderedCommnents } from '/src/utils/pdf_utils'
import PrintListPDFTitle from '/src/ui/core/grid/pdf_print/print_list_pdf_title'
import PrintListPDFComments from '/src/ui/core/grid/pdf_print/print_list_pdf_comments'
import PrintListPDFSections from '/src/ui/core/grid/pdf_print/print_list_pdf_sections'
import PrintListPDFPictureColumns from '/src/ui/core/grid/pdf_print/print_list_pdf_picture_columns'

export default function PrintListPDFBody({
  gridData,
  gridSections,
  storeValues,
  modelName,
  templateTitle,
  isPictureBig
}) {
  const firstFlexibleColumnDescription = getDescriptionField(gridSections)
  const orderedSections = sortArrayOfObjectsByNumber(gridSections, 'position')
  const pictureColumns = extractPictureColumns(orderedSections)

  return gridData.map((item, index) => {
    const orderedComments = getOrderedCommnents(item, orderedSections)

    return (
      <div className={`print-list-pdf__item ${index === 0 ? '' : 'page-break'}`} key={item.id}>
        <div className="print-list-pdf__item-dot">{index + 1}</div>

        <PrintListPDFTitle
          item={item}
          modelName={modelName}
          storeValues={storeValues}
          templateTitle={templateTitle}
          firstFlexibleColumnDescription={firstFlexibleColumnDescription}
        />

        <PrintListPDFSections
          item={item}
          project={storeValues.project}
          sections={orderedSections}
          orderedComments={orderedComments}
        />

        <PrintListPDFPictureColumns
          item={item}
          storeValues={storeValues}
          columns={pictureColumns}
          isPictureBig={isPictureBig}
        />

        <PrintListPDFComments orderedComments={orderedComments} />
      </div>
    )
  })
}

PrintListPDFBody.propTypes = {
  gridData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  gridSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      position: PropTypes.number,
      eav_columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          title: PropTypes.string,
          description: PropTypes.string,
          position: PropTypes.number,
          column_type: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            description: PropTypes.string
          })
        })
      )
    })
  ).isRequired,
  storeValues: PropTypes.shape({
    units: PropTypes.oneOfType([PropTypes.object]),
    project: PropTypes.oneOfType([PropTypes.object]),
    subproject: PropTypes.oneOfType([PropTypes.object]),
    inspectStatuses: PropTypes.oneOfType([PropTypes.object]),
    progressServiceStatuses: PropTypes.oneOfType([PropTypes.object])
  }).isRequired,
  modelName: PropTypes.string.isRequired,
  templateTitle: PropTypes.string,
  isPictureBig: PropTypes.bool
}

PrintListPDFBody.defaultProps = {
  templateTitle: undefined,
  isPictureBig: true
}
