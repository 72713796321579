import React from 'react'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import Layout from '/src/ui/core/layouts/layout'
import EmbedIframe from '/src/ui/core/layouts/embed_iframe'
import '/src/static/css/report.css'

export default function Report() {
  const [queryParams] = useQueryParams({ baseUrl: StringParam, id: NumberParam })

  return (
    <Layout key={queryParams.id}>
      {queryParams.baseUrl && (
        <EmbedIframe url={queryParams.baseUrl} allowedUrls={['powerbi_iframe', 'telerik_reports']} />
      )}
    </Layout>
  )
}
