import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { isBlank } from '/src/utils/boolean_refinements'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/delta_hours_column.css'

export default function DeltaHoursColumn({ data }) {
  const [globalProject] = useStore('project')

  if (isBlank(data) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_hours)) return '---'
  if (isBlank(data.progress_summary.progress_hours)) return '---'

  const actualQuantity = data.progress_summary.actual_hours
  const progressQuantity = data.progress_summary.progress_hours
  const remainingQuantity = progressQuantity - actualQuantity

  const actualFormatted = formatNumberWithPrecision(actualQuantity, globalProject)
  const plannedFormatted = formatNumberWithPrecision(progressQuantity, globalProject)
  const remainingFormatted = formatNumberWithPrecision(remainingQuantity, globalProject)

  const isPositiveDelta = remainingQuantity >= 0
  const remainingClass = `delta-hours-column__remaining${isPositiveDelta ? '--positive' : '--negative'}`

  const currentHoursTooltip = I18n.t('progresses.current_hours')
  const plannedHoursTooltip = I18n.t('progresses.planned_hours')
  const remainingHoursTooltip = I18n.t('progresses.remaining_hours')

  return (
    <div className="delta-hours-column">
      <CustomTooltip position="left" parentTitle>
        <span className="delta-hours-column__actual" title={currentHoursTooltip}>
          {actualFormatted}
        </span>
      </CustomTooltip>
      <span>/</span>
      <CustomTooltip position="top" parentTitle>
        <span className="delta-hours-column__planned" title={plannedHoursTooltip}>
          {plannedFormatted}
        </span>
      </CustomTooltip>
      <span>/</span>
      <CustomTooltip position="right" parentTitle>
        <span className={remainingClass} title={remainingHoursTooltip}>
          {remainingFormatted}
        </span>
      </CustomTooltip>
    </div>
  )
}

DeltaHoursColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

DeltaHoursColumn.defaultProps = {
  data: {}
}

export const deltaHours = (data, project) => {
  if (isBlank(data) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_hours)) return '---'
  if (isBlank(data.progress_summary.progress_hours)) return '---'

  return formatNumberWithPrecision(data.progress_summary.actual_hours - data.progress_summary.progress_hours, project)
}
