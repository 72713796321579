import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { getFormatLocaleDateTime, dateTimeStringToLocalTime } from '/src/utils/project_formatter'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/input_date.css'
import '/src/static/css/core/inputs/input_date_time.css'

export default function InputDateTime({ inputProps }) {
  const { id, className, title, required, value, readOnly, onChange } = inputProps
  const [project] = useStore('project')
  const [defaultValue, setDefaultValue] = useState(value)

  const valueFormatter = (newValue) => (newValue ? newValue.toISOString() : '')

  const inputChangeProps = {
    id,
    value: defaultValue,
    onChange,
    valueFormatter
  }

  const [inputValue, onInputChange] = useInputChange(inputChangeProps)
  const error = useInputError({ inputValue, title, required })

  const inputClass = () => {
    const classes = ['form-input-date']
    if (!inputValue) classes.push('invisible-placeholder')
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  useEffect(() => {
    if (onChange && value === 'current_date') {
      const currentDate = new Date()
      setDefaultValue(currentDate)
      onChange(id, valueFormatter(currentDate))
    }
  }, [id, value, onChange])

  return (
    <React.Fragment>
      <DateTimePicker
        id={id}
        className={inputClass()}
        onChange={onInputChange}
        value={inputValue ? dateTimeStringToLocalTime(inputValue) : null}
        formatPlaceholder="formatPattern"
        format={getFormatLocaleDateTime(project)}
        disabled={readOnly}
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputDateTime.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool
  }).isRequired
}
