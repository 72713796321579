import React from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import DownloadAttachment from './download_attachment'
import Attachments from '/src/ui/core/inputs/attachments'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

// eslint-disable-next-line max-lines-per-function
export default function DeleteAttachment({ file, fileCategory, onDelete, removeUrlParams, route, isCreationForm }) {
  const { fetch } = useFetch()
  const [showConfirmation, renderConfirmation] = useConfirmationModal(true)

  const deleteAttachment = () => {
    fetch(route, removeUrlParams, {
      onSuccess: () => {
        notifySuccess(I18n.t('delete_attachment_modal.delete_successful'))
        if (onDelete) onDelete(file)
      }
    })
  }

  const confirmationModalParams = {
    title: I18n.t('delete_attachment_modal.title'),
    description: I18n.t('delete_attachment_modal.description'),
    actionButtonText: I18n.t('delete_attachment_modal.ok'),
    cancelButtonText: I18n.t('delete_attachment_modal.cancel'),
    buttonColor: '#B33939',
    onSuccess: () => deleteAttachment
  }

  const handleDelete = () => {
    showConfirmation(confirmationModalParams)
  }

  return (
    <div className="d-flex">
      {!isCreationForm && (
        <div className="left">
          <DownloadAttachment file={file} fileCategory={fileCategory} />
        </div>
      )}
      {renderConfirmation()}
      <Attachments fileName={file.name} fileSize={file.size} />
      {removeUrlParams && (
        <span className="icon" onClick={handleDelete} role="delete-attachment">
          <MdClose />
        </span>
      )}
    </div>
  )
}

DeleteAttachment.propTypes = {
  fileCategory: PropTypes.string,
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    uuid: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number
  }).isRequired,
  onDelete: PropTypes.func,
  removeUrlParams: PropTypes.shape({
    requestAction: PropTypes.string,
    httpAction: PropTypes.string,
    resourceId: PropTypes.number,
    additionalResource: PropTypes.oneOfType([PropTypes.object]),
    data: PropTypes.oneOfType([PropTypes.object]),
    query: PropTypes.oneOfType([PropTypes.object])
  }),
  route: PropTypes.string.isRequired,
  isCreationForm: PropTypes.bool
}

DeleteAttachment.defaultProps = {
  fileCategory: null,
  onDelete: null,
  removeUrlParams: null,
  isCreationForm: false
}
