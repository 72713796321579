import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import AttachmentUploadProgress from '/src/ui/core/progress_bars/attachment_upload_progress_bar'
import { MAX_PICTURE_SIZE_MB, PARSED_PICTURE_EXTENSIONS } from '/src/models/concerns/attachment'

export default function fixedPictureHintNote({ currentProgress, maxProgress, triggerFileSelect, totalFilesSize }) {
  const uploading = !!maxProgress

  const hint = useMemo(
    () =>
      uploading ? (
        <AttachmentUploadProgress
          currentProgress={currentProgress}
          maxProgress={maxProgress}
          attachmentTypeTranslation="form.inputs.fixed_picture.progress.attachment_type"
          filesSize={totalFilesSize}
        />
      ) : (
        <span className="hint-message">
          <span onClick={triggerFileSelect} role="button" tabIndex={0} className="select-file">
            {I18n.t(`form.inputs.fixed_picture.drop_zone.hint.select_file`)}
          </span>
          {` ${I18n.t('form.inputs.fixed_picture.drop_zone.hint.drag_and_drop')}`}
        </span>
      ),
    [currentProgress, maxProgress, totalFilesSize, triggerFileSelect, uploading]
  )

  const note = useMemo(
    () =>
      !uploading ? (
        <div className="note-message">
          {I18n.t('form.inputs.fixed_picture.drop_zone.note.extensions', { extensions: PARSED_PICTURE_EXTENSIONS })}
          <br />
          {I18n.t('form.inputs.fixed_picture.drop_zone.note.max_size', { max_size: MAX_PICTURE_SIZE_MB })}
        </div>
      ) : (
        <span />
      ),
    [uploading]
  )

  return {
    pictureHint: hint,
    pictureNote: note
  }
}

fixedPictureHintNote.propTypes = {
  currentProgress: PropTypes.number.isRequired,
  maxProgress: PropTypes.number.isRequired,
  triggerFileSelect: PropTypes.func.isRequired,
  totalFilesSize: PropTypes.number.isRequired
}
