import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ColumnFooterTitle({ showTotals, selected }) {
  if (selected > 1)
    return (
      <td className="grid-total">
        {selected} {I18n.t('grid.selecteds')}
      </td>
    )

  if (selected === 1)
    return (
      <td className="grid-total">
        {selected} {I18n.t('grid.selected')}
      </td>
    )
  return <td className="grid-total">{showTotals && I18n.t('grid.totals')}</td>
}

ColumnFooterTitle.propTypes = {
  showTotals: PropTypes.bool,
  selected: PropTypes.number
}

ColumnFooterTitle.defaultProps = {
  showTotals: true,
  selected: 0
}
