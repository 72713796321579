/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useInitialState from '/src/utils/store/initial_store'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { MdDone, MdClose } from 'react-icons/md'

export default function GridInterativeModeHeader({ tabbing }) {
  const [interactiveMode, setInteractiveMode] = useStore('interactive_mode')
  const [interactiveScopeList, setInteractiveScopeList] = useStore('interactive_scope_list')
  const [initialState] = useInitialState()
  const { loading, errors, responseData, fetchAPI } = useFetchAPI('scopes/update_in_batch')

  const disableInteractiveMode = () => {
    setInteractiveMode(initialState.interactive_mode)
    setInteractiveScopeList([])
  }

  useEffect(() => {
    return function cleanup() {
      disableInteractiveMode()
    }
  }, [])

  // open load popup dialog
  useEffect(() => {
    if (loading && !errors) {
      dispatch(BusEvents.SHOW_LOADING_DIALOG)
    } else {
      dispatch(BusEvents.HIDE_DIALOG)
      disableInteractiveMode()
    }
  }, [loading, errors])

  const updateInBatch = () => {
    const scopes = {}
    interactiveScopeList.forEach((selected) => {
      scopes[selected.id] = { label: selected.label }
    })
    const data = { fields: scopes }
    const params = { httpAction: 'put', data }
    if (Object.entries(scopes).length > 0) fetchAPI(params)
  }

  return (
    <React.Fragment>
      {interactiveMode && interactiveMode.status && (
        <div data-testid="interactive-mode" className="interactive-mode">
          <p>{`${interactiveScopeList.length} ${I18n.t('grid.select_items')}`}</p>
          <div>
            <button
              data-testid="approve"
              type="button"
              className="button-rounded default"
              onClick={() => updateInBatch()}
            >
              <span>
                <MdDone />
              </span>
            </button>
            <button
              data-testid="cancel"
              type="button"
              className="button-rounded cancel"
              onClick={disableInteractiveMode}
            >
              <span>
                <MdClose />
              </span>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

GridInterativeModeHeader.propTypes = {
  tabbing: PropTypes.number
}

GridInterativeModeHeader.defaultProps = {
  tabbing: undefined
}
