/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import { useStore } from 'react-context-hook'
import useFetch from '/src/hooks/api/fetch'
import useRequestsPinned from '/src/hooks/requests_pinned'
import BusEvents from '/src/hooks/bus/bus_events'
import PlanningModel from '/src/models/planning'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import useProgressServices from '/src/ui/domain/progress_services/progress_services'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import { forceShowColumnsOnEditableGrid, progressToEditableGrid } from '/src/utils/columns_formatter'
import { isProgressColumnNotEditable } from '/src/models/progress'
import { NON_EDITABLE_SERVICES_STATUSES } from '/src/utils/constants/progress_services'
import { byString } from '/src/utils/object'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import Label from '/src/ui/core/grid/label'
import I18n from '/src/utils/translations'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import useModel from '/src/ui/core/forms/model_hook'
import ProgressServiceFormModal from '/src/ui/domain/progress_services/progress_services_form_modal'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import { indexify } from '/src/utils/array'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function PlanningGrid({ templates }) {
  const { requestId: requestIdPin } = useRequestsPinned()

  const [subproject] = useStore('subproject')
  const { fetch } = useFetch()
  const [templateTitle, setTemplateTitle] = useState()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [planningModel, onChangeColumn] = useModel(new PlanningModel(), ['statuses'])

  const closeSidePanel = () => {
    setColumnStyles(SIDE_PANEL_CLOSED)
  }
  const openSidePanel = () => {
    setColumnStyles(SIDE_PANEL_OPENED)
  }
  useBus(
    BusEvents.SIDE_PANEL_CLOSED,
    () => {
      closeSidePanel()
      setServiceSelectedItem(undefined)
    },
    [serviceSelectedItem]
  )

  const serviceRowEditable = (row) => {
    const itemStatusI18n = byString(progressServiceStatuses, `${row.progress_service_status_id}.i18n_id`)
    return !NON_EDITABLE_SERVICES_STATUSES.includes(itemStatusI18n)
  }

  const editableGridProps = {
    topEntity: {
      model: planningModel,
      shouldAllowCell: (column, dataItem) => {
        const { available_on_formula: availableOnFormula } = column
        const itemOrPropertyMissing = !dataItem || dataItem.progress_service_status_id === undefined

        if (isProgressColumnNotEditable(column, dataItem, progressServiceStatuses)) return false
        if (itemOrPropertyMissing || !availableOnFormula) return true
        return serviceRowEditable(dataItem)
      },
      isRowEditable: (row) => !row.id || serviceRowEditable(row)
    },
    allowCreate: false,
    allowDelete: true,
    allowDuplicate: false
  }

  const [editableGrid, setInEditMode, editableTopGridColumns, editableTopGridDataSource] = useEditableGrid(
    editableGridProps
  )

  const selectedTemplate = templates.find((template) => template.title === templateTitle)

  const onProgressServiceRowClick = useCallback(
    (e) => {
      if (!e) {
        setServiceSelectedItem()
        closeSidePanel()
        return
      }
      const { dataItem } = e
      let newItem
      if (!serviceSelectedItem || serviceSelectedItem.id !== dataItem.id) {
        newItem = dataItem
        openSidePanel()
      } else {
        newItem = null
        closeSidePanel()
      }
      setServiceSelectedItem(newItem)
    },
    [serviceSelectedItem]
  )

  const labels = [<Label key="planning-view" title={I18n.t('planning.view')} />]

  const fetchRequests = useCallback(
    ({ data: dataSource }) => {
      if (
        !dataSource ||
        dataSource.length === 0 ||
        dataSource.every((dataItem) => dataItem?.request?.request_status_id)
      )
        return

      const queryParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: { id: dataSource.map((planning) => planning.request.id) }
        }
      }

      fetch('requests', queryParams, {
        onSuccess: ({ data: { data } }) => {
          const modelName = planningModel.paramName

          const requestsById = indexify(data, 'id')
          const onSetDataSource = (prevDataSource) => ({
            ...prevDataSource,
            data: prevDataSource.data.map((item) => ({
              ...item,
              request: requestsById[item.request.id]
            }))
          })

          dispatch({
            type: BusEvents.UPDATE_GRID_DATA_SOURCE,
            payload: { modelName, onSetDataSource }
          })
        }
      })
    },
    [fetch, planningModel]
  )

  const onPlanningGridDataSource = (dataSource) => {
    fetchRequests(dataSource)
    editableTopGridDataSource.set(dataSource)

    const newSelectedItem = dataSource.data.filter((item) => item.id === serviceSelectedItem?.id)[0]

    if (!isEqual(newSelectedItem, serviceSelectedItem)) {
      if (!newSelectedItem) closeSidePanel()
      else {
        setServiceSelectedItem(newSelectedItem)
      }
    }
  }

  const onSetPlanningGridColumns = (columns) => {
    let formattedColumns = forceShowColumnsOnEditableGrid(columns, ['request.id', 'item_number', 'service_number'])

    if (subproject.progress_type === 'service') {
      formattedColumns = progressToEditableGrid(formattedColumns)
    }

    editableTopGridColumns.set(formattedColumns)
  }

  const useProgressServiceProps = {
    handleCloseSidePanel: closeSidePanel,
    serviceSelectedItem,
    filtered: false,
    setInEditMode,
    onSelectServiceItem: onProgressServiceRowClick,
    onClick: onProgressServiceRowClick,
    showConfirmation,
    labels,
    serviceModel: planningModel,
    requestIdPin,
    serviceSidePanelDataItem: serviceSelectedItem,
    onGridDataSource: onPlanningGridDataSource,
    onGridColumns: onSetPlanningGridColumns
  }

  const [serviceGrid, serviceSidePanel, _, setServiceTemplate] = useProgressServices(
    useProgressServiceProps
  )

  const onTemplateChange = (template) => {
    const { title } = template
    const selectedTemplate = templates.find((template) => template.title === title)
    setTemplateTitle(title)
    setServiceTemplate(selectedTemplate)
    closeSidePanel()
    setServiceSelectedItem()
  }

  if (selectedTemplate) translateFormulaServiceColumns(planningModel, selectedTemplate.id)

  useEffect(() => {
    onChangeColumn('statuses', progressServiceStatuses)
  }, [progressServiceStatuses])

  return (
    <React.Fragment>
      {editableGrid(
        <React.Fragment>
          {renderConfirmation()}
          <TabsWrapper tabType="progress_service" onTemplateChange={onTemplateChange} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div className="shadowed-page">
              {templates.length > 0 && selectedTemplate && (
                <React.Fragment key={selectedTemplate.id}>{serviceGrid(selectedTemplate)}</React.Fragment>
              )}
            </div>
            {serviceSelectedItem ? serviceSidePanel() : <div />}
          </LayoutPanels>
          <ProgressServiceFormModal />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

PlanningGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  templates: PropTypes.arrayOf(PropTypes.object).isRequired
}
