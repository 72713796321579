import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/core/panels/side_panel_tabs.css'

export default function SidePanelTabs({ tabs, defaultTab }) {
  const defaultTabIndex = defaultTab >= 0 ? defaultTab : 0

  const [currentTab, setCurrentTab] = useState(defaultTabIndex)
  const defaultTabExpandSidePanel = useRef(isPresent(tabs?.[defaultTab]?.expandSidePanelWidth))

  if (!tabs || tabs.length === 0) return null

  const onTabClick = useCallback(
    (index) => {
      setCurrentTab((currentIndex) => {
        if (currentIndex === index) return currentIndex
        dispatch({
          type: BusEvents.EXPAND_SIDE_PANEL_WIDTH,
          payload: isPresent(tabs[index].expandSidePanelWidth)
        })
        return index
      })
    },
    [tabs]
  )

  useBus(
    BusEvents.OPEN_SIDE_PANEL_TAB,
    ({ payload }) => {
      const { name: tabName } = payload
      const tabIndex = tabs.findIndex(({ name }) => tabName === name)
      if (tabIndex >= 0) onTabClick(tabIndex)
    },
    [tabs, onTabClick]
  )

  useEffect(() => {
    if (defaultTabExpandSidePanel.current) dispatch({ type: BusEvents.EXPAND_SIDE_PANEL_WIDTH, payload: true })
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: BusEvents.EXPAND_SIDE_PANEL_WIDTH, payload: false })
    }
  }, [])

  return (
    <React.Fragment>
      <div className="side-panel-tab-names">
        {tabs.map((tab, index) => {
          const className = currentTab === index ? 'active-tab-name' : 'tab-name'
          return (
            tab && (
              <div className="tab-name-wrapper" key={`${tab.index}-${tab.name}`}>
                <span className={className} onClick={() => onTabClick(index)}>
                  {I18n.t(`side_panel.tabs.${tab.name}`)}
                </span>
              </div>
            )
          )
        })}
      </div>

      <div className="side-panel-content">{tabs[currentTab]?.body}</div>
    </React.Fragment>
  )
}

SidePanelTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      body: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    })
  ),
  defaultTab: PropTypes.number
}

SidePanelTabs.defaultProps = {
  tabs: [],
  defaultTab: 0
}
