import { useState, useCallback } from 'react'
import lodash from 'lodash'
import { merge, remove } from '/src/utils/array'

export default function useGridFilterSelection(setFilter) {
  const [gridFilter, setGridFilter] = useState([])

  const { isEqual } = lodash

  const onFilterSelection = useCallback(
    (selectedFilter) => {
      if (isEqual(selectedFilter, gridFilter)) return

      setGridFilter(selectedFilter)

      setFilter((oldFilter) => {
        const cleanedFilter = remove(oldFilter, gridFilter)
        return merge(cleanedFilter, selectedFilter)
      })
    },
    [gridFilter, isEqual]
  )

  return onFilterSelection
}
