import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import I18n from '/src/utils/translations'

export default function Block({ block }) {
  const [className, setClassName] = useState('')
  const [style, setStyle] = useState({})
  const [closed, setClosed] = useState(block.collapsible)

  const { border, component, maxHeight, radius, title, type } = block

  const buildClass = () => {
    let newClassName = `side-panel-block ${type}-block`
    newClassName += radius === false ? '' : ' block-radius'
    newClassName += border === false ? '' : ' block-border'
    newClassName += maxHeight ? ' block-scroll-y' : ''

    return newClassName
  }

  const buildStyle = () => {
    let newStyle = { ...style }
    const maxHeightObj = maxHeight ? { maxHeight, overflowY: 'auto' } : { maxHeight: undefined }

    newStyle = { ...newStyle, ...maxHeightObj }

    return newStyle
  }

  useEffect(() => {
    if (!block) return

    setClassName(buildClass())
    setStyle(buildStyle())
  }, [block])

  return (
    <div className={className} style={style}>
      {title && (
        <div className="block-title" onClick={() => setClosed((prev) => !prev)}>
          <div className={block.collapsible && 'title-content'}>
            <span>{title}</span>
            {block.collapsible && (
              <span className="collapse-button">
                {closed ? I18n.t('side_menu.expand') : I18n.t('side_menu.collapse')}
              </span>
            )}
          </div>
        </div>
      )}
      {!block.collapsible && component}
      {block.collapsible && (
        <AnimateHeight duration={300} height={closed ? 0 : 'auto'}>
          {component}
        </AnimateHeight>
      )}
    </div>
  )
}

Block.propTypes = {
  block: PropTypes.shape({
    border: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.object]).isRequired,
    maxHeight: PropTypes.string,
    radius: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string.isRequired,
    collapsible: PropTypes.bool
  })
}

Block.defaultProps = {
  block: PropTypes.shape({
    border: true,
    maxHeight: null,
    radius: true,
    collapsible: false
  })
}
