import React, { useEffect, useState } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import PropTypes from 'prop-types'
import { MdAddCircle } from 'react-icons/md'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import I18n from '/src/utils/translations'
import SubRequestPanel from '/src/ui/domain/sub_requests/sub_request_panel'
import SubRequestItem from '/src/ui/domain/sub_requests/sub_request_item'
import '/src/static/css/domain/sub_requests/sub_request.css'
import '/src/static/css/core/buttons/buttons.css'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function SubRequestsGrid({ request, scopeTemplateId, label }) {
  const [allSubRequests, setAllSubRequests] = useState([])
  const [subRequests, setSubRequests] = useState([])
  const getSubRequests = useFetchAPI('requests')

  const [subScopes, setSubScopes] = useState([])
  const getSubScopes = useFetchAPI('scopes')

  const [scopes, setScopes] = useState([])
  const getScopes = useFetchAPI('scope_summaries')

  const [scopeCluster, setScopeCluster] = useState({})
  const getCluster = useFetchAPI('scope_cluster_summaries')

  const postSubRequest = useFetchAPI('requests/create_subrequest')

  const subRequestsQueryParams = {
    requestAction: 'READ',
    httpAction: 'get',
    query: { where: { parent_id: request.id } }
  }

  const loadData = () => {
    const scopesQueryParams = {
      httpAction: 'get',
      dataOptions: {
        paging: { skip: 0, pageSize: 30 },
        sorting: [{ field: 'number', dir: 'asc' }],
        filtering: {
          filter: {
            logic: 'and',
            filters: [
              { type: 'where', column: 'request_id', value: request.id },
              { type: 'where', column: 'eav_template_id', value: scopeTemplateId },
              { type: 'where', column: 'label', value: label }
            ]
          }
        }
      }
    }
    getScopes.fetchAPI(scopesQueryParams)
    getCluster.fetchAPI(scopesQueryParams)
  }

  const createSubRequest = () => {
    const newSubRequest = { ...request }
    newSubRequest.parent_id = request.id
    newSubRequest.id = null

    const subRequestData = {
      request: newSubRequest,
      parent_id: request.id,
      scopes_with_factors: scopes.map((s) => {
        return { scope_id: s.id, factor: 0 }
      })
    }

    const createSubRequestQueryParams = {
      httpAction: 'post',
      data: subRequestData,
      dialogMessages: true
    }

    postSubRequest.fetchAPI(createSubRequestQueryParams)
  }

  const fetchSubScopes = () => {
    const parentScopesIds = scopes.map((s) => s.id)

    const subScopesQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { label, parent_id: parentScopesIds } }
    }

    getSubScopes.fetchAPI(subScopesQueryParams)
  }

  useEffect(() => loadData(), [])

  // 1st useEffect to handle the GET request of the parent scopes list
  useEffect(() => {
    if (getScopes.loading) return
    if (getScopes.errors) return
    if (getScopes.responseData.data.length == 0) return

    setScopes(getScopes.responseData.data)

    getSubRequests.fetchAPI(subRequestsQueryParams)
  }, [getScopes.loading, getScopes.errors, getScopes.responseData])

  // 2nd useEffect to handle the GET request of the sub requests list that will be filtered
  useEffect(() => {
    if (getSubRequests.loading) return
    if (getSubRequests.errors) return
    if (getSubRequests.responseData.data.length == 0) return

    setAllSubRequests(getSubRequests.responseData.data)

    fetchSubScopes()
  }, [getSubRequests.loading, getSubRequests.errors, getSubRequests.responseData])

  // 3rd useEffect to use the proper sub scopes and filter the sub requests list
  useEffect(() => {
    if (getSubScopes.loading) return
    if (getSubScopes.errors) return
    if (getSubScopes.responseData.data.length == 0) return

    const labelSubScopes = getSubScopes.responseData.data
    setSubScopes(labelSubScopes)

    const validSubRequestIds = labelSubScopes.map((s) => s.request_id)
    const newSubRequests = allSubRequests.filter((sr) => validSubRequestIds.includes(sr.id))
    setSubRequests(newSubRequests)
  }, [getSubScopes.loading, getSubScopes.errors, getSubScopes.responseData])

  useEffect(() => {
    if (getCluster.loading) return
    if (getCluster.errors) return
    if (getCluster.responseData.data.length == 0) return

    setScopeCluster(getCluster.responseData.data['0'])
  }, [getCluster.loading, getCluster.errors, getCluster.responseData])

  useEffect(() => {
    if (postSubRequest.loading) {
      dispatch(BusEvents.SHOW_LOADING_DIALOG)
      return
    }

    if (postSubRequest.errors) return
    if (!postSubRequest.responseData.hasOwnProperty('id')) return

    getSubRequests.fetchAPI(subRequestsQueryParams)
  }, [postSubRequest.loading, postSubRequest.errors, postSubRequest.responseData])

  return (
    <FormWrapper
      model={{
        singularName: I18n.t('sub_requests.creation_page.title')
      }}
      backText={I18n.t('sub_requests.creation_page.back')}
      classes="full"
    >
      <div className="sub-request">
        <div className="panels">
          <SubRequestPanel
            request={request}
            scopes={scopes}
            templateId={scopeTemplateId}
            scopeCluster={scopeCluster}
            label={label}
          />
          <div className="sub-requests-list">
            <span className="header">
              <span className="title">{I18n.t('sub_requests.creation_page.all')}</span>
              <span className="counter">{`${subRequests.length} ${I18n.t('requests.sub_requests')}`}</span>
            </span>
            <div className="items">
              {subRequests.map((subRequest) => (
                <SubRequestItem
                  key={subRequest.id}
                  subRequest={subRequest}
                  loadData={loadData}
                  scopes={scopes}
                  templateId={scopeTemplateId}
                />
              ))}
              <div className="new" onClick={createSubRequest} data-testid="create-sub-request">
                <span className="add-button">
                  <MdAddCircle />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  )
}

SubRequestsGrid.propTypes = {
  request: PropTypes.oneOfType([PropTypes.object]).isRequired,
  scopeTemplateId: PropTypes.number,
  label: PropTypes.string
}

SubRequestsGrid.defaultProps = {
  scopeTemplateId: undefined,
  label: undefined
}
