import React from 'react'
import PropTypes from 'prop-types'
import { MdModeEditOutline } from 'react-icons/md'

export default function BatchEditionUpdateButton({ onEditAction }) {
  return (
    <div
      className="columns-batch-assigner--action-button"
      onClick={() => onEditAction()}
      data-testid="batch-edition-update-button"
    >
      <MdModeEditOutline />
    </div>
  )
}

BatchEditionUpdateButton.propTypes = {
  onEditAction: PropTypes.func
}

BatchEditionUpdateButton.defaultProps = {
  onEditAction: () => {}
}
