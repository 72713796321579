const forbiddenTypesOnGrid = [
  'drop_formula_id',
  'drop_formula_variables',
  'multiple_drop',
  'datasheet_result',
  'datasheet_aggregation',
  'gps',
  'picture',
  'fixed_picture',
  'signature',
  'attachment',
  'matrix',
  'matrix_dimension'
]

export default forbiddenTypesOnGrid
