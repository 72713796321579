import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import useTabs from '/src/ui/core/layouts/template_tabs_hook'
import TemplateTabs from '/src/ui/core/layouts/template_tabs'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import ContentLoader from 'react-content-loader'
import I18n from '/src/utils/translations'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useRequestFilterByDiscipline from '/src/hooks/request_filter_by_discipline'
import useGeneralDiscipline from '/src/hooks/general_discipline'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/layouts/tabs_wrapper.css'

const isTabsEmpty = (tabs) => !tabs || tabs.length === 0 || !tabs[0] || !tabs[0].id

const notifyNoTemplate = () => notifyError({ body: I18n.t('templates.no_template'), closeTimeout: 10 })

export default function TabsWrapper({ tabType, onTemplateChange, notifications }) {
  const generalDiscipline = useGeneralDiscipline()

  const [tabsList, setTabsList] = useState()
  const { responseData, status } = useTabs(tabType)

  const { selectedRequests, isRequestsFilled, hasRequestWithDisciplineId } = useRequestsPinned()
  const filterByDiscipline = useRequestFilterByDiscipline()

  const loadingDiv = (
    <ContentLoader speed={2} height={37} width="100%">
      <rect x="5" y="0" rx="10" ry="10" width="100" height="10" />
      <rect x="125" y="0" rx="10" ry="10" width="50" height="10" />
      <rect x="200" y="0" rx="10" ry="10" width="150" height="10" />
    </ContentLoader>
  )

  const setTemplateTabs = useCallback((data) => {
    setTabsList(data)
    dispatch({ type: BusEvents.SET_EDITABLE_GRID_EAV_TEMPLATE, payload: { eavTemplates: data } })
  }, [])

  const putNotifications = useCallback(() => {
    return tabsList?.map((tab) => ({
      ...tab,
      notificationsCount: notifications.find((notification) => notification.id === tab.id)?.notificationsCount
    }))
  }, [notifications, tabsList])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const { data } = responseData

    if (isTabsEmpty(data)) notifyNoTemplate()

    if (filterByDiscipline) {
      const tabsFiltered = data.filter(
        (tab) =>
          !isRequestsFilled() ||
          !tab.discipline_id ||
          hasRequestWithDisciplineId(tab.discipline_id) ||
          (generalDiscipline && tab.discipline_id === generalDiscipline.id)
      )

      if (isRequestsFilled()) setTemplateTabs(tabsFiltered)
    } else {
      setTemplateTabs(data)
    }
  }, [responseData, selectedRequests, isRequestsFilled, hasRequestWithDisciplineId, setTemplateTabs])

  return !tabsList || (!filterByDiscipline && tabsList.length === 0) ? (
    loadingDiv
  ) : (
    <TemplateTabs tabs={notifications? putNotifications() : tabsList} onTemplateChange={onTemplateChange} />
  )
}

TabsWrapper.propTypes = {
  tabType: PropTypes.string.isRequired,
  onTemplateChange: PropTypes.func,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    notificationsCount: PropTypes.number
  }))
}

TabsWrapper.defaultProps = {
  onTemplateChange: null,
  notifications: null
}
