import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import DatasheetsTrackBlock from './datasheets_track_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/model_side_panel.css'

export default function DatasheetsSidePanel({ dataItem, onClose, sections, contextMenuItems, columns }) {
  const sectionBlocks = useSectionBlock({ sections, dataItem })
  const [blocks, setBlocks] = useState()

  useEffect(() => {
    if (!dataItem) onClose()
    else setBlocks([buildSummary(), ...sectionBlocks])
  }, [dataItem])

  const buildSummary = () => ({
    component: <DatasheetsTrackBlock dataItem={dataItem} columns={columns} contextMenuItems={contextMenuItems} />,
    title: '',
    type: 'track',
    key: 'datasheet-track-block'
  })

  return (
    <div className="model-side-panel">
      {dataItem && (
        <BlocksSidePanel
          title={I18n.t('datasheets.datasheets_detail.side_panel_title')}
          closable
          onClose={onClose}
          blocks={blocks}
        />
      )}
    </div>
  )
}

DatasheetsSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired
}
