import React, { useState, useRef, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import EditableGridWrapper from '/src/ui/core/grid/editable_grid_wrapper'

// eslint-disable-next-line max-lines-per-function
/**
 *
 * @param {Object} topEntity - top entity related props (model, shouldAllowCell, onCreateNewItem)
 * @param {Object} bottomEntity - bottom entity related props (model, shouldAllowCell, onCreateNewItem)
 * @param recoverSettings
 * @param {boolean} allowCreate
 * @returns {[(function(*)),((value: (((prevState: boolean) => boolean) | boolean)) => void),{set: (value: (prevState: undefined) => undefined) => void, get: undefined},{set: (value: (prevState: undefined) => undefined) => void, get: undefined},{set: (value: (prevState: undefined) => undefined) => void, get: undefined},null]}
 */
export default function useEditableGrid({
  topEntity,
  bottomEntity,
  recoverSettings,
  allowCreate,
  allowDelete,
  tabTemplate,
  filterStepsModalOpts,
  allowDuplicate
}) {
  const [inEditMode, setInEditMode] = useState(false)
  const [topGridColumns, setTopGridColumns] = useState()
  const [topGridDataSource, setTopGridDataSource] = useState()
  const [bottomGridColumns, setBottomGridColumns] = useState()
  const [bottomGridDataSource, setBottomGridDataSource] = useState()
  const [globalSubProject] = useStore('subproject')
  const currentSubProject = useRef(globalSubProject)

  useEffect(() => {
    if (!globalSubProject || !globalSubProject.id || currentSubProject.current.id === globalSubProject.id) return
    currentSubProject.current = globalSubProject
    setInEditMode(false)
  }, [globalSubProject])

  const dataSource = (option) => {
    if (option === 'top') return topGridDataSource
    if (option === 'bottom') return bottomGridDataSource
  }

  const gridColumns = (option) => {
    if (option === 'top') return topGridColumns
    if (option === 'bottom') return bottomGridColumns
  }

  const model = (option) => {
    if (option === 'bottom') return bottomEntity.model
    return topEntity.model
  }

  const shouldAllowCell = (option) => {
    if (option === 'top' && topEntity.shouldAllowCell) return topEntity.shouldAllowCell
    if (option === 'bottom' && bottomEntity.shouldAllowCell) return bottomEntity.shouldAllowCell
    return undefined
  }

  const isRowEditable = (option) => {
    if (option === 'top' && topEntity.isRowEditable) return topEntity.isRowEditable
    if (option === 'bottom' && bottomEntity.isRowEditable) return bottomEntity.isRowEditable
    return undefined
  }

  const exitEditableMode = () => {
    setInEditMode(false)
    if (recoverSettings) {
      dispatch({ type: BusEvents.EXITING_EDITABLE_MODE, payload: recoverSettings })
    }
  }

  const onCreateNewItem = (option) => {
    if (option === 'top') return topEntity.onCreateNewItem
    if (option === 'bottom') return bottomEntity.onCreateNewItem
  }

  const isCreateAllowed = (option) => {
    if (topEntity?.hideCreate || bottomEntity?.hideCreate) {
      if (option === 'top') return !topEntity.hideCreate
      if (option === 'bottom') return !bottomEntity.hideCreate
    }
    return allowCreate
  }

  return [
    (children) => (
      <EditableGridWrapper
        model={model(inEditMode)}
        dataSource={dataSource(inEditMode)}
        columns={gridColumns(inEditMode)}
        shouldAllowCell={shouldAllowCell(inEditMode)}
        inEdit={inEditMode}
        onCancel={exitEditableMode}
        onAction={exitEditableMode}
        allowCreate={isCreateAllowed(inEditMode)}
        allowDelete={allowDelete}
        allowDuplicate={allowDuplicate}
        onCreateNewItem={onCreateNewItem(inEditMode)}
        isRowEditable={isRowEditable(inEditMode)}
        tabTemplate={tabTemplate}
        filterStepsModalOpts={filterStepsModalOpts}
      >
        {children}
      </EditableGridWrapper>
    ),
    setInEditMode,
    { set: setTopGridColumns, get: topGridColumns },
    { set: setTopGridDataSource, get: topGridDataSource },
    { set: setBottomGridColumns, get: bottomGridColumns },
    { set: setBottomGridDataSource, get: bottomGridDataSource }
  ]
}
