import React from 'react'
import { Loader } from '/src/ui/core/loaders/loader'

export default function GridLoader() {
  return (
    <React.Fragment>
      <div className="loading-header" data-testid="loading-header" />
      <div className="loading-body">
        {Array(9)
          .fill('')
          .map((e, i) => (
            <div className="loading-line" key={i}>
              <Loader key={i} />
            </div>
          ))}
      </div>
    </React.Fragment>
  )
}
