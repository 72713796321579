import React from 'react'
import PropTypes from 'prop-types'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import ClearAllFiltersButton from '/src/ui/core/grid/clear_all_filters_button'
import { isBlank } from '/src/utils/boolean_refinements'
import '/src/static/css/core/grid/grid.css'

export default function SimpleGridLabels({
  gridColumns,
  labels,
  kendoGridFilters,
  clearColumnFilters,
  clearAllFiltersCallback,
  modelParamName,
  skipClearRequest,
  clearAllFiltersVisibility
}) {
  const chipsData = kendoGridFilters.map(({ field, column, value, operator }) => {
    const fieldColumn = field || column
    const text = gridColumns.find((col) => [col.field, col.description].includes(fieldColumn))
    if (!text || isBlank(value)) return undefined

    return (
      <LabelFilter
        key={fieldColumn}
        value={value}
        operator={operator}
        text={text.title}
        onClick={() => clearColumnFilters([fieldColumn])}
        suppressLink
        classes="filter-badge"
      />
    )
  })

  const labelsEmpty = () => {
    return labels.filter((label) => label !== null && label !== undefined && typeof label !== 'boolean').length > 0
  }

  const clearAllFiltersLabel = (
    <ClearAllFiltersButton
      key="clear-all-filters-button"
      onClick={clearAllFiltersCallback}
      modelParamName={modelParamName}
      visibililty={chipsData.length > 0 || (clearAllFiltersVisibility && labelsEmpty())}
      skipClearRequest={skipClearRequest}
    />
  )

  return [...labels, ...chipsData, clearAllFiltersLabel]
}

SimpleGridLabels.propTypes = {
  gridColumns: PropTypes.array,
  labels: PropTypes.array,
  kendoGridFilters: PropTypes.arrayOf(
    PropTypes.shape({
      logic: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          operator: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
            PropTypes.string,
            PropTypes.shape({ start: PropTypes.string, end: PropTypes.string })
          ])
        })
      )
    })
  ),
  clearColumnFilters: PropTypes.func.isRequired,
  modelParamName: PropTypes.string.isRequired,
  clearAllFiltersCallback: PropTypes.func,
  skipClearRequest: PropTypes.bool,
  clearAllFiltersVisibility: PropTypes.bool
}

SimpleGridLabels.defaultProps = {
  gridColumns: [],
  labels: [],
  kendoGridFilters: [],
  clearColumnFilters: () => {},
  clearAllFiltersCallback: () => {},
  skipClearRequest: false,
  clearAllFiltersVisibility: true
}
