import React, { useState } from 'react'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Modal from '/src/ui/core/popups/modal'
import ColoredProgressBar from '/src/ui/core/progress_bars/colored_progress_bar'
import I18n from '/src/utils/translations'
import { MIN_TOTAL_PAGES_FOR_SHOW_LOADING_MODAL } from '/src/hooks/api/fetch_api'
import '/src/static/css/loading_batch_entities.css'

export default function LoadingBatchEntities() {
  const [status, setStatus] = useState({ loading: false, totalPages: 0 })
  const [showLoadingModal, setShowLoadingModal] = useState(false)

  useBus(
    BusEvents.BATCH_ENTITIES_LOADING,
    ({ payload }) => {
      if (payload.loading && payload.totalPages >= MIN_TOTAL_PAGES_FOR_SHOW_LOADING_MODAL) {
        setStatus({ ...payload, page: payload.totalPages - payload.pagesLeft })
        setShowLoadingModal(true)
      } else setShowLoadingModal(false)
    },
    []
  )

  return (
    <React.Fragment>
      {showLoadingModal && (
        <Modal
          height={20}
          width={30}
          className="loading-batch-entities-modal"
          title={I18n.t('loading_batch_entities.title')}
        >
          <div id="loadingBatchEntities" className="loading-batch-entities-content">
            <span>{I18n.t('main_dialog.loading_message')}</span>
            <ColoredProgressBar progress={status.page / status.totalPages} />
            <div className="loading-batch-entities-info">
              <span>{I18n.t('loading_batch_entities.page_of', { page: status.page, total: status.totalPages })}</span>
              <span className="loading-batch-entities-percentage">
                {((status.page / status.totalPages) * 100).toFixed(2)}%
              </span>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}
