import { useEffect, useState } from 'react'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { useStore } from 'react-context-hook'
import useSyncBatch from './sync_batch'
import useUpdateLastSync from '/src/hooks/api/update_last_sync'
import { DISTANT_DATE } from '/src/utils/constants/date'

/**
 * This custom hook will store entities data using <SyncBatch /> to call them
 *
 * @param additionalEntities - it's an array of strings with name entities names
 * aditional entities
 * @return {*}
 */
export default function useBatchEntities(additionalEntities, isFixedForm = false) {
  const [disciplines, setDisciplines] = useStore('disciplines')
  const [units, setUnits] = useStore('units')
  const [servicePackages, setServicePackages] = useStore('service_packages')
  const [areas, setAreas] = useStore('areas')
  const [subareas, setSubareas] = useStore('subareas')
  const [requestStatuses, setRequestStatuses] = useStore('request_statuses')
  const [scaffoldingStatuses, setScaffoldingStatuses] = useStore('scaffolding_statuses')
  const [inspectStatuses, setInspectStatuses] = useStore('inspect_statuses')
  const [progressStatuses, setProgressStatuses] = useStore('progress_statuses')
  const [progressServiceStatuses, setProgressServiceStatuses] = useStore('progress_service_statuses')
  const [estimateStatuses, setEstimateStatuses] = useStore('estimate_statuses')
  const [formulas, setFormulas] = useStore('formulas')
  const [employees, setEmployees] = useStore('employees')
  const [fileCategory, setFileCategory] = useStore('file_categories')
  const [variableTypes, setVariableTypes] = useStore('variable_types')
  const [datasheetCategories, setDatasheetCategories] = useStore('datasheet_categories')
  const [inspectionCategories, setInspectionCategories] = useStore('inspection_categories')
  const [fieldSettings, setFieldSettings] = useStore('field_settings')
  const [contractServices, setContractServices] = useStore('contract_services')
  const [scaffoldingTypes, setScaffoldingTypes] = useStore('scaffolding_types')
  const [areaNoises, setAreaNoises] = useStore('area_noises')
  const [loadClasses, setLoadClasses] = useStore('load_classes')
  const [reportTemplates, setReportTemplates] = useStore('report_templates')
  const [reportTemplatesRoles, setReportTemplatesRoles] = useStore('report_templates_roles')
  const [updatedLastSync, setEntityLastSync] = useUpdateLastSync()
  const [lastSync, _] = useState(updatedLastSync)

  if (isFixedForm) return false

  const batchedEntities = {
    disciplines: { get: disciplines, set: setDisciplines },
    units: { get: units, set: setUnits },
    service_packages: { get: servicePackages, set: setServicePackages },
    areas: { get: areas, set: setAreas },
    subareas: { get: subareas, set: setSubareas },
    request_statuses: { get: requestStatuses, set: setRequestStatuses },
    scaffolding_statuses: { get: scaffoldingStatuses, set: setScaffoldingStatuses },
    inspect_statuses: { get: inspectStatuses, set: setInspectStatuses },
    progress_statuses: { get: progressStatuses, set: setProgressStatuses },
    progress_service_statuses: { get: progressServiceStatuses, set: setProgressServiceStatuses },
    estimate_statuses: { get: estimateStatuses, set: setEstimateStatuses },
    formulas: { get: formulas, set: setFormulas },
    employees: { get: employees, set: setEmployees },
    file_categories: { get: fileCategory, set: setFileCategory },
    variable_types: { get: variableTypes, set: setVariableTypes },
    datasheet_categories: { get: datasheetCategories, set: setDatasheetCategories },
    inspection_categories: { get: inspectionCategories, set: setInspectionCategories },
    field_settings: { get: fieldSettings, set: setFieldSettings },
    contract_services: { get: contractServices, set: setContractServices },
    scaffolding_types: { get: scaffoldingTypes, set: setScaffoldingTypes },
    area_noises: { get: areaNoises, set: setAreaNoises },
    load_classes: { get: loadClasses, set: setLoadClasses },
    report_templates: { get: reportTemplates, set: setReportTemplates },
    report_templates_roles: { get: reportTemplatesRoles, set: setReportTemplatesRoles }
  }

  const onPageFetch = (entityName, entityData, newData, inactives) => {
    if (inactives) inactives.forEach((entity) => delete entityData[entity.id])

    batchedEntities[entityName].set(entityData)
    setEntityLastSync(entityName, newData, inactives)
  }

  if (additionalEntities) {
    additionalEntities.forEach((entity) => {
      const { foreignKey, query } = entity
      const [get, set] = useStore(foreignKey)
      batchedEntities[foreignKey] = { get, set, query }
    })
  }

  Object.keys(batchedEntities).forEach((key) => {
    const currentQuery = batchedEntities[key].query
    batchedEntities[key].onPageFetch = onPageFetch
    if (key !== 'report_templates_roles') {
      batchedEntities[key].query = { ...currentQuery, order: { updated_at: 'asc' } }
    }
    if (lastSync && lastSync[key])
      batchedEntities[key].query.where = {
        updated_at: `${lastSync[key]}..${DISTANT_DATE}`
      }
  })

  const { loading, status } = useSyncBatch(batchedEntities, undefined, true)

  useEffect(() => {
    dispatch({
      type: BusEvents.BATCH_ENTITIES_LOADING,
      payload: status
    })
  }, [status])

  return loading
}
