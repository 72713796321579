import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import FormHeader from '/src/ui/core/forms/form_header'
import useSendForm from '/src/ui/core/forms/send_form_hook'
import Button from '/src/ui/core/buttons/button'
import '/src/static/css/core/grid/side_panel/side_panel_form.css'

// eslint-disable-next-line max-lines-per-function
export default function SidePanelFormWrapper({
  title,
  subtitle,
  badge,
  buttons,
  separator,
  dataItem,
  model,
  validateForm,
  onClose,
  onCreateNew,
  children,
  awaitFormSuccess
}) {
  const action = dataItem?.id ? 'update' : 'create'

  const [createNew, setCreateNew] = useState()

  const onCloseForm = () => onClose && onClose()
  const onSuccessForm = () => {
    if (!createNew) {
      onCloseForm()
    } else if (onCreateNew) {
      onCreateNew()
    }

    dispatch(BusEvents.RELOAD_GRID)
  }

  const [sendForm] = useSendForm({
    model,
    action,
    dataItem,
    onSuccess: onSuccessForm
  })

  useBus(
    BusEvents.FORM_SUBMIT,
    () => {
      dispatch(BusEvents.INPUT_VALIDATE)
    },
    []
  )

  const onClick = (e, button) => {
    setCreateNew(button.createNew)

    if (button.cancel) onCloseForm()
    else {
      if (button.submit && (!validateForm || (validateForm && validateForm(dataItem)))) sendForm(dataItem)

      if (button.onClick) button.onClick(e, button, sendForm, dataItem)
      else if (awaitFormSuccess) {
        dispatch(BusEvents.FORM_SUBMIT)
      } else {
        dispatch(BusEvents.FORM_SUBMITTED)
      }
    }
  }

  useBus(
    BusEvents.FORM_SUCCESS,
    () => {
      dispatch({ type: BusEvents.FORM_FINISHED, payload: { createNew } })
    },
    [createNew]
  )

  return (
    <div className="side-panel-form">
      {title && <FormHeader title={title} subtitle={subtitle} badge={badge} separator={separator} />}
      {children}
      {buttons && (
        <div className="side-panel-form-buttons">
          {buttons.map(
            (button) =>
              button && (
                <span key={button.id}>
                  <Button
                    type={button.submit ? 'submit' : 'button'}
                    text={button.text}
                    className={button.class}
                    halfWidth={button.halfWidth}
                    icon={button.icon}
                    disabled={button.disabled}
                    onClick={(e) => onClick(e, button)}
                  />
                </span>
              )
          )}
        </div>
      )}
    </div>
  )
}

SidePanelFormWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  badge: PropTypes.element,
  buttons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        cancel: PropTypes.bool,
        class: PropTypes.string,
        onClick: PropTypes.func,
        createNew: PropTypes.bool
      })
    ])
  ).isRequired,
  separator: PropTypes.bool,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateForm: PropTypes.func,
  onClose: PropTypes.func,
  onCreateNew: PropTypes.func,
  children: PropTypes.element.isRequired,
  awaitFormSuccess: PropTypes.bool
}

SidePanelFormWrapper.defaultProps = {
  dataItem: undefined,
  badge: undefined,
  separator: false,
  validateForm: () => {},
  onClose: () => {},
  onCreateNew: () => {},
  awaitFormSuccess: false
}
