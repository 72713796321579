import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { MdOutlineCleaningServices } from 'react-icons/md'
import I18n from '/src/utils/translations'
import usePreferences from '/src/ui/core/grid/preferences'

export default function UserPreferencesMenu({ showUserPopup }) {
  const onClearPreferences = useCallback(() => showUserPopup(false), [showUserPopup])

  const { clearPreferences } = usePreferences({ onClearPreferences })

  const clearUserPreferences = () => {
    showUserPopup(true)
    clearPreferences()
  }

  return (
    <button type="button" onClick={clearUserPreferences} className="user-menu-item">
      <MdOutlineCleaningServices />
      {I18n.t('user_menu.clean_preferences')}
    </button>
  )
}

UserPreferencesMenu.propTypes = {
  showUserPopup: PropTypes.func
}

UserPreferencesMenu.defaultProps = {
  showUserPopup: () => {}
}
