import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import useFetch from '/src/hooks/api/fetch'

export default function useInspectedUtils({ inspectedType, inspectedId, setShowLoading, setInspectedItem }) {
  const { fetch } = useFetch()

  const getInspectedTypeRoute = useCallback(() => {
    switch (inspectedType) {
      case 'Request':
        return 'requests'
      case 'Scope':
        return 'scopes'
      case 'Progress':
        return 'progresses'
      case 'Scaffolding':
        return 'scaffoldings'
      default:
        return 0
    }
  }, [inspectedType])

  const fetchItemsForTrackBlock = useCallback(
    (requestRoute) => {
      setShowLoading(true)
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: { id: inspectedId }
        }
      }

      fetch(requestRoute, params, {
        onSuccess: ({ data }) => {
          setShowLoading(false)
          if (!data.data[0]) setInspectedItem({})
          else setInspectedItem(data.data[0])
        }
      })
    },
    [inspectedId, fetch, setInspectedItem, setShowLoading]
  )

  useEffect(() => {
    const inspectedRoute = getInspectedTypeRoute()
    if (inspectedId) fetchItemsForTrackBlock(inspectedRoute)
  }, [inspectedId, fetchItemsForTrackBlock, getInspectedTypeRoute])
}

useInspectedUtils.propTypes = {
  inspectedType: PropTypes.string,
  inspectedId: PropTypes.number,
  setShowLoading: PropTypes.func,
  setInspectedItem: PropTypes.func
}
