import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { parseFormulaResult } from '/src/models/concerns/formula_result'
import BooleanColumn from '/src/ui/core/fields/boolean'

export default function FormulaColumn({ value, isInteger }) {
  const [globalProject] = useStore('project')

  const parsedValue = parseFormulaResult(value, isInteger, globalProject)

  if (typeof parsedValue === 'boolean') return <BooleanColumn value={parsedValue} />
  return parsedValue
}

FormulaColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  isInteger: PropTypes.bool
}

FormulaColumn.defaultProps = {
  value: null,
  isInteger: false
}
