import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function FilterResults({ values, displayValues, filters, displayParentValues }) {

  const renderKeys = (key) => {
    return filters.find((column) => column.description === key).title
  }

  const renderValues = (key) => {
    const displayValue = displayValues[key] ?? displayParentValues[key]

    if (key === 'inspected_type') {
      return I18n.t(`inspections.inspected_modules.${displayValue.toLowerCase()}`)
    }

    if (key === 'service_ids') {
      return (
        <span>
          {displayValue.map((id) => (
            <span key={id} className="filter-result__service-id">
              {id}
            </span>
          ))}
        </span>
      )
    }

    return displayValue
  }

  return (
    <div className="filter-result">
      <div className="result-title">
        <span>{I18n.t('inspections.module')}</span>
        <img src="/static/svg/tick_blue.svg" alt="Blue tick" />
      </div>
      <div className="result-filters" data-testid="result-filters">
        {Object.keys(displayValues)
          .filter((key) => values[key])
          .map((key) => (
            <p key={key}>
              {`${renderKeys(key)}: `}
              {renderValues(key)}
            </p>
          ))}
      </div>
    </div>
  )
}

FilterResults.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  filters: PropTypes.oneOfType([PropTypes.array]).isRequired,
  displayValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  displayParentValues: PropTypes.oneOfType([PropTypes.object])
}

FilterResults.defaultProps = {
  displayParentValues: {}
}
