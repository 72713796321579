import React, { useState } from 'react'
import { MdThumbsUpDown } from 'react-icons/md'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import I18n from '/src/utils/translations'
import BusEvents from '/src/hooks/bus/bus_events'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function BatchAcceptanceIcon() {
  const [selectedItems, setSelectedItems] = useState([])

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => {
      setSelectedItems(payload)
    },
    [setSelectedItems]
  )

  useBus(BusEvents.RELOAD_GRID, () => setSelectedItems([]), [setSelectedItems])

  return (
    <React.Fragment>
      {selectedItems && selectedItems.length > 0 && (
        <div id="acceptance-icon-id">
          <CustomTooltip position="bottom" parentTitle>
            <button
              id="acceptance-button-id"
              data-testid="acceptance-button"
              type="button"
              title={I18n.t('dialogs.batch_acceptance.title')}
              className="open-icon-popup btn-icon grid-header-icon more-button"
              onClick={() =>
                dispatch({
                  type: BusEvents.OPEN_MODAL,
                  payload: { type: 'acceptance', data: selectedItems }
                })}
            >
              <MdThumbsUpDown />
            </button>
          </CustomTooltip>
        </div>
      )}
    </React.Fragment>
  )
}

BatchAcceptanceIcon.propTypes = {}

BatchAcceptanceIcon.defaultProps = {}
