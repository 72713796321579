import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/grid/cells.css'

export default function CircleLabel({ className, value, icon }) {
  const buildIcon = () => {
    if (icon === null) return <span className={`rounded-label-indicator`} />
    else return <div className="cell-icon">{icon}</div>
  }

  return !value || value === '' ? (
    ''
  ) : (
    <div data-testid="rounded-label" className="align-cells-center">
      <div className={`rounded-label ${className}`}>
        {buildIcon()}
        <span className="rounded-label-text">{value}</span>
      </div>
    </div>
  )
}

CircleLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
}

CircleLabel.defaultProps = {
  className: '',
  value: '',
  icon: null
}
