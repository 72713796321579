import React from 'react'
import PropTypes from 'prop-types'
import { FaCalendarTimes } from 'react-icons/fa'
import { ReactComponent as FeatureSearch } from '/public/static/svg/feature_search_icon'
import I18n from '/src/utils/translations'
import CircleLabel from '/src/ui/core/fields/circle_label'
import '/src/static/css/core/badges/flag_icons_badge.css'

export default function ScaffoldingInspectionLabel({ dataItem }) {
  const inspectionControl = dataItem.inspection_control
  if (!inspectionControl) return null

  return (
    <CircleLabel
      value={I18n.t(`scaffoldings.inspection.${inspectionControl}`)}
      icon={
        inspectionControl === 'missing_initial' ? (
          <div className="flag-icons-badge-full flag-icons-badge-full--yellow">
            <FeatureSearch style={{ marginLeft: '1px' }} />
          </div>
        ) : (
          <div className="flag-icons-badge-full flag-icons-badge-full--red">
            <FaCalendarTimes />
          </div>
        )
      }
    />
  )
}

ScaffoldingInspectionLabel.propTypes = {
  dataItem: PropTypes.shape({
    inspection_control: PropTypes.string
  })
}

ScaffoldingInspectionLabel.defaultProps = {
  dataItem: {}
}
