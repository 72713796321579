import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { formatCurrency, formatNumberWithPrecision } from '/src/utils/project_formatter'
import TitledHashList from '/src/ui/core/lists/titled_hash_list'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import { translateFormulaService } from '/src/ui/domain/formulas_services/formulas_services_utils'
import '/src/static/css/domain/estimates/estimate_service_values_tooltip.css'

export default function EstimateServiceValuesTooltip({ totalPrice, estimateService }) {
  const [hashList, setHashlist] = useState()
  const [project] = useStore('project')
  const {
    application_price: applicationPrice,
    materials_price: materialsPrice,
    equipment_price: equipmentPrice,
    norm_hours: normHours,
    budget_target_hours: budgetHours,
    other_price: otherPrice,
    team_target_hours: teamTargetHours
  } = estimateService

  useEffect(() => {
    if (project) setNewHashList()
  }, [project])

  const formatHour = (value) => `${formatNumberWithPrecision(value, project)}h`

  const translate = (i18nDescription, column) => {
    return translateFormulaService(I18n.t(i18nDescription), column, estimateService.eav_template_id)
  }

  const setNewHashList = () => {
    const newHashList = {
      [translate('estimate.norm_hours', 'norm_hours')]: formatHour(normHours),
      [translate('estimate.team_target_hours', 'team_target_hours')]: formatHour(teamTargetHours),
      [translate('estimate.budget_hours', 'budget_target_hours')]: formatHour(budgetHours),
      [translate('estimate.tooltip.application', 'application_price')]: formatCurrency(applicationPrice, project),
      [translate('estimate.tooltip.material', 'materials_price')]: formatCurrency(materialsPrice, project),
      [translate('estimate.tooltip.equipment', 'equipment_price')]: formatCurrency(equipmentPrice, project),
      [translate('estimate.tooltip.others', 'other_price')]: formatCurrency(otherPrice, project)
    }

    setHashlist(newHashList)
  }

  const tooltipContent = () =>
    hashList && <TitledHashList title={I18n.t('estimate.service_details')} hashValues={hashList} />

  return (
    <div className="estimate-total-price">
      <GeneralTooltip content={tooltipContent}>
        <div title="tooltip">{totalPrice}</div>
        <div className="info-icon" title="tooltip">
          i
        </div>
      </GeneralTooltip>
    </div>
  )
}

EstimateServiceValuesTooltip.propTypes = {
  estimateService: PropTypes.shape({
    application_price: PropTypes.number,
    materials_price: PropTypes.number,
    equipment_price: PropTypes.number,
    norm_hours: PropTypes.number,
    budget_target_hours: PropTypes.number,
    other_price: PropTypes.number,
    team_target_hours: PropTypes.number
  }).isRequired,
  totalPrice: PropTypes.string.isRequired
}
