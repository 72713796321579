/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import QRCode from 'qrcode.react'
import HeaderMain from '/src/ui/domain/estimates/pdf_export/estimates_pdf_header_main'
import HeaderInfo from '/src/ui/domain/estimates/pdf_export/estimates_pdf_header_info'
import AdditionalInfo from '/src/ui/domain/estimates/pdf_export/estimates_pdf_add_info'
import '/src/static/css/domain/estimates/estimates_pdf_header.css'

const EstimatesPDFHeader = ({ requests, estimates, employees }) => {
  const request = requests[0] || {}
  const {
    id: requestId,
    reason,
    comments,
    subproject,
    project_type,
    discipline,
    request_date,
    request_end_date,
    kaefer_energy_priority,
    priority_sequence,
    maintenance_plant,
    area,
    subarea,
    system,
    main_work_centre_mwc,
    functional_location,
    request_status
  } = request

  const estimate = estimates[requestId] || {}
  const { responsible_id } = estimate

  const employee = employees[responsible_id] || {}
  const { name: employeeName, last_name: employeeLastName } = employee

  const selectedProject = JSON.parse(localStorage.getItem('project')) || {}
  const { description: projectDescription } = selectedProject

  const getJobcardStatus = (status) => {
    switch (status) {
      case 'in_performance':
      case 'in_planning':
        return I18n.t('estimate.pdf_header.status_issued_for_construction')
      case 'done':
        return I18n.t('estimate.pdf_header.status_completed')
      default:
        return I18n.t('estimate.pdf_header.status_issued_for_approval')
    }
  }

  return (
    <div className="estimates-pdf-header">
      <div className="estimates-pdf-header__top">
        <div className="estimates-pdf-header__top__logos">
          <img className="estimates-pdf-header__top__logos__logo" src="/static/svg/kaefer-logo.svg" alt="Kaefer Logo" />
          <img className="estimates-pdf-header__top__logos__logo" src="/static/svg/logo-red.svg" alt="Red Logo" />
          <img
            className="estimates-pdf-header__top__logos__logo"
            src="/static/svg/equinor-logo.svg"
            alt="Equinor Logo"
          />
        </div>
        <div className="estimates-pdf-header__top__qr">
          {requestId && <QRCode value={requestId.toString()} size={110} />}
        </div>
      </div>

      <HeaderMain
        projectDescription={projectDescription}
        subproject={subproject}
        projectType={project_type}
        reason={reason}
        comments={comments}
      />

      <HeaderInfo
        reason={reason}
        discipline={discipline}
        requestDate={request_date}
        requestEndDate={request_end_date}
        kaeferEnergyPriority={kaefer_energy_priority}
        prioritySequence={priority_sequence}
        maintenancePlant={maintenance_plant}
        area={area}
        subarea={subarea}
      />

      <AdditionalInfo
        system={system}
        mainWorkCentreMwc={main_work_centre_mwc}
        functionalLocation={functional_location}
        employeeName={employeeName}
        employeeLastName={employeeLastName}
        getJobcardStatus={getJobcardStatus}
        requestStatus={request_status}
      />

      <div className="page-break" />
    </div>
  )
}

EstimatesPDFHeader.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      reason: PropTypes.string,
      comments: PropTypes.string,
      subproject: PropTypes.string,
      project_type: PropTypes.string,
      discipline: PropTypes.string,
      request_date: PropTypes.string,
      request_end_date: PropTypes.string,
      kaefer_energy_priority: PropTypes.string,
      priority_sequence: PropTypes.number,
      maintenance_plant: PropTypes.string,
      area: PropTypes.string,
      subarea: PropTypes.string,
      system: PropTypes.string,
      main_work_centre_mwc: PropTypes.string,
      functional_location: PropTypes.string,
      request_status: PropTypes.shape({
        i18n_id: PropTypes.string
      })
    })
  ),
  estimates: PropTypes.objectOf(
    PropTypes.shape({
      responsible_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  employees: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      last_name: PropTypes.string
    })
  )
}

export default EstimatesPDFHeader
