import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'

export default function DecimalColumn({ value }) {
  const [globalProject] = useStore('project')
  return formatNumberWithPrecision(value, globalProject)
}

DecimalColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DecimalColumn.defaultProps = {
  value: null
}
