import { format } from 'date-fns'
import { IntlService } from '@progress/kendo-react-intl'
import { toFixedReliable } from '/src/utils/float'
import I18n from '/src/utils/translations'

function getNumberSeparator(project) {
  const numberFormat = project.number_format_type
  let separator = ','
  if (numberFormat === 'DOT') {
    separator = '.'
  }
  return separator
}

function getNumberDelimiter(project) {
  const numberFormat = project.number_format_type
  let delimiter = '.'
  if (numberFormat === 'DOT') {
    delimiter = ','
  }
  return delimiter
}

export function formatNumberWithDelimiter(value, project) {
  try {
    if (Number.isNaN(parseInt(value, 10))) return ''

    // default 1.000.000 (number_format_type = 0 ='COMMA')
    const delimiter = getNumberDelimiter(project)
    return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${delimiter}`)
  } catch (e) {
    return value
  }
}

export function formatNumberWithDelimiterAndSeparator(value, project) {
  if (Number.isNaN(parseFloat(value))) return ''

  // default 1.000,00 (number_format_type = 0 ='COMMA')
  const separator = getNumberSeparator(project)
  const delimiter = getNumberDelimiter(project)
  const parts = value.toString().split('.')
  const regex = /(\d)(?=(\d\d\d)+(?!\d))/g
  const numberWithDelimiter = parts[0].toString().replace(regex, `$1${delimiter}`)
  if (parts.length === 1) return numberWithDelimiter
  return numberWithDelimiter + separator + parts[1]
}

// Display percentage fields with N decimal places, separator and delimiter set in the project,
// and the symbol '%' right after the numeric value
export function formatPercentageWithPrecision(value, precision, project) {
  try {
    if (Number.isNaN(parseFloat(value))) return ''

    const valuePercentage = toFixedReliable(value * 100, precision)
    if (precision === 0) return `${valuePercentage} %`
    return `${formatNumberWithDelimiterAndSeparator(valuePercentage, project)} %`
  } catch (e) {
    return value
  }
}

export function formatNumberWithPrecision(value, project) {
  try {
    if (Number.isNaN(parseFloat(value))) return ''

    const numberPrecision = project.number_of_digits
    const numberWithPrecision = toFixedReliable(parseFloat(value), numberPrecision)
    return formatNumberWithDelimiterAndSeparator(numberWithPrecision, project)
  } catch (e) {
    return value
  }
}

export function formattedNumberToFloat(value) {
  return parseFloat(value.replace(',', '.'))
}

export function formatNumberToFloatByProject(value, project) {
  let parseableFloat
  const isDotSeparator = project.number_format_type === 'DOT'

  if (isDotSeparator) parseableFloat = value.replaceAll(',', '')
  else parseableFloat = value.replaceAll('.', '').replace(',', '.')

  return parseFloat(parseableFloat)
}

export function formatCurrency(value, project) {
  if (!value && Number.isNaN(parseFloat(value))) return ''

  return `${formatNumberWithPrecision(value, project)} ${project.currency_code}`
}

export function getFormatLocaleDate(project) {
  const localeType = project.locale_type

  if (localeType === 'en-US') return 'MM/dd/yyyy'
  if ('ja-JP zh-CN zh-TW zh-HK'.includes(localeType)) return 'yyyy年MM月dd日'
  if (localeType === 'sv-SE') return 'yyyy-MM-dd'
  if ('pt-BR es-ES fr-FR sk-SK tr-TR it-IT pt-PT en-UK'.includes(localeType)) return 'dd/MM/yyyy'
  if ('da-DK nl-NL'.includes(localeType)) return 'dd-MM-yyyy'

  return 'dd.MM.yyyy'
}

export function getFormatLocaleTimeToTime() {
  return 'HH:mm:ss'
}

function getFormatLocaleTimeToDate() {
  return 'HH:mm'
}

export function getFormatLocaleDateTime(project) {
  return `${getFormatLocaleDate(project)} ${getFormatLocaleTimeToDate(project)}`
}

export const normalizeStringToLocalDate = (stringDate) => {
  const MINUTE_TO_MILISEC = 60000
  const date = new Date(stringDate)

  return new Date(date.getTime() + date.getTimezoneOffset() * MINUTE_TO_MILISEC)
}

export const dateTimeStringToLocalTime = (stringDate) => {
  if (stringDate instanceof Date) return stringDate
  if (stringDate && stringDate.indexOf('T') !== -1) {
    if (stringDate.indexOf('Z') === -1) return new Date(`${stringDate}.000Z`)
    return new Date(stringDate)
  }
  return normalizeStringToLocalDate(stringDate)
}

export function formattedLocaleDate(opts) {
  if (!opts.value) {
    return ''
  }
  const formattedValue = opts.parser.parseDate(dateTimeStringToLocalTime(opts.value))

  let localDate = getFormatLocaleDate(opts.project)
  if (typeof opts.time !== 'undefined') {
    localDate = getFormatLocaleDateTime(opts.project)
  }
  return opts.parser.formatDate(formattedValue, localDate)
}

export const normalizeDateToString = (date) => {
  if (date instanceof Date && String(date) !== 'Invalid Date') {
    return format(date, 'yyyy-MM-dd')
  }
  return ''
}

export const normalizeStringDateToJsRawDate = (stringDate) => {
  return new Date(stringDate.replace(/-/g, '/'))
}

export const getLocalDate = () => {
  const utcNow = new Date()
  const localNow = new Date(utcNow.getFullYear(), utcNow.getMonth(), utcNow.getDate())
  return normalizeDateToString(localNow)
}

export const dateDiffDays = (dateStringStart, dateStringEnd) => {
  const dateStart = new Date(dateStringStart)
  const dateEnd = new Date(dateStringEnd)

  const dateEndMilliseconds = Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate())
  const dateStartMilliseconds = Date.UTC(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate())

  return Math.floor((dateEndMilliseconds - dateStartMilliseconds) / (1000 * 60 * 60 * 24))
}

export const getDaysCounter = (date) => {
  if (!date) return null
  if (new Date(date) > new Date(getLocalDate())) {
    const diffDays = dateDiffDays(new Date(getLocalDate()), date)
    if (diffDays > 999) return I18n.t('timeline.days_until', { daysUntil: '999+' })
    if (diffDays < 1) return I18n.t('timeline.today')
    if (diffDays === 1) return I18n.t('timeline.day_until_singular', { daysUntil: diffDays })
    return I18n.t('timeline.days_until', { daysUntil: diffDays })
  }
  const diffDays = dateDiffDays(new Date(date), new Date(getLocalDate()))
  if (diffDays > 999) return I18n.t('timeline.days_ago', { daysAgo: '999+' })
  if (diffDays < 1) return I18n.t('timeline.today')
  if (diffDays === 1) return I18n.t('timeline.day_ago_singular', { daysAgo: diffDays })
  return I18n.t('timeline.days_ago', { daysAgo: diffDays })
}

export const formatHours = (time) => {
  const hours = Math.floor(time)

  let minutes = 60 * (time - Math.floor(time))
  minutes = `0${minutes.toFixed(0)}`.slice(-2)

  return `${hours}:${minutes}h`
}

export const formatDate = (date, project) => {
  const opts = { project }
  opts.parser = new IntlService()
  opts.value = date

  return formattedLocaleDate(opts)
}
