/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdAddCircle } from 'react-icons/md'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import MovingScopes from '/src/ui/domain/scopes/moving_scopes'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/grid.css'

export default function ClusterDetail({ request, label, tabbing, selected }) {
  const [scopes, setScopes] = useState([])
  const getScopes = useFetchAPI('scope_summaries')
  const [interactiveMode, setInteractiveMode] = useStore('interactive_mode')
  const [interactiveScopeList, setInteractiveScopeList] = useStore('interactive_scope_list')

  const startInteractiveMode = () => setInteractiveMode({ ...interactiveMode, status: true, filter: label })

  const handleClick = (scope) => {
    const newScopeLabel = label ? null : interactiveMode.filter
    if (interactiveMode.status) {
      setInteractiveScopeList([...interactiveScopeList, { ...scope, label: newScopeLabel }])
    }
  }

  const isNotInteractiveScopeList = (scope) => {
    if (interactiveScopeList.filter((listItem) => listItem.id === scope.id).length > 0) {
      return false
    }
    return true
  }

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: {
        filtering: {
          filter: {
            logic: 'and',
            filters: [
              { type: 'where', column: 'request_id', value: request },
              { type: 'where', column: 'eav_template_id', value: tabbing },
              { type: 'where', column: 'label', value: label }
            ]
          }
        }
      }
    }
    getScopes.fetchAPI(params)
  }, [])

  useEffect(() => {
    if (!getScopes.loading && !getScopes.errors && getScopes.responseData.data.length > 0) {
      setScopes(getScopes.responseData.data)
    }
    if (!getScopes.loading && getScopes.errors) notifyError('Error on fetching sections')
  }, [getScopes.loading, getScopes.errors, getScopes.responseData])

  const footer = () =>
    !interactiveMode.status &&
    label && (
      <div className="detail-footer">
        <button data-testid="enable" className="button-cleaned" type="button" onClick={() => startInteractiveMode()}>
          <MdAddCircle />
          {I18n.t('scopes.add')}
        </button>
      </div>
    )

  const detailScope = (scope, index) => (
    <td width={460} className="detail-scope">
      <span className="detail-scope-index">{index + 1}</span>
      <span className="detail-scope-id">{scope.id}</span>
      {label && <span className="label">{label}</span>}
    </td>
  )

  const printColumns = (scope) => {
    let columns = ['team_target_hours', 'quantity']
    let width = 'width-100'
    if (!interactiveMode.status) {
      width = 'thin'
      columns = ['team_target_hours', 'remaining_team_target_hours', 'quantity', 'remaining_quantity']
    }
    return (
      <React.Fragment>
        {columns.map((column) => (
          <td className={`detail-column ${width}`} key={`cluster-detail-${column}-column`}>
            <DecimalColumn value={scope[column]} />
          </td>
        ))}
      </React.Fragment>
    )
  }

  if (getScopes.loading) {
    return (
      <div className={`cluster-detail-row ${interactiveMode.status ? 'interactive' : ''}`}>
        <div className="loading">
          <ThreeDotsLoader />
        </div>
      </div>
    )
  }

  if (getScopes.responseData.data.length > 0 || interactiveScopeList.length > 0) {
    return (
      <div
        className={`cluster-detail-row
                    ${selected ? 'selected' : ''}
                    ${interactiveMode.status ? 'interactive' : ''}`}
      >
        <table>
          <tbody>
            {scopes.filter(isNotInteractiveScopeList).map((scope, index) => (
              <tr key={scope.id} onClick={() => handleClick(scope)}>
                {detailScope(scope, index)}
                {printColumns(scope)}
              </tr>
            ))}
            <MovingScopes label={label} />
          </tbody>
        </table>
        {footer()}
      </div>
    )
  }

  return <p>{I18n.t('search.no_result_found')}</p>
}

ClusterDetail.propTypes = {
  request: PropTypes.string.isRequired,
  label: PropTypes.string,
  tabbing: PropTypes.number,
  selected: PropTypes.bool
}

ClusterDetail.defaultProps = {
  selected: false
}
