/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import EstimateServicePackageableModel from '/src/models/estimate_service_packageable'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchSections from '/src/hooks/api/fetch_sections'
import I18n from '/src/utils/translations'

export default function EstimateServicePackageableForm({ opts, estimateId }) {
  const { templateId, scopeId, disciplineId } = opts
  const query = { query: { where: { discipline_id: disciplineId } } }
  const sections = useFetchSections(templateId)
  const estimateServiceModel = new EstimateServicePackageableModel(query)

  const pollingCallback = ({ responseData, onFinishedJob, onJobError }) => {
    if (!responseData) return dispatch(BusEvents.SHOW_FAILURE_DIALOG)
    const onFinishedWithHideDialog = () => {
      onFinishedJob()
      dispatch(BusEvents.HIDE_DIALOG)
    }
    dispatch({
      type: BusEvents.SHOW_SERVICE_IN_PROGRESS_DIALOG,
      payload: {
        jobId: responseData.background_job_id,
        onFinishedJob: onFinishedWithHideDialog,
        onJobError
      }
    })
  }

  const type = 'new'

  return (
    <FormWrapper model={estimateServiceModel} type={type} backText={I18n.t('estimate_services.back')}>
      <React.Fragment>
        <Form
          model={estimateServiceModel}
          sections={sections}
          includeOnForm={{
            scope_id: scopeId,
            eav_template_id: templateId,
            estimate_id: estimateId,
            discipline_id: disciplineId
          }}
          templateId={templateId}
          type={type}
          pollingCallback={pollingCallback}
        />
        <FormButtons model={estimateServiceModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

EstimateServicePackageableForm.propTypes = {
  estimateId: PropTypes.number,
  opts: PropTypes.shape({
    scopeId: PropTypes.number,
    templateId: PropTypes.number,
    disciplineId: PropTypes.number
  })
}

EstimateServicePackageableForm.defaultProps = {
  estimateId: undefined,
  opts: {}
}
