import useFetch from '/src/hooks/api/fetch'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'

export function extractPictureColumns(sections) {
  const unsectionedEavColumns = sections.flatMap((section) => {
    const columns = (section?.eav_columns ?? [])
    const orderedColumns = sortArrayOfObjectsByNumber(columns, 'position')
    return orderedColumns
  })

  const pictureColumns = unsectionedEavColumns.filter((column) => column.column_type?.description === 'picture')
  return pictureColumns
}

export function usePDFSectionsAndTemplate() {
  const { fetch } = useFetch()

  const fetchSectionsAndTemplate = async (templateId, modelName) => {
    const sectionsParams = {
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    const templateParams = { query: { where: { id: templateId } } }

    if (modelName === 'Work Orders') {
      const { data: sectionsData } = await fetch('eav_sections', sectionsParams, { useParse: true })
      return [sectionsData]
    }

    const sectionsFetch = fetch('eav_sections', sectionsParams, { useParse: true })
    const templateFetch = fetch('eav_templates', templateParams, { useParse: true })

    const [{ data: sectionsData }, { data: templateData }] = await Promise.all([sectionsFetch, templateFetch])

    return [sectionsData, templateData[0]?.title]
  }

  return { fetchSectionsAndTemplate }
}

export function categorizePicturesByColumns(item, columns) {
  return columns.reduce((picturesCategorized, { description }) => {
    const itemPicturesUuids = item[description]

    if (!itemPicturesUuids?.length) {
      return picturesCategorized
    }

    const columnPicturesArray = item.pictures.filter((picture) => itemPicturesUuids.includes(picture.uuid))

    if (!columnPicturesArray.length) {
      return picturesCategorized
    }

    return {
      ...picturesCategorized,
      [description]: columnPicturesArray
    }
  }, {})
}

/**
 * This gets all the comments from commentable columns, keeping them in order
 * of sections, and then ordering within each section, by position
 * @param {Object} item - The item that has the flexible_comments property
 * @param {Array} sections - The eav_sections, as returned from the fetch
 * @returns An object with commentable column descriptions as keys,
 * and the values are other objects with the comment value and index.
 * Ex.: { commentable_column_description: { value: 'comment', index: 1 }
 */
export function getOrderedCommnents(item, sections) {
  const comments = item.flexible_comments
  if (!comments) return null

  const unsectionedEavColumns = sections.flatMap((section) => {
    const columns = (section?.eav_columns ?? [])
    const orderedColumns = sortArrayOfObjectsByNumber(columns, 'position')
    return orderedColumns
  })
  
  const orderedComments = {}
  let index = 1

  unsectionedEavColumns.forEach((column) => {
    const key = column.description
    if (comments[key]) {
      orderedComments[key] = { value: comments[key], index }
      index += 1
    }
  })

  return orderedComments
}

export function getInspectionInfo(item) {
  const {
    inspect_date: inspectDate,
    number: inspectionNumber,
    inspect_status: inspectStatus,
    inspected_type: inspectedType
  } = item

  const isScaffolding = inspectedType === 'Scaffolding'
  const isRequest = inspectedType === 'Request'

  const dpmsId = !isScaffolding && item.request_id
  const scaffoldingTag = isScaffolding && item.tag_number
  const itemNumber = !isScaffolding && !isRequest && item.inspected?.number
  const responsibleName = item.responsible?.full_name

  return {
    dpmsId,
    itemNumber,
    inspectDate,
    inspectStatus,
    inspectedType,
    scaffoldingTag,
    responsibleName,
    inspectionNumber
  }
}
