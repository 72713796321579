import { omit, pick } from 'lodash'
import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/boolean_refinements'
import { filterModules } from '/src/utils/inspection_template_filtering'

export const getSubtitle = (isLastStep, title) => {
  if (isLastStep) return I18n.t('filter_steps_modal.result_subtitle')
  return `${I18n.t('filter_steps_modal.subtitle', { title })}`
}

export const getCount = (index, inspectionDetail, responseData) => {
  if (index === 0 && inspectionDetail.inspection_modules?.length > 0)
    return filterModules(responseData, inspectionDetail).length

  return responseData.length
}

// eslint-disable-next-line max-params
export const filterPreviousChoices = (index, newValues, newDisplayValues, currentSelectedValue) => {
  const {
    request_id: requestId,
    inspected_id: inspectedId,
    inspected_template: inspectedTemplate,
    service_ids: serviceIds
  } = newValues

  if (currentSelectedValue === 'Scaffolding' && isPresent(requestId)) {
    const filteredValues = pick(newValues, ['inspected_type'])
    const filteredDisplayValues = pick(newDisplayValues, ['inspected_type'])
    return [filteredValues, filteredDisplayValues]
  }

  if (currentSelectedValue === 'Request') {
    const filteredValues = pick(newValues, ['inspected_type', 'request_id'])
    const filteredDisplayValues = pick(newDisplayValues, ['inspected_type', 'request_id'])
    return [filteredValues, filteredDisplayValues]
  }

  const isDifferentTemplate = index === 2 && currentSelectedValue !== inspectedTemplate
  const isDifferentInspected = index === 3 && currentSelectedValue !== inspectedId
  const isNoLongerService = ['Progress', 'Scope'].includes(currentSelectedValue) && serviceIds

  if (isDifferentTemplate || isDifferentInspected || isNoLongerService) {
    const filteredValues = omit(newValues, ['service_ids'])
    const filteredDisplayValues = omit(newDisplayValues, ['service_ids'])
    return [filteredValues, filteredDisplayValues]
  }

  return [newValues, newDisplayValues]
}

export const buildRouteAndQuery = (filter, values, inspectionDetail) => {  
  const searchRoute = filter.searchRoute || filter.searchRouteBuilder?.(values)
  const searchExtraQuery = filter.searchExtraQuery || filter.queryBuilder?.(values)
  
  if (filter.description === 'request_id' && inspectionDetail.inspection_disciplines?.length > 0) {  
    searchExtraQuery.where.discipline_id = inspectionDetail.inspection_disciplines.join(',')
  }

  return [searchRoute, searchExtraQuery]
}
