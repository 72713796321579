import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'
import '/src/static/css/layout.css'

export default function NotificationBadge({ notificationsCount }) {
  return notificationsCount && notificationsCount > 0 ? (
    <CustomTooltip position="top" parentTitle>
      <span
        title={I18n.t('inspections.pending_inspections')}
        className="notification-badge"
        data-testid="notification-badge"
      >
        {notificationsCount > 99 ? '99+' : notificationsCount}
      </span>
    </CustomTooltip>
  ) : null
}

NotificationBadge.propTypes = {
  notificationsCount: PropTypes.number
}

NotificationBadge.defaultProps = {
  notificationsCount: 0
}
