import { useState, useEffect } from 'react'
import { filterBy } from '@progress/kendo-data-query'
import useInputError from '/src/ui/core/inputs/input_error'
import useInputChange from '/src/ui/core/inputs/input_change'
import { isPresent } from '/src/utils/boolean_refinements'

/**
 * It will make a dumb radio button or select list work as a real form input
 * @param inputProps
 * @return {{wrapperClass: *, onBlur: onInputBlur, data: *, onChange,
 * inputClass: (function(): string), onFilterChange: (function(*): *),
 * error, value, onFocus: onInputFocus}}
 */
export default function useInputDrop(inputProps) {
  const {
    id,
    className,
    value,
    title,
    options,
    required,
    onChange,
    dataItem,
    onBlur,
    onFocus,
    readOnly,
    isDynamicDrop,
    multiple,
    type,
    valueFormatter
  } = inputProps

  const [wrapperClass, setWrapperClass] = useState('form-input-combobox-wrapper')
  const [data, setData] = useState(options)
  const [inputValue, onInputChange] = useInputChange({ id, value, onChange, valueFormatter })
  const isOptionsString = () => typeof options[0] === 'string'
  const textValue = () =>
    isOptionsString() ? inputValue : inputValue && inputValue[inputProps.textField || 'description']
  const [textToSearch, setTextToSearch] = useState(textValue())
  const error = useInputError({ inputValue, title, required, type, dataItem })

  const isInputValueArray = Array.isArray(inputValue)

  const dropValue = isOptionsString() || isInputValueArray ? inputValue : options.find((item) => item.id === inputValue)

  const onFilterChange = (e) => {
    let result = filterBy(options, e.filter)
    if (result.length === 0) result = filterBy(options, { ...e.filter, field: 'id' })

    const value = e.filter.value.trim()
    if (isDynamicDrop && isPresent(value) && !result.includes(value)) result.unshift(value)

    if (isOptionsString()) {
      setTextToSearch(e.filter.value)
    } else {
      const textShow = e.filter.value && e.filter.value[inputProps.textField || 'description']
      setTextToSearch(textShow || undefined)
    }
    setData(result)
  }

  const onComboChange = (e) => {
    const event = isOptionsString()
      ? e
      : {
          target: {
            value: e.target.value ? e.target.value.id : null,
            all_values: e.target.value
          }
        }
    onInputChange(event)
    setTextToSearch(textValue())
  }

  const onInputFocus = (e) => {
    if (onFocus) onFocus(e)
    setWrapperClass('form-input-combobox-wrapper active')
  }

  const onInputBlur = (e) => {
    if (onBlur) onBlur(e)
    setWrapperClass('form-input-combobox-wrapper')
  }

  const inputClass = () => {
    const classes = ['form-input-combobox']
    if (multiple) classes.push('multiple-drop')
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  useEffect(() => {
    setData(options)
    if (isOptionsString() && !multiple) setTextToSearch(inputValue)
  }, [inputValue])

  return {
    textToSearch,
    data,
    error,
    value: dropValue,
    wrapperClass,
    inputClass,
    onChange: onInputChange,
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    readOnly,
    onFilterChange,
    onComboChange,
    isOptionsString
  }
}
