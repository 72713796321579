import React, { useEffect, useMemo } from 'react'
import I18n from '/src/utils/translations'
import PropTypes from 'prop-types'
import Section from '/src/ui/core/layouts/section'
import FieldsGroup from '/src/ui/core/layouts/fields_group'
import { getSectionTitle, isSectionVisible } from '/src/models/concerns/eav_section'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/layouts/sectioned_panel.css'

export default function FixedSections({ sections, fixedFields, sectionable, hideable, renderColumn, dataItem }) {
  // Group fixed fields by their section key
  const fieldGroups = useMemo(() => {
    const groupedColumns = {}

    fixedFields.forEach((field) => {
      const sectionKey = field.section || 'general' // Use 'general' if there's no sectionKey
      if (!groupedColumns[sectionKey]) {
        groupedColumns[sectionKey] = []
      }
      groupedColumns[sectionKey].push(field)
    })

    return groupedColumns
  }, [fixedFields])

  useEffect(() => {
    if (!sections) return

    sections.forEach((section) => {
      dispatch({
        type: BusEvents.FORM_SECTION_DISPLAY_UPDATE,
        payload: {
          ...section,
          shouldDisplay: isSectionVisible(fieldGroups[section.id] || [])
        }
      })
    })
  }, [sections, fieldGroups])

  return (
    <React.Fragment>
      {Object.keys(fieldGroups).map((sectionKey, index) =>
        isSectionVisible(fieldGroups[sectionKey]) ? (
          <Section
            key={sectionKey}
            id={sectionKey}
            index={index + 1}
            title={getSectionTitle(sections, sectionKey) || I18n.t('form.general_information')}
            sectionable={sectionable}
            body={<FieldsGroup columns={fieldGroups[sectionKey]} renderColumn={renderColumn} dataItem={dataItem} />}
            enumerable
            hideable={hideable}
          />
        ) : undefined
      )}
    </React.Fragment>
  )
}

FixedSections.propTypes = {
  sections: PropTypes.oneOfType([PropTypes.array]),
  fixedFields: PropTypes.array.isRequired,
  sectionable: PropTypes.bool,
  hideable: PropTypes.bool,
  renderColumn: PropTypes.func,
  dataItem: PropTypes.object
}

FixedSections.defaultProps = {
  sections: [],
  sectionable: true,
  hideable: true,
  renderColumn: () => {},
  dataItem: undefined
}
