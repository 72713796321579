import React from 'react'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'

export default function IdCell({ id, param }) {
  const [, setRequestIdParam] = useQueryParam(param, NumberParam)

  return (
    <button className="dpmsid-cell-factory" onClick={() => setRequestIdParam(id)} type="button">
      {id}
    </button>
  )
}

IdCell.propTypes = {
  id: PropTypes.number,
  param: PropTypes.string
}

IdCell.defaultProps = {
  param: 'id'
}
