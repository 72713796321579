/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useRef, useState } from 'react'
import { store, useStore } from 'react-context-hook'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import storePreferencesRouteParams, {
  SAVE_PREFERENCES_TIMER,
  STORE_PREFERENCES_STATUS
} from '/src/models/concerns/user'

export default function usePreferences({ onClearPreferences } = {}) {
  const { fetch } = useFetch(true)

  const [savingStatus, setSavingStatus] = useState(STORE_PREFERENCES_STATUS.IDLE)

  const [user] = useStore('user')
  const [gridSettings, setGridSettings] = useStore('grid_settings')

  const saveTimer = useRef()

  const clearLocalGridSettings = useCallback(() => {
    setGridSettings({})
  }, [setGridSettings])

  const saveLocalPreferences = useCallback((preferences) => {
    store.set('grid_settings', preferences || {})
  }, [])

  const savePreferences = useCallback(
    (newGridSettings) => {
      saveLocalPreferences(newGridSettings || {})

      if (saveTimer.current) clearTimeout(saveTimer.current)

      saveTimer.current = setTimeout(() => {
        if (!user || !newGridSettings) return

        const routeParams = storePreferencesRouteParams(user, JSON.stringify(newGridSettings))
        fetch('users', routeParams, {
          onSuccess: () => setSavingStatus(STORE_PREFERENCES_STATUS.SAVED),
          onError: () => setSavingStatus(STORE_PREFERENCES_STATUS.FAILED)
        })

        setSavingStatus(STORE_PREFERENCES_STATUS.SAVING)
      }, SAVE_PREFERENCES_TIMER)
    },
    [setGridSettings, user, fetch]
  )

  const clearTemplatePreferences = useCallback(
    (templateId, onBeforeReload) => {
      if (!templateId || !user) return

      const newGridSettings = gridSettings
      delete newGridSettings[templateId]
      saveLocalPreferences(newGridSettings || {})

      fetch('users', storePreferencesRouteParams(user, JSON.stringify(newGridSettings)), {
        onSuccess: () => {
          if (onBeforeReload) onBeforeReload()
          dispatch({ type: BusEvents.RELOAD_PAGE, payload: true })
          dispatch({ type: BusEvents.RELOAD_PAGE, payload: false })
        },
        onError: () => {
          notifyError(I18n.t('user_menu.notification.error'))
        }
      })
    },
    [user, fetch, gridSettings, saveLocalPreferences]
  )

  const clearPreferences = useCallback(() => {
    clearLocalGridSettings()

    if (!user) return

    fetch('users', storePreferencesRouteParams(user, null), {
      onSuccess: () => {
        notifySuccess(I18n.t('user_menu.notification.clean_preferences'))
        onClearPreferences()
        dispatch({ type: BusEvents.RELOAD_PAGE, payload: false })
      },
      onError: () => {
        notifyError(I18n.t('user_menu.notification.error'))
        onClearPreferences()
        dispatch({ type: BusEvents.RELOAD_PAGE, payload: false })
      }
    })

    dispatch({ type: BusEvents.RELOAD_PAGE, payload: true })
  }, [user, fetch, clearLocalGridSettings, onClearPreferences])

  useEffect(() => {
    return () => {
      if (saveTimer.current) clearTimeout(saveTimer.current)
    }
  }, [])

  return {
    preferences: gridSettings,
    savingPreferencesStatus: savingStatus,
    savePreferences,
    saveLocalPreferences,
    clearTemplatePreferences,
    clearLocalGridSettings,
    clearPreferences
  }
}
