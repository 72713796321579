import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import GridHeaderIcons from '/src/ui/core/grid/grid_header_icons'
import GridHeaderCounter from '/src/ui/core/grid/grid_header_counter'
import GridHeaderPaging from '/src/ui/core/grid/grid_header_paging'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import { Tooltip } from '@progress/kendo-react-tooltip'

// eslint-disable-next-line max-lines-per-function
export default function GridHeader({
  page,
  take,
  total,
  onPageChange,
  gridTitle,
  icons,
  simpleTitle,
  overlayHeaderColumns,
  selectedItem
}) {
  const [grid, setGrid] = useStore('grid')
  const [currentPage, setPage] = useState(page)

  useEffect(() => {
    if (currentPage > total) jumpToPage({ target: { value: 1 } }, 1)
  }, [total])

  const jumpToPage = (event, newPage) => {
    setPage(newPage)
    onPageChange(event, newPage)
  }

  const pageClick = (event, dir) => {
    const nextPage = currentPage + dir
    if (nextPage < 1 || nextPage > total) return
    jumpToPage(event, nextPage)
  }

  const title = () => {
    if (!grid || !grid.filtered || !grid.filtered.title) return gridTitle

    return (
      <React.Fragment>
        <span className="grid-title">
          <Tooltip anchorElement="target" align="left" parentTitle>
            <span title={grid.filtered.title.reason}>{grid.filtered.title.reason}</span>
          </Tooltip>
        </span>
        <LabelFilter
          onClick={() => setGrid()}
          link={`/${grid.filtered.title.link}`}
          icon={grid.filtered.title.icon}
          text={grid.filtered.title.id}
          classes={grid.filtered.title.classes}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="entity-grid-header">
        <div className="entity-grid-header-default">
          <div className="name-labels">
            {simpleTitle ? (
              <div className="grid-simple-name">{gridTitle}</div>
            ) : (
              <div className="entity-grid-name">{title()}</div>
            )}
          </div>
          <div className="entity-grid-header-icons">
            <GridHeaderIcons icons={icons} selectedItem={selectedItem} />
            <div className="entity-grid-pagination" data-testid="grid-options">
              <GridHeaderCounter page={currentPage} jumpToPage={jumpToPage} total={total} />
              <GridHeaderPaging onPageChange={pageClick} take={take} />
            </div>
          </div>
        </div>
        {overlayHeaderColumns}
      </div>
    </React.Fragment>
  )
}

GridHeader.propTypes = {
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  gridTitle: PropTypes.string.isRequired,
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
  simpleTitle: PropTypes.bool,
  overlayHeaderColumns: PropTypes.element,
  selectedItem: PropTypes.oneOfType([PropTypes.object])
}

GridHeader.defaultProps = {
  overlayHeaderColumns: undefined,
  simpleTitle: false,
  selectedItem: undefined
}
