import React, { useState, useCallback } from 'react'
import RequestFilterLabel from '/src/ui/core/grid/request_filter_label'
import RequestStatusFilterIcon from '/src/ui/domain/requests/request_status_filter_icon'
import RequestFlagFilterIcon from '/src/ui/domain/requests/request_flag_filter_icon'
import { getRequestGridFilters, setRequestGridFilters } from '/src/utils/store/request_grid_filters'

export default function useGeneralFilterServices() {
  const { storedFilter, storedGroup, storedLabel } = getRequestGridFilters()

  const [filter, setFilter] = useState(storedFilter || [])
  const [filterGroup, setFilterGroup] = useState(storedGroup || null)
  const [filterLabelNames, setFilterLabelNames] = useState(storedLabel || [])

  const updateFilters = (newFilter, newGroup, newLabel) => {
    setFilter(newFilter)
    setFilterGroup(newGroup)
    setFilterLabelNames(newLabel)

    const objectToStore = {
      storedFilter: newFilter,
      storedGroup: newGroup,
      storedLabel: newLabel
    }
    setRequestGridFilters(objectToStore)
  }

  const clearAllGeneralFilters = useCallback(() => updateFilters([], null, []), [updateFilters])

  const filterLabel = useCallback(
    () =>
      filter &&
      filterGroup &&
      filter.length > 0 && (
        <RequestFilterLabel
          key="request-grid-filter-label"
          filterLabels={filterLabelNames}
          filterGroup={filterGroup}
          clearFilters={() => updateFilters([], null, [])}
        />
      ),
    [filter, filterGroup, filterLabelNames]
  )

  const filterIcons = [
    <RequestFlagFilterIcon
      key="request-flag-filter-icon"
      filter={filter}
      filterGroup={filterGroup}
      updateFilters={updateFilters}
    />,
    <RequestStatusFilterIcon
      key="request-status-filter-icon"
      filter={filter}
      filterGroup={filterGroup}
      updateFilters={updateFilters}
      filterLabelNames={filterLabelNames}
    />
  ]

  return { filter, filterLabel, filterIcons, clearAllGeneralFilters }
}
