import React, { useState, useEffect } from 'react'
import { useStore, useSetStoreValue } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdBackup } from 'react-icons/md'
import codes from 'http-status-codes'
import { isEmpty, uniqBy } from 'lodash'
import { guid } from '@progress/kendo-react-common'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import useCookie from '/src/hooks/cookie'
import I18n from '/src/utils/translations'
import IconButton from '/src/ui/core/buttons/icon_button'
import KendoFileStatus from '/src/utils/kendo_file_status'
import { byString } from '/src/utils/object'
import { indexify } from '/src/utils/array'
import {
  MAX_ATTACHMENT_SIZE,
  MAX_ALLOWED_NUM_UPLOADS,
  PICTURE_CATEGORY_ID,
  formTypes
} from '/src/models/concerns/attachment'
import DeleteAttachment from '/src/ui/core/inputs/delete_attachment'
import UploadAttachmnetModal from '/src/ui/core/inputs/attachments/upload_attachment_modal'
import useInputError from '/src/ui/core/inputs/input_error'
import ReorderableList from '/src/ui/core/layouts/reorderable_list'
import '/src/static/css/core/inputs/input_attachment.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function InputAttachment({ inputProps }) {
  const { id, allowedExtensions, maxFileSize, readOnly, title, required, onChange, dataItem, columnId } = inputProps
  const [selectedCategory, setSelectedCategory] = useState(undefined)
  const [modalOpened, setModalOpened] = useState(false)
  const [globalProject] = useStore('project')
  const [fileCategory] = useStore('file_categories')
  const [globalSubproject] = useStore('subproject')
  const [getToken] = useCookie('remember_token')
  const [innerFiles, setInnerFiles] = useState([])
  const setNotification = useSetStoreValue('notification')
  const isCreationForm = formTypes(dataItem) === 'new'

  const creationSaveAction = 'attachments.json'
  const updateSaveAction = 'upload_attachment'
  const creationDeleteAction = 'attachments/remove'
  const updateDeleteAction = 'delete_attachment'
  const fileColumnType = 'attachment'

  const filesUuids = (item) => {
    if (!item || !item[id]) return []
    return item[id]
  }

  const initFiles = () => {
    const columnUuids = filesUuids(dataItem)
    const itemFiles = dataItem?.attachments

    if (columnUuids.length === 0 || !itemFiles || itemFiles.length === 0) return []

    const columnFiles = indexify(
      itemFiles.filter((at) => dataItem[id].includes(at.uuid)),
      'uuid'
    )

    const kendoFiles = []

    if (columnFiles && !isEmpty(columnFiles)) {
      columnUuids.forEach((uuid) => {
        const file = columnFiles[uuid]

        const fileName = file.attachment_file_name
        const fileSize = file.attachment_file_size
        const fileId = file.id
        const fileDescription = file.description
        const attachableType = file.attachable_type

        kendoFiles.push({
          getRawFile: () => file,
          id: fileId,
          file_description: fileDescription,
          attachable_type: attachableType,
          name: fileName,
          size: fileSize,
          file_category_id: file.file_category_id || PICTURE_CATEGORY_ID,
          uid: file.uuid,
          uuid: file.uuid,
          status: KendoFileStatus.uploaded,
          progress: 100
        })
      })
    }

    return kendoFiles
  }

  const [files, setFiles] = useState(initFiles())
  const error = useInputError({ inputValue: files, title, required, type: 'array' })

  useEffect(() => {
    onChange(
      id,
      files.map(({ uuid }) => uuid)
    )
  }, [id, files, onChange])

  let params = `?eav_column_id=${columnId}`
  params += `&project_id=${globalProject.id}&subproject_id=${globalSubproject.id}`

  const createUrl = (path) => {
    return `${SERVER_URL}/api/v1/${path}${params}`
  }

  const updateUrl = (action) => {
    return `${SERVER_URL}/api/v1/${dataItem.route}/${dataItem.id}/${action}.json${params}`
  }

  const updateFiles = (event) => {
    setInnerFiles(event.newState)
  }

  const message = (scenario, status, body) => ({
    title: I18n.t(`form.inputs.attachment.${scenario}_${status}`),
    body,
    status,
    closable: true,
    closeTimeout: 10
  })

  const onBeforeUpload = (event) => {
    event.additionalData.fileCategoryId = selectedCategory.id
    event.additionalData.uuid = guid()
    event.headers.authorization = `Token ${getToken()}`
  }

  const onStatusChange = (event) => {
    const showUploadErrorMsg = () => {
      const responseData = byString(event.response, 'response.data')
      if (responseData && responseData.error) {
        setNotification(message('upload', 'error', event.response.response.data.error))
        return
      }
      if (responseData && responseData.photo) {
        let msgBody = byString(event.response, 'response.data.photo')
        msgBody = msgBody ? msgBody.join('; ') : undefined
        setNotification(message('upload', 'error', msgBody))
        return
      }
      setNotification(message('upload', 'error', undefined))
    }

    updateFiles(event)
    if (!event.response) return

    if (event.response.status === codes.CREATED) {
      const affectedIndex = event.newState.findIndex((file) => {
        return file.uid === event.affectedFiles[0].uid
      })
      event.newState[affectedIndex].uuid = event.response.response.uuid
      event.newState[affectedIndex].file_category_id = event.response.response.file_category_id || PICTURE_CATEGORY_ID
      event.newState[affectedIndex].attachable_type = event.response.response.attachable_type
      event.newState[affectedIndex].id = event.response.response.id

      updateFiles(event)
      setNotification(message('upload', 'success'))
      return
    }
    showUploadErrorMsg()
  }

  const settings = {
    id: `attachment-kendo-upload-${id}`,
    selectText: I18n.t('form.inputs.attachment.select_files'),
    onFiles: () => innerFiles,
    multiple: true,
    saveField: isCreationForm ? fileColumnType : id,
    saveUrl: isCreationForm ? createUrl(creationSaveAction) : updateUrl(updateSaveAction),
    onAdd: updateFiles,
    onBeforeUpload,
    withCredentials: true,
    onProgress: updateFiles,
    onRemove: updateFiles,
    onStatusChange,
    restrictions: { allowedExtensions, maxFileSize }
  }

  useEffect(() => {
    if (innerFiles.length && innerFiles.every((e) => e.status === KendoFileStatus.uploaded)) {
      setModalOpened(false)
      setFiles((prevFiles) => uniqBy([...prevFiles, ...innerFiles], 'uuid'))

      setInnerFiles([])
    }
  }, [innerFiles])

  const openImportModal = () => {
    if (files.length >= MAX_ALLOWED_NUM_UPLOADS) {
      const messageBody = I18n.t('form.inputs.attachment.maximum_num_uploads_error', {
        max_num: MAX_ALLOWED_NUM_UPLOADS
      })
      setNotification(message('add', 'error', messageBody))
      return
    }

    setModalOpened(true)
  }

  const orderByName = (categories) => {
    return categories.sort((a, b) => {
      if (a.description > b.description) return 1
      if (b.description > a.description) return -1
      return 0
    })
  }

  const renderUploadComponent = () => (
    <div className="input-attachment" id={id}>
      <div className="d-flex">
        <div className="form-input-combobox-wrapper">
          <DropDownList
            onChange={(e) => setSelectedCategory(e.target.value)}
            data={orderByName(Object.values(fileCategory))}
            dataItemKey="id"
            textField="description"
            popupSettings={{ className: 'combobox-list' }}
            disabled={readOnly}
          />
        </div>
        <IconButton
          btnClassName={error ? 'input-error' : ''}
          disabled={readOnly || !selectedCategory}
          onClick={openImportModal}
          icon={<MdBackup />}
          title={I18n.t('actions.upload_file')}
        />
      </div>
      {modalOpened && (
        <UploadAttachmnetModal
          innerFiles={innerFiles}
          allowedExtensions={allowedExtensions}
          maxSize={MAX_ATTACHMENT_SIZE}
          settings={settings}
          closeModal={() => setModalOpened(false)}
        />
      )}
    </div>
  )

  const onDelete = (attachment) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.uuid !== attachment.uuid))
    onChange(id, null)
  }

  const getCategory = (file) => {
    if (file?.file_category_id) {
      return fileCategory?.[file.file_category_id]?.description
    }
  }

  const removeUrlParamsCreate = (file) => ({
    requestAction: 'DESTROY',
    httpAction: 'delete',
    query: { where: { eav_column_id: columnId } },
    data: { uuid: file.uuid }
  })

  const removeUrlParamsUpdate = (file) => ({
    requestAction: 'DESTROY',
    httpAction: 'delete',
    resourceId: dataItem.id,
    additionalResource: { path: updateDeleteAction },
    data: {
      eav_column_id: columnId,
      uuid: file.uuid
    }
  })

  return (
    <React.Fragment>
      <div className="import-dropdown">
        {renderUploadComponent()}
        <ReorderableList
          data={files}
          onItemRender={(file, index) => (
            <DeleteAttachment
              key={file.uuid || index}
              file={file}
              fileCategory={getCategory(file)}
              onDelete={onDelete}
              route={isCreationForm ? creationDeleteAction : dataItem.route}
              removeUrlParams={isCreationForm ? removeUrlParamsCreate(file) : removeUrlParamsUpdate(file)}
              isCreationForm={Boolean(isCreationForm)}
            />
          )}
          idField="uuid"
          onSetList={setFiles}
        />
      </div>
      <div className="error-label error-label-attachment">{error}</div>
    </React.Fragment>
  )
}

InputAttachment.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.array,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    columnId: PropTypes.number,
    readOnly: PropTypes.bool,
    allowedExtensions: PropTypes.oneOfType([PropTypes.array]),
    maxFileSize: PropTypes.number,
    onChange: PropTypes.func
  }).isRequired
}
