import React from 'react'
import PropTypes from 'prop-types'
import { MdDeleteOutline } from 'react-icons/md'

export default function BatchEditionDeleteButton({ column, onDeleteAction }) {
  if (!onDeleteAction) return null
  return (
    <div
      className="columns-batch-assigner--action-button"
      onClick={() => onDeleteAction(column)}
      data-testid="batch-edition-delete-button"
    >
      <MdDeleteOutline />
    </div>
  )
}

BatchEditionDeleteButton.propTypes = {
  column: PropTypes.shape({
    description: PropTypes.string
  }),
  onDeleteAction: PropTypes.func
}

BatchEditionDeleteButton.defaultProps = {
  column: undefined,
  onDeleteAction: undefined
}
