import { useEffect } from 'react'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { moveScreenToError } from '/src/ui/core/inputs/input_error'
import { isBlankOrFalse, isBlankOrZero } from '/src/utils/boolean_refinements'

/**
 *  Hook designed to gather and send form data
 * @param model - project formatted model - mandatory
 * @param action - type of action for the submit (create or update) - mandatory
 * @param eavColumns - Flexible columns to be included on the form - optional
 * @param hideLoadingDialog - boolean to prevent loading dialog popup to be fired - optional
 * @param dataItem - The dataItem to be updated on the update scenario - optional
 * @param submitParams - default params used on form send call - optional
 * @param errorHandler - function to treat error message - optional
 * @param onSuccess - callback function to be called on success - optional
 * @param filterDataAttributes - callback function to filter data before send to API - optional
 * @return {((function(*=): (undefined))|*)[]} [function to send form data to API,
 * boolean loading for the API call]
 */
export default function useSendForm({
  model,
  action,
  eavColumns,
  hideLoadingDialog,
  dataItem,
  submitParams,
  errorHandler,
  onSuccess,
  onFormSubmitAction,
  filterDataAttributes,
  pollingCallback,
  customDispatchEvent
}) {
  const route = `${model.route}${dataItem?.items ? '/save_in_batch' : ''}`
  const { errors, loading, requestAction, responseData, fetchAPI } = useFetchAPI(route)

  // Return the notification message settings based on the action and status
  const message = (status) => I18n.t(`form.${action}_${status}`, { entity: model.name })

  const afterSend = (response) => {
    notifySuccess(response)
    dispatch(BusEvents.FORM_SUBMITTED)
    if (onSuccess) onSuccess(responseData)
  }

  const onFinishedJob = () => {
    afterSend(message('success'))
  }

  const onJobError = () => {
    notifyError(I18n.t('notification.error_job'))
    dispatch(BusEvents.HIDE_DIALOG)
  }

  useEffect(() => {
    if (errors && errorHandler) {
      const response = errorHandler(errors)
      if (response) afterSend(response)
    }

    if (errors || loading) return
    // TODO: Treat api response from form data sent
    if (['CREATE', 'UPDATE'].includes(requestAction)) {
      if (pollingCallback) return pollingCallback({ responseData, onFinishedJob, onJobError })
      dispatch({ type: BusEvents.FORM_SUCCESS, payload: responseData, customDispatchEvent })
      afterSend(message('success'))
    }
  }, [errors, loading, responseData])

  useEffect(() => {
    if (hideLoadingDialog) return
    if (loading) dispatch(BusEvents.SHOW_LOADING_DIALOG)
    else if (!pollingCallback) dispatch(BusEvents.HIDE_DIALOG)
  }, [loading])

  const isAnyRequired = (data) => {
    // TODO: add the colum type in the following array
    //  if you are developing new form field
    const allowedForMandatoryCheck = [
      'boolean',
      'string',
      'integer',
      'percentage',
      'decimal',
      'search',
      'drop',
      'multiple_drop',
      'picture',
      'multiline_text',
      'rich_text',
      'cascade_drop',
      'conditional_cascade_drop',
      'time',
      'link',
      'module_filter',
      'date',
      'date_time',
      'lookup',
      'datasheet_filter',
      'multiple_datasheet_filter',
      'drop_formula'
    ]

    let fixedFieldsRequired = []
    let flexibleFieldsRequired = []

    if (model.columns) {
      fixedFieldsRequired = model.columns.filter(({ required, type }) => {
        const isRequired = typeof required === 'function' ? required(dataItem) : required
        return isRequired && allowedForMandatoryCheck.includes(type)
      })
    }

    if (eavColumns) {
      flexibleFieldsRequired = Object.values(eavColumns).filter((column) => {
        return column.required && allowedForMandatoryCheck.includes(column.column_type.description)
      })
    }

    const requiredFields = [...fixedFieldsRequired, ...flexibleFieldsRequired]

    if (!requiredFields) return false
    moveScreenToError()
    return requiredFields.some((field) => {
      const fieldDescription = field.foreignKey ? field.foreignAttribute : field.description
      return isBlankOrFalse(data[fieldDescription])
    })
  }

  const isInvalidMultipleEntries = (data) => {
    let multipleEntriesFields = []
    let isInvalid = false

    if (model.columns) {
      multipleEntriesFields = model.columns.filter(({ type }) => type === 'multiple_entries')
    }

    multipleEntriesFields.forEach((input) => {
      const fieldDescription = input.description

      if (data[fieldDescription]) {
        if (data[fieldDescription].length < 1) {
          notifyError(
            I18n.t('form.inputs.multiple_entries.empty_inputs', {
              input: input.description,
              model: model.name
            })
          )
          isInvalid = true
          return
        }

        if (data[fieldDescription][0].flag) {
          const childInputs = {}

          Object.keys(data[fieldDescription][0]).forEach((key) => {
            if (key !== 'total' && key !== 'flag' && key !== 'uuid') {
              childInputs[key] = data[fieldDescription][0][key]
            }
          })

          const invalidInputs = Object.keys(childInputs).filter((key) => isBlankOrZero(childInputs[key]))

          if (invalidInputs.length === 0) {
            return
          }

          invalidInputs.forEach((invalidInput) => {
            const column = input.columns.find((col) => col.description === invalidInput)
            notifyError(
              I18n.t('form.inputs.multiple_entries.required', {
                field: column.title
              })
            )
          })

          isInvalid = true
        }
      }
    })

    return isInvalid
  }

  const hasMinOrMaxValues = (data) => {
    moveScreenToError()
    return (
      model.columns &&
      model.columns.some((field) => {
        const fieldDescription = field.foreignKey ? field.foreignAttribute : field.description
        return (field.min && data[fieldDescription] < field.min) || (field.max && data[fieldDescription] > field.max)
      })
    )
  }

  const sendFormData = (form) => {
    if (isAnyRequired(form)) return
    if (hasMinOrMaxValues(form)) return
    if (isInvalidMultipleEntries(form)) return

    const data = {}
    data[model.paramName] = form

    if (filterDataAttributes) data[model.paramName] = filterDataAttributes(form)

    const items = dataItem?.items?.map((item) => ({ ...data[model.paramName], ...item }))

    const isInBatch = !!dataItem?.items
    let params = {
      requestAction: action === 'create' ? 'CREATE' : 'UPDATE',
      httpAction: action === 'create' ? 'post' : 'put',
      data: isInBatch ? { items } : data
    }

    if (action === 'update') params.resourceId = dataItem ? dataItem.id : null

    if (submitParams) params = { ...params, ...submitParams }

    fetchAPI(params)
  }

  return onFormSubmitAction ? [onFormSubmitAction, false] : [sendFormData, loading]
}
