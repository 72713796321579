import React from 'react'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import { useStore } from 'react-context-hook'
import { formattedLocaleDate } from '/src/utils/project_formatter'

export default function DateTimeColumn({ value }) {
  const [globalProject] = useStore('project')
  const opts = { project: globalProject }
  opts.parser = new IntlService()
  opts.time = true
  opts.value = value

  return formattedLocaleDate(opts)
}

DateTimeColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}

DateTimeColumn.defaultProps = {
  value: null
}
