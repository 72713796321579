import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ChooseInspectionTemplateHeader({ templateCategoryName }) {
  const subtitle = templateCategoryName
    ? I18n.t('requests.create_inspection_modal.category_subtitle', {
        category_name: templateCategoryName
      })
    : I18n.t('requests.create_inspection_modal.standalone_subtitle')

  return (
    <div className="select-inspection-subtitle-wrapper">
      <p className="select-inspection-subtitle-text">{subtitle}</p>
    </div>
  )
}

ChooseInspectionTemplateHeader.propTypes = {
  templateCategoryName: PropTypes.string
}

ChooseInspectionTemplateHeader.defaultProps = {
  templateCategoryName: null
}
