import React from 'react'
import PropTypes from 'prop-types'

export default function DatasheetResultColumn({ value }) {
  try {
    const formattedValue = JSON.parse(value)
    return formattedValue.join(' | ')
  } catch (e) {
    return value
  }
}

DatasheetResultColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DatasheetResultColumn.defaultProps = {
  value: null
}
