import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'

export default function DecimalUnitColumn({ value, unit }) {
  const [globalProject] = useStore('project')

  return (
    <span className="align-cells-right">
      {formatNumberWithPrecision(value, globalProject)} {unit}
    </span>
  )
}

DecimalUnitColumn.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string
}

DecimalUnitColumn.defaultProps = {
  value: 0,
  unit: 'm'
}
