import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import { useStore } from 'react-context-hook'
import { formattedLocaleDate } from '/src/utils/project_formatter'
import useTimelineBlockUtils from '/src/ui/domain/requests/timeline_block_utils'
import TimelineBlockGroup from '/src/ui/core/blocks/timeline_block_group'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/domain/requests/schedule_side_panel_block.css'

export default function TimelineSidePanelBlock({ request }) {
  const [project] = useStore('project')
  const [employees] = useStore('employees')
  const opts = { project: project, parser: new IntlService() }
  const [showLoading, setShowLoading] = useState(false)
  const timelineMap = useTimelineBlockUtils({ request, setShowLoading, project })

  const formatDate = (date) => {
    if (date) return formattedLocaleDate({ ...opts, value: date })
  }

  return (
    <div className="timeline-block">
      {showLoading ? (
        <ThreeDotsLoader />
      ) : (
        timelineMap.map((timelineItem, index) => {
          const responsible = employees[timelineItem.responsibleId]
          return (
            timelineItem.title && (
              <TimelineBlockGroup
                key={index}
                title={timelineItem.title}
                statusColor={timelineItem.statusColor}
                statusIcon={timelineItem.statusIcon}
                leftLabel={timelineItem.daysAgo}
              >
                <React.Fragment>
                  <CustomTooltip position="bottom" parentTitle>
                    <span title={timelineItem.comments}>
                      <div className="info">
                        <div className="timeline-comments">{timelineItem.comments}</div>
                      </div>
                    </span>
                  </CustomTooltip>
                  <div className="timeline-dates">
                    <span>{formatDate(timelineItem.statusDate)}</span>
                  </div>
                  {timelineItem.estimatedCost && <div>{timelineItem.estimatedCost}</div>}
                  <div className={`responsible ${responsible && 'border'}`}>{responsible && responsible.full_name}</div>
                </React.Fragment>
              </TimelineBlockGroup>
            )
          )
        })
      )}
    </div>
  )
}

TimelineSidePanelBlock.propTypes = {
  request: PropTypes.oneOfType([PropTypes.object]).isRequired
}
