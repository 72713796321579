import React from 'react'
import PropTypes from 'prop-types'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import RequestForm from '/src/ui/domain/requests/request_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'

export default function RequestFormModal({ requestModel }) {
  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    () => {
      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: requestModel.paramName,
        payload: { dataItem: undefined, formType: 'new' }
      })
    },
    []
  )

  return (
    <GridFormModal modelName={requestModel.paramName}>
      <RequestForm model={requestModel} />
    </GridFormModal>
  )
}

RequestFormModal.propTypes = {
  requestModel: PropTypes.oneOfType([PropTypes.object]).isRequired
}
