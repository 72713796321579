import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import FlagIconsBadge from '/src/ui/core/badges/flag_icons_badge'
import { getStatusFlag, getAdditionalFlag, getInspectionFlag, } from '/src/utils/flag_builder'
import '/src/static/css/domain/progress_services/cells.css'

export default function FlagColumn({ dataItem, request }) {
  const [statuses] = useStore('progress_service_statuses')

  if (!statuses) return null

  const {
    estimate_service: estimateService,
    quantity,
    progress_service_status_id: statusId,
    inspected_flag: inspectedFlag
  } = dataItem

  const notSkipScoping = request && !request.skip_scoping
  const isExtra = (!estimateService || estimateService.quantity === null) && notSkipScoping
  const isRevised = estimateService && estimateService.quantity !== quantity
  const hasInspectionPending = inspectedFlag && inspectedFlag === 'pending'

  const flags = [
    getStatusFlag(statusId, statuses),
    getAdditionalFlag(isExtra, isRevised),
    getInspectionFlag(hasInspectionPending)
  ].filter(Boolean)

  return <FlagIconsBadge flags={flags} />
}

FlagColumn.propTypes = {
  dataItem: PropTypes.shape({
    quantity: PropTypes.number,
    progress_service_status_id: PropTypes.number,
    inspected_flag: PropTypes.string,
    estimate_service: PropTypes.shape({
      quantity: PropTypes.number
    })
  }).isRequired,
  request: PropTypes.shape({
    skip_scoping: PropTypes.bool
  })
}

FlagColumn.defaultProps = {
  request: {}
}
