import React from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import { AiOutlineLoading3Quarters, AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import '/src/static/css/dialogs.css'
import PropTypes from 'prop-types'

export default function BlockingDialog({ title, message, onClose, icon, closeIcon }) {
  const dialogIcon = {
    loadIcon: (
      <div className="dialog-icon-container" data-testid="load-icon">
        <AiOutlineLoading3Quarters className="spinner dialog-icon dialog-icon-load" />
      </div>
    ),
    successIcon: (
      <div className="dialog-icon-container" data-testid="success-icon">
        <AiOutlineCheckCircle className="dialog-icon dialog-icon-success" />
      </div>
    ),
    failureIcon: (
      <div className="dialog-icon-container" data-testid="failure-icon">
        <AiOutlineCloseCircle className="dialog-icon dialog-icon-failure" />
      </div>
    )
  }

  return (
    <Dialog
      title={title}
      closeIcon={closeIcon}
      onClose={onClose}
      width={300}
      height={220}
      style={{
        zIndex: '20000'
      }}
    >
      {dialogIcon[icon]}
      <p className="dialog-message" data-testid="blocking-dialog-rendered">
        {message}
      </p>
    </Dialog>
  )
}

BlockingDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.string.isRequired,
  closeIcon: PropTypes.bool
}

BlockingDialog.defaultProps = {
  onClose: null,
  closeIcon: true,
  message: ''
}
