import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MdEdit } from 'react-icons/md'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import useInputError from '/src/ui/core/inputs/input_error'
import { isJson } from '/src/utils/string'
import { datasheetFilterVisibleOnWeb } from '/src/models/concerns/eav_column'
import { onFilterStepsValues, removeDuplicatedFilters, templateTitles } from '/src/models/concerns/datasheet_filter'
import '/src/static/css/core/inputs/input_filter.css'

export default function InputDatasheetFilter({ inputProps }) {
  const { templates, filters, tag, onChange, read_only: readOnly } = inputProps

  const isRequired = filters[0] && filters[0].required
  const visibleOnWeb = datasheetFilterVisibleOnWeb(filters)
  const title = filters[0] && filters[0].title

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState(filters)
  const [currentValues, setCurrentValues] = useState({})
  const error = useInputError({
    inputValue: currentValues,
    title,
    required: isRequired,
    type: 'object'
  })
  const showCard = filters[0] ? isRequired || visibleOnWeb : true

  const { status, responseData, fetchAPI } = useFetchAPI('eav_columns')

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: filters.map((f) => f.datasheet.datasheet_column_id) } }
    }

    fetchAPI(params)
  }, [])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const newStepFilters = filters.map((f) => {
      const column = responseData.data.find((c) => c.id === f.datasheet.datasheet_column_id)
      return {
        ...f,
        multiple: f.column_type.description === 'multiple_datasheet_filter',
        filterAttribute: column.description,
        query: { where: { eav_template_id: f.datasheet.datasheet_template_id } }
      }
    })

    onFilterStepsValues(newStepFilters, onChange, setCurrentValues)
    setStepFilters(newStepFilters)
  }, [status, responseData])

  useEffect(() => {
    onFilterStepsValues(stepFilters, onChange, setCurrentValues)
  }, [stepFilters])

  const onDone = (values) => {
    const newStepFilters = stepFilters.map((f) => {
      return { ...f, value: values[f.filterAttribute] }
    })

    setStepFilters(newStepFilters)
    setOpen(false)
  }

  const renderValues = (value) => {
    if (Array.isArray(value) && value[0] === null) return I18n.t('fields.na')
    if (isJson(value)) return JSON.parse(value).join(', ')

    return Array.isArray(value) ? value.map((v) => v.computed_text_field || v).join(', ') : value
  }

  const renderFilters = () => {
    const uniqueFilters = removeDuplicatedFilters(stepFilters)
    return uniqueFilters.map((filter) => {
      const { id, title, value } = filter
      return <div key={id}>{`${title}: ${value ? renderValues(value) : I18n.t('fields.na')}`}</div>
    })
  }

  const onEditButton = () => {
    if (readOnly) return
    onFilterStepsValues(stepFilters, onChange, setCurrentValues)
    setOpen(true)
  }

  const filteredSteps = useMemo(() => removeDuplicatedFilters(stepFilters), [stepFilters])

  return (
    <React.Fragment>
      <div className={classNames({ filter: true, 'no-display': !showCard })}>
        <div className={classNames({ 'filter-header': true, 'input-error': error })}>
          <div className="filter-group-title">
            <div className="filter-title">
              <RequiredMark required={isRequired} />
              <span className="datasheet-titles">{templateTitles(templates)}</span>
            </div>
            <div className="filter-tag">{tag}</div>
          </div>
          <div className="filter-edit" onClick={onEditButton}>
            <MdEdit />
          </div>
        </div>
        <div className="filter-body">
          <div className="filters">{renderFilters()}</div>
        </div>
      </div>
      <div className="error-label">{error}</div>
      {open && (
        <FilterStepsModal
          filters={filteredSteps}
          route="datasheets"
          resultTitle={templateTitles(templates)}
          tag={tag}
          onClose={() => setOpen(false)}
          currentValues={currentValues}
          onDone={onDone}
        />
      )}
    </React.Fragment>
  )
}

InputDatasheetFilter.propTypes = {
  inputProps: PropTypes.shape({
    tag: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ),
    onChange: PropTypes.func,
    read_only: PropTypes.bool
  }).isRequired
}
