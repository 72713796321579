import React from 'react'
import EstimateModel from '/src/models/estimate'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import EstimateForm from '/src/ui/domain/estimates/estimate_form'
import '/src/static/css/domain/estimates/estimate_form_modal.css'
import '/src/static/css/domain/estimates/estimate.css'
import '/src/static/css/domain/estimates/estimate_form_modal.css'

export default function EstimateFormModal() {
  const estimateModel = new EstimateModel()

  return (
    <GridFormModal modelName={estimateModel.paramName} className="estimate-form-modal">
      <EstimateForm />
    </GridFormModal>
  )
}
