import React from 'react'
import { PropTypes } from 'prop-types'
import PDFTitleServices from '/src/ui/core/grid/pdf_print/pdf_titles/pdf_title_services'
import PDFTitleInspection from '/src/ui/core/grid/pdf_print/pdf_titles/pdf_title_inspection'

export default function PrintListPDFTitle({
  item,
  modelName,
  storeValues,
  templateTitle,
  firstFlexibleColumnDescription
}) {
  if (['Construction Services', 'Estimate Details'].includes(modelName)) {
    return (
      <PDFTitleServices
        item={item}
        modelName={modelName}
        storeValues={storeValues}
        firstFlexibleColumnDescription={firstFlexibleColumnDescription}
      />
    )
  }

  if (modelName === 'Inspections') {
    return (
      <PDFTitleInspection
        item={item}
        templateTitle={templateTitle}
        inspectStatuses={storeValues.inspectStatuses}
        firstFlexibleColumnDescription={firstFlexibleColumnDescription}
      />
    )
  }

  return null
}

PrintListPDFTitle.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modelName: PropTypes.string.isRequired,
  firstFlexibleColumnDescription: PropTypes.string,
  templateTitle: PropTypes.string,
  storeValues: PropTypes.shape({
    project: PropTypes.oneOfType([PropTypes.object]),
    subproject: PropTypes.oneOfType([PropTypes.object]),
    units: PropTypes.oneOfType([PropTypes.object]),
    inspectStatuses: PropTypes.oneOfType([PropTypes.object]),
    progressServiceStatuses: PropTypes.oneOfType([PropTypes.object])
  }).isRequired,
}

PrintListPDFTitle.defaultProps = {
  firstFlexibleColumnDescription: undefined,
  templateTitle: undefined
}
