import { store } from 'react-context-hook'
import { batchEntities } from '/src/utils/constants/batch_entities'

export default function useClearBatchEntities() {
  const clearBatchEntities = () => {
    batchEntities.forEach((entityName) => {
      store.set(entityName, {})
      store.set('last_sync', {})
    })
  }

  return clearBatchEntities
}
