import React from 'react'
import PropTypes from 'prop-types'

export default function NumberColumn({ value }) {
  return (
    <div className="number-wrapper">
      <div className="number">{value}</div>
    </div>
  )
}

NumberColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number])
}

NumberColumn.defaultProps = {
  value: undefined
}
