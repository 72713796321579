import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@progress/kendo-react-dialogs'
import I18n from '/src/utils/translations'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import InputMultipleDrop from '/src/ui/core/inputs/input_multiple_drop'
import InputDrop from '/src/ui/core/inputs/input_drop'
import InputTitle from '/src/ui/core/inputs/input_title'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import { MAX_COLUMNS_TO_PRINT, PAPER_SIZES } from '/src/utils/constants/printing'
import { MEDIUM_POPUP_HEIGHT, MEDIUM_POPUP_WIDTH } from '/src/utils/constants/popups'
import '/src/static/css/dialogs.css'
import '/src/static/css/core/popups/configure_print_grid_file.css'

export default function ConfigurePrintGridFile({ onClose, columns, model }) {
  const [selectedColumns, setSelectedColumns] = useState([])
  const [paperSize, setPaperSize] = useState()

  const numSelectedColumns = () => (!selectedColumns ? 0 : selectedColumns.length)

  const formatSelectedColumns = (value) => {
    if (value && value.length > MAX_COLUMNS_TO_PRINT) {
      notifyError(
        I18n.t('dialogs.configure_print_grid_file.error_max_columns', {
          maxColumns: MAX_COLUMNS_TO_PRINT
        })
      )
    }

    return !value ? value : value.slice(0, MAX_COLUMNS_TO_PRINT)
  }

  const onGenerateFile = () => {
    if (!selectedColumns || selectedColumns.length === 0 || !paperSize) {
      dispatch(BusEvents.INPUT_VALIDATE)
      return
    }

    dispatch({
      type: BusEvents.PRINT_GRID,
      payload: { selectedColumns, paperSize, model }
    })
    onClose()
  }

  const onChangeColumns = (_, inputValue) => setSelectedColumns(inputValue)
  const onChangePaperSize = (_, inputValue) => setPaperSize(inputValue)

  return (
    <React.Fragment>
      <Dialog
        className="configure-print-grid-file"
        title={I18n.t('dialogs.configure_print_grid_file.header')}
        closeIcon
        onClose={onClose}
        width={MEDIUM_POPUP_WIDTH}
        height={MEDIUM_POPUP_HEIGHT}
      >
        <div className="title">
          <span>{I18n.t('dialogs.configure_print_grid_file.title')}</span>
        </div>
        <div className="subtitle">
          <span>
            {I18n.t('dialogs.configure_print_grid_file.subtitle', {
              maxColumns: MAX_COLUMNS_TO_PRINT
            })}
          </span>
        </div>
        <InputTitle title={I18n.t('dialogs.configure_print_grid_file.select_columns_title')} required>
          <div className="column-label-subtitle">
            {I18n.t('dialogs.configure_print_grid_file.selected_columns', {
              numSelectedColumns: numSelectedColumns(),
              maxColumns: MAX_COLUMNS_TO_PRINT
            })}
          </div>
        </InputTitle>
        <InputMultipleDrop
          inputProps={{
            id: 'selected_columns',
            title: I18n.t('dialogs.configure_print_grid_file.select_columns_title'),
            options: columns || [],
            multiple: true,
            textField: 'title',
            valueField: 'description',
            onChange: onChangeColumns,
            popupClassName: 'column-multiple-drop',
            required: true,
            valueFormatter: formatSelectedColumns
          }}
        />
        <InputTitle title={I18n.t('dialogs.configure_print_grid_file.paper_size_title')} required />
        <InputDrop
          inputProps={{
            id: 'paper_size',
            title: I18n.t('dialogs.configure_print_grid_file.paper_size_title'),
            options: PAPER_SIZES,
            onChange: onChangePaperSize,
            required: true
          }}
        />
        <div className="action-buttons">
          <CancelButton title={I18n.t('actions.cancel')} onClick={onClose} />
          <button className="generate-button" onClick={onGenerateFile}>
            {I18n.t('dialogs.configure_print_grid_file.generate')}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

ConfigurePrintGridFile.propTypes = {
  onClose: PropTypes.func,
  columns: PropTypes.array.isRequired,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

ConfigurePrintGridFile.defaultProps = {
  onClose: () => {}
}
