import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MdThumbUp, MdThumbDown } from 'react-icons/md'
import I18n from '/src/utils/translations'
import useFetch from '/src/hooks/api/fetch'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import useFormModal from '/src/ui/core/popups/form_modal'
import useDisciplineCheckPopupFields from '/src/ui/domain/requests/discipline_check_popup_fields'
import {
  requestTitle,
  DISCIPLINE_CHECK_PENDING_STATUS,
  DISCIPLINE_CHECK_DONE_STATUS,
  DISCIPLINE_CHECK_REJECTED_STATUS
} from '/src/utils/constants/request'
import '/src/static/css/domain/requests/discipline_check_popup.css'

function getNotificationMessage(status) {
  const prefix = 'requests.notification'
  let message = `${prefix}.discipline_check_success`

  if (status === DISCIPLINE_CHECK_DONE_STATUS) {
    message = `${prefix}.discipline_check_approved`
  } else if (status === DISCIPLINE_CHECK_REJECTED_STATUS) {
    message = `${prefix}.discipline_check_rejected`
  }

  return message
}

export default function DisciplineCheckPopup({ dataItem, setDataItem, requestTemplate }) {
  const markedToDiscipline = dataItem.isReady

  const [data, setData, sections] = useDisciplineCheckPopupFields({
    markedToDiscipline,
    requestTemplate
  })

  const { fetch } = useFetch()

  const onDisciplineCheckRequest = useCallback(
    (status) => {
      dispatch(BusEvents.SHOW_LOADING_DIALOG)
      const params = {
        requestAction: 'CREATE',
        httpAction: 'post',
        data: {
          status: status || DISCIPLINE_CHECK_PENDING_STATUS,
          ...data
        }
      }

      fetch('discipline_checks', params, {
        onSuccess: () => {
          const message = getNotificationMessage(status)

          dispatch(BusEvents.HIDE_DIALOG)
          notifySuccess(I18n.t(message))
          dispatch(BusEvents.FORM_SUCCESS)
        },
        onError: () => {
          dispatch(BusEvents.HIDE_DIALOG)
        }
      })
    },
    [data, fetch]
  )

  const buttons = useMemo(() => {
    const negativeTitle = markedToDiscipline ? 'actions.reject' : 'actions.cancel'

    const positiveTitle = markedToDiscipline ? 'actions.approve' : 'actions.confirm'

    const negativeAction = markedToDiscipline
      ? () => {
          if (data.responsible_id && data.date && data.comments) {
            onDisciplineCheckRequest(DISCIPLINE_CHECK_REJECTED_STATUS)
          } else {
            dispatch(BusEvents.INPUT_VALIDATE)
          }
        }
      : null

    const positiveAction = () => {
      if (data.responsible_id && data.date) {
        markedToDiscipline
          ? onDisciplineCheckRequest(DISCIPLINE_CHECK_DONE_STATUS)
          : onDisciplineCheckRequest(DISCIPLINE_CHECK_PENDING_STATUS)
      } else {
        dispatch({ type: BusEvents.INPUT_VALIDATE, skipValidation: ['comments'] })
      }
    }

    return [
      {
        id: 1,
        text: I18n.t(negativeTitle),
        cancel: !markedToDiscipline,
        class: markedToDiscipline ? 'reject-button' : 'cancel-button',
        onClick: negativeAction,
        icon: markedToDiscipline ? <MdThumbDown /> : null
      },
      {
        id: 2,
        text: I18n.t(positiveTitle),
        class: 'approve-button',
        onClick: positiveAction,
        icon: <MdThumbUp />
      }
    ]
  }, [data, dataItem, onDisciplineCheckRequest])

  const modalProps = useMemo(() => {
    const prefix = 'requests.discipline_check'
    const formTitle = I18n.t(`${prefix}.title`)
    const formSubtitle = markedToDiscipline ? `${prefix}.ready_for_review` : `${prefix}.set_as_ready`
    const modalTitle = markedToDiscipline ? `${prefix}.perform` : `${prefix}.ready_for_discipline`

    return {
      formTitle,
      formSubtitle: I18n.t(formSubtitle),
      className: 'discipline-check-popup',
      modalTitle: I18n.t(modalTitle),
      label: requestTitle(dataItem.request || {}),
      sections,
      buttons,
      dataItem: data.responsible_id && data.date ? data : {},
      model: {},
      width: 48.5,
      height: 80.5,
      sectionable: false,
      closable: true,
      onClose: () => {
        setData({})
        setDataItem({})
      }
    }
  }, [buttons, data, dataItem, sections, setData, setDataItem])

  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => {
    if (dataItem && Object.values(dataItem).length) {
      setData(dataItem)
      openModal()
    }
  }, [dataItem, setData])

  return renderModal()
}

DisciplineCheckPopup.propTypes = {
  dataItem: PropTypes.shape({
    request_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    request: PropTypes.object,
    isReady: PropTypes.bool
  }),
  setDataItem: PropTypes.func,
  requestTemplate: PropTypes.shape({
    metadata: PropTypes.string
  })
}

DisciplineCheckPopup.defaultProps = {
  dataItem: {},
  setDataItem: () => {},
  requestTemplate: undefined
}
