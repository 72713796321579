import React from 'react'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { MdRequestQuote } from 'react-icons/md'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import EstimateModel from '/src/models/estimate'

export default function RequestEstimatesIcon({ selectedRequests }) {
  if (selectedRequests.length === 0) return null

  const estimateModel = new EstimateModel()

  const onClick = () =>
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      payload: selectedRequests,
      modelName: estimateModel.paramName
    })

  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="request-estimates-btn"
        type="button"
        title={I18n.t(`requests.request_detail.estimates`)}
        data-testid="request-estimates-btn"
        className="btn-icon grid-header-icon"
        onClick={onClick}
      >
        <MdRequestQuote />
      </button>
    </CustomTooltip>
  )
}

RequestEstimatesIcon.propTypes = {
  selectedRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}

RequestEstimatesIcon.defaultProps = {
  selectedRequests: []
}
