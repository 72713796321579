import { useMemo } from 'react'
import { isDatasheetFilter } from '/src/models/concerns/eav_column'
import {
  allFiltersAvailable,
  formatFilterColumns,
  groupFilterColumnsByTag
} from '/src/models/concerns/datasheet_filter'

export default function useDatasheetFiltersByTag({
  columns,
  datasheetFilters,
  multipleDatasheetFilters,
  datasheetTemplates,
  datasheetColumns
}) {
  const filtersByTag = useMemo(() => {
    if (!columns || !datasheetFilters || !multipleDatasheetFilters || !datasheetTemplates || !datasheetColumns)
      return {}

    const filteredColumns = columns.filter((column) => isDatasheetFilter(column))

    const isFiltersAvailable = allFiltersAvailable({
      filteredColumns,
      datasheetFilters,
      multipleDatasheetFilters,
      datasheetColumns
    })

    if (!isFiltersAvailable) return {}

    const formattedColumns = formatFilterColumns({
      filteredColumns,
      datasheetFilters,
      multipleDatasheetFilters,
      datasheetColumns
    }).sort((a, b) => a.position - b.position)

    const groupedColumns = groupFilterColumnsByTag({
      formattedColumns,
      datasheetTemplates
    })

    return groupedColumns
  }, [columns, datasheetColumns, datasheetFilters, multipleDatasheetFilters, datasheetTemplates])

  return filtersByTag
}
