export const IsEqualOperator = 'eq'
export const IsNotEqualOperator = 'neq'
export const ContainsOperator = 'contains'
export const BetweenOperator = 'between'

export const allFilterOperators = {
  text: [
    { text: 'grid.filterBetweenOperator', operator: BetweenOperator },
    { text: 'grid.filterContainsOperator', operator: ContainsOperator },
    { text: 'grid.filterEqOperator', operator: IsEqualOperator },
    { text: 'grid.filterNotEqOperator', operator: IsNotEqualOperator }
  ]
}
