/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import I18n from '/src/utils/translations'
import '/src/static/css/core/blocks/cluster_block.css'

export default function ClusterBlock({ highlight, dataItems, dataKey, link, onSubrequestClick }) {
  const printHighlight = () => (
    <React.Fragment>
      <ul className="cluster-highlight cluster-block-list clustered-item">
        <li key={0} className="badge-clustered-wrapper">
          <span className="clustered-item-content">
            <span className="badge-clustered" />
          </span>
          <div className="item-button">
            <span>{highlight}</span>
          </div>
        </li>
      </ul>
      <div className="solid-line" />
    </React.Fragment>
  )

  const queryParams = (dataItem) => {
    const itemParams = {}
    link.params.map((param) => (itemParams[param.column] = param.fromDataItem ? dataItem[param.value] : param.value))
    return queryString.stringify(itemParams)
  }

  const printItemContent = (dataItem) => {
    if (!link) return <p className="item-button">{dataItem[dataKey]}</p>

    return (
      <Link onClick={onSubrequestClick} to={`${link.url}?${queryParams(dataItem)}`} className="item-button link">
        {dataItem[dataKey]}
      </Link>
    )
  }

  const printItem = (dataItem, index) => (
    <li key={index} className="item">
      <div className="badge-clustered-wrapper">
        <span className="clustered-item-content">
          <span className="badge-clustered" />
        </span>
        {printItemContent(dataItem)}
      </div>
    </li>
  )

  return (
    <div className="cluster-block-body">
      {dataItems.length > 0 && (
        <React.Fragment>
          {printHighlight()}
          <ul className="cluster-block-list">{dataItems.map((dataItem, index) => printItem(dataItem, index))}</ul>
        </React.Fragment>
      )}
    </div>
  )
}

ClusterBlock.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataItems: PropTypes.arrayOf(PropTypes.object),
  highlight: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.object])
}

ClusterBlock.defaultProps = {
  dataItems: [],
  highlight: I18n.t(`requests.clustered`),
  link: undefined
}
