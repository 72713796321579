import React from 'react'
import PropTypes from 'prop-types'

export default function IconButton({ icon, title, onClick, disabled, btnClassName }) {
  return (
    <div className="btn-text-icon">
      <button className={btnClassName} onClick={onClick} type="button" disabled={disabled}>
        {icon}
        <span>{title}</span>
      </button>
    </div>
  )
}

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  btnClassName: PropTypes.string
}

IconButton.defaultProps = {
  disabled: false,
  onClick: null,
  btnClassName: ''
}
