import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import SimpleGridHeader from '/src/ui/core/grid/simple_grid_header'
import SimpleGridContent from '/src/ui/core/grid/simple_grid_content'
import '/src/static/css/core/grid/grid.css'

const mockFunction = () => {}

export default function LoadingGrid({ model, gridTitle }) {
  const gridWrapper = useRef(null)

  return (
    <div className="entity-grid-wrapper" ref={gridWrapper}>
      <SimpleGridHeader
        page={1}
        take={30}
        total={1}
        loading
        gridTitle={gridTitle}
        icons={[]}
        onPageChange={mockFunction}
      />
      <SimpleGridContent status="loading" model={model} gridWrapper={gridWrapper} />
    </div>
  )
}

LoadingGrid.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  gridTitle: PropTypes.string.isRequired
}
