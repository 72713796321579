import React, { useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Image from '/src/ui/core/fields/image'
import ImageModal from '/src/ui/core/popups/image_modal'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'

export default function GalleryPicture({ images, columnId, openModal }) {
  const [selected, setSelected] = useState(undefined)
  const hasNoImages = !images || images.length === 0

  if (hasNoImages)
    return (
      <GridEmptyState
        modelName={I18n.t('empty_state.pictures')}
        message={I18n.t('empty_state.no_pictures')}
        imageSrc="pictures"
        className="empty-state-wrapper-gallery"
      />
    )

  return (
    <React.Fragment>
      {selected !== undefined && openModal && (
        <ImageModal
          images={images}
          width={100}
          height={100}
          extraClass="full-screen"
          openImage={selected}
          onClose={() => setSelected(undefined)}
        />
      )}
      <div className="gallery-picture-container">
        {images.map((image, index) => (
          <div key={image.id} role="button" className="gallery-picture">
            <Image
              image={image}
              key={image.id}
              index={index}
              onClick={() => setSelected(index)}
              columnId={columnId}
              showComment
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

GalleryPicture.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      file_path: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      comment: PropTypes.string
    })
  ),
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openModal: PropTypes.bool
}

GalleryPicture.defaultProps = {
  images: [],
  columnId: null,
  openModal: true
}
