/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ColumnMenuVisibility from '/src/ui/core/grid/column_menu_visibility'
import '/src/static/css/core/grid/column_menu.css'

export function ColumnMenu({ columns, onColumnsSubmit, onCloseMenu }) {
  const [isOpen, setIsOpen] = useState('visibility')

  return (
    <div className="column-menu-items">
      <ColumnMenuVisibility
        columns={columns}
        onColumnsSubmit={onColumnsSubmit}
        onCloseMenu={onCloseMenu}
        isOpen={isOpen === 'visibility'}
        onExpand={() => setIsOpen('visibility')}
      />
    </div>
  )
}

ColumnMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onColumnsSubmit: PropTypes.func,
  onCloseMenu: PropTypes.func,
}

ColumnMenu.defaultProps = {
  columns: undefined,
  onColumnsSubmit: undefined,
  onCloseMenu: undefined,
}