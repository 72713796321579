import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MdAdd } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'
import { isPresent } from '/src/utils/boolean_refinements'
import { parseDefaultValue } from '/src/models/concerns/eav_column'
import I18n from '/src/utils/translations'

const IGNORE_DEFAULT_VALUES = ['created_at', 'updated_at']

export default function AddRow({
  templateId,
  columns,
  onCreateNewItem,
  onAfterCreateNewItem,
  loading,
  getNewVirtualKey
}) {
  const [project] = useStore('project')
  const [globalSubproject] = useStore('subproject')

  const defaultValues = useMemo(() => {
    const eavColumnsWithDefault = columns.filter(
      (column) => isPresent(column.default) && !IGNORE_DEFAULT_VALUES.includes(column.description)
    )
    return eavColumnsWithDefault.reduce(
      (currentValues, column) => ({
        ...currentValues,
        [column.description]: parseDefaultValue(column, project)
      }),
      {}
    )
  }, [columns, project])

  const createNewItem = () => {
    const newDataItem = {
      ...defaultValues,
      eav_template_id: templateId,
      virtualKey: getNewVirtualKey(),
      subproject_id: globalSubproject.id,
      isNewlyAdded: true
    }
    if (onCreateNewItem) return onCreateNewItem(newDataItem)
    return newDataItem
  }

  const addNewItemToGrid = () => {
    const newDataItem = createNewItem()

    if (!newDataItem) return

    onAfterCreateNewItem(newDataItem)
  }

  return loading ? (
    <SpinnerIcon key="create-spinner" className="add-btn-spinner" />
  ) : (
    <div className="new-button-container">
      <button
        data-testid="new-grid-item-btn"
        title={I18n.t('actions.new')}
        className="new-btn"
        onClick={addNewItemToGrid}
      >
        <MdAdd />
        <span>{I18n.t('actions.new')}</span>
      </button>
    </div>
  )
}

AddRow.propTypes = {
  templateId: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  onCreateNewItem: PropTypes.func,
  onAfterCreateNewItem: PropTypes.func,
  loading: PropTypes.bool,
  getNewVirtualKey: PropTypes.func
}

AddRow.defaultProps = {
  templateId: null,
  columns: [],
  onCreateNewItem: (newItem) => newItem,
  onAfterCreateNewItem: () => {},
  loading: false,
  getNewVirtualKey: () => {}
}
