import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import CircleLabel from '/src/ui/core/fields/circle_label'
import I18n from '/src/utils/translations'

export default function ScaffoldingStatusLabel({ dataItem }) {
  const [statuses] = useStore(`scaffolding_statuses`)

  const status = dataItem && statuses && statuses.length !== 0 && statuses[dataItem.scaffolding_status_id]

  const labelBackgroundColor = () => {
    if (status.i18n_id === 'erected') return 'rounded-label-green'
    if (status.i18n_id === 'ready_to_dismantle') return 'rounded-label-yellow'
    if (status.i18n_id === 'dismantled') return 'rounded-label-red'
    return 'rounded-label-blue'
  }

  return !status || status === '' ? (
    ''
  ) : (
    <CircleLabel className={labelBackgroundColor()} value={I18n.t(`scaffoldings.statuses.${status.i18n_id}`)} />
  )
}

ScaffoldingStatusLabel.propTypes = {
  dataItem: PropTypes.object
}

ScaffoldingStatusLabel.defaultProps = {
  dataItem: {}
}
