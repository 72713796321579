import React from 'react'
import PropTypes from 'prop-types'
import { MdControlPoint, MdControlPointDuplicate } from 'react-icons/md'
import I18n from '/src/utils/translations.js'
import Modal from '/src/ui/core/popups/modal'
import ChooseButton from '/src/ui/core/buttons/choose_button'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/popups/choose_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function ChooseEstimateFormModal({ onClose, opts }) {
  const dispatchOpenFormModal = (packageable) => {
    opts.setModalEstimateService(false)
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      modelName: packageable ? opts.packageableModelName : opts.modelName,
      payload: {
        formType: 'new',
        opts: {
          scopeId: opts.scope.id,
          templateId: opts.serviceTemplate.id,
          disciplineId: opts.serviceTemplate.discipline_id
        }
      }
    })
  }

  return (
    <div className="confirmation-modal choose-modal">
      <Modal height={45} width={28} title={I18n.t('estimate_services.choose_modal.title')} onClose={onClose} closable>
        <div className="body">
          <ChooseButton
            onClick={() => dispatchOpenFormModal(false)}
            title={I18n.t('estimate_services.choose_modal.single')}
            subtitle={I18n.t('estimate_services.choose_modal.single_description')}
            icon={<MdControlPoint />}
          />
          <ChooseButton
            onClick={() => dispatchOpenFormModal(true)}
            title={I18n.t('estimate_services.choose_modal.package')}
            subtitle={I18n.t('estimate_services.choose_modal.package_description')}
            icon={<MdControlPointDuplicate />}
          />
        </div>
      </Modal>
    </div>
  )
}

ChooseEstimateFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opts: PropTypes.oneOfType([PropTypes.object]).isRequired
}
