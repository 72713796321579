import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdCallSplit } from 'react-icons/md'
import { useQueryParams, NumberParam } from 'use-query-params'
import PropTypes from 'prop-types'
import useRequestsPinned from '/src/hooks/requests_pinned'

export default function SpliterClusterIcon({ selectedItem }) {
  const [queryParams] = useQueryParams({ eav_template_id: NumberParam })
  const { requestId } = useRequestsPinned()
  const [visible, setVisible] = useState(false)
  const url = useParams()

  useEffect(() => {
    if (url.collection === 'clusters' && selectedItem && selectedItem.label) return setVisible(true)

    setVisible(false)
  }, [selectedItem])

  const eavTemplateIdParam = `eav_template_id=${queryParams.eav_template_id}`
  const labelParam = `label=${selectedItem.label}`

  return visible ? (
    <Link
      id="split-request"
      to={`/requests/${requestId}/sub_requests?${eavTemplateIdParam}&${labelParam}`}
      className="btn-icon"
    >
      <MdCallSplit />
    </Link>
  ) : null
}

SpliterClusterIcon.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
