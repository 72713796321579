import React from 'react'
import { Upload as KendoUpload } from '@progress/kendo-react-upload'
import PropTypes from 'prop-types'

export default function Upload({ settings }) {
  const {
    id,
    onPaste,
    className,
    klass,
    multiple,
    autoUpload,
    defaultFiles,
    onFiles,
    saveUrl,
    removeUrl,
    removeMethod,
    saveField,
    batch,
    withCredentials,
    onAdd,
    onRemove,
    onProgress,
    onStatusChange,
    onBeforeUpload,
    onBeforeRemove,
    restrictions
  } = settings

  const valueOrStandard = (value, standard) => {
    return value === undefined ? standard : value
  }

  return (
    <div onPaste={onPaste}>
      <KendoUpload
        id={id}
        className={valueOrStandard(className, 'file-area')}
        class={valueOrStandard(klass, '')}
        multiple={valueOrStandard(multiple, true)}
        autoUpload={valueOrStandard(autoUpload, false)}
        defaultFiles={defaultFiles} // controlled/uncontrolled modes (https://www.telerik.com/kendo-react-ui/components/upload/modes/)
        files={valueOrStandard(onFiles && onFiles(), [])}
        saveUrl={valueOrStandard(saveUrl, '')}
        removeUrl={valueOrStandard(removeUrl, '')}
        removeMethod={valueOrStandard(removeMethod, 'DELETE')}
        saveField={valueOrStandard(saveField, 'file')}
        batch={valueOrStandard(batch, false)}
        withCredentials={valueOrStandard(withCredentials, false)}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        onBeforeUpload={onBeforeUpload}
        onBeforeRemove={onBeforeRemove}
        restrictions={valueOrStandard(restrictions, {})}
      />
    </div>
  )
}

Upload.propTypes = {
  settings: PropTypes.shape({
    id: PropTypes.string,
    onPaste: PropTypes.func,
    className: PropTypes.string,
    klass: PropTypes.string,
    multiple: PropTypes.bool,
    autoUpload: PropTypes.bool,
    defaultFiles: PropTypes.array,
    onFiles: PropTypes.func,
    saveUrl: PropTypes.string,
    removeUrl: PropTypes.string,
    removeMethod: PropTypes.string,
    saveField: PropTypes.string,
    batch: PropTypes.bool,
    withCredentials: PropTypes.bool,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    onProgress: PropTypes.func,
    onStatusChange: PropTypes.func,
    onBeforeUpload: PropTypes.func,
    onBeforeRemove: PropTypes.func,
    restrictions: PropTypes.oneOfType([PropTypes.object])
  }).isRequired
}
