import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFetch from '/src/hooks/api/fetch'
import useTranslations from '/src/hooks/api/translations'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import {
  cancelMenuItem,
  deleteMenuItem,
  historyMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  editModalMenuItem,
  duplicateModalMenuItem,
  createInspectionModalMenuItem,
  generateWorkPackage,
  completeServices,
  printListMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useConfigurePrintPDF from '/src/ui/core/popups/configure_print_pdf'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import { canCreateProgress } from '/src/utils/constants/request'
import I18n from '/src/utils/translations.js'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import NewButton from '/src/ui/core/icons/new_button'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import EmptyStateButton from '/src/ui/core/layouts/empty_state_button'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import ProgressModel, { isDone } from '/src/models/progress'
import ProgressesSidePanel from '/src/ui/domain/progresses/progresses_side_panel'
import useRowRenderPerformanceGrids from '/src/ui/domain/progresses/row_render_performance_grids'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import useConfigureWorkPackageFile from '/src/ui/domain/requests/popups/configure_work_package_file'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

// eslint-disable-next-line max-lines-per-function
export default function useProgresses({
  request,
  handleCloseSidePanel,
  setShowNewButton,
  progressSelectedItem,
  onClick,
  showConfirmation,
  setInEditMode,
  onGridColumns,
  onGridDataSource,
  selectFiltering,
  inspectionModelParamName,
  progressSidePanelDataItem,
  setProgressSections
}) {
  const { fetch } = useFetch()
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [progressSectionsColumns, setProgressSectionsColumns] = useState([])
  const [panelDescription, setPanelDescription] = useState({})
  const [sidePanelFields, setSidePanelFields] = useState({})
  const [progressTemplate, setProgressTemplate] = useState()
  const [reqStatuses] = useStore('request_statuses')
  let templateId = useQueryParamWithPrevious('eav_template_id')
  const [subproject] = useStore('subproject')
  const [progressStatuses] = useStore('progress_statuses')
  const [page] = useStore(`progress_grid_page`)
  const history = useHistory()
  const progressModel = new ProgressModel()
  const { filter, gridTitle, labels } = useRequestGridFilter({
    route: progressModel.url,
    templateId
  })

  const { fetchAPI, status, responseData } = useFetchAPI('eav_sections')
  const loadingTranslations = useTranslations()
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()
  const onRowRender = useRowRenderPerformanceGrids('progress')

  const [openWorkPackagePopup, printWorkPackagePopup] = useConfigureWorkPackageFile()

  const [openConfigurationPopup, printGridPopup, isPrintable, printList] = useConfigurePrintPDF(
    gridColumns,
    gridDataSource,
    progressModel
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(templateId)

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (loadingTranslations || status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setProgressSectionsColumns(newSectionsColumns)
    setProgressSections(newSectionsColumns)

    const eavColumns = responseData.data[0].map((section) => section.eav_columns).flat()
    const descriptionColumn = filterFirstFlexibleColumn(eavColumns)

    if (!descriptionColumn) {
      setPanelDescription({})
      return
    }

    setPanelDescription(descriptionColumn.description)
  }, [status, responseData, loadingTranslations])

  useEffect(() => {
    if (!progressSelectedItem) return

    if (progressSelectedItem.eav_template_id !== templateId) templateId = progressSelectedItem.templateId
    setSidePanelFields(() => ({ description: progressSelectedItem[panelDescription] }))
  }, [progressSelectedItem, panelDescription])

  useEffect(() => {
    if (!Object.keys(reqStatuses).length) return
    setShowNewButton(!request || canCreateProgress(reqStatuses[request.request_status_id].i18n_id))
  }, [request, reqStatuses])

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          id: templateId,
          template_type: 'progress'
        }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data }) => {
        setProgressTemplate(data.data[0])
      }
    })
  }, [fetch, templateId])

  const progressStatusesArray = progressStatuses ? Object.values(progressStatuses) : []
  const pendingStatus = progressStatusesArray.find((e) => e.i18n_id === 'pending')
  const canceledStatus = progressStatusesArray.find((e) => e.i18n_id === 'canceled')
  const progressStatus = progressStatusesArray.find((e) => e.i18n_id === 'in_progress')

  const onSetGridColumns = (columns) => {
    setGridColumns(columns)
    if (onGridColumns) onGridColumns(columns)
  }

  const onSetGridDataSource = (dataSource) => {
    setGridDataSource(dataSource)
    if (onGridDataSource) onGridDataSource(dataSource)
  }

  const historyVisibility = (dataItem) => {
    if (canceledStatus && subproject) {
      return (
        dataItem.progress_status_id !== canceledStatus.id &&
        dataItem.quantity > 0 &&
        subproject.progress_type === 'progress'
      )
    }
  }

  const editVisibility = (dataItem) => {
    if (pendingStatus && progressStatus)
      return [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
  }

  const deleteVisibility = (dataItem) => {
    if (pendingStatus && progressStatus) {
      return dataItem.scope_id === null && [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
    }
  }

  const cancelVisibility = (dataItem) => {
    if (pendingStatus && progressStatus) {
      return dataItem.scope_id !== null && [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
    }
  }

  const historiesDataParams = (dataItem) => {
    const params = []
    params.push(`select_item_id=${dataItem.id}`)
    return params.join('&')
  }

  const onClickGenerateWorkPackage = (_, dataItem) =>
    openWorkPackagePopup({
      ...dataItem?.request,
      id: dataItem?.request_id
    })

  const progressesMenuItems = {
    history: historyMenuItem(history, progressModel.url, (dataItem) => historyVisibility(dataItem), {
      dataParams: historiesDataParams
    }),
    edit: editModalMenuItem(
      (dataItem) => editVisibility(dataItem),
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: progressModel.paramName,
          payload: { dataItem, formType: 'edit' }
        })
      }
    ),
    duplicate: duplicateModalMenuItem(
      (dataItem) => editVisibility(dataItem),
      (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: progressModel.paramName,
          payload: { dataItem, formType: 'duplicate' }
        })
      }
    ),
    create_inspection: createInspectionModalMenuItem(
      () => true,
      (e, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_MODAL,
          triggeredModelName: inspectionModelParamName,
          payload: { dataItem }
        })
    ),
    cancel: cancelMenuItem(progressModel, showConfirmation, (dataItem) => cancelVisibility(dataItem)),
    remove: deleteMenuItem(history, progressModel, showConfirmation, handleCloseSidePanel, (dataItem) =>
      deleteVisibility(dataItem)
    ),
    generate_work_package: generateWorkPackage(onClickGenerateWorkPackage),
    complete_services: completeServices(showConfirmation, (dataItem) => {
      if (subproject.progress_type === 'progress' || isDone(dataItem, progressStatuses)) return false
      return true
    })
  }

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    printListMenuItem(printList, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick)
  ]

  const batchAssignerIcon = {
    type: 'batch_assigner',
    visibility: (records) => {
      return records.every((record) => {
        return editVisibility(record)
      })
    }
  }

  const icons = [
    batchAssignerIcon,
    <InspectionsIcon key="inspections-icon" modelName={progressModel?.paramName} />,
    <VerticalDivider />,
    <BulkEditingIcon onClick={() => setInEditMode('top')} />,
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton modelName={progressModel.paramName} />
  ]

  if (templateId) translateFormulaServiceColumns(progressModel, templateId)

  const emptyStateButton = <EmptyStateButton path="/requests" label={I18n.t('requests.title')} />

  return [
    () => (
      <React.Fragment>
        {printWorkPackagePopup}
        {printGridPopup}
        {clearTemplatePreferencesPopup()}
        <TemplatableGrid
          contextMenuItems={Object.values(progressesMenuItems)}
          model={progressModel}
          labels={labels}
          filter={filter}
          gridTitle={gridTitle}
          icons={icons}
          selectedItem={progressSelectedItem}
          selectFiltering={selectFiltering}
          onRowClick={onClick}
          onGridColumns={onSetGridColumns}
          onDataSource={onSetGridDataSource}
          onRowRender={onRowRender}
          page={page}
          selecting
          emptyStateButton={emptyStateButton}
        />
      </React.Fragment>
    ),
    () => (
      <ProgressesSidePanel
        dataItem={progressSidePanelDataItem}
        sections={progressSectionsColumns}
        onClose={handleCloseSidePanel}
        contextMenuItems={progressesMenuItems}
        sidePanelFields={sidePanelFields}
        template={progressTemplate}
      />
    )
  ]
}
