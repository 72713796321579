import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ApplyPackage from '/src/ui/domain/estimate_services/apply_package'

// eslint-disable-next-line max-lines-per-function
export default function ThirdStep({ dataItem, templates }) {
  return (
    <React.Fragment>
      {Object.keys(templates).map((templateId) => (
        <ApplyPackage key={templateId} request={dataItem} template={templates[templateId]} />
      ))}
    </React.Fragment>
  )
}

ThirdStep.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  templates: PropTypes.oneOfType([PropTypes.object])
}

ThirdStep.defaultProps = {
  dataItem: {},
  templates: {}
}
