import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BusEvents from '/src/hooks/bus/bus_events'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import { sortArrayOfObjectsByString } from '/src/utils/object'
import { dispatch } from '/src/hooks/bus/bus'
import useFetch from '/src/hooks/api/fetch'
import useRequestsPinned from '/src/hooks/requests_pinned'
import EnvironmentSwitchList from '/src/ui/core/menus/environment_switch_list'
// eslint-disable-next-line max-lines-per-function
export default function SubprojectSwitch({ project, onChange, onBack, open }) {
  const [user] = useStore('user')
  const [subprojects, setSubprojects] = useState([])
  const [loading, setLoading] = useState(false)
  const { fetch } = useFetch()
  const { clearRequests } = useRequestsPinned()

  const handleSubprojectSelection = (item) => {
    const data = { user: { subproject_id: item.id } }
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: user.id,
      additionalResource: { path: 'store_preferences' },
      data
    }

    dispatch(BusEvents.SHOW_LOADING_DIALOG)
    fetch('users', params, {
      onSuccess: () => {
        dispatch(BusEvents.HIDE_DIALOG)
        clearRequests()
        onChange(item)
      }
    })
  }

  useEffect(() => {
    if (project.id && user) {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        resourceId: user.id,
        additionalResource: { path: 'subprojects' }
      }

      fetch('users', params, {
        onSuccess: ({ data }) => {
          setLoading(false)
          if (!data || !data.data || data.data.length === 0) {
            setSubprojects([])
            return
          }
          const filteredItems = data.data.filter((subp) => {
            return subp.project_id === project.id
          })
          setSubprojects(sortArrayOfObjectsByString(filteredItems, 'description'))
        }
      })
    }
  }, [project, user, fetch])

  if (!open) return <React.Fragment />

  return (
    <EnvironmentSwitchList items={subprojects} loading={loading} onChange={handleSubprojectSelection}>
      {onBack && (
        <button type="button" className="project-item back" onClick={() => onBack()}>
          <MdKeyboardArrowLeft />
          <span>{project.description}</span>
        </button>
      )}
    </EnvironmentSwitchList>
  )
}

SubprojectSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  project: PropTypes.oneOfType([PropTypes.object])
}

SubprojectSwitch.defaultProps = {
  onBack: null,
  project: {}
}
