import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { MdModeEdit } from 'react-icons/md'
import classNames from 'classnames'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetch from '/src/hooks/api/fetch'
import { GRID_FORM_MODAL_WIDTH } from '/src/models/concerns/form'
import ScopeModel from '/src/models/scope'
import Modal from '/src/ui/core/popups/modal'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import InputRichText from '/src/ui/core/inputs/input_rich_text'
import Button from '/src/ui/core/buttons/button'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import FormHeader from '/src/ui/core/forms/form_header'
import RichTextPreview from '/src/ui/domain/scopes/rich_text_preview'
import ActionButton from '/src/ui/core/buttons/action_button'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import '/src/static/css/core/rich_text/work_description_modal.css'

const { route: scopeRoute, paramName: scopeParamName } = new ScopeModel()

export default function WorkDescriptionModal({ dataItem }) {
  const { fetch } = useFetch()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isEdition, setIsEdition] = useState(false)

  const [scopeItem, setScopeItem] = useState()
  const [workDescription, setWorkDescription] = useState()

  useBus(BusEvents.OPEN_MODAL, ({ triggeredModelName }) => {
    if (!triggeredModelName || triggeredModelName !== 'work_description') return

    setIsModalOpened(true)
  })

  const onClose = () => {
    setIsModalOpened(false)
    setIsEdition(false)
    setScopeItem()
    setWorkDescription()
  }

  const onEdit = () => {
    setIsEdition(true)

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: dataItem && dataItem.id } }
    }
    fetch(scopeRoute, queryParams, {
      onSuccess: ({ data }) => {
        const scope = data.data['0']
        setWorkDescription(scope.work_description)
        setScopeItem(scope)
      }
    })
  }

  const onSaveRichText = () => {
    const queryParams = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: scopeItem.id,
      data: { [scopeParamName]: { ...scopeItem, work_description: workDescription } }
    }

    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    fetch(scopeRoute, queryParams, {
      onSuccess: () => {
        notifySuccess(I18n.t('scopes.success_work_description_edition'))

        dispatch(BusEvents.HIDE_DIALOG)
        dispatch(BusEvents.RELOAD_GRID)

        onClose()
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }

  const modalTitle = isEdition ? I18n.t('scopes.edit_work_description') : I18n.t('scopes.work_description')
  const modalLabel = isEdition ? undefined : `${dataItem?.request.reason} - ${dataItem?.request.discipline}`
  const modalBadge = useMemo(
    () =>
      isEdition ? undefined : (
        <div className="summary-item" style={{ fontSize: '1rem' }}>
          {`${I18n.t('scopes.item')} ${dataItem?.number}`}
        </div>
      ),
    [isEdition, dataItem]
  )

  return (
    isModalOpened && (
      <Modal
        title={<FormHeader title={modalTitle} label={modalLabel} badge={modalBadge} inline />}
        width={GRID_FORM_MODAL_WIDTH}
        className={classNames({
          'work-description-modal': true,
          'work-description-modal-edition': isEdition
        })}
        closable
        onClose={onClose}
        footer={
          isEdition ? (
            <React.Fragment>
              <CancelButton title={I18n.t('actions.cancel')} onClick={onClose} />
              {scopeItem && <ActionButton title={I18n.t('actions.save_exit')} onClick={onSaveRichText} />}
            </React.Fragment>
          ) : (
            <Button className="edit-button" text={I18n.t('actions.edit')} icon={<MdModeEdit />} onClick={onEdit} />
          )
        }
      >
        {isEdition &&
          (scopeItem ? (
            <InputRichText
              inputProps={{
                height: 440,
                value: workDescription,
                onChange: (_, newWorkDescription) => setWorkDescription(newWorkDescription)
              }}
            />
          ) : (
            <ThreeDotsLoader />
          ))}
        {!isEdition && <RichTextPreview>{dataItem.work_description}</RichTextPreview>}
      </Modal>
    )
  )
}

WorkDescriptionModal.propTypes = {
  dataItem: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.number,
    work_description: PropTypes.string,
    request: PropTypes.shape({
      reason: PropTypes.string,
      discipline: PropTypes.string
    })
  }).isRequired
}
