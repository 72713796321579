import React from 'react'
import PropTypes from 'prop-types'
import DatasheetForm from '/src/ui/domain/datasheets/datasheet_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'

export default function DatasheetFormModal({ model, templateId }) {
  if (!templateId) return null

  return (
    <GridFormModal modelName={model.paramName}>
      <DatasheetForm templateId={templateId} />
    </GridFormModal>
  )
}

DatasheetFormModal.propTypes = {
  templateId: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired
}

DatasheetFormModal.defaultProps = {
  templateId: undefined
}
