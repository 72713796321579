import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EnvironmentSwitchItem from '/src/ui/core/menus/environment_switch_item'
import PopupSearch from '/src/ui/core/popups/popup_search'
import I18n from '/src/utils/translations'

export default function EnvironmentSwitchList({ items, loading, onChange, children, highlighted, icon, className }) {
  const [search, setSearch] = useState()

  const filterItems = () => {
    if (!search) return items
    return items.filter((item) => item.description.toLowerCase().includes(search.toLowerCase()))
  }

  const handleOptions = () => {
    if (loading) return <span className="list-info">{I18n.t('search.loading')}</span>

    if (items.length === 0) return <span className="list-info">{I18n.t('search.no_result_found')}</span>

    return filterItems().map((item) => (
      <EnvironmentSwitchItem
        highlight={item.id === highlighted}
        item={item}
        key={item.id}
        onChange={onChange}
        icon={icon}
      />
    ))
  }

  const onSearchChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <div data-testid="environment-popup-body" className={`project-switch-list-container ${className}`}>
      <PopupSearch onChange={onSearchChange} />
      {children}
      <div className="project-list">{handleOptions()}</div>
    </div>
  )
}

EnvironmentSwitchList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  highlighted: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  className: PropTypes.string
}

EnvironmentSwitchList.defaultProps = {
  items: null,
  highlighted: null,
  icon: undefined,
  children: undefined,
  className: ''
}
