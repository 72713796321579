import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import Section from '/src/ui/core/layouts/section'
import FieldsGroup from '/src/ui/core/layouts/fields_group'
import { isSectionVisible } from '/src/models/concerns/eav_section'
import '/src/static/css/core/layouts/sectioned_panel.css'

const sectionIndex = (section, startIndex, mapIndex) => {
  if (startIndex) {
    return (section.index || 0) + startIndex + mapIndex + 1
  }
  return section.index || mapIndex + 1
}

export default function SectionedPanel({
  sections,
  enumerable,
  hideable,
  showBorder,
  renderColumn,
  sectionable,
  display,
  startIndex
}) {
  const [sectionComponents, setSectionComponents] = useState(<ThreeDotsLoader />)

  const renderSection = (section, index) => {
    const columns = section.eav_columns || section.columns
    const body = <FieldsGroup columns={columns} renderColumn={renderColumn} key={section.id} />

    return (
      <Section
        index={sectionIndex(section, startIndex, index)}
        title={section.title}
        finished={section.finished}
        body={body}
        enumerable={enumerable}
        hideable={hideable}
        sectionable={sectionable}
        showBorder={showBorder}
        display
        id={section.id}
        key={section.id}
      />
    )
  }

  useEffect(() => {
    if (sections.length === 0) return

    const sortedSections = sections.sort((sec1, sec2) => sec1.position - sec2.position)
    const visibleSections = sortedSections.filter((section) => {
      const { eav_columns, columns } = section
      const shouldDisplay = display || isSectionVisible(eav_columns || columns)

      dispatch({
        type: BusEvents.FORM_SECTION_DISPLAY_UPDATE,
        payload: { ...section, shouldDisplay }
      })

      return shouldDisplay
    })

    setSectionComponents(visibleSections.map(renderSection))
  }, [sections, display])

  return <React.Fragment>{sectionComponents}</React.Fragment>
}

SectionedPanel.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string
    })
  ).isRequired,
  enumerable: PropTypes.bool,
  hideable: PropTypes.bool,
  sectionable: PropTypes.bool,
  renderColumn: PropTypes.func,
  display: PropTypes.bool,
  startIndex: PropTypes.number,
  showBorder: PropTypes.bool
}

SectionedPanel.defaultProps = {
  sectionable: true,
  enumerable: true,
  hideable: true,
  showBorder: true,
  display: undefined,
  startIndex: undefined
}
