import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdKeyboardArrowRight } from 'react-icons/md'
import EnvironmentSwitchList from '/src/ui/core/menus/environment_switch_list'
import useFetch from '/src/hooks/api/fetch'
import { sortArrayOfObjectsByString } from '/src/utils/object'

// eslint-disable-next-line max-lines-per-function
export default function ProjectSwitch({ project, onChange, open }) {
  const [user] = useStore('user')
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const { fetch } = useFetch()

  useEffect(() => {
    if (!user) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      resourceId: user.id,
      additionalResource: { path: 'projects' }
    }

    fetch('users', params, {
      onSuccess: ({ data }) => {
        setLoading(false)
        if (!data || !data.data || data.data.length === 0) {
          setProjects([])
          return
        }

        sortArrayOfObjectsByString(data.data, 'description')
        data.data.sort((a, b) => {
          if (a.id === project.id) return -1
          if (b.id === project.id) return 1
          return 0
        })
        setProjects(data.data)
      }
    })
  }, [user, fetch, project])

  return (
    <EnvironmentSwitchList
      items={projects}
      className={open ? '' : 'no-display'}
      loading={loading}
      onChange={onChange}
      highlighted={project.id}
      icon={
        <span className="project-switch-icon">
          <MdKeyboardArrowRight />
        </span>
      }
    />
  )
}

ProjectSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  project: PropTypes.oneOfType([PropTypes.object])
}

ProjectSwitch.defaultProps = {
  project: {}
}
