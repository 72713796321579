import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/lists/titled_hash_list.css'

export default function TitledHashList({ title, hashValues }) {
  return (
    <div className="titled-hash-list">
      <div className="title">{title}</div>
      {Object.keys(hashValues).map((key) => (
        <div className="values" key={key}>
          <div className="description">{key}</div>
          <div className="value">{hashValues[key]}</div>
        </div>
      ))}
    </div>
  )
}

TitledHashList.propTypes = {
  title: PropTypes.string.isRequired,
  hashValues: PropTypes.oneOfType([PropTypes.object]).isRequired
}
