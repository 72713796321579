import React, { useCallback, useState } from 'react'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import {
  onFilterStepsValues,
  dataItemStepsValues,
  removeDuplicatedFilters,
  templateTitles
} from '/src/models/concerns/datasheet_filter'
import useDatasheetFiltersByTag from '/src/ui/core/grid/editable/datasheet_filters_by_tag'
import { applyItemChange } from '/src/ui/core/grid/editable/data_appliers'

export default function useBulkDatasheetFilters({
  columns,
  dataSource,
  newDataSource,
  setNewDataSource,
  onItemUpdate,
  onDataSourceEdition,
  getDataItemKey,
  datasheetFilters,
  multipleDatasheetFilters,
  datasheetTemplates,
  datasheetColumns
}) {
  const [isOpened, setIsOpened] = useState(false)
  const [dataItemInEdit, setDataItemInEdit] = useState()
  const [filterSteps, setFilterSteps] = useState()
  const [filterValuesByTag, setFilterValuesByTag] = useState({})

  const filtersByTag = useDatasheetFiltersByTag({
    columns,
    datasheetFilters,
    multipleDatasheetFilters,
    datasheetTemplates,
    datasheetColumns
  })

  const onChangeDatasheetFilter = useCallback(
    (dataItem, description, value) => {
      const formattedValue = Array.isArray(value) ? JSON.stringify(value) : value

      const event = { dataItem, field: description, value: formattedValue }
      const gridState = {
        dataSource,
        newDataSource,
        setNewDataSource: (nextDataSource) => {
          setNewDataSource(nextDataSource)
          onDataSourceEdition(nextDataSource)
        }
      }

      applyItemChange(event, gridState, getDataItemKey)

      onItemUpdate(dataItem)
    },
    [dataSource, newDataSource, setNewDataSource, onDataSourceEdition, getDataItemKey]
  )

  const openFiltersModal = useCallback(
    (dataItem, column) => {
      if (!datasheetFilters || !multipleDatasheetFilters || !filtersByTag) return

      const datasheet = datasheetFilters[column.id] || multipleDatasheetFilters[column.id]
      if (!datasheet) return

      const key = column.metadata
      setFilterSteps(filtersByTag[key])
      setDataItemInEdit(dataItem)
      setIsOpened(true)
    },
    [filtersByTag, datasheetFilters, multipleDatasheetFilters]
  )

  const onDone = useCallback(
    (values) => {
      onFilterStepsValues(
        filterSteps.filters.map((step) => ({
          ...step,
          value: values[step.filterAttribute]
        })),
        (description, value) => onChangeDatasheetFilter(dataItemInEdit, description, value),
        (formattedValues) =>
          setFilterValuesByTag((prevValuesByTag) => {
            const prevTagValues = prevValuesByTag[filterSteps.id] || {}
            const itemKey = getDataItemKey(dataItemInEdit)
            const nextTagValues = { ...prevTagValues, [dataItemInEdit[itemKey]]: formattedValues }
            return {
              ...prevValuesByTag,
              [filterSteps.id]: nextTagValues
            }
          })
      )

      setIsOpened(false)
    },
    [filterSteps, onChangeDatasheetFilter, dataItemInEdit, setFilterValuesByTag, getDataItemKey]
  )

  const getSavedItemValues = () => {
    const itemKey = getDataItemKey(dataItemInEdit)
    const itemId = dataItemInEdit[itemKey]
    return filterValuesByTag[filterSteps.id] && filterValuesByTag[filterSteps.id][itemId]
  }

  const hasFilterSteps = filterSteps && filterSteps.filters.length > 0

  const filterStepsModal = isOpened && hasFilterSteps && dataItemInEdit && (
    <FilterStepsModal
      filters={removeDuplicatedFilters(filterSteps.filters)}
      route="datasheets"
      resultTitle={templateTitles(filterSteps.templates)}
      tag={filterSteps.tag}
      onClose={() => setIsOpened(false)}
      currentValues={getSavedItemValues() || dataItemStepsValues(dataItemInEdit, filterSteps.filters)}
      onDone={onDone}
    />
  )

  return [filterStepsModal, openFiltersModal]
}
