import React from 'react'
import PropTypes from 'prop-types'
import ColoredProgressBar from '/src/ui/core/progress_bars/colored_progress_bar'
import { isPresent, isBlankOrZero } from '/src/utils/boolean_refinements'
import '/src/static/css/core/grid/cells.css'

export default function ProgressColumn({ data, summaryKey }) {
  const { [summaryKey]: summary } = data
  let progress = 0

  if (isPresent(summary) && !isBlankOrZero(summary.actual_progress)) progress = summary.actual_progress

  return (
    <span className="progress-column">
      <ColoredProgressBar progress={progress} />
    </span>
  )
}

ProgressColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  summaryKey: PropTypes.string.isRequired
}

ProgressColumn.defaultProps = {
  data: {}
}
