// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class CreationInspectionModel {
  constructor() {
    this.paramName = CreationInspectionModel.paramName
  }

  static paramName = 'inspection'

  name = I18n.t('inspections.title')

  singularName = I18n.t('inspections.description')

  templateType = 'inspection'

  route = 'inspections'

  flexible = true

  columns = [
    {
      id: 1,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 1,
      type: 'module_filter'
    },
    {
      id: 2,
      description: 'inspect_date',
      orderOnForm: 2,
      title: I18n.t('inspections.inspect_date'),
      type: 'date'
    },
    {
      id: 3,
      description: 'responsible',
      field: 'responsible.full_name',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      required: true,
      orderOnForm: 3,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: {
        where: { is_inspection_responsible: 1 }
      },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
    {
      id: 4,
      description: 'eav_template_id',
      hideOnForm: true,
      type: 'integer'
    },
    {
      id: 5,
      description: 'request_id',
      virtualField: true,
      hideOnForm: true,
      type: 'integer'
    },
    {
      id: 6,
      description: 'inspected_id',
      virtualField: true,
      hideOnForm: true,
      type: 'integer'
    }
  ]
}
