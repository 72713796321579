import React from 'react'
import { columnType } from '/src/models/concerns/eav_column'

export const onFilterStepsValues = (filterSteps, onChange, onValues) => {
  let newCurrentValues = {}
  filterSteps.forEach((f) => {
    let val = f.value
    if (!val) {
      onChange(f.description, val)
      return
    }

    if (!f.multiple && Array.isArray(val)) val = f.value[0]
    let currentValue = val

    if (f.multiple && Array.isArray(val)) {
      val = val.map((v) => (v && v.computed_text_field) || v)
      currentValue = val
    } else if (f.multiple && typeof val === 'string') {
      val = JSON.parse(val)
      currentValue = val.map((v) => ({ [f.filterAttribute]: v, computed_text_field: v }))
    }

    onChange(f.description, val)
    newCurrentValues = { ...newCurrentValues, [f.filterAttribute]: currentValue }
  })

  onValues(newCurrentValues)
}

export const allFiltersAvailable = ({
  filteredColumns,
  datasheetFilters,
  multipleDatasheetFilters,
  datasheetColumns
}) => {
  const hasAllFetched = filteredColumns.filter(({ id }) => {
    const datasheet = datasheetFilters[id] || multipleDatasheetFilters[id]
    return datasheet && datasheetColumns[datasheet.datasheet_column_id]
  })

  return filteredColumns.length === hasAllFetched.length
}

export const formatFilterColumns = ({
  filteredColumns,
  datasheetFilters,
  multipleDatasheetFilters,
  datasheetColumns
}) => {
  return filteredColumns.map((column) => {
    const datasheet = datasheetFilters[column.id] || multipleDatasheetFilters[column.id]
    const datasheetColumn = datasheetColumns[datasheet.datasheet_column_id]
    return {
      ...column,
      datasheet,
      multiple: columnType(column) === 'multiple_datasheet_filter',
      filterAttribute: datasheetColumn.description,
      query: { where: { eav_template_id: datasheet.datasheet_template_id } }
    }
  })
}

export const groupFilterColumnsByTag = ({
  formattedColumns,
  onChangeDatasheetFilter,
  datasheetTemplates,
  dataItem
}) => {
  return formattedColumns.reduce((filtersByTag, column) => {
    if (!column.datasheet) return filtersByTag

    const datasheetTemplateId = column.datasheet.datasheet_template_id
    const key = column.metadata

    const groupedTag = filtersByTag[key] || {
      id: key,
      filters: [],
      onChange: onChangeDatasheetFilter,
      templates: [],
      tag: column.metadata,
      type: 'datasheet_filter'
    }

    if (!groupedTag.templates.includes(datasheetTemplates[datasheetTemplateId]))
      groupedTag.templates.push(datasheetTemplates[datasheetTemplateId])

    const filterColumn = column
    if (dataItem && dataItem[column.description]) filterColumn.value = dataItem[column.description]

    const filters = [...groupedTag.filters, filterColumn]

    return {
      ...filtersByTag,
      [key]: {
        ...groupedTag,
        filters,
        read_only: filters[0].read_only,
        initialVisibleOnWeb: filters[0].initialVisibleOnWeb
      }
    }
  }, {})
}

export const dataItemStepsValues = (dataItem, filters) => {
  if (!dataItem || !filters) return {}
  return filters.reduce((dataItemValues, { description, filterAttribute }) => {
    const value = dataItem[description]
    return {
      ...dataItemValues,
      [filterAttribute]: typeof value === 'string' && value.startsWith('[') ? JSON.parse(value) : value
    }
  }, {})
}

export const removeDuplicatedFilters = (stepFilters) => {
  const uniqueDescriptions = new Set()
  const filteredStepFilters = []

  if (stepFilters.some((filter) => !filter?.datasheet?.datasheet_column?.description)) return stepFilters

  for (const filter of stepFilters) {
    const description = filter.datasheet.datasheet_column.description
    if (uniqueDescriptions.has(description)) continue

    uniqueDescriptions.add(description)
    filteredStepFilters.push(filter)
  }

  return filteredStepFilters
}

export const templateTitles = (templates) => {
  return templates.map((item, index) => (
    <React.Fragment key={index}>
      {item.title}
      {index < templates.length - 1 && <br />}
    </React.Fragment>
  ))
}
