import React from 'react'
import PropTypes from 'prop-types'

export default function ChooseButton({ title, subtitle, icon, onClick }) {
  return (
    <div onClick={onClick} className="choose-button">
      <div className="icon">{icon}</div>
      <div className="text">
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}

ChooseButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func
}

ChooseButton.defaultProps = {
  onClick: null
}
