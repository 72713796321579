import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl'
import { languages } from '/src/ui/core/menus/language_switch.jsx'
import I18n from '/src/utils/translations.js'
import weekData from 'cldr-core/supplemental/weekData.json'
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json'
import currencyData from 'cldr-core/supplemental/currencyData.json'
import esDateFields from 'cldr-dates-full/main/es/dateFields.json'
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json'
import deDateFields from 'cldr-dates-full/main/de/dateFields.json'
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json'
import ptDateFields from 'cldr-dates-full/main/pt/dateFields.json'
import zhDateFields from 'cldr-dates-full/main/zh/dateFields.json'
import thDateFields from 'cldr-dates-full/main/th/dateFields.json'
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json'
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json'
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json'
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json'
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json'
import zhCaGregorian from 'cldr-dates-full/main/zh/ca-gregorian.json'
import thCaGregorian from 'cldr-dates-full/main/th/ca-gregorian.json'
import esTimeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json'
import frTimeZoneNames from 'cldr-dates-full/main/fr/timeZoneNames.json'
import deTimeZoneNames from 'cldr-dates-full/main/de/timeZoneNames.json'
import nbTimeZoneNames from 'cldr-dates-full/main/nb/timeZoneNames.json'
import ptTimeZoneNames from 'cldr-dates-full/main/pt/timeZoneNames.json'
import zhTimeZoneNames from 'cldr-dates-full/main/zh/timeZoneNames.json'
import thTimeZoneNames from 'cldr-dates-full/main/th/timeZoneNames.json'
import esNumbers from 'cldr-numbers-full/main/es/numbers.json'
import frNumbers from 'cldr-numbers-full/main/fr/numbers.json'
import deNumbers from 'cldr-numbers-full/main/de/numbers.json'
import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json'
import ptNumbers from 'cldr-numbers-full/main/pt/numbers.json'
import zhNumbers from 'cldr-numbers-full/main/zh/numbers.json'
import thNumbers from 'cldr-numbers-full/main/zh/numbers.json'

load(
  likelySubtags,
  currencyData,
  weekData,
  esDateFields,
  nbDateFields,
  frDateFields,
  deDateFields,
  ptDateFields,
  zhDateFields,
  thDateFields,
  esCaGregorian,
  nbCaGregorian,
  frCaGregorian,
  deCaGregorian,
  ptCaGregorian,
  zhCaGregorian,
  thCaGregorian,
  esTimeZoneNames,
  nbTimeZoneNames,
  frTimeZoneNames,
  deTimeZoneNames,
  ptTimeZoneNames,
  zhTimeZoneNames,
  thTimeZoneNames,
  esNumbers,
  nbNumbers,
  frNumbers,
  deNumbers,
  ptNumbers,
  zhNumbers,
  thNumbers
)

const uploadLocale = (locale) => I18n.translations[locale].kendo_translations

Object.keys(languages).forEach((key) => loadMessages(uploadLocale(key), key))

export default function TranslationLoader({ children }) {
  const [locale] = useStore('language')

  const getLocale = () => {
    if (!locale) return 'en'
    if (locale === 'pt-BR') return 'pt'
    return locale
  }

  return (
    <LocalizationProvider language={locale || 'en'}>
      <IntlProvider locale={getLocale()}>{children}</IntlProvider>
    </LocalizationProvider>
  )
}

TranslationLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node, PropTypes.array]).isRequired
}
