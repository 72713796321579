import React from 'react'
import PropTypes from 'prop-types'
import StringColumn from '/src/ui/core/grid/flexible_column/string'
import IntegerColumn from '/src/ui/core/grid/flexible_column/integer'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'
import PercentageColumn from '/src/ui/core/grid/flexible_column/percentage'
import LinkColumn from '/src/ui/core/fields/link'
import BooleanColumn from '/src/ui/core/fields/boolean'
import DateColumn from '/src/ui/core/grid/flexible_column/date'
import DateTimeColumn from '/src/ui/core/grid/flexible_column/date_time'
import DropFormulaResultColumn from '/src/ui/core/grid/flexible_column/drop_formula'
import DropFormulaColumn from '/src/ui/core/grid/flexible_column/drop_formula_id'
import DropFormulaVariablesColumn from '/src/ui/core/grid/flexible_column/drop_formula_variables'
import MultipleDropColumn from '/src/ui/core/grid/flexible_column/multiple_drop'
import MatrixDimensionColumn from '/src/ui/core/grid/flexible_column/matrix_dimension'
import MatrixResultColumn from '/src/ui/core/grid/flexible_column/matrix'
import DatasheetFilterColumn from '/src/ui/core/grid/flexible_column/datasheet_filter'
import DatasheetResultColumn from '/src/ui/core/grid/flexible_column/datasheet_result'
import DatasheetAggregationColumn from '/src/ui/core/grid/flexible_column/datasheet_aggregation'
import GPSColumn from '/src/ui/core/grid/flexible_column/gps'
import LookupColumn from '/src/ui/core/grid/flexible_column/lookup'
import DropColumn from '/src/ui/core/grid/flexible_column/drop'
import FormulaColumn from '/src/ui/core/grid/flexible_column/formula'
import MultilineTextColumn from '/src/ui/core/fields/multiline_text'
import SignatureField from '/src/ui/core/fields/signature_field'
import AttachmentField from '/src/ui/core/fields/attachment_field'
import Picture from '/src/ui/core/fields/picture'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { MdComment } from 'react-icons/md'
import '/src/static/css/field_factory.css'

export default function FieldFactory({ value, type, opts }) {
  let child

  const getFormatType = () => {
    if (type === 'datasheet_filter' && typeof value === 'boolean') return 'boolean'
    return type
  }

  switch (getFormatType()) {
    case 'string':
    case 'text':
      child = <StringColumn value={value} />
      break
    case 'link':
      child = <LinkColumn value={value} />
      break
    case 'integer':
      child = <IntegerColumn value={value} />
      break
    case 'decimal':
    case 'numeric':
    case 'formula_service':
      child = <DecimalColumn value={value} />
      break
    case 'percentage':
      child = <PercentageColumn value={value} columnData={opts} />
      break
    case 'boolean':
      child = <BooleanColumn value={value} />
      break
    case 'date':
      child = <DateColumn value={value} />
      break
    case 'date_time':
      child = <DateTimeColumn value={value} />
      break
    case 'formula':
      const metadata = opts && opts.metadata
      const isInteger = metadata && JSON.parse(metadata).integer
      child = <FormulaColumn value={value} isInteger={isInteger} />
      break
    case 'drop_formula':
      child = <DropFormulaResultColumn value={value} />
      break
    case 'drop_formula_id':
      child = <DropFormulaColumn value={value} />
      break
    case 'drop_formula_variables':
      child = <DropFormulaVariablesColumn value={value} />
      break
    case 'drop':
      child = <DropColumn value={value} />
      break
    case 'multiple_drop':
      child = <MultipleDropColumn value={value} />
      break
    case 'gps':
      child = <GPSColumn value={value} />
      break
    case 'lookup':
      child = <LookupColumn value={value} />
      break
    case 'multiline_text':
      child = <MultilineTextColumn value={value} />
      break
    case 'matrix_dimension':
      child = <MatrixDimensionColumn value={value} />
      break
    case 'matrix':
      child = <MatrixResultColumn value={value} />
      break
    case 'datasheet_filter':
      child = <DatasheetFilterColumn value={value} />
      break
    case 'datasheet_result':
      child = <DatasheetResultColumn value={value} />
      break
    case 'datasheet_aggregation':
      child = <DatasheetAggregationColumn value={value} />
      break
    case 'fixed_picture':
      child = <Picture images={value} columnId={undefined} openModal={opts?.openModal} />
      break
    case 'picture':
      child = <Picture images={value} columnId={opts?.id} openModal={opts?.openModal} />
      break
    case 'signature':
      child = <SignatureField value={value} />
      break
    case 'attachment':
      child = <AttachmentField value={value} />
      break
    case 'rich_text':
      child = ''
      break
    default:
      child = value
  }

  const commentTooltip = opts.comment && (
    <CustomTooltip position="bottom" parentTitle>
      <span title={opts.comment}>
        <div className="field-comment-icon">
          <MdComment />
        </div>
      </span>
    </CustomTooltip>
  )

  const children = opts.comment && (
    <div className="field-value-group">
      <span className="field-value">{child}</span>
      {commentTooltip}
    </div>
  )

  return children || child
}

FieldFactory.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.object, PropTypes.bool]),
  opts: PropTypes.oneOfType([PropTypes.object])
}

FieldFactory.defaultProps = {
  type: undefined,
  value: null,
  opts: {}
}
