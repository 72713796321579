import { useEffect, useRef } from 'react'

export default function useCallbackRef(callback) {
  const ref = useRef(callback)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  return ref
}
