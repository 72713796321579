import React, { useCallback, useMemo, useState } from 'react'
import { isColumnPrintable } from '/src/models/concerns/grid'
import ConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file_modal'
import ConfigurePrintListFile from '/src/ui/core/popups/configure_print_list_file_modal'

export default function useConfigurePrintPDF(gridColumns, gridDataSource, model) {
  const [dialogToOpen, setDialogToOpen] = useState(null)

  const hasColumns = gridColumns && gridColumns.length > 0
  const hasItems = gridDataSource && gridDataSource.total > 0

  const isPrintable = hasColumns && hasItems

  const openPrintGridCofiguration = useCallback(() => setDialogToOpen('PRINT_GRID'), [])

  const openPrintListCofiguration = useCallback(() => setDialogToOpen('PRINT_LIST'), [])

  const printableColumns = useMemo(() => gridColumns && gridColumns.filter(isColumnPrintable), [gridColumns])

  const popupComponent = useMemo(() => {
    switch (dialogToOpen) {
      case 'PRINT_GRID':
        return (
          <ConfigurePrintGridFile
            model={model}
            columns={printableColumns}
            onClose={() => setDialogToOpen(null)}
          />
        )
      case 'PRINT_LIST':
        return (
          <ConfigurePrintListFile
            model={model}
            gridData={gridDataSource.data}
            onClose={() => setDialogToOpen(null)}
          />
        )
      default:
        return null
    }
  }, [dialogToOpen, model, printableColumns, gridDataSource])

  return [openPrintGridCofiguration, popupComponent, isPrintable, openPrintListCofiguration]
}
