/**
 * Get the first column that is either a string or a formula
 *
 * @param columns - Array of columns to search for the string/formula
 * @returns {firstColumn} - The first column found, which is either a string or a formula
 */
export default function getFirstStringOrFormula(columns) {
  if (!(Array.isArray(columns) && columns.length)) return

  const flexibleColumns = columns.filter((c) => c.column_type)
  const types = ['string', 'formula']
  const firstColumn = flexibleColumns.find((c) => types.includes(c.column_type.description))

  return firstColumn
}
