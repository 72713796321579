import { useCallback, useEffect, useRef } from 'react'
import { useStore } from 'react-context-hook'
import { isEqual, omit } from 'lodash'
import { indexify } from '/src/utils/array'
import useFetch from '/src/hooks/api/fetch'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

const RELATED_INSPECTIONS_LIMIT = 10

const sortTimelineByInspectDate = (timelineMap) => {
  return timelineMap
    .sort((timelineItemA, timelineItemB) => {
      if (!timelineItemA.inspect_date && !timelineItemB.inspect_date) {
        return new Date(timelineItemA.updated_at) - new Date(timelineItemB.updated_at)
      }
      if (!timelineItemA.inspect_date) return 1
      if (!timelineItemB.inspect_date) return -1
      return new Date(timelineItemA.inspect_date) - new Date(timelineItemB.inspect_date)
    })
    .reverse()
}

export default function useRelatedInspectionsUtils({
  dataItem,
  setShowLoading,
  setRelatedInspections,
  setIndexifiedTemplates,
  setIndexifiedSections,
  isInspectionsTab,
  setInspectionCategories
}) {
  const oldDataItem = useRef()
  const { fetch } = useFetch()

  const [allInspectionCategories] = useStore('inspection_categories')

  const fetchRelatedInspections = useCallback(async () => {
    const showSelf = isInspectionsTab
    const showTemplate = isInspectionsTab
    const getIndividualSections = isInspectionsTab

    const isDataItemTheSame = isEqual(omit(dataItem, 'row_selected'), omit(oldDataItem.current, 'row_selected'))

    if (!oldDataItem.current || (oldDataItem.current && !isDataItemTheSame)) {
      oldDataItem.current = dataItem
      setShowLoading(true)
    }

    const inspectionParams = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: {
        paging: { skip: 0, pageSize: RELATED_INSPECTIONS_LIMIT },
        sorting: [{ field: 'number', dir: 'desc' }]
      },
      query: {
        where: {
          ...(dataItem.inspected_type === 'Request' && { request_id: dataItem.request_id }),
          inspected_id: dataItem.inspected_id,
          inspected_type: dataItem.inspected_type,
          ...(dataItem.eav_template_id && { eav_template_id: dataItem.eav_template_id })
        },
        ...(!showSelf && {
          not: {
            id: dataItem.id
          }
        })
      }
    }

    let {
      data: { data: inspections }
    } = await fetch('inspections', inspectionParams)

    if (showTemplate) {
      if (dataItem.service_ids) {
        inspections = inspections.filter((inspection) =>
          inspection.inspected_services.find((service) => dataItem.service_ids.includes(service.serviceable_id))
        )
      }
      const templateIds = Array.from(new Set(inspections.map((inspection) => inspection.eav_template_id)))

      const templateParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: templateIds } }
      }

      const {
        data: { data: templates }
      } = await fetch('eav_templates', templateParams)
      const indexifiedTemplates = indexify(templates, 'id')
      setIndexifiedTemplates(indexifiedTemplates)

      const categoryIds = new Set(Object.values(indexifiedTemplates).map((template) => template.inspection_category_id))
      const inspectionCategories = Object.fromEntries(
        Object.entries(allInspectionCategories ?? {}).filter(([key]) => categoryIds.has(Number(key)))
      )
      setInspectionCategories(inspectionCategories)

      if (getIndividualSections) {
        const sectionsParams = {
          requestAction: 'READ',
          httpAction: 'get',
          additionalResource: { path: 'eav_columns' },
          query: { where: { eav_template_id: templateIds } }
        }

        const { data: sections } = await fetch('eav_sections', sectionsParams)
        const indexifiedSections = indexify(sections, 'eav_template_id')
        setIndexifiedSections(indexifiedSections)
      }
    }

    setRelatedInspections(sortTimelineByInspectDate(inspections))
    setShowLoading(false)
  }, [
    dataItem,
    fetch,
    setIndexifiedTemplates,
    setRelatedInspections,
    setShowLoading,
    isInspectionsTab,
    setIndexifiedSections
  ])

  useEffect(() => {
    fetchRelatedInspections()
  }, [fetchRelatedInspections])

  useBus(BusEvents.RELOAD_SIDE_PANEL, () => fetchRelatedInspections(), [fetchRelatedInspections])
}
