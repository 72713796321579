import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'
import { titleize } from 'inflected'
import I18n from '/src/utils/translations'
import '/src/static/css/core/menus/header.css'

const denyResources = [
  'requests',
  'scopes',
  'plannings',
  'performances',
  'inspections',
  'progress_services',
  'estimate_services',
  'scaffoldings',
  'datasheets'
]

const translate = (param) => {
  if (param === 'home' || !param) return I18n.t('home.home_title')
  let title = I18n.t(`${param}.title`)
  if (title.includes('[missing')) title = I18n.t(`actions.${param}`)
  if (title.includes('[missing')) title = titleize(param)
  return title
}

const translateShortcuts = (param) => {
  const title = I18n.t(`shortcuts.${param}`)
  if (title.includes('[missing')) return titleize(param)
  return title
}

const isHistoryPage = (url) => url.collection === 'history'

export default function Breadcrumb() {
  const [page] = useQueryParam('page', StringParam)
  const [template_type] = useQueryParam('template_type', StringParam)
  const [report_name] = useQueryParam('report_name', StringParam)
  const [path] = useQueryParam('path', StringParam)
  const url = useParams()

  let flow = []

  if (denyResources.includes(url.resource) || isHistoryPage(url)) {
    return null
  } else {
    path ? flow.push(translate(path)) : flow.push(translate(url.resource))
  }

  // Templates > Scoping
  template_type && flow.push(I18n.t(`templates.${template_type}`))

  // Reports > My Well-Grounded Report
  report_name && flow.push(report_name)

  if (page) flow = [translateShortcuts(page)]

  return (
    <div className="page-indicator">
      {flow.map((crumb) => (
        <div key={crumb} className="breadcrumb-item">
          {crumb}
        </div>
      ))}
    </div>
  )
}
