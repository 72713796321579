import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import EmptyState from '/src/ui/core/layouts/empty_state'
import useInspectedData from '/src/hooks/inspected_data'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import RequestsPageSidePanel from '/src/ui/domain/requests/requests_page_side_panel'
import ScopesSidePanel from '/src/ui/domain/scopes/scopes_side_panel'
import ProgressesSidePanel from '/src/ui/domain/progresses/progresses_side_panel'
import ScaffoldingsSidePanel from '/src/ui/domain/scaffoldings/scaffoldings_side_panel'
import '/src/static/css/domain/inspections/inspection_form.css'

export default function InspectedItem({ dataItem }) {
  const [moduleName, inspectedData, inspectedSections, isLoading] = useInspectedData(dataItem)

  const renderSidePanel = (module, data, sections) => {
    switch (module) {
      case 'request':
        return <RequestsPageSidePanel dataItem={data} sections={sections} hideSummaryButtons />
      case 'scope':
        return <ScopesSidePanel dataItem={data} sections={sections} onClose={() => {}} inspection={dataItem} />
      case 'progress':
        return <ProgressesSidePanel dataItem={data} sections={sections} onClose={() => {}} inspection={dataItem} />
      case 'scaffolding':
        return <ScaffoldingsSidePanel dataItem={data} onClose={() => {}} hideSummaryButtons />
      default:
        return null
    }
  }

  if (!isEmpty(dataItem) && isLoading)
    return (
      <div className="inspected-item">
        <ThreeDotsLoader />
      </div>
    )

  return (
    <div className="inspected-item">
      {inspectedData ? (
        renderSidePanel(moduleName, inspectedData, inspectedSections)
      ) : (
        <EmptyState
          imageSrc="workflow"
          modelNameLower={I18n.t('inspections.inspected_item').toLowerCase()}
          message={I18n.t('inspections.select_module_item')}
        />
      )}
    </div>
  )
}

InspectedItem.propTypes = {
  dataItem: PropTypes.shape({
    id: PropTypes.number
  })
}

InspectedItem.defaultProps = {
  dataItem: {}
}
