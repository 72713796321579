import React from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { sanitizeHTML } from '/src/utils/string'
import I18n from '/src/utils/translations'
import '/src/static/css/core/blocks/track_block.css'

export default function ScopesTrackRichText({ dataItem, template }) {
  const handleMoreDetailsClick = () => {
    dispatch({
      type: BusEvents.OPEN_MODAL,
      triggeredModelName: 'work_description'
    })
  }

  const sanitizedText = dataItem.work_description ? sanitizeHTML(dataItem.work_description) : ''

  return (
    <div className="reference-side-panel">
      {template && template.enable_rich_text && (
        <React.Fragment>
          <div className="summary-label">{I18n.t('scopes.work_description')}</div>
          <div className="summary-value limited-summary-value">
            {sanitizedText || I18n.t('scopes.preview_unavailable')}
          </div>
          {dataItem.work_description && (
            <button onClick={handleMoreDetailsClick} href="#" className="see-more-link">
              ...
              {I18n.t('scopes.more_details')}
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

ScopesTrackRichText.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  template: PropTypes.oneOfType([PropTypes.object])
}

ScopesTrackRichText.defaultProps = {
  dataItem: undefined,
  template: undefined
}
