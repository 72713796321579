import { isBlank } from '/src/utils/boolean_refinements'

export function setEstimateStatusFilter(state) {
  if (isBlank(state.estimate_status_filter)) {
    localStorage.removeItem('estimate_status_filter')
  } else {
    localStorage.setItem('estimate_status_filter', JSON.stringify(state.estimate_status_filter))
  }
}

export function getEstimateStatusFilter() {
  const estimateStatusFilter = localStorage.getItem('estimate_status_filter')
  return {
    estimate_status_filter: isBlank(estimateStatusFilter) ? [] : JSON.parse(estimateStatusFilter)
  }
}
