import { useState, useEffect } from 'react'
import useFetch from '/src/hooks/api/fetch'

/**
 * Fetches a contract_service with the latest price filled.  
 * Calls the route: `/api/v1/contract_services/latest_prices`.
 * @param {number|string} serviceId - Id of the service to be fetched.
 * @returns {ContractService} The fetched contract_service object.
 * @example
 * ```javascript
 * {
 *   id: N,
 *   description: 'Contract Service Description',
 *   ...
 *   latest_price: {
 *     materials_price: x,
 *     application_price: y,
 *     ...
 *     other_price: z
 *   }  
 * }
 * ```
 * 
 */
export default function useFetchPricedService(serviceId) {
  const [pricedService, setPricedService] = useState({})
  const { fetch } = useFetch()

  useEffect(() => {
    if (!serviceId) return

    const params = {
      query: { where: { id: serviceId } },
      additionalResource: { path: 'latest_prices' }
    }

    fetch('/contract_services', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        const newService = data[0]
        setPricedService(newService)
      }
    })
  }, [serviceId, fetch])

  return pricedService
}
