/* eslint-disable import/prefer-default-export */

// Returns the "model route" of a history object.
// Example: returns 'scopes' for a history object associated with the url '/scopes?eav_template=1'
export const extractRouteFromHistory = (history) => history.location.pathname.split('/')[1]

export const removeUrlParams = (url) => url.split('?')[0]

export const hasParams = (url) => url && url.includes('?')

export const buildBackURL = (config) => {
  const query = []

  if (config.eavTemplateId) query.push(`eav_template_id=${config.eavTemplateId}`)
  if (config.selectItemId) query.push(`select_item_id=${config.selectItemId}`)
  if (config.selectServiceId) query.push(`select_service_id=${config.selectServiceId}`)

  return query.join('&')
}
