import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export default function useUrlFilterInspections() {
  const history = useHistory()
  const [urlFilterArray, setUrlFilterArray] = useState([])

  const updateUrlFilterArray = useCallback(() => {
    const paramAllowList = new Set(['inspected_id', 'inspected_type', 'request_id'])
    const queryParams = new URLSearchParams(history.location.search)
    const updatedUrlArray = Array.from(queryParams.entries())
      .filter(([param]) => paramAllowList.has(param))
      .map(([param, paramValue]) => ({
        type: 'where',
        column: param,
        operator: 'eq',
        value: paramValue
      }))

    setUrlFilterArray(updatedUrlArray)
  }, [history])

  useEffect(() => {
    updateUrlFilterArray()
    const urlChangeDetector = history.listen(updateUrlFilterArray)
    return () => {
      urlChangeDetector()
    }
  }, [history, updateUrlFilterArray])

  return {
    urlFilterArray,
    updateUrlFilterArray
  }
}
