import React from 'react'
import PropTypes from 'prop-types'
import { MdLocationOn } from 'react-icons/md'
import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/refinements'
import '/src/static/css/gps.css'

export default function InputGPS({ inputProps }) {
  const { value } = inputProps

  let coordinates = I18n.t('form.inputs.gps.without_location')

  if (isPresent(value)) {
    const [latitude, longitude] = JSON.parse(value)
    coordinates = `${latitude} - ${longitude}`
  }

  return (
    <div className="gps-form-text">
      <span className="icon">
        <MdLocationOn />
      </span>
      <span className="gps-button-text">{coordinates}</span>
    </div>
  )
}

InputGPS.propTypes = {
  inputProps: PropTypes.shape({
    value: PropTypes.string
  }).isRequired
}
