import React from 'react'
import PropTypes from 'prop-types'
import { Window } from '@progress/kendo-react-dialogs'
import LoadingCover from '/src/ui/core/layouts/loading_cover'
import '/src/static/css/core/popups/modal.css'

// eslint-disable-next-line max-lines-per-function
export default function Modal({
  title,
  header,
  height,
  width,
  loading,
  footer,
  children,
  style,
  onClose,
  closable,
  appendToBody,
  sizeInPx,
  applyOverlay,
  className,
  modalBodyClassName
}) {
  return (
    <div className="modal-wrapper">
      <Window
        modal={applyOverlay}
        onClose={onClose}
        className={className}
        draggable={false}
        appendTo={appendToBody ? document.body : null}
        minimizeButton={() => null}
        maximizeButton={() => null}
        restoreButton={() => null}
        closeButton={closable ? '' : () => null}
        stage="DEFAULT"
        resizable
        style={{
          ...style,
          height: sizeInPx ? `${height}px` : `${height}%`,
          width: sizeInPx ? `${width}px` : `${width}%`,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        title={title}
      >
        <LoadingCover show={loading.status} text={loading.text} />
        {header && <div className="modal-header">{header}</div>}
        <div className={modalBodyClassName}>{children}</div>
        {footer && <div className="modal-footer">{footer}</div>}
      </Window>
    </div>
  )
}

Modal.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  closable: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  loading: PropTypes.shape({
    status: PropTypes.bool,
    text: PropTypes.string
  }),
  sizeInPx: PropTypes.bool,
  appendToBody: PropTypes.bool,
  applyOverlay: PropTypes.bool,
  className: PropTypes.string,
  modalBodyClassName: PropTypes.string
}

Modal.defaultProps = {
  height: 80,
  width: 36,
  title: '',
  header: null,
  footer: null,
  style: {},
  closable: false,
  loading: { status: false, text: '' },
  onClose: () => {},
  sizeInPx: false,
  appendToBody: false,
  applyOverlay: true,
  className: 'k-window-wrapper',
  modalBodyClassName: 'modal-body'
}
