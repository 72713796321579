import React from 'react'
import PropTypes from 'prop-types'
import UserAvatar from '/src/ui/core/menus/user_avatar'
import Breadcrumb from '/src/ui/core/menus/breadcrumb'
import TopMenu from '/src/ui/core/header/top_menu'
import '/src/static/css/core/menus/header.css'

export default function Header({ showProjectSwitcher }) {
  return (
    <div className="header-bar">
      <div className="header">
        <TopMenu showProjectSwitcher={showProjectSwitcher} />
        <UserAvatar />
      </div>
      <div className="breadcrumb-project-switch">
        <Breadcrumb />
      </div>
    </div>
  )
}

Header.propTypes = {
  showProjectSwitcher: PropTypes.bool
}

Header.defaultProps = {
  showProjectSwitcher: true
}
