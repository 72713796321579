import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { MdInput, MdInfo, MdPerson } from 'react-icons/md'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import UserPreferencesMenu from '/src/ui/core/menus/user_preferences_menu'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function UserMenu({ user, logout, showUserPopup }) {
  const history = useHistory()

  const onVersionClick = () => {
    dispatch({ type: BusEvents.OPEN_MODAL, payload: { type: 'about' } })
  }

  return (
    <div className="user-menu-wrapper">
      <div className="user-info">
        <div className="username">
          <CustomTooltip maxLength={25}>{user.name}</CustomTooltip>
        </div>
        <div className="user-email">
          <CustomTooltip maxLength={25}>{user.email}</CustomTooltip>
        </div>
      </div>
      <div className="user-menu-list">
        <button type="button" onClick={() => history.push(`/users/${user.id}/edit`)} className="user-menu-item">
          <MdPerson />
          {I18n.t('user_menu.profile')}
        </button>
        <UserPreferencesMenu showUserPopup={showUserPopup} />
        <span className="user-popup-divider" />
        <button type="button" className="user-menu-item" data-testid="user-about-version-item" onClick={onVersionClick}>
          <MdInfo />
          {I18n.t('user_menu.about_version')}
        </button>
        <button type="button" className="user-menu-item" data-testid="user-menu-item" onClick={() => logout()}>
          <MdInput />
          {I18n.t('user_menu.logout')}
        </button>
      </div>
    </div>
  )
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number
  }),
  logout: PropTypes.func.isRequired,
  showUserPopup: PropTypes.func
}

UserMenu.defaultProps = {
  user: { name: '', email: '', id: null },
  showUserPopup: () => {}
}
