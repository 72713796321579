import React from 'react'
import { MdBorderColor, MdNewReleases } from 'react-icons/md'
import { BsFillTagFill } from 'react-icons/bs'
import I18n from '/src/utils/translations'
import { ReactComponent as FeatureSearch } from '/public/static/svg/feature_search_icon'

export function getStatusFlag(statusId, statuses) {
  if (!statusId || !statuses[statusId]?.i18n_id) return null

  const statusMapping = {
    in_planning: {
      title: I18n.t('progress_services.not_planned'),
      type: 'not-planned',
      icon: <BsFillTagFill style={{ color: '#f3f5f7' }} />
    },
    planned: {
      title: I18n.t('progress_services.planned'),
      type: 'planned',
      icon: <BsFillTagFill style={{ color: '#f3f5f7' }} />
    },
    canceled: {
      title: I18n.t('progress_services.canceled'),
      type: 'canceled',
      icon: <BsFillTagFill style={{ color: '#f3f5f7' }} />
    }
  }

  return statusMapping[statuses[statusId].i18n_id] || null
}

export function getAdditionalFlag(isExtra, isRevised) {
  if (isExtra) {
    return {
      title: I18n.t('progresses.extra'),
      type: 'extra',
      icon: <MdNewReleases style={{ color: '#ff6326' }} />
    }
  }

  if (isRevised) {
    return {
      title: I18n.t('progresses.revised'),
      type: 'revised',
      icon: <MdBorderColor style={{ color: '#2474e8' }} />
    }
  }

  return null
}

export function getInspectionFlag(hasInspectionPending) {
  if (hasInspectionPending) {
    return {
      title: I18n.t('estimate_services.statuses.inspected_flag'),
      type: 'inspection-pending',
      icon: <FeatureSearch style={{ color: '#f3f5f7' }} />
    }
  }
  return null
}
