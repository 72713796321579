import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import EstimateServicePricesBlock from '/src/ui/domain/estimate_services/estimate_service_prices_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import useContractItemBlock from '/src/hooks/get_contract_item_block'
import useFetchSections from '/src/hooks/api/fetch_sections'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getGalleryTab, getInfoTab } from '/src/ui/core/tabs/get_tabs'
import { CONTEXT_MENU_HIDDEN_STATUS } from '/src/utils/constants/scope'
import EstimateServicesTrackBlock from '/src/ui/domain/estimate_services/estimate_services_track_block'
import '/src/static/css/model_side_panel.css'

export default function ScopingServicesSidePanel({
  dataItem,
  onClose,
  sections,
  contextMenuItems,
  scopeDataItem,
  scopeSections
}) {
  const contractItemBlock = useContractItemBlock(dataItem)
  const requestDataItem = scopeDataItem?.request
  const requestEavTemplateId = requestDataItem?.eav_template_id
  const requestSections = useFetchSections(requestEavTemplateId) || []

  const showContextMenu = !CONTEXT_MENU_HIDDEN_STATUS.includes(scopeDataItem?.request?.request_status_id)

  const buildSummary = () => ({
    component: (
      <EstimateServicesTrackBlock dataItem={dataItem} contextMenuItems={showContextMenu && contextMenuItems} />
    ),
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  const getBlocks = (initialBlocks) => {
    const pricesTitle = (
      <React.Fragment>
        <div className="scoping-services-side-panel__title">
          <span>{I18n.t('estimate_services.prices')}</span>
          <GeneralTooltip>
            <div className="info-icon" title={I18n.t('estimate_services.automatically_prices')}>
              i
            </div>
          </GeneralTooltip>
        </div>
      </React.Fragment>
    )

    const pricesBlock = {
      component: <EstimateServicePricesBlock key="prices_block" dataItem={dataItem} />,
      title: pricesTitle,
      type: 'prices'
    }

    return [buildSummary(), contractItemBlock, pricesBlock, ...initialBlocks]
  }

  const initialSectionBlocks = useSectionBlock({ sections, dataItem })
  const infoBlocks = getBlocks(initialSectionBlocks)
  const infoTab = getInfoTab(infoBlocks)

  const galleryModules = [
    { moduleName: 'request', sections: requestSections, dataItem: requestDataItem },
    { moduleName: 'scope', sections: scopeSections, dataItem: scopeDataItem },
    { moduleName: 'estimate_service', sections, dataItem }
  ]

  const galleryTab = getGalleryTab({ modules: galleryModules, loading: false })

  const scopingServicesTabs = [infoTab, galleryTab]

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('estimate_services.estimate_services_detail.side_panel_title')}
        closable
        onClose={onClose}
      >
        <SidePanelTabs tabs={scopingServicesTabs} />
      </BlocksSidePanel>
    </div>
  )
}

ScopingServicesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  scopeDataItem: PropTypes.oneOfType([PropTypes.object]),
  scopeSections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  }).isRequired
}

ScopingServicesSidePanel.defaultProps = {
  dataItem: undefined,
  scopeDataItem: undefined,
  scopeSections: {}
}
