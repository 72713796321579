import React from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircle, MdClose } from 'react-icons/md'
import I18n from '/src/utils/translations'

export default function StatusFilterPopup({
  labelClassName,
  statuses,
  statusDescription,
  isStatusSelected,
  setPopupOpened,
  onClearFilters,
  onChangeFilter,
  filterType,
  children
}) {
  const clearFilters = () => {
    setPopupOpened(false)
    onClearFilters()
  }

  const changeFilter = (status) => {
    setPopupOpened(false)
    onChangeFilter(status)
  }

  const addStatusClasses = (status) => {
    return `label ${status.i18n_id.replaceAll('_', '-')}`
  }

  return (
    <React.Fragment>
      <div id={`popup-${filterType}-filter`} className={`popup-request-${filterType}-filter`}>
        {statuses &&
          statuses.map((status) => {
            const classes = filterType === 'status' ? addStatusClasses(status) : ''
            return (
              <div key={status.id}>
                <span
                  className={`${labelClassName} workflow-${filterType} ${classes}`}
                  onClick={() => changeFilter(status)}
                >
                  {statusDescription(status)}
                  {isStatusSelected(status) && <MdCheckCircle />}
                </span>
              </div>
            )
          })}
        {children}
      </div>
      <button className="clear-filters" type="button" onClick={() => clearFilters()}>
        <MdClose />
        {I18n.t(`grid.clear_filters`)}
      </button>
    </React.Fragment>
  )
}

StatusFilterPopup.propTypes = {
  labelClassName: PropTypes.string,
  statuses: PropTypes.array,
  statusDescription: PropTypes.func.isRequired,
  isStatusSelected: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  setPopupOpened: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

StatusFilterPopup.defaultProps = {
  labelClassName: '',
  statuses: [],
  children: null
}
