/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { duplicationCustomActionBasic } from '/src/ui/core/forms/form_utils'
import EstimateServiceModel from '/src/models/estimate_service'
import useFetchSections from '/src/hooks/api/fetch_sections'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import useFormulasServices from '/src/hooks/formulas_services'
import processColumn from '/src/ui/domain/formulas_services/formulas_services_utils'
import Form from '/src/ui/core/forms/form'
import I18n from '/src/utils/translations'

export default function EstimateServiceForm({ dataItem, opts, estimateId, type }) {
  const query = {
    query: {
      where: {
        discipline_id: dataItem ? dataItem.discipline_id : opts.disciplineId
      }
    }
  }

  const { templateId, scopeId, disciplineId } = opts

  const [estimateServiceModel, setEstimateServiceModel] = useState(new EstimateServiceModel(query))
  const [formulasStore] = useStore('formulas')
  const sections = useFetchSections(templateId)
  const formulasServices = useFormulasServices(templateId, true)

  const isDuplicate = type === 'duplicate'

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])

  const isEditMode = () => type === 'edit'

  useEffect(() => {
    if (!isEditMode()) return
    const { columns } = estimateServiceModel
    const contractService = columns.find((a) => a.description === 'contract_service')
    contractService.readOnly = true
    setEstimateServiceModel(estimateServiceModel)
  }, [])

  const includeOnForm = () => {
    if (isEditMode()) return undefined
    return {
      scope_id: scopeId,
      eav_template_id: templateId,
      estimate_id: estimateId,
      discipline_id: disciplineId
    }
  }

  return (
    <FormWrapper
      model={estimateServiceModel}
      dataItem={dataItem}
      type={type}
      backText={I18n.t('estimate_services.back')}
      sidePanelSections={sectionsWithGeneral}
    >
      <React.Fragment>
        <Form
          model={estimateServiceModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm()}
          templateId={templateId}
          type={type}
          isDuplicate={isDuplicate}
          submitParams={isDuplicate ? duplicationCustomActionBasic : undefined}
          hasFormulaServices
          formulasServices={formulasServices}
          processColumn={(column) =>
            processColumn(column, formulasServices, formulasStore, estimateServiceModel.paramName)
          }
        />
        <FormButtons model={estimateServiceModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

EstimateServiceForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  opts: PropTypes.shape({
    templateId: PropTypes.number,
    scopeId: PropTypes.number,
    disciplineId: PropTypes.number
  }),
  disciplineId: PropTypes.number,
  estimateId: PropTypes.number,
  type: PropTypes.string
}

EstimateServiceForm.defaultProps = {
  dataItem: null,
  opts: {},
  estimateId: undefined,
  type: undefined
}
