import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/dropdown.css'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { useWatch } from 'react-hook-form'
import { filterBy } from '@progress/kendo-data-query'

export default function InputCascadeDrop({ inputProps }) {
  const { options, dependency, control, dataItem } = inputProps
  const [disabled, setDisabled] = useState(false)
  const [data, setData] = useState(options)
  const foreignAttr = `${dependency}_id`
  const defaultValue = dataItem ? dataItem[foreignAttr] : undefined
  const dependencyValue = useWatch({ control, name: foreignAttr, defaultValue })
  const {
    wrapperClass,
    inputClass,
    error,
    onFocus,
    onBlur,
    onChange,
    value,
    onComboChange,
    isOptionsString
  } = useInputDrop({ ...inputProps, options: data })

  useEffect(() => {
    const hasPreviousValue = (previous, array) => {
      if (!previous) return false
      return array.some((obj) => obj.id === previous.id)
    }

    if (dependencyValue !== undefined) {
      const filteredOptions = options.filter((option) => option[foreignAttr] === dependencyValue)
      if (!hasPreviousValue(value, filteredOptions)) onChange({ target: { value: null } })
      setData(filteredOptions)
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [dependencyValue, options])

  const onFilterChange = (e) => {
    if (dependencyValue !== undefined) {
      const filteredOptions = options.filter((option) => option[foreignAttr] === dependencyValue)
      setData(filterBy(filteredOptions, e.filter))
    } else {
      setData(filterBy(options, e.filter))
    }
  }

  return (
    <div className={wrapperClass}>
      <ComboBox
        disabled={disabled}
        id={inputProps.id}
        className={inputClass()}
        data={data}
        value={value || null}
        onChange={onComboChange}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={{ className: 'combobox-list' }}
        filterable
        textField={isOptionsString() ? null : 'description'}
        valueField={isOptionsString() ? null : 'id'}
        onFilterChange={onFilterChange}
      />
      <div className="error-label">{error}</div>
    </div>
  )
}

InputCascadeDrop.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    options: PropTypes.array,
    dependency: PropTypes.string,
    control: PropTypes.oneOfType([PropTypes.object]),
    dataItem: PropTypes.object
  }).isRequired
}
