/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useStore } from 'react-context-hook'
import { MdFunctions } from 'react-icons/md'
import { Slide } from '@progress/kendo-react-animation'
import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/boolean_refinements'
import { parseFormulaResult } from '/src/models/concerns/formula_result'
import '/src/static/css/inputs.css'

export default function InputControlledFormula({ inputProps }) {
  const { id, name, value, placeholder, className, label, isInteger, readOnly, formula } = inputProps
  const [globalProject] = useStore('project')
  const [displayFormula, setDisplayFormula] = useState(false)

  const formulaDetail = displayFormula && formula ? formula.equation : null

  return (
    <React.Fragment>
      <div className="formula-wrapper" title={I18n.t('form.inputs.formula.description')}>
        {label}
        <input
          id={id}
          className={classNames('form-input-text', { [className]: className })}
          name={name}
          value={isPresent(value) ? parseFormulaResult(value, isInteger, globalProject) : ''}
          disabled
          placeholder={placeholder}
          type="text"
        />
        <button type="button" id="result-button" disabled={readOnly} onClick={() => setDisplayFormula((show) => !show)}>
          <MdFunctions />
        </button>
      </div>
      <span className="formula-detail">
        <Slide>{formulaDetail}</Slide>
      </span>
    </React.Fragment>
  )
}

InputControlledFormula.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.element,
    isInteger: PropTypes.bool,
    readOnly: PropTypes.bool,
    formula: PropTypes.object
  })
}

InputControlledFormula.defaultProps = {
  inputProps: {
    className: '',
    name: '',
    placeholder: '',
    value: null,
    label: null,
    isInteger: false,
    formula: null
  }
}
