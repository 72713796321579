import * as React from 'react'
import * as ReactDOM from 'react-dom'
import I18n from '/src/utils/translations'
import { Button } from '@progress/kendo-react-buttons'
import { Window } from '@progress/kendo-react-dialogs'
import { Upload } from '@progress/kendo-react-upload'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { EditorUtils } from '@progress/kendo-react-editor'
import { insertImageFiles } from '/src/ui/core/rich_text/utils'
import { isPresent } from '/src/utils/boolean_refinements'

export const InsertImageDialog = (props) => {
  const [selected, setSelected] = React.useState(0)
  const [files, setFiles] = React.useState([])
  const isMounted = React.useRef(true)
  React.useEffect(() => {
    // Set the flag to true when component mounts
    isMounted.current = true
    return () => {
      // Set the flag to false when component unmounts
      isMounted.current = false
    }
  }, [])

  let src
  let altText
  let title
  let width
  let height

  const onTabSelect = (event) => {
    setFiles([])
    setSelected(event.selected)
  }

  const onClose = () => {
    props.onClose.call(undefined)
  }

  const onAddFiles = (event) => {
    setFiles(event.newState.map((f) => f.getRawFile && f.getRawFile()).filter((f) => f))
  }

  const extractImageData = () => {
    const data = {
      src: src?.value ?? null,
      title: title?.value ?? null,
      alt: altText?.value ?? null,
      width: width?.value ?? null,
      height: height?.value ?? null
    }
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== null && value !== ''))
  }

  const insertImageWithFiles = (view, files, nodeType, position, attrs) => {
    insertImageFiles({
      view,
      files,
      nodeType,
      position,
      attrs
    })
  }

  const insertImageWithoutFiles = (view, nodeType, attrs) => {
    const newImage = nodeType.createAndFill(attrs)
    EditorUtils.insertNode(view, newImage, true)
  }

  const onInsert = () => {
    const { view, imageNode } = props
    const { nodes } = view.state.schema
    const nodeType = nodes[imageNode]
    const position = null
    const attrs = extractImageData()

    if (files.length) {
      insertImageWithFiles(view, files, nodeType, position, attrs)
    } else {
      insertImageWithoutFiles(view, nodeType, attrs)
    }

    view.focus()
    onClose()
  }

  const { view, imageNode } = props

  const state = view && view.state
  let attrs = {}
  if (state && state.selection.node && state.selection.node.type === state.schema.nodes[imageNode]) {
    attrs = state.selection.node.attrs
  }

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-alt">{I18n.t('rich_text.upload_dialog.alternate_text')}</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-alt"
          defaultValue={attrs.alt}
          ref={(e) => (altText = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-title">{I18n.t('rich_text.upload_dialog.title')}</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-title"
          defaultValue={attrs.title}
          ref={(e) => (title = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-width">{I18n.t('rich_text.upload_dialog.width')}</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-width"
          defaultValue={attrs.width}
          ref={(e) => (width = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-height">{I18n.t('rich_text.upload_dialog.height')}</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-height"
          defaultValue={attrs.height}
          ref={(e) => (height = e)}
        />
      </div>
    </React.Fragment>
  )

  const buttons = (
    <div
      className="text-right"
      style={{
        clear: 'both'
      }}
    >
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onInsert} themecolor="primary">
        Insert
      </Button>
    </div>
  )

  function validateFile(file) {
    if (file.validationErrors && file.validationErrors.length > 0) {
      return Promise.reject({ uid: file.uid })
    }
    return Promise.resolve({ uid: file.uid })
  }

  function readAsDataURL(file, onProgress) {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          const base64Result = reader.result.split(',')[1]
          if (isMounted.current) resolve({ uid: file.uid, base64Result })
        } else {
          reject({ uid: file.uid })
        }
      }

      reader.onprogress = (data) => {
        if (isMounted.current) onProgress(file.uid, data)
      }

      reader.onabort = () => {
        if (isMounted.current) reject({ uid: file.uid })
      }

      reader.onerror = () => {
        if (isMounted.current) reject({ uid: file.uid })
      }

      reader.readAsDataURL(file.getRawFile())
    })
  }

  function onSaveRequest(files, options, onProgress) {
    const currentFile = files[0]
    return validateFile(currentFile)
      .then(() => readAsDataURL(currentFile, onProgress))
      .then((result) => {
        // Update viewModel if necessary
        return Promise.resolve(result)
      })
      .catch((error) => {
        // Handle errors
        return Promise.reject(error)
      })
  }

  function onRemoveRequest(files, options) {
    const currentFile = files[0]
    const uid = currentFile.uid
    return new Promise((resolve) => {
      resolve({
        uid: uid
      })
    })
  }

  return ReactDOM.createPortal(
    <Window
      title={I18n.t('rich_text.upload_dialog.window_title')}
      onClose={onClose}
      initialWidth={500}
      initialHeight={480}
    >
      <TabStrip selected={selected} onSelect={onTabSelect} animation={false}>
        {Object.entries(attrs).length === 0 && (
          <TabStripTab title={I18n.t('rich_text.upload_dialog.upload')}>
            <div className="k-edit-form-container pt-3 pb-3">
              <div className="k-edit-label">
                <label htmlFor="k-editor-image-width">{I18n.t('rich_text.upload_dialog.image')}</label>
              </div>
              <div className="k-edit-field">
                <Upload
                  batch={false}
                  multiple
                  defaultFiles={[]}
                  withCredentials={false}
                  saveUrl={onSaveRequest}
                  removeUrl={onRemoveRequest}
                  onAdd={onAddFiles}
                />
              </div>
              {fields}
              {buttons}
            </div>
          </TabStripTab>
        )}
        <TabStripTab title={I18n.t('rich_text.upload_dialog.by_url')}>
          <div className="k-edit-form-container pt-3 pb-3">
            <div className="k-edit-label">
              <label htmlFor="k-editor-image-url">{I18n.t('rich_text.upload_dialog.web_address')}</label>
            </div>
            <div className="k-edit-field">
              <input
                type="text"
                className="k-textbox"
                id="k-editor-image-url"
                defaultValue={attrs.src}
                disabled={/^data:image/.test(attrs.src || '')}
                ref={(e) => (src = e)}
                autoFocus
              />
            </div>
            {fields}
            {buttons}
          </div>
        </TabStripTab>
      </TabStrip>
      <style>{`.k-dropzone { width: 100%; }`}</style>
    </Window>,
    document.body
  )
}
