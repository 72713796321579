import React from 'react'
import PropTypes from 'prop-types'

export default function DatasheetFilterColumn({ value }) {
  return value
}

DatasheetFilterColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DatasheetFilterColumn.defaultProps = {
  value: null
}
