import React from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import TrackBlockGeneralButtons from '/src/ui/core/buttons/track_block_general_buttons'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import I18n from '/src/utils/translations'
import WorkDescriptionModal from '/src/ui/domain/scopes/work_description_modal'
import ScopesTrackRichText from '/src/ui/domain/scopes/scopes_track_rich_text'
import '/src/static/css/core/blocks/track_block.css'

export default function ScopesTrackBlock({ dataItem, contextMenuItems, showAttachments, template }) {
  const buttons = <TrackBlockGeneralButtons dataItem={dataItem} contextMenuItems={contextMenuItems} />

  return !dataItem || !dataItem.request ? (
    <ThreeDotsLoader />
  ) : (
    <TrackBlock detail={dataItem.request} iconAttachments={showAttachments} actions={buttons}>
      <div className="scope-track-block-children">
        <div className="summary-item">
          {I18n.t('scopes.item')} {dataItem.number}
        </div>
        <ScopesTrackRichText dataItem={dataItem} template={template} />
      </div>
      <WorkDescriptionModal dataItem={dataItem} />
    </TrackBlock>
  )
}

ScopesTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
      duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
      remove: PropTypes.oneOfType([PropTypes.object]).isRequired
    })
  ]),
  showAttachments: PropTypes.bool,
  template: PropTypes.oneOfType([PropTypes.object])
}

ScopesTrackBlock.defaultProps = {
  dataItem: undefined,
  contextMenuItems: undefined,
  showAttachments: true,
  template: undefined
}
