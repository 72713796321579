import React from 'react'
import PropTypes from 'prop-types'
import InfoColumn from '/src/ui/domain/estimates/pdf_export/estimates_pdf_info_column'

const HeaderInfo = ({
  reason,
  discipline,
  requestDate,
  requestEndDate,
  kaeferEnergyPriority,
  prioritySequence,
  maintenancePlant,
  area,
  subarea
}) => (
  <div className="estimates-pdf-header__header-info">
    <InfoColumn
      reason={reason}
      discipline={discipline}
      requestDate={requestDate}
      requestEndDate={requestEndDate}
      kaeferEnergyPriority={kaeferEnergyPriority}
      prioritySequence={prioritySequence}
      maintenancePlant={maintenancePlant}
      area={area}
      subarea={subarea}
    />
  </div>
)

HeaderInfo.propTypes = {
  reason: PropTypes.string,
  discipline: PropTypes.string,
  requestDate: PropTypes.string,
  requestEndDate: PropTypes.string,
  kaeferEnergyPriority: PropTypes.string,
  prioritySequence: PropTypes.number,
  maintenancePlant: PropTypes.string,
  area: PropTypes.string,
  subarea: PropTypes.string
}

export default HeaderInfo
