import I18n from '/src/utils/translations'

export default function useActionFormTitles(scaffolding) {
  const formTitle = () => `${I18n.t('scaffoldings.tag')} #${scaffolding.tag_number}`

  const formSubTitle = () => `${scaffolding.description}`

  return {
    formTitle,
    formSubTitle
  }
}
