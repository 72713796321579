import { useRef } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

export default function useQueryParamWithPrevious(paramName, queryConfig = NumberParam) {
  const [queryParam] = useQueryParam(paramName, queryConfig)
  const prevQueryParam = useRef()

  if (queryParam && prevQueryParam.current !== queryParam) {
    prevQueryParam.current = queryParam
  }

  return queryParam || prevQueryParam.current
}
