import React from 'react'
import PropTypes from 'prop-types'

export default function DatasheetAggregationColumn({ value }) {
  return value
}

DatasheetAggregationColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

DatasheetAggregationColumn.defaultProps = {
  value: null
}
