import { isBlank } from '/src/utils/boolean_refinements'
import { indexify } from '/src/utils/array'
import { isEmpty } from '/src/utils/object'

export const MAX_ATTACHMENT_SIZE = '10mb'
export const MAX_ATTACHMENT_SIZE_MB = 10
export const MAX_PICTURE_SIZE = '5mb'
export const MAX_PICTURE_SIZE_MB = 5
export const MAX_ALLOWED_NUM_UPLOADS = 30
export const MAX_ALLOWED_NUM_PICTURES = 30
export const PICTURE_CATEGORY_ID = 2
export const PICTURE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif', '.svg']
export const PARSED_PICTURE_EXTENSIONS = PICTURE_EXTENSIONS.join(',').replaceAll('.', ' ').toUpperCase()

export const getAttachmentFiles = (dataItem, attachments) => {
  if (
    isBlank(dataItem.attachments) ||
    dataItem.attachments.length === 0 ||
    isBlank(attachments) ||
    attachments.length === 0
  )
    return []

  const columnFiles = indexify(
    dataItem.attachments.filter(({ uuid }) => attachments.includes(uuid)),
    'uuid'
  )
  if (isEmpty(columnFiles)) return []
  if (typeof attachments === 'string') return [columnFiles[attachments]]
  return attachments.map((uuid) => columnFiles[uuid])
}

export const formTypes = (dataItem) => {
  if (dataItem && dataItem.id) return 'edit'
  if (!dataItem || !dataItem.formType || dataItem.inspected || dataItem.formType === 'new') return 'new'
  return 'edit'
}

export const defaultPictureRestrictions = {
  allowedExtensions: PICTURE_EXTENSIONS,
  maxFileSize: MAX_PICTURE_SIZE_MB * 1000000
}
