import { useMemo } from 'react'
import { useStore } from 'react-context-hook'

export default function useGeneralDiscipline() {
  const [disciplines] = useStore('disciplines')

  const disciplinesFiltered = useMemo(
    () =>
      disciplines &&
      Object.values(disciplines).filter((discipline) => discipline.description.toLowerCase() === 'general'),
    [disciplines]
  )

  return disciplinesFiltered && disciplinesFiltered.length > 0 ? disciplinesFiltered[0] : undefined
}
