// eslint-disable-next-line
import I18n from '/src/utils/translations'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { isBlank } from '/src/utils/boolean_refinements'

export function includeActualProgressOnServiceDataSource(dataSource) {
  if (isBlank(dataSource)) return undefined
  return {
    ...dataSource,
    data:
      dataSource?.data?.map((dataItem) => ({
        ...dataItem,
        actual_progress: dataItem?.progress_service_summary?.actual_progress,
        progress_dirty: false
      })) ?? []
  }
}

export default class ProgressServiceModel {
  constructor() {
    this.paramName = ProgressServiceModel.paramName
    this.singularName = ProgressServiceModel.singularName
  }

  static paramName = 'progress_service'

  static singularName = I18n.t('progress_services.description')

  name = I18n.t('progress_services.title')

  packageableParamName = 'packageable_progress_service'

  route = 'progress_services'

  flexible = true

  parentModels = ['progress', 'progress.request', 'contract_service', 'service_price']

  initialColumns = [
    {
      id: 1,
      description: 'number',
      field: 'number',
      title: '#',
      readOnly: true,
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 2,
      description: 'contract_service',
      field: 'contract_service.description',
      orderOnForm: 1,
      title: I18n.t('progress_services.contract_service'),
      foreignKey: 'contract_services',
      foreignAttribute: 'contract_service_id',
      filterable: true,
      required: true,
      type: 'contract_service',
      width: 200
    },
    {
      id: 3,
      description: 'responsible',
      field: 'responsible.full_name',
      title: I18n.t('progress_services.responsible'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      filterable: true,
      hideOnGrid: true,
      columnSize: 'width-100',
      orderOnForm: 4,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: { where: { is_progress_responsible: 1 } },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
    {
      id: 4,
      description: 'start_date',
      title: I18n.t('progress_services.start_date'),
      orderOnForm: 2,
      hideOnGrid: true,
      width: 200,
      type: 'date'
    },
    {
      id: 5,
      description: 'end_date',
      title: I18n.t('progress_services.end_date'),
      hideOnGrid: true,
      orderOnForm: 3,
      width: 200,
      type: 'date'
    },
    {
      id: 6,
      description: 'unit',
      title: I18n.t('progress_services.unit'),
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 7,
      description: 'service_package',
      field: 'service_package.description',
      hideOnForm: true,
      title: I18n.t('progress_services.service_package'),
      foreignKey: 'service_package',
      foreignAttribute: 'service_package_id',
      filterable: true,
      sortable: false,
      readOnly: true,
      width: 200,
      type: 'text'
    },
    {
      id: 8,
      description: 'flag',
      field: 'flag',
      hideOnForm: true,
      title: I18n.t('progress_services.flag'),
      readOnly: true,
      width: 200,
      filterable: false,
      type: 'string'
    },
    {
      id: 9,
      description: 'description',
      title: I18n.t('progresses.description'),
      hideOnForm: true,
      readOnly: true,
      width: 150,
      filterable: false,
      type: 'string'
    },
    {
      id: 27,
      description: 'factor',
      title: I18n.t('progress_services.factor'),
      readOnly: true,
      editable: false,
      hideOnGrid: true,
      hideOnForm: true,
      width: 200,
      type: 'numeric'
    },
    {
      id: 28,
      description: 'service_description',
      field: 'service_description',
      title: I18n.t('service_description'),
      readOnly: true,
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 10,
      description: 'progress_service_summary',
      field: 'productivity_factor',
      title: I18n.t('progresses.pf'),
      foreignKey: 'progress_service_summary',
      foreignAttribute: 'id',
      readOnly: true,
      hideOnGrid: true,
      hideOnForm: true,
      width: 200,
      filterable: false,
      type: 'decimal'
    },
    {
      id: 11,
      description: 'actual_progress',
      field: 'progress_service_summary.actual_progress',
      title: I18n.t('progresses.progress'),
      default: function fn(dataItem) {
        if (!dataItem || !dataItem.progress_service_summary) return 0
        if (
          this.statuses &&
          this.statuses[dataItem.progress_service_status_id] &&
          this.statuses[dataItem.progress_service_status_id].i18n_id === 'canceled'
        )
          return 0
        return dataItem.progress_service_summary.actual_progress
      }.bind(this),
      foreignKey: 'progress_service_summary',
      hideOnForm: true,
      foreignAttribute: 'id',
      filterable: false,
      sortable: false,
      width: 200,
      type: 'percentage',
      number_of_digits: 0
    },
    {
      id: 25,
      description: 'actual_quantity',
      title: I18n.t('progress_services.actual_quantity'),
      default: function fn(dataItem) {
        if (!dataItem || !dataItem.progress_service_summary) return 0
        if (
          this.statuses &&
          this.statuses[dataItem.progress_service_status_id] &&
          this.statuses[dataItem.progress_service_status_id].i18n_id === 'canceled'
        )
          return 0
        return dataItem.progress_service_summary.actual_quantity
      }.bind(this),
      orderOnForm: 5,
      readOnly: true,
      hideOnGrid: true,
      filterable: false,
      type: 'decimal'
    },
    {
      id: 26,
      description: 'actual_hours',
      title: I18n.t('progress_services.actual_hours'),
      default: function fn(dataItem) {
        if (!dataItem || !dataItem.progress_service_summary) return 0
        if (
          this.statuses &&
          this.statuses[dataItem.progress_service_status_id] &&
          this.statuses[dataItem.progress_service_status_id].i18n_id === 'canceled'
        )
          return 0
        return dataItem.progress_service_summary.actual_hours
      }.bind(this),
      orderOnForm: 7,
      readOnly: true,
      hideOnGrid: true,
      filterable: false,
      type: 'decimal'
    },
    {
      id: 12,
      description: 'delta_quantity',
      title: I18n.t('progresses.delta_qty'),
      orderOnForm: 6,
      default: function fn(dataItem) {
        if (!dataItem || !dataItem.progress_service_summary) return 0
        if (
          this.statuses &&
          this.statuses[dataItem.progress_service_status_id] &&
          this.statuses[dataItem.progress_service_status_id].i18n_id === 'canceled'
        )
          return 0
        return dataItem.progress_service_summary.actual_quantity - dataItem.quantity
      }.bind(this),
      filterable: false,
      readOnly: true,
      width: 200,
      type: 'decimal'
    },
    {
      id: 13,
      description: 'delta_hours',
      title: I18n.t('progresses.delta_hours'),
      default: function fn(dataItem) {
        if (!dataItem || !dataItem.progress_service_summary) return 0
        if (
          this.statuses &&
          this.statuses[dataItem.progress_service_status_id] &&
          this.statuses[dataItem.progress_service_status_id].i18n_id === 'canceled'
        )
          return 0
        return dataItem.progress_service_summary.actual_hours - dataItem.team_target_hours
      }.bind(this),
      orderOnForm: 8,
      readOnly: true,
      sortable: false,
      filterable: false,
      width: '200',
      type: 'decimal'
    },
    {
      id: 14,
      description: 'application_price',
      title: I18n.t('progress_services.application_price'),
      orderOnForm: 9,
      readOnly: true,
      hideOnForm: false,
      type: 'formula_service'
    },
    {
      id: 15,
      description: 'materials_price',
      title: I18n.t('progress_services.materials_price'),
      orderOnForm: 10,
      readOnly: true,
      hideOnForm: false,
      type: 'formula_service'
    },
    {
      id: 16,
      description: 'equipment_price',
      title: I18n.t('progress_services.equipment_price'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 11,
      type: 'formula_service'
    },
    {
      id: 17,
      description: 'other_price',
      title: I18n.t('progress_services.other_price'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 12,
      type: 'formula_service'
    },
    {
      id: 18,
      description: 'norm_hours',
      title: I18n.t('progress_services.norm_hours'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 13,
      type: 'formula_service'
    },
    {
      id: 19,
      description: 'team_target_hours',
      title: I18n.t('progress_services.team_target_hours'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 14,
      type: 'formula_service'
    },
    {
      id: 20,
      description: 'budget_target_hours',
      title: I18n.t('progress_services.budget_target_hours'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 15,
      type: 'formula_service'
    },
    {
      id: 21,
      description: 'quantity',
      title: I18n.t('progress_services.quantity'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 16,
      type: 'formula_service'
    },
    {
      id: 22,
      description: 'crews',
      title: I18n.t('scopes.crews'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 17,
      type: 'formula_service'
    },
    {
      id: 23,
      description: 'crew_size',
      title: I18n.t('scopes.crew_size'),
      orderOnForm: 18,
      readOnly: true,
      hideOnForm: false,
      type: 'formula_service'
    },
    {
      id: 24,
      description: 'working_hours',
      title: I18n.t('scopes.working_hours'),
      readOnly: true,
      hideOnForm: false,
      orderOnForm: 19,
      type: 'formula_service'
    },
    {
      id: 29,
      description: 'discipline',
      title: I18n.t('progress_services.discipline'),
      foreignKey: 'disciplines',
      foreignAttribute: 'discipline_id',
      field: 'discipline.description',
      filterable: true,
      sortable: false,
      editable: false,
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'text'
    }
  ]

  columns = includeDateColumns(this.initialColumns)

  setStatuses = (progressServiceStatuses) => {
    this.statuses = progressServiceStatuses
  }
}
