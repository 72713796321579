import { useMemo, useCallback } from 'react'
import { normalizeDateToString } from '/src/utils/project_formatter'
import { addRequestByDisciplineQueryToColumns } from '/src/models/concerns/form'
import useAccessSolutionDisciplines from '/src/hooks/access_solution_disciplines'

const filterByAddOrModify = (scaffoldingColumns, actionType) => {
  if (actionType === 'dimension') {
    return scaffoldingColumns.filter(
      (column) => !['modification_type', 'work_dismantle', 'work_order_id', 'comment'].includes(column.description)
    )
  }
  return scaffoldingColumns
}

const filterDimensionType = (scaffoldingColumns, dimensionType) => {
  return scaffoldingColumns.filter((column) => {
    if (['length', 'height', 'width'].includes(column.description)) {
      if (dimensionType === 'm3') return true
      if (dimensionType === 'm2') return ['length', 'width'].includes(column.description)
      if (dimensionType === 'm') return column.description === 'length'
    }
    return true
  })
}

const addDateRanges = (actionColumns, scaffolding) => {
  const updatedColumns = actionColumns

  const index = updatedColumns.findIndex((column) => column.description === 'date')
  updatedColumns[index].min = scaffolding.erect_date
  updatedColumns[index].max = normalizeDateToString(new Date())

  return updatedColumns
}

export default function useUpdateActionFormModel(actionModel, scaffolding, actionOpts) {
  const { addOrModify, scaffoldingDimensionType } = actionOpts

  const accessSolutionDisciplines = useAccessSolutionDisciplines()

  const filteredByAddOrModify = useCallback(
    (scaffoldingColumns) => filterByAddOrModify(scaffoldingColumns, addOrModify),
    [addOrModify]
  )

  const filteredDimensionType = useCallback(
    (scaffoldingColumns) => filterDimensionType(scaffoldingColumns, scaffoldingDimensionType),
    [scaffoldingDimensionType]
  )

  const addedDateRanges = useCallback((actionColumns) => addDateRanges(actionColumns, scaffolding), [scaffolding])

  const model = useMemo(() => {
    let columnsUpdated = addRequestByDisciplineQueryToColumns(actionModel.columns, null, accessSolutionDisciplines)
    columnsUpdated = filteredByAddOrModify(columnsUpdated)
    columnsUpdated = filteredDimensionType(columnsUpdated)
    columnsUpdated = addedDateRanges(columnsUpdated)
    return { ...actionModel, columns: columnsUpdated }
  }, [actionModel, accessSolutionDisciplines, filteredByAddOrModify, filteredDimensionType])

  return [model]
}
