import React, { useCallback, useMemo, useRef, useState } from 'react'
import I18n from '/src/utils/translations'
import classNames from 'classnames'
import { MdCheck, MdFormatIndentIncrease } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import { createSingleDateRangeFilter, createWeekDateRangeFilter } from '/src/utils/calendar'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import DateColumn from '/src/ui/core/grid/flexible_column/date'

export default function useProgressServicesDateFilter({ filter, onFilter, onSelectedOptionChange }) {
  const [selectedOption, setSelectedOption] = useState('all')
  const popupButtonRef = useRef(null)

  const cleanFilter = () => filter.filter((f) => !['start_date', 'end_date'].includes(f.column))

  const resetDateFilters = useCallback(() => {
    onSelectedOptionChange(I18n.t('progress_services.date_filter.all'))
    setSelectedOption('all')
  }, [cleanFilter, setSelectedOption, onFilter, onSelectedOptionChange])

  const hasDateFilter = useMemo(() => selectedOption !== 'all', [selectedOption])

  const extractDateRange = (filterWeek) => {
    const startDateFilter = filterWeek.find(({ column }) => column === 'end_date')
    const endDateFilter = filterWeek.find(({ column }) => column === 'start_date')

    const startDate = startDateFilter.value[0]
    const endDate = endDateFilter.value[1]

    return (
      <span>
        {`${I18n.t('progress_services.date_filter.week')}: `}
        <DateColumn value={startDate} />
        {` ${I18n.t('progress_services.date_filter.to')} `}
        <DateColumn value={endDate} />
      </span>
    )
  }

  const changeFilter = (filterOption) => {
    let filters = []
    const today = new Date()
    let selectedFilter = ''

    switch (filterOption) {
      case 'yesterday': {
        const yesterday = new Date(today.getTime())
        yesterday.setDate(today.getDate() - 1)
        filters = createSingleDateRangeFilter(yesterday)
        selectedFilter = I18n.t('progress_services.date_filter.yesterday')
        break
      }
      case 'today':
        filters = createSingleDateRangeFilter(today)
        selectedFilter = I18n.t('progress_services.date_filter.today')
        break
      case 'tomorrow': {
        const tomorrow = new Date(today.getTime())
        tomorrow.setDate(today.getDate() + 1)
        filters = createSingleDateRangeFilter(tomorrow)
        selectedFilter = I18n.t('progress_services.date_filter.tomorrow')
        break
      }
      case 'week':
        filters = createWeekDateRangeFilter(today)
        selectedFilter = extractDateRange(filters)
        break
      default:
        filters = []
        selectedFilter = I18n.t('progress_services.date_filter.all')
    }

    setSelectedOption(filterOption)
    onSelectedOptionChange(selectedFilter)
    onFilter((f) => [...cleanFilter(f), ...filters])
  }

  const items = ['all', 'week', 'yesterday', 'today', 'tomorrow'].map((option) => ({
    text: I18n.t(`progress_services.date_filter.${option}`),
    className: classNames({ 'date-filter-item': true, selected: selectedOption === option }),
    icon: selectedOption === option ? <MdCheck /> : <span />,
    onClick: () => changeFilter(option)
  }))

  const dateFilterButton = useMemo(
    () => (
      <PopupMenu items={items} popupButtonRef={popupButtonRef}>
        <CustomTooltip position="bottom" parentTitle>
          <button
            id="open-filter-popup-button"
            type="button"
            className="btn-icon grid-header-icon"
            data-testid="date-filter-button"
            title={I18n.t('progress_services.date_filter_info')}
            ref={popupButtonRef}
          >
            <MdFormatIndentIncrease />
          </button>
        </CustomTooltip>
      </PopupMenu>
    ),
    [items, popupButtonRef]
  )

  return {
    dateFilterButton,
    resetDateFilters,
    hasDateFilter
  }
}
