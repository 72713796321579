import React from 'react'
import PropTypes from 'prop-types'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import '/src/static/css/core/layouts/loading_cover.css'

export default function LoadingCover({ show, text, className }) {
  return (
    show && (
      <div className={`loading-cover ${className}`} data-testid="load-icon">
        <AiOutlineLoading3Quarters className="spinner dialog-icon dialog-icon-load" />
        <div className="modal-loading-text">{text}</div>
      </div>
    )
  )
}

LoadingCover.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string
}

LoadingCover.defaultProps = {
  show: false,
  text: '',
  className: ''
}
