import { isBlank } from '/src/utils/boolean_refinements'

/**
 * File designed to process columns before sending them to the input component
 * @return Processed Column
 */
export default function processColumn(column, formulasServices, formulasStore, entity) {
  // TODO: Use switch statement if we need to deal with another type
  if (column.type !== 'formula_service') return column

  if (isBlank(formulasServices)) return column

  const formulaId = formulasServices[`${column.description}_formula_id`]
  const formula = formulasStore[formulaId]

  if (!formula) return column

  return { ...column, formula_id: formula.id, formula: { ...formula, entity } }
}

export const translateFormulaService = (originalText, description, eavTemplateId) => {
  const formulaServicesRename = JSON.parse(localStorage.getItem('translations'))
  const columnKey = `${eavTemplateId}_formulas_services.index.${description}_formula_id`

  if (formulaServicesRename && formulaServicesRename[columnKey]) {
    return formulaServicesRename[columnKey]
  }
  return originalText
}

export const translateFormulaServiceColumns = (model, eavTemplateId) => {
  model.columns.forEach((column) => {
    const newTitle = translateFormulaService(column.title, column.description, eavTemplateId)
    const modifiedColumn = { ...column, title: newTitle }
    Object.assign(column, modifiedColumn)
  })
}
