const DIMENSION = 'dimension'
const MODIFICATION = 'modification'

const isScaffoldingDismantled = (statuses, scaffolding) => {
  if (!statuses || !scaffolding || !Object.keys(statuses).length) return false
  const status = statuses[scaffolding.scaffolding_status_id]
  return status && status.i18n_id === 'dismantled'
}

export { DIMENSION, MODIFICATION, isScaffoldingDismantled }
