import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@progress/kendo-react-tooltip'
import '/src/static/css/tooltip.css'

export default function CustomTooltip({ children, maxLength, position, className, parentTitle }) {
  if (typeof children === 'string') {
    if (children.length <= maxLength) return <span className={className}>{children}</span>

    return (
      <Tooltip position={position} parentTitle={parentTitle} anchorElement="target">
        <span className={className} title={children}>{`${children.substr(0, maxLength)} ...`}</span>
      </Tooltip>
    )
  }

  return (
    <Tooltip position={position} parentTitle={parentTitle} anchorElement="target">
      {children}
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  maxLength: PropTypes.number,
  position: PropTypes.string,
  parentTitle: PropTypes.bool,
  className: PropTypes.string
}

CustomTooltip.defaultProps = {
  maxLength: 35,
  position: 'top',
  parentTitle: false,
  className: ''
}
