import { useEffect, useReducer, useCallback, useRef, useState } from 'react'
import useFetch from '/src/hooks/api/fetch'
import { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'
import { objectEquals } from '/src/utils/object'
import { useStore } from 'react-context-hook'

const MAX_RETRIES = 3

const storeEntities = (entities, batchEntities) => {
  entities.forEach((entity) => {
    const { data: entityData, inactives: entityInactives } = entity
    if (!batchEntities[entity.name]) return

    const { get, set, onPageFetch, key } = batchEntities[entity.name]
    const hasNoInactives = entityInactives && entityInactives.length === 0

    if (entityData.length === 0 && hasNoInactives) {
      if (!get) set([])
      else set(get)
      return
    }

    const result = entityData.reduce((map, obj) => {
      map[obj[key || 'id']] = obj
      return map
    }, {})

    const newEntityData = { ...get, ...result }

    if (onPageFetch) onPageFetch(entity.name, newEntityData, entityData, entityInactives)
    else set(newEntityData)
  })
}

const getTotalPages = (entities, paginateInactives) => {
  let totalPages = 0

  entities.forEach((entity) => {
    const verifyInactives = paginateInactives && entity.inactives_total
    const inactivesTotal = verifyInactives ? entity.inactives_total : 0

    const maxItems = Math.max(entity.total, inactivesTotal)
    const entityTotalPages = Math.ceil(maxItems / MAX_PAGE_SIZE)

    if (entityTotalPages > totalPages) totalPages = entityTotalPages
  })

  return totalPages
}

const syncBatchRoutes = (batchEntities) =>
  Object.keys(batchEntities).map((key) => {
    return { name: key, query: batchEntities[key].query }
  })

/**
 * This custom hook will fetch multiple paginated pre defined entities data
 * @param batchEntities
 * @return {[*, *]}
 */

const initialState = {
  mounted: false,
  loading: true,
  totalPages: 0,
  skip: 0,
  pagesLeft: -1,
  retries: 0
}

function reducer(state, action) {
  const { pagesLeft, skip, retries } = state

  switch (action.type) {
    case 'startFetch':
      return { ...state, ...initialState, mounted: true }
    case 'pageUpdate':
      return {
        ...state,
        ...action.payload,
        skip: skip + MAX_PAGE_SIZE,
        pagesLeft: pagesLeft === -1 ? action.payload.totalPages - 1 : pagesLeft - 1,
        retries: 0
      }
    case 'retryFetch':
      return { ...state, retries: retries + 1 }
    case 'done':
      return { ...state, ...initialState, mounted: true, loading: false }
    default:
      throw Error()
  }
}

export default function useSyncBatch(batchEntities, onDone, paginateInactives = false) {
  const [subproject, _] = useStore('subproject')
  const { fetch } = useFetch()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [entitiesKeys, setEntitiesKeys] = useState(Object.keys(batchEntities) || [])
  const entitiesRef = useRef(batchEntities)

  const onSyncBatchSuccess = useCallback(
    (responseData) => {
      const { data } = responseData

      const hasData = data.some((item) => {
        const hasItemData = item.data && item.data.length > 0
        const hasItemInactives = item.inactives && item.inactives.length > 0
        return hasItemData || (paginateInactives && hasItemInactives)
      })

      if (hasData) {
        storeEntities(data, entitiesRef.current)
        dispatch({
          type: 'pageUpdate',
          payload: { totalPages: getTotalPages(data, paginateInactives) }
        })
      } else {
        dispatch({ type: 'done' })
      }
    },
    [paginateInactives]
  )

  const fetchSyncBatch = useCallback(
    (currentSkip = 0) => {
      const batchQueryParams = {
        requestAction: 'READ',
        httpAction: 'post',
        dataOptions: { paging: { pageSize: MAX_PAGE_SIZE, skip: currentSkip } },
        additionalResource: { path: 'batch' },
        data: { routes: syncBatchRoutes(entitiesRef.current) }
      }

      fetch('sync', batchQueryParams, {
        onSuccess: (responseData) => onSyncBatchSuccess(responseData.data),
        onError: () => dispatch({ type: 'retryFetch' })
      })
    },
    [fetch, onSyncBatchSuccess]
  )

  const syncBatch = useCallback(
    (newBatchEntities) => {
      if (!newBatchEntities) {
        dispatch({ type: 'startFetch' })
        fetchSyncBatch()
        return
      }

      entitiesRef.current = newBatchEntities

      const nextEntitiesKeys = Object.keys(entitiesRef.current)
      setEntitiesKeys(nextEntitiesKeys)

      if (nextEntitiesKeys.length === 0) {
        dispatch({ type: 'done' })
      } else {
        dispatch({ type: 'startFetch' })
        fetchSyncBatch()
      }
    },
    [fetchSyncBatch]
  )

  useEffect(() => {
    entitiesRef.current = batchEntities
  }, [batchEntities])

  useEffect(() => {
    const keys = Object.keys(batchEntities)
    setEntitiesKeys((prevEntitiesKeys) => {
      if (objectEquals(prevEntitiesKeys, keys)) return prevEntitiesKeys
      return keys
    })
  }, [batchEntities])

  useEffect(() => {
    const { mounted, loading, totalPages, pagesLeft, skip, retries } = state

    if (!mounted) {
      if (entitiesKeys.length === 0) {
        dispatch({ type: 'done' })
        return
      }

      dispatch({ type: 'startFetch' })
      fetchSyncBatch(skip)
      return
    }

    if (loading) {
      if (retries > 0) {
        if (retries > MAX_RETRIES) {
          dispatch({ type: 'done' })
        } else {
          fetchSyncBatch(skip)
        }
        return
      }

      if (totalPages > 0) {
        if (pagesLeft > 0) {
          fetchSyncBatch(skip)
        } else {
          dispatch({ type: 'done' })
        }
      }
    } else if (onDone) {
      onDone()
    }
  }, [entitiesKeys, state, fetchSyncBatch, onDone, subproject])

  return { loading: state.loading, status: state, syncBatch }
}
