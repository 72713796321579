import React from 'react'
import PropTypes from 'prop-types'
import IdCell from '/src/ui/core/grid/id_cell'
import DatasheetModel from '/src/models/progress'
import FieldFactory from '/src/ui/core/fields/field_factory'

export default function DatasheetCellFactory({ field, dataItem }) {
  const { columns } = new DatasheetModel()
  const column = columns.find((c) => c.description == field)

  switch (field) {
    case 'id':
      return <IdCell id={dataItem[field]} param="id" />
    default:
      return <FieldFactory value={dataItem[field]} type={column ? column.type : 'text'} />
  }
}

DatasheetCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

DatasheetCellFactory.defaultProps = {
  field: '',
  dataItem: undefined
}
