import { useState } from 'react'

/**
 *  This is a helper custom hook for column order
 * @return {[*, (function(*, *): *)]} =
 * [
 *   columnOrder: the order of columns,
 *   onColumnReorder: callback whe columns are reordered on the grid
 * ]
 */
export default function useColumnOrder() {
  const [columnOrder, setColumnOrder] = useState({})

  const onColumnReorder = (e, columns) => {
    const newColumnOrder = {}
    e.columns.forEach((column) => (newColumnOrder[column.field] = column.orderIndex))
    setColumnOrder(newColumnOrder)

    const orderedColumns = columns.map((column) => {
      // TODO: This (column.field || column.description) is necessary to
      // account for the way Columns are created on the SimpleGrid's
      // buildColumnComponent method. After refactoring the way we assign
      // values to the Column field state on that function, we should remove this OR.
      return { ...column, orderIndex: newColumnOrder[column.field || column.description] }
    })

    return orderedColumns
  }

  return [columnOrder, onColumnReorder]
}
