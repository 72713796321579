import React from 'react'
import PropTypes from 'prop-types'

export default function MatrixDimensionColumn({ value }) {
  return value
}

MatrixDimensionColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

MatrixDimensionColumn.defaultProps = {
  value: null
}
