// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectedModule {
  columns = [
    {
      id: 1,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 1,
      metadata: JSON.stringify([
        { id: 'Request', description: I18n.t('inspections.inspected_modules.request') },
        { id: 'Scope', description: I18n.t('inspections.inspected_modules.scope') },
        { id: 'estimate_services', description: I18n.t('inspections.inspected_modules.estimate_services') },
        { id: 'Progress', description: I18n.t('inspections.inspected_modules.progress') },
        { id: 'progress_services', description: I18n.t('inspections.inspected_modules.progress_services') },
        { id: 'Scaffolding', description: I18n.t('inspections.inspected_modules.scaffolding') }
      ]),
      textDisplayFields: ['description'],
      default: 'Request',
      required: true,
      width: 200,
      type: 'drop'
    },
    {
      id: 2,
      description: 'request_id',
      title: I18n.t('requests.id'),
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      orderOnForm: 2,
      required: true,
      searchFields: ['id', 'reason', 'discipline][description', 'comments'],
      queryBuilder: (previous) => {
        const { inspected_type: inspectedType } = previous
        return {
          where: {
          '[request_statuses][i18n_id]': ['Progress', 'progress_services'].includes(inspectedType)?
            [
            'in_planning',
            'in_performance',
            'on_hold',
            'done'
            ] : [
              'registered',
              'confirmed',
              'in_scoping',
              'authorization_pending',
              'in_planning',
              'in_performance',
              'on_hold',
              'done'
            ]
          }
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
      type: 'search'
    },
    {
      id: 3,
      description: 'inspected_template',
      title: I18n.t('inspections.template'),
      orderOnForm: 3,
      required: true,
      searchFields: ['title'],
      queryBuilder: (previous) => {
        const { inspected_type: inspectedType } = previous
        const templateTypes = {
          Scope: 'scoping',
          Progress: 'progress',
          estimate_services: 'scoping',
          progress_services: 'progress'
        }

        return { where: { template_type: templateTypes[inspectedType] } }
      },
      searchRoute: 'eav_templates',
      textDisplayFields: ['title'],
      type: 'search'
    },
    {
      id: 4,
      description: 'inspected_id',
      title: I18n.t('inspections.inspected'),
      orderOnForm: 4,
      required: true,
      searchFields: ['number'],
      queryBuilder: (previous) => {
        const { request_id: requestId, inspected_template: inspectedTemplate } = previous
        const queryObj = requestId
          ? { where: { request_id: requestId, eav_template_id: inspectedTemplate } }
          : {
              where: {
                '[scaffolding_statuses][i18n_id]': ['erected', 'ready_to_dismantle']
              }
            }

        return queryObj
      },
      searchRouteBuilder: (previous) => {
        const { inspected_type: inspectedType } = previous
        const searchRoute = {
          Scope: 'scopes',
          estimate_services: 'scopes',
          Progress: 'progresses',
          progress_services: 'progresses',
          Scaffolding: 'scaffoldings'
        }

        return searchRoute[inspectedType]
      },
      textDisplayFields: ['number'],
      searchOperator: 'contains',
      textField: 'number',
      type: 'search'
    },
    {
      id: 5,
      description: 'service_ids',
      title: I18n.t('inspections.inspected_services'),
      orderOnForm: 5,
      required: true,
      multiple: true,
      distinct: true,
      searchFields: ['number'],
      queryBuilder: ({ inspected_type: inspectedType, inspected_id: inspectedId }) => {
        const parentIdMap = {
          estimate_services: 'scope_id',
          progress_services: 'progress_id'
        }

        return {
          where: {
            [parentIdMap[inspectedType]]: inspectedId
          }
        }
      },
      searchRouteBuilder: ({ inspected_type: inspectedType }) => {
        return inspectedType
      },
      textDisplayFields: ['number'],
      textField: 'number',
      type: 'search'
    }
  ]
}
