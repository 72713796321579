import React from 'react'
import PropTypes from 'prop-types'

export default function DropFormulaVariablesColumn({ value }) {
  return value !== null ? JSON.stringify(value) : ''
}

DropFormulaVariablesColumn.propTypes = {
  value: PropTypes.shape({})
}

DropFormulaVariablesColumn.defaultProps = {
  value: {}
}
