import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

/**
 * This hook controls the entire expand/collapse behavior of a pictures group.
 * @param {object} collapse - An object containing params to control the collapsing of the group.
 * @param {boolean} collapse.isCollapsible - Defines if the pictures group should be collapsible.
 * @param {number} collapse.picturesPerRow - How many pictures should each row display.
 * @param {number} collapse.picturesSize - The size of the picture, using 'vw'.
 * @param {number} numberOfPictures - Total number of pictures to be shown.
 * @returns {array} The returned array contains the toggle button element,
 * the classes for the pictures group and the dynamically set styles, calculated based
 * on the number of pictures and whether the component is collapsed or not.
 */
export default function useCollapsiblePictures(collapse, numberOfPictures = 0) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { isCollapsible, picturesPerRow, picturesSize } = collapse

  const hasEnoughPictures = numberOfPictures > picturesPerRow

  const calculateWidth = () => {
    const vwPart = `${picturesPerRow * picturesSize}vw`
    const pxPart = `${(picturesPerRow - 1) * 10}px`
    return `calc(${vwPart} + ${pxPart})`
  }

  const calculateHeight = () => {
    if (isCollapsed) return `${picturesSize}vw`

    const numberOfRows = Math.ceil(numberOfPictures / picturesPerRow)
    const vwPart = `${numberOfRows * picturesSize}vw`
    const pxPart = `${(numberOfRows - 1) * 10}px`

    return `calc(${vwPart} + ${pxPart})`
  }

  const getCollapsibleStyles = () => {
    if (!isCollapsible) return {}
    return {
      height: calculateHeight(),
      width: calculateWidth()
    }
  }

  const getCarouselClassName = () => {
    if (!isCollapsible) return 'side-panel-carousel-wrapper'
    return 'side-panel-carousel-wrapper--collapsible'
  }

  const toggleButton = isCollapsible && hasEnoughPictures && (
    <div
      onClick={() => setIsCollapsed((prev) => !prev)}
      className="collapsible-pictures-toggle"
      data-testid="collapsible-pictures-toggle-button"
    >
      {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
    </div>
  )

  return [toggleButton, getCollapsibleStyles(), getCarouselClassName()]
}
