import { camelize, pluralize } from 'inflected'
import { sanitizeString } from '/src/utils/string'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import { IsEqualOperator, ContainsOperator, IsNotEqualOperator } from '/src/models/concerns/filter_operators'

const BATCH_ENTITY_EQUIVALENT = { responsibles: 'employees' }
const FOREIGN_COLUMN_FILTERS = ['request.reason', 'request.comments']
const SEARCH_BY_DESCRIPTION = ['load_class_id', 'area_noise_id', 'scaffolding_type_id']
const CUSTOM_FILTERS = ['main_work_order_erect_id']

const getEntityAndField = (searchFilter) => {
  const [entityName, fieldName] = searchFilter.field.split('.')
  const fKName = `${entityName}_id`
  if (SEARCH_BY_DESCRIPTION.includes(entityName)) return { entityName, fieldName: 'description', fKName: entityName }
  return { entityName, fieldName, fKName }
}

const sanitizeStrings = (searchVal, entityVal) => ({
  searchValue: sanitizeString(searchVal || ''),
  entityValue: sanitizeString(entityVal || '')
})

const batchedOrEquivalent = (entityName, batchedEntities) => {
  if (batchedEntities[entityName]) return Object.values(batchedEntities[entityName])
  return Object.values(batchedEntities[BATCH_ENTITY_EQUIVALENT[entityName]])
}

const treatAssociationColumnFilter = (gridFilter) => {
  const [associationName, columnName] = gridFilter.field.split('.')
  return { ...gridFilter, field: `${associationName}][${columnName}` }
}

const treatAllTypesStatusFilter = ({ gridFilter, comparisonFunc, batchedEntities, locale }) => {
  const searchFilter = { ...gridFilter }
  const { field, value } = searchFilter
  const [entity] = field.split('_')
  const translations = { ...I18n.translations[locale] }
  const statusTranslations = Object.entries(translations[pluralize(entity)].statuses)
  const idValues = statusTranslations.reduce((ids, translation) => {
    const { searchValue, entityValue } = sanitizeStrings(value, translation[1])
    const entities = batchedOrEquivalent(camelize(pluralize(field), false), batchedEntities)

    if (comparisonFunc(entityValue, searchValue)) {
      ids.push(entities.find((e) => e.i18n_id === translation[0]).id)
    }

    return ids
  }, [])

  searchFilter.operator = IsEqualOperator
  searchFilter.field = `${field}_id`
  searchFilter.value = idValues

  return searchFilter
}

const treatObjectContainsFilter = (gridFilter, batchedEntities) => {
  const searchFilter = { ...gridFilter }
  const { entityName, fieldName, fKName } = getEntityAndField(searchFilter)
  const parsedEntityName = entityName.split('_id')[0]
  const entities = batchedOrEquivalent(camelize(pluralize(parsedEntityName), false), batchedEntities)
  const ids = entities.reduce((filtered, entity) => {
    const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
    if (entityValue.includes(searchValue)) filtered.push(entity.id)
    return filtered
  }, [])

  searchFilter.operator = IsEqualOperator
  searchFilter.field = fKName
  searchFilter.value = ids.toString()

  return searchFilter
}

const treatObjectEqFilter = (gridFilter, batchedEntities) => {
  const searchFilter = { ...gridFilter }
  const { entityName, fieldName, fKName } = getEntityAndField(searchFilter)
  const parsedEntityName = entityName.split('_id')[0]
  const entities = batchedOrEquivalent(camelize(pluralize(parsedEntityName), false), batchedEntities)
  const matchSingleEntity = (entity) => {
    const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
    return searchValue === entityValue
  }
  const item = entities.find(matchSingleEntity)

  searchFilter.field = fKName
  searchFilter.value = item ? item.id : -1

  return searchFilter
}

const treatObjectNeqFilter = (gridFilter, batchedEntities) => {
  const searchFilter = { ...gridFilter }
  const { entityName, fieldName, fKName } = getEntityAndField(searchFilter)
  const parsedEntityName = entityName.split('_id')[0]
  const entities = batchedOrEquivalent(camelize(pluralize(parsedEntityName), false), batchedEntities)
  const ids = entities.reduce((filtered, entity) => {
    const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
    if (searchValue !== entityValue) filtered.push(entity.id)
    return filtered
  }, [])

  searchFilter.field = fKName
  searchFilter.operator = IsEqualOperator
  searchFilter.value = ids.toString()

  return searchFilter
}

const treatGeneralObjectFilter = (gridFilter, batchedEntities) => {
  let searchFilter = { ...gridFilter }
  const { operator } = searchFilter

  // eslint-disable-next-line default-case
  switch (operator) {
    case ContainsOperator:
      searchFilter = treatObjectContainsFilter(searchFilter, batchedEntities)
      break
    case IsEqualOperator:
      searchFilter = treatObjectEqFilter(searchFilter, batchedEntities)
      break
    case IsNotEqualOperator:
      searchFilter = treatObjectNeqFilter(searchFilter, batchedEntities)
      break
  }

  return searchFilter
}

const treatStatusFilter = (gridFilter, batchedEntities, locale) => {
  let searchFilter = { ...gridFilter }
  const { operator } = searchFilter
  let comparisonFunc

  // eslint-disable-next-line default-case
  switch (operator) {
    case ContainsOperator:
      comparisonFunc = (entityValue, searchValue) => entityValue.includes(searchValue)
      break
    case IsEqualOperator:
      comparisonFunc = (entityValue, searchValue) => entityValue === searchValue
      break
    case IsNotEqualOperator:
      comparisonFunc = (entityValue, searchValue) => entityValue !== searchValue
      break
  }

  searchFilter = treatAllTypesStatusFilter({
    gridFilter: searchFilter,
    comparisonFunc,
    batchedEntities,
    locale
  })

  return searchFilter
}

const treatContainsOrFilter = (gridFilter, column) => {
  const fields = column.gridFilterFields.map((field) => `${column.gridFilterTable}][${field}`)
  return { ...gridFilter, type: 'contains_or', field: fields }
}

const treatInspectedFilter = (gridFilter) => {
  const { operator, field } = gridFilter
  const comparison = operator === IsEqualOperator ? 'equals' : operator

  return {
    ...gridFilter,
    operator: 'inspected',
    type: 'inspected',
    field: `${field}][${comparison}`
  }
}

export default function treatObjectFilter({ gridFilter, gridColumns, batchedEntities, locale }) {
  let searchFilter = { ...gridFilter }
  const { field } = searchFilter
  const fieldColumn = gridColumns.filter((column) => column.description === field)[0]
  const isFixedField = isBlank(fieldColumn && fieldColumn.eav_template_id)
  // if it is a dot-notation string (if it is a foreign key field)
  if (fieldColumn?.gridFilterType === 'contains_or') searchFilter = treatContainsOrFilter(searchFilter, fieldColumn)
  else if (fieldColumn?.gridFilterType === 'inspected') searchFilter = treatInspectedFilter(searchFilter)
  else if (field.includes('.') && FOREIGN_COLUMN_FILTERS.includes(field))
    searchFilter = treatAssociationColumnFilter(searchFilter)
  else if (field.includes('.')) searchFilter = treatGeneralObjectFilter(searchFilter, batchedEntities)
  else if (isFixedField && field.includes('_status'))
    searchFilter = treatStatusFilter(searchFilter, batchedEntities, locale)
  else if (isFixedField && SEARCH_BY_DESCRIPTION.includes(field))
    searchFilter = treatGeneralObjectFilter(searchFilter, batchedEntities)

  return searchFilter
}
