import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FormSectionLabel from '/src/ui/core/forms/form_section_label'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/core/forms/form_side_panel.css'

export default function FormSectionsSummary({ sections }) {
  const visibleSections = sections.filter(({ hide }) => !hide)
  const firstSectionId = visibleSections[0] && visibleSections[0].id

  const [focusedSection, setFocusedSection] = useState()

  const onSectionClick = (id) => {
    setFocusedSection(id)
    dispatch({ type: BusEvents.TOGGLE_SECTION_FOCUS, payload: id })
  }

  useBus(
    BusEvents.TOGGLE_SECTION_FOCUS,
    ({ payload }) => {
      setFocusedSection(payload)
    },
    []
  )

  useEffect(() => {
    if (isPresent(focusedSection) || isBlank(firstSectionId)) return
    dispatch({ type: BusEvents.TOGGLE_SECTION_FOCUS, payload: firstSectionId })
  }, [firstSectionId])

  return (
    <div className="form-sections-summary" data-testid="form-sections-summary">
      {visibleSections.map(({ id, title }, index) => (
        <FormSectionLabel
          key={id}
          id={id}
          index={index}
          title={title}
          isFocused={`${id}` === `${focusedSection || firstSectionId}`}
          onClick={() => onSectionClick(id)}
        />
      ))}
    </div>
  )
}

FormSectionsSummary.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      awaitFormSuccess: PropTypes.bool,
      title: PropTypes.string,
      body: PropTypes.element,
      subSections: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired
}

FormSectionsSummary.defaultProps = {}
