import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function EnvironmentSwitchItem({ item, onChange, highlight, icon }) {
  return (
    <button
      type="button"
      data-testid="environment-switch-button"
      className={classNames({
        'project-item': true,
        highlight: highlight
      })}
      onClick={() => onChange(item)}
    >
      <span className="project-title">{item.description}</span>
      {icon}
    </button>
  )
}

EnvironmentSwitchItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  highlight: PropTypes.bool,
  icon: PropTypes.element
}

EnvironmentSwitchItem.defaultProps = {
  highlight: false,
  icon: null
}
