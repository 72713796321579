import React, { useState } from 'react'
import useFetch from '/src/hooks/api/fetch'
import useGeneralDiscipline from '/src/hooks/general_discipline'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import { applyItemChange } from '/src/ui/core/grid/editable/data_appliers'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'

export default function useEstimateServiceScopeFilter({
  tabTemplate,
  filterStepsModalOpts,
  dataSource,
  newDataSource,
  setNewDataSource,
  onItemUpdate,
  onDataSourceEdition,
  getDataItemKey
}) {
  const [isOpened, setIsOpened] = useState(false)
  const [currentValues, setCurrentValues] = useState()
  const [filters, setFilters] = useState([])
  const [dataItemInEdit, setDataItemInEdit] = useState()

  const generalDiscipline = useGeneralDiscipline()
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()

  const { fetch } = useFetch()

  const openEstimateServiceScopeFilterStepsModal = (dataItem, column) => {
    const newFilters = []

    const requestFilter = column.filterSteps.find((filter) => filter.filterAttribute === 'request_id')
    const scopeFilter = column.filterSteps.find((filter) => filter.filterAttribute === 'scope_id')

    if (requestFilter) {
      const disciplines = [tabTemplate?.discipline_id]

      if (generalDiscipline && tabTemplate?.discipline_id !== generalDiscipline.id) {
        disciplines.push(generalDiscipline.id)
      }

      const newRequestFilter = {
        ...requestFilter,
        searchExtraQuery: {
          ...requestFilter.searchExtraQuery,
          where: {
            ...requestFilter.searchExtraQuery.where,
            '[discipline_id]': disciplines
          }
        }
      }

      newFilters.push(newRequestFilter)
    }

    if (scopeFilter) {
      const firstFlexibleColumn = filterFirstFlexibleColumn(filterStepsModalOpts?.scopeColumns)
      const textDisplayFields = ['number']

      if (firstFlexibleColumn?.description) {
        textDisplayFields.push(firstFlexibleColumn.description)
      }

      const newScopeFilter = {
        ...scopeFilter,
        searchExtraQuery: { where: { eav_template_id: tabTemplate?.id } },
        textDisplayFields
      }

      newFilters.push(newScopeFilter)
    }

    setFilters(newFilters)
    setDataItemInEdit(dataItem)
    setIsOpened(true)
  }

  const getEstimate = async (requestId) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { request_id: requestId } }
    }

    const estimate = await fetch('estimates', params)

    return estimate.data.data[0]
  }

  const getScope = async (scopeID) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: scopeID } }
    }

    const scope = await fetch('scopes', params)

    return scope.data.data[0]
  }

  const onDone = async (values) => {
    const requestId = values.request_id[0]
    const estimate = await getEstimate(requestId)
    const scope = values.scope_id ? await getScope(values.scope_id[0]) : null

    const events = [
      { dataItem: dataItemInEdit, field: 'estimate_id', value: estimate.id },
      { dataItem: dataItemInEdit, field: 'request_id', value: values.request_id[0] },
      { dataItem: dataItemInEdit, field: 'scope_id', value: values.scope_id[0] },
      { dataItem: dataItemInEdit, field: 'scope', value: scope }
    ]

    const gridState = {
      dataSource,
      newDataSource,
      setNewDataSource: (nextDataSource) => {
        setNewDataSource(nextDataSource)
        onDataSourceEdition(newDataSource)
      }
    }

    events.forEach((event) => {
      applyItemChange(event, gridState, getDataItemKey)
      onItemUpdate(dataItemInEdit)
    })

    const currentVirtualKey = dataItemInEdit.virtualKey
    const newCurrentValues = {
      ...currentValues,
      [currentVirtualKey]: values
    }

    setCurrentValues(newCurrentValues)
    setIsOpened(false)
  }

  const estimateServiceScopeFilterStepsModal = isOpened && (
    <FilterStepsModal
      filters={filters}
      currentValues={currentValues?.[dataItemInEdit.virtualKey]}
      onClose={() => setIsOpened(false)}
      onDone={onDone}
      skipEmpty={false}
    />
  )

  return [estimateServiceScopeFilterStepsModal, openEstimateServiceScopeFilterStepsModal]
}
