import React from 'react'
import PropTypes from 'prop-types'

export default function StringColumn({ value }) {
  return value
}

StringColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

StringColumn.defaultProps = {
  value: null
}
