import React from 'react'
import PropTypes from 'prop-types'
import InputSearch from '/src/ui/core/inputs/input_search'

export default function InputLookup({ inputProps }) {
  const { columnId } = inputProps
  const searchRoute = `eav_lookups/${columnId}/field_values`
  const searchFields = ['text']
  const textDisplayFields = ['text']
  const keyField = 'value'
  const distinct = true

  return (
    <InputSearch
      inputProps={{
        ...inputProps,
        searchRoute,
        searchFields,
        textDisplayFields,
        keyField,
        distinct
      }}
    />
  )
}

InputLookup.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    dependency: PropTypes.string,
    columnId: PropTypes.number
  }).isRequired
}
