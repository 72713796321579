import React from 'react'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import { useStore } from 'react-context-hook'
import { formattedLocaleDate } from '/src/utils/project_formatter'

export default function DateColumn({ value }) {
  const [globalProject] = useStore('project')
  const opts = { project: globalProject }
  opts.parser = new IntlService()
  opts.value = value

  return formattedLocaleDate(opts)
}

DateColumn.propTypes = {
  value: PropTypes.string
}

DateColumn.defaultProps = {
  value: null
}
