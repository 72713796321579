import React from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircle, MdInfoOutline } from 'react-icons/md'
import { FiAlertTriangle } from 'react-icons/fi'
import { Tooltip } from '@progress/kendo-react-tooltip'

const VALID_TYPES = ['success', 'info', 'warning']

export default function ConditionalFormatIcon({ type, message }) {
  const isTypeValid = () => {
    return VALID_TYPES.includes(type)
  }

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <MdCheckCircle />
      case 'info':
        return <MdInfoOutline />
      case 'warning':
        return <FiAlertTriangle />
      default:
        return null
    }
  }

  return isTypeValid() ? (
    <Tooltip anchorElement="target" align="left" parentTitle>
      <span className={`conditional-format-icon ${type}`} title={message}>
        {renderIcon()}
      </span>
    </Tooltip>
  ) : null
}

ConditionalFormatIcon.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
}

ConditionalFormatIcon.defaultProps = {
  type: undefined,
  message: ''
}
