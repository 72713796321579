import PropTypes from 'prop-types'

export default function RequestColumn({ request, field }) {
  const requestField = field.includes('.') ? field.split('.')[1] : field
  const fieldValue = request ? request[requestField] : ''
  return fieldValue ?? null
}

RequestColumn.propTypes = {
  request: PropTypes.shape({
    reason: PropTypes.string,
    comments: PropTypes.string
  }),
  field: PropTypes.string.isRequired
}

RequestColumn.defaultProps = { request: undefined }
