import { useCallback } from 'react'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'

const defaultColumnsToFilter = ['string', 'formula']

export default function useFirstFlexibleColumn(columnsToFilter = defaultColumnsToFilter) {
  const filterFirstFlexibleColumn = useCallback(
    (eavColumns) => {
      if (!eavColumns || eavColumns.length === 0) return undefined

      const descriptionColumns = eavColumns.filter((col) => {
        return columnsToFilter.includes(col.column_type.description)
      })

      if (descriptionColumns.length === 0) return undefined

      return sortArrayOfObjectsByNumber(descriptionColumns, 'position')[0]
    },
    [columnsToFilter]
  )

  return filterFirstFlexibleColumn
}
