import { useState, useEffect } from 'react'
import I18n from '/src/utils/translations'
import { normalizeStringToLocalDate, dateDiffDays } from '/src/utils/project_formatter'
import { createDateRange, dateToYYYYMMDD } from '/src/utils/calendar'
import { isPresent } from '/src/utils/refinements'
import { range } from '/src/utils/array'
import { DEEP_BLUE, OPAC_GREEN, LIGHT_GRAY } from '/src/utils/constants/chart_colors'
import '/src/static/css/domain/progress_services/planning_chart.css'

export default function usePlanningChartData(dataItem, tooltip) {
  const line = { style: 'smooth', width: 2 }

  const [seriesData, setSeriesData] = useState()
  const [dateCategories, setDateCategories] = useState()

  useEffect(() => {
    const categories = createDateCategoriesArray()

    const series = [
      {
        name: I18n.t('progress_services.planning_chart.target'),
        tooltip: (e) => tooltip(e.point, 'target'),
        data: targetSeries(categories),
        visible: false,
        color: 'url(#svg-planned-gradient)',
        zIndex: 1,
        line: { ...line, color: DEEP_BLUE }
      },
      {
        name: I18n.t('progress_services.planning_chart.actual'),
        tooltip: (e) => tooltip(e.point, 'actual'),
        data: actualSeries(categories),
        visible: true,
        color: 'url(#svg-actual-gradient)',
        zIndex: 2,
        line: { ...line, color: OPAC_GREEN }
      },
      {
        name: I18n.t('progress_services.planning_chart.forecasted'),
        tooltip: (e) => tooltip(e.point, 'forecasted'),
        data: forecastedSeries(categories),
        visible: true,
        color: 'url(#svg-forecasted-gradient)',
        zIndex: 3,
        line: { ...line, color: LIGHT_GRAY }
      }
    ]

    setDateCategories(categories)
    setSeriesData(series)
  }, [dataItem])

  const createDateCategoriesArray = () => {
    let dates = [
      dataItem.start_date,
      dataItem.end_date,
      dataItem.progress_service_summary.forecast_base_date,
      dataItem.progress_service_summary.forecast_end_date
    ]

    const forecastDates = dataItem.forecast_by_date.map((item) => item.date)

    dates = dates.concat(forecastDates)

    dates = dates.filter((date) => isPresent(date))

    if (dates.length === 0) return []

    dates = dates.map((date) => normalizeStringToLocalDate(date))

    const minimumDate = new Date(Math.min.apply(null, dates))
    const maximumDate = new Date(Math.max.apply(null, dates))

    return createDateRange(minimumDate, maximumDate).map((d) => dateToYYYYMMDD(d))
  }

  const targetSeries = (categories) => {
    if (!dataItem.start_date || !dataItem.end_date || !dataItem.quantity) return []

    const days = dateDiffDays(dataItem.start_date, dataItem.end_date) + 1

    const targetDailyQuantity = dataItem.quantity / days

    let targetAccumulatedQuantity = 0

    const targetQuantities = categories.map((date) => {
      const t = Date.parse(date)
      if (t >= Date.parse(dataItem.start_date) && t <= Date.parse(dataItem.end_date)) {
        targetAccumulatedQuantity += targetDailyQuantity
        return targetAccumulatedQuantity
      }
      return undefined
    })
    return targetQuantities
  }

  const actualSeries = (categories) => {
    const { forecast_by_date: forecastByDate } = dataItem

    let actualQuantities = forecastByDate
      .filter((item) => item.forecasted === false)
      .map((item) => [item.date, item.accumulated_quantity])

    actualQuantities = Object.fromEntries(actualQuantities)

    return categories.map((date) => actualQuantities[date])
  }

  const forecastedSeries = (categories) => {
    const { forecast_by_date: forecastByDate } = dataItem

    let forecastedQuantities = forecastByDate
      .filter((item) => item.forecasted)
      .map((item) => [item.date, item.accumulated_quantity])

    forecastedQuantities = Object.fromEntries(forecastedQuantities)

    // It is also required to add the last actual item on the forecasted
    const actualQuantities = forecastByDate.filter((item) => item.forecasted === false)

    if (actualQuantities.length > 0) {
      const lastActual = actualQuantities[actualQuantities.length - 1]
      forecastedQuantities[lastActual.date] = lastActual.accumulated_quantity
    }

    return categories.map((date) => forecastedQuantities[date])
  }

  return [seriesData, dateCategories]
}
