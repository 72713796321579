import { useEffect } from 'react'
import BusEvents from '/src/hooks/bus/bus_events'

let subscribers = []

const validateEvent = (event) => {
  if (!Object.values(BusEvents).includes(event)) {
    let errorMsg = `Event '${event}' not found! `
    errorMsg += 'Check the bus_events.js file to see the registered events'
    throw new Error(errorMsg)
  }
}

const subscribe = (filter, callback) => {
  if (filter === undefined || filter === null) return undefined
  if (callback === undefined || callback === null) return undefined
  if (typeof filter === 'string') validateEvent(filter)

  subscribers = [...subscribers, [filter, callback]]

  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber[1] !== callback)
  }
}

export const dispatch = (event) => {
  let { type } = event
  if (typeof event === 'string') type = event
  validateEvent(type)

  const args = []
  if (typeof event === 'string') args.push({ type })
  else args.push(event)

  subscribers.forEach(([filter, callback]) => {
    if (typeof filter === 'string' && filter !== type) return
    if (typeof filter === 'function' && !filter(...args)) return

    callback(...args)
  })
}

const useBus = (type, callback, dependencies = []) => {
  useEffect(() => subscribe(type, callback), dependencies)

  return dispatch
}

export default useBus
