import React from 'react'
import PropTypes from 'prop-types'
import { FaCalendarTimes } from 'react-icons/fa'
import { getStatus, isOverdue } from '/src/models/concerns/inspection_filter'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'

export default function InspectionStatusLabel({ dataItem, statuses }) {
  const status = getStatus(dataItem, statuses)

  if (!dataItem || !status) return <React.Fragment />

  return (
    <CustomTooltip maxLength={14}>
      <div>
        <div className={`inspection label workflow-status ${status}`} role="status">
          <span className="status-circle" />
          {isOverdue(dataItem.inspect_date, status) && (
            <span className="status-circle-extra">
              <FaCalendarTimes style={{ color: 'white' }} />
            </span>
          )}
          {I18n.t(`inspections.statuses.${status}`)}
        </div>
      </div>
    </CustomTooltip>
  )
}

InspectionStatusLabel.propTypes = {
  dataItem: PropTypes.shape({
    inspect_status_id: PropTypes.number,
    inspect_date: PropTypes.string,
    recurrency_uuid: PropTypes.string
  }),
  statuses: PropTypes.oneOfType([PropTypes.object])
}

InspectionStatusLabel.defaultProps = {
  dataItem: undefined,
  statuses: undefined
}
