import { useEffect, useState } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'

export default function useGetEstimate() {
  const [id, setId] = useState(null)
  const [statusId, setStatusId] = useState(null)
  const getEstimate = useFetchAPI('estimates')

  const read = (requestId) => {
    const params = { requestAction: 'READ', httpAction: 'get' }
    getEstimate.fetchAPI({ ...params, query: { where: { request_id: requestId } } })
  }

  useEffect(() => {
    if (getEstimate.status !== 'SUCCESS' || getEstimate.responseData.data.length === 0) return
    setId(getEstimate.responseData.data[0].id)
    setStatusId(getEstimate.responseData.data[0].estimate_status_id)
  }, [getEstimate.status, getEstimate.responseData])

  return {
    id,
    statusId,
    read
  }
}
