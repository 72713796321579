import React from 'react'
// eslint-disable-next-line import/no-unresolved
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import '/src/static/css/core/rich_text/rich_text_preview.css'

const RichTextPreview = ({ children }) => {
  const parseHtmlString = (htmlString) => {
    if (typeof htmlString === 'string' || htmlString instanceof String) {
      return ReactHtmlParser(htmlString)
    }
    return []
  }

  return <div className="rich-text-content">{parseHtmlString(children)}</div>
}

export default RichTextPreview

RichTextPreview.propTypes = {
  children: PropTypes.string
}

RichTextPreview.defaultProps = {
  children: ''
}
