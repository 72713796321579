import { useState, useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import useWatchParentsBatch from '/src/hooks/watch_parents_batch'

/**
 * Fetches all the parent items for the desired item
 *
 * @param item - Object (The item used to fetch it's parents)
 * @param parents - Array of Strings (The parents that will be feched)
 * @return parentItems - Object - The parent item models with their fields
 *
 * Ex:
 * > useWatchParents({ id: 1, scope_id: 1, ... }, ['scope', 'estimate.request'])
 * {
 *   'scope': { id: 1, description: 'My Scope' },
 *   'request': { id: 2, reason: 'My Reason', test_integer: 666 }
 * }
 */
export default function useWatchParents(item, parents, control) {
  const [parentItems, setParentItems] = useState({})

  const parentsToWatch = parents.filter((parent) => !parent.includes('.'))
  const fieldsToWatch = parentsToWatch.map((p) => `${p}_id`)
  const watchFormValues = useWatch({ control, name: fieldsToWatch })

  const itemWatched = useMemo(
    () => [
      {
        ...item,
        ...watchFormValues.reduce((map, value, index) => {
          if (value === undefined) return map
          return { ...map, [fieldsToWatch[index]]: value }
        }, {})
      }
    ],
    [item, watchFormValues]
  )

  const parentsFromBatch = useWatchParentsBatch(itemWatched, parents)

  useEffect(() => {
    setParentItems((prevParentItems) => {
      if (!parentsFromBatch) return prevParentItems
      return Object.keys(parentsFromBatch).reduce((currentParents, parent) => {
        const parentItems = Object.values(parentsFromBatch[parent])
        const parentFetched = parentItems.length > 0

        if (!parentFetched) return { ...prevParentItems, ...currentParents }
        return { ...prevParentItems, ...currentParents, [parent]: parentItems[0] }
      }, {})
    })
  }, [parentsFromBatch])

  return [parentItems, setParentItems]
}
