import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line max-len
import ProgressServicePackeageableForm from '/src/ui/domain/progress_services/progress_service_packageable_form'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'

export default function ProgressServicePackageableFormModal({ templateId, model }) {
  if (!templateId) return null

  return (
    <GridFormModal modelName={model.packageableParamName}>
      <ProgressServicePackeageableForm />
    </GridFormModal>
  )
}

ProgressServicePackageableFormModal.propTypes = {
  templateId: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired
}

ProgressServicePackageableFormModal.defaultProps = {
  templateId: undefined
}
