/* eslint-disable import/prefer-default-export */
import I18n from '/src/utils/translations'

const convertLocaleApiKey = (key) => key.replace(/\./, '.index.')

export const CustomI18n = {}

CustomI18n.t = (key, options = {}) => {
  const translations = localStorage.getItem('translations')
  let parsedTranslations

  try {
    parsedTranslations = JSON.parse(translations)
  } catch (e) {
    parsedTranslations = {}
  }

  const convertedKey = convertLocaleApiKey(key)
  const customTranslation = parsedTranslations && parsedTranslations[convertedKey]

  if (customTranslation) {
    return customTranslation.replace(/%\{(.*?)\}/g, (_, variable) => {
      return options[variable] !== undefined ? options[variable] : `%{${variable}}`
    })
  }

  return I18n.t(key, options)
}
