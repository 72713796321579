import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatCurrency } from '/src/utils/project_formatter'

export default function CurrencyColumn({ value }) {
  const [globalProject] = useStore('project')
  return formatCurrency(value, globalProject)
}

CurrencyColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

CurrencyColumn.defaultProps = {
  value: null
}
