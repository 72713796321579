import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithDelimiter } from '/src/utils/project_formatter'

export default function IntegerColumn({ value }) {
  const [globalProject] = useStore('project')
  const formattedInt = formatNumberWithDelimiter(value, globalProject)

  return formattedInt
}

IntegerColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

IntegerColumn.defaultProps = {
  value: null
}
