import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { MdArrowRight, MdArrowDropDown, MdOutlineAddPhotoAlternate } from 'react-icons/md'
import AnimateHeight from 'react-animate-height'
import UploadPictureModal from '/src/ui/core/layouts/upload_picture_modal'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/layouts/gallery_accordion.css'

export default function GalleryAccordion({
  label,
  index,
  picLength,
  expandedAccordion,
  setExpandedAccordion,
  children,
  column,
  dataItem,
  model,
  showButtons
}) {
  const isExpanded = index === expandedAccordion
  const height = isExpanded ? 'auto' : 0
  const [showUploadPictureModal, setShowUploadPictureModal] = useState(false)

  const handleUploadPicture = () => {
    setShowUploadPictureModal(true)
  }

  const closeUploadPictureModalCallback = useCallback(() => {
    dispatch(BusEvents.RELOAD_GRID)
    setShowUploadPictureModal(false)
  }, [setShowUploadPictureModal])

  const handleAccordionExpansion = () => {
    if (isExpanded) setExpandedAccordion(null)
    else setExpandedAccordion(index)
  }

  return (
    <div id={`accordion-${index}`} data-testid="gallery-accordion" className="gallery-accordion">
      <div className="accordion-header">
        <button
          type="button"
          className="expand-accordion-button"
          data-testid={`expand-${index}`}
          onClick={handleAccordionExpansion}
        >
          {isExpanded ? <MdArrowDropDown /> : <MdArrowRight />}
        </button>
        <span className="accordion-label" onClick={handleAccordionExpansion}>
          {label}
        </span>
        <span className="accordion-length" onClick={handleAccordionExpansion}>
          {picLength}
        </span>
        {showButtons && (
          <button
            type="button"
            className="add-pictures-button"
            data-testid="add-picture-button"
            onClick={handleUploadPicture}
          >
            <MdOutlineAddPhotoAlternate />
          </button>
        )}
      </div>
      <div className="accordion-body">
        <AnimateHeight duration={500} height={height} data-testid={`expand-body-${index}`}>
          {children}
        </AnimateHeight>
      </div>
      {showUploadPictureModal && (
        <UploadPictureModal
          label={label}
          closeUploadPictureModalCallback={closeUploadPictureModalCallback}
          dataItem={dataItem}
          column={column}
          model={model}
        />
      )}
    </div>
  )
}

GalleryAccordion.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  picLength: PropTypes.string.isRequired,
  expandedAccordion: PropTypes.number,
  setExpandedAccordion: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  column: PropTypes.shape({
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        file_path: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        comment: PropTypes.string
      })
    )
  }).isRequired,
  showButtons: PropTypes.bool,
  model: PropTypes.oneOfType([PropTypes.object]),
  dataItem: PropTypes.shape({
    id: PropTypes.number
  })
}

GalleryAccordion.defaultProps = {
  expandedAccordion: null,
  showButtons: true,
  model: {},
  dataItem: {}
}
