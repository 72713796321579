import React from 'react'
import PropTypes from 'prop-types'
import { BiLock } from 'react-icons/bi'
import { MdInfoOutline } from 'react-icons/md'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { isNumericInputWithMinMax } from '/src/models/concerns/eav_column'
import RequiredMark from '/src/ui/core/alerts/required_mark'

export default function GridHeaderCell({ title, children, column, isColumnEditable, onClick, isColumnRequired }) {
  const information = column.information || title
  const customInfo = isNumericInputWithMinMax(column)

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="header-cell k-link" href="#" onClick={onClick}>
      {!column.hideLockIcon && isColumnEditable && !isColumnEditable(column) ? (
        <span className="header-icon">
          <BiLock />
        </span>
      ) : isColumnEditable && customInfo ? (
        <CustomTooltip position="bottom">
          <span className="header-icon">
            <MdInfoOutline className="num-info-icon" title={customInfo} />
          </span>
        </CustomTooltip>
      ) : null}
      <CustomTooltip position="bottom">
        <span className="header-title" title={information}>
          <RequiredMark required={isColumnRequired} />
          {title}
        </span>
      </CustomTooltip>
      {children}
    </a>
  )
}

GridHeaderCell.propTypes = {
  title: PropTypes.string.isRequired,
  column: PropTypes.shape({
    hideLockIcon: PropTypes.bool,
    information: PropTypes.string
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool]))
  ]),
  isColumnEditable: PropTypes.func,
  onClick: PropTypes.func,
  isColumnRequired: PropTypes.bool
}

GridHeaderCell.defaultProps = {
  children: undefined,
  column: { information: false, hideLockIcon: false },
  isColumnEditable: undefined,
  onClick: () => {},
  isColumnRequired: false
}
