import React, { useState, useEffect } from 'react'
import '/src/static/css/core/grid/grid.css'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function GridHeaderCounter({ page, jumpToPage, total }) {
  const [current, setCurrent] = useState(page)
  const [previous, setPrevious] = useState()
  const [style, setStyle] = useState({ width: `${page.toString().length}em` })

  useEffect(() => {
    setCurrent(page)
  }, [page])

  useEffect(() => {
    let { length } = current.toString()
    if (current === '') length = 1
    setStyle({ width: `${length}em` })
  }, [current])

  const onChange = (e) => {
    let { value } = e.target
    value = value.toString() === '' ? value.toString() : parseInt(value, 10)
    setCurrent(value)
  }

  const onBlur = (e) => {
    let { value } = e.target
    value = parseInt(value, 10)
    if (value < 1 || !value) value = 1
    else if (value > total) value = total
    setCurrent(value)
    if (value !== previous) jumpToPage(e, value)
  }

  const onFocus = (e) => setPrevious(parseInt(e.target.value, 10))

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) e.target.blur()
  }

  return (
    <div className="grid-counter">
      <input
        type="number"
        className="actual-page"
        data-testid="actual-page"
        style={style}
        value={current}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={handleKeyPress}
      />
      {I18n.t('grid.of')}
      <span className="total-page">{total || 1}</span>
    </div>
  )
}

GridHeaderCounter.propTypes = {
  page: PropTypes.number.isRequired,
  jumpToPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
}
